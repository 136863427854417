import { useSelector } from 'react-redux';
import { Provider } from '../types';
import { partnerSelector } from '../store/partner/selectors';

type ProvidersStatus = {
  guardarian: boolean;
  transfi: boolean;
  transak: boolean;
  simplex: boolean;
  'guardarian-off-ramp': boolean;
};

export const useProviderStatuses = (providers: Array<Provider>) => {
  const partner = useSelector(partnerSelector());
  const providersStatus: ProvidersStatus = {
    guardarian: partner?.isGuardarianEnabled ?? false,
    transfi: partner?.isOffRampEnabled ?? false,
    transak: partner?.isTransakEnabled ?? false,
    simplex: partner?.isSimplexEnabled ?? false,
    'guardarian-off-ramp': partner?.isGuardarianOffRampEnabled ?? false,
  };

  return providers.map((provider) => {
    const isActivated = providersStatus[provider.id as keyof ProvidersStatus];
    return { ...provider, isActivated };
  });
};
