import React from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import { LoaderIcon } from '../../../icons/loader-icon';
import { useTeamAccountActivate } from '../../../../api/modules/account/use-team-account-activate';
import { ResponseType } from '../../../../default-types';
import { getSafeErrorMessageText } from '../../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../../helpers/console-error-message';
import { ROUTES } from '../../../../constants/routes';
import classes from './styles.module.scss';

type TeamActivateType = {
  token: string | null,
};

export const TeamActivate: React.FC<TeamActivateType> = (props) => {
  const { token } = props;

  const history = useHistory();
  const fetchTeamAccountActivate = useTeamAccountActivate();

  const [fetching, setFetching] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [isActivated, setIsActivated] = React.useState(false);

  const handleTeamActivate = async () => {
    setFetching(true);
    const { data, status, errorMessage }: ResponseType = await fetchTeamAccountActivate(token || '');

    if (status === 200) {
      setFetching(false);
      setIsActivated(true);
      history.replace(ROUTES.DASHBOARD);
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/team-invite/activate');
      setFetching(false);
      setError(errorDataMessage);
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      handleTeamActivate();
    }, 0);
  }, []);

  const currentText = error || 'Activation of your subsidiary account';

  return (
    <div className={cn([
      classes.content,
      Boolean(error) && classes.contentRed,
    ])}
    >
      {!isActivated && currentText}
      {isActivated && 'Your subsidiary account has been activated.'}
      {' '}
      {fetching && (
        <LoaderIcon size={20} />
      )}
    </div>
  );
};
