import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { nanoid } from 'nanoid';
import { BalanceRowMemo } from './components/balance-row';
import classes from './styles.module.scss';

export const Balances = (props) => {
  const {
    className,
    items,
    onConvert,
    onTopUp,
    onRequestPayout,
  } = props;

  return (
    <div className={cn(classes.balances, className)}>
      <table className={cn(classes.balancesTable, className)}>
        <tbody>
          <tr className={classes.balancesTableHeader}>
            <th className={classes.balancesTableHeaderItem}>Coin</th>
            <th className={classes.balancesTableHeaderItem}>
              <div className={classes.balancesTableHeaderInfo}>
                <div className={classes.balancesTableHeaderAmount}>
                  <p className={classes.amount}>Amount</p>
                </div>
                <div className={classes.balancesTableHeaderActions}>
                  <p>Top-up</p>
                  <p>Withdraw</p>
                  <p>Convert</p>
                </div>
              </div>
            </th>
          </tr>
          {items.map((item) => (
            <BalanceRowMemo
              key={item?.key ?? nanoid()}
              currency={item}
              onConvert={onConvert}
              onTopUp={onTopUp}
              onRequestPayout={onRequestPayout}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

Balances.defaultProps = {
  className: null,
  items: null,
  onConvert: null,
  onTopUp: null,
  onRequestPayout: null,
};

Balances.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  onConvert: PropTypes.func,
  onTopUp: PropTypes.func,
  onRequestPayout: PropTypes.func,
};
