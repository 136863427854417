import * as React from 'react';
import { Helmet } from 'react-helmet';
import { CustomersPage } from '../../components/customers-pages';

export const Customers = () => (
  <>
    <Helmet>
      <title>Customers</title>
    </Helmet>
    <CustomersPage />
  </>
);
