import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Section } from '../section';
import { InputSearch } from '../ui/input-search';
import { LoaderIcon } from '../icons/loader-icon';
import { hasElementsInArray } from '../../helpers/has-elements-in-array';
import { useUpdateCoins } from '../../api/modules/account/use-update-coins';
import { useDeleteCoins } from '../../api/modules/account/use-delete-coins';
import { safeToUpperCase } from '../../helpers/safe-to-upper-case';
import { setSelectedTickers } from '../../store/currencies/reducer';
import { useFetchPartnerProfile } from '../../api/modules/account/use-fetch-partner-profile';
import { useAllCurrencies } from '../../hooks/use-all-currencies';
import { partnerFetchingSelector, partnerSelector } from '../../store/partner/selectors';
import {
  availableCurrenciesSelector,
  currenciesFetchedSelector,
  currenciesFetchingSelector,
  currencyListSelector,
  selectedTickersSelector,
} from '../../store/currencies/selectors';
import { usePageNamesToDisplayInfo } from '../../hooks/use-page-names-to-display-info';
import { PAGE_NAMES_TO_DISPLAY_INFO } from '../../constants/app-constants';
import { PageHeader } from '../ui/page-header';
import { useFiatCurrencies } from '../../hooks/use-fiat-currencies';
import { GroupCurrencies } from './components/group-currencies';
import { getFilteredCurrencies, getGroupCurrencies, getGroupTickers } from './coins-settings-page-helpers';
import { useArrayOfGroupData } from './hooks/use-array-of-group-data';
import { CoinsSettingsInfoAboutUse } from './components/coins-settings-info-about-use';
import classes from './styles.module.scss';

const CATEGORY_NAMES = {
  POPULAR: 'Popular Coins',
  STABLE: 'Stable Coins',
  FIAT: 'Fiat Currencies',
  OTHER: 'Other Currencies',
};
const DEFAULT_TICKER = 'BTC';

export const CoinsSettingsPage = () => {
  const dispatch = useDispatch();
  const fetchUpdateCoins = useUpdateCoins();
  const fetchDeleteCoins = useDeleteCoins();
  const partner = useSelector(partnerSelector());
  const isPartnerFetching = useSelector(partnerFetchingSelector());
  const currenciesFetching = useSelector(currenciesFetchingSelector());
  const currenciesFetched = useSelector(currenciesFetchedSelector());
  const currencyList = useSelector(currencyListSelector());
  const availableCurrencies = useSelector(availableCurrenciesSelector());
  const selectedTickersByPartner = useSelector(selectedTickersSelector());
  const { fiatCurrencies } = useFiatCurrencies(currencyList, true);
  const isOnrampEnabled = (partner?.isGuardarianEnabled || partner?.isOnrampEnabled);
  const currencies = [...availableCurrencies, ...fiatCurrencies];
  const fetching = isPartnerFetching || currenciesFetching;

  const {
    infoBoxShow,
    addPageNameToDisplayInfo,
    removePageNameToDisplayInfo,
  } = usePageNamesToDisplayInfo(
    PAGE_NAMES_TO_DISPLAY_INFO.COINS_SETTINGS,
  );

  const [searchValue, setSearchValue] = React.useState('');
  const [pending, setPending] = React.useState(false);
  const [pendingCategoryName, setPendingCategoryName] = React.useState(null);

  const currenciesFiltered = React.useMemo(
    () => getFilteredCurrencies(currencies, searchValue),
    [currencies, searchValue],
  );

  const {
    popularGroupTickers,
    stableGroupTickers,
    fiatGroupTickers,
    otherGroupTickers,
  } = React.useMemo(
    () => getGroupTickers(currencies),
    [currencies],
  );

  const {
    popularGroupCurrencies,
    stableGroupCurrencies,
    fiatGroupCurrencies,
    otherGroupCurrencies,
  } = React.useMemo(
    () => getGroupCurrencies(currenciesFiltered),
    [currenciesFiltered],
  );

  const arrayOfGroupData = useArrayOfGroupData({
    popularGroupTickers,
    stableGroupTickers,
    fiatGroupTickers,
    otherGroupTickers,
    popularGroupCurrencies,
    stableGroupCurrencies,
    fiatGroupCurrencies,
    otherGroupCurrencies,
    pendingCategoryName,
    pending,
    selectedTickersByPartner,
    CATEGORY_NAMES,
  });

  const handleSearchChange = (value) => {
    setSearchValue(value);
  };
  const handleUpdateCoins = async (tickers, isDefaultCoin = false) => {
    setPending(true);
    const { status } = await fetchUpdateCoins(tickers);

    if (status === 200) {
      const nextSelectedTickers = !isDefaultCoin ? [...selectedTickersByPartner, ...tickers] : tickers;
      dispatch(setSelectedTickers(nextSelectedTickers));
    }
    setPending(false);
  };
  const handleDeleteCoins = async (tickers) => {
    setPending(true);
    const { status } = await fetchDeleteCoins(tickers);

    if (status === 200) {
      const nextSelectedTickers = selectedTickersByPartner
        .filter((coin) => !tickers.includes(safeToUpperCase(coin)));
      dispatch(setSelectedTickers(nextSelectedTickers));
    }
    setPending(false);
  };
  const handleCurrencyClick = async (ticker, isCoinActive, categoryName) => {
    setPendingCategoryName(categoryName);

    if (!isCoinActive) {
      await handleUpdateCoins([ticker]);
    }

    if (isCoinActive && selectedTickersByPartner?.length > 1) {
      await handleDeleteCoins([ticker]);
    }
  };
  const handleSwitcherChange = async (isActive, categoryName, tickers) => {
    if (categoryName === CATEGORY_NAMES.FIAT && !isOnrampEnabled) return;

    setPendingCategoryName(categoryName);

    if (isActive) {
      await handleDeleteCoins(tickers);

      const selectedTickersByPartnerFiltered = selectedTickersByPartner
        .filter((item) => !tickers.includes(item));

      if (!hasElementsInArray(selectedTickersByPartnerFiltered)) {
        await handleUpdateCoins([DEFAULT_TICKER], true);
      }
    } else {
      await handleUpdateCoins(tickers);
    }
  };

  const hasGroupCurrencies =
    hasElementsInArray(popularGroupCurrencies) ||
    hasElementsInArray(stableGroupCurrencies) ||
    hasElementsInArray(fiatGroupCurrencies) ||
    hasElementsInArray(otherGroupCurrencies);
  const currenciesShow = !fetching && currenciesFetched;
  const notFoundTextShow = !fetching && currenciesFetched && !hasGroupCurrencies;

  useFetchPartnerProfile();
  useAllCurrencies();

  return (
    <>
      <Helmet>
        <title>Coins settings</title>
      </Helmet>
      <div className={classes.coinSettingsPage}>
        <PageHeader
          title="Coins settings"
          showTooltipFirstText={!infoBoxShow}
          onClick={infoBoxShow ? removePageNameToDisplayInfo : addPageNameToDisplayInfo}
        />
        {infoBoxShow && (
          <CoinsSettingsInfoAboutUse
            className={classes.infoBlock}
            name={PAGE_NAMES_TO_DISPLAY_INFO.COINS_SETTINGS}
            onClose={removePageNameToDisplayInfo}
          />
        )}
        <Section title="Customers will be able to pay in the following currencies.">
          <div className={classes.description}>
            Received funds will be automatically converted and sent to your payout wallet(s).
            {!isOnrampEnabled && (
              <p>
                You can also enable fiat currencies for payments, just through the
                {' '}
                <a
                  className={classes.link}
                  href="/fiat-operations-settings"
                >
                  fiat operations settings
                </a>
                .
              </p>
            )}
          </div>
          <InputSearch
            id="coins-search"
            className={classes.searchInput}
            placeholder="Type your coin name here"
            onChange={handleSearchChange}
          />
          <div className={classes.coinsContent}>
            {fetching && <LoaderIcon className={classes.loader} />}
            {currenciesShow &&
              arrayOfGroupData.map(
                (item) =>
                  hasElementsInArray(item.currencies) && (
                    <GroupCurrencies
                      key={item.title}
                      className={classes.group}
                      title={item.title}
                      currencies={item.currencies}
                      tickers={item.tickers}
                      selectedTickersByPartner={selectedTickersByPartner}
                      onCurrencyClick={handleCurrencyClick}
                      pending={item.pending}
                      switcherActive={item.isSwitcherActive}
                      onSwitcherChange={handleSwitcherChange}
                      categoryNames={CATEGORY_NAMES}
                    />
                  ),
              )}
            {notFoundTextShow && (
              <div className={classes.notFound}>
                No matches were found for your query
              </div>
            )}
          </div>
        </Section>
      </div>
    </>
  );
};
