function copyToClipboard(text) {
  const el = document.createElement('textarea');

  el.value = text;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  try {
    document.execCommand('copy');
    el.remove();
  } catch (error) {
    el.remove();

    return false;
  }

  return true;
}

export default copyToClipboard;
