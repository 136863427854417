import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { CheckboxWithText } from '../../../ui/checkbox-with-text';
import { isFunction } from '../../../../helpers/is-function';
import { Button } from '../../../ui/button';
import { LoaderIcon } from '../../../icons/loader-icon';
import classes from './styles.module.scss';

export const ActivateCustody = (props) => {
  const {
    className,
    title,
    onClick,
    fetching,
  } = props;

  const [isChecked, setIsChecked] = React.useState(false);
  const [isCheckedError, setIsCheckedError] = React.useState(false);

  const handleAgreementCheck = () => {
    setIsChecked((prev) => !prev);
    setIsCheckedError(false);
  };
  const handleButtonClick = () => {
    if (!isChecked) {
      setIsCheckedError(true);

      return;
    }
    if (isFunction(onClick)) {
      onClick(isChecked);
    }
  };

  const currentTitle = title || 'Save on network fees and safely store funds on our platform.';

  return (
    <div className={cn(classes.activateCustody, className)}>
      <div className={classes.activateCustodyTitle}>
        Activate Custody
      </div>
      <div className={classes.activateCustodyText}>
        <strong>
          {currentTitle}
        </strong>
      </div>
      <ul className={classes.activateCustodyList}>
        <li>
          <img
            src="/images/arrow-blue-icon.svg"
            alt="Icon"
            decoding="async"
          />
          check the boxes below
        </li>
        <li>
          <img
            src="/images/arrow-blue-icon.svg"
            alt="Icon"
            decoding="async"
          />
          whitelist your IP to be completely secure
        </li>
        <li>
          <img
            src="/images/arrow-blue-icon.svg"
            alt="Icon"
            decoding="async"
          />
          start using Custody NOW!
        </li>
      </ul>
      <CheckboxWithText
        className={classes.activateCustodyCheckbox}
        onChange={handleAgreementCheck}
        checked={isChecked}
        error={isCheckedError}
      >
        I have read and accepted
        {' '}
        <a
          href="/files/np-custody-agreement.pdf"
          target="_blank"
        >
          Custody Solution
        </a>
        {' '}
        user agreement
      </CheckboxWithText>
      <div className={classes.activateCustodyButtonBox}>
        <Button
          className={classes.activateCustodyButton}
          onClick={handleButtonClick}
          disabled={fetching}
        >
          {fetching ? (
            <LoaderIcon
              size={25}
              path="/images/loader-white-on-blue-icon.gif"
            />
          ) : 'Continue'}
        </Button>
        {isCheckedError && (
          <span className={classes.activateCustodyButtonError}>
            <img
              src="/images/attention-icon.svg"
              alt="Icon"
              decoding="async"
              width={19}
              height={19}
            />
            Please check the box
          </span>
        )}
      </div>
      <a
        className={classes.activateCustodyLink}
        href="https://nowpayments.io/custody"
        target="_blank"
        rel="noreferrer"
      >
        What is Custody?
      </a>
    </div>
  );
};

ActivateCustody.defaultProps = {
  className: null,
  title: null,
  onClick: null,
  fetching: false,
};

ActivateCustody.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  fetching: PropTypes.bool,
};
