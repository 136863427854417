import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { partnerSelector } from '../store/partner/selectors';
import { setPaymentSettingsFetching, setBalances } from '../store/payment-settings/reducer';
import { isObject } from '../helpers/is-object';
import { getSafeErrorMessageText } from '../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../helpers/console-error-message';
import { balancesSelector } from '../store/payment-settings/selectors';
import { useBalance } from '../api/modules/account/use-balance';
import { useTimeout } from './use-timeout';

export const useBalancesData = () => {
  const dispatch = useDispatch();
  const partner = useSelector(partnerSelector());
  const balancesData = useSelector(balancesSelector());
  const fetchBalance = useBalance();

  const fetchBalances = React.useCallback(async () => {
    dispatch(setPaymentSettingsFetching(true));
    const { data, status, errorMessage } = await fetchBalance();

    if (status === 200) {
      const balanceNormalized = isObject(data?.balance) ? Object.values(data.balance) : [];
      dispatch(setBalances(balanceNormalized));
      dispatch(setPaymentSettingsFetching(false));
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/balances');
      dispatch(setPaymentSettingsFetching(false));
    }
  }, []);
  const firstFetchBalances = React.useCallback(async () => {
    if (!partner || !partner?.isCustody || balancesData) {
      return;
    }

    await fetchBalances();
  }, [partner, balancesData]);

  useTimeout(firstFetchBalances, 0, firstFetchBalances);

  return {
    reFetchBalances: fetchBalances,
  };
};
