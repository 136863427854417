import React from 'react';
import { useSelector } from 'react-redux';
import { PagesWrapper } from './components/pages-wrapper';
import { ProvidersSection } from './components/providers-section';
import { ON_RAMP } from './helpers';
import { FaqSection } from '../shared/faq-section';
import { partnerFetchingSelector, partnerSelector } from '../../store/partner/selectors';
import { useFetchPartnerProfile } from '../../api/modules/account/use-fetch-partner-profile';
import { useProviderStatuses } from '../../hooks/use-providers-statuses';

export const FiatOperationsPage = () => {
  const partnerFetching = useSelector(partnerFetchingSelector());
  const providersWithStatus = useProviderStatuses(ON_RAMP.cards);
  const partner = useSelector(partnerSelector());
  const partnerEmail = partner?.email || '';

  useFetchPartnerProfile();

  return (
    <PagesWrapper>
      <ProvidersSection
        description="Receive fiat payments from users and withdraw directly to your wallet in any of 300+ cryptocurrencies. Submit a request to activate fiat on-ramp providers."
        link="https://nowpayments.notion.site/On-ramp-Payment-Providers-Fees-Other-Details-a637be0644a148e39d0d12ab3cae9956?pvs=4"
        providers={providersWithStatus}
        isLoading={partnerFetching}
        partnerEmail={partnerEmail}
      />
      <FaqSection faqData={ON_RAMP.faq} />
    </PagesWrapper>
  );
};
