import * as React from 'react';
import { hasElementsInArray } from '../helpers/has-elements-in-array';
import { safeToUpperCase } from '../helpers/safe-to-upper-case';
import { FIAT_HIDDEN_CURRENCIES } from '../constants/currencies-constants';

/**
 * @param { Array<object> } currencies
 * @param { Boolean } filterForPayments
 * @param { Boolean } filterHidden
 * @returns {{ fiatCurrencies: Array<object> }}
 */
export const useFiatCurrencies = (currencies = [], filterForPayments = false, filterHidden = true) => {
  const fiatCurrencies = React.useMemo(() => {
    if (!hasElementsInArray(currencies)) {
      return [];
    }

    const currenciesFiltered = currencies.filter((currency) => {
      const isFiat = currency.isFiat;
      const isVisible = !FIAT_HIDDEN_CURRENCIES.includes(safeToUpperCase(currency.code));

      return isFiat && (filterHidden ? isVisible : true);
    });

    if (filterForPayments) {
      return currenciesFiltered.filter((currency) => currency.enableForPayment);
    }

    return currenciesFiltered;
  }, [currencies, filterForPayments]);

  return { fiatCurrencies };
};
