import * as React from 'react';
import { getMassPayoutInfoItems } from '../../../helpers/get-mass-payout-info-items';

export const useMassPayoutInfo = (reFetchWithdrawals) => {
  const [info, setInfo] = React.useState(null);
  const [is2faPopupShow, setIs2faPopupShow] = React.useState(false);

  const infoItems = React.useMemo(() => (
    getMassPayoutInfoItems(info)
  ), [info]);

  const handleInfoPopupClose = () => {
    setInfo(null);
  };
  const handle2faPopupToggle = () => {
    setIs2faPopupShow((prevState) => !prevState);
  };
  const handleCallback2fa = React.useCallback(async (isVerified) => {
    setInfo((prevState) => ({
      ...prevState,
      isVerified,
    }));
    await reFetchWithdrawals();
    setIs2faPopupShow(false);
  }, [reFetchWithdrawals]);

  return {
    isInfoPopupShow: Boolean(info),
    is2faPopupShow,
    infoItems,
    massPayoutId: info?.id,
    massPayoutBatchWithdrawalId: info?.batchWithdrawalId || null,
    isMassPayoutVerified: info?.isVerified || false,
    setInfo,
    handleInfoPopupClose,
    handle2faPopupToggle,
    handleCallback2fa,
  };
};
