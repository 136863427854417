import * as React from 'react';
import { saveToPdf } from '../../../helpers/save-to-pdf';
import { withdrawalHeaders } from '../../../constants/app-constants';
import { createBodyForExportDocument } from '../helpers';
import { saveToDifferentFileFormat } from '../../../helpers/save-to-different-file-format';

const DOCUMENT_NAME = 'NowPayments';

export const useExportState = (massPayouts) => {
  const [isExportModalShow, setIsExportModalShow] = React.useState(false);

  const handleExportModalToggle = () => {
    setIsExportModalShow((prevState) => !prevState);
  };
  const exportPDF = React.useCallback(() => {
    saveToPdf({
      name: DOCUMENT_NAME,
      headerData: [withdrawalHeaders],
      bodyData: createBodyForExportDocument(massPayouts),
      columnOptions: {
        0: { cellWidth: 25 },
        1: { cellWidth: 25 },
        2: { cellWidth: 25 },
        3: { cellWidth: 25 },
        4: { cellWidth: 46 },
        5: { cellWidth: 46 },
        6: { cellWidth: 25 },
        7: { cellWidth: 25 },
        8: { cellWidth: 25 },
        9: { cellWidth: 25 },
      },
      orientation: 'landscape',
    });
  }, [massPayouts]);
  const exportDifferentFormat = React.useCallback((format) => {
    saveToDifferentFileFormat({
      name: DOCUMENT_NAME,
      format: format,
      headerData: [withdrawalHeaders],
      bodyData: createBodyForExportDocument(massPayouts),
    });
  }, [massPayouts]);

  return {
    isExportModalShow,
    handleExportModalToggle,
    exportPDF,
    exportDifferentFormat,
  };
};
