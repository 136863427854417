import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Popup } from '../../popup';
import { Button } from '../../ui/button';
import classes from './styles.module.scss';

export const PopupSuccess = (props) => {
  const {
    className,
    open,
    onClose,
    title,
    text,
    buttonText,
  } = props;

  return (
    <Popup
      classNameContent={cn(classes.popupSuccess, className)}
      open={open}
      onClose={onClose}
      withCloseButton
    >
      {title && (
        <div className={classes.title}>
          {title}
        </div>
      )}
      {text && (
        <div
          className={classes.text}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      )}
      <Button
        className={classes.button}
        onClick={onClose}
      >
        {buttonText}
      </Button>
    </Popup>
  );
};

PopupSuccess.defaultProps = {
  className: null,
  open: false,
  onClose: null,
  title: null,
  text: null,
  buttonText: 'OK',
};

PopupSuccess.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.node,
  buttonText: PropTypes.string,
};
