import * as React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';
import { signUpMapFn } from '../../helpers/account/map-fns';

export const useRegisterWithGoogle = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (options) => {
    const access_token = options?.accessToken ?? null;
    const isAgreeSubs = options?.isAgreeSubs ?? false;
    const link_id = options?.linkId || null;
    const data = { access_token, isAgreeSubs };

    if (link_id) {
      data.link_id = link_id;
    }

    const [cancelToken] = getRequestSource();
    const requestPromise = client.post(
      '/register-google',
      { ...data },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      signUpMapFn,
    );
  }, [getRequestSource]);
};
