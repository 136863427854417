export const SUBSCRIPTION_USER_PAGE_PER_PAGE = 20;

export const SUBSCRIPTION_USER_PAGE_INFO_THS_DESKTOP = [{
  id: 1,
  name: 'User',
}, {
  id: 2,
  name: 'Status',
}, {
  id: 3,
  name: 'Subscribed',
}, {
  id: 4,
  name: 'Updated',
}];

export const SUBSCRIPTION_USER_PAGE_INFO_THS_MOBILE = [{
  id: 1,
  name: 'User / Status',
}, {
  id: 2,
  name: 'Subscribed / Updated',
}];

export const SUBSCRIPTION_USER_PAGE_THS_DESKTOP = [{
  id: 1,
  name: 'Payment ID / Invoice ID',
}, {
  id: 2,
  name: 'Amount sent',
}, {
  id: 3,
  name: 'Amount received',
}, {
  id: 4,
  name: 'Status',
}, {
  id: 5,
  name: 'Created at',
}, {
  id: 6,
  name: 'Status updated',
}];

export const SUBSCRIPTION_USER_PAGE_THS_MOBILE = [{
  id: 1,
  name: 'Payment ID / Invoice ID',
}, {
  id: 2,
  name: 'Amount sent / Amount received',
}, {
  id: 3,
  name: 'Status',
}];

export const SUBSCRIPTION_USER_PAGE_RESET_TEMPLATE = {
  feePaidByUser: null,
  fixedRate: null,
  outcomePrice: '',
  payinAddress: '',
  payinHash: '',
  status: '',
};
