import * as React from 'react';
import apiClient from '../../../api/api-client';
import { isNeedStatusUpdate } from '../../../helpers/is-need-status-update';
import { isFunction } from '../../../helpers/is-function';

const INTERVAL_TIME = 4000;

export const useObservePayment = () => {
  const updateTimeoutRef = React.useRef(null);
  const paymentRef = React.useRef(null);

  const stopObserve = () => {
    clearTimeout(updateTimeoutRef.current);
    updateTimeoutRef.current = null;
    paymentRef.current = null;
  };

  /**
   * @param {(payment: Payment, done: boolean) => void} onPaymentUpdate 
   * @returns 
   */
  const observeIteration = (onPaymentUpdate) => {
    const currentPayment = paymentRef.current;
    const isNeedUpdate = isNeedStatusUpdate(currentPayment?.status);

    if (!isNeedUpdate) {
      if (currentPayment) {
        onPaymentUpdate(currentPayment, true);
      }
      stopObserve();
      
      return;
    }

    updateTimeoutRef.current = setTimeout(async () => {
      const paymentResponse = await apiClient.getPaymentById(currentPayment.id);

      if (Object.keys(paymentResponse).length) {
        paymentRef.current = paymentResponse;
        onPaymentUpdate(paymentResponse, false);
      }

      observeIteration(onPaymentUpdate);
    }, INTERVAL_TIME);
  };


  /**
   * @param {Payment} payment 
   * @param {(payment: Payment, done: boolean) => void} onPaymentUpdate 
   * @returns 
   */
  const startObserve = (payment, onPaymentUpdate) => {
    if (Boolean(updateTimeoutRef.current) || Boolean(paymentRef.current)) {
      stopObserve();
    }

    if (!payment || !isFunction(onPaymentUpdate)) {
      return;
    }

    paymentRef.current = payment;
    observeIteration(onPaymentUpdate);
  };

  React.useEffect(() => stopObserve, []);

  return {
    startObserve,
    stopObserve,
  };
};