import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Popup } from '../../../popup';
import { CustomerDepositOrWithdrawForm } from '../customer-deposit-or-withdraw-form';
import { CUSTOMER_FORM_NAMES, getCurrentDataForForm } from '../../helpers';
import { CustomerCreateBillingPlanForm } from '../customer-create-billing-plan-form';
import { CustomerSaveBillingPlanForm } from '../customer-save-billing-plan-form';
import { isFunction } from '../../../../helpers/is-function';
import { CustomerActiveBillingPlans } from '../customer-active-billing-plans';
import { CustomerPageContext } from '../../customer-page';
import classes from './styles.module.scss';

export const PopupCustomerForms = (props) => {
  const { className } = props;

  const customerPageContext = React.useContext(CustomerPageContext);
  const {
    open,
    onClose,
    activeFormName,
    partnerBalances,
    subPartnerBalances,
    onActiveFormNameChange,
  } = customerPageContext;

  const isDepositShow = activeFormName === CUSTOMER_FORM_NAMES.DEPOSIT;
  const isWithdrawalShow = activeFormName === CUSTOMER_FORM_NAMES.WITHDRAW;
  const isDepositOrWithdrawFormShow = isDepositShow || isWithdrawalShow;
  const isCreateBillingPlanShow = activeFormName === CUSTOMER_FORM_NAMES.CREATE_BILLING_PLAN;
  const isSaveBillingPlanShow = activeFormName === CUSTOMER_FORM_NAMES.SAVE_BILLING_PLAN;
  const isActiveBillingPlanShow = activeFormName === CUSTOMER_FORM_NAMES.ACTIVE_BILLING_PLAN;

  const {
    title,
    currencies,
    defaultTicker,
    buttonText,
    tabsShow,
  } = React.useMemo(() => (
    getCurrentDataForForm(activeFormName, partnerBalances, subPartnerBalances)
  ), [activeFormName, partnerBalances, subPartnerBalances]);

  const handleFormNameChange = (name) => () => {
    if (isFunction(onActiveFormNameChange)) {
      onActiveFormNameChange(name);
    }
  };

  return (
    <Popup
      classNameContent={cn(classes.customerPopup, className)}
      open={open}
      onClose={onClose}
      withCloseButton
      mobileFullView
      line
      title={title}
    >
      {isDepositOrWithdrawFormShow && (
        <CustomerDepositOrWithdrawForm
          currencies={currencies}
          defaultTicker={defaultTicker}
          buttonText={buttonText}
          tabsShow={tabsShow}
        />
      )}
      {isCreateBillingPlanShow && (
        <CustomerCreateBillingPlanForm
          onButtonClick={handleFormNameChange(CUSTOMER_FORM_NAMES.SAVE_BILLING_PLAN)}
        />
      )}
      {isSaveBillingPlanShow && (
        <CustomerSaveBillingPlanForm
          className={classes.saveBillingPlanForm}
          onButtonClick={handleFormNameChange(CUSTOMER_FORM_NAMES.CREATE_BILLING_PLAN)}
        />
      )}
      {isActiveBillingPlanShow && (
        <CustomerActiveBillingPlans />
      )}
    </Popup>
  );
};

PopupCustomerForms.defaultProps = {
  className: null,
};

PopupCustomerForms.propTypes = {
  className: PropTypes.string,
};
