import * as React from 'react';
import PropTypes from 'prop-types';
import { Popup } from '../../../popup';
import { PaymentsInfoBox } from '../../../payments-info-box';
import { PaymentsInfoButtons } from '../../../payments-info-buttons';

export const PopupPaymentInfo = (props) => {
  const {
    className,
    open,
    onClose,
    subscriptionName,
    items,
  } = props;

  return (
    <Popup
      classNameContent={className}
      open={open}
      onClose={onClose}
      withCloseButton
    >
      <PaymentsInfoBox
        title="Plan Name"
        id={subscriptionName}
        items={items}
      />
      <PaymentsInfoButtons onClose={onClose} />
    </Popup>
  );
};

PopupPaymentInfo.defaultProps = {
  className: null,
  open: null,
  onClose: null,
  subscriptionName: null,
  items: null,
};

PopupPaymentInfo.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  subscriptionName: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
};
