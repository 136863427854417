import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import OauthPermissions from "../../../../../components/oauth-permissions";
import SubmitButton from "../../../../../components/submit-button";
import TextArea from "../../../../../components/ui/textarea";
import Input from "../../../../../components/input";
import { acc_front_domain } from "../../../../../config";
import styles from "./styles.module.scss";

let timer = 0;

const generateLink = (clientId, redirectUrl) => {
  return `${acc_front_domain}/oauth?client_id=${clientId}&state=2021&response_type=code&redirect_uri=${redirectUrl}`;
};

const ModalView = ({ isShow, onShow, service }) => {
  const divRef = React.useRef(null);
  const [isCoppied, setIsCoppied] = React.useState(false);

  const handleCancelClick = () => {
    onShow(false);
  };

  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      onShow(false);
    }
  };

  const handleCopyClick = () => {
    setIsCoppied(true);

    timer = setTimeout(() => {
      setIsCoppied(false);
    }, 1000);
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      clearTimeout(timer);
    };
  }, []);

  if (!isShow) {
    return null;
  }

  return (
    <div className={styles.externalWrapper}>
      <div className={styles.wrapper} ref={divRef}>
        <h3 className={styles.title}>{service.name}</h3>
        <div className={styles.middle}>
          <div className={styles.leftBlock}>
            <div className={styles.infoBlock}>
              <h4>Name</h4>
              <Input
                placeholder="App Name"
                value={service.name}
                readOnly={true}
                name="app_name"
              />
              <h4>Description</h4>
              <TextArea
                placeholder="App Description"
                value={service.description}
                readOnly={true}
              />
            </div>
          </div>
          <div className={styles.rightBlock}>
            <OauthPermissions
              permissions={service.permissions}
              isReadOnly={true}
              view={true}
            />
          </div>
        </div>
        <div className={styles.linkBlock}>
          <h4>Link</h4>
          <div className={styles.linkRow}>
            <Input
              value={generateLink(service.id, service.redirect_url)}
              readOnly={true}
              styleCustom={{ width: "90%" }}
              name="link"
            />
            {!isCoppied && (
              <CopyToClipboard
                text={generateLink(service.id, service.redirect_url)}
                onCopy={handleCopyClick}
              >
                <span className={styles.copyLink}>Copy</span>
              </CopyToClipboard>
            )}
            {isCoppied && (
              <FontAwesomeIcon
                icon={faCheckSquare}
                size="1x"
                className={styles.coppiedIcon}
              />
            )}
          </div>
        </div>
        <div className={styles.endButtons}>
          <SubmitButton
            className={styles.btnCancel}
            buttonState="normal"
            onSubmit={handleCancelClick}
          >
            Cancel
          </SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default ModalView;
