import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { partnerSelector } from '../store/partner/selectors';
import {
  setPaymentSettingsFetching,
  setApiKeys,
  setHasActiveApiKey,
  setApiKeysFetched,
  setApiKeysError,
} from '../store/payment-settings/reducer';
import { getSafeErrorMessageText } from '../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../helpers/console-error-message';
import { apiKeysFetchedSelector } from '../store/payment-settings/selectors';
import { useApiKeys } from '../api/modules/account/use-api-keys';
import { hasActiveApiKey } from '../helpers/has-active-api-key';
import { useTimeout } from './use-timeout';

export const useApiKeysData = () => {
  const dispatch = useDispatch();
  const partner = useSelector(partnerSelector());
  const apiKeysFetched = useSelector(apiKeysFetchedSelector());
  const fetchApiKeys = useApiKeys();

  const fetchKeys = React.useCallback(async () => {
    if (!partner || apiKeysFetched) {
      return;
    }

    dispatch(setPaymentSettingsFetching(true));
    const { data, status, errorMessage } = await fetchApiKeys();

    if (status === 200) {
      const keys = data?.keys ?? [];
      dispatch(setApiKeys(keys));
      dispatch(setHasActiveApiKey(hasActiveApiKey(keys)));
      dispatch(setApiKeysFetched(true));
      dispatch(setPaymentSettingsFetching(false));
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/api-keys');
      dispatch(setApiKeysError(`Api keys: ${errorDataMessage}`));
      dispatch(setPaymentSettingsFetching(false));
    }
  }, [partner, apiKeysFetched]);

  useTimeout(fetchKeys, 0, fetchKeys);
};
