import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initial-state';

export const CURRENCIES_REDUCER_NAME = 'currencies';

const setCurrencyListState = (state, { payload }) => {
  state.currencyList = payload;
};
const setAvailableCurrenciesState = (state, { payload }) => {
  state.availableCurrencies = payload;
};
const setFiatCurrenciesState = (state, { payload }) => {
  state.fiatCurrencies = payload;
};
const setSelectedTickersState = (state, { payload }) => {
  state.selectedTickers = payload;
};
const setFetchingState = (state, { payload }) => {
  state.fetching = payload;
};
const setFetchedState = (state, { payload }) => {
  state.fetched = payload;
};
const setErrorState = (state, { payload }) => {
  state.error = payload;
};

export const currenciesSlice = createSlice({
  name: CURRENCIES_REDUCER_NAME,
  initialState,
  reducers: {
    setCurrencyList: setCurrencyListState,
    setAvailableCurrencies: setAvailableCurrenciesState,
    setFiatCurrencies: setFiatCurrenciesState,
    setSelectedTickers: setSelectedTickersState,
    setFetching: setFetchingState,
    setFetched: setFetchedState,
    setError: setErrorState,
  },
});

export const {
  setCurrencyList,
  setAvailableCurrencies,
  setFiatCurrencies,
  setSelectedTickers,
  setFetching,
  setFetched,
  setError,
} = currenciesSlice.actions;
export const currenciesReducer = currenciesSlice.reducer;
