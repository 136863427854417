import * as React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';

const mapFnItem = (data) => ({
  amountReceived: data?.amount_received ?? null,
  burningPercent: data?.burning_percent ?? null,
  expirationEstimateDate: data?.expiration_estimate_date ?? null,
  ipnCallbackUrl: data?.ipn_callback_url ?? null,
  network: data?.network ?? null,
  networkPrecision: data?.network_precision ?? null,
  orderDescription: data?.order_description ?? null,
  orderId: data?.order_id ?? null,
  payAddress: data?.pay_address ?? null,
  payAmount: data?.pay_amount ?? null,
  payCurrency: data?.pay_currency ?? null,
  invoiceId: data?.invoice_id ?? null,
  paymentId: data?.payment_id ?? null,
  paymentStatus: data?.payment_status ?? null,
  priceAmount: data?.price_amount ?? null,
  priceCurrency: data?.price_currency ?? null,
  purchaseId: data?.purchase_id ?? null,
  smartContract: data?.smart_contract ?? null,
  timeLimit: data?.time_limit ?? null,
  type: data?.type ?? null,
  createdAt: data?.created_at ?? null,
  updatedAt: data?.updated_at ?? null,
  validUntil: data?.valid_until ?? null,
  isFeePaidByUser: data?.is_fee_paid_by_user ?? false,
  isFixedRate: data?.is_fixed_rate ?? false,
  payinExtraId: data?.payin_extra_id ?? null,
});
const mapFn = (data) => ({
  result: mapFnItem(data?.result ?? {}),
});

export const useCreateSubPartnerPayment = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (options) => {
    const sub_partner_id = options?.customerId ?? null;
    const amount = options?.amount ?? null;
    const currency = options?.ticker ?? null;

    const [cancelToken] = getRequestSource();
    const requestPromise = client.post(
      '/sub-partners/payment',
      {
        sub_partner_id,
        amount,
        currency,
      },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
