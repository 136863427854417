import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { New2faWithdrawal } from '../new-2fa-withdrawal';
import { Popup } from '../popup';
import { New2fa } from '../new-2fa';
import classes from './styles.module.scss';

export const New2faPopup = (props) => {
  const {
    className,
    open,
    onClose,
    onUpdate,
    withdrawalId,
    is2faCode,
    pending,
    apiErrorMessage,
  } = props;

  return (
    <Popup
      classNameContent={cn(classes.new2FAPopup, className)}
      open={open}
      onClose={onClose}
    >
      {!is2faCode && (
        <New2faWithdrawal
          onUpdate={onUpdate}
          onClose={onClose}
          withdrawalId={withdrawalId}
        />
      )}
      {is2faCode && (
        <New2fa
          onClose={onClose}
          onUpdate={onUpdate}
          pending={pending}
          apiErrorMessage={apiErrorMessage}
        />
      )}
    </Popup>
  );
};

New2faPopup.defaultProps = {
  className: null,
  open: false,
  onClose: null,
  onUpdate: null,
  withdrawalId: null,
  is2faCode: false,
  pending: false,
  apiErrorMessage: null,
};

New2faPopup.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
  withdrawalId: PropTypes.string,
  is2faCode: PropTypes.bool,
  pending: PropTypes.bool,
  apiErrorMessage: PropTypes.string,
};
