import { createSlice } from '@reduxjs/toolkit';
import { isObject } from '../../helpers/is-object';
import { initialState } from './initial-state';

export const MARKET_INFO_REDUCER_NAME = 'marketInfo';

const setMarketInfoCodeState = (state, { payload }) => {
  state.code = payload;
};
const setMarketInfoFromCurrencyState = (state, { payload }) => {
  state.fromCurrency = payload;
};
const setMarketInfoRatesState = (state, { payload }) => {
  state.result = payload;
};
const setMarketInfoNextRatesState = (state, { payload }) => {
  state.result = isObject(state.result) ? { ...state.result, ...payload } : payload;
};
const setMarketInfoUnprocessedRatesState = (state, { payload }) => {
  state.unprocessed = payload;
};
const setMarketInfoErrorState = (state, { payload }) => {
  state.error = payload;
};
const setMarketInfoFetchingState = (state, { payload }) => {
  state.fetching = payload;
};
const setMarketInfoFetchedState = (state, { payload }) => {
  state.fetched = payload;
};

export const marketInfoSlice = createSlice({
  name: MARKET_INFO_REDUCER_NAME,
  initialState,
  reducers: {
    setMarketInfoCode: setMarketInfoCodeState,
    setMarketInfoFromCurrency: setMarketInfoFromCurrencyState,
    setMarketInfoRates: setMarketInfoRatesState,
    setMarketInfoNextRates: setMarketInfoNextRatesState,
    setMarketInfoUnprocessedRates: setMarketInfoUnprocessedRatesState,
    setMarketInfoError: setMarketInfoErrorState,
    setMarketInfoFetching: setMarketInfoFetchingState,
    setMarketInfoFetched: setMarketInfoFetchedState,
  },
});

export const {
  setMarketInfoCode,
  setMarketInfoFromCurrency,
  setMarketInfoRates,
  setMarketInfoNextRates,
  setMarketInfoUnprocessedRates,
  setMarketInfoError,
  setMarketInfoFetching,
  setMarketInfoFetched,
} = marketInfoSlice.actions;
export const marketInfoReducer = marketInfoSlice.reducer;
