import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  depositAddressesFetchedSelector,
  depositAddressesSelector,
} from '../store/payment-settings/selectors';
import { getSafeErrorMessageText } from '../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../helpers/console-error-message';
import { useCreateDepositAddress } from '../api/modules/account/use-create-deposit-address';
import { DEPOSIT_ADDRESSES_NETWORKS } from '../constants/app-constants';
import { safeToLowerCase } from '../helpers/safe-to-lower-case';
import { setDepositAddressesFetched } from '../store/payment-settings/reducer';
import { hasElementsInArray } from '../helpers/has-elements-in-array';
import { PermanentDepositAddress, ResponseType } from '../default-types';

export const useGetDepositAddressByNetwork = (network: string | null) => {
  const depositAddresses = useSelector(depositAddressesSelector());
  const isDepositAddressesFetched = useSelector(depositAddressesFetchedSelector());
  const dispatch = useDispatch();

  const [address, setAddress] = React.useState<string | null>(null);
  const [isAddressFetching, setIsAddressFetching] = React.useState(false);
  const [addressError, setAddressError] = React.useState<string | null>(null);

  const fetchCreateDepositAddress = useCreateDepositAddress();
  const resetAddressError = () => {
    setAddressError(null);
  };

  const createAddress = React.useCallback(async () => {
    if (!network) return;
    setIsAddressFetching(true);
    const {
      data,
      status,
      errorMessage,
    }: ResponseType = await fetchCreateDepositAddress(network);

    if (status === 200 && data?.result?.address) {
      setAddress(data.result.address);
      dispatch(setDepositAddressesFetched(false));
    } else {
      const currentErrorMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, currentErrorMessage, '/deposits/addresses');
      setAddressError(currentErrorMessage);
    }
    setIsAddressFetching(false);
  }, [network]);

  React.useEffect(() => {
    if (!network || isAddressFetching || !isDepositAddressesFetched) return;

    const isPDANetwork = DEPOSIT_ADDRESSES_NETWORKS.includes(safeToLowerCase(network));

    if (!isPDANetwork) return;

    const foundAddress = depositAddresses.find((el: PermanentDepositAddress) => {
      if (!hasElementsInArray(el.chains)) return false;
      return el.chains.includes(network);
    });
    if (foundAddress) {
      setAddress(foundAddress.address);
    } else {
      createAddress();
    }
  }, [depositAddresses, network]);

  return {
    address,
    isAddressFetching,
    addressError,
    resetAddressError,
  };
};
