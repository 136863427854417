import * as React from 'react';

export const StatisticsTabCalendarIcon: React.FC<{ color: string | undefined }> = (props) => {
  const { color = '#7E7E7E' } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="none">
      <g stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth=".8" clipPath="url(#a)">
        {/* eslint-disable-next-line max-len */}
        <path d="M7.917 1.667H2.083a.833.833 0 0 0-.833.833v5.833c0 .46.373.834.833.834h5.834c.46 0 .833-.373.833-.834V2.5a.833.833 0 0 0-.833-.833ZM6.667.833V2.5M3.333.833V2.5M1.25 4.167h7.5" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h10v10H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
