import * as yup from 'yup';
import { hasElementsInArray } from '../../helpers/has-elements-in-array';
import { safeToLowerCase } from '../../helpers/safe-to-lower-case';
import dateFormat from '../../libs/date-format';
import { safeToUpperCase } from '../../helpers/safe-to-upper-case';
import { getColorStatus } from '../../helpers/get-color-status';
import { formatDate } from '../../helpers/format-date';
import { STATUSES, DATE_PATTERN } from '../../constants/app-constants';
import { isObject } from '../../helpers/is-object';
import { getDateTime } from '../../helpers/get-date-time';
import { getFiatPriceFromCrypto } from '../../helpers/get-fiat-price-from-crypto';
import { isCurrencyNetworkShow } from '../../helpers/is-currency-network-show';
import { getPayinHashLink } from '../../helpers/get-payin-hash-link';
import { toFixed } from '../../helpers/to-fixed';
import { getArrayWithIndexId } from '../../helpers/get-array-with-index-id';
import { OFF_RAMP_PROVIDERS } from '../../constants/providers-constants';

const DEFAULT_ERROR_TEXT = 'Internal Error: Please contact customer support';
const INVALID_IP_ERROR_PATTERN = /^Access denied \| Invalid IP.*$/;
const INVALID_ADDRESS_ERROR_PATTERN = /^Withdrawal addresses .+ is not whitelisted$/;

export const IP_ERROR_CODE = 'ip_error';

export const BALANCE_ELEMENTS_PER_PAGE = 10;

export const CONVERSION_SCHEMA = yup.object({
  fromCurrency: yup.string().required('From currency is required field'),
  toCurrency: yup.string().required('To currency is required field'),
  amount: yup.string().required('Amount is required field'),
});

export const BALANCE_TOP_UP_SCHEMA = yup.object({
  fromCurrency: yup.string().required('Balance currency is required field'),
  toCurrency: yup.string().required('Pay currency is required field'),
});

export const BALANCE_TOP_UP_SCHEMA_WITH_AMOUNT = yup.object({
  fromCurrency: yup.string().required('Balance currency is required field'),
  toCurrency: yup.string(),
  amount: yup.string().required('Amount is required field'),
});

export const HISTORY_SCHEMA = yup.object({
  status: yup.string().trim(),
  id: yup.string().trim(),
  payoutDescription: yup.string().trim(),
});

export const CONVERSION_HISTORY_SCHEMA = yup.object({
  status: yup.string().trim(),
  currencyFrom: yup.string().trim(),
  currencyTo: yup.string().trim(),
});

export const PAYOUTS_HISTORY_NAME = 'payouts-history';
export const CONVERSION_HISTORY_NAME = 'conversion-history';
export const FIAT_HISTORY_NAME = 'fiat-history';

const PAYOUTS_HISTORY_TH_ITEMS = [{
  id: 1,
  name: 'ID',
}, {
  id: 2,
  name: 'Amount',
}, {
  id: 3,
  name: 'Address',
}, {
  id: 4,
  name: 'Created at',
}, {
  id: 5,
  name: 'Status updated',
}, {
  id: 6,
  name: 'Status',
}];

const CONVERSION_HISTORY_TH_ITEMS = [{
  id: 1,
  name: 'ID',
}, {
  id: 2,
  name: 'Amount from',
}, {
  id: 3,
  name: 'Amount to',
}, {
  id: 4,
  name: 'Created at',
}, {
  id: 5,
  name: 'Status updated',
}, {
  id: 6,
  name: 'Status',
}];

const FIAT_HISTORY_TH_ITEMS = [{
  id: 1,
  name: 'ID',
}, {
  id: 2,
  name: 'Amount',
}, {
  id: 3,
  name: 'To bank account',
}, {
  id: 4,
  name: 'Created at',
}, {
  id: 5,
  name: 'Status updated',
}, {
  id: 6,
  name: 'Status',
}];

export const HISTORY_OPTIONS_OBJECT = {
  [PAYOUTS_HISTORY_NAME]: {
    thItems: PAYOUTS_HISTORY_TH_ITEMS,
    placeholder: 'ID, ticker, address...',
    emptyTableText: 'There are no transactions yet.',
  },
  [CONVERSION_HISTORY_NAME]: {
    thItems: CONVERSION_HISTORY_TH_ITEMS,
    placeholder: 'ID, ticker',
    emptyTableText: "There are no conversions yet.",
  },
  [FIAT_HISTORY_NAME]: {
    thItems: FIAT_HISTORY_TH_ITEMS,
    placeholder: 'ID, ticker, bank account...',
    emptyTableText: 'There are no transactions yet.',
  },
};

/**
 * @param {array<Object>} data
 * @param {string} searchText
 * @returns {Array}
 */
export const getFilteredDataWithSearch = (data, searchText) => {
  const hasData = hasElementsInArray(data);

  if (!hasData) {
    return [];
  }

  if (!searchText) {
    return data;
  }

  const searchTextLowerCased = safeToLowerCase(searchText);

  return data.filter((item) => {
    const id = item?.id ?? '';
    const ticker = item?.currency ?? '';
    const address = item?.address ?? '';

    const idLowerCased = safeToLowerCase(id);
    const tickerLowerCased = safeToLowerCase(ticker);

    return idLowerCased.indexOf(searchTextLowerCased) > -1
      || tickerLowerCased.indexOf(searchTextLowerCased) > -1
      || address.indexOf(searchText) > -1;
  });
};

/**
 * @param {array} data
 * @param {object} formData
 * @returns {array}
 */
export const getFilteredDataWithFilter = (data, formData) => {
  const hasData = hasElementsInArray(data);

  if (!hasData) {
    return [];
  }

  const dateFrom = formData?.date?.from ?? null;
  const dateTo = formData?.date?.to ?? null;
  const currentDateTo = dateTo || dateFrom;
  const dateFromTime = getDateTime(dateFrom, 'date');
  const dateToTime = getDateTime(currentDateTo, 'date');
  const searchStatus = formData?.status ?? '';
  const searchId = formData?.id ?? '';
  const searchPayoutDescription = formData?.payoutDescription ?? '';

  return data.filter((item) => {
    const itemCreatedAt = item?.createdAt ?? '';
    const itemCreatedAtTime = getDateTime(itemCreatedAt, 'date');
    const itemStatus = item?.status ?? '';
    const itemStatusLowerCased = safeToLowerCase(itemStatus);
    const itemId = item?.id ?? '';
    const itemPayoutDescription = item?.payoutDescription ?? '';
    const isDatesMatch = itemCreatedAtTime >= dateFromTime && itemCreatedAtTime <= dateToTime;
    const isStatusMatch = itemStatusLowerCased === searchStatus;
    const isIdMatch = itemId.startsWith(searchId);
    const isPayoutDescriptionMatch = itemPayoutDescription.includes(searchPayoutDescription);
    const isDatesNotFound = (Boolean(dateFrom) && Boolean(currentDateTo)) && !isDatesMatch;
    const isStatusNotFound = Boolean(searchStatus) && !isStatusMatch;
    const isIdNotFound = Boolean(searchId) && !isIdMatch;
    const isPayoutDescriptionNotFound = Boolean(searchPayoutDescription) && !isPayoutDescriptionMatch;

    const isNotAllowed = (
      isDatesNotFound
      || isStatusNotFound
      || isIdNotFound
      || isPayoutDescriptionNotFound
    );

    return !isNotAllowed;
  });
};

/**
 * @param {array<Object>} data
 * @param {string} searchText
 * @returns {Array}
 */
export const getConversionFilteredDataWithSearch = (data, searchText) => {
  const hasData = hasElementsInArray(data);

  if (!hasData) {
    return [];
  }

  if (!searchText) {
    return data;
  }

  const searchTextLowerCased = safeToLowerCase(searchText);

  return data.filter((item) => {
    const id = item?.id ?? '';
    const tickerFrom = item?.currencyFrom ?? '';
    const tickerTo = item?.currencyTo ?? '';

    const idLowerCased = safeToLowerCase(id);
    const tickerFromLowerCased = safeToLowerCase(tickerFrom);
    const tickerToLowerCased = safeToLowerCase(tickerTo);

    return idLowerCased.indexOf(searchTextLowerCased) > -1
      || tickerFromLowerCased.indexOf(searchTextLowerCased) > -1
      || tickerToLowerCased.indexOf(searchText) > -1;
  });
};

/**
 * @param {array} data
 * @param {object} formData
 * @returns {array}
 */
export const getConversionFilteredDataWithFilter = (data, formData) => {
  const hasData = hasElementsInArray(data);

  if (!hasData) {
    return [];
  }

  const dateFrom = formData?.date?.from ?? null;
  const dateTo = formData?.date?.to ?? null;
  const currentDateTo = dateTo || dateFrom;
  const dateFromTime = getDateTime(dateFrom, 'date');
  const dateToTime = getDateTime(currentDateTo, 'date');
  const searchCurrencyFrom = formData?.currencyFrom ?? '';
  const searchCurrencyFromLowerCased = safeToLowerCase(searchCurrencyFrom);
  const searchCurrencyTo = formData?.currencyTo ?? '';
  const searchCurrencyToLowerCased = safeToLowerCase(searchCurrencyTo);
  const searchStatus = formData?.status ?? '';

  return data.filter((item) => {
    const itemCreatedAt = item?.createdAt ?? '';
    const itemCreatedAtTime = getDateTime(itemCreatedAt, 'date');
    const itemStatus = item?.status ?? '';
    const itemStatusLowerCased = safeToLowerCase(itemStatus);
    const itemCurrencyFrom = item?.currencyFrom ?? '';
    const itemCurrencyFromLowerCased = safeToLowerCase(itemCurrencyFrom);
    const itemCurrencyTo = item?.currencyTo ?? '';
    const itemCurrencyToLowerCased = safeToLowerCase(itemCurrencyTo);
    const isDatesMatch = itemCreatedAtTime >= dateFromTime && itemCreatedAtTime <= dateToTime;
    const isStatusMatch = itemStatusLowerCased === searchStatus;
    const isCurrencyFromMatch = itemCurrencyFromLowerCased.startsWith(searchCurrencyFromLowerCased);
    const isCurrencyToMatch = itemCurrencyToLowerCased.startsWith(searchCurrencyToLowerCased);
    const isDatesNotFound = (Boolean(dateFrom) && Boolean(currentDateTo)) && !isDatesMatch;
    const isStatusNotFound = Boolean(searchStatus) && !isStatusMatch;
    const isCurrencyFromNotFound = Boolean(searchCurrencyFromLowerCased) && !isCurrencyFromMatch;
    const isCurrencyToNotFound = Boolean(searchCurrencyToLowerCased) && !isCurrencyToMatch;

    const isNotAllowed = (
      isDatesNotFound ||
      isStatusNotFound ||
      isCurrencyFromNotFound ||
      isCurrencyToNotFound
    );

    return !isNotAllowed;
  });
};

/**
 * @param {array<Object>} data
 * @param {string} searchText
 * @returns {Array}
 */
export const getFilteredFiatDataWithSearch = (data, searchText) => {
  const hasData = hasElementsInArray(data);

  if (!hasData) {
    return [];
  }

  if (!searchText) {
    return data;
  }

  const searchTextLowerCased = safeToLowerCase(searchText);

  return data.filter((item) => {
    const id = item?.id ?? '';
    const ticker = item?.cryptoCurrencyCode ?? '';
    const bankAccount = item?.fiatAccountNumber ?? '';

    const idLowerCased = safeToLowerCase(id);
    const tickerLowerCased = safeToLowerCase(ticker);

    return idLowerCased.indexOf(searchTextLowerCased) > -1
      || tickerLowerCased.indexOf(searchTextLowerCased) > -1
      || bankAccount.indexOf(searchText) > -1;
  });
};

/**
 * @param data {Array}
 * @returns {[]}
 */
export const createBodyForDocument = (data) => {
  const hasData = hasElementsInArray(data);

  if (!hasData) {
    return [];
  }

  return data.map((item) => ([
    item?.id ?? '',
    item?.batchWithdrawalId ?? '',
    item?.amount ?? '',
    item?.currency ?? '',
    item?.address ?? '',
    item?.hash ?? '',
    item?.status ?? '',
    dateFormat(item?.createdAt ?? ''),
    dateFormat(item?.updatedAt ?? ''),
  ]));
};

/**
 * @param data {Array}
 * @returns {[]}
 */
export const createBodyForConversionDocument = (data) => {
  const hasData = hasElementsInArray(data);

  if (!hasData) {
    return [];
  }

  return data.map((item) => ([
    item?.id ?? '',
    item?.currencyFrom ?? '',
    item?.currencyTo ?? '',
    item?.hash ?? '',
    item?.status ?? '',
    dateFormat(item?.createdAt ?? ''),
    dateFormat(item?.updatedAt ?? ''),
  ]));
};

/**
 * @param {Object} data
 * @returns {String}
 */
const getFilterNamesText = (data) => {
  const dataNormalized = isObject(data) ? Object.entries(data) : [];
  const dataFiltered = dataNormalized.filter(([, value]) => value);

  return dataFiltered.reduce((acc, [key, value], index) => {
    const delimiter = index === dataFiltered.length - 1 ? '' : '_';
    const currentKey = key === 'createdAt' ? 'Date' : key;
    const isCurrentKeyString = typeof currentKey === 'string';
    const keyString = isCurrentKeyString
      ? currentKey.charAt(0).toUpperCase() + currentKey.slice(1)
      : '';
    const currentName = keyString ? `${keyString}${delimiter}` : '';

    acc += currentName;

    return acc;
  }, '');
};

/**
 * @param {Boolean} isConversionActive
 * @param {Object} filterData
 * @returns {String}
 */
export const getCurrentExportName = (isConversionActive, filterData) => {
  const historyName = isConversionActive ? 'ConversionHistory' : 'PayoutsHistory';
  const filterNamesText = getFilterNamesText(filterData);
  const currentTemplate = filterNamesText ? `${historyName}_${filterNamesText}` : historyName;

  return `NowPayments_${currentTemplate}`;
};

/**
 * @param {String} message
 * @returns {string|*}
 */
const getNormalizedErrorMessageForInfoItems = (message) => {
  const hasRewardWord = typeof message === 'string'
    ? message.includes('reward')
    : false;

  return hasRewardWord ? DEFAULT_ERROR_TEXT : message;
};

/**
 * @param {object} payment
 * @param {object} currenciesObject
 * @returns {[]}
 */
export const getPaymentInfoItems = (payment, currenciesObject) => {
  const batchWithdrawalId = payment?.batchWithdrawalId ?? null;
  const extraId = payment?.extraId ?? null;
  const amount = payment?.amount ?? 0;
  const ticker = payment?.currency ?? '';
  const tickerUpperCased = safeToUpperCase(ticker);
  const address = payment?.address ?? null;
  const hash = payment?.hash ?? '';
  const status = payment?.status ?? null;
  const statusLowerCased = safeToLowerCase(status);
  const statusColor = status && getColorStatus(statusLowerCased);
  const statusNode = status ? `<span style="color: ${statusColor}">${statusLowerCased}</span>` : null;
  const createdDate = payment?.createdAt ?? null;
  const createdDateNormalized = createdDate ? formatDate(createdDate, DATE_PATTERN) : null;
  const updatedDate = payment?.updatedAt ?? null;
  const updatedDateNormalized = updatedDate ? formatDate(updatedDate, DATE_PATTERN) : null;
  const currencyFound = currenciesObject[tickerUpperCased] || {};
  const hashLink = getPayinHashLink(currencyFound?.explorer_link_hash, hash);
  const isErrorMessageShow = statusLowerCased === STATUSES.FAILED || statusLowerCased === STATUSES.REJECTED;
  const errorMessage = payment?.error ?? null;
  const errorMessageNormalized = getNormalizedErrorMessageForInfoItems(errorMessage);
  const currentErrorMessage = isErrorMessageShow ? errorMessageNormalized : null;
  const currentTicker = currencyFound?.ticker ?? tickerUpperCased;
  const currentTickerUpperCased = safeToUpperCase(currentTicker);
  const amountText = `${amount} ${currentTickerUpperCased}`;
  const network = currencyFound?.network ?? null;
  const isNetworkShow = isCurrencyNetworkShow(network, ticker);
  const payoutDescription = payment?.payoutDescription ?? null;

  return getArrayWithIndexId([
    {
      key: 'Batch Withdrawal Id:',
      value: batchWithdrawalId,
      network: null,
      isShort: false,
      link: null,
      isCopied: false,
    },
    {
      key: 'ExtraId:',
      value: extraId,
      network: null,
      isShort: false,
      link: null,
      isCopied: false,
    },
    {
      key: 'Amount',
      value: amountText,
      network: isNetworkShow ? network : null,
      isShort: false,
      link: null,
      isCopied: false,
    },
    {
      key: 'Address',
      value: address,
      network: null,
      isShort: true,
      link: null,
      isCopied: true,
    },
    {
      key: 'Hash',
      value: hash,
      network: null,
      isShort: true,
      link: hashLink,
      isCopied: !hashLink,
    },
    {
      key: 'Payout Description',
      value: payoutDescription,
      network: null,
      isShort: false,
      link: null,
      isCopied: false,
    },
    {
      key: 'Status',
      value: statusNode,
      network: null,
      isShort: false,
      link: null,
      isCopied: false,
    },
    {
      key: 'Created at',
      value: createdDateNormalized,
      network: null,
      isShort: false,
      link: null,
      isCopied: false,
    },
    {
      key: 'Status updated',
      value: updatedDateNormalized,
      network: null,
      isShort: false,
      link: null,
      isCopied: false,
    },
    {
      key: 'Error message',
      value: currentErrorMessage,
      network: null,
      isShort: false,
      link: null,
      isCopied: false,
    },
  ]);
};

/**
 * @param {object} payment
 * @param {object} currenciesObject
 * @returns {[]}
 */
export const getConversionPaymentInfoItems = (payment, currenciesObject) => {
  const status = payment?.status ?? null;
  const statusLowerCased = safeToLowerCase(status);
  const statusColor = status && getColorStatus(statusLowerCased);
  const statusNode = status ? `<span style="color: ${statusColor}">${statusLowerCased}</span>` : null;
  const isStatusWaiting = statusLowerCased === STATUSES.WAITING;
  const tickerFrom = payment?.currencyFrom ?? '';
  const tickerTo = payment?.currencyTo ?? '';
  const tickerFromUpperCased = safeToUpperCase(tickerFrom);
  const tickerToUpperCased = safeToUpperCase(tickerTo);
  const amountFrom = payment?.amountFrom ?? null;
  const amountTo = payment?.amountTo ?? null;
  const amountFromNormalized = amountFrom ? toFixed(amountFrom, 8) :  '-';
  const amountToEstimating = !amountTo && isStatusWaiting ? 'Estimating' : null;
  const createdDate = payment?.createdAt ?? null;
  const createdDateNormalized = createdDate ? formatDate(createdDate, DATE_PATTERN) : null;
  const updatedDate = payment?.updatedAt ?? null;
  const updatedDateNormalized = updatedDate ? formatDate(updatedDate, DATE_PATTERN) : null;
  const isErrorMessageShow = statusLowerCased === STATUSES.FAILED || statusLowerCased === STATUSES.REJECTED;
  const errorMessage = payment?.error ?? null;
  const errorMessageNormalized = getNormalizedErrorMessageForInfoItems(errorMessage);
  const currentErrorMessage = isErrorMessageShow ? errorMessageNormalized : null;
  const currencyFromFound = currenciesObject[tickerFromUpperCased] || {};
  const currencyToFound = currenciesObject[tickerToUpperCased] || {};
  const currentTickerFrom = currencyFromFound?.ticker ?? currencyFromFound?.code;
  const currentTickerTo = currencyToFound?.ticker ?? currencyToFound?.code;
  const currentTickerFromUpperCased = safeToUpperCase(currentTickerFrom);
  const currentTickerToUpperCased = safeToUpperCase(currentTickerTo);

  const amountFromText = amountFrom
    ? `${amountFromNormalized} ${currentTickerFromUpperCased}`
    : amountFromNormalized;
  const amountToNormalized = amountTo ? `${toFixed(amountTo, 8)} ${currentTickerToUpperCased}` : '-';
  const amountToText = amountToEstimating || amountToNormalized;

  const networkFrom = currencyFromFound?.network ?? null;
  const networkTo = currencyToFound?.network ?? null;
  const isNetworkFromShow = isCurrencyNetworkShow(networkFrom, tickerFrom);
  const isNetworkToShow = isCurrencyNetworkShow(networkTo, tickerTo) && !amountToEstimating && amountTo;

  return [
    {
      id: '1',
      key: 'Amount from:',
      value: amountFromText,
      network: isNetworkFromShow ? networkFrom : null,
      isShort: false,
      link: null,
      isCopied: false,
    },
    {
      id: '2',
      key: 'Amount to:',
      value: amountToText,
      network: isNetworkToShow ? networkTo : null,
      isShort: false,
      link: null,
      isCopied: false,
    },
    {
      id: '3',
      key: 'Status',
      value: statusNode,
      network: null,
      isShort: false,
      link: null,
      isCopied: false,
    },
    {
      id: '4',
      key: 'Created at',
      value: createdDateNormalized,
      network: null,
      isShort: false,
      link: null,
      isCopied: false,
    },
    {
      id: '5',
      key: 'Status updated',
      value: updatedDateNormalized,
      network: null,
      isShort: false,
      link: null,
      isCopied: false,
    },
    {
      id: '6',
      key: 'Error message',
      value: currentErrorMessage,
      network: null,
      isShort: false,
      link: null,
      isCopied: false,
    },
  ];
};


/**
 * @param {object} payment
 * @param {object} currenciesObject
 * @returns {[]}
 */
export const getFiatPaymentInfoItems = (payment, currenciesObject) => {
  const offRampProviderId = payment?.requestId ?? null;
  const cryptoAmount = payment?.cryptoCurrencyAmount ?? '';
  const ticker = payment?.cryptoCurrencyCode ?? '';
  const tickerUpperCased = safeToUpperCase(ticker);
  const fiatAmount = payment?.fiatAmount ?? '';
  const fiatCurrencyCode = payment?.fiatCurrencyCode ?? '';
  const fiatCurrencyCodeUpperCased = safeToUpperCase(fiatCurrencyCode);
  const fiatAccountNumber = payment?.fiatAccountNumber ?? null;
  const providerCode = payment?.provider ?? null;
  const provider = providerCode
    ? OFF_RAMP_PROVIDERS.find((el) => el.code === providerCode)?.name
    : null;
  const status = payment?.status ?? null;
  const statusLowerCased = safeToLowerCase(status);
  const statusColor = status && getColorStatus(statusLowerCased);
  const statusNode = status ? `<span style="color: ${statusColor}">${statusLowerCased}</span>` : null;
  const createdDate = payment?.createdAt ?? null;
  const createdDateNormalized = createdDate ? formatDate(createdDate, DATE_PATTERN) : null;
  const updatedDate = payment?.updatedAt ?? null;
  const updatedDateNormalized = updatedDate ? formatDate(updatedDate, DATE_PATTERN) : null;
  const currencyFound = currenciesObject[tickerUpperCased] || {};
  const fiatCurrencyFound = currenciesObject[fiatCurrencyCodeUpperCased] || {};
  const isErrorMessageShow = statusLowerCased === STATUSES.FAILED || statusLowerCased === STATUSES.REJECTED;
  const errorMessage = payment?.error ?? null;
  const errorMessageNormalized = getNormalizedErrorMessageForInfoItems(errorMessage);
  const currentErrorMessage = isErrorMessageShow ? errorMessageNormalized : null;
  const currentTicker = currencyFound?.ticker ?? tickerUpperCased;
  const currentFiatTicker = fiatCurrencyFound?.ticker ?? tickerUpperCased;
  const currentTickerUpperCased = safeToUpperCase(currentTicker);
  const currentFiatTickerUpperCased = safeToUpperCase(currentFiatTicker);
  const cryptoAmountText = `${cryptoAmount} ${currentTickerUpperCased}`;
  const fiatAmountText = `${fiatAmount} ${currentFiatTickerUpperCased}`;
  const network = currencyFound?.network ?? null;
  const isNetworkShow = isCurrencyNetworkShow(network, ticker);
  const payoutDescription = payment?.payoutDescription ?? null;

  return getArrayWithIndexId([
    {
      key: 'Off-ramp Provider id:',
      value: offRampProviderId,
      network: null,
      isShort: true,
      link: null,
      isCopied: true,
    },
    {
      key: 'Crypto Amount',
      value: cryptoAmountText,
      network: isNetworkShow ? network : null,
      isShort: false,
      link: null,
      isCopied: false,
    },
    {
      key: fiatAmount ? 'Fiat Amount' : 'Fiat Currency',
      value: fiatAmount ? fiatAmountText : currentFiatTickerUpperCased,
      network: null,
      isShort: false,
      link: null,
      isCopied: false,
    },
    {
      key: 'Fiat Account Number',
      value: fiatAccountNumber,
      network: null,
      isShort: true,
      link: null,
      isCopied: true,
    },
    {
      key: 'Provider',
      value: provider,
      network: null,
      isShort: false,
      link: null,
      isCopied: false,
    },
    {
      key: 'Payout Description',
      value: payoutDescription,
      network: null,
      isShort: false,
      link: null,
      isCopied: false,
    },
    {
      key: 'Status',
      value: statusNode,
      network: null,
      isShort: false,
      link: null,
      isCopied: false,
    },
    {
      key: 'Created at',
      value: createdDateNormalized,
      network: null,
      isShort: false,
      link: null,
      isCopied: false,
    },
    {
      key: 'Status updated',
      value: updatedDateNormalized,
      network: null,
      isShort: false,
      link: null,
      isCopied: false,
    },
    {
      key: 'Error message',
      value: currentErrorMessage,
      network: null,
      isShort: false,
      link: null,
      isCopied: false,
    },
  ]);
};

/**
 * @param {Array<object>} balance
 * @param {Object} rates
 * @param {String} fiatTicker
 * @returns {Array<object>}
 */
export const getBalanceWithFiatAmount = (balance, rates, fiatTicker) => {
  if (!hasElementsInArray(balance)) {
    return [];
  }

  return balance.map((item) => {
    const tickerLowerCased = safeToLowerCase(item?.ticker);
    const currentRate = rates?.[tickerLowerCased] || null;
    const fiatPrice = getFiatPriceFromCrypto(item?.amount, currentRate, 2);

    return {
      ...item,
      fiatAmount: fiatPrice,
      fiatCurrency: fiatTicker,
    };
  }).sort((balanceItem, nextBalanceItem) => (
    nextBalanceItem.fiatAmount - balanceItem.fiatAmount
  ));
};

export const getPayoutError = (errorMessage, errorDataMessage, isFiatPayout) => {
  if (INVALID_IP_ERROR_PATTERN.test(errorDataMessage)) return IP_ERROR_CODE;
  if (!isFiatPayout && INVALID_ADDRESS_ERROR_PATTERN.test(errorDataMessage)) return errorDataMessage;

  return errorMessage;
};
