import React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';

const mapFn = (data) => data;

export const useCreateConversionPayment = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (options) => {
    const from = options?.from ?? null;
    const to = options?.to ?? null;
    const amount = options?.amount ?? null;
    const [cancelToken] = getRequestSource();
    const requestPromise = client.post(
      '/conversion-payment',
      {
        from,
        to,
        amount,
      },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
