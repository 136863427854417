import React from 'react';
import { useDashboardInitState, useLastTransactions } from '../../hooks';
import { DashboardStatistics } from '../dashboard-statistic';
import { StatisticsGraph } from '../../../statistics-graph';
import { PaymentHistoryTable } from '../../../payment-history-table';
import { hasElementsInArray } from '../../../../helpers/has-elements-in-array';
import { SystemStatuses } from '../../../system-statuses';
import { PlatformUpdates } from '../../../platform-updates';
import { DashboardTransactionInfoPopup } from '../dashboard-transaction-info-popup';
import classes from './styles.module.scss';

export const DashboardTransactionHistory = () => {
  const { currenciesObject } = useDashboardInitState();
  const lastTransactionsState = useLastTransactions(currenciesObject);

  return (
    <div className={classes.dashboardTransactionsHistory}>
      <div className={classes.dashboardBox}>
        <div className={classes.dashboardStatistics}>
          <DashboardStatistics currenciesObject={currenciesObject} />
          <StatisticsGraph
            className={classes.dashboardStatisticsGraph}
            currenciesObject={currenciesObject}
          />
        </div>
        <div className={classes.dashboardTransactions}>
          <PaymentHistoryTable
            // @ts-ignore
            title="Latest Transactions"
            className={classes.dashboardTransactionsHistory}
            items={lastTransactionsState.lastTransactions}
            hasItems={hasElementsInArray(lastTransactionsState.lastTransactions)}
            onClick={lastTransactionsState.handleTransactionClick}
            apiError={lastTransactionsState.apiErrorMessage}
            isTimeoutError={lastTransactionsState.isTimeout}
            currenciesObject={currenciesObject}
          />
        </div>
        <div className={classes.dashboardAdditionalSection}>
          <SystemStatuses className={classes.dashboardSystemStatuses} />
          <PlatformUpdates className={classes.dashboardPlatformUpdates} />
        </div>
      </div>
      <DashboardTransactionInfoPopup
        open={lastTransactionsState.isInfoPopupOpen}
        onClose={lastTransactionsState.handleInfoPopupClose}
        paymentId={lastTransactionsState.transactionPaymentId}
        items={lastTransactionsState.transactionInfoItems}
      />
    </div>
  );
};
