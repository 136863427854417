import React from "react";
import cs from "classnames";
import styles from "./styles.module.scss";

function SwitchCheckbox(props) {
  const {
    onChange = () => {},
    name = "",
    checked = false,
    onClick = () => {},
  } = props;

  return (
    <label className={styles.switchCheckbox} onClick={onClick}>
      <span className={styles.switchCheckboxLabel}>
        {checked ? "On" : "Off"}
      </span>
      <div className={cs(styles.checkboxBody, { [styles.checked]: checked })}>
        <input
          type="checkbox"
          className={styles.checkboxInput}
          onChange={(evt) => onChange(evt.target.checked)}
          name={name}
          checked={checked}
        />
        <span className={styles.switch} />
      </div>
    </label>
  );
}

export default SwitchCheckbox;
