import React from 'react';
import { ResponseType } from '../../../default-types';
import { getSafeErrorMessageText } from '../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../helpers/console-error-message';
import { POPUP_CONTENT_TYPE } from '../helpers';
import { useUpdateWhitelistedIpsInfo } from '../../../api/modules/account/use-update-whitelisted-ips-info';

export const useActions = (ips: Array<string>, reFetchWhitelistIps: () => void) => {
  const updateWhitelistedIpsInfo = useUpdateWhitelistedIpsInfo();

  const [popupType, setPopupType] = React.useState<string | null>(null);
  const [newIpAddress, setNewIpAddress] = React.useState('');
  const [fetching, setFetching] = React.useState(false);
  const [apiError, setApiError] = React.useState<string | null>(null);
  const [ipAddressForDelete, setIpAddressForDelete] = React.useState<string | null>(null);
  const [is2faShow, setIs2faShow] = React.useState(false);

  const handlePopupOpen = React.useCallback((type: string) => {
    setPopupType(type);
  }, []);
  const handlePopupClose = () => {
    setPopupType(null);
    if (ipAddressForDelete) {
      setIpAddressForDelete(null);
    }
  };
  const handleAddAddressPopupOpen = () => {
    handlePopupOpen(POPUP_CONTENT_TYPE.ADD);
  };
  const handle2faClose = () => {
    setIs2faShow(false);
    setNewIpAddress('');
  };
  const handleToAddIpAddress = React.useCallback(async () => {
    setFetching(true);
    const { data, status, errorMessage }: ResponseType = await updateWhitelistedIpsInfo([...ips, newIpAddress]);

    if (status === 200) {
      await reFetchWhitelistIps();
      setFetching(false);
      setIs2faShow(false);
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/whitelisted-ips-info');
      setFetching(false);
      setApiError(errorDataMessage);
    }
  }, [newIpAddress]);
  const handleIpAddressDelete = React.useCallback(async () => {
    setFetching(true);
    const nextIps = ips.filter((ip) => ip !== ipAddressForDelete);
    const { data, status, errorMessage }: ResponseType = await updateWhitelistedIpsInfo(nextIps);

    if (status === 200) {
      await reFetchWhitelistIps();
      setFetching(false);
      setPopupType(null);
      setIpAddressForDelete(null);
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/whitelisted-ips-info');
      setFetching(false);
      setApiError(errorDataMessage);
    }
  }, [ips, ipAddressForDelete]);
  const handleButtonConfirmClick = (value: string) => {
    const ipFound = ips.find((ip) => ip === value);
    if (ipFound) {
      setApiError('This address already exists');
      return;
    }
    setApiError(null);
    setNewIpAddress(value);
    setPopupType(null);
    setIs2faShow(true);
  };
  const handleTooltipDeleteClick = (address: string) => {
    setIpAddressForDelete(address);
    setPopupType(POPUP_CONTENT_TYPE.DELETE);
  };

  React.useEffect(() => {
    let timerId: ReturnType<typeof setTimeout> | null = null;

    if (apiError) {
      timerId = setTimeout(() => {
        setApiError(null);
      }, 5000);
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [apiError]);

  return {
    popupType,
    fetching,
    apiError,
    is2faShow,
    ipAddressForDelete,
    handlePopupOpen,
    handlePopupClose,
    handle2faClose,
    handleToAddIpAddress,
    handleIpAddressDelete,
    handleButtonConfirmClick,
    handleTooltipDeleteClick,
    handleAddAddressPopupOpen,
  };
};
