import restRequestWrapper from '../rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';
import { createUsersMapFn, subPartnerBalanceMapFn } from './map-fns';

export const createSubscription = (options) => {
  const client = nowPaymentsAccountApiRestTransport();
  const subscription_plan_id = options?.subscriptionPlanId ?? null;
  const sub_partner_id = options?.subPartnerId ?? null;

  const requestPromise = client.post(
    '/subscriptions',
    {
      subscription_plan_id,
      sub_partner_id,
    },
  );

  return restRequestWrapper(
    requestPromise,
    null,
    createUsersMapFn,
  );
};

export const getSubPartnerBalance = (id) => {
  const client = nowPaymentsAccountApiRestTransport();

  const requestPromise = client.get(
    `/sub-partners/balance/${id}`,
    null,
  );

  return restRequestWrapper(
    requestPromise,
    null,
    subPartnerBalanceMapFn,
  );
};
