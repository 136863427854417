export const POPUP_OBJECT = {
  addKey: {
    type: 'addKey',
    title: 'API key created 💫',
  },
  addWallet: {
    type: 'addWallet',
    title: 'Add new wallet',
  },
  edit: {
    type: 'edit',
    title: 'Edit API key',
  },
  delete: {
    type: 'delete',
    title: 'Delete API key',
  },
};

export const ELEMENTS_PER_PAGE = 8;
