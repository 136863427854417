import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Popup } from '../../../popup';
import { PaymentsInfoBox } from '../../../payments-info-box';
import { Button } from '../../../ui/button';
import { LoaderIcon } from '../../../icons/loader-icon';
import { hasElementsInArray } from '../../../../helpers/has-elements-in-array';
import classes from './styles.module.scss';

export const PopupCustomerBalanceInfo = (props) => {
  const {
    className,
    open,
    onClose,
    items,
    onUpdate,
    fetching,
  } = props;

  return (
    <Popup
      classNameContent={cn(classes.popup, className)}
      open={open}
      onClose={onClose}
      withCloseButton
    >
      <PaymentsInfoBox
        title="Balances"
        items={items}
      />
      {!hasElementsInArray(items) && (
        <div className={classes.text}>
          No balance to display
        </div>
      )}
      <Button
        onClick={onUpdate}
        className={classes.button}
        disabled={fetching}
      >
        {fetching ? (
          <LoaderIcon path="/images/loader-white-on-blue-icon.gif" />
        ) : 'Update balances'}
      </Button>
    </Popup>
  );
};

PopupCustomerBalanceInfo.defaultProps = {
  className: null,
  open: null,
  onClose: null,
  items: null,
  onUpdate: null,
  fetching: false,
};

PopupCustomerBalanceInfo.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.object),
  onUpdate: PropTypes.func,
  fetching: PropTypes.bool,
};
