import React from 'react';
import { CancelToken } from 'axios';

const useAxiosSource = () => {
  const [, setRequestSource] = React.useState(null);

  return React.useCallback(() => {
    const source = CancelToken.source();

    setRequestSource((prevSource) => {
      if (prevSource) {
        prevSource.cancel();
      }

      return source;
    });

    return [
      source.token,
      source.cancel,
    ];
  }, []);
};

export default useAxiosSource;
