import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { isFunction } from '../../../helpers/is-function';
import { MenuFilterIcon } from '../../icons/menu-filter-icon';
import styles from './styles.module.scss';

export const FilterButton = React.memo((props) => {
  const {
    className,
    count,
    active,
    onClick,
  } = props;

  const isActive = active || count;
  const currentColor = isActive ? '#64ACFF' : '#CFCFCF';

  const handleClick = () => {
    if (isFunction(onClick)) {
      onClick();
    }
  };
  const handleKeyPress = () => {
    if (isFunction(onClick)) {
      onClick();
    }
  };

  return (
    <div
      className={cn([
        styles.filterButton,
        count && styles.filterButtonActiveCount,
        className,
      ])}
      onClick={handleClick}
      onKeyUp={handleKeyPress}
      tabIndex={0}
      role="button"
    >
      <div className={styles.filterButtonCount}>
        {count}
      </div>
      <MenuFilterIcon color={currentColor} />
    </div>
  );
});

FilterButton.defaultProps = {
  className: null,
  count: null,
  active: false,
  onClick: null,
};

FilterButton.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

FilterButton.displayName = 'FilterButton';
