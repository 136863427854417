import React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';
import { fiatPayoutMapFn } from '../../helpers/account/map-fns';

const mapFn = (data) => ({
  result: (data?.result?.rows ?? []).map(fiatPayoutMapFn) ?? [],
});

export const useFiatPayouts = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async () => {
    const [cancelToken] = getRequestSource();
    const requestPromise = client.get('/fiat-payouts', {
      cancelToken,
    });

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
