export const statusList = [
  'Waiting',
  'Confirming',
  'Confirmed',
  'Sending',
  'Partially_paid',
  'Finished',
  'Failed',
  'Refunded',
  'Expired',
];

export const withdrawalStatusList = [
  'Waiting',
  'Failed',
  'Finished',
  'Rejected',
  'Processing',
];

export const withdrawalHeaders = [
  'ID',
  'Batch Withdrawal Id',
  'Amount',
  'Currency',
  'address',
  'Payout Hash',
  'Status',
  'Fee',
  'Created at',
  'Last update at',
];

export const CONVERSION_HISTORY_HEADERS = [
  'ID',
  'Currency From',
  'Currency To',
  'Payout Hash',
  'Status',
  'Created at',
  'Last update at',
];

export const headers = [
  'Parent Payment ID',
  'Payment ID',
  'Payment Extra IDs',
  'Order ID',
  'Original Price',
  'Pay Price',
  'Actually paid',
  'Outcome Price',
  'Status',
  'Created at',
  'Last update at',
  'Payin address',
  'Payin hash',
  'Network Fee',
  'Service Fee',
];
export const DIFFERENT_FORMAT_HEADERS = [
  'Parent Payment ID',
  'Payment ID',
  'Payment Extra IDs',
  'Order ID',
  'Original Price',
  'Pay Price',
  'Actually paid',
  'Outcome Price',
  'Status',
  'Created at',
  'Last update at',
  'Payin address',
  'Payin hash',
  'Network Fee',
  'Service Fee',
  'Payout address',
  'Payout hash',
];

export const NOTIFICATION_NAME = 'ACCOUNT_BANNER_NOTIFICATION';
export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 256;
export const DEFAULT_PER_PAGE = 20;
export const ENABLE_CUSTODY_LOCAL_STORAGE_NAME = 'IS_ENABLE_CUSTODY';

export const PAGE_NAMES_TO_DISPLAY_INFO_LOCAL_STORAGE_NAME = 'page_names_to_display_info';

export const ERROR_CODES = {
  INVALID_AUTH_TOKEN: 'INVALID_AUTH_TOKEN',
  ECONNABORTED: 'ECONNABORTED',
};

export const ERROR_MESSAGES = {
  empty_fields: 'Fill all fields',
  not_match_passwors: 'Passwords do not match',
  password_length: `Password length must be at least ${
    PASSWORD_MIN_LENGTH || 6
  }`,
};

export const PAYMENT_ORIGIN_TYPES = {
  REPEATED: 'REPEATED',
  CHILD: 'CHILD',
};

export const STATUSES = {
  WAITING: 'waiting',
  CONFIRMING: 'confirming',
  CONFIRMED: 'confirmed',
  SENDING: 'sending',
  PARTIALLY_PAID: 'partially_paid',
  FINISHED: 'finished',
  FAILED: 'failed',
  REFUNDED: 'refunded',
  EXPIRED: 'expired',
  REJECTED: 'rejected',
  REJECTING: 'rejecting',
  PROCESSING: 'processing',
  CREATED: 'created',
};
export const PAYMENT_STATUS_ITEMS = [
  { key: STATUSES.WAITING, value: 'Waiting' },
  { key: STATUSES.CONFIRMING, value: 'Confirming' },
  { key: STATUSES.CONFIRMED, value: 'Confirmed' },
  { key: STATUSES.SENDING, value: 'Sending' },
  { key: STATUSES.PARTIALLY_PAID, value: 'Partially paid' },
  { key: STATUSES.FINISHED, value: 'Finished' },
  { key: STATUSES.FAILED, value: 'Failed' },
  { key: STATUSES.REFUNDED, value: 'Refunded' },
  { key: STATUSES.EXPIRED, value: 'Expired' },
];
export const FIXED_RATE_SELECT_ITEMS = [
  { key: 'true', value: 'True' },
  { key: 'false', value: 'False' },
];
export const FEE_PAID_BY_USER_SELECT_ITEMS = [
  { key: 'true', value: 'True' },
  { key: 'false', value: 'False' },
];
export const DEPOSIT_USER_SELECT_ITEMS = [
  { key: PAYMENT_ORIGIN_TYPES.REPEATED, value: 'Re-deposit' },
  { key: PAYMENT_ORIGIN_TYPES.CHILD, value: 'Wrong asset deposit' },
];

export const DATE_PATTERN = 'dd MMM yyyy, hh:mm aaa';

export const CURRENCY_USD = 'USD';

export const SEARCH_SOURCE = {
  SEARCH_FIELD: 0,
  SEARCH_FORM: 1,
};

export const WITHDRAWAL_FEE_PAID_BY = {
  RECEIVER: {
    key: 'receiver',
    value: 'Receiver',
  },
  RECEIVER_EXCLUDED: {
    key: 'receiver_excluded',
    value: 'Sender',
  },
};

export const WITHDRAWAL_FEE_PAID_BY_ITEMS = Object.values(WITHDRAWAL_FEE_PAID_BY);

export const PAGE_NAMES_TO_DISPLAY_INFO = {
  DASHBOARD: 'dashboard',
  PAYMENT_LINK: 'payment_link',
  MASS_PAYOUTS: 'mass_payouts',
  PAYMENTS: 'payments',
  SUBSCRIPTIONS: 'subscriptions',
  TRANSACTION_HISTORY: 'transaction_history',
  CUSTODY: 'custody',
  DONATIONS: 'donations',
  PAYMENT_SETTINGS: 'payment_settings',
  COINS_SETTINGS: 'coins_settings',
  CUSTOMERS: 'customers',
};
export const PAGE_NAMES_TO_DISPLAY_INFO_SHOW = [
  PAGE_NAMES_TO_DISPLAY_INFO.PAYMENT_LINK,
  PAGE_NAMES_TO_DISPLAY_INFO.MASS_PAYOUTS,
  PAGE_NAMES_TO_DISPLAY_INFO.PAYMENTS,
  PAGE_NAMES_TO_DISPLAY_INFO.SUBSCRIPTIONS,
  PAGE_NAMES_TO_DISPLAY_INFO.TRANSACTION_HISTORY,
  PAGE_NAMES_TO_DISPLAY_INFO.CUSTODY,
  PAGE_NAMES_TO_DISPLAY_INFO.DONATIONS,
  PAGE_NAMES_TO_DISPLAY_INFO.COINS_SETTINGS,
  PAGE_NAMES_TO_DISPLAY_INFO.CUSTOMERS,
];

export const CUSTOMER_TRANSACTION_TYPES = {
  DEPOSIT: 'DEPOSIT',
  WITHDRAWAL: 'WITHDRAWAL',
  RECURRING_PAYMENT: 'RECURRING_PAYMENT',
  SUB_PARTNERS_TRANSFER: 'SUB_PARTNERS_TRANSFER',
  PAYMENT: 'CRYPTO2CRYPTO',
};
export const TRANSACTION_TYPES_SELECT_ITEMS = [
  { key: CUSTOMER_TRANSACTION_TYPES.DEPOSIT, value: CUSTOMER_TRANSACTION_TYPES.DEPOSIT },
  { key: CUSTOMER_TRANSACTION_TYPES.WITHDRAWAL, value: CUSTOMER_TRANSACTION_TYPES.WITHDRAWAL },
  { key: CUSTOMER_TRANSACTION_TYPES.RECURRING_PAYMENT, value: CUSTOMER_TRANSACTION_TYPES.RECURRING_PAYMENT },
  { key: CUSTOMER_TRANSACTION_TYPES.SUB_PARTNERS_TRANSFER, value: CUSTOMER_TRANSACTION_TYPES.SUB_PARTNERS_TRANSFER },
];

export const TOOLTIP_TEXTS = {
  NETWORK_FEE: 'Internal exchange transaction fee, already included in the funds received by the merchant',
  SERVICE_FEE: 'NOWPayments\' processing fee, already included in the funds received by the merchant',
  MASS_PAYOUT_FEE: 'Covers network fee and processing withdrawal fee',
};

export const PERIODS = {
  DAYS: 'days',
  MONTHS: 'months',
  YEARS: 'years',
};

export const TFA = {
  TYPES: {
    EMAIL: 'email',
    APP: 'app',
  },
  TEXTS: {
    EMAIL: 'Confirmation code has been sent to your email',
    APP: 'Ether the confirmation code from your app',
    EMAIL_SECOND: 'Please check your e-mail and input 2FA code in the field below',
    APP_SECOND: 'Please check your mobile app and input 2FA code in the field below',
  },
};

export const DEPOSIT_ADDRESSES_NETWORKS = ['trx'];
