import React from 'react';
import cn from 'classnames';
import { Portal } from '../../../portal';
import { Popup } from '../../../popup';
import { POPUP_CONTENT_TYPE } from '../../helpers';
import { ContentForInfo } from '../content-for-info';
import { ContentForAddAddress } from '../content-for-add-address';
import { ContentForDeleteAddress } from '../content-for-delete-address';
import classes from './styles.module.scss';

type WhitelistPopupType = {
  className?: string | null,
  open: boolean,
  onClose: () => void,
  type: string | null,
  onButtonConfirm: (value: string) => void,
  fetching: boolean,
  apiError: string | null,
  onDelete: () => void,
  ipAddressForDelete: string | null,
};

export const WhitelistPopup: React.FC<WhitelistPopupType> = (props) => {
  const {
    className,
    open,
    onClose,
    type,
    onButtonConfirm,
    fetching,
    apiError,
    onDelete,
    ipAddressForDelete,
  } = props;

  const isTypeInfo = type === POPUP_CONTENT_TYPE.INFO;
  const isTypeAdd = type === POPUP_CONTENT_TYPE.ADD;
  const isTypeDelete = type === POPUP_CONTENT_TYPE.DELETE;
  const titleForInfo = isTypeInfo && 'Why is Whitelisting needed?';
  const titleForAddAddresses = isTypeAdd && 'Add an IP address to Whitelist';
  const titleForDeleteAddresses = isTypeDelete && 'Delete IP address from Whitelist';
  const currentTitle = titleForInfo || titleForAddAddresses || titleForDeleteAddresses || null;

  return (
    <Portal>
      <Popup
        classNameContent={cn(classes.whitelistPopup, className)}
        open={open}
        onClose={onClose}
        withoutPadding
        withCloseButton
        mobileFullView
        line
        title={currentTitle}
      >
        {isTypeInfo && <ContentForInfo onClick={onClose} />}
        {isTypeAdd && (
          <ContentForAddAddress
            onClick={onButtonConfirm}
            fetching={fetching}
            apiError={apiError}
          />
        )}
        {isTypeDelete && (
          <ContentForDeleteAddress
            ipAddress={ipAddressForDelete}
            onClick={onClose}
            onDelete={onDelete}
            fetching={fetching}
            apiError={apiError}
          />
        )}
      </Popup>
    </Portal>
  );
};

WhitelistPopup.defaultProps = {
  className: null,
};
