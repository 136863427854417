import React, { Component, Fragment } from "react";
import QRCode from "qrcode";

export default class QRCodeComponent extends Component {
  constructor(props) {
    super(props);
    this.QRCodeCanvas = React.createRef();
    this.state = { QRCodeIsShown: false };

    this.handleQRCodeButtonClick = this.handleQRCodeButtonClick.bind(this);
  }

  componentDidMount() {
    var opt = this.props.options || {
      width: 140,
      heigth: 140,
      margin: 0,
    };

    QRCode.toCanvas(this.QRCodeCanvas.current, this.props.value, opt);
  }

  handleQRCodeButtonClick() {
    this.setState((state) => ({
      QRCodeIsShown: !state.QRCodeIsShown,
    }));
  }

  getQRCodeCanvasClass() {
    return this.state.QRCodeIsShown ? "qrcode qrcode_visible" : "qrcode";
  }

  render() {
    return (
      <Fragment>
        <canvas
          className={this.getQRCodeCanvasClass()}
          ref={this.QRCodeCanvas}
        />
      </Fragment>
    );
  }
}
