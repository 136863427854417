import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';
import { partnerFetchedSelector, partnerFetchingSelector } from '../../../store/partner/selectors';
import {
  setPartner,
  setPartnerFetched,
  setPartnerFetching,
  setPartnerError,
} from '../../../store/partner/reducer';
import { isObject } from '../../../helpers/is-object';
import { getSafeErrorMessageText } from '../../../helpers/get-safe-error-message-text';
import { useProfileUpdateShownOnboard } from './use-profile-update-shown-onboard';

const mapFn = (data) => ({
  id: data?.id ?? null,
  companyName: data?.company_name ?? null,
  email: data?.email ?? null,
  hasTfa: data?.has_tfa ?? false,
  type2fa: data?.type_2fa ?? null,
  isCustody: data?.is_custody ?? false,
  isShowOnboard: data?.is_show_onboard ?? false,
  isGuardarianEnabled: data?.is_onramp_guardarian_enabled ?? false,
  isGuardarianOffRampEnabled: data?.is_offramp_guardarian_enabled ?? false,
  isOnrampEnabled: data?.is_onramp_enabled ?? false,
  isOffRampEnabled: data?.is_offramp_enabled ?? false,
  isUtorgEnabled: data?.is_utorg_enabled ?? false,
  isTransakEnabled: data?.is_transak_enabled ?? false,
  isSimplexEnabled: data?.is_simplex_enabled ?? false,
  storeDetails: data?.store_details ?? null,
  isAutoPush: data?.is_auto_push ?? false,
});

export const useFetchPartnerProfile = () => {
  const dispatch = useDispatch();
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();
  const updateShownOnboard = useProfileUpdateShownOnboard();
  const partnerFetched = useSelector(partnerFetchedSelector());
  const partnerFetching = useSelector(partnerFetchingSelector());

  const fetchProfile =  React.useCallback(async () => {
    if (partnerFetched || partnerFetching) {
      return;
    }
    dispatch(setPartnerFetching(true));
    const [cancelToken] = getRequestSource();
    const requestPromise = client.get(
      '/profile',
      null,
      { cancelToken },
    );

    const { data, status } = await restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );

    if (status === 200 && isObject(data)) {
      if (!data.isShowOnboard) {
        const updateShownOnboardResponse = await updateShownOnboard({ isShowOnboard: true });

        if (updateShownOnboardResponse?.status === 200) {
          data.isShowOnboard = true;
        }
      }
      dispatch(setPartner(data));
    } else {
      const errorMessage = getSafeErrorMessageText(data?.errorData?.message);
      dispatch(setPartnerError(`Profile: ${errorMessage}`));
    }
    dispatch(setPartnerFetching(false));
    dispatch(setPartnerFetched(true));
  }, [
    getRequestSource,
    partnerFetched,
    partnerFetching,
    dispatch,
  ]);

  React.useEffect(() => {
    const timerId = setTimeout(fetchProfile);

    return () => clearTimeout(timerId);
  }, [fetchProfile]);
};
