export const BILLING_PLAN_PAGE_PER_PAGE = 20;

export const BILLING_PLAN_PAGE_THS_DESKTOP = [{
  id: 1,
  name: 'User ID',
}, {
  id: 2,
  name: 'Status',
}, {
  id: 3,
  name: 'Subscribed',
}, {
  id: 4,
  name: 'Updated',
}];

export const BILLING_PLAN_PAGE_THS_MOBILE = [{
  id: 1,
  name: 'User ID',
}, {
  id: 2,
  name: 'Status',
}, {
  id: 3,
  name: 'Subscribed / Updated',
}];

export const BILLING_PLAN_INFO_TH = [{
  id: '1',
  name: 'Plan ID',
  element: null,
}, {
  id: '2',
  name: 'Name',
  element: null,
}, {
  id: '3',
  name: 'Cost',
  element: null,
}, {
  id: '4',
  name: 'Period',
  element: null,
}, {
  id: '5',
  name: 'Created at',
  element: null,
}];
