import React from 'react';
import { ExternalLinkWithUnderline } from '../../../external-link-with-underline';
import {
  BODY_FOR_WALLET_WHITELIST_EMAIL,
  TITLE_FOR_WALLET_WHITELIST_EMAIL,
} from '../../helpers';
import classes from './styles.module.scss';

export const WalletsWhitelistActivation = () => (
  <div className={classes.walletsWhitelistActivation}>
    <img
      src="/images/whitelist-disabled.svg"
      alt="Icon"
      decoding="async"
      loading="lazy"
      width={24}
      height={24}
    />
    <div className={classes.walletsWhitelistActivationText}>
      Risk control disabled
    </div>
    <ExternalLinkWithUnderline
      className={classes.walletsWhitelistActivationLink}
      href={`mailto:partners@nowpayments.io?subject=${TITLE_FOR_WALLET_WHITELIST_EMAIL}&body=${BODY_FOR_WALLET_WHITELIST_EMAIL}`}
      text="Activate whitelisting"
    />
  </div>
);
