import React from 'react';
import cn from 'classnames';
import { DashboardProductCard } from '../dashboard-product-card';
import { ExternalLinkWithUnderline } from '../../../external-link-with-underline';
import { INDUSTRIES_CARDS_OBJECT } from '../../constants';
import { IndustrySelectField } from '../industry-select-field';
import classes from './styles.module.scss';

export const DashboardOurProducts = () => {
  const [isChange, setIsChange] = React.useState(false);

  const activeIndustryKey = window.sessionStorage.getItem('industry') || '';
  const cards = INDUSTRIES_CARDS_OBJECT[activeIndustryKey || 'other'];

  const handleChange = () => {
    setIsChange((prev) => !prev);
  };
  const handleSelect = (key: string) => {
    if (key !== activeIndustryKey) {
      window.sessionStorage.setItem('industry', key);
      handleChange();
      setTimeout(handleChange, 300);
    }
  };

  return (
    <div className={classes.dashboardOurProducts}>
      <h2 className={classes.dashboardOurProductsTitle}>
        Our products
      </h2>
      <p className={classes.dashboardOurProductsDescription}>
        We have selected the most relevant products for you. Choose your project’s industry and start
        integration here or go directly to the API documentation.
      </p>
      <IndustrySelectField
        className={classes.dashboardOurProductsIndustrySelect}
        onSelect={handleSelect}
        activeIndustryKey={activeIndustryKey}
      />
      <div className={classes.dashboardOurProductsCardsWrapper}>
        <div className={cn([
          classes.dashboardOurProductsCards,
          isChange && classes.dashboardOurProductsCardsChange,
        ])}
        >
          {cards.map((card) => (
            <DashboardProductCard
              key={card.name}
              card={card}
              isSmallCard={false}
            />
          ))}
        </div>
      </div>
      <ExternalLinkWithUnderline
        href="https://nowpayments.io/blog/step-by-step-guide-how-to-accept-crypto-payments-on-your-website-with-nowpayments"
        text="API integration guide"
      />
    </div>
  );
};
