import * as React from 'react';
import { hasElementsInArray } from '../helpers/has-elements-in-array';
import { isObject } from '../helpers/is-object';

export const useNumberOfActiveFilters = (data) => (
  React.useMemo(() => {
    if (isObject(data)) {
      const dataNormalized = Object.values(data);
      const dataFiltered = dataNormalized.filter((item) => item);
      const hasDataFiltered = hasElementsInArray(dataFiltered);

      return hasDataFiltered ? dataFiltered.length : null;
    } else {
      return null;
    }
  }, [data])
);
