import React from 'react';
import cn from 'classnames';
import { ProvidersCardProps } from '../../types';
import { Button } from '../../../ui/button';
import classes from './styles.module.scss';

export const ProvidersCard: React.FC<ProvidersCardProps> = (props) => {
  const {
    card,
    onClick,
    isActivatedBlock,
    isSmallCard,
    partnerEmail,
  } = props;

  const currentActivationLink = card.shouldAppendEmailLink ? `${card.activationLink}${partnerEmail}` : card.activationLink;
  const handleActiveButtonClick = () => {
    if (typeof onClick === 'function') {
      onClick(currentActivationLink);
    }
  };

  return (
    <div
      className={cn([
        classes.card,
        card.isAvailableSoon && classes.soon,
        isSmallCard && classes.cardSmall,
      ])}
    >
      {!card.isActivated && card.highlightedInfo && (
        <p className={classes.cardHighlightedInfo}>
          {card.highlightedInfo}
        </p>
      )}
      <div
        className={cn([
          classes.cardMain,
          isSmallCard && classes.cardMainSmall,
        ])}
      >
        <img
          src={card.logoUrl}
          alt={card.name}
          width={44}
          height={44}
          decoding="async"
          loading="lazy"
        />
        <div className={classes.cardMainProvider}>
          <h3 className={classes.cardMainProviderName}>{card.name}</h3>
          <div className={classes.cardPaymentSystems}>
            {card.paymentSystems?.map((system) => (
              <div
                key={system.name}
                data-tooltip-id="providers-card-tooltip"
                data-tooltip-content={system.tooltipText ?? ''}
              >
                <img
                  src={system.logoUrl}
                  alt={system.name}
                  loading="lazy"
                  decoding="async"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className={cn([
          classes.cardInfo,
          isSmallCard && classes.cardInfoSmall,
        ])}
      >
        <div className={classes.cardInfoContent}>
          <p className={classes.cardText}>
            {card.info.firstRow}
          </p>
          {card.info.tooltipText && (
            <img
              src="/images/info-outline-icon.svg"
              alt="info"
              decoding="async"
              loading="lazy"
              data-tooltip-id="providers-card-tooltip"
              data-tooltip-content={card.info.tooltipText ?? ''}
            />
          )}
        </div>
        {card.info.secondRow && (
          <div className={classes.cardInfoContent}>
            <p className={cn([
              classes.cardText,
              card.info.isSecondRowGrey && classes.cardExplanation,
            ])}
            >
              {card.info.secondRow}
            </p>
            {card.info.secondRowTooltipText && (
              <img
                src="/images/info-outline-icon.svg"
                alt="info"
                decoding="async"
                loading="lazy"
                data-tooltip-id="providers-card-tooltip"
                data-tooltip-content={card.info.secondRowTooltipText ?? ''}
              />
            )}
          </div>
        )}
      </div>
      <div className={cn([
        classes.cardVerification,
        isSmallCard && classes.cardVerificationSmall,
      ])}
      >
        <p
          className={classes.cardText}
          dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
            __html: card.verification.firstRow,
          }}
        />
        <p
          className={classes.cardText}
          dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
            __html: card.verification.secondRow,
          }}
        />
      </div>
      {!card.isAvailableSoon && (
        card.isPermissionRequired ? (
          <Button
            className={cn([
              classes.cardButton,
              isActivatedBlock && classes.cardButtonActivated,
              isSmallCard && classes.cardButtonSmall,
            ])}
            onClick={handleActiveButtonClick}
          >
            Activate
          </Button>
        ) : (
          <Button
            className={cn([
              classes.cardButton,
              isActivatedBlock && classes.cardButtonActivated,
              isSmallCard && classes.cardButtonSmall,
            ])}
            href={currentActivationLink}
            target={card.target}
            rel="nofollow noreferrer noopener"
          >
            Activate
          </Button>
        )
      )}
    </div>
  );
};
