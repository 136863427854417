import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { isFunction } from '../../helpers/is-function';
import { AddWalletCurrenciesPopup } from '../add-wallet-currencies-popup';
import { hasActiveApiKey } from '../../helpers/has-active-api-key';
import { ActivateCustody } from './components/activate-custody';
import { WhiteList } from './components/white-list';
import classes from './styles.module.scss';

export const EnableCustodySteps = (props) => {
  const {
    className,
    title,
    onSubmit,
    apiErrorMessage,
    fetching,
    onAvailableCurrencies,
    onApiKeys,
    hasWallets,
  } = props;

  const [activeCustodyStepFinished, setActiveCustodyStepFinished] = React.useState(false);
  const [currencies, setCurrencies] = React.useState(null);
  const [isAddWalletPopupActive, setIsAddWalletPopupActive] = React.useState(false);
  const [isDataFetching, setIsDataFetching] = React.useState(false);

  const handleAddWalletCallback = () => {
    setIsAddWalletPopupActive(false);
    setActiveCustodyStepFinished(true);
  };
  const handleAddWalletPopupToggle = () => {
    setIsAddWalletPopupActive((prev) => !prev);
  };
  const handleActiveCustodyClick = async (isAgree) => {
    if (!isAgree) {
      return;
    }

    if (isFunction(onApiKeys)) {
      setIsDataFetching(true);
      const getApiKeysResponse = await onApiKeys();

      const isActiveApiKey = hasActiveApiKey(getApiKeysResponse?.value);

      if (isFunction(onAvailableCurrencies) && !isActiveApiKey && !hasWallets) {
        const availableCurrencies = await onAvailableCurrencies();
        setIsDataFetching(false);
        setCurrencies(availableCurrencies);
        setIsAddWalletPopupActive(true);

        return;
      }
      setIsDataFetching(false);
    }
    setActiveCustodyStepFinished(true);
  };
  const handleWhiteListClick = (IPs) => {
    if (isFunction(onSubmit)) {
      onSubmit(IPs);
    }
  };

  return (
    <>
      <div className={cn(classes.enableCustodySteps, className)}>
        <div className={classes.enableCustodyStep}>
          {!activeCustodyStepFinished && (
            <ActivateCustody
              title={title}
              onClick={handleActiveCustodyClick}
              fetching={isDataFetching}
            />
          )}
          {activeCustodyStepFinished && (
            <WhiteList
              onClick={handleWhiteListClick}
              apiErrorMessage={apiErrorMessage}
              fetching={fetching}
            />
          )}
        </div>
        <div className={classes.enableCustodyStepFooter}>
          Once custody is activated, incoming funds will be stored in your NOWPayments account.
          You may access them in Custody section, or reach us at
          {' '}
          <a href="mailto:partners@nowpayments.io">partners@nowpayments.io</a>
          {' '}
          if you’d like to know more.
        </div>
      </div>
      <AddWalletCurrenciesPopup
        open={isAddWalletPopupActive}
        onClose={handleAddWalletPopupToggle}
        currencies={currencies}
        onCallback={handleAddWalletCallback}
      />
    </>
  );
};

EnableCustodySteps.defaultProps = {
  className: null,
  title: null,
  onSubmit: null,
  apiErrorMessage: null,
  fetching: false,
  onAvailableCurrencies: null,
  onApiKeys: null,
  hasWallets: false,
};

EnableCustodySteps.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  apiErrorMessage: PropTypes.string,
  fetching: PropTypes.bool,
  onAvailableCurrencies: PropTypes.func,
  onApiKeys: PropTypes.func,
  hasWallets: PropTypes.bool,
};
