import React from 'react';
import { getSafeErrorMessageText } from '../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../helpers/console-error-message';
import { useSubPartners } from '../../../api/modules/account/use-sub-partners';
import { checkErrorCode } from '../../../helpers/check-error-code';
import { hasElementsInArray } from '../../../helpers/has-elements-in-array';
import { useTimeout } from '../../../hooks/use-timeout';
import { useCreateEmailSubscription } from '../../../api/modules/account/use-create-email-subscription';

export const useBillingPlanPageManageCustomersState = (users, planId, reFetchUsers) => {
  const fetchSubPartners = useSubPartners();
  const fetchCreateEmailSubscription = useCreateEmailSubscription();

  const [isPopupShow, setIsPopupShow] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);
  const [isFetched, setIsFetched] = React.useState(false);
  const [subPartners, setSubPartners] = React.useState([]);
  const [apiErrorMessage, setApiErrorMessage] = React.useState(null);
  const [isAddSubPartnersFetching, setIsAddSubPartnersFetching] = React.useState(false);

  const subPartnersForCreateForm = React.useMemo(() => {
    const hasSubPartners = hasElementsInArray(subPartners);
    const hasUsers = hasElementsInArray(users);

    if (!hasSubPartners || !hasUsers) {
      return subPartners;
    }

    const userIds = users.reduce((acc, item) => {
      const subPartnerId = item?.subscriber?.subPartnerId ?? null;

      if (subPartnerId) {
        acc.push(subPartnerId);
      }

      return acc;
    }, []);

    return subPartners.filter((item) => !userIds.includes(item?.id));
  }, [subPartners, users]);

  const resetApiErrorMessage = () => {
    setApiErrorMessage(null);
  };
  const handlePopupManageCustomersOpen = () => {
    setIsPopupShow(true);
  };
  const handlePopupManageCustomersClose = () => {
    setIsPopupShow(false);
  };

  const reFetchSubPartners = React.useCallback(async () => {
    setIsFetching(true);
    const { data, status, errorMessage } = await fetchSubPartners();

    if (status === 200) {
      setSubPartners(data?.result ?? []);
      setIsFetching(false);
      setIsFetched(true);
    } else {
      checkErrorCode(data?.errorData?.code);
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/sub-partners');
      setApiErrorMessage(errorDataMessage);
      setIsFetching(false);
    }
  }, []);
  const handleSubmit = React.useCallback(async (idsArray) => {
    if (!hasElementsInArray(idsArray)) {
      setApiErrorMessage('No users selected');

      return;
    }

    setIsAddSubPartnersFetching(true);
    const { data, status, errorMessage } = await fetchCreateEmailSubscription({
      subPartnerId: idsArray,
      subscriptionPlanId: planId,
    });
    if (status === 201) {
      setIsAddSubPartnersFetching(false);
      setIsPopupShow(false);
      void reFetchUsers();
    } else {
      checkErrorCode(data?.errorData?.code);
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/subscriptions');
      setApiErrorMessage(errorDataMessage);
      setIsAddSubPartnersFetching(false);
    }
  }, []);

  useTimeout(resetApiErrorMessage, 3000, apiErrorMessage);

  return {
    isPopupManageCustomersShow: isPopupShow,
    handlePopupManageCustomersOpen,
    handlePopupManageCustomersClose,
    isSubPartnersFetching: isFetching,
    isSubPartnersFetched: isFetched,
    subPartners: subPartnersForCreateForm,
    subPartnersApiErrorMessage: apiErrorMessage,
    reFetchSubPartners,
    handleManageCustomersSubmit: handleSubmit,
    isAddSubPartnersFetching,
  };
};
