import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Popup } from '../popup';
import { Portal } from '../portal';
import { partnerFetchedSelector } from '../../store/partner/selectors';
import { TeamActivate } from './components/team-activate';
import { ROUTES } from '../../constants/routes';
import classes from './styles.module.scss';

export const TeamActivatePopup = () => {
  const location = useLocation();
  const history = useHistory();
  const isPartnerFetched = useSelector(partnerFetchedSelector());

  const [token, setToken] = React.useState<string | null>(null);

  const handleClose = () => {
    setToken(null);
    if (location.search) {
      history.replace(ROUTES.DASHBOARD);
    }
  };

  React.useEffect(() => {
    if (isPartnerFetched) {
      const nextToken = new URLSearchParams(location.search).get('team-activate');
      if (nextToken) {
        setToken(nextToken);
      }
    }
  }, [isPartnerFetched]);

  return (
    <Portal>
      <Popup
        classNameContent={classes.popup}
        open={Boolean(token)}
        onClose={handleClose}
        withCloseButton
      >
        <TeamActivate token={token} />
      </Popup>
    </Portal>
  );
};
