import React from 'react';
import { useSelector } from 'react-redux';
import { TYPES } from '../helpers';
import { hasElementsInArray } from '../../../helpers/has-elements-in-array';
import { useCreateWhitelistAddresses, CREATE_WHITELIST_ADDRESSES_PATH } from '../../../api/modules/account/use-create-whitelist-addresses';
import { ResponseType } from '../../../default-types';
import { getSafeErrorMessageText } from '../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../helpers/console-error-message';
import { currencyListSelector } from '../../../store/currencies/selectors';
import { useCryptoCurrencies } from '../../../hooks/use-crypto-currencies';

export const useState = () => {
  const createWhitelistAddresses = useCreateWhitelistAddresses();
  const currencyList = useSelector(currencyListSelector());
  const { cryptoCurrencies } = useCryptoCurrencies(currencyList);

  const [isPopupShow, setIsPopupShow] = React.useState(false);
  const [is2FAShow, setIs2FAShow] = React.useState(false);
  const [isSuccessShow, setIsSuccessShow] = React.useState(false);
  const [activeTabType, setActiveTabType] = React.useState(TYPES.MANUALLY);
  const [whitelistData, setWhitelistData] = React.useState<Array<object> | null>(null);
  const [isPending, setIsPending] = React.useState(false);
  const [apiErrorMessage, setApiErrorMessage] = React.useState<string | null>(null);

  const isManualType = activeTabType === TYPES.MANUALLY;
  const isUploadType = activeTabType === TYPES.UPLOAD;

  const handlePopupToggle = () => {
    setIsPopupShow((prevState) => !prevState);
  };
  const handle2faClose = () => {
    setIs2FAShow(false);
  };
  const handleSuccessPopupClose = () => {
    setIsSuccessShow(false);
  };
  const handleSubmit = (formData: Array<object>) => {
    if (isManualType) {
      setWhitelistData(formData);
    }
    setIs2FAShow(true);
    setIsPopupShow(false);
  };
  const handleWhitelistAddressSend = React.useCallback(async () => {
    setIsPending(true);
    const { data, status, errorMessage }: ResponseType = await createWhitelistAddresses(whitelistData);
    setIsPending(false);

    if (status === 200) {
      setIs2FAShow(false);
      setIsSuccessShow(true);
      setWhitelistData(null);
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, CREATE_WHITELIST_ADDRESSES_PATH);
      setApiErrorMessage(errorDataMessage);
    }
  }, [whitelistData]);

  React.useEffect(() => {
    if (whitelistData) {
      setWhitelistData(null);
    }
  }, [activeTabType]);

  React.useEffect(() => {
    let nextTimerId: ReturnType<typeof setTimeout> | null = null;
    if (apiErrorMessage) {
      nextTimerId = setTimeout(() => {
        setApiErrorMessage(null);
      }, 5000);
    }

    return () => {
      if (nextTimerId) {
        clearTimeout(nextTimerId);
      }
    };
  }, [apiErrorMessage]);

  return {
    state: {
      isPopupShow,
      activeTabType,
      isManualType,
      isUploadType,
      hasWhitelistData: hasElementsInArray(whitelistData),
      is2FAShow,
      isPending,
      isSuccessShow,
      apiErrorMessage,
      currencies: cryptoCurrencies,
    },
    actions: {
      handlePopupToggle,
      setActiveTabType,
      handleSubmit,
      setWhitelistData,
      handle2faClose,
      handleWhitelistAddressSend,
      handleSuccessPopupClose,
    },
  };
};
