import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes';
import { HASH_NAMES, HASHES } from '../helpers';

export const useRouteHashAndStepVariables = () => {
  const location = useLocation();
  const history = useHistory();
  const hashText = location.hash.split('#').join('');
  const isPayoutWallets = hashText === HASH_NAMES.WALLETS;
  const isApiKeys = hashText === HASH_NAMES.KEYS;
  const isIpAddresses = hashText === HASH_NAMES.ADDRESSES;
  const isPaymentDetails = hashText === HASH_NAMES.DETAILS;
  const isPaymentNotifications = hashText === HASH_NAMES.NOTIFICATIONS;

  const addHashToRoute = (hash: string) => {
    history.push(`${ROUTES.STORE_SETTINGS}#${hash}`);
  };

  React.useEffect(() => {
    if (!HASHES.includes(hashText)) {
      history.replace(`${ROUTES.STORE_SETTINGS}#${HASH_NAMES.WALLETS}`);
    }
  }, [hashText]);

  return {
    steps: {
      isPayoutWallets,
      isApiKeys,
      isIpAddresses,
      isPaymentDetails,
      isPaymentNotifications,
    },
    addHashToRoute,
    hashText,
  };
};
