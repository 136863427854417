import React from 'react';
import cn from 'classnames';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { StatsAreaChartProps } from '../../types';
import classes from './styles.module.scss';
import { TYPES } from '../../helpers';
import { formatDate } from '../../../../helpers/format-date';
import { toFixed } from '../../../../helpers/to-fixed';
import { safeToUpperCase } from '../../../../helpers/safe-to-upper-case';
import { toFormat } from '../../../../helpers/to-format';

export const StatsAreaChart: React.FC<StatsAreaChartProps> = (props) => {
  const {
    className = null,
    data,
    activePeriodType,
  } = props;

  const isPeriodTypeSevenDays = activePeriodType === TYPES.SEVEN_DAYS;

  const formatXAxisValue = (date: string): string => {
    const datePattern = isPeriodTypeSevenDays ? 'eee dd' : 'MM.dd';
    return formatDate(date, datePattern);
  };
  const formatYAxisValue = (value: number, index: number): string => {
    if (index === 0) {
      return ' ';
    }
    return toFormat(String(value), '$') || ' ';
  };
  const formatTooltipLabel = (date: string): string => formatDate(date, 'MMM dd,yyyy');
  const formatTooltipContent = (value: any, str: string, tooltipProps: any) => {
    if (tooltipProps.dataKey === 'amount') {
      const amount = tooltipProps.payload?.amount ?? 0;
      const amountFixed = toFixed(amount, 2);
      const amountFormatted = toFormat(amountFixed);
      const ticker = tooltipProps.payload?.currency ?? '';
      return `${amountFormatted} ${safeToUpperCase(ticker)}`;
    }
    return value;
  };

  return (
    <div className={cn(classes.statsAreaChart, className)}>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={data}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id="colorAmount" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#D7EAFF" stopOpacity={1} />
              <stop offset="95%" stopColor="#D7EAFF" stopOpacity={0.2} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="4" />
          <XAxis
            dataKey="date"
            axisLine={false}
            tickLine={false}
            minTickGap={20}
            tickFormatter={formatXAxisValue}
          />
          <YAxis
            dataKey="amount"
            axisLine={false}
            tickLine={false}
            tickFormatter={formatYAxisValue}
          />
          <Tooltip
            formatter={formatTooltipContent}
            labelFormatter={formatTooltipLabel}
            contentStyle={{
              background: '#ffffff',
              borderRadius: 8,
              boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.2)',
              padding: 0,
              border: 0,
              overflow: 'hidden',
            }}
            wrapperClassName={classes.statsAreaChartTooltip}
            labelClassName={classes.statsAreaChartTooltipLabel}
            itemStyle={{
              background: '#ffffff',
              fontSize: '16px',
              lineHeight: '20px',
              color: '#13171C',
              padding: '8px 15px',
            }}
          />
          <Area
            type="monotone"
            dataKey="amount"
            stroke="#64ACFF"
            strokeWidth="2"
            fillOpacity={1}
            fill="url(#colorAmount)"
            name="Turnover"
            animationDuration={600}
          />
          <Area
            type="monotone"
            dataKey="count"
            stroke="transparent"
            strokeWidth="0"
            fillOpacity={0}
            name="Payouts"
            animationDuration={600}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};
