import React from 'react';
import { Button } from '../../../ui/button';
import { LoaderIcon } from '../../../icons/loader-icon';
import { WhitelistUploadCsvTypes } from '../../types';
import classes from './styles.module.scss';
import { CsvReader } from '../../../shared/csv-reader';

export const WhitelistUploadCsv: React.FC<WhitelistUploadCsvTypes> = (props) => {
  const {
    onCSVcb,
    onRemove,
    onSubmit,
    disabled,
    fetching = false,
    apiError = '',
  } = props;

  return (
    <div className={classes.whitelistUploadCSV}>
      <CsvReader
        onCallBack={onCSVcb}
        onRemove={onRemove}
        apiError={apiError}
        isCurrencyOnly
      />
      <Button
        className={classes.button}
        onClick={onSubmit}
        disabled={disabled || fetching}
      >
        {fetching ? (
          <LoaderIcon path="/images/loader-white-on-blue-icon.gif" />
        ) : 'Continue'}
      </Button>
    </div>
  );
};
