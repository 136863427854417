import { DATE_PATTERN, STATUSES, TOOLTIP_TEXTS } from '../constants/app-constants';
import { DEFAULT_INFO_ERROR_TEXT } from '../components/mass-payouts-page/helpers';
import { safeToUpperCase } from './safe-to-upper-case';
import { getColorStatus } from './get-color-status';
import { formatDate } from './format-date';
import { safeToLowerCase } from './safe-to-lower-case';
import { getArrayWithIndexId } from './get-array-with-index-id';
import { getAmountWithTicker } from './get-amount-with-ticker';
import { isCurrencyNetworkShow } from './is-currency-network-show';

/**
 * @param {Object} payment
 * @returns {Array<object>}
 */
export const getMassPayoutInfoItems = (payment) => {
  const batchId = payment?.batchWithdrawalId ?? null;
  const extraId = payment?.extraId ?? null;
  const amount = payment?.amount ?? null;
  const ticker = payment?.currency ?? '';
  const currentTicker = payment?.ticker ?? '';
  const currentTickerUpperCased = safeToUpperCase(currentTicker);
  const amountWithTicker = getAmountWithTicker(amount, currentTickerUpperCased);
  const address = payment?.address ?? null;
  const hash = payment?.hash ?? null;
  const status = payment?.status ?? null;
  const statusLowerCased = safeToLowerCase(status);
  const statusColor = status && getColorStatus(status);
  const statusNode = status ? `<span style="color: ${statusColor}">${status}</span>` : null;
  const createdDate = payment?.createdAt ?? null;
  const createdDateNormalized = createdDate ? formatDate(createdDate, DATE_PATTERN) : null;
  const updatedDate = payment?.updatedAt ?? null;
  const updatedDateNormalized = updatedDate ? formatDate(updatedDate, DATE_PATTERN) : null;
  const fee = payment?.fee ?? null;
  const feeNormalized = fee ? `${fee} ${currentTickerUpperCased}` : null;
  const isErrorMessageShow = statusLowerCased === STATUSES.FAILED
    || statusLowerCased === STATUSES.REJECTED;
  const errorMessage = payment?.error ?? null;
  const hasRewardWord = typeof errorMessage === 'string'
    ? errorMessage.includes('reward')
    : false;
  const errorMessageNormalized = hasRewardWord ? DEFAULT_INFO_ERROR_TEXT : errorMessage;
  const currentErrorMessage = isErrorMessageShow ? errorMessageNormalized : null;
  const network = payment?.network ?? null;
  const isNetworkFromShow = isCurrencyNetworkShow(network, ticker);
  const payoutDescription = payment?.payoutDescription ?? null;

  return getArrayWithIndexId([
    {
      key: 'Batch ID',
      value: batchId,
      network: null,
      link: null,
      isCopied: false,
    }, {
      key: 'ExtraId',
      value: extraId,
      network: null,
      link: null,
      isCopied: false,
    }, {
      key: 'Amount',
      value: amountWithTicker,
      network: isNetworkFromShow ? network : null,
      link: null,
      isCopied: false,
    }, {
      key: 'Address',
      value: address,
      network: null,
      link: null,
      isCopied: false,
    }, {
      key: 'Payout Hash',
      value: hash,
      network: null,
      link: null,
      isCopied: false,
    }, {
      key: 'Payout Description',
      value: payoutDescription,
      network: null,
      link: null,
      isCopied: false,
    }, {
      key: 'Status',
      value: statusNode,
      network: null,
      link: null,
      isCopied: false,
    }, {
      key: 'Created at',
      value: createdDateNormalized,
      network: null,
      link: null,
      isCopied: false,
    }, {
      key: 'Status updated',
      value: updatedDateNormalized,
      network: null,
      link: null,
      isCopied: false,
    }, {
      key: 'Fee',
      value: feeNormalized,
      network: null,
      link: null,
      isCopied: false,
      tooltipText: TOOLTIP_TEXTS.MASS_PAYOUT_FEE,
    }, {
      key: 'Error message',
      value: currentErrorMessage,
      network: null,
      link: null,
      isCopied: false,
    },
  ]);
};
