import * as React from 'react';
import { Helmet } from 'react-helmet';
import { BillingPlansPage } from '../../components/customers-pages';

export const BillingPlans = () => (
  <>
    <Helmet>
      <title>Billing Plans</title>
    </Helmet>
    <BillingPlansPage />
  </>
);
