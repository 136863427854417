import { hasElementsInArray } from './has-elements-in-array';
import { safeToLowerCase } from './safe-to-lower-case';

/**
 * @param currencies {array}
 * @param availableTickers {array}
 * @returns {array}
 */
export const getFilteredCurrencies = (currencies, availableTickers) => {
  const hasCurrencies = hasElementsInArray(currencies);
  const hasAvailableTickers = hasElementsInArray(availableTickers);

  if (!hasCurrencies) {
    return [];
  }

  if (!hasAvailableTickers) {
    return currencies;
  }

  const availableTickersLowerCased = availableTickers.map((item) => safeToLowerCase(item));

  return currencies.filter((item) => availableTickersLowerCased.includes(safeToLowerCase(item?.code)));
};
