import {
  differenceInCalendarDays, getDaysInMonth, startOfDay, endOfDay,
} from 'date-fns';
import { nanoid } from 'nanoid';
import { COLORS_FOR_PIE_CHART, TYPES } from './constans';
import { formatDate } from '../../../helpers/format-date';
import { calculateDate } from '../../../helpers/calculate-date';
import {
  TurnoverStatsDataType,
  NormalizedTurnoverStatisticsForAreaChartTypes,
  StatsPieChartCoinType,
  CustomizedLabelForPieChart,
} from '../types';
import { hasElementsInArray } from '../../../helpers/has-elements-in-array';
import { safeToUpperCase } from '../../../helpers/safe-to-upper-case';
import { StatisticsCurrencyType } from '../../../api/helpers/types';
import { getCurrentNetworkForShow } from '../../../helpers/get-current-network-for-show';
import { toFixed } from '../../../helpers/to-fixed';

const RADIAN = Math.PI / 180;

/**
 * Data for Turnover Statistics
 */
export const getNormalizedTurnoverStatisticsForAreaChart = (
  data: NormalizedTurnoverStatisticsForAreaChartTypes,
  startDate: string,
  endDate: string,
): TurnoverStatsDataType[] => {
  const endDateObject = new Date(endDate);
  const calendarDays = differenceInCalendarDays(endDateObject, new Date(startDate));
  const calendarDaysNormalized = calendarDays > 6 ? calendarDays : 7;

  const newArrayForStats = new Array(calendarDaysNormalized).fill(null);
  return newArrayForStats.map((item, index) => {
    const date = calculateDate(endDateObject, index, TYPES.DAY, true);
    const dateString = date.toISOString();
    const dateStringNormalized = formatDate(dateString, 'yyyy-MM-dd');
    const currentData = data?.[dateStringNormalized] || {};

    return ({
      date: dateString,
      amount: Number(currentData?.baseAmount || 0),
      currency: currentData?.baseCurrency ?? null,
      count: currentData?.count ?? '0',
    });
  }).reverse();
};

/**
 * Get Current Start and End Dates of period
 */
export const getPeriodDates = (periodType: string, customPeriod: { start: string | null, end: string | null }) => {
  let endDate = new Date();
  let startDate = calculateDate(endDate, 7, TYPES.DAY, true);

  switch (periodType) {
    case TYPES.ONE_MONTH: {
      const daysInMonth = getDaysInMonth(endDate);
      startDate = calculateDate(endDate, daysInMonth, TYPES.DAY, true);
      break;
    }
    case TYPES.THREE_MONTH: {
      startDate = calculateDate(endDate, 3, TYPES.MONTH, true);
      break;
    }
    case TYPES.CALENDAR: {
      const { start, end } = customPeriod;
      if (start) {
        startDate = new Date(start);
      }
      if (end) {
        endDate = new Date(end);
      }
      break;
    }
    default: {
      console.info(`${periodType} - no such period exists period`);
    }
  }

  return {
    startDate: startOfDay(startDate).toISOString(),
    endDate: endOfDay(endDate).toISOString(),
  };
};

/**
 * Data for Coins Statistics
 */
export const getNormalizedStatisticsForPieChart = (
  statsData: StatisticsCurrencyType[],
  currenciesObject: { [key: string]: any },
): StatsPieChartCoinType[] => {
  if (!hasElementsInArray(statsData)) {
    return [
      {
        id: '1',
        count: '',
        amount: 0.0001,
        ticker: '',
        name: null,
        network: null,
        color: '#f19b46',
        isEmptyData: true,
      },
    ];
  }

  const statsDataNormalized = statsData.map((item, index) => {
    const tickerUpperCased = safeToUpperCase(item.currency);
    const coin = currenciesObject[tickerUpperCased];
    const amountToFixed = toFixed(item?.amount ?? '0', 8);

    return {
      id: nanoid(),
      count: item?.count ?? null,
      amount: Number(amountToFixed),
      ticker: coin?.ticker ?? tickerUpperCased,
      name: coin?.name ?? null,
      network: getCurrentNetworkForShow(coin?.network, item.currency),
      color: COLORS_FOR_PIE_CHART[index] || COLORS_FOR_PIE_CHART.at(-1),
      isEmptyData: false,
    };
  });
  statsDataNormalized.sort((a, b) => (a.amount < b.amount ? 1 : -1));
  return statsDataNormalized.slice(0, 4);
};

export const getCustomizedLabelForPieChart = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}: CustomizedLabelForPieChart) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const coordinateX = (cx + radius * Math.cos(-midAngle * RADIAN)) - 10;
  const coordinateY = (cy + radius * Math.sin(-midAngle * RADIAN)) - 2;
  const percentNumber = percent * 100;
  const percentDigits = percentNumber > 1 ? 0 : 2;
  const label = `${toFixed(String(percentNumber), percentDigits)}%`;

  return { label, coordinateX, coordinateY };
};
