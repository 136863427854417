import { STATUSES } from '../constants/app-constants';

/**
 * @param {String} status
 * @returns {boolean}
 */
export const isNeedStatusUpdate = (status) => (
  status === STATUSES.WAITING
  || status === STATUSES.SENDING
  || status === STATUSES.CONFIRMING
  || status === STATUSES.CONFIRMED
);
