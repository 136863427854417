import * as React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';
import { userSubscriptionMapFn } from '../../helpers/account/map-fns';

const mapFn = (data) => ({
  result: (data?.result ?? []).map(userSubscriptionMapFn),
  count: data?.count ?? null,
});

export const useEmailSubscriptions = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (options) => {
    const limit = options?.limit || -1;
    const page = options?.page || 1;
    const email = options?.email || null;
    const sub_partner_id = options?.subPartnerId || null;
    const subscription_plan_id = options?.id || null;
    const only_emails = options?.onlyEmails || null;
    const only_sub_partners = options?.onlySubPartners || null;
    const orderBy = options?.orderBy || 'desc';
    const sortBy = options?.sortBy || null;
    const [cancelToken] = getRequestSource();
    const requestPromise = client.get(
      '/subscriptions',
      {
        params: {
          limit,
          page,
          email,
          sub_partner_id,
          subscription_plan_id,
          only_emails,
          only_sub_partners,
          orderBy,
          sortBy,
        },
      },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
