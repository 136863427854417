import { PERIODS } from '../../../constants/app-constants';

export const SUBSCRIPTION_PLAN_PERIODS = [
  { key: PERIODS.DAYS, value: 'Day(s)' },
  { key: PERIODS.MONTHS, value: 'Month(s)' },
  { key: PERIODS.YEARS, value: 'Year(s)' },
];

export const MAX_NUMBER_OF_TITLE_CHARACTERS = 50;
export const MAX_NUMBER_OF_TITLE_ERROR_MESSAGE = 'Title must be at most 50 characters';
