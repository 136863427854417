import React from 'react';
import { getArrayWithIndexId } from '../../../helpers/get-array-with-index-id';
import { PayoutWalletsIcon } from '../../icons/payout-wallets-icon';
import { KeysIcon } from '../../icons/keys-icon';
import { ApiAddressesIcon } from '../../icons/api-addresses-icon';
import { PaymentDetailsIcon } from '../../icons/payment-details-icon';
import { PaymentNotificationsIcon } from '../../icons/payment-notifications-icon';

export const HASH_NAMES = {
  WALLETS: 'wallets',
  KEYS: 'keys',
  ADDRESSES: 'addresses',
  DETAILS: 'details',
  NOTIFICATIONS: 'notifications',
};
export const HASHES = [
  HASH_NAMES.WALLETS,
  HASH_NAMES.KEYS,
  HASH_NAMES.ADDRESSES,
  HASH_NAMES.DETAILS,
  HASH_NAMES.NOTIFICATIONS,
];
export const PAYMENT_SETTINGS_NAVIGATION = getArrayWithIndexId([
  {
    hash: HASH_NAMES.WALLETS,
    name: 'Payout wallets',
    Icon: <PayoutWalletsIcon />,
  },
  {
    hash: HASH_NAMES.KEYS,
    name: 'API keys',
    Icon: <KeysIcon />,
  },
  {
    hash: HASH_NAMES.ADDRESSES,
    name: 'IP addresses',
    Icon: <ApiAddressesIcon />,
  },
  {
    hash: HASH_NAMES.DETAILS,
    name: 'Payment details',
    Icon: <PaymentDetailsIcon />,
  },
  {
    hash: HASH_NAMES.NOTIFICATIONS,
    name: 'Instant payment notifications',
    Icon: <PaymentNotificationsIcon />,
  },
]);
