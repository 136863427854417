import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button } from '../../../ui/button';
import { isFunction } from '../../../../helpers/is-function';
import classes from './styles.module.scss';

const DEFAULT_TITLE = 'Your conversion has been created.';
const DEFAULT_TEXT = '<strong>IMPORTANT</strong>: conversions may take up to 20 minutes to reach your custody. <br /> If you still have some questions, please contact us <br /> on <a href="mailto:partners@nowpayments.io">partners@nowpayments.io</a> and we will help you!';

export const ConversionCreated = (props) => {
  const {
    className,
    title,
    onClick,
    buttonText,
    children,
  } = props;

  const handleClick = () => {
    if (isFunction(onClick)) {
      onClick();
    }
  };

  return (
    <div className={cn(classes.payoutCreated, className)}>
      <div className={classes.payoutCreatedTitle}>
        {title}
      </div>
      <div
        className={classes.payoutCreatedText}
        dangerouslySetInnerHTML={{ __html: children }}
      />
      {onClick && (
        <Button
          className={classes.payoutCreatedButton}
          onClick={handleClick}
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
};

ConversionCreated.defaultProps = {
  className: null,
  title: DEFAULT_TITLE,
  onClick: null,
  buttonText: 'Ok',
  children: DEFAULT_TEXT,
};

ConversionCreated.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
  children: PropTypes.node,
};
