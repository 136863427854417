import * as yup from 'yup';

export const ADD_ACCOUNT_SCHEMA_FIRST_STEP = yup.object({
  fiatCurrency: yup.string().required('Fiat currency is required'),
  paymentCode: yup.string().required('Payment code is required'),
  countryCode: yup.string().required('Country is required'),
});

export const ADD_ACCOUNT_SCHEMA_SECOND_STEP_EURO = yup.object({
  iban: yup
    .string()
    .required('IBAN is required')
    .matches(/^(?:[A-Za-z]{2}[A-Za-z0-9]{13,})?$/, 'Invalid IBAN'),
  accountNumber: yup.string().required('Account number is required'),
  processingConsent: yup.boolean().oneOf([true], 'You must consent to processing'),
});

export const ADD_ACCOUNT_SCHEMA_SECOND_STEP_INR = yup.object({
  swiftBic: yup
    .string()
    .required('SWIFT/BIC Code is required')
    .matches(/^[a-zA-Z0-9]{8,11}$/, 'Invalid SWIFT/BIC Code'),
  accountNumber: yup.string().required('Account number is required'),
  processingConsent: yup.boolean().oneOf([true], 'You must consent to processing'),
});

export const ADD_ACCOUNT_SCHEMA_SECOND_STEP_OTHER = yup.object({
  accountNumber: yup.string().required('Account number is required'),
  processingConsent: yup.boolean().oneOf([true], 'You must consent to processing'),
});
