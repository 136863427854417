import * as React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';
import { createUsersMapFn } from '../../helpers/account/map-fns';

export const useCreateEmailSubscription = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (options) => {
    const subscription_plan_id = options?.subscriptionPlanId || null;
    const email = options?.email || null;
    const sub_partner_id = options?.subPartnerId || null;

    const body = { subscription_plan_id };

    if (email) {
      body.email = email;
    }

    if (sub_partner_id) {
      body.sub_partner_id = sub_partner_id;
    }

    const [cancelToken] = getRequestSource();
    const requestPromise = client.post(
      '/subscriptions',
      body,
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      createUsersMapFn,
    );
  }, [getRequestSource]);
};
