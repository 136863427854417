import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { formatDate } from '../../../../helpers/format-date';
import { isFunction } from '../../../../helpers/is-function';
import { DATE_PATTERN } from '../../../../constants/app-constants';
import classes from './styles.module.scss';

export const SubscriptionPlanTableRow = React.memo((props) => {
  const {
    className,
    onClick,
    id,
    email,
    active,
    createdAt,
    updatedAt,
    isDesktop,
  } = props;

  const emailNormalized = email || '-';
  const statusText = active ? 'Active' : 'Inactive';
  const createdDateFormatted = formatDate(createdAt, DATE_PATTERN);
  const createdDateNormalized = createdDateFormatted || '-';
  const updatedDateFormatted = formatDate(updatedAt, DATE_PATTERN);
  const updatedDateNormalized = updatedDateFormatted || '-';

  const handleRowClick = () => {
    if (isFunction(onClick)) {
      onClick(id);
    }
  };

  return (
    <tr
      className={cn(classes.row, className)}
      onClick={handleRowClick}
    >
      <td className={cn(classes.rowTd, classes.overflow, classes.colorBlack)}>
        {emailNormalized}
      </td>
      <td className={classes.rowTd}>
        <span className={active ? classes.colorGreen : classes.colorPink}>
          {statusText}
        </span>
      </td>
      <td className={classes.rowTd}>
        {createdDateNormalized}
        {!isDesktop && (
          <div className={classes.rowTdSecondItem}>
            {updatedDateNormalized}
          </div>
        )}
      </td>
      {isDesktop && (
        <td className={classes.rowTd}>
          {updatedDateNormalized}
        </td>
      )}
    </tr>
  );
});

SubscriptionPlanTableRow.defaultProps = {
  className: null,
  onClick: null,
  id: null,
  email: null,
  active: false,
  createdAt: null,
  updatedAt: null,
  isDesktop: false,
};

SubscriptionPlanTableRow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
  email: PropTypes.string,
  active: PropTypes.bool,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  isDesktop: PropTypes.bool,
};

SubscriptionPlanTableRow.displayName = 'SubscriptionPlanTableRow';
