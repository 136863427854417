import React from 'react';
import { useSelector } from 'react-redux';
import { getSafeErrorMessageText } from '../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../helpers/console-error-message';
import { isPartnerCustodySelector, partnerFetchedSelector } from '../../../store/partner/selectors';
import { useEmailSubscriptions } from '../../../api/modules/account/use-email-subscriptions';
import { BILLING_PLAN_PAGE_PER_PAGE } from '../helpers';
import { debounce } from '../../../helpers/utils';

const DEFAULT_DATA = {
  result: [],
  count: null,
};

export const useBillingPlanPageUsersState = (planId) => {
  const isPartnerCustody = useSelector(isPartnerCustodySelector());
  const partnerFetched = useSelector(partnerFetchedSelector());
  const fetchEmailSubscriptions = useEmailSubscriptions();

  const [searchText, setSearchText] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [isUsersFetching, setIsUsersFetching] = React.useState(false);
  const [usersData, setUsersData] = React.useState(DEFAULT_DATA);
  const [usersApiErrorMessage, setUsersApiErrorMessage] = React.useState(null);

  const reFetchUsers = React.useCallback(async (page = 1, subPartnerId = null) => {
    setUsersApiErrorMessage(null);
    setIsUsersFetching(true);
    const { data, status, errorMessage } = await fetchEmailSubscriptions({
      limit: BILLING_PLAN_PAGE_PER_PAGE,
      id: planId,
      page,
      subPartnerId,
      onlySubPartners: true,
    });
    if (status === 200) {
      setUsersData(data);
      setIsUsersFetching(false);
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/subscriptions');
      setUsersApiErrorMessage(errorDataMessage);
      setIsUsersFetching(false);
    }
  }, [planId]);

  const handleSearchChange = React.useCallback(debounce((value) => {
    setSearchText(value);
    setPage(1);
  }, 400), [setSearchText, setPage]);

  React.useEffect(() => {
    if (!partnerFetched || !isPartnerCustody) {
      return;
    }
    void reFetchUsers(page, searchText);
  }, [partnerFetched, isPartnerCustody, page, searchText]);

  return {
    isUsersFetching,
    users: usersData.result,
    usersCount: usersData.count,
    usersApiErrorMessage,
    searchText,
    page,
    setPage,
    handleSearchChange,
    reFetchUsers,
  };
};
