import React from 'react';
import { Helmet } from 'react-helmet';
import { DashboardPage } from '../../components/dashboard-page';

const Dashboard = () => (
  <>
    <Helmet>
      <title>Dashboard</title>
    </Helmet>
    <DashboardPage />
  </>
);

export default Dashboard;
