import { ROUTES } from '../../../constants/routes';
import { getArrayWithIndexId } from '../../../helpers/get-array-with-index-id';
import { OFF_RAMP_PROVIDERS, ON_RAMP_PROVIDERS } from '../../../constants/providers-constants';

export const INNER_NAVIGATION_ITEMS = [{
  id: '1',
  href: ROUTES.FIAT_OPERATIONS_SETTINGS,
  name: 'On-ramp',
}, {
  id: '2',
  href: ROUTES.FIAT_OPERATIONS_SETTINGS_OFF_RAMP,
  name: 'Off-ramp',
}];

export const ON_RAMP = {
  cards: ON_RAMP_PROVIDERS,
  faq: getArrayWithIndexId([{
    question: 'How to start accepting fiat payments?',
    answer: `
      To start accepting fiat payments fill out the activation form to complete verification
      with the fiat provider. Once the feature is available, your users will have the option to pay
      in fiat. The funds will be converted and deposited into your Custody balance in the primary
      cryptocurrency of your account.
    `,
  }, {
    question: 'What are NOWPayments’ fees?',
    answer: `
      Fiat payments are available for:
      <ul>
        <li>API</li>
        <li>Payment links (invoices)</li>
        <li>Plugins: Shopify, WooCommerce</li>
      </ul>
    `,
  }, {
    question: 'Which industries can use this feature?',
    answer: `
      The functionality is available for most industries, but its accessibility depends on the chosen provider.
      There may be individual requirements or questions specific to certain industries.
    `,
  }, {
    question: 'In which countries are fiat payments available?',
    answer: `
      The availability of fiat payments depends on the payment provider:
      <ul>
        <li>Guardarian is available in 140+ countries (except the US, the UK & Canada).</li>
        <li>Utorg is available in 170+ countries.</li>
      </ul>
      You can find the complete list of available countries
      <a
        href="https://nowpayments.notion.site/On-ramp-Payment-Providers-Fees-Other-Details-a637be0644a148e39d0d12ab3cae9956?pvs=4"
        target="_blank"
        rel="nofollow noopener noreferrer"
      >here</a>.
    `,
  }, {
    question: 'Which fiat currencies are available for payments?',
    answer: `
      <b>Guardarian:</b><br />
      🇺🇸 USD, 🇪🇺 EUR, 🇨🇦 CAD, 🇦🇺 AUD, 🇰🇷 KRW, 🇮🇱 ILS, 🇷🇴 RON, 🇦🇷 ARS, 🇮🇳 INR, 🇮🇩 IDR, 🇲🇽 MXN, 🇲🇾 MYR, 
      🇹🇷 TRY, 🇨🇱 CLP, 🇵🇪 PEN, 🇵🇭 PHP, 🇹🇭 THB, 🇻🇳 VND, 🇵🇱 PLN, 🇧🇷 BRL
      <br /><br />
      <b>Utorg:</b><br />
      🇳🇿 NZD, 🇪🇺 EUR, 🇵🇱 PLN, 🇨🇱 CLP, 🇦🇪 AED, 🇯🇵 JPY, 🇹🇷 TRY, 🇸🇪 SEK, 🇺🇸 USD, 🇿🇦 ZAR, 🇰🇷 KRW, 🇬🇧 GBP, 
      🇦🇺 AUD, 🇧🇷 BRL, 🇨🇦 CAD, 🇨🇿 CZK, 🇩🇰 DKK, 🇹🇭 THB, 🇳🇴 NOK, 🇮🇩 IDR, 🇧🇬 BGN, 🇮🇳 INR, 🇲🇽 MXN, 🇲🇾 MYR, 🇵🇭 PHP
    `,
  }, {
    question: 'Are there limits for fiat payments?',
    answer: `
      Limits vary based on the chosen provider and payment method. You can find more information
      <a
        href="https://nowpayments.notion.site/On-ramp-Payment-Providers-Fees-Other-Details-a637be0644a148e39d0d12ab3cae9956?pvs=4"
        target="_blank"
        rel="nofollow noopener noreferrer"
      >here</a>.
    `,
  }, {
    question: 'What fees are charged for fiat payment transactions?',
    answer: `
      Fees vary based on the chosen provider and payment method. You can find detailed information on fees
      <a
        href="https://nowpayments.notion.site/On-ramp-Payment-Providers-Fees-Other-Details-a637be0644a148e39d0d12ab3cae9956?pvs=4"
        target="_blank"
        rel="nofollow noopener noreferrer"
      >here</a>.
    `,
  }, {
    question: 'Can I add my own mark up to fiat payments?',
    answer: `
      No, this functionality is only applicable to crypto payments.
    `,
  }, {
    question: 'How does fiat payment flow work via Guardarian?',
    answer: `
      <video controls width="100%" height="auto">
        <source src="/videos/Guardarian.mp4" type="video/mp4">
        <source src="/videos/Guardarian.webm" type="video/webm">
        <source src="/videos/Guardarian.ogv" type="video/ogg">
      </video>
    `,
  }, {
    question: 'How does fiat payment flow work via Utorg?',
    answer: `
      <video controls width="100%" height="auto">
        <source src="/videos/Utorg.mp4" type="video/mp4">
        <source src="/videos/Utorg.webm" type="video/webm">
        <source src="/videos/Utorg.ogv" type="video/ogg">
      </video>
    `,
  }, {
    question: 'Is it possible to avoid redirecting to the fiat provider? Can a white-label solution be implemented?',
    answer: `
      No, as a specific license is required for processing fiat exchanges, and this license is held 
      exclusively by fiat providers.
    `,
  }]),
};

export const OFF_RAMP = {
  cards: OFF_RAMP_PROVIDERS,
  faq: getArrayWithIndexId([{
    question: 'How to withdraw funds in fiat?',
    answer: `
      To withdraw your profits in fiat, follow these steps:
      <ol>
        <li>Activate Custody: All payments from transactions will accumulate in your Custody balance.</li>
        <li>Fill out the activation form to complete verification with the fiat provider. </li>
      </ol>
      Once the fiat provider is successfully activated, you'll have the option to transfer funds from your 
      Custody balance to your bank account.<br />
      Follow these steps:
      <ol>
        <li>Provide your bank account details.</li>
        <li>Whitelist the IP address which you will use to request fund withdrawals.</li>
        <li>Congratulations! You can now seamlessly withdraw profits in fiat through both the user 
        interface and API.</li>
      </ol>
    `,
  }, {
    question: 'How to whitelist my IP address?',
    answer: `
      Payouts can only be requested when using a whitelisted IP address. It's a security measure 
      enabled for each partner account by default.<br />
      You can whitelist your IP anytime by dropping a formal request using your registration email to
      <u><a href="mailto:partners@nowpayments.io">partners@nowpayments.io</a></u>.
    `,
  }, {
    question: 'Which industries can use this feature?',
    answer: `
      The functionality is available for most industries, but its accessibility depends on the chosen 
      provider. There may be individual requirements or questions specific to certain industries.
    `,
  }, {
    question: 'In which countries are fiat payments available?',
    answer: `
      The availability of fiat payments depends on the payment provider:
      <ul>
        <li>Transfi is available in 44+ countries and Eurozone</li>
      </ul>
      You can find the complete list of available countries
      <a
        href="https://nowpayments.notion.site/Off-ramp-Payment-Providers-Fees-Other-Details-d6fd8471035141f08a55631c0e705a85?pvs=4"
        target="_blank"
        rel="nofollow noopener noreferrer"
      >here</a>.
    `,
  }, {
    question: 'Which fiat currencies are available for payouts?',
    answer: `
      <b>Transfi</b><br />
      🇮🇩 IDR, 🇵🇭 PHP, 🇲🇾 MYR, 🇻🇳 VND, 🇯🇵 JPY, 🇹🇭 THB, 🇮🇳 INR, 🇪🇬 EGP, 🇪🇺 EUR, 🇺🇸 USD, 🇬🇧 GBP, 🇩🇰 DKK, 🇦🇺 AUD, 🇳🇿 NZD
    `,
  }, {
    question: 'Are there limits for withdrawing profits in fiat?',
    answer: `
      There are no limits for withdrawing profits in fiat.
    `,
  }, {
    question: 'What fees are charged for fiat withdrawals?',
    answer: `
      Pricing tiers are based on each month’s volumes and usage period. For example:
      <ul>
        <li>Month 1 transaction fee for USD pay-out will be 1%</li>
        <li>If gross volumes for month 1 are USD 750,000, the transaction fee for month 2 will be 0.75%</li>
        <li>If gross volumes for month 2 are USD 6,000,000, the transaction fee for month 3 will be 0.5%</li>
        <li>If gross volumes for month 3 are USD 3,000,000, the transaction fee for month 4 will be 0.75%</li>
      </ul>
    `,
  }]),
};
