import React from 'react';
import { useDispatch } from 'react-redux';
import { PaymentDetailsRadioButtons } from '../payment-details-radio-buttons';
import { PermissionPopup } from '../../../permision-popup';
import { Portal } from '../../../portal';
import { useTimeout } from '../../../../hooks/use-timeout';
import { useUpdateAutoProcessing } from '../../../../api/modules/account/use-update-auto-processing';
import { setPartnerIsAutoPush } from '../../../../store/partner/reducer';
import { getSafeErrorMessageText } from '../../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../../helpers/console-error-message';
import { ResponseType } from '../../../../default-types';
import classes from './styles.module.scss';

type PaymentDetailsDepositsProps = {
  isAutoPush: boolean | null,
  partnerId: string,
};

export const PaymentDetailsDeposits: React.FC<PaymentDetailsDepositsProps> = (props) => {
  const {
    isAutoPush,
    partnerId,
  } = props;

  const dispatch = useDispatch();
  const updateAutoProcessing = useUpdateAutoProcessing();
  const isProcessActive = isAutoPush === true;
  const isDoNotProcessActive = isAutoPush === false;

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isPending, setIsPending] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  const updateAutoProcessingStatus = async (isAutoProcessing: boolean) => {
    let isUpdated = false;
    setIsPending(true);
    const {
      data,
      status,
      errorMessage,
    }: ResponseType = await updateAutoProcessing(partnerId, isAutoProcessing);
    setIsPending(false);

    if (status === 200) {
      dispatch(setPartnerIsAutoPush(data?.isAutoPush ?? false));
      isUpdated = true;
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, `/partner/${partnerId}/update-auto-push`);
      setIsError(true);
    }
    return isUpdated;
  };

  const handleRadioChange = async () => {
    if (isDoNotProcessActive) {
      setIsModalOpen(true);
    } else if (isProcessActive) {
      await updateAutoProcessingStatus(false);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleConsent = async () => {
    const isUpdated = await updateAutoProcessingStatus(true);
    if (isUpdated) {
      setIsModalOpen(false);
    }
  };

  useTimeout(() => setIsError(false), 2000, isError);

  return (
    <div>
      <h3>Extra deposits auto processing</h3>
      <PaymentDetailsRadioButtons
        firstRadioData={{
          title: 'Process extra deposits',
          isActive: isProcessActive,
          description: 'If multiple deposits are sent for one payment, each of them will be processed.',
          value: 'process',
        }}
        secondRadioData={{
          title: 'Do not process extra deposits',
          isActive: isDoNotProcessActive,
          description: 'If multiple deposits are sent for one payment, only the first one is processed.',
          value: 'not-process',
        }}
        onChange={handleRadioChange}
        name="auto-processing"
        isLoading={isPending}
        isError={isError}
      />
      <Portal>
        <PermissionPopup
          className={classes.autoProcessingPopup}
          open={isModalOpen}
          onClose={handleModalClose}
          onConfirm={handleConsent}
          title="Auto processing сonsent"
          description="By enabling this option I consent that NOWPayments may proceed processing all incoming payments paid with wrong assets in accordance with the current rate."
          buttonLabel="Consent"
          pending={isPending}
          mobileFullView
        />
      </Portal>
    </div>
  );
};
