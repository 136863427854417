import * as React from 'react';
import PropTypes from 'prop-types';
import { InputSearch } from '../../../ui/input-search';
import { FilterButton } from '../../../ui/filter-button';
import { isFunction } from '../../../../helpers/is-function';
import { TransactionsFilter } from '../transactions-filter';
import { PaymentsFilter } from '../payments-filter';
import classes from './styles.module.scss';

export const CustomerFullFilter = (props) => {
  const {
    searchValue,
    onChangeSearch,
    activeFilterItemsNumber,
    onSubmit,
    onFilterReset,
    transactionHistoryActive,
    defaultData,
  } = props;

  const [isFilterActive, setIsFilterActive] = React.useState(false);

  const handleFilterToggle = () => {
    setIsFilterActive((prevState) => !prevState);
  };
  const handleFilterClose = () => {
    setIsFilterActive(false);
  };
  const handleFilterSubmit = (data) => {
    if (isFunction(onSubmit)) {
      onSubmit(data, setIsFilterActive);
    }
  };

  const currentIdText = transactionHistoryActive ? 'Transaction ID' : 'Payment ID';

  return (
    <>
      <div className={classes.customerFullFilter}>
        <InputSearch
          className={classes.searchInput}
          placeholder={`Search ${currentIdText}`}
          onChange={onChangeSearch}
          value={searchValue}
        />
        <FilterButton
          className={classes.customerFullFilterButton}
          onClick={handleFilterToggle}
          active={isFilterActive}
          count={activeFilterItemsNumber}
        />
      </div>
      {isFilterActive && (
        <>
          {transactionHistoryActive && (
            <TransactionsFilter
              onSubmit={handleFilterSubmit}
              onClose={handleFilterClose}
              onResetFormData={onFilterReset}
              defaultData={defaultData}
            />
          )}
          {!transactionHistoryActive && (
            <PaymentsFilter
              onSubmit={handleFilterSubmit}
              onClose={handleFilterClose}
              onResetFormData={onFilterReset}
              defaultData={defaultData}
            />
          )}
        </>
      )}
    </>
  );
};

CustomerFullFilter.defaultProps = {
  searchValue: null,
  onChangeSearch: null,
  activeFilterItemsNumber: null,
  onSubmit: null,
  onFilterReset: null,
  transactionHistoryActive: false,
  defaultData: null,
};

CustomerFullFilter.propTypes = {
  searchValue: PropTypes.string,
  onChangeSearch: PropTypes.func,
  activeFilterItemsNumber: PropTypes.number,
  onSubmit: PropTypes.func,
  onFilterReset: PropTypes.func,
  transactionHistoryActive: PropTypes.bool,
  defaultData: PropTypes.object,
};
