import React from 'react';
import cn from 'classnames';
import { MinusIcon } from '../../icons/minus-icon';
import { PlusIcon } from '../../icons/plus-icon';
import classes from './styles.module.scss';

type NumberStepperInputProps = {
  className: string;
  minValue: string;
  maxValue: string;
  value: string;
  step: number;
  onChange: (value: string) => void;
};

export const NumberStepperInput: React.FC<NumberStepperInputProps> = (props) => {
  const {
    className,
    minValue,
    maxValue,
    value,
    step,
    onChange,
  } = props;

  const valueNum = Number(value);
  const minValueNum = Number(minValue);
  const maxValueNum = Number(maxValue);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nextValue = e.target.value;

    if (nextValue === '') {
      onChange(minValue);
      return;
    }

    const nextValueNum = parseInt(nextValue, 10);

    if (nextValueNum > maxValueNum) {
      onChange(maxValue);
    } else if (nextValueNum < minValueNum) {
      onChange(minValue);
    } else {
      onChange(nextValueNum.toString());
    }
  };
  const handleIncrement = () => {
    if (valueNum === maxValueNum) return;

    onChange((valueNum + step).toString());
  };
  const handleDecrement = () => {
    if (valueNum === minValueNum) return;

    onChange((valueNum - step).toString());
  };

  return (
    <div className={cn(classes.numberStepperInput, className)}>
      <button
        className={cn(classes.controllerButton, classes.controllerButtonMinus)}
        type="button"
        onClick={handleDecrement}
      >
        <MinusIcon color={valueNum <= minValueNum ? '#D2D2D2' : '#64ACFF'} />
      </button>
      <input
        className={classes.numberInput}
        type="number"
        min={minValue}
        max={maxValue}
        value={value}
        onChange={handleChange}
      />
      <button
        className={cn(classes.controllerButton, classes.controllerButtonPlus)}
        type="button"
        onClick={handleIncrement}
      >
        <PlusIcon color={valueNum >= maxValueNum ? '#D2D2D2' : '#64ACFF'} />
      </button>
    </div>
  );
};
