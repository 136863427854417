import * as yup from 'yup';

export const TRANSACTIONS_FILTER_SCHEMA = yup.object({
  type: yup.string().trim(),
  status: yup.string().trim(),
});
export const PAYMENTS_FILTER_SCHEMA = yup.object({
  status: yup.string().trim(),
  payCurrency: yup.string().trim(),
});
