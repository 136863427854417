import React from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import cn from 'classnames';
import { isFunction } from '../../../helpers/is-function';
import styles from './styles.module.scss';

export const Button = (props) => {
  const {
    className,
    type,
    href,
    target,
    rel,
    onClick,
    disabled,
    children,
    displayRed,
    displayBorder,
    displayBorderRed,
    isSpaLink,
  } = props;

  const handleClick = () => {
    if (isFunction(onClick)) {
      onClick();
    }
  };
  const handleKeyPress = () => {
    if (isFunction(onClick)) {
      onClick();
    }
  };

  return (
    <>
      {!href && (
        <button
          type={type}
          className={cn([
            styles.button,
            displayRed && styles.buttonRed,
            displayBorder && styles.buttonBorder,
            displayBorderRed && styles.buttonBorderRed,
            className,
          ])}
          onClick={handleClick}
          onKeyPress={handleKeyPress}
          tabIndex="0"
          disabled={disabled}
        >
          {children}
        </button>
      )}
      {href && !isSpaLink && (
        <a
          href={href}
          target={target}
          rel={rel}
          className={cn([
            styles.button,
            displayRed && styles.buttonRed,
            displayBorder && styles.buttonBorder,
            displayBorderRed && styles.buttonBorderRed,
            className,
          ])}
        >
          {children}
        </a>
      )}
      {href && isSpaLink && (
        <Link
          className={cn([
            styles.button,
            displayRed && styles.buttonRed,
            displayBorder && styles.buttonBorder,
            displayBorderRed && styles.buttonBorderRed,
            className,
          ])}
          to={href}
        >
          {children}
        </Link>
      )}
    </>
  );
};

Button.defaultProps = {
  className: '',
  href: null,
  target: null,
  rel: null,
  type: 'button',
  onClick: null,
  disabled: null,
  children: null,
  displayRed: false,
  displayBorder: false,
  displayBorderRed: false,
  isSpaLink: false,
};

Button.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  displayRed: PropTypes.bool,
  displayBorder: PropTypes.bool,
  displayBorderRed: PropTypes.bool,
  isSpaLink: PropTypes.bool,
};
