import * as React from 'react';
import { OnboardingFormStepIconPropsType } from '../../../../types';

export const GenerateApiStep: React.FC<OnboardingFormStepIconPropsType> = (props) => {
  const { isActive } = props;

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13087_53916)">
        <circle
          cx="16"
          cy="16"
          r="15.5"
          fill="white"
          stroke={isActive ? '#64ACFF' : '#D2D2D2'}
        />
        <path
          d="M25.7211 15.0418C26.093 15.4137 26.093 16.0165 25.7211 16.3884L21.9115 20.198C21.7258 20.3842 21.482 20.4771 21.2382 20.4771C20.9943 20.4771 20.7505 20.3842 20.5648 20.198C20.1929 19.8261 20.1929 19.2232 20.5648 18.8513L23.7006 15.7151L20.5648 12.5789C20.1929 12.207 20.1929 11.6041 20.5648 11.2322C20.9362 10.8603 21.5401 10.8603 21.9115 11.2322L25.7211 15.0418ZM11.4352 12.5789L8.29943 15.7151L11.4352 18.8513C11.8071 19.2232 11.8071 19.8261 11.4352 20.198C11.2495 20.3842 11.0057 20.4771 10.7618 20.4771C10.518 20.4771 10.2742 20.3842 10.0885 20.198L6.27893 16.3884C5.90702 16.0165 5.90702 15.4137 6.27893 15.0418L10.0885 11.2322C10.4599 10.8603 11.0638 10.8603 11.4352 11.2322C11.8071 11.6041 11.8071 12.207 11.4352 12.5789ZM18.7891 11.307L14.9795 20.8309C14.8305 21.2033 14.4728 21.4295 14.0952 21.4295C13.9771 21.4295 13.8576 21.4075 13.7419 21.3609C13.2533 21.1656 13.0162 20.6113 13.2114 20.1228L15.1162 15.3608L17.021 10.5988C17.2167 10.1103 17.7714 9.87312 18.2591 10.0684C18.7472 10.2641 18.9843 10.8184 18.7891 11.307Z"
          fill={isActive ? '#64ACFF' : '#D2D2D2'}
        />
      </g>
      <defs>
        <clipPath id="clip0_13087_53916">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
