import * as React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';

export const useCreateSubPartners = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (options) => {
    const name = options?.name ?? null;
    const isOneName = options?.isOneName ?? false;

    const [cancelToken] = getRequestSource();
    const requestPromise = client.post(
      isOneName ? '/sub-partners/balance' : '/sub-partners/balances',
      { name },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      (data) => data,
    );
  }, [getRequestSource]);
};
