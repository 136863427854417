import { BigNumber } from 'bignumber.js';
import { isNumber } from './is-number';

/**
 * ToFixed
 *
 * @param value {string, number}
 * @param digits {number}
 * @param exponential {number}
 * @returns {string}
 */
export const toFixed = (value, digits = 8, exponential = -9) => {
  if (!isNumber(value) && typeof value !== 'string') {
    return value;
  }

  BigNumber.config({ EXPONENTIAL_AT: [exponential, 20] });

  return new BigNumber(value)
    .decimalPlaces(digits)
    .toString();
};
