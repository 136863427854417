import * as React from 'react';
import cn from 'classnames';
import { OnboardingStepsHeader } from '../onboarding-steps-header';
import { Button } from '../../../../../ui/button';
import { CheckboxWithText } from '../../../../../ui/checkbox-with-text';
import classes from './styles.module.scss';

type ActivateCustodyStepProps = {
  onBack: () => void,
  onContinue: () => void,
  isStartActivateCustodyShow: boolean,
  onActivate: () => Promise<void>,
};

export const ActivateCustodyStep: React.FC<ActivateCustodyStepProps> = (props) => {
  const {
    onBack,
    onContinue,
    isStartActivateCustodyShow,
    onActivate,
  } = props;

  const [isCheckboxChecked, setIsCheckBoxChecked] = React.useState(false);
  const [isCheckboxError, setIsCheckboxError] = React.useState(false);

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target?.checked;
    setIsCheckBoxChecked(isChecked);
    if (isChecked) {
      setIsCheckboxError(false);
    } else {
      setIsCheckboxError(true);
    }
  };
  const handleActivate = async () => {
    if (!isCheckboxChecked) {
      setIsCheckboxError(true);
    } else {
      await onActivate();
    }
  };

  return (
    <div className={classes.activateCustodyStep}>
      <OnboardingStepsHeader
        title="Activate Custody"
        hasBackButton
        onClick={onBack}
      />
      {isStartActivateCustodyShow ? (
        <div className={classes.activateCustodyStepStart}>
          <p className={classes.activateCustodyStepStartDescription}>
            Elevate your funds management and get access to limitless blockchain operations with:
          </p>
          <div className={classes.activateCustodyStepStartFeatures}>
            <p className={cn([
              classes.activateCustodyStepStartListTitle,
              classes.activateCustodyStepStartListTitleBlue,
            ])}
            >
              Smart security
            </p>
            <ul className={classes.activateCustodyStepStartList}>
              <li className={cn([
                classes.activateCustodyStepStartListItem,
                classes.activateCustodyStepStartListItemStorage,
              ])}
              >
                <b>Safe storage </b>
                guaranteed through a combination of hot and cold storage.
              </li>
              <li className={cn([
                classes.activateCustodyStepStartListItem,
                classes.activateCustodyStepStartListItemRisk,
              ])}
              >
                <b>Risk control </b>
                for withdrawals requires authorized IPs and wallets, along with 2FA protection.
              </li>
            </ul>
          </div>
          <div className={classes.activateCustodyStepStartFeatures}>
            <p className={cn([
              classes.activateCustodyStepStartListTitle,
              classes.activateCustodyStepStartListTitleGreen,
            ])}
            >
              Funds control
            </p>
            <ul className={classes.activateCustodyStepStartList}>
              <li className={cn([
                classes.activateCustodyStepStartListItem,
                classes.activateCustodyStepStartListItemConversions,
              ])}
              >
                <b>Instant off-chain conversions </b>
                without network fees.
              </li>
              <li className={cn([
                classes.activateCustodyStepStartListItem,
                classes.activateCustodyStepStartListItemCosts,
              ])}
              >
                <b>Minimal transaction costs </b>
                reduce transaction fees, which are only incurred when withdrawing funds.
              </li>
            </ul>
          </div>
          <div className={classes.activateCustodyStepStartInteractive}>
            <CheckboxWithText
              className={classes.activateCustodyStepStartCheckbox}
              error={isCheckboxError}
              checked={isCheckboxChecked}
              onChange={handleCheck}
            >
              I have read and accept
              {' '}
              <a
                href="/files/np-custody-agreement.pdf"
                target="_blank"
              >
                Custody Solution
              </a>
              {' '}
              user agreement
            </CheckboxWithText>
            <div className={classes.activateCustodyStepStartButtons}>
              <Button
                className={classes.activateCustodyStepButton}
                onClick={handleActivate}
              >
                Activate
              </Button>
              <Button
                className={classes.activateCustodyStepButton}
                onClick={onContinue}
                displayBorder
              >
                Skip for now
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.activateCustodyStepSuccess}>
          <img
            className={classes.activateCustodyStepSuccessImage}
            src="/images/dashboard-page/activated-castody.svg"
            alt="Custody successfully activated"
          />
          <h4 className={classes.activateCustodyStepSuccessTitle}>
            Custody successfully activated
          </h4>
          <p className={classes.activateCustodyStepSuccessDescription}>
            The funds you get from payments will now be collected on your Custody balance. You can
            convert and withdraw them at any time.
          </p>
          <Button
            className={cn([
              classes.activateCustodyStepButton,
              classes.activateCustodyStepSuccessButton,
            ])}
            onClick={onContinue}
          >
            Continue
          </Button>
        </div>
      )}
    </div>
  );
};
