import React from 'react';
import classes from '../whitelist-popup/styles.module.scss';
import { Button } from '../../../ui/button';
import { LoaderIcon } from '../../../icons/loader-icon';

type ContentForInfoType = {
  ipAddress: string | null,
  onClick: () => void,
  onDelete: () => void,
  fetching: boolean,
  apiError: string | null,
};

export const ContentForDeleteAddress: React.FC<ContentForInfoType> = (props) => {
  const {
    ipAddress,
    onClick,
    onDelete,
    fetching,
    apiError,
  } = props;

  return (
    <>
      <div>
        Are you sure you want to delete the
        {' '}
        {ipAddress}
        {' '}
        IP address from the Whitelist? Once you do that, you will not be able to perform
        withdrawals with this address.
      </div>
      <div className={classes.whitelistPopupButtons}>
        <Button
          className={classes.whitelistPopupButton}
          onClick={onDelete}
          displayRed
        >
          {fetching ? (
            <LoaderIcon size={30} path="/images/loader-white-on-red-icon.gif" />
          ) : 'Yes, delete'}
        </Button>
        <Button
          className={classes.whitelistPopupButton}
          onClick={onClick}
          displayBorder
        >
          Cancel
        </Button>
      </div>
      {apiError && (
        <div className={classes.whitelistPopupError}>{apiError}</div>
      )}
    </>
  );
};
