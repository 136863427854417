export const FIAT_POSITIONS = {
  usd: 1,
  eur: 2,
  cad: 3,
  aud: 4,
  gbp: 5,
  DEFAULT_POSITION: 500,
};

export const FIAT_HIDDEN_CURRENCIES = ['USDTTRC20', 'USDT'];

export const WARNING_CURRENCIES = ['ETH', 'TUSD', 'PAX', 'USDTERC20',
  'WABI', 'HT', 'BAT', 'MCO', 'ETC', 'BUSD',
  'CRO', 'GT', 'STPT', 'VET', 'SXP'];
