import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import styles from "./styles.module.scss";

const Input = ({
  faIcon,
  placeholder,
  onChange,
  value,
  styleCustom,
  readOnly = false,
  classNames = [],
  ...props
}) => {
  return (
    <div
      className={cn(styles.inputBlock, ...classNames)}
      style={{ ...styleCustom }}
    >
      {faIcon && (
        <FontAwesomeIcon
          icon={faIcon}
          size="1x"
          data-tooltip-content=""
          className={styles.icon}
        />
      )}
      {!readOnly ? (
        <input
          type="text"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={faIcon ? styles.withIcon : styles.withoutIcon}
          {...props}
        />
      ) : (
        <input
          type="text"
          placeholder={placeholder}
          value={value}
          readOnly={readOnly}
          className={faIcon ? styles.withIcon : styles.withoutIcon}
          {...props}
        />
      )}
    </div>
  );
};

export default Input;
