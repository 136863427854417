import React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';

export const CREATE_WHITELIST_ADDRESSES_PATH = '/whitelist/addresses/send-email';
const mapFn = (data) => data;

export const useCreateWhitelistAddresses = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (addresses) => {
    const [cancelToken] = getRequestSource();
    const requestPromise = client.post(
      CREATE_WHITELIST_ADDRESSES_PATH,
      { whitelistData: addresses },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
