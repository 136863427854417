import React from "react";
import withModal from "../../../../components/with-modal";
import Loader from "../../../../components/loader";
import TfaTypeSelector from "./tfa-type-selector";
import TfaEmailSetup from "./tfa-email-setup";
import TfaAppSetup from "./tfa-app-setup";
import styles from "./styles.module.scss";

function SetTfaModal(props) {
  const {
    setupAppTfa,
    addAppTfa,
    secret,
    onClose,
    isLoading,
    setVerifyCode,
    verifyCode,
    setupEmailTfa,
    modalState,
    appTfaError,
    emailTfaError,
    appTfaButtonState,
    appTfaFailureMessage,
  } = props;

  return (
    <div className={styles.tfaTypeModal}>
      {isLoading && (
        <div className={styles.loader}>
          <Loader width={60} />
        </div>
      )}
      <div className={styles.closeButton} onClick={onClose}></div>
      {
        {
          select: (
            <TfaTypeSelector
              setupEmailTfa={setupEmailTfa}
              setupAppTfa={setupAppTfa}
              appTfaError={appTfaError}
              emailTfaError={emailTfaError}
            />
          ),
          app: (
            <TfaAppSetup
              addAppTfa={addAppTfa}
              secret={secret}
              setVerifyCode={setVerifyCode}
              verifyCode={verifyCode}
              buttonState={appTfaButtonState}
              buttonFailureMessage={appTfaFailureMessage}
            />
          ),
          email: <TfaEmailSetup />,
        }[modalState]
      }
    </div>
  );
}

export default withModal(SetTfaModal);
