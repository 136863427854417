import * as React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';
import { paymentMapFnItem } from '../../helpers/account/map-fns';

const mapFn = (data) => ({
  result: (data?.result ?? []).map(paymentMapFnItem),
  count: data?.count ?? null,
});

export const useSubPartnersPayments = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (options) => {
    const limit = options?.limit || -1;
    const page = options?.page || 1;
    const orderBy = options?.orderBy || 'desc';
    const sortBy = options?.sortBy || null;
    const id = options?.id || null;
    const filter = options?.filter || null;
    const status = options?.status || null;
    const pay_currency = options?.payCurrency || null;
    const sub_partner_id = options?.customerId ?? null;
    const date_from = options?.dateFrom || null;
    const date_to = options?.dateTo || null;

    const [cancelToken] = getRequestSource();
    const requestPromise = client.get(
      '/sub-partners/payments',
      {
        params: {
          limit,
          page,
          orderBy,
          sortBy,
          id,
          filter,
          status,
          pay_currency,
          sub_partner_id,
          date_from,
          date_to,
        },
      },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
