export const getShortStringWithEllipsis = (
  str: string,
  maxLength: number = 19,
  prefixLength: number = 8,
  suffixLength: number = 8,
): string => {
  if (str.length > maxLength) {
    const prefix = str.slice(0, prefixLength);
    const suffix = str.slice(-suffixLength);
    return `${prefix}...${suffix}`;
  }

  return str;
};
