import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { InputSearch } from '../../ui/input-search';
import { FilterButton } from '../../ui/filter-button';
import { isFunction } from '../../../helpers/is-function';
import classes from './styles.module.scss';

export const CustomSearchFilter = (props) => {
  const {
    className,
    onChangeSearch,
    activeFilterItemsNumber,
    placeholder,
    children,
    onFilterClose,
    AdditionElements,
  } = props;

  const [isFilterActive, setIsFilterActive] = React.useState(false);

  const handleFilterToggle = () => {
    setIsFilterActive((prevState) => !prevState);
  };
  const handleFilterClose = () => {
    setIsFilterActive(false);
  };

  React.useEffect(() => {
    if (isFunction(onFilterClose)) {
      onFilterClose(setIsFilterActive);
    }
  }, [onFilterClose]);

  return (
    <>
      <div className={cn(classes.customSearchFilter, className)}>
        <InputSearch
          className={classes.customSearchFilterInput}
          placeholder={placeholder}
          onChange={onChangeSearch}
        />
        <FilterButton
          className={classes.customSearchFilterButton}
          onClick={handleFilterToggle}
          active={isFilterActive}
          count={activeFilterItemsNumber}
        />
        {AdditionElements}
      </div>
      {isFilterActive && (
        <div className={classes.customSearchFilterContent}>
          <div
            className={classes.customSearchFilterClose}
            onClick={handleFilterClose}
          >
            <div className={classes.customSearchFilterCloseLine} />
            <div className={classes.customSearchFilterCloseLine} />
          </div>
          {children}
        </div>
      )}
    </>
  );
};

CustomSearchFilter.defaultProps = {
  className: null,
  onChangeSearch: null,
  activeFilterItemsNumber: null,
  children: null,
  filterClose: false,
  AdditionElements: null,
};

CustomSearchFilter.propTypes = {
  className: PropTypes.string,
  onChangeSearch: PropTypes.func,
  activeFilterItemsNumber: PropTypes.number,
  children: PropTypes.node,
  filterClose: PropTypes.bool,
  AdditionElements: PropTypes.node,
};
