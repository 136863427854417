import React from 'react';
import classNames from 'classnames';
import SmallLoader from '../small-loader';
import styles from './styles.module.scss';
export default function Button({
  children,
  variant = 'default',
  disabled = false,
  className,
  onClick,
  type = 'button',
  loading = false,
}) {
  return (
    <button
      className={classNames(styles.button, styles[variant], className)}
      disabled={disabled || loading}
      onClick={onClick}
      type={type}
    >
      {loading ? <SmallLoader width={22} height={22} /> : children || 'Click!'}
    </button>
  );
}
