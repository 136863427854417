import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from '../../../../helpers/format-date';
import { isFunction } from '../../../../helpers/is-function';
import { DATE_PATTERN, PAYMENT_ORIGIN_TYPES } from '../../../../constants/app-constants';
import { getColorStatus } from '../../../../helpers/get-color-status';
import { normalizeAmountWithTickerString } from '../../../../helpers/normalize-amount-with-ticker-string';
import classes from './styles.module.scss';

export const PaymentHistoryTableRow = (props) => {
  const {
    className,
    onClick,
    isDesktop,
    currenciesObject,
    payment,
  } = props;

  const paymentId = payment?.paymentId ?? null;
  const orderId = payment?.orderId ?? '-';

  const originalPriceNormalized =
    normalizeAmountWithTickerString(payment?.originalPrice, currenciesObject);
  const payPriceNormalized =
    normalizeAmountWithTickerString(payment?.payPrice, currenciesObject, payment?.payCurrency);
  const outcomePriceNormalized =
    normalizeAmountWithTickerString(payment?.outcomePrice, currenciesObject, payment?.outcomeCurrency);

  const status = payment?.status ?? '';
  const statusColor = getColorStatus(status);
  const statusNormalized = status || '-';

  const createdDateFormatted = formatDate(payment?.createdAt, DATE_PATTERN);
  const createdDateNormalized = createdDateFormatted || '-';
  const updatedDateFormatted = formatDate(payment?.updatedAt, DATE_PATTERN);
  const updatedDateNormalized = updatedDateFormatted || '-';

  const originTypeRepeatedText = payment?.originType === PAYMENT_ORIGIN_TYPES.REPEATED ? 'Re-deposit' : null;
  const originTypeChildText = payment?.originType === PAYMENT_ORIGIN_TYPES.CHILD ? 'Wrong Asset' : null;
  const currentOriginTypeText = originTypeRepeatedText || originTypeChildText || null;

  const handleRowClick = () => {
    if (isFunction(onClick)) {
      onClick(payment);
    }
  };

  return (
    <tr
      className={className}
      onClick={handleRowClick}
    >
      <td>
        <div>
          {paymentId}
        </div>
        {!isDesktop && (
          <div data-overflow="hidden" className={classes.ellipsis}>
            {orderId}
          </div>
        )}
      </td>
      {isDesktop && (
        <>
          <td data-color="black">
            {orderId}
          </td>
          <td data-color="black">
            {originalPriceNormalized}
          </td>
        </>
      )}
      <td>
        <div>
          {payPriceNormalized}
        </div>
        <div data-margin="top">
          {outcomePriceNormalized}
        </div>
      </td>
      <td>
        {currentOriginTypeText}
        <div style={{ color: statusColor }}>
          {statusNormalized}
        </div>
      </td>
      {isDesktop && (
        <td>
          <div>
            {createdDateNormalized}
          </div>
          <div data-margin="top">
            {updatedDateNormalized}
          </div>
        </td>
      )}
    </tr>
  );
};

PaymentHistoryTableRow.defaultProps = {
  className: null,
  onClick: null,
  isDesktop: false,
  currenciesObject: {},
  payment: {},
};

PaymentHistoryTableRow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  isDesktop: PropTypes.bool,
  currenciesObject: PropTypes.object,
  payment: PropTypes.object,
};
