import { toFixed } from './to-fixed';
import { safeToUpperCase } from './safe-to-upper-case';

/**
 * @param {Object} payment
 * @param {Number} fixedDigits
 * @param {Object} currenciesObject
 * @returns {string|null}
 */

export const getPaymentServiceFee = (payment = {}, fixedDigits = 8, currenciesObject) => {
  const outcomeCurrency = payment?.outcomeCurrency ?? '';
  const outcomeTicker = currenciesObject?.[outcomeCurrency]?.ticker || outcomeCurrency;
  const outcomeTickerUpperCased = safeToUpperCase(outcomeTicker);
  const commissionAmount = payment?.commissionAmount ?? null;
  const commissionAmountNumber = commissionAmount ? Number(commissionAmount) * 2 : null;
  const commissionAmountToFixed = !Number.isNaN(commissionAmountNumber)
    ? toFixed(commissionAmountNumber, fixedDigits)
    : null;

  return commissionAmountToFixed
    ? `${commissionAmountToFixed} ${outcomeTickerUpperCased}`
    : null;
};
