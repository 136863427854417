import React from 'react';
import cn from 'classnames';
import classes from './styles.module.scss';

const Checkbox = ({
  value,
  onChange,
  error = false,
  defaultChecked,
  checked,
}) => (
  <label className={cn(
    classes.checkbox, {
      [classes.error]: error,
    },
  )}>
    <input
      className={classes.checkboxInput}
      type="checkbox"
      value={value}
      onChange={onChange}
      defaultChecked={defaultChecked}
      checked={checked}
    />
    <div className={classes.checkboxBox} />
  </label>
);

export default Checkbox;
