import { ExtendedWallet } from '../types';

export const getNormalizedWalletsPriority = (wallets: Array<ExtendedWallet>, targetWalletId: string) => {
  let oldIndex = null;

  const newWallets = wallets.map((wallet, i) => {
    if (wallet.id === targetWalletId) {
      oldIndex = i;
    }
    return { id: wallet.id };
  });

  if (oldIndex) {
    const removedWalletData = newWallets.splice(oldIndex, 1)[0];
    newWallets.unshift(removedWalletData);
  }

  return newWallets.map((el, i) => (
    {
      id: el.id,
      priority: i,
    }
  ));
};
