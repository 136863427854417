import React from 'react';
import classes from './styles.module.scss';

type EmptySearchProps = {
  children: React.ReactNode,
  imagePath: string,
};

export const EmptySearch = React.memo((props: EmptySearchProps) => {
  const {
    children,
    imagePath,
  } = props;

  return (
    <div className={classes.emptySearch}>
      <img
        src={imagePath}
        alt="Empty"
        decoding="async"
        loading="lazy"
        width={48}
        height={48}
      />
      <div className={classes.emptySearchText}>
        {children}
      </div>
    </div>
  );
});
