const DEFAULT_TEXT = 'Something went wrong';

/**
 * @param {string} message
 * @param {string} text
 * @returns {string|string}
 */
export const getSafeErrorMessageText = (message, text = DEFAULT_TEXT) => (
  typeof message === 'string' ? message : text
);
