import React from 'react';
import cn from 'classnames';
import { IpnSettings } from '../../../ipn-settings';

type IpnSectionProps = {
  className: string,
  classNameActive: string,
};

export const IpnSection: React.FC<IpnSectionProps> = (props) => {
  const {
    className,
    classNameActive,
  } = props;

  const [isRendered, setIsRendered] = React.useState(false);

  React.useEffect(() => {
    setIsRendered(true);
  }, []);

  return <IpnSettings className={cn(className, isRendered && classNameActive)} />;
};
