import * as React from 'react';
import { safeToUpperCase } from '../helpers/safe-to-upper-case';
import { hasElementsInArray } from '../helpers/has-elements-in-array';
import { FIAT_HIDDEN_CURRENCIES } from '../constants/currencies-constants';

/**
 * @param { Array<object> } currencies
 * @param { Boolean } filterHidden
 * @returns {{currenciesObject: Object}}
 */
export const useCurrenciesToObject = (currencies, filterHidden = true) => {
  const currenciesObject = React.useMemo(() => {
    if (!hasElementsInArray(currencies)) {
      return {};
    }

    const result = {};

    currencies.forEach((item) => {
      if (item.isFiat && filterHidden && FIAT_HIDDEN_CURRENCIES.includes(safeToUpperCase(item.code))) {
        return;
      }

      result[safeToUpperCase(item.code)] = item;
    });

    return result;
  }, [currencies]);

  return { currenciesObject };
};
