import React from 'react';
import { useSendClientError } from '../../api/modules/account/use-send-client-error';
import styles from './styles.module.scss';

export const ErrorFallback = (props) => {
  const { error, resetErrorBoundary } = props;
  const sendClientError = useSendClientError();

  const send = async () => {
    void sendClientError({
      message: error.message,
      href: window.location.href,
    });
  };

  React.useEffect(() => {
    void send();
  }, []);

  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>Sorry. Something went wrong</h3>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};
