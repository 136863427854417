import * as React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button } from '../../../ui/button';
import { InputSearch } from '../../../ui/input-search';
import { LoaderIcon } from '../../../icons/loader-icon';
import { customerBalancesFetchedSelector } from '../../../../store/customer-management/selectors';
import classes from './styles.module.scss';

export const CustomersFilter = (props) => {
  const {
    onClick,
    onChangeSearch,
    buttonShow,
    onBalances,
    pending,
  } = props;

  const customerBalancesFetched = useSelector(customerBalancesFetchedSelector());

  const currentButtonBalanceText = !customerBalancesFetched ? 'Show balances' : 'Update balances';

  return (
    <div className={classes.subscriptionFilter}>
      {buttonShow && (
        <Button
          className={classes.button}
          onClick={onClick}
        >
          Add customers
        </Button>
      )}
      {buttonShow && onBalances && (
        <Button
          className={cn(classes.button, classes.buttonBalances)}
          onClick={onBalances}
          disabled={pending}
        >
          {pending ? (
            <LoaderIcon
              size={25}
              path="/images/loader-white-on-blue-icon.gif"
            />
          ) : currentButtonBalanceText}
        </Button>
      )}
      <InputSearch
        className={classes.searchInput}
        placeholder="Search Customer ID, Name"
        onChange={onChangeSearch}
      />
    </div>
  );
};

CustomersFilter.defaultProps = {
  onClick: null,
  onChangeSearch: null,
  buttonShow: false,
  onBalances: null,
  pending: false,
};

CustomersFilter.propTypes = {
  onClick: PropTypes.func,
  onChangeSearch: PropTypes.func,
  buttonShow: PropTypes.bool,
  onBalances: PropTypes.func,
  pending: PropTypes.bool,
};
