import * as React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';
import { subscriptionPlanItemMapFn } from '../../helpers/account/map-fns';

const mapFn = (data) => (
  subscriptionPlanItemMapFn(data?.result ?? {})
);

export const useCreateSubscriptionsPlan = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (options) => {
    const title = options?.title ?? null;
    const interval_day = options?.periodDays ?? null;
    const amount = options?.amount ?? null;
    const currency = options?.currency ?? null;
    const ipn_callback_url = options?.ipnCallbackUrl ?? null;
    const success_url = options?.successUrl ?? null;
    const cancel_url = options?.cancelUrl ?? null;
    const partially_paid_url = options?.partiallyPaidUrl ?? null;

    const [cancelToken] = getRequestSource();
    const requestPromise = client.post(
      '/subscriptions/plans',
      {
        title,
        interval_day,
        amount,
        currency,
        ipn_callback_url,
        success_url,
        cancel_url,
        partially_paid_url,
      },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
