import React from 'react';
import PropTypes from 'prop-types';

export const MassPayoutsIcon = (props) => {
  const { color } = props;

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M10.0352 3.46398L10.0352 9.8558L7.85334 9.8558L7.85334 3.46398L10.0352 3.46398Z" />
      <path d="M15 10.9211H3V17.3129H15V10.9211Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.97472 4.36615L8.99686 1.01169L12.9184 4.23391L11.5148 5.86345L9.00946 3.80481L6.39289 5.98703L4.97472 4.36615Z" />
    </svg>
  );
};

MassPayoutsIcon.defaultProps = {
  color: '#616161',
};

MassPayoutsIcon.propTypes = {
  color: PropTypes.string,
};
