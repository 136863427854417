import React from "react";
import styles from "./styles.module.scss";

function Pagination(props) {
  const { setUniversalState, currentPage, isNextPageExist } = props;

  return (
    <div className={styles.body}>
      <div
        className={currentPage !== 1 ? styles.active : styles.inactive}
        onClick={() => {
          if (currentPage !== 1) {
            setUniversalState("page", currentPage - 1);
          }
        }}
      >
        &larr;
      </div>
      <div className={styles.active}>{currentPage}</div>
      <div
        className={isNextPageExist ? styles.active : styles.inactive}
        onClick={() => {
          if (isNextPageExist) {
            setUniversalState("page", currentPage + 1);
          }
        }}
      >
        &rarr;
      </div>
    </div>
  );
}

export default Pagination;
