import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { isFunction } from '../../../helpers/is-function';
import classes from './styles.module.scss';

export const TableTabs = (props) => {
  const {
    className,
    activeTabKey,
    firstTabKey,
    firstTabName,
    secondTabKey,
    secondTabName,
    thirdTabKey,
    thirdTabName,
    onClick,
  } = props;

  const handleClick = (name) => () => {
    if (isFunction(onClick)) {
      onClick(name);
    }
  };

  return (
    <div className={cn(classes.tableTabs, className)}>
      <div
        className={cn([
          classes.tableTab,
          activeTabKey === firstTabKey && classes.tableTabActive,
        ])}
        onClick={handleClick(firstTabKey)}
        onKeyPress={handleClick(firstTabKey)}
        tabIndex={0}
        role="button"
      >
        {firstTabName}
      </div>
      <div
        className={cn([
          classes.tableTab,
          activeTabKey === secondTabKey && classes.tableTabActive,
        ])}
        onClick={handleClick(secondTabKey)}
        onKeyPress={handleClick(secondTabKey)}
        tabIndex={0}
        role="button"
      >
        {secondTabName}
      </div>
      {thirdTabKey && thirdTabName && (
        <div
          className={cn([
            classes.tableTab,
            activeTabKey === thirdTabKey && classes.tableTabActive,
          ])}
          onClick={handleClick(thirdTabKey)}
          onKeyPress={handleClick(thirdTabKey)}
          tabIndex={0}
          role="button"
        >
          {thirdTabName}
        </div>
      )}
    </div>
  );
};

TableTabs.defaultProps = {
  className: null,
  activeTabKey: null,
  firstTabKey: null,
  firstTabName: null,
  secondTabName: null,
  secondTabKey: null,
  thirdTabKey: null,
  thirdTabName: null,
  onClick: null,
};

TableTabs.propTypes = {
  className: PropTypes.string,
  activeTabKey: PropTypes.string,
  firstTabKey: PropTypes.string,
  firstTabName: PropTypes.string,
  secondTabName: PropTypes.string,
  secondTabKey: PropTypes.string,
  thirdTabKey: PropTypes.string,
  thirdTabName: PropTypes.string,
  onClick: PropTypes.func,
};
