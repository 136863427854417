import { hasElementsInArray } from '../../../helpers/has-elements-in-array';
import { getDateTime } from '../../../helpers/get-date-time';
import { safeToLowerCase } from '../../../helpers/safe-to-lower-case';
import dateFormat from '../../../libs/date-format';
import { safeToUpperCase } from '../../../helpers/safe-to-upper-case';

/**
 * @param {array<object>} payouts
 * @param {object} currenciesObject
 * @returns {Array<object>}
 */
export const normalizeMassPayouts = (payouts, currenciesObject = {}) => {
  if (!hasElementsInArray(payouts)) {
    return [];
  }

  return payouts.map((item) => {
    const payoutCurrencyUpperCased = safeToUpperCase(item?.currency ?? '');
    const currencyFound = currenciesObject[payoutCurrencyUpperCased] || {};
    const ticker = currencyFound?.ticker ?? payoutCurrencyUpperCased;
    const network = currencyFound?.network ?? null;

    return { ...item, ticker, network };
  });
};

/**
 * @param {Array<object>} data
 * @param {String} searchText
 * @returns {Array<object>}
 */
export const getFilteredMassPayoutsWithSearch = (data, searchText = '') => {
  if (!hasElementsInArray(data)) {
    return [];
  }

  const regExp = new RegExp(searchText, 'i');

  return data.filter((item) => {
    const id = item?.id ?? '';
    const batchId = item?.batchWithdrawalId ?? '';

    return regExp.test(id) || regExp.test(batchId);
  });
};

/**
 * @param {Array<object>} data
 * @param {Object} filterData
 * @returns {Array<object>}
 */
export const getFilteredMassPayoutsWithFilter = (data, filterData = {}) => {
  if (!hasElementsInArray(data)) {
    return [];
  }

  const dateFrom = filterData?.dateFrom ?? null;
  const dateTo = filterData?.dateTo ?? null;
  const currentDateTo = dateTo || dateFrom;
  const dateFromTime = getDateTime(dateFrom, 'date');
  const dateToTime = getDateTime(currentDateTo, 'date');
  const searchStatus = filterData?.status ?? '';
  const searchCurrency = filterData?.currency ?? '';
  const searchCurrencyLowerCased = safeToLowerCase(searchCurrency);
  const searchPayoutDescription = filterData?.payoutDescription ?? '';

  return data.filter((item) => {
    const itemCreatedAt = item?.createdAt ?? '';
    const itemCreatedAtTime = getDateTime(itemCreatedAt, 'date');
    const itemStatus = item?.status ?? '';
    const itemStatusLowerCased = safeToLowerCase(itemStatus);
    const itemCurrency = item?.currency ?? '';
    const itemCurrencyLowerCased = safeToLowerCase(itemCurrency);
    const itemPayoutDescription = item?.payoutDescription ?? '';

    const isDatesMatch = itemCreatedAtTime >= dateFromTime && itemCreatedAtTime <= dateToTime;
    const isStatusMatch = itemStatusLowerCased === searchStatus;
    const isCurrencyMatch = itemCurrencyLowerCased === searchCurrencyLowerCased;
    const isPayoutDescriptionMatch = itemPayoutDescription.includes(searchPayoutDescription);

    const isDatesNotFound = (Boolean(dateFrom) && Boolean(currentDateTo)) && !isDatesMatch;
    const isStatusNotFound = Boolean(searchStatus) && !isStatusMatch;
    const isCurrencyNotFound = Boolean(searchCurrencyLowerCased) && !isCurrencyMatch;
    const isPayoutDescriptionNotFound = Boolean(searchPayoutDescription) && !isPayoutDescriptionMatch;

    const isNotAllowed = isDatesNotFound
      || isStatusNotFound
      || isCurrencyNotFound
      || isPayoutDescriptionNotFound;

    return !isNotAllowed;
  });
};

/**
 * @param data {Array}
 * @returns {[]}
 */
export const createBodyForExportDocument = (data) => {
  const hasData = hasElementsInArray(data);

  if (!hasData) {
    return [];
  }

  return data.map((item) => ([
    item?.id ?? '',
    item?.batchWithdrawalId ?? '',
    item?.amount ?? '',
    item?.currency ?? '',
    item?.address ?? '',
    item?.hash ?? '',
    item?.status ?? '',
    item?.fee ? `${item.fee} ${item?.currency ?? ''}` : '',
    dateFormat(item?.createdAt ?? ''),
    dateFormat(item?.updatedAt ?? ''),
  ]));
};
