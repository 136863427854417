import React from 'react';
import PropTypes from 'prop-types';

export const CustodyIcon = (props) => {
  const { color } = props;

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.79607 16.4352C8.87913 16.4786 8.972 16.5007 9.06487 16.5C9.15774 16.4992 9.24986 16.4764 9.33367 16.4323L12.0096 15.0018C12.7684 14.5973 13.3626 14.145 13.8262 13.6185C14.8342 12.4712 15.3847 11.0069 15.3749 9.49698L15.3431 4.51649C15.3401 3.94283 14.9633 3.43096 14.4061 3.24489L9.42805 1.57467C9.12829 1.47318 8.79985 1.47539 8.50538 1.57982L3.54618 3.30961C2.99197 3.50303 2.622 4.01858 2.62502 4.59297L2.65673 9.56979C2.66655 11.0819 3.23586 12.5395 4.26046 13.6751C4.7286 14.1943 5.32811 14.64 6.09524 15.0379L8.79607 16.4352ZM8.08767 10.5817C8.19942 10.6891 8.34439 10.742 8.48936 10.7406C8.63433 10.7398 8.77855 10.6854 8.88879 10.5766L11.8131 7.69357C12.0328 7.47661 12.0306 7.12801 11.8086 6.91399C11.5858 6.69997 11.2272 6.70144 11.0075 6.9184L8.48106 9.40865L7.44663 8.41432C7.22389 8.2003 6.866 8.20251 6.64552 8.41946C6.4258 8.63642 6.42807 8.98503 6.65081 9.19905L8.08767 10.5817Z"
      />
    </svg>
  );
};

CustodyIcon.defaultProps = {
  color: '#616161',
};

CustodyIcon.propTypes = {
  color: PropTypes.string,
};
