import React from 'react';
import cn from 'classnames';
import { useInitState, useActions } from './hooks';
import { ExternalLinkWithUnderline } from '../external-link-with-underline';
import { IpnPopup } from './components/ipn-popup';
import { SetUpIpn } from './components/set-up-ipn';
import { SetUpNotifications } from './components/set-up-notifications';
import classes from './styles.module.scss';

type IpnSettingsProps = {
  className: string,
};

export const IpnSettings: React.FC<IpnSettingsProps> = (props) => {
  const { className } = props;

  const {
    ipnKey,
    ipnCallbackUrl,
    isIpnKeyLoading,
    isIpnCallbackUrlLoading,
    isIpnCallbackUrlUpdated,
    ipnTimeout,
    ipnNumRecurrent,
    getIpnKey,
    updateIpnUrl,
    updateRecurrentNotifications,
  } = useInitState();

  const {
    popupType,
    handlePopupRegenerateOpen,
    handlePopupNewIpnKeyOpen,
    handlePopupClose,
    regenerateIpnKey,
    newIpnKey,
    isNewIpnKeyLoading,
    newIpnKeyError,
  } = useActions(getIpnKey);

  return (
    <div className={cn(className, classes.ipnSettings)}>
      <ul className={classes.ipnList}>
        <SetUpIpn
          ipnKey={ipnKey}
          isIpnKeyLoading={isIpnKeyLoading}
          ipnCallbackUrl={ipnCallbackUrl}
          isIpnCallbackUrlLoading={isIpnCallbackUrlLoading}
          isIpnCallbackUrlUpdated={isIpnCallbackUrlUpdated}
          onPopupRegenerate={handlePopupRegenerateOpen}
          onPopupNewIpnKey={handlePopupNewIpnKeyOpen}
          onSaveCallbackUrl={updateIpnUrl}
        />
        {ipnKey && (
          <SetUpNotifications
            ipnTimeout={ipnTimeout}
            ipnNumRecurrent={ipnNumRecurrent}
            onSaveNotifications={updateRecurrentNotifications}
          />
        )}
      </ul>
      <div className={classes.ipnDocsLinkContainer}>
        <ExternalLinkWithUnderline
          className={classes.ipnDocsLink}
          href="https://documenter.getpostman.com/view/7907941/2s93JusNJt#api-documentation"
          text="IPN docs"
        />
      </div>
      <IpnPopup
        type={popupType}
        onClose={handlePopupClose}
        onProceed={handlePopupNewIpnKeyOpen}
        onGenerateIpnKey={regenerateIpnKey}
        newIpnKey={newIpnKey}
        isNewIpnKeyLoading={isNewIpnKeyLoading}
        newIpnKeyError={newIpnKeyError}
      />
    </div>
  );
};
