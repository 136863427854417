import React from 'react';
import PropTypes from 'prop-types';
import { InvoiceResultWithoutCurrency } from '../invoice-result-without-currency';
import { InvoiceResultWithCurrency } from '../../../invoice-result-with-currency';
import { safeToUpperCase } from '../../../../helpers/safe-to-upper-case';
import { getCurrentNetworkForShow } from '../../../../helpers/get-current-network-for-show';
import styles from './styles.module.scss';

export const InvoiceResult = (props) => {
  const {
    invoiceInfo,
    paymentInfo,
    currenciesObject,
  } = props;

  const invoicePayCurrency = invoiceInfo?.pay_currency ?? null;
  const invoiceOrderId = invoiceInfo?.order_id ?? null;

  const priceAmount = invoiceInfo?.price_amount ?? null;
  const priceCurrency = invoiceInfo?.price_currency ?? null;
  const priceCurrencyUpperCased = safeToUpperCase(priceCurrency);
  const priceCurrencyFound = currenciesObject[priceCurrencyUpperCased] || {};
  const priceCurrentTicker = priceCurrencyFound?.ticker ?? priceCurrencyUpperCased;
  const priceCurrentNetwork = getCurrentNetworkForShow(priceCurrencyFound?.network, priceCurrency);

  const payAmount = paymentInfo?.pay_amount ?? null;
  const payCurrency = paymentInfo?.pay_currency ?? null;
  const payCurrencyUpperCased = safeToUpperCase(payCurrency);
  const payCurrencyFound = currenciesObject[payCurrencyUpperCased] || {};
  const payCurrentTicker = payCurrencyFound?.ticker ?? payCurrencyUpperCased;
  const payCurrentNetwork = getCurrentNetworkForShow(payCurrencyFound?.network, payCurrency);

  const paymentPayinAddress = paymentInfo?.exchange_data?.payinAddress ?? '';
  const paymentPayinExtraId = paymentInfo?.payin_extra_id ?? null;
  const paymentStatus = paymentInfo?.status ?? null;
  const paymentId = paymentInfo?.id ?? null;
  const type = paymentInfo?.type ?? null;
  const invoiceUrl = invoiceInfo?.invoice_url ?? null;
  const paymentUrl = invoiceUrl && paymentId ? `${invoiceUrl}&paymentId=${paymentId}` : null;

  return (
    <div className={styles.invoiceResultModal}>
      {invoicePayCurrency && (
        <InvoiceResultWithCurrency
          orderId={invoiceOrderId}
          priceAmount={priceAmount}
          priceTicker={priceCurrentTicker}
          priceNetwork={priceCurrentNetwork}
          payAmount={payAmount}
          payTicker={payCurrentTicker}
          payNetwork={payCurrentNetwork}
          payinAddress={paymentPayinAddress}
          payinExtraId={paymentPayinExtraId}
          status={paymentStatus}
          url={paymentUrl}
          type={type}
        />
      )}
      {!invoicePayCurrency && (
        <InvoiceResultWithoutCurrency
          orderId={invoiceOrderId}
          priceAmount={priceAmount}
          priceTicker={priceCurrentTicker}
          priceNetwork={priceCurrentNetwork}
          url={invoiceUrl}
        />
      )}
    </div>
  );
};

InvoiceResult.defaultProps = {
  invoiceInfo: {
    cancel_url: null,
    created_at: null,
    id: null,
    invoice_url: null,
    ipn_callback_url: null,
    order_description: null,
    order_id: null,
    partially_paid_url: null,
    pay_currency: null,
    price_amount: null,
    price_currency: null,
    success_url: null,
    updated_at: null,
  },
  paymentInfo: {
    pay_amount: null,
    pay_currency: null,
    payin_extra_id: null,
    exchange_data: {
      payinAddress: '',
    },
    status: null,
  },
  currenciesObject: {},
};

InvoiceResult.propTypes = {
  invoiceInfo: PropTypes.shape({
    cancel_url: PropTypes.string,
    created_at: PropTypes.string,
    id: PropTypes.string,
    invoice_url: PropTypes.string,
    ipn_callback_url: PropTypes.string,
    order_description: PropTypes.string,
    order_id: PropTypes.string,
    partially_paid_url: PropTypes.string,
    pay_currency: PropTypes.string,
    price_amount: PropTypes.string,
    price_currency: PropTypes.string,
    success_url: PropTypes.string,
    updated_at: PropTypes.string,
  }),
  paymentInfo: PropTypes.shape({
    pay_amount: PropTypes.string,
    pay_currency: PropTypes.string,
    payin_extra_id: PropTypes.string,
    exchange_data:  PropTypes.shape({
      payinAddress: PropTypes.string,
    }),
    status: PropTypes.string,
  }),
  currenciesObject: PropTypes.object,
};
