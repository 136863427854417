import * as React from 'react';

export const MinusIcon: React.FC<{ color: string }> = (props) => {
  const { color } = props;

  return (
    <svg width="12" height="4" viewBox="0 0 12 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.98835 3.20703C1.2904 3.20703 0.724609 2.66584 0.724609 1.99824C0.724609 1.33064 1.2904 0.789449 1.98835 0.789449L10.1202 0.789449C10.8182 0.789449 11.384 1.33064 11.384 1.99824C11.384 2.66584 10.8182 3.20703 10.1202 3.20703L1.98835 3.20703Z"
        fill={color}
      />
    </svg>
  );
};
