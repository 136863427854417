import * as yup from 'yup';
import dateFormat from '../../libs/date-format';
import { hasElementsInArray } from '../../helpers/has-elements-in-array';
import { safeToUpperCase } from '../../helpers/safe-to-upper-case';
import { getPaymentNetworkFee } from '../../helpers/get-payment-network-fee';
import { getPaymentServiceFee } from '../../helpers/get-payment-service-fee';

export const PAYMENTS_FILTER_SCHEMA = yup.object({
  status: yup.string().trim(),
  fixedRate: yup.mixed(),
  feePaidByUser: yup.mixed(),
  payinAddress: yup.string().trim(),
  payinHash: yup.string().trim(),
  outcomePrice: yup.string().trim(),
  apiKeyId: yup.string().trim(),
  paymentType: yup.string().trim(),
});

/**
 * @param payments {Array}
 * @param isPDF {Boolean}
 * @returns {[]}
 */
export const createBodyForDocument = (payments, isPDF = true) => {
  const hasPayments = hasElementsInArray(payments);

  if (!hasPayments) {
    return [];
  }

  return payments.map((payment) => {
    const result = [
      payment?.parentPaymentId ?? '',
      payment?.paymentId ?? '',
      String(payment?.paymentExtraIds ?? ''),
      payment?.orderId ?? '',
      payment?.originalPrice ?? '',
      payment?.payPrice ?? '',
      payment?.exchangeData?.amountSend
        ? `${payment.exchangeData.amountSend} ${safeToUpperCase(payment?.payCurrency)}`
        : '',
      payment?.outcomePrice ?? '',
      payment?.status ?? '',
      dateFormat(payment?.createdAt ?? ''),
      dateFormat(payment?.updatedAt ?? ''),
      payment?.exchangeData?.payinAddress ?? '',
      payment?.exchangeData?.payinHash ?? '',
      getPaymentNetworkFee(payment, 8),
      getPaymentServiceFee(payment, 8),
    ];
    if (!isPDF) {
      const payoutAddress = payment?.exchangeData?.payoutAddress ?? '';
      const currentPayoutAddress = payoutAddress?.startsWith('changenow_partner')
        ? 'Balance'
        : payoutAddress;
      result.push(currentPayoutAddress);
      result.push(payment?.exchangeData?.payoutHash ?? '');
    }

    return result;
  });
};
