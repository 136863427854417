import React from 'react';
import { useSelector } from 'react-redux';
import { isPartnerCustodySelector, partnerFetchedSelector } from '../../../store/partner/selectors';
import { getSafeErrorMessageText } from '../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../helpers/console-error-message';
import { useSubPartners } from '../../../api/modules/account/use-sub-partners';
import { CUSTOMERS_PAGE_PER_PAGE, getSubPartnerDataWithAmount } from '../helpers';
import { checkErrorCode } from '../../../helpers/check-error-code';
import { customerBalancesSelector } from '../../../store/customer-management/selectors';
import { useTimeout } from '../../../hooks/use-timeout';
import {
  marketInfoFromCurrencySelector,
  marketInfoRatesSelector,
} from '../../../store/market-info/selectors';
import { currencyListSelector } from '../../../store/currencies/selectors';
import { useCurrenciesToObject } from '../../../hooks/use-currencies-to-object';

const DEFAULT_SUB_PARTNERS_DATA = {
  result: [],
  count: null,
};
const TIMER_TIMEOUT = 4000;

export const useCustomersPageInitState = () => {
  const fetchSubPartners = useSubPartners();
  const isPartnerCustody = useSelector(isPartnerCustodySelector());
  const partnerFetched = useSelector(partnerFetchedSelector());
  const currencyList = useSelector(currencyListSelector());
  const customerBalances = useSelector(customerBalancesSelector());
  const marketInfoRates = useSelector(marketInfoRatesSelector());
  const marketInfoFromCurrency = useSelector(marketInfoFromCurrencySelector());
  const { currenciesObject } = useCurrenciesToObject(currencyList);

  const [searchText, setSearchText] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [isPending, setIsPending] = React.useState(false);
  const [subPartnersData, setSubPartnersData] = React.useState(DEFAULT_SUB_PARTNERS_DATA);
  const [subPartnersError, setSubPartnersError] = React.useState(null);
  const [hasSubPartnersOnFirstBoot, setHasSubPartnersOnFirstBoot] = React.useState(false);

  const subPartnersDataWithBalances = React.useMemo(() => ({
    count: subPartnersData.count,
    result: getSubPartnerDataWithAmount({
      data: subPartnersData.result,
      balances: customerBalances,
      rates: marketInfoRates,
      currenciesObject,
      ticker: marketInfoFromCurrency,
    }),
  }), [
    subPartnersData,
    customerBalances,
    marketInfoRates,
    currenciesObject,
    marketInfoFromCurrency,
  ]);

  const resetSubPartnersError = () => {
    setSubPartnersError(null);
  };
  const reFetchSubPartners = async (page = 1, filter = null, firstInit = false) => {
    setIsPending(true);
    const { data, status, errorMessage } = await fetchSubPartners({
      limit: CUSTOMERS_PAGE_PER_PAGE,
      page,
      filter,
    });

    if (status === 200) {
      setSubPartnersData({
        result: data?.result ?? [],
        count: data?.count ?? null,
      });
      if (firstInit) {
        setHasSubPartnersOnFirstBoot(Boolean(data?.count ?? false));
      }
      setIsPending(false);
    } else {
      checkErrorCode(data?.errorData?.code);
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/sub-partners');
      setSubPartnersError(errorDataMessage);
      setIsPending(false);
    }
  };

  React.useEffect(() => {
    if (partnerFetched && isPartnerCustody) {
      void reFetchSubPartners(1, null, true);
    }
  }, [partnerFetched, isPartnerCustody]);

  useTimeout(resetSubPartnersError, TIMER_TIMEOUT, subPartnersError);

  return {
    isPending,
    subPartnersData: subPartnersDataWithBalances,
    subPartnersError,
    hasSubPartnersOnFirstBoot,
    setHasSubPartnersOnFirstBoot,
    reFetchSubPartners,
    searchText,
    setSearchText,
    page,
    setPage,
  };
};
