import * as React from 'react';
import { Helmet } from 'react-helmet';
import { FiatOperationsPage } from '../../components/fiat-operations-page';

export const FiatOperationsSettings = () => (
  <>
    <Helmet>
      <title>Fiat Operations</title>
    </Helmet>
    <FiatOperationsPage />
  </>
);
