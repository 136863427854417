import * as React from 'react';
import { PaymentLinkPage } from '../../components/payment-link-page';
import { ProfileInfoContextProvider, PaymentsContextProvider } from '../../components/payment-link-page/hooks';

const PaymentLink = () => {
  return (
    <ProfileInfoContextProvider>
      <PaymentsContextProvider>
        <PaymentLinkPage />
      </PaymentsContextProvider>
    </ProfileInfoContextProvider>
  );
};

export default PaymentLink;
