import React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';

export const useSendOuterClientError = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (errorData: object) => {
    const [cancelToken] = getRequestSource();
    client.defaults.headers.Authorization = null;
    client.defaults.headers['X-Request-With'] = 'XMLHttpRequest';
    const requestPromise = client.post(
      '/outer-client-message',
      errorData,
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      (data) => data,
    );
  }, [getRequestSource]);
};
