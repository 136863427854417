import {
  format, isValid, isDate, parseISO,
} from 'date-fns';

export const formatDate = (date = '', pattern = 'dd.MM.yyyy HH:mm:ss') => {
  const dateInstance = isDate(date)
    ? date
    : parseISO(date);

  return isValid(dateInstance) ? format(dateInstance, pattern) : date;
};
