import React from 'react';
import cn from 'classnames';
import { Section } from '../section';
import { Button } from '../ui/button';
import { WhitelistPopup } from './components/whitelist-popup';
import { useState } from './hooks';
import { New2faPopup } from '../new-2fa-popup';
import { WhitelistSuccessPopup } from './components/whitelist-success-popup';
import classes from './styles.module.scss';

export const MassPayoutWhitelistAddresses: React.FC<{ className?: string | undefined }> = (props) => {
  const { className } = props;

  const { state, actions } = useState();

  return (
    <>
      <Section
        className={cn(classes.section, className)}
        title="Whitelist your addresses"
      >
        <div className={classes.description}>
          Create a request for whitelisting payout addresses.
        </div>
        <Button
          className={classes.button}
          onClick={actions.handlePopupToggle}
          displayBorder
        >
          Whitelist my addresses
        </Button>
      </Section>
      <WhitelistPopup
        open={state.isPopupShow}
        onClose={actions.handlePopupToggle}
        activeType={state.activeTabType}
        onActiveType={actions.setActiveTabType}
        onSubmit={actions.handleSubmit}
        isManualType={state.isManualType}
        isUploadType={state.isUploadType}
        onWhitelistData={actions.setWhitelistData}
        hasWhitelistData={state.hasWhitelistData}
        currencies={state.currencies}
      />
      <New2faPopup
        open={state.is2FAShow}
        onClose={actions.handle2faClose}
        onUpdate={actions.handleWhitelistAddressSend}
        is2faCode
        pending={state.isPending}
        apiErrorMessage={state.apiErrorMessage}
      />
      <WhitelistSuccessPopup
        open={state.isSuccessShow}
        onClose={actions.handleSuccessPopupClose}
      />
    </>
  );
};

MassPayoutWhitelistAddresses.defaultProps = {
  className: undefined,
};
