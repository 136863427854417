import * as yup from 'yup';
import { MAX_NUMBER_OF_TITLE_CHARACTERS, MAX_NUMBER_OF_TITLE_ERROR_MESSAGE } from './constants';

const emptyToNull = (_, originalValue) => originalValue || null;
const periodToEmpty = (_, originalValue) => Number(originalValue) ? originalValue : '';

export const CREATE_SUBSCRIPTION_PLAN_SCHEMA = yup.object({
  title: yup.string().trim().required('Title is required field').max(MAX_NUMBER_OF_TITLE_CHARACTERS, MAX_NUMBER_OF_TITLE_ERROR_MESSAGE),
  period: yup.string().trim().required('Period is required field').transform(periodToEmpty),
  periodSelect: yup.string().required(),
  amount: yup.string().required('Cost per period is required field'),
  currency: yup.string().required(),
  ipnCallbackUrl: yup.string().trim().transform(emptyToNull).nullable(),
  successUrl: yup.string().trim().transform(emptyToNull).nullable(),
  cancelUrl: yup.string().trim().transform(emptyToNull).nullable(),
  partiallyPaidUrl: yup.string().trim().transform(emptyToNull).nullable(),
});
