import React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';
import { currenciesMapFn } from '../../helpers/account/map-fns';

const mapFn = (data: any) => ({
  data: {
    firstCurrencies: {
      invoice: typeof data?.data?.firstCurrencies?.invoice === 'object'
        ? currenciesMapFn(data.data.firstCurrencies.invoice)
        : null,
      donation: typeof data?.data?.firstCurrencies?.donation === 'object'
        ? currenciesMapFn(data.data.firstCurrencies.donation)
        : null,
    },
  },
  status: data?.status ?? null,
  statusCode: data?.statusCode ?? null,
});

export const useGetFirstCurrencies = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async () => {
    const [cancelToken] = getRequestSource();
    const requestPromise = client.get(
      '/first-currencies',
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [client, getRequestSource]);
};
