import { nanoid } from 'nanoid';
import { getSubPartnerBalancesConvertedToArray } from '../../../helpers/get-sub-partner-balances-converted-to-array';
import { safeToUpperCase } from '../../../helpers/safe-to-upper-case';
import { FIAT_POSITIONS } from '../../../constants/currencies-constants';
import { safeToLowerCase } from '../../../helpers/safe-to-lower-case';
import { hasElementsInArray } from '../../../helpers/has-elements-in-array';

const withdrawalsItemMapFn = (data) => ({
  key: nanoid(),
  id: data?.id ?? null,
  batchWithdrawalId: data?.batch_withdrawal_id ?? null,
  address: data?.address ?? null,
  payoutDescription: data?.payout_description ?? null,
  amount: data?.amount ?? null,
  currency: data?.currency ?? null,
  error: data?.error ?? null,
  extraId: data?.extra_id ?? null,
  fiatAmount: data?.fiat_amount ?? null,
  fiatCurrency: data?.fiat_currency ?? null,
  hash: data?.hash ?? null,
  ipnCallbackUrl: data?.ipn_callback_url ?? null,
  isRequestPayouts: data?.is_request_payouts ?? null,
  status: data?.status ?? null,
  uniqueExternalId: data?.unique_external_id ?? null,
  requestedAt: data?.requested_at ?? null,
  createdAt: data?.created_at ?? null,
  updatedAt: data?.updated_at ?? null,
  isVerified: data?.is_verified ?? false,
  fee: data?.fee ?? null,
  feePaidBy: data?.fee_paid_by ?? null,
});

const createWithdrawalPaymentsDataMapFn = (data) => ({
  id: data?.id ?? null,
  withdrawals: (data?.withdrawals ?? []).map(withdrawalsItemMapFn),
  isVerified: data?.is_verified ?? false,
});

const withdrawalPayoutDataMapFn = (data) => ({
  id: data?.id ?? null,
  withdrawals: (data?.withdrawals ?? []).map(withdrawalsItemMapFn),
  isVerified: data?.is_verified ?? false,
});

const withdrawalPaymentsDataMapFn = (data) => ({
  withdrawals: (data?.withdrawals ?? []).map(withdrawalsItemMapFn),
});

export const createWithdrawalPaymentsRequestDataMapFn = (data) => (
  data
    ? createWithdrawalPaymentsDataMapFn(data)
    : null
);

export const withdrawalPayoutRequestDataMapFn = (data) => (
  data
    ? withdrawalPayoutDataMapFn(data)
    : null
);

export const withdrawalPaymentsRequestDataMapFn = (data) => (
  data
    ? withdrawalPaymentsDataMapFn(data)
    : null
);

export const subscriptionPlanItemMapFn = (data) => ({
  id: data?.id ?? null,
  title: data?.title ?? null,
  intervalDay: data?.interval_day ?? null,
  amount: data?.amount ?? null,
  currency: data?.currency ?? null,
  ipnCallbackUrl: data?.ipn_callback_url ?? null,
  successUrl: data?.success_url ?? null,
  cancelUrl: data?.cancel_url ?? null,
  partiallyPaidUrl: data?.partially_paid_url ?? null,
  createdAt: data?.created_at ?? null,
  updatedAt: data?.updated_at ?? null,
});

export const userSubscriptionMapFn = (data) => ({
  id: data?.id ?? null,
  subscriptionPlanId: data?.subscription_plan_id ?? null,
  isActive: data?.is_active ?? false,
  status: data?.status ?? null,
  expireDate: data?.expire_date ?? null,
  subscriber: {
    email: data?.subscriber?.email ?? null,
    subPartnerId: data?.subscriber?.sub_partner_id ?? null,
  },
  createdAt: data?.created_at ?? null,
  updatedAt: data?.updated_at ?? null,
});
export const createUsersMapFn = (data) => ({
  result: userSubscriptionMapFn(data?.result ?? {}),
});

const paymentMapFnItemExchangeData = (data) => ({
  amountReceive: data?.amountReceive ?? null,
  amountSend: data?.amountSend ?? null,
  fromCurrency: data?.fromCurrency ?? null,
  id: data?.id ?? null,
  payinAddress: data?.payinAddress ?? null,
  payinHash: data?.payinHash ?? null,
  payoutAddress: data?.payoutAddress ?? null,
  payoutHash: data?.payoutHash ?? null,
  payinExtraId: data?.payinExtraId ?? null,
  status: data?.status ?? null,
  toCurrency: data?.toCurrency ?? null,
  updatedAt: data?.updatedAt ?? null,
  depositFee: {
    amount: data?.depositFee?.amount ?? null,
    currency: data?.depositFee?.currency ?? null,
  },
  estimatedDepositFee: {
    amount: data?.estimatedDepositFee?.amount ?? null,
    currency: data?.estimatedDepositFee?.currency ?? null,
  },
  withdrawalFee: {
    amount: data?.withdrawalFee?.amount ?? null,
    currency: data?.withdrawalFee?.currency ?? null,
  },
});
export const paymentMapFnItem = (data) => ({
  burningPercent: data?.burning_percent ?? null,
  isFeePaidByUser: data?.is_fee_paid_by_user ?? false,
  isFixedRate: data?.is_fixed_rate ?? false,
  orderDescription: data?.order_description ?? null,
  orderId: data?.order_id ?? null,
  originalPrice: data?.original_price ?? null,
  outcomeAmount: data?.outcome_amount ?? null,
  outcomeCurrency: data?.outcome_currency ?? null,
  outcomePrice: data?.outcome_price ?? null,
  payAmount: data?.pay_amount ?? null,
  payCurrency: data?.pay_currency ?? null,
  payPrice: data?.pay_price ?? null,
  paymentId: data?.payment_id ?? null,
  repeatedDeposits: data?.repeatedDeposits ?? [],
  status: data?.status ?? null,
  type: data?.type ?? null,
  createdAt: data?.created_at ?? null,
  updatedAt: data?.updated_at ?? null,
  exchangeData: paymentMapFnItemExchangeData(data?.exchange_data),
  invoiceId: data?.invoice_id ?? null,
  apiKeyId: data?.api_key_id ?? null,
  commissionPercent: data?.commission_percent ?? null,
  commissionAmount: data?.commission_amount ?? null,
  originType: data?.origin_type ?? null,
  paymentExtraIds: data?.payment_extra_ids ?? null,
  parentPaymentId: data?.parent_payment_id ?? null,
  subPartnerId: data?.sub_partner_id ?? null,
  ipnCallbackUrl: data?.ipn_callback_url ?? null,
});

export const signInMapFn = (data) => ({
  emailIsActivated: data?.emailIsActivated ?? false,
  hasTeam: data?.hasTeam ?? false,
  need2faToken: data?.need_tfa_token ?? false,
  type2fa: data?.type_2fa ?? null,
  partnerId: data?.partnerId ?? null,
  token: data?.token ?? null,
  isUserConfirmationRequired: data?.isUserConfirmationRequired ?? false,
});

export const signUpMapFn = (data) => ({
  emailIsActivated: data?.emailIsActivated ?? false,
  hasTeam: data?.hasTeam ?? false,
  partnerId: data?.partnerId ?? null,
  token: data?.token ?? null,
});

export const subPartnerBalanceMapFn = (data) => ({
  result: {
    balances: getSubPartnerBalancesConvertedToArray(data?.result?.balances ?? {}),
    subPartnerId: data?.result?.subPartnerId ?? null,
  },
});

export const fiatCurrenciesMapItemFn = (data, index) => ({
  id: nanoid(),
  code: data?.code ?? null,
  ticker: safeToUpperCase(data?.ticker || data?.code || null),
  name: data?.name ?? null,
  enable: data?.enable ?? false,
  enableForPayment: data?.enable_for_payment ?? false,
  logoUrl: data?.logo_url ?? null,
  cgId: data?.cg_id ?? null,
  isFiat: true,
  position: data?.priority
    || FIAT_POSITIONS[safeToLowerCase(data?.code)]
    || FIAT_POSITIONS.DEFAULT_POSITION + index,
});
export const fiatCurrenciesMapFn = (data) => (
  hasElementsInArray(data)
    ? data.map(fiatCurrenciesMapItemFn)
    : []
);

export const fiatPayoutMapFn = (data) => ({
  id: data?.id ?? null,
  isVerified: data?.isVerified ?? null,
  provider: data?.provider ?? null,
  requestId: data?.requestId ?? null,
  status: data?.status ?? null,
  fiatCurrencyCode: data?.fiatCurrencyCode ?? null,
  fiatAmount: data?.fiatAmount ?? null,
  cryptoCurrencyCode: data?.cryptoCurrencyCode ?? null,
  cryptoCurrencyAmount: data?.cryptoCurrencyAmount ?? null,
  fiatAccountCode: data?.fiatAccountCode ?? null,
  fiatAccountNumber: data?.fiatAccountNumber ?? null,
  payoutDescription: data?.payoutDescription ?? null,
  error: data?.error ?? null,
  createdAt: data?.createdAt ?? null,
  updatedAt: data?.updatedAt ?? null,
});

export const walletMapFn = (data) => ({
  id: data?.id ?? null,
  baseAddress: data?.base_address ?? null,
  baseCurrency: data?.base_currency ?? null,
  extraId: data?.extra_id ?? null,
  priority: data?.priority ?? null,
  storeId: data?.store_id ?? null,
});

export const currenciesMapFn = (data) => ({
  ...data,
  isPopular: data?.is_popular ?? false,
  isStable: data?.is_stable ?? false,
  isFiat: data?.is_fiat ?? false,
  position: data?.priority ?? null,
  ticker: safeToUpperCase(data?.ticker || data?.code || null),
});
