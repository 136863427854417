import React from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import SubmitButton, { buttonState } from '../submit-button';
import withModal from '../with-modal';
import config from '../../config';
import apiClient from '../../api/api-client';
import { isFunction } from '../../helpers/is-function';
import { TFA } from '../../constants/app-constants';
import styles from './styles.module.scss';

const ModalTwoFactor = (props) => {
  const {
    onVerifyTfa,
    onClose,
    onSubmit,
    submitInvalidCode,
  } = props;

  const [captcha, setCaptcha] = React.useState('');
  const [typeTfa, setTypeTfa] = React.useState('');
  const [code, setCode] = React.useState('');
  const [error, setError] = React.useState('');
  const [isInvalidCode, setIsInvalidCode] = React.useState(false);
  const [submitBtnState, setSubmitBtnState] = React.useState(buttonState.normal);
  const captchaRef = React.useRef(null);

  const handleCaptchaChange = (token) => {
    setCaptcha(token);
  };
  const handleVerificationCodeChange = (event) => {
    setCode(event.target.value);
    setIsInvalidCode(false);
  };
  const handleSubmit = () => {
    if (isFunction(onSubmit)) {
      onSubmit(code, captcha);
    }
    setTimeout(() => {
      captchaRef?.current?.reset();
    }, 1000);
  };
  const handleTfaSubmit = async () => {
    try {
      const verifyCode = await apiClient.verifyTwoFactorCode(captcha, code);

      if (isFunction(onVerifyTfa)) {
        onVerifyTfa(verifyCode, captcha);
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message ?? null;
      const errorText = error?.response?.data?.error ?? null;

      if (errorMessage === 'Invalid verification code') {
        setIsInvalidCode(true);
      }

      if (errorText) {
        setError(errorText);
      }

      captchaRef?.current?.reset();
      setSubmitBtnState(buttonState.failure);
    }
  };
  const fetch = async () => {
    await apiClient.sendTwoFactorCode();
    const type = await apiClient.getTfaTokenType();

    setTypeTfa(type);
  };

  React.useEffect(() => {
    if (submitInvalidCode) {
      setIsInvalidCode(true);
    }
  }, [submitInvalidCode]);

  React.useEffect(() => {
    let timerId = null;

    if (submitBtnState === buttonState.failure) {
      timerId = setTimeout(() => {
        setSubmitBtnState(buttonState.normal);
      }, 2000);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [submitBtnState]);

  React.useEffect(() => {
    fetch();
  }, []);

  const currentDescriptionText = typeTfa === TFA.TYPES.APP ? TFA.TEXTS.APP : TFA.TEXTS.EMAIL;
  const currentHandleSubmit = onSubmit ? handleSubmit : handleTfaSubmit;

  return (
    <div className={styles.wrapper}>
      <h4 className={styles.title}>Two step authentication</h4>
      <p className={styles.desc}>
        {currentDescriptionText}
      </p>
      <div className={styles.inputBlock}>
        <input
          className={styles.inputField}
          name="verificationCode"
          value={code}
          placeholder="Enter your authentication code"
          onChange={handleVerificationCodeChange}
        />
        <div className={styles.inputFieldError}>
          {isInvalidCode && (
            <span>
              Enter valid verification code
            </span>
          )}
        </div>
      </div>
      <div className={styles.captchaBlock}>
        <ReCAPTCHA
          sitekey={config.captcha_key}
          onChange={handleCaptchaChange}
          ref={captchaRef}
          hl="en"
        />
      </div>
      <div className={styles.buttonsBlock}>
        <SubmitButton
          onSubmit={onClose}
          buttonState={buttonState.normal}
          className={styles.cancelBtn}
        >
          Cancel
        </SubmitButton>
        <SubmitButton
          onSubmit={currentHandleSubmit}
          buttonState={submitBtnState}
          failureMessage={error}
        >
          OK
        </SubmitButton>
      </div>
    </div>
  );
};

ModalTwoFactor.defaultProps = {
  onVerifyTfa: null,
  onClose: null,
  onSubmit: null,
  submitInvalidCode: false,
};

ModalTwoFactor.propTypes = {
  onVerifyTfa: PropTypes.func,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  submitInvalidCode: PropTypes.bool,
};

export default withModal(ModalTwoFactor);
