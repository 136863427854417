import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { RangeDatepicker } from '../../range-datepicker';
import { formatDate } from '../../../helpers/format-date';
import useOutsideClick from '../../../hooks/use-outside-click';
import classes from './styles.module.scss';

const DEFAULT_TEXT = 'dd.mm.yyyy';
const DEFAULT_DATE_PATTERN = 'dd.MM.yyyy';
let nextCloseTimerId = null;
export const DateField = (props) => {
  const {
    className,
    title,
    createdFrom,
    createdTo,
    onChange,
    isPlaceholderDate,
  } = props;

  const componentRef = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isAnimate, setIsAnimate] = React.useState(false);

  const handleClose = () => {
    setIsAnimate(false);
    nextCloseTimerId = setTimeout(() => {
      setIsOpen(false);
    }, 100);
  };
  const handleToggle = () => {
    if (!isOpen) {
      setIsOpen(true);
    } else {
      handleClose();
    }
  };

  const placeholder = `${DEFAULT_TEXT} - ${DEFAULT_TEXT}`;
  const isEmpty = !createdFrom && !createdTo;
  const createdFromText = createdFrom
    ? formatDate(createdFrom, DEFAULT_DATE_PATTERN)
    : DEFAULT_TEXT;
  const createdToText = createdTo
    ? formatDate(createdTo, DEFAULT_DATE_PATTERN)
    : null;

  React.useEffect(() => {
    let nextTimerId = null;

    if (isOpen) {
      nextTimerId = setTimeout(() => {
        setIsAnimate(true);
      }, 100);
    }

    return () => {
      if (nextTimerId) {
        clearTimeout(nextTimerId);
      }
      if (nextCloseTimerId) {
        clearTimeout(nextCloseTimerId);
      }
    };
  }, [isOpen]);

  useOutsideClick(componentRef, handleClose);

  return (
    <div
      className={cn([
        classes.dateField,
        className,
      ])}
    >
      {title && (
        <div className={classes.dateFieldTitle}>
          {title}
        </div>
      )}
      <div
        className={cn([
          classes.dateFieldBox,
          isOpen && classes.dateFieldBoxActive,
        ])}
        ref={componentRef}
      >
        <div
          className={cn([
            classes.dateFieldInput,
            !createdFrom && !createdTo && classes.dateFieldInputGray,
          ])}
          onClick={handleToggle}
          tabIndex={0}
          role="button"
        >
          <span>
            {isPlaceholderDate && 'Date:'}
            {' '}
            {isEmpty && placeholder}
            {!isEmpty && (
              <>
                {createdFromText}
                {createdToText && (
                  <>
                    {' '}
                    -
                    {' '}
                    {createdToText}
                  </>
                )}
              </>
            )}
          </span>
          <img
            className={classes.dateFieldArrow}
            src="/images/select-arrow-icon.svg"
            alt="Arrow Icon"
            decoding="async"
          />
        </div>
        {isOpen && (
          <div
            className={cn([
              classes.dateFieldDatepicker,
              isAnimate && classes.dateFieldDatepickerAnimate,
            ])}
          >
            <RangeDatepicker
              from={createdFrom}
              to={createdTo}
              onChange={onChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};

DateField.defaultProps = {
  className: null,
  title: null,
  createdFrom: null,
  createdTo: null,
  onChange: null,
  isPlaceholderDate: false,
};

DateField.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  createdFrom: PropTypes.instanceOf(Date),
  createdTo: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  isPlaceholderDate: PropTypes.bool,
};
