import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { StatisticsGraphType } from './types';
import { Tabs } from './components/tabs';
import { STATISTICS_GRAPH_PERIOD_TABS, STATISTICS_GRAPH_TABS } from './helpers';
import { StatsAreaChart } from './components/stats-area-chart';
import { useInitialState } from './hooks';
import { RangeDatepicker } from '../range-datepicker';
import useOutsideClick from '../../hooks/use-outside-click';
import { InfoCard } from './components/info-card';
import { ROUTES } from '../../constants/routes';
import { StatsPieChart } from './components/stats-pie-chart';
import { LoaderIcon } from '../icons/loader-icon';
import { Button } from '../ui/button';
import classes from './styles.module.scss';
import styles from '../ui/button/styles.module.scss';

export const StatisticsGraph: React.FC<StatisticsGraphType> = (props) => {
  const {
    className = null,
    currenciesObject = {},
  } = props;

  const componentState = useInitialState(currenciesObject);
  const componentRef = React.useRef<HTMLDivElement>(null);

  useOutsideClick(componentRef, componentState.handleCalendarClose);

  return (
    <div className={cn(classes.statisticsGraph, className)}>
      <div className={classes.statisticsGraphHeader}>
        <div className={classes.statisticsGraphTitle}>
          Statistics
          {componentState.isStatsFetching && (
            <LoaderIcon size={20} />
          )}
        </div>
        <Tabs
          className={cn([
            classes.statisticsGraphTabs,
            componentState.isTabsDisabled && classes.statisticsGraphTabsDisabled,
          ])}
          items={STATISTICS_GRAPH_TABS}
          activeTabType={componentState.activeStatsType}
          onClick={componentState.handleGraphTabClick}
        />
        <div className={classes.statisticsGraphPeriodTabsBox} ref={componentRef}>
          <Tabs
            className={cn([
              classes.statisticsGraphPeriodTabs,
              (componentState.isTabsDisabled || componentState.isCoinsStatsActive)
                && classes.statisticsGraphPeriodTabsDisabled,
            ])}
            items={STATISTICS_GRAPH_PERIOD_TABS}
            activeTabType={componentState.activePeriodType}
            onClick={componentState.handlePeriodTabClick}
          />
          {componentState.isCalendarShow && (
            <RangeDatepicker
              className={classes.statisticsGraphPeriodCalendar}
              from={componentState.calendarDateFrom}
              to={componentState.calendarDateTo}
              onChange={componentState.handleDateRangeChange}
            />
          )}
        </div>
      </div>
      <div className={classes.statisticsGraphBody}>
        {componentState.isTurnoverStatsActive && (
          <StatsAreaChart
            className={cn([
              classes.statisticsGraphItem,
              componentState.isStatsChartDisabled && classes.statisticsGraphDisabled,
            ])}
            data={componentState.simpleAreaChartsData}
            activePeriodType={componentState.activePeriodType}
          />
        )}
        {componentState.isCoinsStatsActive && (
          <StatsPieChart
            className={cn([
              classes.statisticsGraphItem,
              componentState.isStatsChartDisabled && classes.statisticsGraphDisabled,
            ])}
            data={componentState.pieChartData}
          />
        )}
        {(componentState.isNoTransactions || componentState.errorMessage) && (
          <div className={classes.statisticsGraphInfo}>
            <InfoCard className={classes.statisticsGraphInfoCard}>
              {componentState.isNoTransactions && (
                <>
                  Here, we have your upcoming payments ready to display.
                  <br />
                  To initiate the first payment, simply click on the button below!
                  <div className={classes.statisticsGraphInfoCardButtons}>
                    <Link
                      to={`${ROUTES.INVOICES}?firstPayment=true`}
                      className={styles.button}
                    >
                      Create first payment
                    </Link>
                    <Button
                      href="https://documenter.getpostman.com/view/7907941/2s93JusNJt#intro"
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                      displayBorder
                    >
                      Check API docs
                    </Button>
                  </div>
                </>
              )}
              {!componentState.isNoTransactions && componentState.errorMessage && (
                <div className={classes.statisticsGraphInfoCardError}>
                  {componentState.errorMessage}
                </div>
              )}
            </InfoCard>
          </div>
        )}
      </div>
    </div>
  );
};
