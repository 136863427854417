import * as React from 'react';

export const usePaymentInfoPopup = () => {
  const [activePayment, setActivePayment] = React.useState(null);
  const [isPaymentInfoOpen, setIsPaymentInfoOpen] = React.useState(false);

  const openPaymentInfoPopup = React.useCallback((payment) => {
    if (!payment) {
      return;
    }

    setActivePayment({ ...payment });
    setIsPaymentInfoOpen(true);
  }, []);

  const closePaymentInfoPopup = React.useCallback(() => {
    setIsPaymentInfoOpen(false);
  }, []);

  return {
    activePayment,
    isPaymentInfoOpen,
    setActivePayment,
    openPaymentInfoPopup,
    closePaymentInfoPopup,
  };
};
