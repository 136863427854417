import React from 'react';
import { createPortal } from 'react-dom';
import { PortalProps } from './types';

const DEFAULT_ID = 'portal-container';

export const Portal: React.FC<PortalProps> = (props) => {
  const {
    id,
    children,
  } = props;
  const currentId = id || DEFAULT_ID;
  const portalElement = document.getElementById(currentId) || null;

  return portalElement ? createPortal(children, portalElement) : null;
};
