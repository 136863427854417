import * as React from 'react';
import { Helmet } from 'react-helmet';
import { SubscriptionsPage } from '../../components/subscriptions-pages';

const Subscriptions = () => (
  <>
    <Helmet>
      <title>Subscriptions</title>
    </Helmet>
    <SubscriptionsPage />
  </>
);

export default Subscriptions;
