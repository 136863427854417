import React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';
import { walletMapFn } from "../../helpers/account/map-fns";

const mapFn = (data) => walletMapFn(data?.wallet ?? null);

export const useCreateStoreOutcomeWallet = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (options) => {
    const outcomeAddress = options?.outcomeAddress ?? null;
    const outcomeCurrency = options?.outcomeCurrency ?? null;
    const eid = options?.eid ?? null;
    const [cancelToken] = getRequestSource();
    const requestPromise = client.post(
      '/store-outcome-wallet',
      {
        outcomeAddress,
        outcomeCurrency,
        eid,
      },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
