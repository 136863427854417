import * as React from 'react';
import cn from 'classnames';
import { GenerateApiStep } from '../icons/generate-api-step';
import { AccountStep } from '../icons/account-step';
import { CustodyStep } from '../icons/custody-step';
import { GetStartedStep } from '../icons/get-started-step';
import classes from './styles.module.scss';

type OnboardingStepIconsProps = {
  activeStepNumber: number,
  startStep: number,
};

export const OnboardingStepsIcons: React.FC<OnboardingStepIconsProps> = (props) => {
  const {
    activeStepNumber,
    startStep,
  } = props;

  const items = React.useMemo(() => {
    const steps = [
      {
        id: '1',
        icon: <GenerateApiStep isActive={activeStepNumber === 1} />,
        isCompleted: activeStepNumber > 1,
      },
      {
        id: '2',
        icon: <AccountStep isActive={activeStepNumber === 2} />,
        isCompleted: activeStepNumber > 2,
      },
      {
        id: '3',
        icon: <CustodyStep isActive={activeStepNumber === 3} />,
        isCompleted: activeStepNumber > 3,
      },
      {
        id: '4',
        icon: <GetStartedStep isActive={activeStepNumber === 4} />,
        isCompleted: activeStepNumber === 4,
      },
    ];
    if (startStep === 2) steps.shift();
    return steps;
  }, [activeStepNumber, startStep]);

  return (
    <div className={classes.onboardingStepsIcons}>
      {items.map((item, index) => (
        <React.Fragment key={item.id}>
          {item.isCompleted ? (
            <img
              src="/images/done-icon.svg"
              alt="Completed"
              decoding="async"
              loading="lazy"
              width={32}
              height={32}
            />
          ) : item.icon}
          {index < (items.length - 1) && (
            <span className={cn([
              classes.onboardingStepsIconsLine,
              startStep === 2 && classes.onboardingStepsIconsLineLong,
            ])}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};
