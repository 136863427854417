import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { CheckboxNew } from '../checkbox-new';
import classes from './styles.module.scss';

export const CheckboxWithText = (props) => {
  const {
    id,
    className,
    onChange,
    checked,
    error,
    disabled,
    children,
  } = props;

  return (
    <div className={cn(classes.checkboxWithText, className)}>
      <CheckboxNew
        id={id}
        className={classes.checkboxWithTextItem}
        onChange={onChange}
        checked={checked}
        error={error}
        disabled={disabled}
      />
      {children && (
        <span className={classes.checkboxWithTextContent}>
          {children}
        </span>
      )}
    </div>
  );
};

CheckboxWithText.defaultProps = {
  id: null,
  className: null,
  onChange: null,
  checked: null,
  error: false,
  disabled: null,
  children: null,
};

CheckboxWithText.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};
