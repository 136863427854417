import * as React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import { googleApiRestTransport } from '../../transports/google-api-rest-transport';

const mapFn = (data) => ({
  email: data?.email ?? null,
  familyName: data?.family_name ?? null,
  givenName: data?.given_name ?? null,
  hd: data?.hd ?? null,
  id: data?.id ?? null,
  locale: data?.locale ?? null,
  name: data?.name ?? null,
  picture: data?.picture ?? null,
  verifiedEmail: data?.verified_email ?? null,
});

export const useGoogleUserInfo = () => {
  const client = googleApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (token) => {
    const [cancelToken] = getRequestSource();
    const requestPromise = client.get(
      '/oauth2/v1/userinfo',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
