import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isFunction } from '../../../../helpers/is-function';
import { SimpleSelectField } from '../../../ui/simple-select-field';
import { Field } from '../../../ui/field';
import { Button } from '../../../ui/button';
import {
  SUBSCRIPTION_USER_PAGE_RESET_TEMPLATE,
  USER_PAYMENTS_FILTER_SCHEMA,
} from '../../helpers';
import { PAYMENT_STATUS_ITEMS } from '../../../../constants/app-constants';
import { CurrenciesSelectField } from '../../../currencies-select-field';
import { DateField } from '../../../ui/date-field';
import classes from './styles.module.scss';

export const SubscriptionUserFilter = (props) => {
  const {
    className,
    onSubmit,
    onClose,
    onResetFormData,
    currencies,
    defaultData,
    createdFrom,
    createdTo,
    onDateChange,
  } = props;

  const defaultOutcomeCurrency = defaultData?.outcomeCurrency ?? '';
  const defaultPayinAddress = defaultData?.payinAddress ?? '';
  const defaultPayinHash = defaultData?.payinHash ?? '';
  const defaultStatus = defaultData?.status ?? '';
  const {
    control,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(USER_PAYMENTS_FILTER_SCHEMA),
  });

  const [isReset, setIsReset] = React.useState(false);

  const handleFormSubmit = (data) => {
    setIsReset(false);

    if (isFunction(onSubmit)) {
      onSubmit(data);
    }
  };
  const handleResetForm = () => {
    if (!isReset) {
      setIsReset(true);
    }
    if (isFunction(reset)) {
      reset(SUBSCRIPTION_USER_PAGE_RESET_TEMPLATE);
    }
    if (isFunction(onResetFormData)) {
      onResetFormData();
    }
  };
  const handleClose = () => {
    if (isFunction(onClose)) {
      onClose();
    }
  };
  const handleCurrencySelect = (func) => (currency) => {
    const ticker = currency?.code ?? '';

    func(ticker);
  };

  React.useEffect(() => {
    let nextTimerId = null;

    if (isReset) {
      nextTimerId = setTimeout(() => {
        setIsReset(false);
      }, 1000);
    }

    return () => {
      if (nextTimerId) {
        clearTimeout(nextTimerId);
      }
    };
  }, [isReset]);

  return (
    <div className={cn(classes.paymentsFilter, className)}>
      <div
        className={classes.paymentsFilterClose}
        onClick={handleClose}
      >
        <div className={classes.paymentsFilterCloseLine} />
        <div className={classes.paymentsFilterCloseLine} />
      </div>
      <div className={classes.paymentsFilterTitle}>
        Filter
      </div>
      <div className={classes.paymentsFilterLine} />
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className={classes.paymentsFields}>
          <DateField
            className={classes.paymentsFieldDate}
            title="Date"
            createdFrom={createdFrom}
            createdTo={createdTo}
            onChange={onDateChange}
          />
          <Controller
            name="status"
            control={control}
            defaultValue={defaultStatus}
            render={({ field }) => (
              <SimpleSelectField
                className={classes.paymentsFilterInput}
                title="Payment status"
                data={{
                  id: field.name,
                  items: PAYMENT_STATUS_ITEMS,
                  onSelect: field.onChange,
                  placeholder: 'Choose',
                  reset: isReset,
                  defaultValue: defaultStatus,
                }}
              />
            )}
          />
          <Controller
            name="payinAddress"
            control={control}
            defaultValue={defaultPayinAddress}
            render={({ field }) => (
              <Field
                className={classes.paymentsFilterInput}
                title="Payin address"
                data={{
                  id: field.name,
                  value: field.value,
                  onChange: field.onChange,
                  name: field.name,
                  placeholder: 'Enter address',
                }}
              />
            )}
          />
          <Controller
            name="payinHash"
            control={control}
            defaultValue={defaultPayinHash}
            render={({ field }) => (
              <Field
                className={classes.paymentsFilterInput}
                title="Payin hash"
                data={{
                  id: field.name,
                  value: field.value,
                  onChange: field.onChange,
                  name: field.name,
                  placeholder: 'Enter hash',
                }}
              />
            )}
          />
          <Controller
            name="outcomeCurrency"
            control={control}
            defaultValue={defaultOutcomeCurrency}
            render={({ field }) => (
              <CurrenciesSelectField
                className={cn(classes.paymentsFilterInput, classes.paymentsFilterCurrencies)}
                label="Outcome currency"
                placeholder="Choose"
                currencies={currencies}
                onSelect={handleCurrencySelect(field.onChange)}
                reset={isReset}
                defaultTicker={defaultOutcomeCurrency}
                smallTheme
              />
            )}
          />
        </div>
        <div className={classes.paymentsButtons}>
          <Button
            type="submit"
            className={cn(classes.paymentsButton, classes.paymentsButtonSubmit)}
          >
            Apply
          </Button>
          <Button
            className={cn(classes.paymentsButton, classes.paymentsButtonReset)}
            onClick={handleResetForm}
          >
            Clear filters
          </Button>
        </div>
      </form>
    </div>
  );
};

SubscriptionUserFilter.defaultProps = {
  className: null,
  onSubmit: null,
  onClose: null,
  onResetFormData: null,
  currencies: [],
  defaultData: null,
  createdFrom: null,
  createdTo: null,
  onDateChange: null,
};

SubscriptionUserFilter.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  onResetFormData: PropTypes.func,
  currencies: PropTypes.arrayOf(PropTypes.object),
  defaultData: PropTypes.shape({
    feePaidByUser: PropTypes.string,
    fixedRate: PropTypes.string,
    outcomeCurrency: PropTypes.string,
    payinAddress: PropTypes.string,
    payinHash: PropTypes.string,
    status: PropTypes.string,
  }),
  createdFrom: PropTypes.instanceOf(Date),
  createdTo: PropTypes.instanceOf(Date),
  onDateChange: PropTypes.func,
};
