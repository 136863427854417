import React from 'react';
import { nanoid } from 'nanoid';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';

const providerAccountInfoMetaDataMapFn = (data) => ({
  iban: data?.iban ?? null,
  number: data?.number ?? null,
  vendor: data?.vendor ?? null,
  bankCode: data?.bankCode ?? null,
  swiftbic: data?.swiftbic ?? null,
});

const providerAccountInfoMapFn = (data) => ({
  id: data?._id ?? null,
  logo: data?.logo ?? null,
  paId: data?.paId ?? null,
  type: data?.type ?? null,
  status: data?.status ?? null,
  userId: data?.userId ?? null,
  country: data?.country ?? null,
  currency: data?.currency ?? null,
  metaData: providerAccountInfoMetaDataMapFn(data?.metaData),
  createdAt: data?.createdAt ?? null,
  updatedAt: data?.updatedAt ?? null,
  displayName: data?.displayName ?? null,
  last4Digits: data?.last4Digits ?? null,
  paymentCode: data?.paymentCode ?? null,
  accountNumber: data?.accountNumber ?? null,
});

const accountMapFn = (data) => ({
  id: nanoid(),
  provider: data?.provider ?? null,
  fiatCurrencyCode: data?.fiatCurrencyCode ?? null,
  providerAccountInfo: providerAccountInfoMapFn(data?.providerAccountInfo),
  createdAt: data?.createdAt ?? null,
  updatedAt: data?.updatedAt ?? null,
});

const mapFn = (data) => ({
  result: (data?.result?.rows ?? []).map(accountMapFn) ?? [],
});

export const usePayoutAccounts = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (params) => {
    const [cancelToken] = getRequestSource();
    const requestPromise = client.get(
      '/fiat-payouts/accounts',
      { cancelToken, params },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
