import * as React from 'react';
import { OnboardingStepsHeader } from '../onboarding-steps-header';
import { useMediaQuery } from '../../../../../../hooks/use-media-query';
import { SlickSliderCustom } from '../../../../../shared/slick-slider-custom';
import { INDUSTRIES_CARDS_OBJECT } from '../../../../constants';
import { DashboardProductCard } from '../../../dashboard-product-card';
import classes from './styles.module.scss';

type GetStartedStepProps = {
  onBack: () => void,
  industry: string,
};

export const GetStartedStep: React.FC<GetStartedStepProps> = (props) => {
  const {
    onBack,
    industry,
  } = props;

  const cards = INDUSTRIES_CARDS_OBJECT[industry];

  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <div className={classes.getStartedStep}>
      <OnboardingStepsHeader
        title="Get started NOW"
        description="We have selected the most relevant products for you. Start integration from your account or go directly to the API documentation."
        hasBackButton
        onClick={onBack}
      />
      {isMobile ? (
        <div className={classes.getStartedStepMobileCards}>
          {cards.map((card) => (
            <DashboardProductCard
              key={card.name}
              card={card}
              isSmallCard={false}
            />
          ))}
        </div>
      ) : (
        <SlickSliderCustom className={classes.getStartedStepSlider}>
          {cards.map((card) => (
            <DashboardProductCard
              key={card.name}
              card={card}
              isSmallCard
            />
          ))}
        </SlickSliderCustom>
      )}
    </div>
  );
};
