import * as React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';
import { paymentMapFnItem } from '../../helpers/account/map-fns';

const mapFn = (data) => ({
  result: (data?.result ?? []).map(paymentMapFnItem),
  count: data?.count ?? null,
});

export const useSubscriptionUserPayments = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (id, options) => {
    const limit = options?.limit || -1;
    const page = options?.page || 1;
    const orderBy = options?.orderBy || 'desc';
    const sortBy = options?.sortBy || null;
    const filter = options?.filter || null;
    const status = options?.status || null;
    const is_fixed_rate = options?.isFixedRate || null;
    const is_fee_paid_by_user = options?.isFeePaidByUser || null;
    const payin_address = options?.payinAddress || null;
    const payin_hash = options?.payinHash || null;
    const currency = options?.currency || null;
    const date_from = options?.dateFrom || null;
    const date_to = options?.dateTo || null;

    const [cancelToken] = getRequestSource();
    const requestPromise = client.get(
      `/subscriptions/${id}/payments`,
      {
        params: {
          limit,
          page,
          orderBy,
          sortBy,
          filter,
          status,
          is_fixed_rate,
          is_fee_paid_by_user,
          payin_address,
          payin_hash,
          currency,
          date_from,
          date_to,
        },
      },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
