import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Popup } from '../../../popup';
import styles from '../../styles.module.scss';
import { InvoiceResult } from '../invoice-result';
import { useProfileInfoState } from '../../hooks';

const ObservablePaymentPopup = (props) => {
  const { isOpen, onClose, observablePaymentData } = props;
  const {
    invoice,
    payment,
  } = observablePaymentData ?? {};

  const { profileInfo: { currenciesObject } } = useProfileInfoState();

  return (
    <Popup
      className={styles.popupPaymentLinkResult}
      open={isOpen}
      onClose={onClose}
      withCloseButton
    >
      <InvoiceResult
        invoiceInfo={invoice}
        paymentInfo={payment}
        currenciesObject={currenciesObject}
      />
    </Popup>
  );
};

ObservablePaymentPopup.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  observablePaymentData: PropTypes.shape({
    invoice: PropTypes.object,
    payment: PropTypes.object,
  }),
};

const ObservablePaymentPopupMemo = React.memo(ObservablePaymentPopup);

export default ObservablePaymentPopupMemo;
