import React from 'react';
import { Portal } from '../../../portal';
import { Popup } from '../../../popup';
import { POPUPS_OBJECT } from '../../helpers';
import { AddWalletCurrencies } from '../../../add-wallet-currencies';
import { EditWalletForm } from '../edit-wallet-form';
import { DeleteWallet } from '../delete-wallet';
import { AddWalletToWhitelist } from '../add-wallet-to-whitelist';
import { ExtendedWallet, PopupData } from '../../types';
import { Currency } from '../../../../default-types';
import classes from './styles.module.scss';

type PayoutWalletsPopupProps = {
  type: PopupData | null,
  onClose: () => void,
  currencies: Array<Currency>,
  allWallets: Array<ExtendedWallet>,
  activeWallet: ExtendedWallet | null,
  onAddWalletInWhitelist: () => void;
};

export const PayoutWalletsPopup: React.FC<PayoutWalletsPopupProps> = (props) => {
  const {
    type,
    onClose,
    currencies,
    allWallets,
    activeWallet,
    onAddWalletInWhitelist,
  } = props;

  const isLastWallet = allWallets?.length === 1 ?? false;

  return (
    <Portal>
      <Popup
        classNameContent={classes.payoutWalletsPopupContent}
        title={type?.type === POPUPS_OBJECT.delete.type && isLastWallet
          ? 'Unable to delete the only payout wallet'
          : type?.title}
        open={Boolean(type)}
        onClose={onClose}
        withCloseButton
        mobileFullView
        withoutPadding
        line
      >
        {type?.type === POPUPS_OBJECT.add.type && (
          <AddWalletCurrencies
            className={classes.payoutWalletsAddWalletPopup}
            currencies={currencies}
            isAddressHiddenFirst
            onCallback={onClose}
            showWarning
            formAttributes={{
              currencyTitle: 'Choose currency',
              currencyPlaceholder: 'Currency',
              isSmallTheme: true,
              walletTitle: 'Enter wallet address',
              walletPlaceholder: 'Address',
              memoTitle: 'Enter Memo',
              memoPlaceholder: 'Memo',
              submitButtonLabel: 'Add wallet',
            }}
          />
        )}
        {type?.type === POPUPS_OBJECT.edit.type && activeWallet && (
          <EditWalletForm
            activeWallet={activeWallet}
            onClose={onClose}
          />
        )}
        {type?.type === POPUPS_OBJECT.delete.type && activeWallet && (
          <DeleteWallet
            activeWallet={activeWallet}
            wallets={allWallets}
            onClose={onClose}
            isLastWallet={isLastWallet}
          />
        )}
        {type?.type === POPUPS_OBJECT.whitelist.type && activeWallet && (
          <AddWalletToWhitelist onClick={onAddWalletInWhitelist} />
        )}
      </Popup>
    </Portal>
  );
};
