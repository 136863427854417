import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { isFunction } from '../../../helpers/is-function';
import classes from './styles.module.scss';

export const ButtonWithIcon = (props) => {
  const {
    className,
    onClick,
    Icon,
    textShow,
    children,
  } = props;

  const handleClick = () => {
    if (isFunction(onClick)) {
      onClick();
    }
  };

  return (
    <button
      type="button"
      className={cn(classes.buttonWithIcon, className)}
      onClick={handleClick}
    >
      <span className={classes.buttonWithIconBoxImg}>
        {Icon}
        {!Icon && (
          <img
            src="/images/settings-plus.svg"
            alt="Icon settings"
            decoding="async"
            loading="lazy"
            width="12"
            height="12"
          />
        )}
      </span>
      {textShow && (
        <span className={classes.buttonWithIconText}>
          {children}
        </span>
      )}
    </button>
  );
};

ButtonWithIcon.defaultProps = {
  className: null,
  onClick: null,
  Icon: null,
  textShow: true,
  children: null,
};

ButtonWithIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  Icon: PropTypes.node,
  textShow: PropTypes.bool,
  children: PropTypes.node,
};
