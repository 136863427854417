import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OnboardingStepsIcons } from './components/onboarding-steps-icons';
import { GenerateApiKeyStep } from './components/generate-api-key-step';
import { SetUpAccountForm } from './components/set-up-account-form';
import { ActivateCustodyStep } from './components/activate-custody-step';
import { GetStartedStep } from './components/get-started-step';
import {
  currenciesFetchingSelector,
  currencyListSelector,
} from '../../../../store/currencies/selectors';
import {
  paymentSettingsFetchingSelector,
  storeDataSelector,
} from '../../../../store/payment-settings/selectors';
import { industrySelector } from '../../../../store/statistics/selectors';
import { LoaderIcon } from '../../../icons/loader-icon';
import {
  partnerFetchedSelector,
  partnerFetchingSelector,
  partnerSelector,
} from '../../../../store/partner/selectors';
import { useCryptoCurrencies } from '../../../../hooks/use-crypto-currencies';
import { useUpdateProfile } from '../../../../api/modules/account/use-update-profile';
import { setPartner } from '../../../../store/partner/reducer';
import { setIndustry } from '../../../../store/statistics/reducer';
import { getSafeErrorMessageText } from '../../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../../helpers/console-error-message';
import { useUpdateBaseCurrency } from '../../../../api/modules/account/use-update-base-currency';
import { useUpdateStorePaymentSettings } from '../../../../api/modules/account/use-update-store-payment-setting';
import {
  setBaseCurrency,
  setStoreData,
} from '../../../../store/payment-settings/reducer';
import { useIpAddress } from '../../../../api/modules/account/use-ip-address';
import { setItemToLocalStorage } from '../../../../libs/local-storage';
import { ENABLE_CUSTODY_LOCAL_STORAGE_NAME } from '../../../../constants/app-constants';
import { useOpenCustody } from '../../../../api/modules/account/use-open-custody';
import { useBaseCurrencyForSelect } from '../../../../hooks/use-base-currency-for-select';
import { SetUpAccountFormDataType } from '../../types';
import { ResponseType } from '../../../../default-types';
import classes from './styles.module.scss';

type OnboardingStepsProps = {
  startStep: number,
  generatedApiKey: string | null,
  isFetching: boolean,
  onAddKey: (apiKey: string) => void,
};

export const OnboardingSteps: React.FC<OnboardingStepsProps> = (props) => {
  const {
    startStep,
    generatedApiKey,
    isFetching,
    onAddKey,
  } = props;

  const {
    baseCurrencies,
    currentBaseCurrencyCode,
  } = useBaseCurrencyForSelect();

  const currencyList = useSelector(currencyListSelector());
  const isCurrenciesLoading = useSelector(currenciesFetchingSelector());
  const isPaymentSettingsLoading = useSelector(paymentSettingsFetchingSelector());
  const storeData = useSelector(storeDataSelector());
  const partner = useSelector(partnerSelector());
  const isPartnerFetched = useSelector(partnerFetchedSelector());
  const isPartnerFetching = useSelector(partnerFetchingSelector());
  const currentIndustry = useSelector(industrySelector());
  const fetchUpdateProfile = useUpdateProfile();
  const fetchUpdateBaseCurrency = useUpdateBaseCurrency();
  const fetchIpAddress = useIpAddress();
  const fetchOpenCustody = useOpenCustody();
  const fetchUpdateStorePaymentSettings = useUpdateStorePaymentSettings();
  const dispatch = useDispatch();
  const { cryptoCurrencies } = useCryptoCurrencies(currencyList);
  const currentCompanyName = partner?.companyName;
  const storeDetails = partner?.storeDetails;
  const isCustody = partner?.isCustody;
  const currentFeePaidByCode = storeData?.withdrawalFeePaidBy;
  const isStartActivateCustodyShow = !isPartnerFetching && !isCustody && isPartnerFetched;
  const isLoading = isCurrenciesLoading || isPaymentSettingsLoading;

  const [stepNumber, setStepNumber] = React.useState(startStep);

  const handleContinue = () => {
    setStepNumber((prev) => prev + 1);
  };
  const handleBack = () => {
    setStepNumber((prev) => prev - 1);
  };
  const setNewCompanyName = async (companyName: string) => {
    const {
      data,
      status,
      errorMessage,
    }: ResponseType = await fetchUpdateProfile(companyName, storeDetails || null);

    if (status === 200 && data) {
      dispatch(setPartner({ ...partner, companyName }));
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/profile');
    }
  };
  const setNewBaseCurrency = async (baseCurrencyCode: string) => {
    const {
      data,
      status,
      errorMessage,
    }: ResponseType = await fetchUpdateBaseCurrency(baseCurrencyCode);

    if (status === 200 && data) {
      dispatch(setBaseCurrency(baseCurrencyCode));
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/base-currency');
    }
  };
  const setNewFeePaidBy = async (nextFeePaidBy: string) => {
    const {
      data,
      status,
      errorMessage,
    }: ResponseType = await fetchUpdateStorePaymentSettings({ withdrawalFeePaidBy: nextFeePaidBy });

    if (status === 200 && data) {
      dispatch(setStoreData({ ...data }));
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/store-payment-settings');
    }
  };
  const getIpAddress = async () => {
    const {
      data,
      status,
      errorMessage,
    }: ResponseType = await fetchIpAddress();

    if (status === 200) {
      return data?.ip;
    }
    const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
    consoleErrorMessage(errorMessage, errorDataMessage, '/ip');
    return null;
  };
  const handleSubmitSetUpAccountForm = async (formData: SetUpAccountFormDataType) => {
    const {
      companyName,
      industry,
      baseCurrency,
      feePaidBy,
    } = formData;

    const isValidCompanyName = companyName && companyName !== currentCompanyName;
    const isValidIndustry = industry && industry !== currentIndustry;
    const isValidBaseCurrencyCode = baseCurrency && baseCurrency !== currentBaseCurrencyCode;
    const isValidFeePaidBy = feePaidBy && feePaidBy !== currentFeePaidByCode;

    if (isValidIndustry) dispatch(setIndustry(industry));

    await Promise.allSettled([
      isValidCompanyName && setNewCompanyName(companyName),
      isValidBaseCurrencyCode && setNewBaseCurrency(baseCurrency),
      isValidFeePaidBy && setNewFeePaidBy(feePaidBy),
    ]);
  };
  const handleCustodyActivate = async () => {
    const currentIp = await getIpAddress();
    if (currentIp) {
      const {
        data,
        status,
        errorMessage,
      }: ResponseType = await fetchOpenCustody({ ips: [currentIp] });

      if (status === 200 && data?.result) {
        dispatch(setPartner({ ...partner, isCustody: true }));
        setItemToLocalStorage(ENABLE_CUSTODY_LOCAL_STORAGE_NAME, true);
      } else {
        const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
        consoleErrorMessage(errorMessage, errorDataMessage, '/open-custody');
      }
    }
  };

  return (
    <div className={classes.onboardingSteps}>
      {!isLoading ? (
        <>
          <OnboardingStepsIcons
            activeStepNumber={stepNumber}
            startStep={startStep}
          />
          {stepNumber === 1 && (
            <GenerateApiKeyStep
              currencies={cryptoCurrencies}
              onContinue={handleContinue}
              generatedApiKey={generatedApiKey}
              isFetching={isFetching}
              onAddKey={onAddKey}
            />
          )}
          {stepNumber === 2 && (
            <SetUpAccountForm
              hasBackButton={startStep !== 2}
              onBack={handleBack}
              onContinue={handleContinue}
              currencies={baseCurrencies}
              feePaidByCode={currentFeePaidByCode}
              currentCompanyName={currentCompanyName}
              currentBaseCurrencyCode={currentBaseCurrencyCode}
              industry={currentIndustry}
              onSubmit={handleSubmitSetUpAccountForm}
            />
          )}
          {stepNumber === 3 && (
            <ActivateCustodyStep
              onBack={handleBack}
              onContinue={handleContinue}
              isStartActivateCustodyShow={isStartActivateCustodyShow}
              onActivate={handleCustodyActivate}
            />
          )}
          {stepNumber === 4 && (
            <GetStartedStep
              onBack={handleBack}
              industry={currentIndustry || 'other'}
            />
          )}
        </>
      ) : (
        <LoaderIcon size={20} />
      )}
    </div>
  );
};
