import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button } from '../../../ui/button';
import { LoaderIcon } from '../../../icons/loader-icon';
import { Balances } from '../../../balances';
import { Section } from '../../../section';
import { BALANCE_ELEMENTS_PER_PAGE } from '../../custody-page-helpers';
import { NewPagination } from '../../../shared/new-pagination';
import { InputSearch } from '../../../ui/input-search';
import { EmptySearch } from '../../../empty-search';
import classes from './styles.module.scss';

export const SectionBalance = (props) => {
  const {
    className,
    buttonTopUpShow,
    onConvert,
    onTopUp,
    onRequestPayout,
    isDataFetching,
    isMarketInfoFetching,
    hasBalance,
    balance,
    balanceLength,
    balanceFilteredLength,
    page,
    onPage,
    onSearch,
  } = props;

  const ref = React.useRef(null);

  const [isLoading, setIsLoading] = React.useState(true);

  const handlePageChange = (page) => {
    onPage(page);
    ref.current?.scrollIntoView({ behavior: 'instant' });
  };

  React.useEffect(() => {
    let timerId = null;
    if (!isDataFetching) {
      timerId = setTimeout(() => setIsLoading(false), 100);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [isDataFetching]);

  const isPaginationShow = hasBalance && balanceFilteredLength > BALANCE_ELEMENTS_PER_PAGE;
  const isSearchShow = hasBalance && balanceLength > BALANCE_ELEMENTS_PER_PAGE;
  const isEmptyBoxShow = hasBalance && balanceFilteredLength === 0;
  const isLoaderShow = isLoading || isMarketInfoFetching;

  return (
    <Section
      title="Balances"
      className={cn([
        className,
        classes.sectionBalance,
        !hasBalance && classes.sectionBalanceEmpty,
      ])}
      rightElement={
        <div
          className={classes.sectionBalanceHeaderControl}
          ref={ref}
        >
          {isSearchShow && (
            <InputSearch
              id="balances-search"
              className={classes.sectionBalanceSearch}
              placeholder="Search coin"
              onChange={onSearch}
              imageSearch="/images/search-dark-gray-icon.svg"
            />
          )}
          {buttonTopUpShow && (
            <Button
              className={classes.button}
              type="button"
              onClick={onTopUp}
            >
              Top-up
            </Button>
          )}
        </div>
      }
    >
      {isLoaderShow && (
        <div className={classes.loader}>
          <LoaderIcon size={30} />
        </div>
      )}
      {!isLoaderShow && (
        <>
          {!isEmptyBoxShow && hasBalance && (
            <Balances
              className={cn([
                classes.balances,
                isSearchShow && classes.balancesWithSearch,
              ])}
              items={balance}
              onConvert={onConvert}
              onTopUp={onTopUp}
              onRequestPayout={onRequestPayout}
              isLoading={isLoading}
            />
          )}
          {isEmptyBoxShow && (
            <EmptySearch>
              Sorry, no coins were found
            </EmptySearch>
          )}
          {isPaginationShow && (
            <NewPagination
              className={classes.sectionBalancePagination}
              currentPage={page}
              countItems={balanceFilteredLength}
              itemsPerPage={BALANCE_ELEMENTS_PER_PAGE}
              onChangePage={handlePageChange}
            />
          )}
          {!hasBalance && (
            <div className={classes.balancesEmptyText}>
              <p>Your assets will appear here when you receive new payments or top-up balance.</p>
              <picture className={classes.balancesEmptyPicture}>
                <source
                  srcSet="/images/custody-page/custody-empty-balances-desktop.svg"
                  media="(min-width: 768px)"
                />
                <img
                  className={classes.balancesEmptyImage}
                  src="/images/custody-page/custody-empty-balances.svg"
                  alt="Empty balance"
                  decoding="async"
                  loading="lazy"
                />
              </picture>
              <Button
                className={cn(classes.balancesEmptyButton)}
                type="button"
                onClick={onTopUp}
              >
                Top-up balance
              </Button>
            </div>
          )}
        </>
      )}
    </Section>
  );
};

SectionBalance.defaultProps = {
  className: null,
  buttonTopUpShow: false,
  onConvert: null,
  onTopUp: null,
  onRequestPayout: null,
  isDataFetching: false,
  isMarketInfoFetching: false,
  hasBalance: false,
  balance: null,
  balanceLength: null,
  balanceFilteredLength: null,
  page: 1,
  onPage: null,
  onSearch: null,
};

SectionBalance.propTypes = {
  className: PropTypes.string,
  buttonTopUpShow: PropTypes.bool,
  onConvert: PropTypes.func,
  onTopUp: PropTypes.func,
  onRequestPayout: PropTypes.func,
  isLoading: PropTypes.bool,
  isDataFetching: PropTypes.bool,
  isMarketInfoFetching: PropTypes.bool,
  hasBalance: PropTypes.bool,
  balance: PropTypes.arrayOf(PropTypes.object),
  balanceLength: PropTypes.number,
  balanceFilteredLength: PropTypes.number,
  page: PropTypes.number,
  onPage: PropTypes.func,
  onSearch: PropTypes.func,
};
