import React from 'react';
import { useSelector } from 'react-redux';
import { useAvailableCurrencies } from '../../api/modules/account/use-available-currencies';
import { hasElementsInArray } from '../../helpers/has-elements-in-array';
import { safeToLowerCase } from '../../helpers/safe-to-lower-case';
import {
  ENABLE_CUSTODY_LOCAL_STORAGE_NAME,
  PAGE_NAMES_TO_DISPLAY_INFO,
} from '../../constants/app-constants';
import { useBalance } from '../../api/modules/account/use-balance';
import { useWithdrawalPayments } from '../../api/modules/account/use-withdrawal-payments';
import { isObject } from '../../helpers/is-object';
import { useBaseCurrency } from '../../api/modules/account/use-base-currency';
import { getFilteredCurrencies } from '../../helpers/get-filtered-currencies';
import { setItemToLocalStorage } from '../../libs/local-storage';
import { LoaderIcon } from '../icons/loader-icon';
import { EnableCustodySteps } from '../enable-custody-steps';
import { useOpenCustody } from '../../api/modules/account/use-open-custody';
import { Section } from '../section';
import { useCustodyCheck } from '../../hooks/use-custody-check';
import { getSafeErrorMessageText } from '../../helpers/get-safe-error-message-text';
import { useConversionHistory } from '../../api/modules/account/use-conversion-history';
import { useApiKeys } from '../../api/modules/account/use-api-keys';
import { usePageNamesToDisplayInfo } from '../../hooks/use-page-names-to-display-info';
import { PageHeader } from '../ui/page-header';
import { useMarketInfoRates } from '../../hooks/use-market-info-rates';
import { getUniqueTickersForMarketInfo } from '../../helpers/get-unique-tickers-for-market-info';
import { useFetchPartnerProfile } from '../../api/modules/account/use-fetch-partner-profile';
import { useAllCurrencies } from '../../hooks/use-all-currencies';
import { useCurrenciesToObject } from '../../hooks/use-currencies-to-object';
import { currencyListSelector, selectedTickersSelector } from '../../store/currencies/selectors';
import { useCryptoCurrencies } from '../../hooks/use-crypto-currencies';
import { useStoreOutcomeData } from '../../hooks/use-store-outcome-data';
import { walletsSelector } from '../../store/payment-settings/selectors';
import { useFiatPayouts } from '../../api/modules/account/use-fiat-payouts';
import { useDepositAddressesData } from '../../hooks/use-deposit-addresses-data';
import { CustodyMain } from './components/custody-main';
import { CustodyInfoAboutUse } from './components/custody-info-about-use';
import classes from './styles.module.scss';

const CURRENCY_USD = 'usd';
const BALANCE_TIMER_TIME = 10000;
let nextIntervalId = null;

export const CustodyPage = () => {
  const fetchOpenCustody = useOpenCustody();
  const fetchApiKeys = useApiKeys();
  const fetchAvailableCurrencies = useAvailableCurrencies();
  const fetchBalance = useBalance();
  const fetchWithdrawalPayments = useWithdrawalPayments();
  const fetchConversionHistory = useConversionHistory();
  const fetchFiatHistory = useFiatPayouts();
  const fetchBaseCurrency = useBaseCurrency();
  const currencyList = useSelector(currencyListSelector());
  const tickersSelectedByPartner = useSelector(selectedTickersSelector());
  const { cryptoCurrencies } = useCryptoCurrencies(currencyList);
  const { currenciesObject } = useCurrenciesToObject(currencyList);
  const wallets = useSelector(walletsSelector());
  const hasWallets = hasElementsInArray(wallets);

  const {
    isCustody,
    isCustodyFetching,
    isCustodyFetched,
    setIsCustody,
  } = useCustodyCheck();

  const {
    infoBoxShow,
    addPageNameToDisplayInfo,
    removePageNameToDisplayInfo,
  } = usePageNamesToDisplayInfo(PAGE_NAMES_TO_DISPLAY_INFO.CUSTODY);

  const [balance, setBalance] = React.useState([]);
  const [withdrawals, setWithdrawals] = React.useState([]);
  const [conversionHistory, setConversionHistory] = React.useState([]);
  const [fiatHistory, setFiatHistory] = React.useState([]);
  const [baseCurrency, setBaseCurrency] = React.useState(CURRENCY_USD);
  const [apiErrorMessage, setApiErrorMessage] = React.useState(null);
  const [isBalanceApiError, setIsBalanceApiError] = React.useState(false);
  const [isCustodyPreloading, setIsCustodyPreloading] = React.useState(false);
  const [isDataFetching, setIsDataFetching] = React.useState(false);
  const [isEnableCustodyFetching, setIsEnableCustodyFetching] = React.useState(false);
  const [isNeedMarketInfoRates, setIsNeedMarketInfoRates] = React.useState(false);

  const tickersForMarketInfo = React.useMemo(() => (
    getUniqueTickersForMarketInfo(balance, currenciesObject, true)
  ), [balance, currenciesObject]);
  const partnerCurrencies = React.useMemo(() => (
    getFilteredCurrencies(cryptoCurrencies, tickersSelectedByPartner)
  ), [cryptoCurrencies, tickersSelectedByPartner]);

  const handleEnableCustodySubmit = async (ips) => {
    setApiErrorMessage(null);
    setIsEnableCustodyFetching(true);
    const { data, status } = await fetchOpenCustody({ ips });
    setIsEnableCustodyFetching(false);

    if (status === 200 && data?.result) {
      setIsCustody(true);
      setIsCustodyPreloading(true);
      setItemToLocalStorage(ENABLE_CUSTODY_LOCAL_STORAGE_NAME, true);
    } else {
      const errorMessage = getSafeErrorMessageText(data?.errorData?.message);

      setApiErrorMessage(errorMessage);
    }
  };
  const getApiKeys = async () => {
    const { data, status } = await fetchApiKeys();

    if (status === 200) {
      return data?.keys ?? [];
    }

    return [];
  };
  const getAvailableCurrencies = async () => {
    const { data, status } = await fetchAvailableCurrencies();

    return status === 200 && hasElementsInArray(data) ? data : [];
  };
  const getBaseCurrency = async () => {
    const { data, status } = await fetchBaseCurrency();

    if (status === 200) {
      const baseCurrency = data?.baseCurrency ?? CURRENCY_USD;

      return safeToLowerCase(baseCurrency);
    }

    return CURRENCY_USD;
  };
  const fetchBalanceInterval = async () => {
    if (!isBalanceApiError) {
      return;
    }

    nextIntervalId = setTimeout(async () => {
      const { data, status } = await fetchBalance();

      if (status === 200) {
        const balanceIsObject = isObject(data?.balance);
        const balanceNormalized = balanceIsObject ? Object.values(data.balance) : [];

        setIsBalanceApiError(false);
        setIsCustodyPreloading(false);
        setBalance(balanceNormalized);
        clearTimeout(nextIntervalId);
      } else {
        fetchBalanceInterval();
      }
    }, BALANCE_TIMER_TIME);
  };
  const getBalance = async () => {
    const { data, status } = await fetchBalance();

    if (status === 200) {
      setIsCustodyPreloading(false);
      const balanceIsObject = isObject(data?.balance);

      return balanceIsObject ? Object.values(data.balance) : [];
    } else {
      setIsBalanceApiError(true);
    }

    return [];
  };
  const getWithdrawals = async () => {
    const { data, status } = await fetchWithdrawalPayments({
      params: {
        type: 'balance_payouts',
      },
    });

    if (status === 200) {
      return data?.withdrawals ?? [];
    }

    return [];
  };
  const updateWithdrawals = async () => {
    const updatedWithdrawals = await getWithdrawals();
    setWithdrawals(updatedWithdrawals);

    return updatedWithdrawals;
  };
  const getConversionHistory = async () => {
    const { data, status } = await fetchConversionHistory();

    if (status === 200 && hasElementsInArray(data?.result)) {
      return data.result;
    }

    return [];
  };
  const updateConversionHistory = async () => {
    const nextConversionHistory = await getConversionHistory();

    if (hasElementsInArray(nextConversionHistory)) {
      setConversionHistory(nextConversionHistory);
    }
  };
  const getFiatHistory = async () => {
    const { data, status } = await fetchFiatHistory();

    if (status === 200 && hasElementsInArray(data?.result)) {
      return data.result;
    }

    return [];
  };
  const updateFiatHistory = async () => {
    const nextFiatHistory = await getFiatHistory();

    if (hasElementsInArray(nextFiatHistory)) {
      setFiatHistory(nextFiatHistory);
    }
  };
  const initProfile = async () => {
    setIsDataFetching(true);
    const [
      getBaseCurrencyResponse,
      getBalanceResponse,
      getWithdrawalsResponse,
      getConversionHistoryResponse,
      getFiatHistoryResponse,
    ] = await Promise.allSettled([
      getBaseCurrency(),
      getBalance(),
      getWithdrawals(),
      getConversionHistory(),
      getFiatHistory(),
    ]);
    const baseCurrencyResponseValue = getBaseCurrencyResponse?.value ?? CURRENCY_USD;
    const balanceValue = getBalanceResponse?.value ?? [];
    const withdrawalsValue = getWithdrawalsResponse?.value ?? [];
    const conversionHistoryValue = getConversionHistoryResponse?.value ?? [];
    const fiatHistoryValue = getFiatHistoryResponse?.value ?? [];

    setBaseCurrency(baseCurrencyResponseValue);
    setBalance(balanceValue);
    setWithdrawals(withdrawalsValue);
    setConversionHistory(conversionHistoryValue);
    setFiatHistory(fiatHistoryValue);
    setIsDataFetching(false);

    if (hasElementsInArray(balanceValue)) {
      setIsNeedMarketInfoRates(true);
    }
  };

  React.useEffect(() => {
    if (isCustody) {
      initProfile();
    }
  }, [isCustody]);

  React.useEffect(() => {
    if (isCustodyPreloading && isBalanceApiError) {
      fetchBalanceInterval();
    }

    return () => {
      if (nextIntervalId) {
        clearTimeout(nextIntervalId);
      }
    };
  }, [isCustodyPreloading, isBalanceApiError]);

  React.useEffect(() => {
    let nextTimerId = null;
    if (apiErrorMessage) {
      nextTimerId = setTimeout(() => {
        setApiErrorMessage(null);
      }, 3000);
    }

    return () => {
      if (nextTimerId) {
        clearTimeout(nextTimerId);
      }
    };
  }, [apiErrorMessage]);

  useFetchPartnerProfile();
  useAllCurrencies();
  useDepositAddressesData();
  useStoreOutcomeData();
  useMarketInfoRates({
    isNeedRates: isNeedMarketInfoRates,
    tickers: tickersForMarketInfo,
  });

  const enableCustodyStepsShow = !isCustodyFetching && !isCustody && isCustodyFetched;
  const isCustodyPreloadContentShow = !enableCustodyStepsShow && isCustodyPreloading;
  const isCustodyBalanceErrorTextShow = !enableCustodyStepsShow && !isCustodyPreloading && isBalanceApiError;
  const contentShow = isCustodyFetched && isCustody && !isCustodyPreloading && !isBalanceApiError;

  return (
    <div className={classes.custody}>
      <PageHeader
        title="Custody"
        showTooltipFirstText={!infoBoxShow}
        onClick={infoBoxShow ? removePageNameToDisplayInfo : addPageNameToDisplayInfo}
        infoTooltipShow={contentShow}
      />
      {isCustodyFetching && (
        <div className={classes.loaderBlock}>
          <LoaderIcon size={40} />
        </div>
      )}
      {enableCustodyStepsShow && (
        <EnableCustodySteps
          onSubmit={handleEnableCustodySubmit}
          apiErrorMessage={apiErrorMessage}
          fetching={isEnableCustodyFetching}
          onAvailableCurrencies={getAvailableCurrencies}
          onApiKeys={getApiKeys}
          hasWallets={hasWallets}
        />
      )}
      {isCustodyPreloadContentShow && (
        <Section className={classes.balanceErrorBox}>
          <div className={classes.balanceErrorBoxFlex}>
            We are setting up your custody. Please stand by
            {' '}
            <LoaderIcon size={25} />
          </div>
        </Section>
      )}
      {isCustodyBalanceErrorTextShow && (
        <Section className={classes.balanceErrorBox}>
          <div>
            Your balances are safe, but temporarily unavailable.
          </div>
          <div>
            Please try a bit later, or reach us to
            {' '}
            <a href='mailto:support@nowpayments.io'>support@nowpayments.io</a>
            {' '}
            if you want to stay tuned!
          </div>
        </Section>
      )}
      {contentShow && (
        <>
          {infoBoxShow && (
            <CustodyInfoAboutUse
              className={classes.infoBlock}
              name={PAGE_NAMES_TO_DISPLAY_INFO.CUSTODY}
              onClose={removePageNameToDisplayInfo}
            />
          )}
          <CustodyMain
            availableCurrencies={cryptoCurrencies}
            partnerCurrencies={partnerCurrencies}
            currenciesObject={currenciesObject}
            balance={balance}
            withdrawals={withdrawals}
            conversionHistory={conversionHistory}
            fiatHistory={fiatHistory}
            baseCurrency={baseCurrency}
            dataFetching={isDataFetching}
            onUpdateWithdrawals={updateWithdrawals}
            onUpdateConversionHistory={updateConversionHistory}
            onUpdateFiatHistory={updateFiatHistory}
          />
        </>
      )}
    </div>
  );
};
