import * as EmailValidator from 'email-validator';
import { safeToUpperCase } from '../../../helpers/safe-to-upper-case';
import { formatDate } from '../../../helpers/format-date';
import { DATE_PATTERN } from '../../../constants/app-constants';
import { getCurrentNetworkForShow } from '../../../helpers/get-current-network-for-show';

/**
 * @param {Object} data
 * @param {Boolean} desktop
 * @param {Object} currenciesObject
 * @returns {Array<object>}
 */
export const getPlanInfoData = (data, desktop, currenciesObject = {}) => {
  const id = data?.id ?? null;
  const title = data?.title ?? '-';
  const amount = data?.amount ?? null;
  const ticker = data?.currency ?? '';
  const tickerUpperCased = safeToUpperCase(ticker);
  const coinFound = currenciesObject[tickerUpperCased] ?? {};
  const currentTicker = coinFound?.ticker ?? tickerUpperCased;
  const amountWithTicker = amount ? `${amount} ${currentTicker}` : '-';
  const currentNetwork = getCurrentNetworkForShow(coinFound?.network, ticker);
  const period = data?.intervalDay ?? null;
  const currentPeriodName = period === 1 ? 'Day' : 'Days';
  const periodNormalized = period ? `${period} ${currentPeriodName}` : '-';
  const createdAt = data?.createdAt ?? null;
  const createdDateFormatted = formatDate(createdAt, DATE_PATTERN);
  const createdDateNormalized = createdDateFormatted || '-';
  const defaultParams = {
    textSecond: null,
    isTextBlack: false,
    isTextOverflow: false,
    isTextSecondBlack: false,
    isTextSecondOverflow: false,
    textWithTitle: false,
    textSecondWithTitle: false,
    network: null,
  };
  const desktopData = [{
    id: '1',
    text: id,
    ...defaultParams,
  }, {
    id: '2',
    text: title,
    ...defaultParams,
    isTextBlack: true,
    isTextOverflow: true,
    textWithTitle: true,
  }, {
    id: '3',
    text: amountWithTicker,
    ...defaultParams,
    isTextBlack: true,
    network: currentNetwork,
  }, {
    id: '4',
    text: periodNormalized,
    ...defaultParams,
    isTextBlack: true,
  }, {
    id: '5',
    text: createdDateNormalized,
    ...defaultParams,
  }];
  const mobileData = [{
    id: '1',
    text: id,
    ...defaultParams,
    textSecond: title,
    isTextSecondBlack: true,
    isTextSecondOverflow: true,
    textSecondWithTitle: true,
  }, {
    id: '2',
    text: amountWithTicker,
    ...defaultParams,
    network: currentNetwork,
    textSecond: periodNormalized,
    isTextBlack: true,
    isTextSecondBlack: true,
  }, {
    id: '3',
    text: createdDateNormalized,
    ...defaultParams,
  }];

  return desktop ? desktopData : mobileData;
};

/**
 * @param {Array<string>} emails
 * @param {Number} maxEmails
 * @returns {{isValid: boolean, error: string}|{isValid: boolean, error: null}}
 */
export const checkEmails = (emails, maxEmails) => {
  const isEmailsLengthAllowed = emails.length <= maxEmails;

  if (!isEmailsLengthAllowed) {
    return {
      isValid: false,
      error: `Emails must be at most ${maxEmails}`,
    };
  }

  const emailInvalid = emails.find((email) => !EmailValidator.validate(email));

  if (emailInvalid) {
    return {
      isValid: false,
      error: `Email - ${emailInvalid} is not valid`,
    };
  }

  return {
    isValid: true,
    error: null,
  };
};
