import differenceInDays from 'date-fns/differenceInDays';
import { PERIODS } from '../constants/app-constants';

/**
 * @param {Number} periodNumber
 * @param {String} period
 * @returns {Number}
 */
export const getPeriodDays = (periodNumber = 0, period = null) => {
  const endDate = new Date();

  switch (period) {
    case PERIODS.DAYS: {
      endDate.setDate(endDate.getDate() + periodNumber);
      break;
    }
    case PERIODS.MONTHS: {
      endDate.setMonth(endDate.getMonth() + periodNumber);
      break;
    }
    case PERIODS.YEARS: {
      endDate.setFullYear(endDate.getFullYear() + periodNumber);
      break;
    }
    default: {
      console.info('Period not found');
    }
  }

  return differenceInDays(endDate, new Date());
};
