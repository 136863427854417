import React from 'react';
import { isFunction } from '../helpers/is-function';
import { createSubscription } from '../api/helpers/account/requests';
import { hasElementsInArray } from '../helpers/has-elements-in-array';
import { useTimeout } from './use-timeout';

/**
 * @param {{ subPartnerId: string|*, callback: function|* }} options
 * @returns {Object}
 */
export const useAddSubPartnerToSubscriptionPlans = (options = {}) => {
  const { subPartnerId, callback } = options;

  const [isFetching, setIsFetching] = React.useState(false);
  const [isSent, setIsSent] = React.useState(false);
  const [apiError, setApiError] = React.useState(null);

  const resetSent = () => {
    setIsSent(false);
  };
  const handleSubmit = React.useCallback(async (arrayIds) => {
    if (!hasElementsInArray(arrayIds)) {
      return;
    }
    setApiError(null);
    setIsFetching(true);
    const promises = arrayIds.map((subscriptionPlanId) => createSubscription({
      subscriptionPlanId: subscriptionPlanId,
      subPartnerId,
    }));
    const promisesResponse = await Promise.allSettled(promises);
    const apiError = [...promisesResponse]
      .filter((item) => item?.value?.data?.errorData?.message)
      .map((item) => item?.value?.data?.errorData?.message)
      .join(',');

    if (apiError) {
      setApiError(apiError);
    }
    setIsSent(true);
    setIsFetching(false);
    if (isFunction(callback)) {
      callback();
    }
  }, [subPartnerId]);

  useTimeout(resetSent, 5000, isSent);

  return {
    isSent,
    isFetching,
    apiError,
    handleSubmit,
  };
};
