import React from 'react';
import cn from 'classnames';
import { CurrencyNetworkSup } from '../shared/currency-network-sup';
import { getUrlWithDomain } from '../../helpers/get-url-with-domain';
import { isCurrencyNetworkShow } from '../../helpers/is-currency-network-show';
import classes from './styles.module.scss';

type LogoTickerNetworkProps = {
  className?: string,
  logoUrl: string,
  currentTicker: string,
  ticker: string,
  network: string,
  logoSize?: number,
};

export const LogoTickerNetwork: React.FC<LogoTickerNetworkProps> = (props) => {
  const {
    className,
    logoUrl,
    currentTicker,
    ticker,
    network,
    logoSize,
  } = props;

  const imagePath = getUrlWithDomain(logoUrl);
  const isNetworkShow = isCurrencyNetworkShow(network, ticker);

  return (
    <div className={cn([
      className,
      classes.logoTickerNetwork,
    ])}
    >
      <img
        width={logoSize}
        height={logoSize}
        src={imagePath}
        alt={currentTicker}
        decoding="async"
      />
      {currentTicker}
      {isNetworkShow && (
        <CurrencyNetworkSup network={network} />
      )}
    </div>
  );
};

LogoTickerNetwork.defaultProps = {
  className: '',
  logoSize: 20,
};
