import React from 'react';
import PropTypes from 'prop-types';

export const PaymentDetailsIcon = (props) => {
  const { color } = props;

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.20667 1.33398H10.794C12.8533 1.33398 14 2.52065 14 4.55398V11.4407C14 13.5073 12.8533 14.6673 10.794 14.6673H5.20667C3.18 14.6673 2 13.5073 2 11.4407V4.55398C2 2.52065 3.18 1.33398 5.20667 1.33398ZM5.38667 4.44065V4.43398H7.37933C7.66667 4.43398 7.9 4.66732 7.9 4.95332C7.9 5.24732 7.66667 5.48065 7.37933 5.48065H5.38667C5.09933 5.48065 4.86667 5.24732 4.86667 4.96065C4.86667 4.67398 5.09933 4.44065 5.38667 4.44065ZM5.38667 8.49399H10.6133C10.9 8.49399 11.1333 8.26065 11.1333 7.97399C11.1333 7.68732 10.9 7.45332 10.6133 7.45332H5.38667C5.09933 7.45332 4.86667 7.68732 4.86667 7.97399C4.86667 8.26065 5.09933 8.49399 5.38667 8.49399ZM5.38667 11.5407H10.6133C10.8794 11.514 11.08 11.2867 11.08 11.0207C11.08 10.7473 10.8794 10.5207 10.6133 10.494H5.38667C5.18667 10.474 4.99333 10.5673 4.88667 10.7407C4.78 10.9073 4.78 11.1273 4.88667 11.3007C4.99333 11.4673 5.18667 11.5673 5.38667 11.5407Z"
      />
    </svg>
  );
};

PaymentDetailsIcon.defaultProps = {
  color: '#A4A4A4',
};

PaymentDetailsIcon.propTypes = {
  color: PropTypes.string,
};
