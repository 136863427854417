import React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';
import { walletMapFn } from "../../helpers/account/map-fns";

const mapFn = (data) => Array.isArray(data) ? data.map(walletMapFn) : [];

export const useCreateStoreOutcomeWallets = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (options) => {
    const wallets = options?.wallets ?? [];
    const [cancelToken] = getRequestSource();
    const requestPromise = client.put(
      '/store-outcome-wallets',
      { wallets },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
