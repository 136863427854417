import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from '../../../popup';
import { RequestPayoutForm } from '../../../request-payout-form';
import { AddNewAccountForm } from '../../../add-new-account-form';
import { RequestPayoutCreated } from '../request-payout-created';

export const RequestPayoutPopup = (props) => {
  const {
    className,
    open,
    onClose,
    onClick,
    title,
    currencies,
    currency,
    errorMessage,
    onResetPayoutFormData,
    fetching,
    isPayoutCreatedShow,
    payoutCreatedData,
    providersData,
    walletData,
  } = props;

  const {
    onUpdate,
    fiatCurrencies,
  } = providersData;

  const [isAddAccountFormActive, setIsAddAccountFormActive] = React.useState(false);

  const activeRequestPayoutForm = () => {
    setIsAddAccountFormActive(false);
  };
  const activeAddAccountForm = () => {
    setIsAddAccountFormActive(true);
  };

  return (
    <Popup
      classNameContent={className}
      open={open}
      onClose={onClose}
      withCloseButton
      mobileFullView
    >
      {isPayoutCreatedShow && (
        <RequestPayoutCreated
          onClick={onClose}
          payoutData={payoutCreatedData}
        />
      )}
      {!isPayoutCreatedShow && isAddAccountFormActive && (
        <AddNewAccountForm
          onUpdate={onUpdate}
          closePopup={activeRequestPayoutForm}
          fiatCurrencies={fiatCurrencies}
          hasBackArrow
          onBack={activeRequestPayoutForm}
        />
      )}
      {!isPayoutCreatedShow && !isAddAccountFormActive && (
        <RequestPayoutForm
          title={title}
          currencies={currencies}
          currency={currency}
          onClick={onClick}
          errorMessage={errorMessage}
          onResetPayoutFormData={onResetPayoutFormData}
          fetching={fetching}
          onAddAccountClick={activeAddAccountForm}
          providersData={providersData}
          walletData={walletData}
        />
      )}
    </Popup>
  );
};

RequestPayoutPopup.defaultProps = {
  className: null,
  open: false,
  onClose: null,
  onClick: null,
  title: null,
  address: null,
  currencies: null,
  currency: null,
  errorMessage: null,
  onResetPayoutFormData: null,
  fetching: false,
  isPayoutCreatedShow: false,
  payoutCreatedData: null,
  providersData: {},
  walletData: {},
};

RequestPayoutPopup.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  title: PropTypes.string,
  address: PropTypes.string,
  currencies: PropTypes.arrayOf(PropTypes.shape({})),
  currency: PropTypes.object,
  errorMessage: PropTypes.string,
  onResetPayoutFormData: PropTypes.func,
  fetching: PropTypes.bool,
  isPayoutCreatedShow: PropTypes.bool,
  payoutCreatedData: PropTypes.shape({}),
  providersData: PropTypes.shape({}),
  walletData: PropTypes.shape({}),
};
