import React from 'react';
import classes from '../whitelist-popup/styles.module.scss';
import { Button } from '../../../ui/button';

const EMAIL_TITLE = 'IP address whitelisting';
const EMAIL_BODY = 'I\'d like to confirm to disable IP whitelisting and take all the risks related to it.';

type ContentForInfoType = {
  onClick: () => void,
};

export const ContentForInfo: React.FC<ContentForInfoType> = (props) => {
  const { onClick } = props;

  return (
    <>
      <div>
        Whitelisting is designed to
        {' '}
        <strong>protect your funds from unauthorized withdrawals</strong>
        {' '}
        from your account. With this system in place, fund withdrawal risk is controlled by
        only allowing transactions from whitelisted IP addresses.
        <br />
        ⚠️ You may opt out of this procedure by submitting a request and assuming associated risks.
      </div>
      <div className={classes.whitelistPopupButtons}>
        <Button
          className={classes.whitelistPopupButton}
          onClick={onClick}
        >
          OK
        </Button>
        <Button
          className={classes.whitelistPopupButton}
          href={`mailto:partners@nowpayments.io?subject=${EMAIL_TITLE}&body=${EMAIL_BODY}`}
          displayBorderRed
        >
          Request to disable IP Whitelisting
        </Button>
      </div>
    </>
  );
};
