import React, { useState, useEffect, useRef } from 'react';
import ValidatorWrapper, { ValidatorField, rules } from '@coxy/react-validator';
import cn from 'classnames';
import apiClient from '../../../api/api-client';
import config from '../../../config';
import commonStyles from '../../../css/common.module.scss';
import styles from './styles.module.scss';

const ShopifySettings = () => {
  const [shopifyApiKey, setShopifyApiKey] = useState('');
  const [shopifyApiAccessToken, setShopifyApiAccessToken] = useState('');
  const [shopDomain, setShopDomain] = useState('');
  const [shopName, setShopName] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [apiKeys, setApiKeys] = useState([]);
  const [submitPressed, setSubmitPressed] = useState(false);
  const [selectDisabled, setSelectDisabled] = useState(false);
  const [submitted, setSubmitted] = useState({
    message: '',
    success: false,
  });

  const validator = useRef(null);

  const fetch = async () => {
    const result = await apiClient.getApiKeys();

    if (Object.keys(result).length) {
      const keys = result.keys.filter((key) => key.is_active);

      setApiKeys(keys);

      return keys;
    } else {
      setApiKeys([]);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  const fetchApiKeys = async () => {
    const result = await apiClient.getShopifyInfo();

    if (!result.status) {
      setApiKey(apiKeys[0].api_key);

      return;
    }

    if (apiKeys.find((key) => key.id === result.data.token_id)) {
      setApiKey(apiKeys.find((key) => key.id === result.data.token_id).api_key);
      setSelectDisabled(true);
    }

    setShopifyApiKey(result.data.api_key);
    setShopName(result.data.shop_name);
    setShopDomain(result.data.domain);
    setShopifyApiAccessToken(result.data.password);
  };

  useEffect(() => {
    if (!apiKeys.length) return;
    fetchApiKeys();
  }, [apiKeys]);

  const handleDomainChange = (e) => {
    setShopDomain(e.target.value);
  };

  const handleSubmit = async () => {
    const { isValid } = validator.current.validate();

    if (isValid) {
      let apiKeyId;

      try {
        apiKeyId = apiKeys.find((key) => key.api_key === apiKey).id;
      } catch (e) {
        setSubmitted({
          success: false,
          message: 'Error',
        });
      }

      const response = await apiClient.saveShopifyInfo({
        shopName,
        shopifyAdminApiAccessToken: shopifyApiAccessToken,
        domain: shopDomain.replace(/^https?:\/\//, ''),
        shopifyApiKey,
        apiKey: apiKeyId,
      });

      if (response && response.status === 200) {
        setSubmitted({
          success: true,
          message: 'Success',
        });
      } else {
        setSubmitted({
          success: false,
          message: response?.data?.message || 'Error',
        });
      }
    }

    setSubmitPressed(true);
  };

  if (!apiKeys.length) return null;

  return (
    <>
      <h2>Shopify</h2>

      <div className={cn(commonStyles.panel, styles.shopify)}>
        <div>
          <div className={styles.container}>
            <ValidatorWrapper ref={validator}>
              <p className={styles.label}>Shopify Api Key</p>
              <ValidatorField value={shopifyApiKey} rules={rules.notEmpty}>
                {({ isValid }) => (
                  <>
                    <input
                      className={cn(commonStyles.textField, styles.input, {
                        [styles.error]: !isValid && submitPressed,
                      })}
                      type="text"
                      value={shopifyApiKey}
                      onChange={(e) => setShopifyApiKey(e.target.value)}
                    />
                  </>
                )}
              </ValidatorField>

              <p className={styles.label}>Shopify Admin API access token</p>
              <ValidatorField
                value={shopifyApiAccessToken}
                rules={rules.notEmpty}
              >
                {({ isValid }) => (
                  <>
                    <input
                      className={cn(commonStyles.textField, styles.input, {
                        [styles.error]: !isValid && submitPressed,
                      })}
                      type="text"
                      value={shopifyApiAccessToken}
                      onChange={(e) => setShopifyApiAccessToken(e.target.value)}
                    />
                  </>
                )}
              </ValidatorField>

              <p className={styles.label}>Shop domain</p>
              <ValidatorField value={shopDomain} rules={rules.notEmpty}>
                {({ isValid }) => (
                  <>
                    <input
                      className={cn(commonStyles.textField, styles.input, {
                        [styles.error]: !isValid && submitPressed,
                      })}
                      type="text"
                      value={shopDomain}
                      onChange={handleDomainChange}
                    />
                  </>
                )}
              </ValidatorField>

              <p className={styles.label}>Shop name</p>
              <ValidatorField value={shopName} rules={rules.notEmpty}>
                {({ isValid }) => (
                  <>
                    <input
                      className={cn(commonStyles.textField, styles.input, {
                        [styles.error]: !isValid && submitPressed,
                      })}
                      type="text"
                      value={shopName}
                      onChange={(e) => setShopName(e.target.value)}
                    />
                  </>
                )}
              </ValidatorField>
            </ValidatorWrapper>

            <p className={styles.label}>NOWPayments Api key</p>

            <div className={styles.select}>
              <select
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
                disabled={selectDisabled}
              >
                {apiKeys.length &&
                  apiKeys.map((key) => (
                    <option key={key.api_key} value={key.api_key}>
                      {key.api_key}
                    </option>
                  ))}
              </select>
            </div>

            <div
              className={cn(styles.buttonBox, {
                [styles.column]: !submitted.success,
              })}
            >
              <button className={styles.button} onClick={handleSubmit}>
                Connect to Shopify
              </button>

              {submitted.message && (
                <span
                  className={cn(styles.successMessage, {
                    [styles.errorMessage]: !submitted.success,
                  })}
                >
                  {submitted.success ? '✔' : '✖'} {submitted.message}
                </span>
              )}
            </div>

            <p className={styles.text}>
              In Shopify please add a payment method at
              <span className={styles.blue}>
                {' '}
                Settings `&gt; Payments `&gt; Manual Payment Methods
              </span>{' '}
              with the name
              <span className={styles.pink}>
                {' '}
                Pay in crypto with NOWPayments
              </span>
            </p>

            <p className={styles.text}>
              In Shopify please paste following script at
              <span className={styles.blue}>
                {' '}
                Settings `&gt; Checkout `&gt; Additional Scripts
              </span>
            </p>
          </div>
        </div>

        <div>
          <div className={styles.container}>
            <p className={styles.mainLabel}>Additional Script</p>
            <p className={styles.code}>
              {`<script id="np_popup" src="${config.front_domain}/js/shopify.js" data-api="${apiKey}"></script>`}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopifySettings;
