import React from 'react';
import cn from 'classnames';
import classes from './styles.module.scss';
import { InnerNavigationTabsType } from './types';
import { useMediaQuery } from '../../hooks/use-media-query';

const DEFAULT_TAB_HEIGHT = 48;

export const InnerNavigationTabs: React.FC<InnerNavigationTabsType> = (props) => {
  const {
    className,
    classNameList,
    items,
    onClick,
    activeHash,
  } = props;
  const isDesktop = useMediaQuery('(min-width: 1200px)');
  const componentRef = React.useRef<HTMLDivElement | null>(null);

  const [topCoordinate, setTopCoordinate] = React.useState(0);
  const [height, setHeight] = React.useState(DEFAULT_TAB_HEIGHT);

  const handleClick = (hash: string) => () => {
    onClick(hash);
  };
  const handleKeyDown = (hash: string) => (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      onClick(hash);
    }
  };

  React.useEffect(() => {
    const navigation = componentRef.current;
    if (!isDesktop || !navigation) {
      return;
    }
    const activeNavigationTab = navigation.querySelector('.styles_innerNavigationLinkActive__bayxv');

    if (!activeNavigationTab) {
      return;
    }
    const navigationTopCoordinate = navigation.getBoundingClientRect().top;
    const activeNavigationTabTopCoordinate = activeNavigationTab.getBoundingClientRect().top;
    const activeTopCoordinate = activeNavigationTabTopCoordinate - navigationTopCoordinate;
    const activeTopCoordinateNormalized = !Number.isNaN(activeTopCoordinate)
      ? activeTopCoordinate
      : 0;
    setTopCoordinate(activeTopCoordinateNormalized);
    setHeight(activeNavigationTab.clientHeight || DEFAULT_TAB_HEIGHT);
  }, [isDesktop, activeHash]);

  return (
    <div className={cn(classes.innerNavigation, className)}>
      <div
        className={cn(classes.innerNavigationList, classNameList)}
        ref={componentRef}
      >
        <div
          className={classes.innerNavigationListLine}
          style={{ height: `${height}px`, top: `${topCoordinate}px` }}
        />
        {items.map((item) => (
          <div
            key={item?.id}
            className={cn([
              classes.innerNavigationLink,
              activeHash === item.hash && classes.innerNavigationLinkActive,
            ])}
            onClick={handleClick(item.hash)}
            onKeyDown={handleKeyDown(item.hash)}
            role="button"
            tabIndex={0}
          >
            {item.Icon}
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
};
