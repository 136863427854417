import React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';
import {
  CheckWalletsWhitelistResponse,
  WalletWhitelistData,
} from '../../helpers/types';

const mapItemFn = (data: WalletWhitelistData) => ({
  exists: data?.exists ?? false,
  enabled: data?.enabled ?? false,
  whitelist: data?.whitelist ?? null,
});
const mapFn = (data: CheckWalletsWhitelistResponse) => ({
  success: data?.success ?? false,
  result: (data?.result ?? []).map(mapItemFn),
});

export const useCheckWalletsWhitelist = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (whitelistData: Array<{ currency: string, address: string }>) => {
    const [cancelToken] = getRequestSource();
    const requestPromise = client.post(
      '/whitelist/status',
      { whitelistData },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [client, getRequestSource]);
};
