import { hasElementsInArray } from './has-elements-in-array';

/**
 * @param {Array} ids
 * @returns {*|String}
 */
export const getPaymentExtraIdsString = (ids) => {
  if (!hasElementsInArray(ids)) {
    return ids;
  }

  return ids.length > 1
    ? ids.join(', ')
    : ids.join('');
};
