import React from 'react';
import classes from './styles.module.scss';
import { DashboardOurProducts } from '../dashboard-our-products';
import { DashboardGetStarted } from '../dashboard-get-started';
import { DashboardSettings } from '../dashboard-settings';

export const DashboardOnboarding = () => (
  <div className={classes.dashboardOnboarding}>
    <p className={classes.dashboardOnboardingDescription}>
      In this section, you will be able to find your account&apos;s statistics.
      Now get ready to start with your integration!
    </p>
    <div className={classes.dashboardOptions}>
      <DashboardGetStarted />
      <DashboardSettings />
    </div>
    <DashboardOurProducts />
  </div>
);
