import React from 'react';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import { LoaderIcon } from '../../../icons/loader-icon';
import { EmptySearch } from '../../../empty-search';
import { PayoutWalletsTableRowMemo } from '../payout-wallets-table-row';
import {
  ExtendedWallet,
  PopupData,
} from '../../types';
import { NewPagination } from '../../../shared/new-pagination';
import {
  ELEMENTS_PER_PAGE,
  POPUPS_OBJECT,
} from '../../helpers';
import ToolTip from '../../../tool-tip';
import { PayoutWalletsOptions } from '../payout-wallets-options';
import { Button } from '../../../ui/button';
import classes from './styles.module.scss';

type PayoutWalletsTableProps = {
  wallets: Array<ExtendedWallet>,
  walletsWhitelist: Array<ExtendedWallet>,
  isFetched: boolean,
  isFetching: boolean,
  error: string | null,
  hasSearchValue: boolean,
  page: number,
  onPage: (value: number) => void,
  walletsFilteredLength: number,
  onPopupOpen: (type: PopupData) => void,
  onOptionsClick: (wallet: ExtendedWallet) => void,
  onOptionsReset: () => void,
  activeWallet: ExtendedWallet | null,
  onSetAsMain: () => Promise<void>
  isWhitelistHidden: boolean,
};

export const PayoutWalletsTable: React.FC<PayoutWalletsTableProps> = (props) => {
  const {
    wallets,
    walletsWhitelist,
    isFetched,
    isFetching,
    error,
    hasSearchValue,
    page,
    onPage,
    walletsFilteredLength,
    onPopupOpen,
    onOptionsClick,
    onOptionsReset,
    activeWallet,
    onSetAsMain,
    isWhitelistHidden,
  } = props;

  const isDesktop = useMediaQuery('(min-width: 992px)');
  const loaderShown = !isFetched && isFetching;
  const errorShown = !isFetching && error;
  const dataShown = !loaderShown && !errorShown;
  const emptyBoxShown = isFetched && !errorShown && !walletsFilteredLength;
  const isPaginationShow = walletsFilteredLength > ELEMENTS_PER_PAGE;

  const handleAddWalletClick = () => {
    onPopupOpen(POPUPS_OBJECT.add);
  };

  return (
    <>
      <div className={classes.payoutWalletsTable}>
        <div className={classes.payoutWalletsTableHeader}>
          {isDesktop ? (
            <>
              <div className={classes.payoutWalletsTableTd}>Currency</div>
              <div className={classes.payoutWalletsTableTd}>Address</div>
            </>
          ) : (
            <div className={classes.payoutWalletsTableTd}>Currency / Address</div>
          )}
          <div className={classes.payoutWalletsTableTd}>Actions</div>
        </div>
        <div className={classes.payoutWalletsTableBody}>
          {loaderShown && (
            <div className={classes.payoutWalletsTableBodyLoader}>
              <LoaderIcon size={25} />
            </div>
          )}
          {errorShown && (
            <div className={classes.payoutWalletsTableBodyError}>
              {error}
            </div>
          )}
          {emptyBoxShown && (
            <EmptySearch imagePath="/images/empty-search.svg">
              {!hasSearchValue ? (
                <>
                  <p>No addresses added to payout wallets.</p>
                  <Button
                    className={classes.payoutWalletsTableAdd}
                    onClick={handleAddWalletClick}
                  >
                    Add first wallet
                  </Button>
                </>
              ) : 'Sorry, no payout wallets were found'}
            </EmptySearch>
          )}
          {dataShown && wallets.map((wallet) => (
            <PayoutWalletsTableRowMemo
              key={wallet.id}
              wallet={wallet}
              isActive={wallet.id === activeWallet?.id}
              onClick={onOptionsClick}
            />
          ))}
        </div>
        <ToolTip
          id="payout-wallets-tooltip"
          className={classes.payoutWalletsTableTooltip}
          place="bottom-end"
          offset={4}
          openOnClick
          clickable
          render={() => (
            <PayoutWalletsOptions
              activeWallet={activeWallet}
              walletsWhitelist={walletsWhitelist}
              onClick={onPopupOpen}
              onSetAsMain={onSetAsMain}
              isWhitelistHidden={isWhitelistHidden}
            />
          )}
          afterHide={onOptionsReset}
        />
        <ToolTip
          id="main-wallet-tooltip"
          className={classes.payoutWalletsTableTooltipMain}
          place="top-start"
          offset={4}
          noArrow
          render={() => (
            <div>
              The payment will be sent automatically to this wallet if:
              <ul>
                <li>A customer pays in a currency without an address in your Payout wallets</li>
                <li>Fees optimization is off</li>
              </ul>
            </div>
          )}
        />
      </div>
      {isPaginationShow && (
        <NewPagination
          className={classes.payoutWalletsTablePagination}
          currentPage={page}
          countItems={walletsFilteredLength}
          itemsPerPage={ELEMENTS_PER_PAGE}
          onChangePage={onPage}
        />
      )}
    </>
  );
};
