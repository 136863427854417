import { safeToUpperCase } from './safe-to-upper-case';

/**
 * @param {String} str
 * @param {String} defaultTicker
 * @param {Object} currenciesObject
 * @param {String} symbol
 * @returns {string}
 */
export const normalizeAmountWithTickerString = (
  str = '',
  currenciesObject = {},
  defaultTicker = null,
  symbol = '-',
) => {
  if (typeof str !== 'string') {
    return '';
  }

  const strSplitted = str.split(' ');
  const amount = strSplitted[0] || '';
  const ticker = !defaultTicker ? strSplitted[1] : defaultTicker;
  const tickerUpperCased = safeToUpperCase(ticker);
  const coinFound = currenciesObject?.[tickerUpperCased] || {};
  const currentTicker = coinFound?.ticker ?? tickerUpperCased;

  return amount ? `${amount} ${currentTicker}` : symbol;
};
