import React from "react";
import styles from "./styles.module.scss";

function AffiliateStatistic(props) {
  return (
    <div className={styles.affiliateStatistic}>
      <h2>Statistics</h2>
      <div className={styles.rowBlock}>
        <div className={styles.block}>
          <p className={styles.title}>Total Number of Referrals</p>
          <p className={styles.value}>{props.referralCount}</p>
        </div>

        <div className={styles.block}>
          <p className={styles.title}>Referrals this week</p>
          <p className={styles.value}>{props.referralCountLastWeek}</p>
        </div>

        <div className={styles.block}>
          <p className={styles.estimateTitle}>Estimated profit</p>
          {props.referralRewards && props.referralRewards.length ? (
            <div className={styles.scrollBlock}>
              {props.referralRewards.map((reward, i) => (
                <div key={`reward${i}`} className={styles.estimateValue}>
                  <img src={reward.logoUrl} alt=""></img>
                  <div>{`${reward.currency}: ${reward.reward}`}</div>
                </div>
              ))}
            </div>
          ) : (
            <p className={styles.value}>0</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default AffiliateStatistic;
