import { isCurrencyNetworkShow } from './is-currency-network-show';

/**
 * @param {String} network
 * @param {String} ticker
 * @returns {String|null}
 */
export const getCurrentNetworkForShow = (network = null, ticker = '') => {
  return isCurrencyNetworkShow(network, ticker) ? network : null;
};
