import React from 'react';
import { safeToLowerCase } from '../../../helpers/safe-to-lower-case';
import { ELEMENTS_PER_PAGE } from '../helpers';
import { ExtendedWallet } from '../types';

export const useFilteredWalletsData = (wallets: Array<ExtendedWallet>) => {
  const [searchValue, setSearchValue] = React.useState('');
  const [page, setPage] = React.useState(1);

  const walletsFiltered = React.useMemo(() => {
    if (!searchValue) return wallets;

    return wallets.filter((wallet: ExtendedWallet) => {
      const isTickerMatches = safeToLowerCase(wallet.baseCurrency).includes(safeToLowerCase(searchValue));
      const isAddressMatches = safeToLowerCase(wallet.baseAddress).includes(safeToLowerCase(searchValue));
      return isTickerMatches || isAddressMatches;
    });
  }, [searchValue, wallets]);
  const walletsFilteredLength = React.useMemo(() => walletsFiltered?.length ?? 0, [walletsFiltered]);
  const walletsSliced = React.useMemo(() => {
    if (walletsFilteredLength <= ELEMENTS_PER_PAGE) {
      return walletsFiltered;
    }

    const startIndex = (page - 1) * ELEMENTS_PER_PAGE;
    const endIndex = page * ELEMENTS_PER_PAGE;

    return walletsFiltered.slice(startIndex, endIndex);
  }, [page, walletsFiltered, walletsFilteredLength]);

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
  };
  const handlePageChange = (value: number) => {
    setPage(value);
  };

  return {
    currentWallets: walletsSliced,
    walletsFilteredLength,
    handleSearchChange,
    handlePageChange,
    page,
    searchValue,
  };
};
