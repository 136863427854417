import React from 'react';
import cn from 'classnames';
import { Portal } from '../../../portal';
import { Popup } from '../../../popup';
import { Button } from '../../../ui/button';
import classes from './styles.module.scss';

type WhitelistSuccessPopupProps = {
  className?: null | string,
  open: boolean,
  onClose: () => void,
  title?: string,
  description?: string,
  withOkButton?: boolean,
  imagePath?: string,
};

export const WhitelistSuccessPopup: React.FC<WhitelistSuccessPopupProps> = (props) => {
  const {
    className,
    open,
    onClose,
    title,
    description,
    withOkButton,
    imagePath,
  } = props;

  return (
    <Portal>
      <Popup
        classNameContent={cn(classes.whitelistSuccessPopup, className)}
        open={open}
        onClose={onClose}
        withCloseButton
        mobileFullView
        title={title}
        description={description}
        Icon={(
          <img
            src={imagePath}
            alt="Success"
            decoding="async"
            loading="lazy"
          />
        )}
      >
        {withOkButton && (
          <Button onClick={onClose}>
            OK
          </Button>
        )}
      </Popup>
    </Portal>
  );
};

WhitelistSuccessPopup.defaultProps = {
  className: null,
  title: 'Success!',
  description: 'Your addresses will be added to the whitelist within 1 business day.',
  withOkButton: false,
  imagePath: '/images/whitelist-success.svg',
};
