import validator from 'is-my-ip-valid';

const validateV4 = validator({ version: 4 });
const validateV6 = validator({ version: 6 });

/**
 * @param {String} address
 * @returns {boolean}
 */
export const isValidIPAddress = (address) => {
  if (typeof address !== 'string') {
    return false;
  }

  const isV4FormatAddress = validateV4(address);
  const isV6FormatAddress = validateV6(address);

  return isV4FormatAddress || isV6FormatAddress;
};
