import * as React from 'react';
import { useHistory } from 'react-router-dom';

/**
 * @param {String} path
 * @param {Boolean} isReplace
 */
export const useHistoryReplace = (path, isReplace = false) => {
  const history = useHistory();

  React.useEffect(() => {
    if (isReplace) {
      history.replace(path);
    }
  }, [isReplace]);
};
