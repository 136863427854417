import React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';

export const useProfileUpdateShownOnboard = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (options) => {
    const isShowOnboard = options?.isShowOnboard ?? false;
    const [cancelToken] = getRequestSource();
    const requestPromise = client.put(
      '/profile/update-show-onboard',
      { isShowOnboard },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      (data) => data,
    );
  }, [getRequestSource]);
};
