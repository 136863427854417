import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Popup } from '../../../popup';
import { ManageCustomersForm } from '../manage-customers-form';
import classes from './styles.module.scss';

export const PopupManageCustomers = (props) => {
  const {
    className,
    open,
    onClose,
    onSubmit,
    apiErrorMessage,
    fetching,
    subPartners,
    subPartnersFetching,
    subPartnersFetched,
    onSubPartners,
  } = props;

  return (
    <Popup
      classNameContent={cn(classes.popup, className)}
      className={classes.popupWrapper}
      title="Manage customers"
      open={open}
      onClose={onClose}
      withCloseButton
      mobileFullView
      line
    >
      <ManageCustomersForm
        className={classes.content}
        onSubmit={onSubmit}
        apiErrorMessage={apiErrorMessage}
        fetching={fetching}
        subPartners={subPartners}
        subPartnersFetching={subPartnersFetching}
        subPartnersFetched={subPartnersFetched}
        onSubPartners={onSubPartners}
      />
    </Popup>
  );
};

PopupManageCustomers.defaultProps = {
  className: null,
  open: null,
  onClose: null,
  onSubmit: null,
  apiErrorMessage: null,
  fetching: false,
  subPartners: null,
  subPartnersFetching: false,
  subPartnersFetched: false,
  onSubPartners: null,
};

PopupManageCustomers.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  apiErrorMessage: PropTypes.string,
  fetching: PropTypes.bool,
  subPartners: PropTypes.arrayOf(PropTypes.object),
  subPartnersFetching: PropTypes.bool,
  subPartnersFetched: PropTypes.bool,
  onSubPartners: PropTypes.func,
};
