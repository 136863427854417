import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNotification } from '../api/modules/account/use-notification';
import { setNotificationMessage } from '../store/settings/reducer';
import { notificationMessageSelector } from '../store/settings/selectors';

const NOTIFICATION_NAME = 'notification_message_time';
const MINUTES = 5;

export const useNotificationData = () => {
  const dispatch = useDispatch();
  const fetchNotification = useNotification();
  const notificationMessage = useSelector(notificationMessageSelector());

  const setDateTimeToSessionStorage = React.useCallback(async (number = 1, isMinutes = false) => {
    const nextDate = new Date();
    if (isMinutes) {
      nextDate.setMinutes(nextDate.getMinutes() + number);
    } else {
      nextDate.setDate(nextDate.getDate() + number);
    }
    dispatch(setNotificationMessage(null));
    sessionStorage.setItem(NOTIFICATION_NAME, `${nextDate.getTime()}`);
  }, [dispatch, setNotificationMessage]);

  const setNotificationData = React.useCallback(async () => {
    const { data, status } = await fetchNotification();

    if (status === 200) {
      if (data?.text) {
        dispatch(setNotificationMessage(data?.text ?? null));
      } else {
        void setDateTimeToSessionStorage(MINUTES, true);
      }
    }
  }, [fetchNotification, dispatch, setNotificationMessage]);

  const checkNotificationMessage = React.useCallback(async () => {
    const notificationDateTimeFromSessionStorage = sessionStorage.getItem(NOTIFICATION_NAME);
    let expiredDate = true;

    if (notificationDateTimeFromSessionStorage) {
      const notificationDateTimeNumber = Number(notificationDateTimeFromSessionStorage);
      const dateTimeNow = new Date().getTime();

      if (dateTimeNow < notificationDateTimeNumber) {
        expiredDate = false;
      } else {
        sessionStorage.removeItem(NOTIFICATION_NAME);
      }
    }

    if (expiredDate) {
      void setNotificationData();
    }
  }, [setNotificationData]);

  const handleNotificationMessageClose = React.useCallback(() => {
    void setDateTimeToSessionStorage();
  }, [setDateTimeToSessionStorage]);

  return {
    notificationMessage,
    checkNotificationMessage,
    handleNotificationMessageClose,
  };
};
