import React from 'react';
import PropTypes from 'prop-types';
import { safeToUpperCase } from '../../../../helpers/safe-to-upper-case';
import { formatDate } from '../../../../helpers/format-date';
import { isFunction } from '../../../../helpers/is-function';
import { DATE_PATTERN } from '../../../../constants/app-constants';

export const BillingPlansTableRow = (props) => {
  const {
    className,
    data,
    currenciesObject,
    onClick,
    isDesktop,
  } = props;

  const id = data?.id ?? null;
  const title = data?.title ?? '-';
  const amount = data?.amount ?? null;
  const ticker = data?.currency ?? '';
  const tickerUpperCased = safeToUpperCase(ticker);
  const currentTicker = currenciesObject[tickerUpperCased]?.ticker ?? tickerUpperCased;
  const amountWithTicker = amount ? `${amount} ${currentTicker}` : '-';
  const period = data?.intervalDay ?? 0;
  const currentPeriodName = period === 1 ? 'Day' : 'Days';
  const periodNormalized = `${period} ${currentPeriodName}`;
  const createdAt = data?.createdAt ?? '';
  const createdDateFormatted = formatDate(createdAt, DATE_PATTERN);
  const createdDateNormalized = createdDateFormatted || '-';

  const handleRowClick = () => {
    if (isFunction(onClick)) {
      onClick(id);
    }
  };

  return (
    <tr
      className={className}
      onClick={handleRowClick}
    >
      <td>
        <div>
          {id}
        </div>
        {!isDesktop && (
          <div data-color="black" data-margin="top" data-overflow="hidden">
            {title}
          </div>
        )}
      </td>
      {isDesktop && (
        <td data-color="black" data-overflow="hidden">
          {title}
        </td>
      )}
      <td>
        <div data-color="black">
          {amountWithTicker}
        </div>
        {!isDesktop && (
          <div data-color="black" data-margin="top">
            {periodNormalized}
          </div>
        )}
      </td>
      {isDesktop && (
        <td data-color="black">
          {periodNormalized}
        </td>
      )}
      <td>
        {createdDateNormalized}
      </td>
    </tr>
  );
};

BillingPlansTableRow.defaultProps = {
  className: null,
  data: null,
  currenciesObject: {},
  onClick: null,
  isDesktop: false,
};

BillingPlansTableRow.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  currenciesObject: PropTypes.object,
  onClick: PropTypes.func,
  isDesktop: PropTypes.bool,
};
