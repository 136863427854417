import { hasElementsInArray } from './has-elements-in-array';

/**
 * @param {Array<Object>} keys
 * @returns {boolean}
 */
export const hasActiveApiKey = (keys = []) => {
  const hasKeysValue = hasElementsInArray(keys);

  return hasKeysValue ? keys.some((key) => key.isActive) : false;
};
