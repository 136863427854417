export const TRACK_EVENT_NAMES = {
  OPEN_MODAL_INVOICES_CREATE: 'лк___invoices___create',
  INVOICE_CREATED: 'лк___invoices___created',
  INVOICE_CREATED_WITH_FIXED_RATE: 'лк___invoices___created___fixed_rate',
  INVOICE_CREATED_ALL_OPTIONS: 'лк___invoices___created___all_options',
  MASS_PAYOUTS_CSV: 'лк___mass___create_csv',
  MASS_PAYOUTS_CSV_UPLOADED: 'лк___mass___create_csv___uploaded',
  MASS_PAYOUTS_MANUALLY: 'лк___mass___create_manually',
  MASS_PAYOUTS_MANUALLY_CREATED: 'лк___mass___create_manually___confirm',
  MASS_PAYOUTS_2FA_SUCCESS: 'лк___mass___create___success',
  CUSTOMERS_ADD: 'лк___customers___add',
  CUSTOMERS_ADDED: 'лк___customers___added',
  CUSTOMERS_CREATE_PLAN: 'лк___customers___create_plan',
  CUSTOMERS_CREATED_PLAN: 'лк___customers___created_plan',
  CUSTODY_CONVERT: 'лк___custody___convert',
  CUSTODY_CONVERTED: 'лк___custody___converted',
  CUSTODY_TOP_UP: 'лк___custody___topup',
  CUSTODY_TOP_UP_SUCCESS: 'лк___custody___topup_success',
  CUSTODY_PDA_TOP_UP_SUCCESS: 'custody___pda___topup___success',
  CUSTODY_REQUEST_PAYOUT: 'лк___custody___payout',
  CUSTODY_REQUEST_PAYOUT_SUCCESS: 'лк___custody___payout_success',
  SIGN_UP_REGISTERED: 'sign_up_registered',
  SIGN_UP_EMAIL_CONFIRMED: 'sign_up_email_confirmed',
  SIGN_UP_COMPLETE: 'sign_up_complete',
};
