import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isPartnerCustodySelector, partnerFetchedSelector } from '../store/partner/selectors';

/**
 * @param {String} path
 * @param {Boolean} replace
 */
export const usePartnerCustodyCheck = (path, replace = false) => {
  const history = useHistory();
  const partnerFetched = useSelector(partnerFetchedSelector());
  const isPartnerCustody = useSelector(isPartnerCustodySelector());

  React.useEffect(() => {
    const isRedirect = partnerFetched && !isPartnerCustody;
    if (isRedirect && !replace) {
      history.push(path);
    }
    if (isRedirect && replace) {
      history.replace(path);
    }
  }, [partnerFetched, isPartnerCustody]);
};
