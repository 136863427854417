import React from 'react';
import { useSelector } from 'react-redux';
import { useWhitelistedIpsInfo } from '../../../api/modules/account/use-whitelisted-ips-info';
import {
  isPartnerCustodySelector,
  partnerFetchedSelector,
} from '../../../store/partner/selectors';
import { ResponseType } from '../../../default-types';
import { checkErrorCode } from '../../../helpers/check-error-code';
import { getSafeErrorMessageText } from '../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../helpers/console-error-message';

export const useInitState = () => {
  const fetchWhitelistedIpsInfo = useWhitelistedIpsInfo();
  const partnerFetched = useSelector(partnerFetchedSelector());
  const isPartnerCustody = useSelector(isPartnerCustodySelector());

  const [ips, setIps] = React.useState<Array<string>>([]);
  const [isWhitelistActive, setIsWhitelistActive] = React.useState(false);
  const [isWhitelistFetching, setIsWhitelistFetching] = React.useState(false);
  const [isWhitelistFetched, setIsWhitelistFetched] = React.useState(false);
  const [whitelistError, setWhitelistError] = React.useState<string | null>(null);

  const reFetchWhitelistIps = React.useCallback(async () => {
    setWhitelistError(null);
    const { data, status, errorMessage }: ResponseType = await fetchWhitelistedIpsInfo();

    if (status === 200) {
      setIsWhitelistActive(data.checkWhitelistedIps);
      setIps(data.whitelistedIps);
    } else {
      checkErrorCode(data?.errorData?.code);
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/whitelisted-ips-info');
      setWhitelistError(errorDataMessage);
    }
  }, []);
  const initState = React.useCallback(async () => {
    setIsWhitelistFetching(true);
    setWhitelistError(null);
    const { data, status, errorMessage }: ResponseType = await fetchWhitelistedIpsInfo();

    if (status === 200) {
      setIsWhitelistActive(data.checkWhitelistedIps);
      setIps(data.whitelistedIps);
      setIsWhitelistFetching(false);
      setIsWhitelistFetched(true);
    } else {
      checkErrorCode(data?.errorData?.code);
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/whitelisted-ips-info');
      setIsWhitelistFetching(false);
      setIsWhitelistFetched(true);
      setWhitelistError(errorDataMessage);
    }
  }, []);

  React.useEffect(() => {
    if (partnerFetched && isPartnerCustody) {
      initState();
    }
  }, [partnerFetched, isPartnerCustody]);

  return {
    ips,
    isWhitelistActive,
    isWhitelistFetching,
    isWhitelistFetched,
    whitelistError,
    reFetchWhitelistIps,
  };
};
