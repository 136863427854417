import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { SectionBalance } from '../section-balance';
import { SectionHistory } from '../../../section-history';
import { InputSearch } from '../../../ui/input-search';
import { MenuFilterIcon } from '../../../icons/menu-filter-icon';
import { HistoryFilterMemo } from '../history-filter';
import { HistoryTableMemo } from '../../../history-table';
import {
  getFilteredDataWithFilter,
  getFilteredDataWithSearch,
  getPaymentInfoItems,
  createBodyForDocument,
  getConversionFilteredDataWithSearch,
  getConversionFilteredDataWithFilter,
  createBodyForConversionDocument,
  getConversionPaymentInfoItems,
  getCurrentExportName,
  getBalanceWithFiatAmount,
  getPayoutError,
  PAYOUTS_HISTORY_NAME,
  CONVERSION_HISTORY_NAME,
  FIAT_HISTORY_NAME,
  getFilteredFiatDataWithSearch,
  getFiatPaymentInfoItems,
  HISTORY_OPTIONS_OBJECT,
} from '../../custody-page-helpers';
import { CustodyHistoryItemMemo } from '../custody-history-item';
import { NewPagination } from '../../../shared/new-pagination';
import { TopUpBalancePopup } from '../top-up-balance-popup';
import { InvoiceResultPopup } from '../invoice-result-popup';
import { ConversionPopup } from '../conversion-popup';
import { PaymentInfoPopup } from '../payment-info-popup';
import { ExportPopup } from '../../../shared/export-popup';
import { New2faPopup } from '../../../new-2fa-popup';
import { safeToLowerCase } from '../../../../helpers/safe-to-lower-case';
import { useConversionEstimate } from '../../../../api/modules/account/use-conversion-estimate';
import { useCreateConversionPayment } from '../../../../api/modules/account/use-create-conversion-payment';
import { useMinAmount } from '../../../../api/modules/account/use-min-amount';
import { debounce } from '../../../../helpers/utils';
import { hasElementsInArray } from '../../../../helpers/has-elements-in-array';
import { saveToPdf } from '../../../../helpers/save-to-pdf';
import { CONVERSION_HISTORY_HEADERS, withdrawalHeaders } from '../../../../constants/app-constants';
import { saveToDifferentFileFormat } from '../../../../helpers/save-to-different-file-format';
import { isFunction } from '../../../../helpers/is-function';
import { isNeedStatusUpdate } from '../../../../helpers/is-need-status-update';
import { usePayment } from '../../../../api/modules/account/use-payment';
import { useOutcomeWalletAddress } from '../../../../api/modules/account/use-outcome-wallet-address';
import { useRequestPayout } from '../../../../api/modules/account/use-request-payout';
import { useCreateStoreOutcomeWallet } from '../../../../api/modules/account/use-create-store-outcome-wallet';
import { useTopUpEstimate } from '../../../../api/modules/account/use-top-up-estimate';
import { useCreateInvoice } from '../../../../api/modules/account/use-create-invoice';
import { useRequestFiatPayout } from '../../../../api/modules/account/use-request-fiat-payout';
import { useFiatEstimate } from "../../../../api/modules/account/use-fiat-estimate";
import { getSafeErrorMessageText } from '../../../../helpers/get-safe-error-message-text';
import { ConversionHistoryFilterMemo } from '../conversion-history-filter';
import { ConversionCreatedPopup } from '../conversion-created-popup';
import { RequestPayoutPopup } from '../request-payout-popup';
import { TableTabs } from '../../../shared/table-tabs';
import {
  marketInfoFetchingSelector,
  marketInfoFromCurrencySelector,
  marketInfoRatesSelector,
} from '../../../../store/market-info/selectors';
import { partnerSelector } from '../../../../store/partner/selectors';
import { trackEvent } from '../../../../helpers/utils/track-event';
import { consoleErrorMessage } from '../../../../helpers/console-error-message';
import { TRACK_EVENT_NAMES } from '../../../../constants/track-event-names';
import { OFF_RAMP_PROVIDERS } from '../../../../constants/providers-constants';
import { useFiatOffRampCurrencies } from '../../../../hooks/use-fiat-off-ramp-currencies';
import { useBankAccounts } from '../../../../hooks/use-bank-accounts';
import { useProviderStatuses } from '../../../../hooks/use-providers-statuses';
import { setWallets } from '../../../../store/payment-settings/reducer';
import { CustomInformationLink } from '../../../shared/custom-information-link';
import {
  depositAddressesErrorSelector,
  paymentSettingsFetchingSelector,
} from '../../../../store/payment-settings/selectors';
import { useFilteredBalancesData } from '../../hooks';
import { useGetDepositAddressByNetwork } from '../../../../hooks/use-get-deposit-address-by-network';
import classes from './styles.module.scss';

const ELEMENTS_PER_PAGE = 15;
const PAYMENT_INFO_UPDATING_TIME = 4000;
const MIN_AMOUNT_ERROR_TEXT = 'Amount is below minimum for this coin';
const FIAT_ESTIMATE_AMOUNT_ERROR_TEXT = 'Please specify the correct withdrawal amount';
const VALID_DEPOSIT_ADDRESS_ERROR = 'Deposits addresses: Request failed with status code 500';

export const CustodyMain = (props) => {
  const {
    availableCurrencies,
    partnerCurrencies,
    currenciesObject,
    balance,
    withdrawals,
    conversionHistory,
    fiatHistory,
    baseCurrency,
    dataFetching,
    onUpdateWithdrawals,
    onUpdateConversionHistory,
    onUpdateFiatHistory,
  } = props;

  const marketInfoRates = useSelector(marketInfoRatesSelector());
  const marketInfoFromCurrency = useSelector(marketInfoFromCurrencySelector());
  const marketInfoFetching = useSelector(marketInfoFetchingSelector());
  const partner = useSelector(partnerSelector());
  const depositAddressesError = useSelector(depositAddressesErrorSelector());
  const paymentSettingsFetching = useSelector(paymentSettingsFetchingSelector());
  const fetchConversionEstimate = useConversionEstimate();
  const fetchCreateConversionPayment = useCreateConversionPayment();
  const fetchMinAmount = useMinAmount();
  const fetchTopUpEstimate = useTopUpEstimate();
  const fetchCreateInvoice = useCreateInvoice();
  const fetchPayment = usePayment();
  const fetchOutcomeWalletAddress = useOutcomeWalletAddress();
  const fetchRequestPayout = useRequestPayout();
  const fetchCreateStoreOutcomeWallet = useCreateStoreOutcomeWallet();
  const fetchRequestFiatPayout = useRequestFiatPayout();
  const fetchFiatEstimate = useFiatEstimate();
  const dispatch = useDispatch();

  const [page, setPage] = React.useState(1);
  const [searchValue, setSearchValue] = React.useState('');
  const [searchFormData, setSearchFormData] = React.useState(null);
  const [activePaymentInfo, setActivePaymentInfo] = React.useState(null);
  const [conversionEstimateAmount, setConversionEstimateAmount] = React.useState(null);
  const [defaultTicker, setDefaultTicker] = React.useState(null);
  const [estimate, setEstimate] = React.useState(null);
  const [fiatEstimate, setFiatEstimate] = React.useState(null);
  const [nextTimerId, setNextTimerId] = React.useState(null);
  const [minAmount, setMinAmount] = React.useState(null);
  const [invoiceCreatedData, setInvoiceCreatedData] = React.useState(null);
  const [paymentCreatedData, setPaymentCreatedData] = React.useState(null);
  const [requestPayoutCurrency, setRequestPayoutCurrency] = React.useState(null);
  const [payoutOutcomeAddress, setPayoutOutcomeAddress] = React.useState(null);
  const [requestPayoutWithdrawalId, setRequestPayoutWithdrawalId] = React.useState(null);
  const [payoutCreatedData, setPayoutCreatedData] = React.useState(null);
  const [depositCurrency, setDepositCurrency] = React.useState(null);

  const [isFilterOpen, setIsFilterOpen] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);
  const [isEstimateFetching, setIsEstimateFetching] = React.useState(false);
  const [isFiatEstimateFetching, setIsFiatEstimateFetching] = React.useState(false);
  const [isTopUpPopupShow, setIsTopUpPopupShow] = React.useState(false);
  const [isConversionFormShow, setIsConversionFormShow] = React.useState(false);
  const [isPayoutCreatedShow, setIsPayoutCreatedShow] = React.useState(false);
  const [isConversionCreatedPopupShow, setIsConversionCreatedPopupShow] = React.useState(false);
  const [isExportPopupShow, setIsExportPopupShow] = React.useState(false);
  const [isInfoPopupShow, setIsInfoPopupShow] = React.useState(false);
  const [is2faPopupShow, setIs2faPopupShow] = React.useState(false);
  const [isInvoiceResultPopupShow, setIsInvoiceResultPopupShow] = React.useState(false);
  const [isRequestPayoutPopupShow, setIsRequestPayoutPopupShow] = React.useState(false);
  const [isRequestPayoutFetching, setIsRequestPayoutFetching] = React.useState(false);
  const [isAddWalletFetching, setIsAddWalletFetching] = React.useState(false);
  const [activeHistoryName, setActiveHistoryName] = React.useState(PAYOUTS_HISTORY_NAME);
  const [errorApiMessage, setErrorApiMessage] = React.useState(null);
  const [errorAmountMessage, setErrorAmountMessage] = React.useState(null);
  const [payCurrencyErrorMessage, setPayCurrencyErrorMessage] = React.useState(null);
  const [addWalletErrorMessage, setAddWalletErrorMessage] = React.useState(null);
  const [selectedProvider, setSelectedProvider] = React.useState(null);
  const [isAccountsUpdate, setIsAccountsUpdate] = React.useState(true);
  const [payoutError, setPayoutError] = React.useState(null);

  const historyItems = React.useMemo(() => {
    if (!dataFetching) {
      if (activeHistoryName === CONVERSION_HISTORY_NAME) return conversionHistory;
      if (activeHistoryName === FIAT_HISTORY_NAME) return fiatHistory;

      return withdrawals;
    }

    return [];
  }, [activeHistoryName, dataFetching]);
  const historyItemsFiltered = React.useMemo(() => {
    const isConversionHistoryActive = activeHistoryName === CONVERSION_HISTORY_NAME;
    const isFiatHistoryActive = activeHistoryName === FIAT_HISTORY_NAME;

    if (isConversionHistoryActive) {
      return searchFormData
        ? getConversionFilteredDataWithFilter(conversionHistory, searchFormData)
        : getConversionFilteredDataWithSearch(conversionHistory, searchValue);
    }

    if (isFiatHistoryActive) {
      return searchFormData
        ? getFilteredDataWithFilter(fiatHistory, searchFormData)
        : getFilteredFiatDataWithSearch(fiatHistory, searchValue);
    }

    return searchFormData
      ? getFilteredDataWithFilter(withdrawals, searchFormData)
      : getFilteredDataWithSearch(withdrawals, searchValue);
  }, [
    activeHistoryName,
    conversionHistory,
    withdrawals,
    fiatHistory,
    searchValue,
    searchFormData,
  ]);
  const historyItemsFilteredLength = React.useMemo(() => historyItemsFiltered?.length ?? 0, [historyItemsFiltered]);
  const historyItemsNormalized = React.useMemo(() => {
    if (historyItemsFilteredLength <= ELEMENTS_PER_PAGE) {
      return historyItemsFiltered;
    }

    const startIndex = (page - 1) * ELEMENTS_PER_PAGE;
    const endIndex = page * ELEMENTS_PER_PAGE;

    return historyItemsFiltered.slice(startIndex, endIndex);
  }, [page, historyItemsFiltered, historyItemsFilteredLength]);
  const withdrawalInfoItems = React.useMemo(() => {
    if (activeHistoryName === CONVERSION_HISTORY_NAME) {
      return getConversionPaymentInfoItems(activePaymentInfo, currenciesObject);
    }
    if (activeHistoryName === FIAT_HISTORY_NAME) {
      return getFiatPaymentInfoItems(activePaymentInfo, currenciesObject);
    }

    return getPaymentInfoItems(activePaymentInfo, currenciesObject);
  }, [activePaymentInfo, currenciesObject, activeHistoryName]);
  const balanceWithUsdAmount = React.useMemo(() => (
    getBalanceWithFiatAmount(balance, marketInfoRates, marketInfoFromCurrency)
  ), [balance, marketInfoRates, marketInfoFromCurrency]);

  const {
    currentBalances,
    balancesFilteredLength,
    handleBalanceSearchChange,
    handleBalancePageChange,
    balancePage,
  } = useFilteredBalancesData(balanceWithUsdAmount);

  const {
    address,
    isAddressFetching,
    addressError,
    resetAddressError,
  } = useGetDepositAddressByNetwork(depositCurrency?.network);

  const handleHistoryTabClick = (name) => {
    setActiveHistoryName(name);
    setSearchValue('');
    setSearchFormData(null);
    setPage(1);
  };
  const handleInvoiceResultPopupClose = () => {
    setIsInvoiceResultPopupShow(false);

    if (nextTimerId) {
      clearTimeout(nextTimerId);
      setNextTimerId(null);
    }
  };
  const handleTopUpPopupClose = () => {
    setIsTopUpPopupShow(false);
    setMinAmount(null);
    setErrorApiMessage(null);
    setErrorAmountMessage(null);
    setEstimate(null);
    setDefaultTicker(null);
    setDepositCurrency(null);
  };
  const handle2faToggle = () => {
    setIs2faPopupShow((prev) => !prev);
  };
  const handleInfoPopupClose = () => {
    setIsInfoPopupShow(false);
  };
  const handleSearchChange = (value) => {
    setSearchValue(value);
  };
  const handleFiltersToggle = React.useCallback(() => {
    setIsFilterOpen((prev) => !prev);
  }, []);
  const handleFilterSubmit = React.useCallback((data) => {
    setSearchFormData((prevState) => ({
      ...prevState,
      ...data,
    }));
    setSearchValue('');
    const date = data?.date ?? null;
    const status = data?.status ?? null;
    const id = data?.id ?? null;
    const payoutDescription = data?.payoutDescription ?? null;
    const isDataEmpty = !date && !status && !id && !payoutDescription;

    if (isDataEmpty) {
      setSearchFormData(null);
    }
  }, [setSearchFormData, setSearchValue]);
  const handleConversionFilterSubmit = React.useCallback((data) => {
    setSearchFormData((prevState) => ({
      ...prevState,
      ...data,
    }));
    setSearchValue('');

    const date = data?.date ?? null;
    const status = data?.status ?? null;
    const currencyFrom = data?.currencyFrom ?? null;
    const currencyTo = data?.currencyTo ?? null;
    const isDataEmpty = !date && !status && !currencyFrom && !currencyTo;

    if (isDataEmpty) {
      setSearchFormData(null);
    }
  }, [setSearchFormData, setSearchValue]);
  const handleConvertButtonClick = React.useCallback((ticker) => {
    if (ticker) {
      setDefaultTicker(safeToLowerCase(ticker));
    }
    setIsConversionFormShow(true);
    trackEvent(TRACK_EVENT_NAMES.CUSTODY_CONVERT, {
      action: 'click',
    });
  }, []);
  const setFetchedConversionEstimate = async (fromTicker, toTicker, amount) => {
    setIsEstimateFetching(true);

    const { data, status } = await fetchConversionEstimate({
      from: fromTicker,
      to: toTicker,
      amount,
    });

    setIsEstimateFetching(false);

    if (status === 200) {
      const estimate = data?.estimatedAmount ?? null;
      const estimateString = estimate ? String(estimate) : estimate;

      setConversionEstimateAmount(estimateString);

      return estimate;
    } else {
      const errorMessage = getSafeErrorMessageText(data?.errorData?.message);
      setConversionEstimateAmount(null);
      setErrorApiMessage(errorMessage);
    }
  };
  const handleConversionCurrencyChange = async (fromTicker, toTicker, amount) => {
    const amountNumber = Number(amount);
    const fromTickerNormalized = fromTicker || null;
    const fromTickerLowerCased = safeToLowerCase(fromTickerNormalized);
    const toTickerNormalized = toTicker || null;
    const toTickerLowerCased = safeToLowerCase(toTickerNormalized);
    const isValid = amountNumber && fromTickerNormalized && toTickerNormalized;

    if (isValid) {
      await setFetchedConversionEstimate(
        fromTickerLowerCased,
        toTickerLowerCased,
        amountNumber,
      );
    } else {
      setConversionEstimateAmount(null);
    }
  };
  const handleConversionAmountChange = React.useCallback(debounce(async (amount, fromTicker, toTicker) => {
    const amountNumber = Number(amount);
    const fromTickerNormalized = fromTicker || null;
    const fromTickerLowerCased = safeToLowerCase(fromTickerNormalized);
    const toTickerNormalized = toTicker || null;
    const toTickerLowerCased = safeToLowerCase(toTickerNormalized);
    const isValid = amountNumber && fromTickerNormalized && toTickerNormalized;

    setErrorApiMessage(null);

    if (!amountNumber) {
      setConversionEstimateAmount(null);

      return;
    }

    if (isValid) {
      await setFetchedConversionEstimate(
        fromTickerLowerCased,
        toTickerLowerCased,
        amountNumber,
      );
    }
  }, 500), []);
  const handleConversionSubmit = async (formData) => {
    const fromTicker = formData?.fromCurrency ?? null;
    const toTicker = formData?.toCurrency ?? null;
    const payAmount = formData?.amount ?? null;

    setIsFetching(true);
    const { data, status } = await fetchCreateConversionPayment({
      from: fromTicker,
      to: toTicker,
      amount: payAmount,
    });
    setIsFetching(false);

    if (status === 200) {
      setIsConversionFormShow(false);
      setIsConversionCreatedPopupShow(true);
      setConversionEstimateAmount(null);
      if (isFunction(onUpdateConversionHistory)) {
        onUpdateConversionHistory();
      }
      trackEvent(TRACK_EVENT_NAMES.CUSTODY_CONVERTED, {
        action: 'submit',
      });
    } else {
      const errorMessage = getSafeErrorMessageText(data?.errorData?.message);
      setErrorApiMessage(errorMessage);
    }
  };
  const handleConversionCreatedPopupClose = () => {
    setIsConversionCreatedPopupShow(false);
    if (errorApiMessage) {
      setErrorApiMessage(null);
    }
  };
  const handleConversionFormClose = () => {
    setIsConversionFormShow(false);
    setDefaultTicker(null);
    setErrorApiMessage(null);
    setConversionEstimateAmount(null);
  };
  const handleExportPopupToggle = React.useCallback(() => {
    setIsExportPopupShow((prev) => !prev);
  }, []);
  const handleExportPDF = () => {
    if (!hasElementsInArray(historyItemsFiltered)) {
      return;
    }

    const isConversionActive = activeHistoryName === CONVERSION_HISTORY_NAME;
    const name = getCurrentExportName(
      isConversionActive,
      searchFormData,
    );
    const currentHeader = isConversionActive
      ? CONVERSION_HISTORY_HEADERS
      : withdrawalHeaders;
    const currentCreateBodyFunction = isConversionActive
      ? createBodyForConversionDocument
      : createBodyForDocument;
    const columnOptions = isConversionActive
      ? {
        0: { cellWidth: 25 },
        1: { cellWidth: 30 },
        2: { cellWidth: 30 },
        3: { cellWidth: 35 },
        4: { cellWidth: 25 },
        5: { cellWidth: 30 },
        6: { cellWidth: 30 },
      } : {
        0: { cellWidth: 25 },
        1: { cellWidth: 25 },
        2: { cellWidth: 25 },
        3: { cellWidth: 25 },
        4: { cellWidth: 48 },
        5: { cellWidth: 40 },
        6: { cellWidth: 25 },
        7: { cellWidth: 40 },
        8: { cellWidth: 40 },
      };
    const orientation = isConversionActive
      ? 'portrait'
      : 'landscape';

    saveToPdf({
      name,
      headerData: [currentHeader],
      bodyData: currentCreateBodyFunction(historyItemsFiltered),
      columnOptions,
      orientation,
    });
  };
  const handleExportDifferentFormat = (format) => {
    if (!hasElementsInArray(historyItemsFiltered)) {
      return;
    }

    const isConversionActive = activeHistoryName === CONVERSION_HISTORY_NAME;
    const name = getCurrentExportName(
      isConversionActive,
      searchFormData,
    );
    const currentHeader = isConversionActive
      ? CONVERSION_HISTORY_HEADERS
      : withdrawalHeaders;
    const currentCreateBodyFunction = isConversionActive
      ? createBodyForConversionDocument
      : createBodyForDocument;

    saveToDifferentFileFormat({
      name,
      format: format,
      headerData: [currentHeader],
      bodyData: currentCreateBodyFunction(historyItemsFiltered),
    });
  };
  const handleChangePage = (nextPage) => {
    setPage(nextPage);
  };
  const handleHistoryItemClick = React.useCallback((data) => {
    setActivePaymentInfo(data);
    setIsInfoPopupShow(true);
  }, []);
  const handle2faSubmit = async () => {
    if (isFunction(onUpdateWithdrawals)) {
      const updatedWithdrawals = await onUpdateWithdrawals();
      const hasUpdatedWithdrawals = hasElementsInArray(updatedWithdrawals);
      const currentPaymentFound = hasUpdatedWithdrawals && activePaymentInfo
        ? updatedWithdrawals.find((item) => item?.batchWithdrawalId === activePaymentInfo?.batchWithdrawalId)
        : null;
      if (currentPaymentFound) {
        setActivePaymentInfo(currentPaymentFound);
      }
      setIs2faPopupShow(false);
    }
  };
  const handleTopUpButtonClick = React.useCallback((ticker) => {
    if (ticker) {
      setDefaultTicker(safeToLowerCase(ticker));
    }

    setIsTopUpPopupShow(true);
    trackEvent(TRACK_EVENT_NAMES.CUSTODY_TOP_UP, {
      action: 'click',
    });
  }, []);
  const handleResetData = () => {
    setErrorApiMessage(null);
    setEstimate(null);
    setErrorAmountMessage(null);
    setPayCurrencyErrorMessage(null);
  };
  const setFetchedEstimate = async (fromTicker, toTicker, baseTicker, amount) => {
    setIsEstimateFetching(true);

    const { data, status } = await fetchTopUpEstimate({
      fromTicker,
      toTicker,
      baseTicker,
      amount,
    });

    setIsEstimateFetching(false);

    if (status === 200) {
      const estimate = data?.estimatedAmount ?? null;

      setEstimate(estimate);
    } else {
      const errorMessage = getSafeErrorMessageText(data?.errorData?.message);

      setEstimate(null);
      setErrorApiMessage(errorMessage);
    }
  };
  const updateInfoCreatedPayment = (payment) => {
    if (nextTimerId) {
      clearTimeout(nextTimerId);
      setNextTimerId(null);
    }

    const startUpdating = (id, status) => {
      const isNeedUpdate = isNeedStatusUpdate(status);

      if (!isNeedUpdate) {
        return false;
      }

      const nextId = setTimeout(async () => {
        const { data, status } = await fetchPayment(id);

        if (status === 200) {
          setPaymentCreatedData(data);
          startUpdating(data?.id, data?.status);
        } else {
          return false;
        }
      }, PAYMENT_INFO_UPDATING_TIME);

      setNextTimerId(nextId);
    };

    startUpdating(payment?.id, payment?.status);
  };
  const handleTopUpCurrencyFromChange = async (fromTicker, toTicker, amount) => {
    handleResetData();
    const fromTickerNormalized = fromTicker || null;
    const fromTickerLowerCased = safeToLowerCase(fromTickerNormalized);
    const toTickerNormalized = toTicker || null;
    const toTickerLowerCased = safeToLowerCase(toTickerNormalized);
    const amountNumber = Number(amount);
    const isValid = amountNumber && fromTickerNormalized && toTickerNormalized;

    if (isValid) {
      if (amountNumber < minAmount) {
        setErrorAmountMessage(MIN_AMOUNT_ERROR_TEXT);

        return;
      }
      await setFetchedEstimate(
        fromTickerLowerCased,
        toTickerLowerCased,
        baseCurrency,
        amount,
      );
    }
  };
  const handleTopUpCurrencyToChange = async (fromTicker, toTicker, amount) => {
    handleResetData();
    const fromTickerNormalized = fromTicker || null;
    const fromTickerLowerCased = safeToLowerCase(fromTickerNormalized);
    const toTickerNormalized = toTicker || null;
    const toTickerLowerCased = safeToLowerCase(toTickerNormalized);
    const amountNumber = Number(amount);
    const isValid = amountNumber && fromTickerNormalized && toTickerNormalized;
    let nextMinAmount = null;

    const { data, status } = await fetchMinAmount({
      from: toTickerLowerCased,
      to: baseCurrency,
    });

    if (status === 200) {
      nextMinAmount = data?.estimatedAmountTo ?? null;
    }

    setMinAmount(nextMinAmount);

    if (isValid) {
      if (amountNumber < nextMinAmount) {
        setErrorAmountMessage(MIN_AMOUNT_ERROR_TEXT);

        return;
      }
      await setFetchedEstimate(
        fromTickerLowerCased,
        toTickerLowerCased,
        baseCurrency,
        amount,
      );
    }
  };
  const handleTopUpAmountChange = React.useCallback(debounce(async (value, fromTicker, toTicker) => {
    const amount = Number(value);
    const fromTickerNormalized = fromTicker || null;
    const fromTickerLowerCased = safeToLowerCase(fromTickerNormalized);
    const toTickerNormalized = toTicker || null;
    const toTickerLowerCased = safeToLowerCase(toTickerNormalized);
    const isValid = amount && fromTickerNormalized && toTickerNormalized;

    setErrorApiMessage(null);
    setEstimate(null);
    setErrorAmountMessage(null);

    if (!amount) {
      return;
    }

    if (amount < minAmount) {
      setErrorAmountMessage(MIN_AMOUNT_ERROR_TEXT);

      return;
    }

    if (isValid) {
      await setFetchedEstimate(
        fromTickerLowerCased,
        toTickerLowerCased,
        baseCurrency,
        amount,
      );
    }
  }, 500), [minAmount, baseCurrency]);
  const handleTopUpSubmit = async (formData) => {
    const fromTickerNormalized = formData?.fromCurrency ?? null;
    const fromTickerLowerCased = safeToLowerCase(fromTickerNormalized);
    const toTickerNormalized = formData?.toCurrency ?? null;
    const toTickerLowerCased = safeToLowerCase(toTickerNormalized);
    const amount = Number(formData?.amount ?? 0);

    if (amount < minAmount) {
      return;
    }

    setIsFetching(true);

    const { data, status } = await fetchCreateInvoice({
      payoutCurrency: fromTickerLowerCased,
      payCurrency: toTickerLowerCased,
      priceAmount: amount,
      priceCurrency: baseCurrency,
    });

    setIsFetching(false);

    if (status === 200) {
      const invoiceData = data?.invoice ?? null;
      const paymentData = data?.payment ?? null;

      setInvoiceCreatedData(invoiceData);
      setPaymentCreatedData(paymentData);
      setIsTopUpPopupShow(false);
      setIsInvoiceResultPopupShow(true);
      trackEvent(TRACK_EVENT_NAMES.CUSTODY_TOP_UP_SUCCESS, {
        action: 'submit',
      });
      updateInfoCreatedPayment(paymentData);
    } else {
      const errorMessage = getSafeErrorMessageText(data?.errorData?.message);
      setErrorApiMessage(errorMessage);
    }
  };
  const handleRequestPayoutPopupClose = () => {
    setIsRequestPayoutPopupShow(false);
    setPayoutOutcomeAddress(null);
    setPayoutError(null);
    setRequestPayoutWithdrawalId(null);
    setIsPayoutCreatedShow(false);
    setPayoutCreatedData(null);
  };
  const handleRequestPayout = React.useCallback(async (currency) => {
    const ticker = currency?.code ?? null;
    const tickerLowerCased = safeToLowerCase(ticker);

    const { data, status } = await fetchOutcomeWalletAddress({ ticker: tickerLowerCased });

    if (status === 200) {
      const address = data?.address ?? null;

      setPayoutOutcomeAddress(address);
    }

    setIsRequestPayoutPopupShow(true);
    setRequestPayoutCurrency(currency);
    trackEvent(TRACK_EVENT_NAMES.CUSTODY_REQUEST_PAYOUT, {
      action: 'click',
    });
  }, []);
  const handleAddWalletSubmit = async (address, extraId) => {
    setAddWalletErrorMessage(null);
    const ticker = requestPayoutCurrency?.code ?? null;
    const currentExtraId = extraId || null;

    setIsAddWalletFetching(true);
    const { data, status } = await fetchCreateStoreOutcomeWallet({
      outcomeAddress: address,
      outcomeCurrency: ticker,
      eid: currentExtraId,
    });
    setIsAddWalletFetching(false);

    if (status === 200 && data) {
      setPayoutOutcomeAddress(address);
      dispatch(setWallets(data));
    } else {
      const errorMessage = getSafeErrorMessageText(data?.errorData?.message);

      setAddWalletErrorMessage(errorMessage);
    }
  };
  const handleRequestFiatPayout = (formData) => {
    const { ticker, fiatCurrency, amount } = formData;

    const tickerLowerCased = safeToLowerCase(ticker);
    const fiatCurrencyLowerCased = safeToLowerCase(fiatCurrency);

    return fetchRequestFiatPayout({
      ticker: tickerLowerCased,
      fiatCurrency: fiatCurrencyLowerCased,
      amount: amount,
      provider: selectedProvider?.code,
    });
  };

  const handleRequestCryptoPayout = (formData) => {
    const { ticker, amount, address } = formData;

    if (!address) {
      return;
    }

    const tickerLowerCased = safeToLowerCase(ticker);
    const amountString = String(amount);

    return fetchRequestPayout({
      ticker: tickerLowerCased,
      address: payoutOutcomeAddress,
      amount: amountString,
    });
  };

  const handleRequestPayoutConfirm = async (payoutData) => {
    const {
      isFiatPayout,
      formData,
    } = payoutData;

    setIsRequestPayoutFetching(true);
    const requestPayoutFunction = isFiatPayout ? handleRequestFiatPayout : handleRequestCryptoPayout;
    const { data, status, errorMessage } = await requestPayoutFunction(formData);
    setIsRequestPayoutFetching(false);

    if (status !== 200) {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      const currentPayoutError = getPayoutError(errorMessage, errorDataMessage, isFiatPayout);
      setPayoutError(currentPayoutError);

      const endpoint = isFiatPayout ? '/fiat-payouts' : '/request-payout';
      consoleErrorMessage(errorMessage, errorDataMessage, endpoint);

      return;
    }

    trackEvent(TRACK_EVENT_NAMES.CUSTODY_REQUEST_PAYOUT_SUCCESS, {
      action: 'submit',
    });

    const withdrawalId = data?.id ?? null;
    const isVerified = data?.isVerified ?? false;
    setPayoutCreatedData(isFiatPayout ? data : data?.withdrawals[0]);

    if (!isVerified) {
      setIs2faPopupShow(true);
      setRequestPayoutWithdrawalId(withdrawalId);

      return;
    }

    if (!isFiatPayout && isFunction(onUpdateWithdrawals)) {
      onUpdateWithdrawals();
    }

    if (isFiatPayout && isFunction(onUpdateFiatHistory)) {
      onUpdateFiatHistory();
    }

    setIsPayoutCreatedShow(true);
  };

  const handleRequestPayout2faSubmit = async () => {
    if (isFunction(onUpdateWithdrawals)) {
      onUpdateWithdrawals();
    }

    setIs2faPopupShow(false);
    setIsPayoutCreatedShow(true);
  };
  const handleProviderSelect = React.useCallback((provider) => {
    setSelectedProvider(provider || null);
  }, []);

  const setFetchedFiatPayoutEstimate = async (fiatCurrency, cryptoCurrency, amount, providerCode) => {
    const { data, status, errorMessage } = await fetchFiatEstimate({
      fiatCurrency,
      cryptoCurrency,
      amount,
      provider: providerCode,
    });

    if (status !== 200 && errorMessage !== 'canceled') {
      const errorDataMessage = data.status === 400
        ? FIAT_ESTIMATE_AMOUNT_ERROR_TEXT
        : getSafeErrorMessageText(data?.errorData?.message);
      setPayoutError(errorDataMessage);
      consoleErrorMessage(errorMessage, errorDataMessage, '/fiat-payouts/estimate');

      return;
    }

    setFiatEstimate(data?.result);
  };

  const setFiatPayoutEstimate = React.useCallback(debounce(async (fiatCurrency, cryptoCurrency, amount) => {
    setIsFiatEstimateFetching((prev) => !prev);
    const MIDDLE_TICKER = 'usdttrc20';
    const cryptoCurrencyNormalized = cryptoCurrency || null;
    const cryptoCurrencyLowerCased = safeToLowerCase(cryptoCurrencyNormalized);
    const fiatCurrencyNormalized = fiatCurrency || null;
    const fiatCurrencyLowerCased = safeToLowerCase(fiatCurrencyNormalized);
    const isFirstConversionNeeded = cryptoCurrencyLowerCased !== MIDDLE_TICKER;

    let amountForFiatConversion = amount;

    if (cryptoCurrencyLowerCased && isFirstConversionNeeded) {
      amountForFiatConversion = await setFetchedConversionEstimate(
        cryptoCurrencyLowerCased,
        MIDDLE_TICKER,
        amount,
      );
    }

    await setFetchedFiatPayoutEstimate(
      fiatCurrencyLowerCased,
      MIDDLE_TICKER,
      amountForFiatConversion,
      selectedProvider?.code,
    );
    setIsFiatEstimateFetching((prev) => !prev);

  }, 500), [selectedProvider]);

  const handleResetPayoutFormData = () => {
    setPayoutError(null);
    setFiatEstimate(null);
  };

  const hasBalance = hasElementsInArray(balanceWithUsdAmount);
  const buttonTopUpShow = !dataFetching && hasBalance;
  const hasHistoryItemsFiltered = hasElementsInArray(historyItemsFiltered);
  const isConversionHistory = activeHistoryName === CONVERSION_HISTORY_NAME;
  const isCryptoPayoutsHistory = activeHistoryName === PAYOUTS_HISTORY_NAME;
  const isFiatPayoutsHistory = activeHistoryName === FIAT_HISTORY_NAME;
  const currentHistoryThItems = HISTORY_OPTIONS_OBJECT[activeHistoryName].thItems;
  const isFilterMenuIconActive = isFilterOpen || Boolean(searchFormData);
  const isPaginationShow = historyItemsFilteredLength > ELEMENTS_PER_PAGE;
  const current2faWithdrawalId = requestPayoutWithdrawalId || activePaymentInfo?.batchWithdrawalId;
  const current2faSubmit = requestPayoutWithdrawalId ? handleRequestPayout2faSubmit : handle2faSubmit;
  const isActivateOffRampShow = isFiatPayoutsHistory && !partner?.isOffRampEnabled;
  const inputSearchPlaceholder = HISTORY_OPTIONS_OBJECT[activeHistoryName].placeholder;
  const hasHistoryItems = hasElementsInArray(historyItems);
  const emptyTableText = isActivateOffRampShow ? "" : HISTORY_OPTIONS_OBJECT[activeHistoryName].emptyTableText;
  const partnerEmail = partner?.email || '';
  const fiatCurrencies = useFiatOffRampCurrencies(selectedProvider?.code);
  const { accounts, isAccountsLoading } = useBankAccounts(isAccountsUpdate, selectedProvider?.code);
  const providersWithStatus = useProviderStatuses(OFF_RAMP_PROVIDERS);
  const allBalancesLength = balanceWithUsdAmount.length;
  const isValidDepositAddressesError = depositAddressesError && depositAddressesError !== VALID_DEPOSIT_ADDRESS_ERROR;
  const depositError = Boolean(isValidDepositAddressesError || addressError);
  const isDeposit = depositCurrency && address && !isAddressFetching && !paymentSettingsFetching && !depositError;

  return (
    <div className={classes.custodyMain}>
      <SectionBalance
        className={classes.sectionBalances}
        buttonTopUpShow={buttonTopUpShow}
        onConvert={handleConvertButtonClick}
        onTopUp={handleTopUpButtonClick}
        onRequestPayout={handleRequestPayout}
        isDataFetching={dataFetching}
        isMarketInfoFetching={marketInfoFetching}
        hasBalance={hasBalance}
        balance={currentBalances}
        balanceLength={allBalancesLength}
        balanceFilteredLength={balancesFilteredLength}
        page={balancePage}
        onPage={handleBalancePageChange}
        onSearch={handleBalanceSearchChange}
      />
      <SectionHistory
        className={classes.custodyHistory}
        Tabs={(
          <TableTabs
            className={cn([
              classes.custodyHistoryTabs,
              isConversionHistory && classes.custodyHistoryTabsConversionsActive,
              isFiatPayoutsHistory && classes.custodyHistoryTabsFiatActive,
            ])}
            activeTabKey={activeHistoryName}
            firstTabKey={PAYOUTS_HISTORY_NAME}
            firstTabName="Crypto payouts"
            secondTabKey={CONVERSION_HISTORY_NAME}
            secondTabName="Conversions"
            thirdTabKey={FIAT_HISTORY_NAME}
            thirdTabName="Fiat payouts"
            onClick={handleHistoryTabClick}
          />
        )}
        RightElement={(
          <InputSearch
            className={classes.searchInput}
            placeholder={inputSearchPlaceholder}
            value={searchValue}
            onChange={handleSearchChange}
            ImageAdditional={(
              <span
                onClick={handleFiltersToggle}
                className={cn([
                  classes.searchInputMenuIcon,
                  isFilterMenuIconActive && classes.searchInputMenuIconActive,
                ])}
              >
                <MenuFilterIcon />
              </span>
            )}
          />
        )}
        filter={(
          <div className={cn([
            classes.custodyHistoryFilterBox,
            isFilterOpen && classes.custodyHistoryFilterBoxActive,
          ])}>
            {isConversionHistory && (
              <ConversionHistoryFilterMemo
                className={classes.custodyHistoryFilter}
                onSubmit={handleConversionFilterSubmit}
                onClose={handleFiltersToggle}
                onSearchFormData={setSearchFormData}
                hasData={hasHistoryItemsFiltered}
                onExport={handleExportPopupToggle}
                currencies={availableCurrencies}
              />
            )}
            {!isConversionHistory && (
              <HistoryFilterMemo
                className={classes.custodyHistoryFilter}
                onSubmit={handleFilterSubmit}
                onClose={handleFiltersToggle}
                onSearchFormData={setSearchFormData}
                hasData={hasHistoryItemsFiltered}
                onExport={handleExportPopupToggle}
              />
            )}
          </div>
        )}
      >
        <HistoryTableMemo
          className={cn([
            classes.custodyHistoryTable,
            isConversionHistory && classes.custodyConversionHistoryTable,
            isFiatPayoutsHistory && classes.custodyFiatHistoryTable,
            !dataFetching && !hasHistoryItems && classes.custodyHistoryTableEmpty,
          ])}
          thItems={currentHistoryThItems}
          colSpan={6}
          loading={dataFetching}
          historyData={historyItemsNormalized}
          hasData={hasHistoryItems}
          emptyText={emptyTableText}
          notFoundText="No transactions matching this criteria."
          emptyElement={isActivateOffRampShow ? (
            <CustomInformationLink
              className={classes.custodyHistoryTableLink}
              href="/fiat-operations-settings"
              isSpaLink
            >
              <p>
                Empower your customers to make payments in crypto and get profit in traditional fiat, mitigating market
                volatility. Submit a request to activate fiat off-ramp providers through the
                {' '}
                <span>fiat operations settings</span>.
              </p>
            </CustomInformationLink>
          ) : (
            <img
              className={classes.custodyNoTransactionsIcon}
              src="/images/custody-page/no-items-icon.svg"
              alt="No transactions"
              decoding="async"
              loading="lazy"
              width="48"
              height="48"
            />
          )}
        >
          {historyItemsNormalized.map((item) => (
            <CustodyHistoryItemMemo
              key={item?.id}
              data={item}
              onClick={handleHistoryItemClick}
              conversionHistory={isConversionHistory}
              fiatHistory={isFiatPayoutsHistory}
              currenciesObject={currenciesObject}
            />
          ))}
        </HistoryTableMemo>
        {isPaginationShow && (
          <NewPagination
            currentPage={page}
            countItems={historyItemsFilteredLength}
            itemsPerPage={ELEMENTS_PER_PAGE}
            onChangePage={handleChangePage}
          />
        )}
      </SectionHistory>
      <TopUpBalancePopup
        className={classes.custodyBalanceTopUpPopup}
        open={isTopUpPopupShow}
        onClose={handleTopUpPopupClose}
        formData={{
          currencies: partnerCurrencies,
          onSubmit: handleTopUpSubmit,
          baseCurrency: baseCurrency,
          defaultTicker: defaultTicker,
          payCurrencyErrorMessage: payCurrencyErrorMessage,
          minAmount: minAmount,
          errorAmountMessage: errorAmountMessage,
          estimate: estimate,
          onAmountChange: handleTopUpAmountChange,
          onCurrencyFrom: handleTopUpCurrencyFromChange,
          onCurrencyTo: handleTopUpCurrencyToChange,
          estimateFetching: isEstimateFetching,
          fetching: isFetching,
          errorApiMessage: errorApiMessage,
        }}
        depositAddressData={{
          isDeposit,
          address,
          depositCurrency,
          setDepositCurrency,
          depositAddressesFetching: paymentSettingsFetching,
          isAddressFetching,
          depositError,
          resetAddressError,
        }}
      />
      <InvoiceResultPopup
        className={classes.custodyPaymentInfoPopup}
        open={isInvoiceResultPopupShow}
        onClose={handleInvoiceResultPopupClose}
        invoice={invoiceCreatedData}
        payment={paymentCreatedData}
        currenciesObject={currenciesObject}
      />
      <ConversionPopup
        className={classes.custodyConversionPopup}
        open={isConversionFormShow}
        onClose={handleConversionFormClose}
        formData={{
          currencies: balance,
          availableCurrencies: availableCurrencies,
          defaultTicker: defaultTicker,
          onAmountChange: handleConversionAmountChange,
          estimate: conversionEstimateAmount,
          estimateFetching: isEstimateFetching,
          errorApiMessage: errorApiMessage,
          onSubmit: handleConversionSubmit,
          onCurrencyChange: handleConversionCurrencyChange,
        }}
      />
      <ConversionCreatedPopup
        className={classes.custodyPayoutCreatedPopup}
        open={isConversionCreatedPopupShow}
        onClose={handleConversionCreatedPopupClose}
      />
      <PaymentInfoPopup
        className={classes.custodyInfoBoxPopup}
        open={isInfoPopupShow}
        onClose={handleInfoPopupClose}
        id={activePaymentInfo?.id}
        items={withdrawalInfoItems}
        verified={activePaymentInfo?.isVerified}
        onClick={handleInfoPopupClose}
        on2faToggle={handle2faToggle}
        cryptoPayoutHistoryActive={isCryptoPayoutsHistory}
      />
      <ExportPopup
        className={classes.custodyExportPopup}
        open={isExportPopupShow}
        onClose={handleExportPopupToggle}
        onExportPdf={handleExportPDF}
        onExport={handleExportDifferentFormat}
      />
      <RequestPayoutPopup
        className={classes.custodyRequestPayoutPopup}
        open={isRequestPayoutPopupShow}
        title="Request payout"
        currencies={availableCurrencies}
        currency={requestPayoutCurrency}
        onClose={handleRequestPayoutPopupClose}
        onClick={handleRequestPayoutConfirm}
        errorMessage={payoutError}
        onResetPayoutFormData={handleResetPayoutFormData}
        fetching={isRequestPayoutFetching}
        isPayoutCreatedShow={isPayoutCreatedShow}
        payoutCreatedData={payoutCreatedData}
        providersData={{
          providers: providersWithStatus,
          onSelectProvider: handleProviderSelect,
          selectedProvider: selectedProvider,
          accounts: accounts,
          onUpdate: setIsAccountsUpdate,
          isAccountsLoading: isAccountsLoading,
          fiatCurrencies: fiatCurrencies,
          onChange: setFiatPayoutEstimate,
          fiatEstimate: fiatEstimate,
          isFiatEstimateFetching: isFiatEstimateFetching,
          partnerEmail: partnerEmail,
        }}
        walletData={{
          address: payoutOutcomeAddress,
          onWalletSubmit: handleAddWalletSubmit,
          walletError: addWalletErrorMessage,
          walletFetching: isAddWalletFetching,
        }}
      />
      <New2faPopup
        open={is2faPopupShow}
        onClose={handle2faToggle}
        onUpdate={current2faSubmit}
        withdrawalId={current2faWithdrawalId}
      />
    </div>
  );
};

CustodyMain.defaultProps = {
  availableCurrencies: [],
  partnerCurrencies: [],
  currenciesObject: {},
  balance: [],
  withdrawals: [],
  conversionHistory: [],
  fiatHistory: [],
  baseCurrency: null,
  dataFetching: false,
  onUpdateConversionHistory: null,
  onUpdateFiatHistory: null,
};

CustodyMain.propTypes = {
  availableCurrencies: PropTypes.arrayOf(PropTypes.object),
  partnerCurrencies: PropTypes.arrayOf(PropTypes.object),
  currenciesObject: PropTypes.object,
  balance: PropTypes.arrayOf(PropTypes.object),
  withdrawals: PropTypes.arrayOf(PropTypes.object),
  conversionHistory: PropTypes.arrayOf(PropTypes.object),
  fiatHistory: PropTypes.arrayOf(PropTypes.object),
  baseCurrency: PropTypes.string,
  dataFetching: PropTypes.bool,
  onUpdateConversionHistory: PropTypes.func,
  onUpdateFiatHistory: PropTypes.func,
};
