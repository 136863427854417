import * as React from 'react';
import { useSelector } from 'react-redux';
import { currencyListSelector } from '../store/currencies/selectors';
import { baseCurrencySelector } from '../store/payment-settings/selectors';
import { useFiatCurrencies } from './use-fiat-currencies';
import { useCurrenciesToObject } from './use-currencies-to-object';
import { useCryptoCurrencies } from './use-crypto-currencies';
import { Currency, FiatCurrency } from '../default-types';

export const useBaseCurrencyForSelect = () => {
  const currencyList = useSelector(currencyListSelector());
  const currentBaseCurrencyCode = useSelector(baseCurrencySelector());
  const { cryptoCurrencies } = useCryptoCurrencies(currencyList);
  const { fiatCurrencies } = useFiatCurrencies(currencyList, false, false) as
    { fiatCurrencies: Array<FiatCurrency> };
  const { currenciesObject: cryptoCurrenciesObject } = useCurrenciesToObject(cryptoCurrencies, false);

  const baseCurrencies = React.useMemo(() => fiatCurrencies.map((el: FiatCurrency) => {
    let baseCurrencyElement: FiatCurrency | Currency = el;
    const baseCurrencyTicker = el.code ?? '';
    const foundCryptoCurrency = cryptoCurrenciesObject[baseCurrencyTicker as
      keyof typeof cryptoCurrenciesObject] as unknown as Currency;

    if (foundCryptoCurrency) {
      baseCurrencyElement = { ...foundCryptoCurrency, isPopular: false };
    } else {
      baseCurrencyElement = { ...baseCurrencyElement, name: '' };
    }

    return baseCurrencyElement;
  }), [fiatCurrencies, cryptoCurrenciesObject]);

  return {
    currentBaseCurrencyCode,
    baseCurrencies,
  };
};
