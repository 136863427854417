import * as React from 'react';

export const useTimeout = (func, delay = 1000, dependency) => {
  React.useEffect(() => {
    let timerId = null;

    if (dependency) {
      timerId = setTimeout(func, delay);
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [dependency ?? null]);
};
