import React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';
import { safeToLowerCase } from '../../../helpers/safe-to-lower-case';
import { CURRENCY_USD } from '../../../constants/app-constants';

const reduceItemFn = (acc, item) => {
  acc[safeToLowerCase(item.to_currency)] = item.rate;

  return acc;
};
const mapFn = (data) => ({
  code: data?.code ?? null,
  result: (data?.result?.[0]?.quotes ?? []).reduce(reduceItemFn, {}),
  fromCurrency: data?.result?.[0]?.from_currency ?? null,
  error: data?.error ?? null,
  unprocessed: data?.unprocessed ?? [],
});

/**
 * {String} options.fiatTicker
 * {String|Array} options.to
 */

export const useInfoPrice = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (options) => {
    const fiatTicker = options?.fiatTicker ?? CURRENCY_USD;
    const to = options?.to ?? null;
    const params = to ? { to } : null;

    const [cancelToken] = getRequestSource();
    const requestPromise = client.get(
      `/info/price/${fiatTicker}`,
      { params },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
