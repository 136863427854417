import { hasElementsInArray } from '../../../helpers/has-elements-in-array';
import { getFiatAmountOfBalances } from '../../../helpers/get-fiat-amount-of-balances';

/**
 * @param {Array<string>} names
 * @param {Number} maxNames
 * @param {Number} maxNameLength
 * @returns {{isValid: boolean, error: string}|{isValid: boolean, error: null}}
 */
export const checkNames = (names, maxNames, maxNameLength) => {
  const isNamesLengthAllowed = names.length <= maxNames;

  if (!isNamesLengthAllowed) {
    return {
      isValid: false,
      error: `Names must be at most ${maxNames}`,
    };
  }

  const nameInvalid = names.find((name) => name?.length > maxNameLength);

  if (nameInvalid) {
    return {
      isValid: false,
      error: `Name - ${nameInvalid} is not valid, must be at most ${maxNameLength} characters`,
    };
  }

  return {
    isValid: true,
    error: null,
  };
};

/**
 * @param {Object} options
 * @returns {Array<object>}
 */
export const getSubPartnerDataWithAmount = (options) => {
  const {
    data = [],
    balances = {},
    rates = null,
    currenciesObject = {},
    ticker,
  } = options;

  if (!hasElementsInArray(data)) {
    return [];
  }

  return data.map((item) => {
    const id = item?.id ?? null;
    const currentBalanceFound = balances[id] || null;
    const fiatAmount = getFiatAmountOfBalances(currentBalanceFound, currenciesObject, rates);
    const fiatAmountNormalized = rates && currentBalanceFound ? fiatAmount : null;

    return {
      ...item,
      amount: fiatAmountNormalized,
      ticker,
    };
  });
};
