import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from '../../../../helpers/format-date';
import { isFunction } from '../../../../helpers/is-function';
import { DATE_PATTERN } from '../../../../constants/app-constants';
import { getAmountWithTicker } from '../../../../helpers/get-amount-with-ticker';
import { getColorStatus } from '../../../../helpers/get-color-status';
import { safeToLowerCase } from '../../../../helpers/safe-to-lower-case';
import { toFixed } from '../../../../helpers/to-fixed';
import { safeToUpperCase } from '../../../../helpers/safe-to-upper-case';
import {
  getTransactionType,
  getTransactionTypeText,
} from '../../../../helpers/get-transaction-type';

export const TransactionsTableRow = (props) => {
  const {
    className,
    data,
    currenciesObject,
    onClick,
    isDesktop,
  } = props;

  const id = data?.id || data?.paymentId || null;
  const customerId = data?.fromSubId || data?.subPartnerId || null;
  const status = data?.status ?? null;
  const statusColor = status && getColorStatus(status);
  const statusNormalized = safeToLowerCase(status) || '-';
  const amount = data?.amount ?? 0;
  const amountFixed = toFixed(amount, 8);
  const ticker = data?.currency ?? '';
  const currentTicker = currenciesObject[safeToUpperCase(ticker)]?.ticker ?? ticker;
  const amountWithTicker = getAmountWithTicker(Number(amountFixed), currentTicker);
  const payAmount = data?.payAmount ?? null;
  const payTicker = data?.payCurrency ?? null;
  const payCurrentTicker = currenciesObject[safeToUpperCase(payTicker)]?.ticker ?? payTicker;
  const payPrice = payAmount ? `${payAmount} ${payCurrentTicker}` : null;
  const type = data?.type ?? null;
  const typeUpperCased = safeToUpperCase(type);
  const transactionType = getTransactionType(typeUpperCased);
  const transactionTypeText = getTransactionTypeText(typeUpperCased);
  const updatedAt = data?.updatedAt ?? '';
  const updatedDateFormatted = formatDate(updatedAt, DATE_PATTERN);
  const updatedDateNormalized = updatedDateFormatted || '-';

  const handleRowClick = () => {
    if (isFunction(onClick)) {
      onClick(data);
    }
  };

  return (
    <tr
      className={className}
      onClick={handleRowClick}
    >
      <td>
        <div>
          {id}
        </div>
        {!isDesktop && (
          <div>
            {customerId}
          </div>
        )}
      </td>
      {isDesktop && (
        <td>
          {customerId}
        </td>
      )}
      <td>
        <div data-text="capitalize" style={{ color: statusColor }}>
          {statusNormalized}
        </div>
        {!isDesktop && (
          <div data-color="black">
            {payPrice || amountWithTicker}
          </div>
        )}
      </td>
      {isDesktop && (
        <td data-color="black">
          {payPrice || amountWithTicker}
        </td>
      )}
      <td>
        <div>
          <span data-color="black">
            {transactionType}
          </span>
          {' '}
          {transactionTypeText}
        </div>
        {!isDesktop && (
          <div>
            {updatedDateNormalized}
          </div>
        )}
      </td>
      {isDesktop && (
        <td>
          {updatedDateNormalized}
        </td>
      )}
    </tr>
  );
};

TransactionsTableRow.defaultProps = {
  className: null,
  data: null,
  currenciesObject: {},
  onClick: null,
  isDesktop: false,
};

TransactionsTableRow.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  currenciesObject: PropTypes.object,
  onClick: PropTypes.func,
  isDesktop: PropTypes.bool,
};
