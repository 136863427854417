import React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';

const mapFn = (data) => data;

export const useConversionEstimate = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (options) => {
    const from_currency = options?.from ?? null;
    const to_currency = options?.to ?? null;
    const amount = options?.amount ?? null;
    const [cancelToken] = getRequestSource();
    const requestPromise = client.get(
      '/conversion-estimate',
      {
        params: {
          from_currency,
          to_currency,
          amount,
        },
      },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
