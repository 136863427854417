import * as React from 'react';
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { OnboardingStepsHeader } from '../onboarding-steps-header';
import { SET_UP_ACCOUNT_SCHEMA } from '../../helpers';
import { Field } from '../../../../../ui/field';
import { IndustrySelectField } from '../../../industry-select-field';
import { CurrenciesSelectField } from '../../../../../currencies-select-field';
import { SimpleSelectField } from '../../../../../ui/simple-select-field';
import { WITHDRAWAL_FEE_PAID_BY_ITEMS } from '../../../../../../constants/app-constants';
import { Button } from '../../../../../ui/button';
import { Currency, FiatCurrency } from '../../../../../../default-types';
import { SetUpAccountFormDataType } from '../../../../types';
import classes from './styles.module.scss';

type SetUpAccountFormProps = {
  hasBackButton: boolean,
  currencies: Array<{}>,
  onBack: () => void,
  onContinue: () => void,
  feePaidByCode: string;
  currentCompanyName: string;
  currentBaseCurrencyCode: string | null;
  industry: string;
  onSubmit: (formData: SetUpAccountFormDataType) => Promise<void>;
};

export const SetUpAccountForm: React.FC<SetUpAccountFormProps> = (props) => {
  const {
    hasBackButton,
    currencies,
    onBack,
    onContinue,
    feePaidByCode,
    currentCompanyName,
    currentBaseCurrencyCode,
    industry,
    onSubmit,
  } = props;

  const {
    control,
    handleSubmit,
    formState,
  } = useForm({ resolver: yupResolver(SET_UP_ACCOUNT_SCHEMA) });

  const { companyName } = formState.errors;
  const companyNameErrorMessage = (companyName?.message as string) ?? null;
  const currentFeePaidBy = WITHDRAWAL_FEE_PAID_BY_ITEMS
    .find((el) => el.key === feePaidByCode)?.value;

  const handleBaseCurrencySelect = React.useCallback(
    (func: (value: string) => void) => (currency: Currency | FiatCurrency) => {
      func(currency?.code || '');
    },
    [],
  );
  const handleFormSubmit: SubmitHandler<FieldValues> = async (formData) => {
    await onSubmit(formData as SetUpAccountFormDataType);
    onContinue();
  };

  return (
    <form
      className={classes.setUpAccountForm}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <OnboardingStepsHeader
        title="Set up account"
        hasBackButton={hasBackButton}
        onClick={onBack}
      />
      <Controller
        name="companyName"
        control={control}
        defaultValue={currentCompanyName}
        render={({ field }) => (
          <Field
            className={classes.setUpAccountFormInput}
            title="Company name"
            data={{
              id: field.name,
              value: field.value,
              name: field.name,
              onChange: field.onChange,
              placeholder: 'Enter your company name (optional)',
              error: companyNameErrorMessage,
            }}
          />
        )}
      />
      <Controller
        name="industry"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <IndustrySelectField
            className={classes.setUpAccountFormInput}
            title="Your industry"
            placeholder="Choose your industry (optional)"
            onSelect={field.onChange}
            activeIndustryKey={industry}
          />
        )}
      />
      <Controller
        name="baseCurrency"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <CurrenciesSelectField
            className={classes.setUpAccountFormInput}
            label="Base currency"
            currencies={currencies}
            defaultTicker={currentBaseCurrencyCode}
            onSelect={handleBaseCurrencySelect(field.onChange)}
            smallTheme
            withoutOtherCurrencies
          />
        )}
      />
      <Controller
        name="feePaidBy"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <SimpleSelectField
            className={classes.setUpAccountFormInput}
            title="Withdrawal fee paid by"
            data={{
              items: WITHDRAWAL_FEE_PAID_BY_ITEMS,
              onSelect: field.onChange,
              defaultValue: currentFeePaidBy,
            }}
          />
        )}
      />
      <Button
        className={classes.setUpAccountFormButton}
        type="submit"
      >
        Continue
      </Button>
    </form>
  );
};
