import React from 'react';
import { useDispatch } from 'react-redux';
import { setStoreData } from '../../../../store/payment-settings/reducer';
import { getSafeErrorMessageText } from '../../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../../helpers/console-error-message';
import { useUpdateStorePaymentSettings } from '../../../../api/modules/account/use-update-store-payment-setting';
import { PaymentDetailsField } from '../payment-details-field';
import { PaymentDetailsRadioButtons } from '../payment-details-radio-buttons';
import { isFunction } from '../../../../helpers/is-function';
import { WITHDRAWAL_FEE_PAID_BY } from '../../../../constants/app-constants';
import { ResponseType, StorePaymentSettingsParams } from '../../../../default-types';

type PaymentDetailsFeesProps = {
  isFeeOptimisationEnable: boolean | null,
  feePaidBy: string | null,
  markup: string,
  covering: string,
};

const inputRegex = /^[0-9]*\.?[0-9]{0,2}$/;
const MAX_VALUE = 10;
const DEFAULT_VALUE = '0.00';

export const PaymentDetailsFees: React.FC<PaymentDetailsFeesProps> = (props) => {
  const {
    isFeeOptimisationEnable,
    feePaidBy,
    markup,
    covering,
  } = props;

  const fetchUpdateStorePaymentSettings = useUpdateStorePaymentSettings();
  const dispatch = useDispatch();
  const isOptimizeActive = isFeeOptimisationEnable === true;
  const isDoNotOptimizeActive = isFeeOptimisationEnable === false;
  const receiverData = WITHDRAWAL_FEE_PAID_BY.RECEIVER;
  const senderData = WITHDRAWAL_FEE_PAID_BY.RECEIVER_EXCLUDED;
  const isReceiverActive = feePaidBy === receiverData.key;
  const isSenderActive = feePaidBy === senderData.key;

  const [nextMarkup, setNextMarkup] = React.useState<string | null>(null);
  const [nextCovering, setNextCovering] = React.useState<string | null>(null);
  const [isMarkupError, setIsMarkupError] = React.useState(false);
  const [isCoveringError, setIsCoveringError] = React.useState(false);
  const [isMarkupSaved, setIsMarkupSaved] = React.useState(false);
  const [isCoveringSaved, setIsCoveringSaved] = React.useState(false);
  const [isMarkupLoading, setIsMarkupLoading] = React.useState(false);
  const [isCoveringLoading, setIsCoveringLoading] = React.useState(false);
  const [isFeeOptimizationLoading, setIsFeeOptimizationLoading] = React.useState(false);
  const [isFeePaidByLoading, setIsFeePaidByLoading] = React.useState(false);
  const [isFeeOptimizationApiError, setIsFeeOptimizationApiError] = React.useState(false);
  const [isFeePaidByApiError, setIsFeePaidByApiError] = React.useState(false);

  const updateStore = async (
    options: StorePaymentSettingsParams,
    onLoading: (isLoading: boolean) => void,
    onUpdate?: (() => void) | null,
    onError?: () => void,
  ) => {
    onLoading(true);

    const {
      data,
      status,
      errorMessage,
    }: ResponseType = await fetchUpdateStorePaymentSettings(options);

    onLoading(false);

    if (status === 200 && data) {
      dispatch(setStoreData({ ...data }));

      if (onUpdate && isFunction(onUpdate)) {
        onUpdate();
      }
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/store-payment-settings');
      if (onError && isFunction(onError)) {
        onError();
      }
    }
  };

  const handleFeeOptimisationChange = async () => {
    await updateStore(
      { feeOptimization: !isFeeOptimisationEnable },
      setIsFeeOptimizationLoading,
      null,
      () => setIsFeeOptimizationApiError(true),
    );
  };

  const handleFeePaidByChange = async () => {
    const nextFeePaidBy = isReceiverActive
      ? senderData.key
      : receiverData.key;
    await updateStore(
      { withdrawalFeePaidBy: nextFeePaidBy },
      setIsFeePaidByLoading,
      null,
      () => setIsFeePaidByApiError(true),
    );
  };

  const saveMarkup = async () => {
    if (Number(nextMarkup) === Number(markup) || nextMarkup === null) return;

    await updateStore(
      { markup: nextMarkup || DEFAULT_VALUE },
      setIsMarkupLoading,
      () => setIsMarkupSaved(true),
    );
  };

  const saveCovering = async () => {
    if (Number(nextCovering) === Number(covering) || nextCovering === null) return;

    await updateStore(
      { covering: nextCovering || DEFAULT_VALUE },
      setIsCoveringLoading,
      () => setIsCoveringSaved(true),
    );
  };

  const handleChangeMarkupInput = (value: string) => {
    if (!inputRegex.test(value)) return;

    setNextMarkup(value);
    if (Number(value) > MAX_VALUE) {
      setIsMarkupError(true);
      setNextMarkup(String(MAX_VALUE));
    }
  };

  const handleChangeCoveringInput = (value: string) => {
    if (!inputRegex.test(value)) return;

    setNextCovering(value);
    if (Number(value) > MAX_VALUE) {
      setIsCoveringError(true);
      setNextCovering(String(MAX_VALUE));
    }
  };

  React.useEffect(() => {
    const timerId = setTimeout(() => {
      setIsMarkupError(false);
      setIsCoveringError(false);
      setIsMarkupSaved(false);
      setIsCoveringSaved(false);
      setIsFeeOptimizationApiError(false);
      setIsFeePaidByApiError(false);
    }, 2000);

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [
    isMarkupError,
    isCoveringError,
    isMarkupSaved,
    isCoveringSaved,
    isFeeOptimizationApiError,
    isFeePaidByApiError,
  ]);

  return (
    <ul>
      <li>
        <h3>Network fee optimisation</h3>
        <PaymentDetailsRadioButtons
          firstRadioData={{
            title: 'Optimize',
            isActive: isOptimizeActive,
            description: 'System automatically chooses between your outcome wallets based on the lowest network fee.',
            value: 'optimize',
          }}
          secondRadioData={{
            title: 'Do not optimize',
            isActive: isDoNotOptimizeActive,
            description: 'System sends the payment to the wallet of the currency sent or, if not added, to the first one in your Wallets list.',
            value: 'not-optimize',
          }}
          onChange={handleFeeOptimisationChange}
          name="fee-optimisation"
          isLoading={isFeeOptimizationLoading}
          isError={isFeeOptimizationApiError}
        />
      </li>
      <li>
        <h3>Payment markup</h3>
        <p>
          The markup percentage over the initial price. For example, if you set a 10% markup for $100 item,
          your client will be billed $110.
        </p>
        <PaymentDetailsField
          value={nextMarkup === null ? markup : nextMarkup}
          onChange={handleChangeMarkupInput}
          onSave={saveMarkup}
          isError={isMarkupError}
          infoText="Maximum markup is 10%"
          isSaved={isMarkupSaved}
          isLoading={isMarkupLoading}
        />
      </li>
      <li>
        <h3>Payment covering</h3>
        <p>
          The payment percentage that needs to be paid to be considered completed. For example, with a 5% setting,
          a $100 item is fully paid if the customer transfers $95 in crypto.
        </p>
        <PaymentDetailsField
          value={nextCovering === null ? covering : nextCovering}
          onChange={handleChangeCoveringInput}
          onSave={saveCovering}
          isError={isCoveringError}
          infoText="Maximum covering is 10%"
          isSaved={isCoveringSaved}
          isLoading={isCoveringLoading}
        />
      </li>
      <li>
        <h3>Withdrawal fee paid by</h3>
        <PaymentDetailsRadioButtons
          firstRadioData={{
            title: receiverData.value,
            isActive: isReceiverActive,
            description: 'Fee paid from withdrawal amount.',
            value: receiverData.key,
          }}
          secondRadioData={{
            title: senderData.value,
            isActive: isSenderActive,
            description: 'Fee paid separately from the withdrawal amount and deducted from your balance.',
            value: senderData.key,
          }}
          onChange={handleFeePaidByChange}
          name="fee-paid-by"
          isLoading={isFeePaidByLoading}
          isError={isFeePaidByApiError}
        />
      </li>
    </ul>
  );
};
