import React from 'react';
import PropTypes from 'prop-types';

export const DotsIcon = React.memo((props) => {
  const { color } = props;

  return (
    <svg
      width="4"
      height="15"
      viewBox="0 0 4 15"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 3C1.17157 3 0.5 2.32843 0.5 1.5C0.5 0.671573 1.17157 0 2 0C2.82843 0 3.5 0.671573 3.5 1.5C3.5 2.32843 2.82843 3 2 3Z" />
      <path d="M2 9C1.17157 9 0.5 8.32843 0.5 7.5C0.5 6.67157 1.17157 6 2 6C2.82843 6 3.5 6.67157 3.5 7.5C3.5 8.32843 2.82843 9 2 9Z" />
      <path d="M2 15C1.17157 15 0.5 14.3284 0.5 13.5C0.5 12.6716 1.17157 12 2 12C2.82843 12 3.5 12.6716 3.5 13.5C3.5 14.3284 2.82843 15 2 15Z" />
    </svg>
  );
});

DotsIcon.defaultProps = {
  color: '#9C9CA7',
};

DotsIcon.propTypes = {
  color: PropTypes.string,
};
DotsIcon.displayName = DotsIcon;
