import { getDateIsoString } from './get-date-iso-string';

/**
 * @param {Object|String} date
 * @param {String} representation
 * @returns {number}
 */
export const getDateTime = (date = null, representation) => {
  const dateISO = getDateIsoString(date, representation);

  return  new Date(dateISO).getTime();
};
