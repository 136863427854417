import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hasElementsInArray } from '../helpers/has-elements-in-array';
import { pageNamesToDisplayInfoSelector } from '../store/settings/selectors';
import { setPageNamesToDisplayInfo } from '../store/settings/reducer';
import { setItemToLocalStorage } from '../libs/local-storage';
import { PAGE_NAMES_TO_DISPLAY_INFO_LOCAL_STORAGE_NAME } from '../constants/app-constants';

export const usePageNamesToDisplayInfo = (name) => {
  const dispatch = useDispatch();
  const pageNamesToDisplayInfo = useSelector(pageNamesToDisplayInfoSelector());

  const addPageNameToDisplayInfo = React.useCallback(() => {
    if (pageNamesToDisplayInfo.includes(name)) {
      return;
    }

    const nextPageNamesToDisplayInfo = [...pageNamesToDisplayInfo, name];
    const nextPageNamesToDisplayInfoString = JSON.stringify(nextPageNamesToDisplayInfo);
    dispatch(setPageNamesToDisplayInfo(nextPageNamesToDisplayInfo));
    setItemToLocalStorage(PAGE_NAMES_TO_DISPLAY_INFO_LOCAL_STORAGE_NAME, nextPageNamesToDisplayInfoString);
  }, [pageNamesToDisplayInfo, name]);

  const removePageNameToDisplayInfo = React.useCallback(() => {
    if (!hasElementsInArray(pageNamesToDisplayInfo)) {
      return;
    }

    const pageNamesToDisplayInfoFiltered = pageNamesToDisplayInfo.filter((item) => item !== name);
    const pageNamesToDisplayInfoString = JSON.stringify(pageNamesToDisplayInfoFiltered);
    dispatch(setPageNamesToDisplayInfo(pageNamesToDisplayInfoFiltered));
    setItemToLocalStorage(PAGE_NAMES_TO_DISPLAY_INFO_LOCAL_STORAGE_NAME, pageNamesToDisplayInfoString);
  }, [pageNamesToDisplayInfo, name]);

  const infoBoxShow = React.useMemo(() => (
    pageNamesToDisplayInfo.includes(name)
  ), [pageNamesToDisplayInfo]);

  return {
    infoBoxShow,
    addPageNameToDisplayInfo,
    removePageNameToDisplayInfo,
  };
};
