import React from 'react';
import PropTypes from 'prop-types';

export const PayoutWalletsIcon = (props) => {
  const { color } = props;

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.58314 7.12553V8.87548C8.58314 9.3396 8.76751 9.78471 9.09569 10.1129C9.42387 10.4411 9.86897 10.6254 10.3331 10.6254H14.4163C14.6156 10.6267 14.8133 10.5911 14.9996 10.5204V11.7921C14.9996 12.2562 14.8153 12.7013 14.4871 13.0295C14.1589 13.3577 13.7138 13.542 13.2497 13.542H2.74995C2.28584 13.542 1.84073 13.3577 1.51255 13.0295C1.18437 12.7013 1 12.2562 1 11.7921V4.20894C1 3.74482 1.18437 3.29971 1.51255 2.97153C1.84073 2.64335 2.28584 2.45898 2.74995 2.45898H13.2497C13.7138 2.45898 14.1589 2.64335 14.4871 2.97153C14.8153 3.29971 14.9996 3.74482 14.9996 4.20894V5.48057C14.8133 5.40991 14.6156 5.37431 14.4163 5.37558H10.3331C9.86897 5.37558 9.42387 5.55995 9.09569 5.88813C8.76751 6.21631 8.58314 6.66141 8.58314 7.12553Z" />
      <path d="M14.4165 6.54297H10.3333C10.1786 6.54297 10.0302 6.60443 9.92085 6.71382C9.81146 6.82321 9.75 6.97158 9.75 7.12629V8.87624C9.75 9.03095 9.81146 9.17932 9.92085 9.28871C10.0302 9.3981 10.1786 9.45956 10.3333 9.45956H14.4165C14.5713 9.45956 14.7196 9.3981 14.829 9.28871C14.9384 9.17932 14.9999 9.03095 14.9999 8.87624V7.12629C14.9999 6.97158 14.9384 6.82321 14.829 6.71382C14.7196 6.60443 14.5713 6.54297 14.4165 6.54297ZM11.7916 8.87624C11.6186 8.87624 11.4494 8.82492 11.3055 8.72878C11.1616 8.63264 11.0495 8.49598 10.9832 8.3361C10.917 8.17622 10.8997 8.00029 10.9334 7.83056C10.9672 7.66084 11.0505 7.50493 11.1729 7.38256C11.2953 7.26019 11.4512 7.17686 11.6209 7.1431C11.7906 7.10934 11.9666 7.12667 12.1265 7.19289C12.2863 7.25912 12.423 7.37126 12.5191 7.51515C12.6153 7.65904 12.6666 7.82821 12.6666 8.00126C12.6666 8.23332 12.5744 8.45588 12.4103 8.61997C12.2462 8.78406 12.0237 8.87624 11.7916 8.87624Z" />
    </svg>
  );
};

PayoutWalletsIcon.defaultProps = {
  color: '#A4A4A4',
};

PayoutWalletsIcon.propTypes = {
  color: PropTypes.string,
};
