import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import classes from './styles.module.scss';
import { ValueFromStatisticType } from './type';
import { LoaderIcon } from '../../../icons/loader-icon';
import { CurrencyNetworkSup } from '../../../shared/currency-network-sup';

export const ValueFromStatistic: React.FC<ValueFromStatisticType> = (props) => {
  const {
    className = null,
    title,
    amount,
    ticker,
    currency = null,
    network = null,
    tickersShow = false,
    fetching = false,
    href = null,
    text = null,
  } = props;

  return (
    <div className={cn([
      classes.valueFromStatistic,
      className,
    ])}
    >
      <div className={classes.valueFromStatisticTitle}>
        {title}
        {tickersShow && (
          <div className={classes.valueFromStatisticTickers}>
            {currency && (
              <span className={classes.valueFromStatisticTicker}>
                {currency}
              </span>
            )}
            {network && (
              <CurrencyNetworkSup network={network} />
            )}
          </div>
        )}
      </div>
      {!text && (
        <div className={classes.valueFromStatisticAmount}>
          {fetching && (
            <LoaderIcon size={25} />
          )}
          {!fetching && (
            <>
              {amount}
              {' '}
              <span className={classes.valueFromStatisticAmountTicker}>
                {ticker}
              </span>
            </>
          )}
        </div>
      )}
      {text && (
        <div className={classes.valueFromStatisticText}>
          {text}
        </div>
      )}
      {href && (
        <Link to={href} className={classes.valueFromStatisticLink}>
          <img
            src="/images/dashboard-link-arrow.svg"
            alt="Icon"
            decoding="async"
            loading="lazy"
            width={20}
            height={20}
          />
        </Link>
      )}
    </div>
  );
};
