import React from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { ExtendedWallet } from '../../types';
import { LogoTickerNetwork } from '../../../logo-ticker-network';
import { Field } from '../../../ui/field';
import { Button } from '../../../ui/button';
import { safeToUpperCase } from '../../../../helpers/safe-to-upper-case';
import { getSafeErrorMessageText } from '../../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../../helpers/console-error-message';
import { useUpdateStoreOutcomeWallet } from '../../../../api/modules/account/use-update-store-outcome-wallet';
import { LoaderIcon } from '../../../icons/loader-icon';
import { updateWallet } from '../../../../store/payment-settings/reducer';
import { ResponseType } from '../../../../default-types';
import classes from './styles.module.scss';

type EditWalletFormProps = {
  activeWallet: ExtendedWallet;
  onClose: () => void;
};

export const EditWalletForm: React.FC<EditWalletFormProps> = (props) => {
  const {
    activeWallet,
    onClose,
  } = props;

  const walletReqEx = activeWallet.walletRegex;
  const memoReqEx = activeWallet.extraIdRegex;
  const fetchUpdateStoreOutcomeWallet = useUpdateStoreOutcomeWallet();
  const dispatch = useDispatch();

  const [address, setAddress] = React.useState<string>(activeWallet.baseAddress);
  const [memo, setMemo] = React.useState<string | null>(activeWallet.extraId);
  const [isAddressValid, setIsAddressValid] = React.useState(true);
  const [isMemoValid, setIsMemoValid] = React.useState(true);
  const [addressError, setAddressError] = React.useState<string | null>(null);
  const [memoError, setMemoError] = React.useState<string | null>(null);
  const [isFetching, setIsFetching] = React.useState(false);
  const [apiError, setApiError] = React.useState<string | null>(null);

  const handleAddressChange = (value: string) => {
    setAddress(value);
    setAddressError(null);

    if (walletReqEx) {
      const isValid = value.match(walletReqEx);

      if (isValid) {
        setIsAddressValid(true);
      } else {
        setIsAddressValid(false);
      }
    }
  };
  const handleMemoChange = (value: string) => {
    setMemo(value);
    setMemoError(null);

    if (memoReqEx && value) {
      const isValid = value.match(memoReqEx);

      if (isValid) {
        setIsMemoValid(true);
      } else {
        setIsMemoValid(false);
      }
    }
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!address) {
      setAddressError('Address is required field');
      return;
    }
    if (activeWallet.extraId && !memo) {
      setMemoError('Memo is required field');
      return;
    }

    if (address !== activeWallet.baseAddress || memo !== activeWallet.extraId) {
      if (!isAddressValid) {
        setAddressError('Invalid address');
        return;
      }
      if (activeWallet.extraId && !isMemoValid) {
        setMemoError('Invalid Memo');
        return;
      }

      setIsFetching(true);

      const {
        data,
        status,
        errorMessage,
      }: ResponseType = await fetchUpdateStoreOutcomeWallet({
        outcomeCurrency: safeToUpperCase(activeWallet.baseCurrency),
        outcomeAddress: address,
        eid: memo,
      });

      if (status === 200) {
        dispatch(updateWallet({
          id: activeWallet.id,
          baseAddress: address,
          extraId: memo,
        }));
        onClose();
      } else {
        const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
        consoleErrorMessage(errorMessage, errorDataMessage, '/store-outcome-wallet');
        setApiError(errorDataMessage);
      }

      setIsFetching(false);
    } else {
      onClose();
    }
  };

  return (
    <form className={cn(classes.editWalletForm, classes.form)} onSubmit={handleFormSubmit}>
      <LogoTickerNetwork
        className={classes.editWalletFormCurrency}
        currentTicker={activeWallet.currentTicker}
        ticker={activeWallet.ticker}
        logoUrl={activeWallet.logoUrl}
        network={activeWallet.network}
        logoSize={24}
      />
      <Field
        className={classes.editWalletFormInput}
        title="Edit wallet address"
        data={{
          type: 'text',
          value: address,
          onChange: handleAddressChange,
          error: addressError,
        }}
      />
      {activeWallet.extraId && (
        <Field
          className={classes.editWalletFormInput}
          title="Edit memo"
          data={{
            type: 'text',
            value: memo,
            onChange: handleMemoChange,
            error: memoError,
          }}
        />
      )}
      <div className={classes.editWalletFormApiError}>
        {apiError}
      </div>
      <div className={classes.editWalletFormButtons}>
        <Button
          className={classes.editWalletFormButton}
          type="submit"
          disabled={isFetching}
        >
          {
            isFetching
              ? <LoaderIcon path="/images/loader-white-on-blue-icon.gif" />
              : 'Save changes'
          }
        </Button>
        <Button
          className={classes.editWalletFormButton}
          displayBorder
          onClick={onClose}
        >
          Close
        </Button>
      </div>
    </form>
  );
};
