import React from 'react';

export const lazyWithRetry = (componentImport) => React.lazy(async () => {
  const pageHasAlreadyBeenForceRefreshed =
    JSON.parse(window.sessionStorage.getItem('page-has-been-force-refreshed') || false);

  try {
    const component = await componentImport();
    window.sessionStorage.setItem(
      'page-has-been-force-refreshed',
      'false',
    );

    return component;
  } catch (error) {
    if (!pageHasAlreadyBeenForceRefreshed) {
      window.sessionStorage.setItem(
        'page-has-been-force-refreshed',
        'true',
      );

      return window.location.reload();
    }

    throw error;
  }
});
