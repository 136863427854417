import React from 'react';
import { useDispatch } from 'react-redux';
import { getSafeErrorMessageText } from '../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../helpers/console-error-message';
import { isObject } from '../helpers/is-object';
import { checkErrorCode } from '../helpers/check-error-code';
import { useSubscriptionsPlans } from '../api/modules/account/use-subscriptions-plans';
import {
  setAllPlans,
  setAllPlansError,
  setAllPlansFetched,
  setAllPlansFetching,
} from '../store/customer-management/reducer';

export const useAllSubscriptionPlans = () => {
  const dispatch = useDispatch();
  const fetchSubscriptionsPlans = useSubscriptionsPlans();

  const reFetchPlans = React.useCallback(async () => {
    dispatch(setAllPlansError(null));
    dispatch(setAllPlansFetching(true));
    const { data, status, errorMessage } = await fetchSubscriptionsPlans();
    if (status === 200 && isObject(data)) {
      const items = data?.result ?? null;
      const count = data?.count ?? null;
      dispatch(setAllPlans({ items, count }));
      dispatch(setAllPlansFetching(false));
      dispatch(setAllPlansFetched(true));
    } else {
      checkErrorCode(data?.errorData?.code);
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/subscriptions/plans');
      dispatch(setAllPlansError(errorDataMessage));
      dispatch(setAllPlansFetching(false));
    }
  }, []);

  return { reFetchAllSubscriptionPlans: reFetchPlans };
};
