export const formValidation = (name, desc, secret, permissions, redirectUrl) => {
  let errors = {};

  if (!name.length) {
    errors.name = 'Please enter name';
  }

  if (!desc.length) {
    errors.description = 'Please enter description';
  }

  if (!secret.length) {
    errors.secret = 'Please enter secret key';
  }

  if (!Object.keys(permissions).length) {
    errors.permissions = 'Please enter permissions';
  }

  if (!redirectUrl.length) {
    errors.link = 'Please enter redirect url';
  }

  if (Object.keys(errors).length) {
    return { isValid: false, errors };
  }

  return { isValid: true, errors };
};
