import React from 'react';
import { Link } from 'react-router-dom';
import { Popup } from '../../../popup';
import { Button } from '../../../ui/button';
import { Portal } from '../../../portal';
import { ROUTES } from '../../../../constants/routes';
import classes from './styles.module.scss';

export const DashboardNotificationPopup: React.FC<{ open: boolean, onClose: () => void }> = (props) => {
  const { open, onClose } = props;

  return (
    <Portal>
      <Popup
        classNameContent={classes.popup}
        open={open}
        onClose={onClose}
      >
        <div className={classes.notification}>
          You entered backup code and your authentication has been reset. If you want to enable it
          again, go to
          {' '}
          <Link to={ROUTES.ACCOUNT_SETTINGS}>Account Settings</Link>
          .
          <Button onClick={onClose}>
            Ok
          </Button>
        </div>
      </Popup>
    </Portal>
  );
};
