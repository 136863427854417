import * as React from 'react';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { useNumberOfActiveFilters } from '../../../hooks/use-number-of-active-filters';

export const usePaymentsPageFilterState = (reFetchPayments) => {
  const isDesktop = useMediaQuery('(min-width: 992px)');

  const [searchText, setSearchText] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [filterData, setFilterData] = React.useState(null);

  const handleFilterReset = React.useCallback(async () => {
    if (!filterData) {
      return;
    }

    setFilterData(null);

    await reFetchPayments(1, searchText, null);
    setPage(1);
  }, [filterData, searchText]);
  const handleFilterSubmit = React.useCallback(async (data, setIsFilterActive) => {
    const hasData = Object.values(data).some((item) => Boolean(item));

    if (!hasData) {
      return;
    }

    setFilterData(data);
    if (!isDesktop) {
      setIsFilterActive(false);
    }
    await reFetchPayments(1, searchText, data);
    setPage(1);
  }, [isDesktop, searchText]);

  const numberOfActiveFilters = useNumberOfActiveFilters(filterData);

  return {
    searchText,
    setSearchText,
    page,
    setPage,
    filterData,
    numberOfActiveFilters,
    handleFilterReset,
    handleFilterSubmit,
  };
};
