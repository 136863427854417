import { Provider } from '../types';

export const getSplitProvidersByStatus = (providers: Array<Provider>) => {
  const activatedProviders: Array<Provider> = [];
  const availableProviders: Array<Provider> = [];
  providers.forEach((provider) => {
    if (provider.isActivated) {
      activatedProviders.push(provider);
    } else {
      availableProviders.push(provider);
    }
  });

  return { activatedProviders, availableProviders };
};
