import React from 'react';
import { useSelector } from 'react-redux';
import { PagesWrapper } from './components/pages-wrapper';
import { ProvidersSection } from './components/providers-section';
import { getFilteredCurrenciesByAddedAccounts, OFF_RAMP } from './helpers';
import { FaqSection } from '../shared/faq-section';
import { PermissionPopup } from '../permision-popup';
import { AddAccountSection } from './components/add-account-section';
import { AddAccountPopup } from './components/add-account-popup';
import { partnerFetchingSelector, partnerSelector } from '../../store/partner/selectors';
import { useFetchPartnerProfile } from '../../api/modules/account/use-fetch-partner-profile';
import { useBankAccounts } from '../../hooks/use-bank-accounts';
import { useProviderStatuses } from '../../hooks/use-providers-statuses';
import { useFiatOffRampCurrencies } from '../../hooks/use-fiat-off-ramp-currencies';
import { PERMISSION_TO_PROCESS_DATA } from '../../constants/providers-constants';

export const OffRampPage = () => {
  const [isPermissionPopupOpen, setIsPermissionPopupOpen] = React.useState(false);
  const [activationLinkInPopup, setActivationLinkInPopup] = React.useState('');
  const [isAddAccountPopupOpen, setIsAddAccountPopupOpen] = React.useState(false);
  const [isAccountsUpdate, setIsAccountsUpdate] = React.useState(true);
  const { accounts, isAccountsLoading } = useBankAccounts(isAccountsUpdate);
  const fiatCurrencies = useFiatOffRampCurrencies();
  const partner = useSelector(partnerSelector());
  const partnerFetching = useSelector(partnerFetchingSelector());
  const isOfframpEnabled = partner?.isOffRampEnabled;
  const providersWithStatus = useProviderStatuses(OFF_RAMP.cards);
  const partnerEmail = partner?.email || '';

  const filteredFiatCurrencies = React.useMemo(() => (
    getFilteredCurrenciesByAddedAccounts(fiatCurrencies, accounts)
  ), [fiatCurrencies, accounts]);

  const handleActiveButtonClick = (link: string) => {
    setActivationLinkInPopup(link);
    setIsPermissionPopupOpen(true);
  };
  const handlePermissionPopupClose = () => {
    setIsPermissionPopupOpen(false);
  };
  const handleAddAccountPopupOpen = () => {
    setIsAddAccountPopupOpen(true);
  };
  const handleAddAccountPopupClose = () => {
    setIsAddAccountPopupOpen(false);
  };

  useFetchPartnerProfile();

  return (
    <PagesWrapper>
      {isOfframpEnabled && (
        <AddAccountSection
          onClick={handleAddAccountPopupOpen}
          accounts={accounts}
          isLoading={isAccountsLoading}
        />
      )}
      <ProvidersSection
        description="Empower your customers to make payments in crypto and get profit in traditional fiat, mitigating market volatility. Submit a request to activate fiat off-ramp providers."
        link="https://nowpayments.notion.site/Off-ramp-Payment-Providers-Fees-Other-Details-d6fd8471035141f08a55631c0e705a85?pvs=4"
        providers={providersWithStatus}
        isLoading={partnerFetching}
        onActiveButtonClick={handleActiveButtonClick}
        partnerEmail={partnerEmail}
      />
      <FaqSection faqData={OFF_RAMP.faq} />
      <PermissionPopup
        open={isPermissionPopupOpen}
        onClose={handlePermissionPopupClose}
        activationLink={activationLinkInPopup}
        title={PERMISSION_TO_PROCESS_DATA.title}
        description={PERMISSION_TO_PROCESS_DATA.text}
        buttonLabel="Process"
      />
      <AddAccountPopup
        open={isAddAccountPopupOpen}
        onClose={handleAddAccountPopupClose}
        onUpdate={setIsAccountsUpdate}
        fiatCurrencies={filteredFiatCurrencies}
      />
    </PagesWrapper>
  );
};
