import loader from '../../images/svg/small-loader.svg';
import styles from './styles.module.scss';
export default function SmallLoader({ width = 16, height = 16 }) {
  return (
    <img
      src={loader}
      alt="Loading..."
      className={styles.loader}
      width={width}
      height={height}
    />
  );
}
