import React from 'react';
import cn from 'classnames';
import { SettingSectionProps } from '../../types';
import { ApiKeysSettings } from '../../../api-keys-settings';

export const ApiKeysSection: React.FC<SettingSectionProps> = (props) => {
  const {
    className,
    classNameActive,
  } = props;

  const [isRendered, setIsRendered] = React.useState(false);

  React.useEffect(() => {
    setIsRendered(true);
  }, []);

  return <ApiKeysSettings className={cn(className, isRendered && classNameActive)} />;
};
