import React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';

const mapFn = (data: {
  status: string | null,
  result: null | {
    address: string | null,
  },
  error: string | null,
}) => ({
  status: data?.status ?? null,
  result: {
    address: data?.result?.address ?? null,
  },
  error: data?.error ?? null,
});

export const useCreateDepositAddress = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (currency: string) => {
    const [cancelToken] = getRequestSource();
    const requestPromise = client.post(
      '/deposits/address',
      { currency },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
