import React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';

export const useCreateInvoice = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (options) => {
    const price_amount = options?.priceAmount ?? '';
    const price_currency = options?.priceCurrency ?? '';
    const payout_currency = options?.payoutCurrency ?? null;
    const pay_currency = options?.payCurrency ?? '';
    const order_id = options?.orderId ?? '';
    const order_description = options?.orderDescription ?? '';
    const iid = options?.iid ?? null;
    const ipn_callback_url = options?.callbackUrl ?? null;
    const success_url = options?.successUrl ?? null;
    const cancel_url = options?.cancelUrl ?? null;
    const is_fixed_rate = options?.isFixedRate ?? false;
    const is_fee_paid_by_user = options?.isFeePaidByUser ?? false;
    const [cancelToken] = getRequestSource();
    const requestPromise = client.post(
      '/invoices',
      {
        price_amount,
        price_currency,
        pay_currency,
        payout_currency,
        order_id,
        order_description,
        iid,
        ipn_callback_url,
        success_url,
        cancel_url,
        is_fixed_rate,
        is_fee_paid_by_user,
      },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      (data) => data,
    );
  }, [getRequestSource]);
};
