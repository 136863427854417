import * as React from 'react';
import { OnboardingFormStepIconPropsType } from '../../../../types';

export const GetStartedStep: React.FC<OnboardingFormStepIconPropsType> = (props) => {
  const { isActive } = props;

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13087_53930)">
        <circle
          cx="16"
          cy="16"
          r="15.5"
          fill="white"
          stroke={isActive ? '#64ACFF' : '#D2D2D2'}
        />
        <path
          d="M22.7045 11.0005C22.3775 11.0101 22.067 11.1475 21.8388 11.3836L14.2376 19.0385L10.0796 15.7229C9.95084 15.6117 9.80111 15.5277 9.63937 15.4762C9.47763 15.4246 9.30722 15.4065 9.13836 15.4229C8.9695 15.4393 8.80567 15.4899 8.65671 15.5717C8.50774 15.6534 8.37672 15.7647 8.2715 15.8987C8.16628 16.0327 8.08903 16.1867 8.04438 16.3515C7.99974 16.5164 7.98863 16.6886 8.01171 16.8578C8.03479 17.0271 8.09159 17.1899 8.17871 17.3365C8.26582 17.4831 8.38144 17.6105 8.51864 17.711L13.5555 21.7267C13.7978 21.9196 14.1021 22.0161 14.4104 21.9978C14.7188 21.9795 15.0097 21.8478 15.2279 21.6276L23.6227 13.1735C23.8034 12.9949 23.9264 12.7656 23.9758 12.5156C24.0252 12.2655 23.9986 12.0063 23.8994 11.7717C23.8003 11.537 23.6333 11.338 23.4201 11.2004C23.2069 11.0629 22.9576 10.9932 22.7045 11.0005Z"
          fill={isActive ? '#64ACFF' : '#D2D2D2'}
        />
      </g>
      <defs>
        <clipPath id="clip0_13087_53930">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
