import * as React from 'react';
import { usePaymentsByInvoiceId } from '../../../api/modules/account/use-payments-by-invoice-id';
import { usePaymentFinishId } from '../../../api/modules/account/use-payment-finish-id';
import { usePayment } from '../../../api/modules/account/use-payment';

export const PaymentsStateContext = React.createContext();
export const PaymentsActionsContext = React.createContext();

const useContextValueFactory = (context) => () => {
  const contextValue = React.useContext(context);

  if (!contextValue) {
    throw new Error('Missing payments context value');
  }

  return contextValue;
};

export const usePaymentsActions = useContextValueFactory(PaymentsActionsContext);
export const usePaymentsState = useContextValueFactory(PaymentsStateContext);

export const PaymentsContextProvider = (props) => {
  const [arePaymentsLoading, setArePaymentsLoading] = React.useState(false);
  const [isChangingPaymentStatus, setIsChangingPaymentStatus] = React.useState(false);
  const [payments, setPayments] = React.useState([]);
  const fetchPaymentsByInvoiceId = usePaymentsByInvoiceId();
  const putPaymentToFinished = usePaymentFinishId();
  const fetchPaymentById = usePayment();

  const loadPaymentsByInvoiceId = React.useCallback(async (invoiceId) => {
    if (!invoiceId) {
      console.log('Missing invoice id');

      return;
    }

    setArePaymentsLoading(true);
    const { data = [] } = await fetchPaymentsByInvoiceId(invoiceId);
    setPayments(data);
    setArePaymentsLoading(false);
  }, [setArePaymentsLoading, fetchPaymentsByInvoiceId, setPayments]);

  const resetPayments = React.useCallback(() => {
    setPayments([]);
  }, []);

  const updatePaymentInCollection = React.useCallback((paymentObj) => {
    if (!paymentObj) {
      return;
    }

    const paymentsIndex = payments.findIndex(payment => payment.id === paymentObj?.id);

    if (paymentsIndex !== -1) {
      const newPayments = [...payments];
      newPayments.splice(paymentsIndex, 1, paymentObj);
      setPayments(newPayments);
    }
  }, [payments, setPayments]);

  const setFinishedStatusToPayment = React.useCallback(async (paymentId) => {
    if (!paymentId) {
      return;
    }

    setIsChangingPaymentStatus(true);
    const { data, status: responseStatus } = await putPaymentToFinished({ id: paymentId });
    setIsChangingPaymentStatus(false);

    if (responseStatus !== 200 && !data) {
      return {};
    }

    const {
      status: paymentStatus = null,
      exchange_data = {},
      updated_at = null,
    } = data;
    const paymentPartialUpdate = { status: paymentStatus, exchange_data, updated_at };
    const payment = payments.find(p => p.id === paymentId);

    if (payment) {
      updatePaymentInCollection({ ...payment, ...paymentPartialUpdate });
    }

    return paymentPartialUpdate;
  }, [payments, setIsChangingPaymentStatus, putPaymentToFinished, updatePaymentInCollection]);

  const updatePaymentById = React.useCallback(async (paymentId) => {
    if (!payments.find(({ id }) => id === paymentId)) {
      return;
    }

    const paymentResult = await fetchPaymentById(paymentId);
    updatePaymentInCollection(paymentResult);
  }, [payments, fetchPaymentById, updatePaymentInCollection]);

  const stateContextValue = React.useMemo(() => ({
    payments,
    arePaymentsLoading,
    isChangingPaymentStatus,
  }), [
    payments,
    arePaymentsLoading,
    isChangingPaymentStatus,
  ]);

  const actionsContextValue = React.useMemo(() => ({
    loadPaymentsByInvoiceId,
    setFinishedStatusToPayment,
    updatePaymentById,
    updatePaymentInCollection,
    resetPayments,
  }), [
    loadPaymentsByInvoiceId,
    setFinishedStatusToPayment,
    updatePaymentById,
    updatePaymentInCollection,
    resetPayments,
  ]);

  return (
    <PaymentsActionsContext.Provider value={actionsContextValue}>
      <PaymentsStateContext.Provider value={stateContextValue}>
        {props.children}
      </PaymentsStateContext.Provider>
    </PaymentsActionsContext.Provider>
  );
};
