import React from 'react';
import { useCountries } from '../api/modules/account/use-countries';
import { getSafeErrorMessageText } from "../helpers/get-safe-error-message-text";
import { consoleErrorMessage } from "../helpers/console-error-message";

export const useCountryList = () => {
  const [countryList, setCountryList] = React.useState([]);
  const fetchCountriesRequestSend = useCountries();

  React.useEffect(() => {
    const fetchCountries = async () => {
      const { data, status, errorMessage } = await fetchCountriesRequestSend();

      if (status === 200) {
        setCountryList(data);
      } else {
        const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
        consoleErrorMessage(errorMessage, errorDataMessage, '/switchere/country/list');
      }
    };

    fetchCountries();
  }, []);

  return countryList;
};
