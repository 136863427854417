import React from 'react';
import { hasElementsInArray } from '../../helpers/has-elements-in-array';
import { useFetchPartnerProfile } from '../../api/modules/account/use-fetch-partner-profile';
import { useAllCurrencies } from '../../hooks/use-all-currencies';
import { Section } from '../section';
import { ExportPopup } from '../shared/export-popup';
import { New2faPopup } from '../new-2fa-popup';
import { MassPayoutMethods } from '../mass-payout-methods';
import { useBalancesData } from '../../hooks/use-balances-data';
import { MassPayoutWhitelistAddresses } from '../mass-payout-whitelist-addresses';
import { PageWrapper } from './components/page-wrapper';
import { useInitState, useMassPayoutInfo, useExportState } from './hooks';
import { MassPayoutsFullFilter } from './components/mass-payouts-full-filter';
import { MassPayoutsTable } from './components/mass-payouts-table';
import { PopupInfoAboutMassPayout } from './components/popup-info-about-mass-payout';
import classes from './styles.module.scss';

export const MassPayoutsPage = () => {
  const {
    massPayoutsForExport,
    massPayoutsForPage,
    massPayoutsCount,
    isFetching: massPayoutsFetching,
    apiError: massPayoutsApiError,
    handleSearchChange,
    handleFilterReset,
    handleFilterSubmit,
    searchText,
    filterData,
    page,
    setPage,
    numberOfActiveFilters,
    reFetchWithdrawals,
  } = useInitState();

  const {
    isExportModalShow,
    handleExportModalToggle,
    exportPDF,
    exportDifferentFormat,
  } = useExportState(massPayoutsForExport);

  const {
    isInfoPopupShow,
    is2faPopupShow,
    infoItems,
    massPayoutId,
    massPayoutBatchWithdrawalId,
    isMassPayoutVerified,
    setInfo,
    handleInfoPopupClose,
    handle2faPopupToggle,
    handleCallback2fa,
  } = useMassPayoutInfo(reFetchWithdrawals);

  useFetchPartnerProfile();
  useAllCurrencies();
  const { reFetchBalances } = useBalancesData();

  const massPayoutsReFetchCallback = React.useCallback(async () => {
    void reFetchWithdrawals();
    void reFetchBalances();
  }, []);

  return (
    <PageWrapper>
      <div className={classes.sectionBox}>
        <Section
          className={classes.sectionHeader}
          title="Create mass payout"
        >
          <div className={classes.description}>
            Please use the form below to create mass payout.
          </div>
          <MassPayoutMethods
            className={classes.massPayout}
            reFetch={massPayoutsReFetchCallback}
          />
        </Section>
        <MassPayoutWhitelistAddresses className={classes.sectionWhitelist} />
      </div>
      <MassPayoutsFullFilter
        onChangeSearch={handleSearchChange}
        activeFilterItemsNumber={numberOfActiveFilters}
        onSubmit={handleFilterSubmit}
        onFilterReset={handleFilterReset}
        defaultData={filterData}
        onExportModal={handleExportModalToggle}
        exportDisabled={!hasElementsInArray(massPayoutsForPage)}
      />
      <MassPayoutsTable
        items={massPayoutsForPage}
        hasItems={hasElementsInArray(massPayoutsForPage)}
        hasSearchData={Boolean(searchText) || Boolean(numberOfActiveFilters)}
        loading={massPayoutsFetching}
        onClick={setInfo}
        count={massPayoutsCount}
        onPaginatorClick={setPage}
        page={page}
        apiError={massPayoutsApiError}
      />
      <ExportPopup
        className={classes.exportPopup}
        open={isExportModalShow}
        onClose={handleExportModalToggle}
        onExportPdf={exportPDF}
        onExport={exportDifferentFormat}
      />
      <PopupInfoAboutMassPayout
        open={isInfoPopupShow}
        onClose={handleInfoPopupClose}
        title="Payout ID"
        id={massPayoutId}
        items={infoItems}
        onVerify={handle2faPopupToggle}
        verified={isMassPayoutVerified}
      />
      <New2faPopup
        open={is2faPopupShow}
        onClose={handle2faPopupToggle}
        onUpdate={handleCallback2fa}
        withdrawalId={massPayoutBatchWithdrawalId}
      />
    </PageWrapper>
  );
};
