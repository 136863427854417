import React from 'react';
import { PayoutWalletsOption } from '../payout-wallets-option';
import { OPTIONS } from '../../helpers';
import { ExtendedWallet, PopupData } from '../../types';
import classes from './styles.module.scss';

type PayoutWalletsOptionsProps = {
  activeWallet: ExtendedWallet | null,
  walletsWhitelist: Array<ExtendedWallet>,
  onClick: (type: PopupData) => void,
  onSetAsMain: () => Promise<void>,
  isWhitelistHidden: boolean,
};

export const PayoutWalletsOptions: React.FC<PayoutWalletsOptionsProps> = (props) => {
  const {
    activeWallet,
    walletsWhitelist,
    onClick,
    onSetAsMain,
    isWhitelistHidden,
  } = props;

  const activeWalletWhitelist = walletsWhitelist.find((el) => el.id === activeWallet?.id);
  const isAddressAddedInWhitelist = activeWalletWhitelist?.whitelistData?.exists ?? false;
  const isSetAsMainHidden = activeWallet?.priority === 0 ?? false;
  const isWhitelistRequestSend = activeWallet?.whitelistRequest ?? false;
  const optionsWithParameters = OPTIONS.map((option) => {
    const isAddToWhitelistOption = option === 'addToWhitelist';
    const addToWhitelistText = isAddToWhitelistOption && isAddressAddedInWhitelist
      ? 'Address added to Whitelist'
      : null;
    const requestWhitelistText = isAddToWhitelistOption && isWhitelistRequestSend
      ? 'Whitelist request processing'
      : null;

    return {
      name: option,
      isHidden: (isAddToWhitelistOption && isWhitelistHidden)
      || (option === 'setAsMain' && isSetAsMainHidden),
      text: addToWhitelistText || requestWhitelistText,
      isDisable: Boolean(addToWhitelistText) || Boolean(requestWhitelistText) || false,
    };
  });

  return (
    <div className={classes.payoutWalletsOptions}>
      {optionsWithParameters.map((option) => (
        option.isHidden ? null : (
          <PayoutWalletsOption
            key={option.name}
            type={option.name}
            isDisable={option.isDisable}
            text={option.text}
            onClick={onClick}
            onSetAsMain={onSetAsMain}
          />
        )
      ))}
    </div>
  );
};
