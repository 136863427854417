import React from 'react';
import cn from 'classnames';
import { AccordionProps } from './types';
import classes from './styles.module.scss';

export const Accordion: React.FC<AccordionProps> = (props) => {
  const {
    className,
    title,
    children,
  } = props;

  const [open, setOpen] = React.useState(false);

  const handleClick = () => setOpen((prev) => !prev);
  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      setOpen((prev) => !prev);
    }
  };

  return (
    <div className={cn([
      classes.accordion,
      open && classes.accordionOpen,
      className,
    ])}
    >
      {title && (
        <>
          <div
            className={classes.accordionTitle}
            onClick={handleClick}
            onKeyDown={handleKeyPress}
            role="button"
            tabIndex={0}
          >
            {title}
          </div>
          <div className={classes.accordionButton} />
        </>
      )}
      {children && (
        <div
          className={classes.accordionContent}
          dangerouslySetInnerHTML={{ __html: children }} // eslint-disable-line react/no-danger
        />
      )}
    </div>
  );
};
