import React from 'react';
import Loader from '../loader';
import styles from './styles.module.scss';

const buttonState = {
  normal: 'normal',
  processing: 'processing',
  success: 'success',
  failure: 'failure',
  disabled: 'disabled',
};

function SubmitButton(props) {
  const { saveStatusInvalid, saveStatusSuccess, saveStatus } = styles;

  switch (props.buttonState) {
    case buttonState.normal:
      if (props.hiddenButton) {
        return null;
      }

      return (
        <button className={props.className} onClick={props.onSubmit}>
          {props.children}
        </button>
      );
    case buttonState.processing:
      return <Loader className={props.className} width={35} />;
    case buttonState.success:
      if (props.successMessage === undefined) {
        return '';
      }

      return (
        <div
          className={`${saveStatusSuccess} ${saveStatus} ${props.className}`}
        >
          {props.successMessage}
        </div>
      );
    case buttonState.failure:
      if (props.failureMessage === undefined) {
        return '';
      }

      return (
        <div
          className={`${saveStatusInvalid} ${saveStatus} ${props.className}`}
        >
          {props.failureMessage}
        </div>
      );
    case buttonState.disabled:
      if (props.hiddenButton) {
        return null;
      }

      return (
        <button
          className={props.className}
          onClick={props.onSubmit}
          disabled={true}
        >
          {props.children}
        </button>
      );
    default:
      return (
        <button className={'normal'} onClick={props.onSubmit}>
          {props.children}
        </button>
      );
  }
}

export default SubmitButton;
export { buttonState };
