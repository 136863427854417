import * as React from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isFunction } from '../../../../helpers/is-function';
import { SimpleSelectField } from '../../../ui/simple-select-field';
import {
  TRANSACTION_STATUS_ITEMS,
  TRANSACTIONS_FILTER_SCHEMA,
} from '../../helpers';
import { TRANSACTION_TYPES_SELECT_ITEMS } from '../../../../constants/app-constants';
import { DateField } from '../../../ui/date-field';
import { CustomFilter } from '../../../shared/custom-filter';
import { useTimeout } from '../../../../hooks/use-timeout';
import { getTransactionTypeSelectItems } from '../../../../helpers/get-transaction-type-select-items';
import {
  getTransactionType,
  getTransactionTypeText,
} from '../../../../helpers/get-transaction-type';
import classes from './styles.module.scss';

export const TransactionsFilter = (props) => {
  const {
    className,
    onSubmit,
    onClose,
    onResetFormData,
    defaultData,
  } = props;

  const defaultDateFrom = defaultData?.dateFrom ?? null;
  const defaultDateTo = defaultData?.dateTo ?? null;
  const defaultType = defaultData?.type ?? '';
  const defaultStatus = defaultData?.status ?? '';

  const {
    control,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(TRANSACTIONS_FILTER_SCHEMA),
  });

  const [createdFrom, setCreatedFrom] = React.useState(defaultDateFrom);
  const [createdTo, setCreatedTo] = React.useState(defaultDateTo);
  const [isReset, setIsReset] = React.useState(false);

  const defaultTypeText = React.useMemo(() => {
    const defaultValue = TRANSACTION_TYPES_SELECT_ITEMS
      .find((item) => item.key === defaultType)?.value ?? '';

    return defaultValue
      ? `${getTransactionType(defaultValue)} ${getTransactionTypeText(defaultValue)}`
      : '';
  }, [defaultType]);
  const defaultStatusText = React.useMemo(() => (
    TRANSACTION_STATUS_ITEMS
      .find((item) => item.key === defaultStatus)?.value ?? ''
  ), [defaultStatus]);

  const handleDateChange = React.useCallback(({ from, to }) => {
    setCreatedFrom(from);
    setCreatedTo(to);
  }, []);
  const handleFormSubmit = (data) => {
    setIsReset(false);

    if (isFunction(onSubmit)) {
      onSubmit({
        type: data?.type || null,
        status: data?.status || null,
        dateFrom: createdFrom,
        dateTo: createdTo,
      });
    }
  };
  const handleResetForm = () => {
    if (!isReset) {
      setIsReset(true);
    }
    setCreatedFrom(null);
    setCreatedTo(null);
    reset();
    if (isFunction(onResetFormData)) {
      onResetFormData();
    }
  };

  useTimeout(() => { setIsReset(false); }, 1000, isReset);

  return (
    <CustomFilter
      className={className}
      onClose={onClose}
      onSubmit={handleSubmit(handleFormSubmit)}
      onReset={handleResetForm}
    >
      <DateField
        className={classes.fieldDate}
        title="Date"
        createdFrom={createdFrom}
        createdTo={createdTo}
        onChange={handleDateChange}
      />
      <Controller
        name="type"
        control={control}
        defaultValue={defaultType}
        render={({ field }) => (
          <SimpleSelectField
            className={classes.filterInput}
            title="Transaction Type"
            data={{
              id: field.name,
              items: getTransactionTypeSelectItems(TRANSACTION_TYPES_SELECT_ITEMS),
              onSelect: field.onChange,
              placeholder: 'Choose',
              reset: isReset,
              defaultValue: defaultTypeText,
            }}
          />
        )}
      />
      <Controller
        name="status"
        control={control}
        defaultValue={defaultStatus}
        render={({ field }) => (
          <SimpleSelectField
            className={classes.filterInput}
            title="Transaction status"
            data={{
              id: field.name,
              items: TRANSACTION_STATUS_ITEMS,
              onSelect: field.onChange,
              placeholder: 'Choose',
              reset: isReset,
              defaultValue: defaultStatusText,
            }}
          />
        )}
      />
    </CustomFilter>
  );
};

TransactionsFilter.defaultProps = {
  className: null,
  onSubmit: null,
  onClose: null,
  onResetFormData: null,
  transactionsHistory: true,
};

TransactionsFilter.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  onResetFormData: PropTypes.func,
  transactionsHistory: PropTypes.bool,
};
