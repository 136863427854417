import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkErrorCode } from '../../../helpers/check-error-code';
import { getSafeErrorMessageText } from '../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../helpers/console-error-message';
import { usePayments } from '../../../api/modules/account/use-payments';
import { ResponseType } from '../../../default-types';
import { isPartnerCustodySelector, partnerSelector } from '../../../store/partner/selectors';
import { getPaymentInfoItems } from '../../../helpers/get-payments-info-items';
import { lastTransactionsSelector } from '../../../store/statistics/selectors';
import { setLastTransactions } from '../../../store/statistics/reducer';

export const useLastTransactions = (currenciesObject: object) => {
  const dispatch = useDispatch();
  const partner = useSelector(partnerSelector());
  const isPartnerCustody = useSelector(isPartnerCustodySelector());
  const lastTransactions = useSelector(lastTransactionsSelector());
  const fetchPayments = usePayments();

  const [isTimeout, setIsTimeout] = React.useState<boolean>(false);
  const [apiErrorMessage, setApiErrorMessage] = React.useState<string | null>(null);
  const [transactionInfo, setTransactionInfo] = React.useState<object | undefined>(undefined);

  const transactionInfoItems = React.useMemo(() => (
    getPaymentInfoItems(transactionInfo, isPartnerCustody, currenciesObject)
  ), [transactionInfo, isPartnerCustody, currenciesObject]);

  const handleTransactionClick = (payment: object) => {
    setTransactionInfo(payment);
  };
  const handleInfoPopupClose = () => {
    setTransactionInfo(undefined);
  };
  const reFetchPayments = React.useCallback(async () => {
    setIsTimeout(false);
    setApiErrorMessage(null);

    const { data, status, isTimeoutError, errorMessage }: ResponseType = await fetchPayments({
      limit: 5,
      sortBy: 'created_at',
    });

    if (status === 200) {
      const nextPayments = data?.payments ?? [];
      dispatch(setLastTransactions(nextPayments));
    } else {
      checkErrorCode(data?.errorData?.code);
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/payments');

      if (isTimeoutError) {
        setIsTimeout(true);
      } else {
        setApiErrorMessage(errorDataMessage);
      }
    }
  }, []);

  React.useEffect(() => {
    if (partner) {
      reFetchPayments();
    }
  }, [partner]);

  return {
    lastTransactions,
    isTimeout,
    apiErrorMessage,
    handleTransactionClick,
    isInfoPopupOpen: Boolean(transactionInfo),
    transactionInfoItems,
    handleInfoPopupClose,
    // @ts-ignore
    transactionPaymentId: transactionInfo?.paymentId ?? null,
  };
};
