import * as React from 'react';
import PropTypes from 'prop-types';
import { UserDataInfoDesktop } from '../../../shared/user-data-info-desktop';
import { BILLING_PLAN_INFO_TH, getBillingPlanInfoData } from '../../helpers';

export const BillingPlanInfo = React.memo((props) => {
  const {
    className,
    data,
    landscape,
    currenciesObject,
  } = props;

  const bodyData = React.useMemo(() => (
    getBillingPlanInfoData(data, currenciesObject)
  ), [data, currenciesObject]);

  return (
    <UserDataInfoDesktop
      className={className}
      headerItems={BILLING_PLAN_INFO_TH}
      bodyItems={bodyData}
      landscape={landscape}
    />
  );
});

BillingPlanInfo.defaultProps = {
  className: null,
  data: {},
  landscape: false,
  currenciesObject: {},
};

BillingPlanInfo.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  landscape: PropTypes.bool,
  currenciesObject: PropTypes.object,
};

BillingPlanInfo.displayName = 'BillingPlanInfo';
