import React from 'react';
import PropTypes from 'prop-types';

export const PaymentToolsIcon = (props) => {
  const { color } = props;

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.405 1.5H5.94C6.9975 1.5 7.845 2.3625 7.845 3.42075V5.9775C7.845 7.0425 6.9975 7.8975 5.94 7.8975H3.405C2.355 7.8975 1.5 7.0425 1.5 5.9775V3.42075C1.5 2.3625 2.355 1.5 3.405 1.5ZM3.405 10.1023H5.94C6.9975 10.1023 7.845 10.958 7.845 12.023V14.5798C7.845 15.6373 6.9975 16.4998 5.94 16.4998H3.405C2.355 16.4998 1.5 15.6373 1.5 14.5798V12.023C1.5 10.958 2.355 10.1023 3.405 10.1023ZM14.5951 1.5H12.0601C11.0026 1.5 10.1551 2.3625 10.1551 3.42075V5.9775C10.1551 7.0425 11.0026 7.8975 12.0601 7.8975H14.5951C15.6451 7.8975 16.5001 7.0425 16.5001 5.9775V3.42075C16.5001 2.3625 15.6451 1.5 14.5951 1.5ZM12.0601 10.1023H14.5951C15.6451 10.1023 16.5001 10.958 16.5001 12.023V14.5798C16.5001 15.6373 15.6451 16.4998 14.5951 16.4998H12.0601C11.0026 16.4998 10.1551 15.6373 10.1551 14.5798V12.023C10.1551 10.958 11.0026 10.1023 12.0601 10.1023Z"
      />
    </svg>
  );
};

PaymentToolsIcon.defaultProps = {
  color: '#616161',
};

PaymentToolsIcon.propTypes = {
  color: PropTypes.string,
};
