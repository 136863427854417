import React from 'react';
import { WhitelistTextsTypes } from '../../types';
import classes from './styles.module.scss';

const TITLE_FOR_EMAIL = 'Disable wallet address';
const BODY_FOR_EMAIL = 'I\'d like to disable wallet address whitelisting and accept all risks related to this operation.';
const PARAMS_FOR_EMAIL = `?subject=${TITLE_FOR_EMAIL}&body=${BODY_FOR_EMAIL}`;

export const WhitelistTexts: React.FC<WhitelistTextsTypes> = (props) => {
  const {
    isManualType,
    isUploadType,
  } = props;

  return (
    <div className={classes.whitelistTexts}>
      {isManualType && (
        <>
          Please fill out the following form to request whitelisting addresses for payouts.
        </>
      )}
      {isUploadType && (
        <>
          <a
            href="/files/whitelist-addresses.csv"
            download="WhitelistTemplate.csv"
          >
            Download the template
          </a>
          , fill it out and upload it using the button below to request whitelisting addresses
          for payouts.
        </>
      )}
      <div className={classes.whitelistTextsInfo}>
        <img
          src="/images/info-icon.svg"
          alt="Info-icon"
          decoding="async"
          loading="lazy"
          width={16}
          height={16}
        />
        {' '}
        This procedure may take up to 24 hours.
      </div>
      If you want to disable wallet address whitelisting, please
      {' '}
      <a href={`mailto:whitelist@nowpayments.io${PARAMS_FOR_EMAIL}`}>
        send a request
      </a>
      {' '}
      accepting
      risks related to this operation.
      {isUploadType && (
        <>
          <br />
          <br />
          For every payout specify a certain line like this:
          <picture>
            <source
              srcSet="/images/example-whitelist-addresses-full.svg"
              media="(min-width: 540px)"
            />
            <img
              src="/images/example-whitelist-addresses.svg"
              alt="Mass Payouts"
              decoding="async"
              loading="lazy"
            />
          </picture>
        </>
      )}
    </div>
  );
};
