import * as React from 'react';
import PropTypes from 'prop-types';
import {
  SUBSCRIPTION_USER_PAGE_INFO_THS_DESKTOP,
  SUBSCRIPTION_USER_PAGE_INFO_THS_MOBILE,
  getUserInfoData,
} from '../../helpers';
import { SubscriptionPlanInfoBox } from '../../../subscription-plan-info-box';

export const SubscriptionUserInfo = React.memo((props) => {
  const {
    className,
    data,
    desktop,
    thText,
    tdElement,
  } = props;

  const planHeaderItems = desktop
    ? SUBSCRIPTION_USER_PAGE_INFO_THS_DESKTOP
    : SUBSCRIPTION_USER_PAGE_INFO_THS_MOBILE;
  const bodyData = React.useMemo(() => (
    getUserInfoData(data, desktop)
  ), [data, desktop]);

  return (
    <SubscriptionPlanInfoBox
      className={className}
      headerItems={planHeaderItems}
      bodyItems={bodyData}
      thText={thText}
      tdElement={tdElement}
    />
  );
});

SubscriptionUserInfo.defaultProps = {
  className: null,
  data: {},
  desktop: false,
  element: null,
};

SubscriptionUserInfo.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    intervalDay: PropTypes.string,
    amount: PropTypes.number,
    ticker: PropTypes.string,
    period: PropTypes.string,
    createdAt: PropTypes.string,
  }),
  desktop: PropTypes.bool,
  thText: PropTypes.string,
  tdElement: PropTypes.element,
};

SubscriptionUserInfo.displayName = 'SubscriptionUserInfo';
