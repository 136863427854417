import React from 'react';
import cn from 'classnames';
import { Field } from '../../../ui/field';
import { Button } from '../../../ui/button';
import { LoaderIcon } from '../../../icons/loader-icon';
import classes from './styles.module.scss';

type PaymentDetailsFieldProps = {
  value: string,
  onChange: (value: string) => void,
  onSave: () => void,
  isError: boolean,
  infoText: string,
  isSaved: boolean,
  isLoading: boolean,
};

export const PaymentDetailsField: React.FC<PaymentDetailsFieldProps> = (props) => {
  const {
    value,
    onChange,
    onSave,
    isError,
    infoText,
    isSaved,
    isLoading,
  } = props;

  return (
    <div className={classes.paymentDetailsField}>
      <div className={classes.fieldController}>
        <Field
          className={classes.fieldData}
          data={{
            value,
            onChange,
          }}
        />
        <Button
          className={cn([
            classes.saveButton,
            isSaved && classes.saveButtonSaved,
          ])}
          onClick={onSave}
        >
          {isSaved && !isLoading && (
            <img
              src="/images/check.svg"
              loading="lazy"
              decoding="async"
              alt="ok"
              width={13}
              height={10}
            />
          )}
          {isLoading && !isSaved && (
            <LoaderIcon
              path="/images/loader-white-on-blue-icon.gif"
              size={20}
            />
          )}
          {!isLoading && !isSaved && 'Save'}
        </Button>
      </div>
      <p className={cn([
        isError && classes.errorText,
      ])}
      >
        {infoText}
      </p>
    </div>
  );
};
