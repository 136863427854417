import validator from 'is-my-ip-valid';
import { hasElementsInArray } from '../../helpers/has-elements-in-array';

const validateV4 = validator({ version: 4 });
const validateV6 = validator({ version: 6 });

/**
 * @param {string} addresses
 * @returns {string|null}
 */
export const getNotValidIPAddress = (addresses) => {
  if (typeof addresses !== 'string') {
    return addresses;
  }

  const addressesSplitted = addresses.split(',');
  const notValidAddresses = addressesSplitted.reduce((acc, address) => {
    const isV4FormatAddress = validateV4(address);
    const isV6FormatAddress = validateV6(address);
    const isValid = isV4FormatAddress || isV6FormatAddress;

    if (!isValid) {
      acc.push(address);
    }

    return acc;
  }, []);
  const hasNotValidAddresses = hasElementsInArray(notValidAddresses);

  return hasNotValidAddresses
    ? notValidAddresses[0]
    : null;
};

/**
 * @param {string} currentIp
 * @param {string} IPs
 * @returns {[]}
 */
export const getIPsData = (currentIp, IPs) => {
  const ips = [];
  if (currentIp && typeof currentIp === 'string') {
    ips.push(currentIp);
  }
  if (IPs && typeof IPs === 'string') {
    const IPsSplitted = IPs.split(',');
    const IPsFiltered = IPsSplitted.filter((address) => !!address);
    IPsFiltered.forEach((ip) => {
      ips.push(ip);
    });
  }

  return ips;
};
