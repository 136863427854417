import { STATISTICS_REDUCER_NAME } from './reducer';

export const balanceSelector = () => (state) => state[STATISTICS_REDUCER_NAME].balance;

export const countOfPayoutsSelector = () => (state) => state[STATISTICS_REDUCER_NAME].countOfPayouts;

export const turnoverSelector = () => (state) => state[STATISTICS_REDUCER_NAME].turnover;

export const statsTurnoverSelector = () => (state) => state[STATISTICS_REDUCER_NAME].statsTurnover;

export const statsCoinsSelector = () => (state) => state[STATISTICS_REDUCER_NAME].statsCoins;

export const activeStatsTypeSelector = () => (state) => state[STATISTICS_REDUCER_NAME].activeStatsType;

export const activePeriodTypeSelector = () => (state) => state[STATISTICS_REDUCER_NAME].activePeriodType;

export const periodSelector = () => (state) => state[STATISTICS_REDUCER_NAME].period;

export const lastTransactionsSelector = () => (state) => state[STATISTICS_REDUCER_NAME].lastTransactions;

export const newsSelector = () => (state) => state[STATISTICS_REDUCER_NAME].news;

export const industrySelector = () => (state) => state[STATISTICS_REDUCER_NAME].industry;

export const loadedSelector = () => (state) => state[STATISTICS_REDUCER_NAME].isLoaded;

export const isFetchingSelector = () => (state) => state[STATISTICS_REDUCER_NAME].isFetching;

export const errorMessageSelector = () => (state) => state[STATISTICS_REDUCER_NAME].errorMessage;
