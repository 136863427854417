import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  isPartnerCustodySelector,
  partnerFetchedSelector,
  partnerFetchingSelector,
} from '../../store/partner/selectors';
import { EnableCustody } from '../enable-custody';
import { hasElementsInArray } from '../../helpers/has-elements-in-array';
import { useDebounce } from '../../hooks/use-debounce';
import { customerBalancesFetchingSelector } from '../../store/customer-management/selectors';
import { ROUTES } from '../../constants/routes';
import { useMarketInfoRates } from '../../hooks/use-market-info-rates';
import { marketInfoFetchingSelector } from '../../store/market-info/selectors';
import { CustomersTable } from './components/customers-table';
import {
  useCustomersPageInitState,
  useCreateCustomersData,
  useCustomerBalances,
} from './hooks';
import { CustomersFilter } from './components/customers-filter';
import { PopupCreateCustomers } from './components/popup-create-customers';
import { PagesWrapper } from './components/pages-wrapper';
import classes from './customers-page-styles.module.scss';

export const CustomersPage = () => {
  const history = useHistory();
  const partnerFetching = useSelector(partnerFetchingSelector());
  const partnerFetched = useSelector(partnerFetchedSelector());
  const isPartnerCustody = useSelector(isPartnerCustodySelector());
  const customerBalancesFetching = useSelector(customerBalancesFetchingSelector());
  const marketInfoFetching = useSelector(marketInfoFetchingSelector());

  const {
    isPending,
    subPartnersData,
    subPartnersError,
    hasSubPartnersOnFirstBoot,
    setHasSubPartnersOnFirstBoot,
    reFetchSubPartners,
    searchText,
    setSearchText,
    page,
    setPage,
  } = useCustomersPageInitState();

  const {
    isPopupCreateCustomersShown,
    createCustomersPending,
    createCustomersApiError,
    createCustomersError,
    createCustomersSuccess,
    handleCreateUsersSubmit,
    handlePopupCreateCustomersOpen,
    handlePopupCreateCustomersClose,
  } = useCreateCustomersData();

  const {
    isNeedMarketInfoRates,
    handleCustomerBalances,
  } = useCustomerBalances(subPartnersData?.result ?? []);

  const { call: handleSearchChange } = useDebounce(async (value) => {
    await reFetchSubPartners(1, value);
    setSearchText(value);
    setPage(1);
  }, 300);
  const handlePaginatorClick = React.useCallback(async (nextPage) => {
    await reFetchSubPartners(nextPage, searchText);
    setPage(nextPage);
  }, [searchText]);
  const handleCustomerRowClick = React.useCallback((id) => {
    history.push(`${ROUTES.CUSTOMERS}/${id}`);
  }, []);

  useMarketInfoRates({ isNeedRates: isNeedMarketInfoRates });

  React.useEffect(() => {
    if (createCustomersSuccess) {
      void reFetchSubPartners(page, searchText);

      if (!hasSubPartnersOnFirstBoot) {
        setHasSubPartnersOnFirstBoot(true);
      }
    }
  }, [
    createCustomersSuccess,
    searchText,
    page,
    hasSubPartnersOnFirstBoot,
  ]);

  const isEnableCustodyStepsShow = !partnerFetching && partnerFetched && !isPartnerCustody;
  const isContentShow = !partnerFetching && partnerFetched && isPartnerCustody;
  const subPartnerItems = subPartnersData?.result ?? [];
  const subPartnerItemsCount = subPartnersData?.count ?? null;

  return (
    <PagesWrapper
      contentShow={isContentShow}
      CustodySteps={isEnableCustodyStepsShow ? (
        <EnableCustody
          className={classes.enableCustody}
          title="Please activate custody to access Customer Management feature"
        />
      ) : null}
    >
      <CustomersFilter
        onClick={handlePopupCreateCustomersOpen}
        onChangeSearch={handleSearchChange}
        buttonShow={hasSubPartnersOnFirstBoot}
        onBalances={handleCustomerBalances}
        pending={customerBalancesFetching || marketInfoFetching}
      />
      <CustomersTable
        items={subPartnerItems}
        hasItems={hasElementsInArray(subPartnerItems)}
        hasSearchData={Boolean(searchText)}
        loading={isPending}
        onClick={handleCustomerRowClick}
        count={subPartnerItemsCount}
        onPaginatorClick={handlePaginatorClick}
        page={page}
        apiError={subPartnersError}
        onButtonClick={handlePopupCreateCustomersOpen}
      />
      <PopupCreateCustomers
        open={isPopupCreateCustomersShown}
        onClose={handlePopupCreateCustomersClose}
        onSubmit={handleCreateUsersSubmit}
        errorApiMessage={createCustomersApiError}
        errorMessage={createCustomersError}
        fetching={createCustomersPending}
      />
    </PagesWrapper>
  );
};
