import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from '../../../popup';
import { ConversionForm } from '../../../conversion-form';

export const ConversionPopup = (props) => {
  const {
    className,
    open,
    onClose,
    formData,
  } = props;

  const {
    currencies,
    availableCurrencies,
    defaultTicker,
    onAmountChange,
    estimate,
    estimateFetching,
    errorApiMessage,
    onSubmit,
    onCurrencyChange,
  } = formData;

  return (
    <Popup
      classNameContent={className}
      open={open}
      onClose={onClose}
      withCloseButton
    >
      <ConversionForm
        title="Conversion"
        currencies={currencies}
        availableCurrencies={availableCurrencies}
        defaultTicker={defaultTicker}
        onAmountChange={onAmountChange}
        estimate={estimate}
        estimateFetching={estimateFetching}
        errorApiMessage={errorApiMessage}
        onSubmit={onSubmit}
        onCurrencyChange={onCurrencyChange}
      />
    </Popup>
  );
};

ConversionPopup.defaultProps = {
  className: null,
  open: false,
  onClose: null,
  formData: {},
};

ConversionPopup.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  formData: PropTypes.shape({}),
};
