export const BILLING_PLANS_PAGE_PER_PAGE = 20;

export const BILLING_PLANS_PAGE_THS_DESKTOP = [{
  id: 1,
  name: 'Plan ID',
}, {
  id: 2,
  name: 'Name',
}, {
  id: 3,
  name: 'Cost per period',
}, {
  id: 4,
  name: 'Period',
}, {
  id: 5,
  name: 'Created at',
}];

export const BILLING_PLANS_PAGE_THS_MOBILE = [{
  id: 1,
  name: 'Plan ID /<br /> Name',
}, {
  id: 2,
  name: 'Cost per period /<br /> Period',
}, {
  id: 3,
  name: 'Created at',
}];
