import { PARTNER_REDUCER_NAME } from './reducer';

export const partnerSelector = () => (state) => state[PARTNER_REDUCER_NAME].data;

export const partnerFetchingSelector = () => (state) => state[PARTNER_REDUCER_NAME].fetching;

export const partnerFetchedSelector = () => (state) => state[PARTNER_REDUCER_NAME].fetched;

export const partnerErrorSelector = () => (state) => state[PARTNER_REDUCER_NAME].error;

export const isPartnerCustodySelector = () => (state) => state[PARTNER_REDUCER_NAME].data?.isCustody ?? false;

export const partnerType2faSelector = () => (state) => state[PARTNER_REDUCER_NAME].data?.type2fa ?? null;
