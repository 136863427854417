import React from 'react';
import { Button } from '../../../ui/button';

type RegenerateIpnKeyProps = {
  className: string,
  buttonClassName: string,
  onClose: () => void,
  onProceed: () => void,
};

export const RegenerateIpnKey: React.FC<RegenerateIpnKeyProps> = (props) => {
  const {
    className,
    buttonClassName,
    onClose,
    onProceed,
  } = props;

  return (
    <div className={className}>
      <p>
        After a new IPN secret key is generated, the current key will become inactive, and notifications for
        it will stop coming. Are you sure you want to generate a new key?
      </p>
      <Button
        className={buttonClassName}
        displayRed
        onClick={onProceed}
      >
        I understand, proceed
      </Button>
      <Button
        className={buttonClassName}
        displayBorder
        onClick={onClose}
      >
        Close
      </Button>
    </div>
  );
};
