import React from 'react';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { updateApiKeys } from '../../../../store/payment-settings/reducer';
import { CopyText } from '../../../ui/copy-text';
import Textarea from '../../../ui/textarea';
import { Button } from '../../../ui/button';
import { useUpdateApiKey } from '../../../../api/modules/account/use-update-api-key';
import { getSafeErrorMessageText } from '../../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../../helpers/console-error-message';
import { LoaderIcon } from '../../../icons/loader-icon';
import { ApiKey, ResponseType } from '../../../../default-types';
import classes from './styles.module.scss';

type EditApiKeyProps = {
  apiKey: ApiKey | null,
  onClose: () => void,
  isNeedGenerateNewKey: boolean,
  withNewKey: boolean,
  onAddNewKey: () => Promise<void>,
  isCreatingApiKey: boolean,
};

const MAX_COMMENT_LENGTH = 255;

export const EditApiKey: React.FC<EditApiKeyProps> = (props) => {
  const {
    apiKey,
    onClose,
    isNeedGenerateNewKey,
    withNewKey,
    onAddNewKey,
    isCreatingApiKey,
  } = props;

  const dispatch = useDispatch();
  const fetchUpdateApiKey = useUpdateApiKey();

  const [comment, setComment] = React.useState(apiKey?.description);
  const [longCommentError, setLongCommentError] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);
  const [apiError, setApiError] = React.useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    if (value.length <= MAX_COMMENT_LENGTH) {
      setLongCommentError(false);
      setComment(value);
    } else {
      setLongCommentError(true);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (comment === apiKey?.description) {
      onClose();
      return;
    }

    if (longCommentError) return;

    setIsFetching(true);

    const currentApiKeyId = apiKey?.id ?? '';
    const currentComment = comment || null;

    const {
      data,
      status,
      errorMessage,
    }: ResponseType = await fetchUpdateApiKey(currentApiKeyId, currentComment);

    if (status === 200 && data) {
      dispatch(updateApiKeys({ id: currentApiKeyId, description: currentComment }));
      onClose();
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/api-keys');
      setApiError(errorDataMessage);
    }
    setIsFetching(false);
  };

  React.useEffect(() => {
    if (isNeedGenerateNewKey) {
      onAddNewKey();
    }
  }, [isNeedGenerateNewKey, onAddNewKey]);

  return (
    <form className={classes.editApiKey} onSubmit={handleSubmit}>
      <div className={cn([
        classes.apiKeyData,
        withNewKey && isCreatingApiKey && classes.apiKeyDataEmpty,
      ])}
      >
        {withNewKey && isCreatingApiKey ? (
          <LoaderIcon className={classes.apiKeyDataLoading} />
        ) : (
          <>
            <div className={classes.apiKeyDataBlock}>
              <p className={classes.apiKeyDataTitle}>Your API key</p>
              <CopyText
                className={classes.apiKeyDataCopy}
                copyText={apiKey?.apiKey}
                text={apiKey?.apiKey}
                copyIconPath="/images/copy-icon-small.svg"
                copySuccessIconPath="/images/done-icon.svg"
              />
            </div>
            <div className={classes.apiKeyDataBlock}>
              <p className={classes.apiKeyDataTitle}>Your public key</p>
              <CopyText
                className={classes.apiKeyDataCopy}
                copyText={apiKey?.tid}
                text={apiKey?.tid}
                copyIconPath="/images/copy-icon-small.svg"
                copySuccessIconPath="/images/done-icon.svg"
              />
            </div>
          </>
        )}
      </div>
      <div className={classes.apiKeyComment}>
        <p className={cn(
          classes.apiKeyCommentTitle,
          longCommentError && classes.apiKeyCommentTitleError,
        )}
        >
          Comment must be at most 255 characters
        </p>
        <Textarea
          className={classes.apiKeyTextarea}
          placeholder="Add a comment"
          value={comment}
          onChange={handleChange}
        />
      </div>
      <div className={classes.editApiKeyError}>
        {apiError}
      </div>
      <Button
        className={classes.editApiKeyButton}
        type="submit"
      >
        {
            isFetching
              ? <LoaderIcon path="/images/loader-white-on-blue-icon.gif" />
              : 'Save'
          }
      </Button>
      {!withNewKey && (
        <Button
          className={classes.editApiKeyButton}
          onClick={onClose}
          displayBorder
        >
          Cancel
        </Button>
      )}
    </form>
  );
};
