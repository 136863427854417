import React from 'react';
import { Section } from '../../section';
import { Faq } from '../../fiat-operations-page/types';
import { Accordion } from '../accordion';
import classes from './styles.module.scss';

type FaqProps = {
  faqData: Array<Faq>;
};
export const FaqSection: React.FC<FaqProps> = (props) => {
  const { faqData } = props;

  return (
    <Section
      className={classes.faq}
      title="FAQ"
    >
      {faqData.map((item) => (
        <Accordion title={item.question} key={item.id}>
          {item.answer}
        </Accordion>
      ))}
    </Section>
  );
};
