import React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';

const mapFn = (data) => ({
  companyName: data?.company_name ?? null,
  hasTfa: data?.has_tfa ?? false,
  type2fa: data?.type_2fa ?? null,
  isCustody: data?.is_custody ?? false,
  isShowOnboard: data?.is_show_onboard ?? false,
  isGuardarianEnabled: data?.is_guardarian_enabled ?? false,
  isOnrampEnabled: data?.is_onramp_enabled ?? false,
});

export const useProfile = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async () => {
    const [cancelToken] = getRequestSource();
    const requestPromise = client.get(
      '/profile',
      null,
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
