import React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';
import { withdrawalPaymentsRequestDataMapFn } from '../../helpers/account';

export const useWithdrawalPayments = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (options) => {
    const params = options?.params ?? null;
    const [cancelToken] = getRequestSource();
    const requestPromise = client.get('/withdrawal-payments', {
      params,
      cancelToken,
    });

    return restRequestWrapper(
      requestPromise,
      null,
      withdrawalPaymentsRequestDataMapFn,
    );
  }, [getRequestSource]);
};
