import * as React from 'react';
import { useSelector } from 'react-redux';
import { partnerFetchedSelector } from '../../../store/partner/selectors';
import { usePayments } from '../../../api/modules/account/use-payments';
import { getSafeErrorMessageText } from '../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../helpers/console-error-message';
import { PAYMENTS_PAGE_PER_PAGE } from '../helpers';
import { checkErrorCode } from '../../../helpers/check-error-code';
import { currencyListSelector } from '../../../store/currencies/selectors';
import { useCryptoCurrencies } from '../../../hooks/use-crypto-currencies';
import { useCurrenciesToObject } from '../../../hooks/use-currencies-to-object';

const DEFAULT_PAYMENTS_DATA = {
  payments: [],
  count: null,
};

export const usePaymentsPageInitState = () => {
  const partnerFetched = useSelector(partnerFetchedSelector());
  const currencyList = useSelector(currencyListSelector());
  const fetchPayments = usePayments();
  const { cryptoCurrencies } = useCryptoCurrencies(currencyList);
  const { currenciesObject } = useCurrenciesToObject(currencyList);

  const [isPaymentsFetching, setIsPaymentsFetching] = React.useState(false);
  const [isApiTimeoutError, setIsApiTimeoutError] = React.useState(false);
  const [paymentsData, setPaymentsData] = React.useState(DEFAULT_PAYMENTS_DATA);
  const [paymentsApiErrorMessage, setPaymentsApiErrorMessage] = React.useState(null);
  const [isPaymentsForExportFetching, setIsPaymentsForExportFetching] = React.useState(false);
  const [paymentsForExportErrorMessage, setPaymentsForExportErrorMessage] = React.useState(null);

  const reFetchPayments = React.useCallback(async (page = 1, filter = null, filterData = null) => {
    setIsApiTimeoutError(false);
    setPaymentsApiErrorMessage(null);
    setIsPaymentsFetching(true);

    const { data, status, isTimeoutError, errorMessage } = await fetchPayments({
      limit: PAYMENTS_PAGE_PER_PAGE,
      page,
      filter,
      dateFrom: filterData?.dateFrom,
      dateTo: filterData?.dateTo,
      status: filterData?.status,
      fixedRate: filterData?.fixedRate,
      feePaidByUser: filterData?.feePaidByUser,
      payinAddress: filterData?.payinAddress,
      payinHash: filterData?.payinHash,
      currency: filterData?.outcomePrice,
      apiKey: filterData?.apiKeyId,
      paymentType: filterData?.paymentType,
    });

    if (status === 200) {
      const nextPayments = data?.payments ?? [];
      const nextPaymentsCount = data?.count ?? null;
      setPaymentsData({ payments: nextPayments, count: nextPaymentsCount });
      setIsPaymentsFetching(false);
    } else {
      checkErrorCode(data?.errorData?.code);
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/payments');

      if (isTimeoutError) {
        setIsApiTimeoutError(true);
      } else {
        setPaymentsApiErrorMessage(errorDataMessage);
      }
      setIsPaymentsFetching(false);
    }
  }, []);

  const getPaymentsForExport = React.useCallback(async (page = 1, filter = null, filterData = null) => {
    let result = [];
    setPaymentsForExportErrorMessage(null);
    setIsPaymentsForExportFetching(true);

    const { data, status, isTimeoutError, errorMessage } = await fetchPayments({
      filter,
      dateFrom: filterData?.dateFrom,
      dateTo: filterData?.dateTo,
      status: filterData?.status,
      fixedRate: filterData?.fixedRate,
      feePaidByUser: filterData?.feePaidByUser,
      payinAddress: filterData?.payinAddress,
      payinHash: filterData?.payinHash,
      currency: filterData?.outcomePrice,
      apiKey: filterData?.apiKeyId,
      paymentType: filterData?.paymentType,
    });

    if (status === 200) {
      setIsPaymentsForExportFetching(false);
      result = data?.payments ?? [];
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/payments');
      const currentMessage = isTimeoutError ? errorMessage : errorDataMessage;
      setPaymentsForExportErrorMessage(currentMessage);
      setIsPaymentsForExportFetching(false);
    }

    return result;
  }, []);

  React.useEffect(() => {
    if (partnerFetched) {
      void reFetchPayments();
    }
  }, [partnerFetched]);

  return {
    isPaymentsFetching,
    isApiTimeoutError,
    payments: paymentsData.payments,
    paymentsCount: Number(paymentsData.count),
    paymentsApiErrorMessage,
    reFetchPayments,
    isPaymentsForExportFetching,
    paymentsForExportErrorMessage,
    getPaymentsForExport,
    cryptoCurrencies,
    currenciesObject,
  };
};
