import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { PAGE_NAMES_TO_DISPLAY_INFO, STATUSES } from '../../constants/app-constants';
import { NewPagination } from '../shared/new-pagination';
import { AddWalletCurrenciesPopup } from '../add-wallet-currencies-popup';
import { usePageNamesToDisplayInfo } from '../../hooks/use-page-names-to-display-info';
import { InfoAboutUseTooltip } from '../shared/info-about-use-tooltip';
import { trackEvent } from '../../helpers/utils/track-event';
import { TRACK_EVENT_NAMES } from '../../constants/track-event-names';
import { InvoiceHistoryTableMemo } from './components/invoices-history-table';
import { InvoiceDetailsPopup } from './components/invoice-details-popup';
import { CreateInvoiceButtonMemo } from './components/create-invoice-button';
import { InvoiceFilters } from './components/invoice-filters';
import {
  useInvoicesState,
  usePaymentInfoPopup,
  useProfileInfoActions,
  useProfileInfoState,
  useInvoicePopup,
  useObservablePaymentPopup,
} from './hooks';
import { CreateInvoicePopupMemo } from './components/create-invoice-popup';
import PaymentInfoPopupMemo from './components/payment-info-popup';
import ObservablePaymentPopupMemo from './components/observable-payment-popup';
import { PaymentLinkInfoAboutUse } from './components/payment-link-info-about-use';
import styles from './styles.module.scss';

const ELEMENTS_PER_PAGE = 20;

export const PaymentLinkPage = () => {
  const location = useLocation();

  const {
    profileInfo = {},
    isLoading: isInitialLoading = false,
  } = useProfileInfoState();

  const {
    isUpdatingKeys = false,
    updateKeys,
  } = useProfileInfoActions();

  const {
    hasActiveApiKeys,
    currencies = [],
  } = profileInfo;

  const {
    invoices,
    invoicesTotalCount,
    isInvoiceFilterSet,
    invoicesPage,
    isInvoicesLoading,
    setInvoicesPage,
    setInvoicesFilters,
    reloadInvoices,
  } = useInvoicesState();

  const {
    activeInvoice,
    isInvoicePopupVisible,
    openInvoicePopup,
    closeInvoicePopup,
  } = useInvoicePopup();

  const {
    activePayment,
    setActivePayment,
    isPaymentInfoOpen,
    openPaymentInfoPopup,
    closePaymentInfoPopup,
  } = usePaymentInfoPopup();

  const {
    observablePaymentData,
    isObservablePaymentPopupOpen,
    openObservablePaymentPopup,
    closeObservablePaymentPopup,
  } = useObservablePaymentPopup(openPaymentInfoPopup);

  const {
    infoBoxShow,
    addPageNameToDisplayInfo,
    removePageNameToDisplayInfo,
  } = usePageNamesToDisplayInfo(PAGE_NAMES_TO_DISPLAY_INFO.PAYMENT_LINK);

  const [isCreateInvoicePopupOpen, setIsCreateInvoicePopupOpen] = React.useState(false);
  const [isAddWalletPopupActive, setIsAddWalletPopupActive] = React.useState(false);

  const handleCreateInvoicePopupClose = () => {
    setIsCreateInvoicePopupOpen(false);
  };
  const handleAddWalletCallback = () => {
    updateKeys();
    setIsAddWalletPopupActive(false);
    setIsCreateInvoicePopupOpen(true);
  };

  const toggleAddWalletPopupActive = React.useCallback(() => {
    setIsAddWalletPopupActive((prev) => !prev);
  }, [setIsAddWalletPopupActive]);

  const handlePaymentDetailsRowClick = React.useCallback((payment) => {
    const isWaitingStatus = payment?.status === STATUSES.WAITING;
    const paymentBelongsToInvoice = Boolean(activeInvoice) && payment?.invoice_id === activeInvoice.id;
    if (!isWaitingStatus) {
      openPaymentInfoPopup(payment);
    } else if (paymentBelongsToInvoice) {
      openObservablePaymentPopup(activeInvoice, payment);
    }
  }, [
    activeInvoice,
    openPaymentInfoPopup,
    openObservablePaymentPopup,
  ]);

  const isPaginationShow = invoicesTotalCount > ELEMENTS_PER_PAGE;
  const createPaymentClickHandler = React.useCallback(() => {
    if (isInitialLoading) {
      return;
    }

    const popupFunction = !hasActiveApiKeys ? setIsAddWalletPopupActive : setIsCreateInvoicePopupOpen;
    popupFunction(true);

    trackEvent(TRACK_EVENT_NAMES.OPEN_MODAL_INVOICES_CREATE, {
      action: 'click',
    });
  }, [isInitialLoading, hasActiveApiKeys, setIsAddWalletPopupActive, setIsCreateInvoicePopupOpen]);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const firstPayment = searchParams.get('firstPayment');
    const isFirstPayment = firstPayment === 'true';
    const isNeedPopup = !isInitialLoading && isFirstPayment;
    const isNeedAddWalletPopup =  isNeedPopup && !hasActiveApiKeys;
    const isNeedCreateInvoicePopup = isNeedPopup && hasActiveApiKeys;

    if (isNeedAddWalletPopup) {
      setIsAddWalletPopupActive(true);
    }

    if (isNeedCreateInvoicePopup) {
      setIsCreateInvoicePopupOpen(true);
    }
  }, [isInitialLoading, hasActiveApiKeys]);

  return (
    <div className={styles.invoicePage}>
      <div className={styles.header}>
        <h1>Payment Link</h1>
        <InfoAboutUseTooltip
          className={styles.infoIcon}
          showFirstText={!infoBoxShow}
          onClick={infoBoxShow ? removePageNameToDisplayInfo : addPageNameToDisplayInfo}
        />
      </div>
      {infoBoxShow && (
        <PaymentLinkInfoAboutUse
          className={styles.invoicePageInfoBlock}
          name={PAGE_NAMES_TO_DISPLAY_INFO.PAYMENT_LINK}
          onClose={removePageNameToDisplayInfo}
        />
      )}
      {!hasActiveApiKeys && !isInitialLoading && (
        <div className={styles.textBox}>
          <strong>
            Please specify wallet address and generate API key in Payment Settings
          </strong>
        </div>
      )}
      <InvoiceFilters
        buttonElement={
          <CreateInvoiceButtonMemo
            onClick={createPaymentClickHandler}
            disabled={isInitialLoading}
          />
        }
        setInvoiceFilters={setInvoicesFilters}
        currencies={currencies}
        isInvoiceFilterSet={isInvoiceFilterSet}
      />
      <InvoiceHistoryTableMemo
        invoices={invoices}
        totalCount={invoicesTotalCount}
        onInvoiceRowClick={openInvoicePopup}
        loading={isInitialLoading || isInvoicesLoading}
        onCreatePaymentClick={createPaymentClickHandler}
      />
      {isPaginationShow && (
        <NewPagination
          className={styles.paginator}
          currentPage={invoicesPage}
          countItems={invoicesTotalCount}
          itemsPerPage={ELEMENTS_PER_PAGE}
          onChangePage={setInvoicesPage}
        />
      )}
      <CreateInvoicePopupMemo
        isOpen={isCreateInvoicePopupOpen}
        onClose={handleCreateInvoicePopupClose}
        openObservablePayment={openObservablePaymentPopup}
        updateInvoices={reloadInvoices}
      />
      <InvoiceDetailsPopup
        invoice={activeInvoice}
        isOpen={isInvoicePopupVisible}
        onClose={closeInvoicePopup}
        onPaymentRowClick={handlePaymentDetailsRowClick}
      />
      {/* Single payment in Waiting status info */}
      <ObservablePaymentPopupMemo
        isOpen={isObservablePaymentPopupOpen}
        onClose={closeObservablePaymentPopup}
        observablePaymentData={observablePaymentData}
      />
      {/* Single payment not in Wating status info */}
      <PaymentInfoPopupMemo
        payment={activePayment}
        setActivePayment={setActivePayment}
        isOpen={isPaymentInfoOpen}
        onClose={closePaymentInfoPopup}
      />
      <AddWalletCurrenciesPopup
        open={isAddWalletPopupActive}
        onClose={toggleAddWalletPopupActive}
        currencies={currencies}
        onCallback={handleAddWalletCallback}
        updateFetching={isUpdatingKeys}
      />
    </div>
  );
};
