import React, { Component } from "react";
import Modal from "react-modal";
import styles from "./styles.module.scss";

Modal.setAppElement("#root");

function withModal(WrappedComponent) {
  // eslint-disable-next-line react/display-name
  return class extends Component {
    render() {
      return (
        <Modal
          className={`${styles.modalBody} ${this.props.modalClassName || ""}`}
          overlayClassName={styles.modalOverlay}
          isOpen={this.props.isOpen}
          onRequestClose={this.props.onClose}
        >
          <WrappedComponent {...this.props} />
        </Modal>
      );
    }
  };
}

export default withModal;
