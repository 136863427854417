import React from 'react';
import { IpsType } from '../types';
import { ELEMENTS_PER_PAGE } from '../helpers';
import { safeToLowerCase } from '../../../helpers/safe-to-lower-case';

export const useNormalizedDataWithSearch = (ips: Array<string>) => {
  const [searchValue, setSearchValue] = React.useState('');
  const [page, setPage] = React.useState(1);

  const ipsFiltered = React.useMemo(() => (
    ips.filter((ip: string) => safeToLowerCase(ip).includes(safeToLowerCase(searchValue)))
  ), [searchValue, ips]);
  const ipsFilteredLength = React.useMemo(() => ipsFiltered?.length ?? 0, [ipsFiltered]);
  const ipsNormalized: IpsType = React.useMemo(() => (
    ipsFiltered.map((ip: string, index: number) => ({
      id: `${index}-${ip}`,
      number: String(index + 1),
      address: ip,
    }))
  ), [ipsFiltered]);
  const ipsWhitelistSliced = React.useMemo(() => {
    if (ipsFilteredLength <= ELEMENTS_PER_PAGE) {
      return ipsNormalized;
    }

    const startIndex = (page - 1) * ELEMENTS_PER_PAGE;
    const endIndex = page * ELEMENTS_PER_PAGE;

    return ipsNormalized.slice(startIndex, endIndex);
  }, [page, ipsNormalized, ipsFilteredLength]);

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
  };
  const handlePageChange = (value: number) => {
    setPage(value);
  };

  return {
    currentIps: ipsWhitelistSliced,
    ipsFilteredLength,
    handleSearchChange,
    handlePageChange,
    page,
    searchValue,
  };
};
