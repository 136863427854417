import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { App } from './App';
import { ErrorFallback } from './components/error-fallback';
import config from './config';
import './css/index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ErrorBoundary
    FallbackComponent={ErrorFallback}
    onReset={() => {
      window.location = config.acc_front_domain;
    }}
  >
    <App />
  </ErrorBoundary>,
);
