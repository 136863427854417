export const MASS_PAYOUTS_ERRORS = {
  INVALID_IP: {
    MESSAGE: 'Access denied | Invalid IP',
    NEW_MESSAGE: 'Your current IP address is not whitelisted',
  },
};

export const ERRORS = {
  INVALID_RECARTCHA: 'Invalid reCAPTCHA',
  SOLVE_RECARTCHA: 'Please solve reCAPTCHA correctly',
};

export const ERROR_TEXTS = {
  'AXIOS.TIMEOUT': 'An error occured. Please try to reload the page or contact us at support@nowpayments.io',
  'USER.CONFIRMATION.HASH.WRONG': 'Incorrect confirmation link',
  'USER.CONFIRMATION.HASH.EXPIRED': 'Your activation link is expired',
};
