import * as React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Section } from '../../../section';
import { ProvidersCards } from '../../../providers-cards';
import { LoaderIcon } from '../../../icons/loader-icon';
import { hasElementsInArray } from '../../../../helpers/has-elements-in-array';
import { getSplitProvidersByStatus } from '../../../../helpers/get-split-providers-by-status';
import { ExternalLinkWithUnderline } from '../../../external-link-with-underline';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import { ProvidersSectionProps } from '../../types';
import classes from './styles.module.scss';

export const ProvidersSection: React.FC<ProvidersSectionProps> = (props) => {
  const {
    description,
    link,
    providers,
    onActiveButtonClick,
    isLoading,
    partnerEmail,
  } = props;

  const { activatedProviders, availableProviders } = getSplitProvidersByStatus(providers);
  const isTablet = useMediaQuery('(min-width: 576px)');

  return (
    <Section
      className={classes.providers}
      title="Providers"
    >
      <p className={classes.providersDescription}>{description}</p>
      {isLoading && <LoaderIcon />}
      {!isLoading && hasElementsInArray(activatedProviders) && (
        <>
          <h4 className={classes.providersBlockTitle}>Activated</h4>
          <ProvidersCards
            cards={activatedProviders}
            onClick={onActiveButtonClick}
            isActivatedBlock
            partnerEmail={partnerEmail}
          />
        </>
      )}
      {!isLoading && hasElementsInArray(availableProviders) && (
        <>
          <h4 className={classes.providersBlockTitle}>Available</h4>
          <ProvidersCards
            cards={availableProviders}
            onClick={onActiveButtonClick}
            partnerEmail={partnerEmail}
          />
        </>
      )}
      <ExternalLinkWithUnderline
        href={link}
        text="All you need to know about providers"
      />
      {!isLoading && (
        <ReactTooltip
          className={classes.tooltip}
          id="providers-card-tooltip"
          place={isTablet ? 'right' : 'top'}
        />
      )}
    </Section>
  );
};
