import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setMarketInfoCode,
  setMarketInfoError,
  setMarketInfoFetched,
  setMarketInfoFetching,
  setMarketInfoFromCurrency,
  setMarketInfoNextRates,
  setMarketInfoUnprocessedRates,
} from '../store/market-info/reducer';
import { useInfoPrice } from '../api/modules/account/use-info-price';
import { isObject } from '../helpers/is-object';
import { checkErrorCode } from '../helpers/check-error-code';
import { getSafeErrorMessageText } from '../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../helpers/console-error-message';
import { marketInfoFetchingSelector } from '../store/market-info/selectors';
import { useTimeout } from './use-timeout';

export const useMarketInfoRates = ({ isNeedRates = false, fiatTicker = null, tickers = null }) => {
  const dispatch = useDispatch();
  const fetchInfoPrice = useInfoPrice();
  const marketInfoFetching = useSelector(marketInfoFetchingSelector());

  const initMarketInfoRates = React.useCallback(async () => {
    if (marketInfoFetching) {
      return;
    }

    dispatch(setMarketInfoFetching(true));
    const { data, status, errorMessage } = await fetchInfoPrice({ fiatTicker, to: tickers });

    if (status === 200 && isObject(data)) {
      const nextRates = data?.result ?? {};

      dispatch(setMarketInfoCode(data?.code ?? null));
      dispatch(setMarketInfoFromCurrency(data?.fromCurrency ?? null));
      dispatch(setMarketInfoNextRates(nextRates));
      dispatch(setMarketInfoUnprocessedRates(data?.unprocessed ?? []));
      dispatch(setMarketInfoError(data?.error ?? []));
      dispatch(setMarketInfoFetching(false));
      dispatch(setMarketInfoFetched(true));
    } else {
      checkErrorCode(data?.errorData?.code);
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/info/price');
      dispatch(setMarketInfoFetching(false));
    }
  }, [marketInfoFetching, fiatTicker, tickers]);

  useTimeout(initMarketInfoRates, 0, isNeedRates);
};
