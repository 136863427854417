import React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';
import { withdrawalPayoutRequestDataMapFn } from '../../helpers/account';

export const useRequestPayout = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (options) => {
    const currency = options?.ticker ?? null;
    const address = options?.address ?? null;
    const amount = options?.amount ?? null;
    const [cancelToken] = getRequestSource();
    const requestPromise = client.post(
      '/request-payout',
      { currency, address, amount },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      withdrawalPayoutRequestDataMapFn,
    );
  }, [getRequestSource]);
};
