import * as React from 'react';
import PropTypes from 'prop-types';
import { CreateSubscriptionPlanForm } from '../../../create-subscription-plan-form';
import { Button } from '../../../ui/button';
import { CustomerPageContext } from '../../customer-page';
import classes from './styles.module.scss';

export const CustomerCreateBillingPlanForm = (props) => {
  const { onButtonClick } = props;

  const customerPageContext = React.useContext(CustomerPageContext);
  const {
    fullBaseCurrency,
    onBillingPlanSubmit: onSubmit,
    isBillingPlanCreating: fetching,
    createBillingPlanApiError: errorApiMessage,
  } = customerPageContext;

  return (
    <>
      <CreateSubscriptionPlanForm
        className={classes.customerCreateBillingPlanForm}
        fullBaseCurrency={fullBaseCurrency}
        onSubmit={onSubmit}
        errorApiMessage={errorApiMessage}
        pending={fetching}
      />
      <Button
        className={classes.customerCreateBillingPlanFormButton}
        onClick={onButtonClick}
        displayBorder
      >
        Choose from billing plans
      </Button>
    </>
  );
};

CustomerCreateBillingPlanForm.defaultProps = {
  onButtonClick: null,
};

CustomerCreateBillingPlanForm.propTypes = {
  onButtonClick: PropTypes.func,
};
