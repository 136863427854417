import React from "react";
import styles from "../donation-preview-btn/styles.module.scss";
import { front_domain } from "../../config";

export const CodePreview = ({ apiKey = "as" }) => {
  return (
    <pre className={styles.wrapperCode}>
      <code className={styles.code}>
        <span>
          &lt;<span className={styles.tagName}>iframe</span>
          &nbsp;<span>src</span>=
          <span className={styles.tagString}>
            &quot;{front_domain}/embeds/donation-widget?api_key={apiKey}&source=lk_donation&medium=referral&quot;
          </span>
          &nbsp;<span>frameborder</span>=
          <span className={styles.tagString}>&quot;0&quot;</span>
          &nbsp;<span>scrolling</span>=
          <span className={styles.tagString}>&quot;no&quot;</span>
          &nbsp;<span>style</span>=
          <span className={styles.tagString}>
            &quot;overflow-y: hidden;&quot;
          </span>
          &nbsp;<span>width</span>=
          <span className={styles.tagString}>&quot;354&quot;</span>
          &nbsp;<span>height</span>=
          <span className={styles.tagString}>&quot;680&quot;</span>&gt;
        </span>
        <span>
          &lt;/<span className={styles.tagName}>iframe</span>&gt;
        </span>
      </code>
    </pre>
  );
};
