import * as React from 'react';
import { Helmet } from 'react-helmet';
import { OffRampPage } from '../../components/fiat-operations-page';

export const OffRamp = () => (
  <>
    <Helmet>
      <title>Off Ramp</title>
    </Helmet>
    <OffRampPage />
  </>
);
