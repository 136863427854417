import React from "react";
import commonStyles from "../../css/common.module.scss";

function CommonBlock({ children, header, className = "" }) {
  return (
    <>
      <h2>{header}</h2>
      <div className={`${className} ${commonStyles.panel}`}>{children}</div>
    </>
  );
}

export default CommonBlock;
