import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { isFunction } from '../../../helpers/is-function';
import { hasElementsInArray } from '../../../helpers/has-elements-in-array';
import useOutsideClick from '../../../hooks/use-outside-click';
import { LoaderIcon } from '../../icons/loader-icon';
import styles from './styles.module.scss';

export const SimpleSelect = (props) => {
  const {
    className,
    id,
    onSelect,
    items,
    defaultValue,
    defaultImagePath,
    reset,
    fetching,
    placeholder,
  } = props;

  const hasItems = hasElementsInArray(items);

  const ref = React.useRef(null);
  const [value, setValue] = React.useState(defaultValue);
  const [show, setShow] = React.useState(false);
  const [valueLogo, setValueLogo] = React.useState(defaultImagePath || "");

  const handleSelect = (item) => () => {
    const key = item?.key ?? null;
    const value = item?.value ?? null;
    const logoUrl = item?.logoUrl ?? "";

    if (isFunction(onSelect)) {
      onSelect(key);
    }
    setValue(value);
    setValueLogo(logoUrl);
    setShow(false);
  };
  const handleToggle = () => {
    setShow((prevState) => !prevState);
  };
  const handleClose = () => {
    if (show) {
      setShow(false);
    }
  };

  React.useEffect(() => {
    if (reset) {
      setValue(defaultValue);
      setValueLogo(defaultImagePath);
    }
  }, [reset]);

  React.useEffect(() => {
    if (!value && defaultValue) {
      setValue(defaultValue);
    }
    if (!valueLogo && defaultImagePath) {
      setValueLogo(defaultImagePath);
    }
  }, [value, defaultValue, valueLogo, defaultImagePath]);

  useOutsideClick(ref, handleClose);

  const isPlaceholder = !fetching && !value && placeholder;
  const isValue = !fetching && value;
  const isValueLogo = !fetching && valueLogo;

  return (
    <div
      className={cn([
        styles.simpleSelect,
        className,
        show && styles.simpleSelectActive,
      ])}
      id={id}
      ref={ref}
    >
      <div
        className={styles.simpleSelectValue}
        onClick={handleToggle}
        onKeyDown={handleToggle}
        tabIndex={0}
        role="button"
      >
        {fetching && (
          <LoaderIcon
            size={25}
            className={styles.simpleSelectLoader}
          />
        )}
        {isPlaceholder && (
          <span className={styles.simpleSelectPlaceholder}>
            {placeholder}
          </span>
        )}
        {isValueLogo && (
          <img
            src={valueLogo}
            alt="logo"
            width={24}
            height={24}
            decoding="async"
            loading="lazy"
          />
        )}
        {isValue && value}
        <img
          className={styles.simpleSelectArrow}
          src="/images/select-arrow-icon.svg"
          alt="Arrow Icon"
          decoding="async"
        />
      </div>
      <div className={cn(styles.simpleSelectContent)}>
        <div className={styles.simpleSelectScrollContainer}>
          <ul className={styles.simpleSelectList}>
            {hasItems && items.map((item) => (
              <li
                key={item?.key}
                onClick={handleSelect(item)}
              >
                {item?.logoUrl && (
                  <img
                    src={item?.logoUrl}
                    alt={item?.key}
                    width={24}
                    height={24}
                    decoding="async"
                    loading="lazy"
                  />
                )}
                {item?.value}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

SimpleSelect.defaultProps = {
  className: null,
  id: null,
  onSelect: null,
  items: null,
  defaultValue: null,
  defaultImagePath: null,
  reset: false,
  fetching: false,
  placeholder: null,
};

SimpleSelect.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  onSelect: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
    logoUrl: PropTypes.string,
  })),
  defaultValue: PropTypes.string,
  defaultImagePath: PropTypes.string,
  reset: PropTypes.bool,
  fetching: PropTypes.bool,
  placeholder: PropTypes.string,
};
