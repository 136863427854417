import * as React from 'react';

export const PlusIcon: React.FC<{ color: string }> = (props) => {
  const { color } = props;

  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.79054 1.88092C4.79054 1.18298 5.33174 0.617188 5.99933 0.617188C6.66693 0.617188 7.20813 1.18298 7.20813 1.88092V10.0128C7.20813 10.7107 6.66693 11.2765 5.99933 11.2765C5.33174 11.2765 4.79054 10.7107 4.79054 10.0128V1.88092Z"
        fill={color}
      />
      <path
        d="M1.98835 7.21059C1.2904 7.21059 0.724609 6.6694 0.724609 6.0018C0.724609 5.33421 1.2904 4.79301 1.98835 4.79301H10.1202C10.8182 4.79301 11.384 5.33421 11.384 6.0018C11.384 6.6694 10.8182 7.21059 10.1202 7.21059H1.98835Z"
        fill={color}
      />
    </svg>
  );
};
