import * as React from 'react';
import { debounce, identity } from 'lodash-es';

export const useDebounce = (func, timeout) => {
  const funcRef = React.useRef(null);

  React.useEffect(() => {
    if (funcRef.current) {
      funcRef.current.cancel();
    }

    funcRef.current = debounce(func, timeout, {
      trailing: true,
    });
  }, [timeout]);

  const call = React.useCallback((...args) => {
    const funcToCall = funcRef.current || identity;
    funcToCall(...args);
  }, []);

  const cancel = React.useCallback(() => {
    const funcToCall = funcRef.current?.cancel || identity;
    funcToCall();
  });

  return {
    call,
    cancel,
  };
};
