import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { CustomInformationLinkProps } from './types';
import classes from './styles.module.scss';

export const CustomInformationLink:React.FC<CustomInformationLinkProps> = (props) => {
  const {
    className,
    href,
    isSpaLink,
    children,
  } = props;

  return (
    <div className={cn(className, classes.customInformationLink)}>
      {isSpaLink ? (
        <Link to={href}>
          <img
            src="/images/info-blue-icon.svg"
            alt="info"
            decoding="async"
            width={24}
            height={24}
          />
          {children}
        </Link>
      ) : (
        <a href={href}>
          <img
            src="/images/info-blue-icon.svg"
            alt="info"
            decoding="async"
            width={24}
            height={24}
          />
          {children}
        </a>
      )}
    </div>
  );
};
