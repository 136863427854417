import { Cancel } from 'axios';
import { ERROR_CODES } from '../../constants/app-constants';
import { ERROR_TEXTS } from '../../constants/errors-constants';

const restRequestWrapper = async (
  requestPromise,
  defaultData = null,
  mapFn = (data) => data,
) => {
  const response = {
    status: 0,
    statusText: undefined,
    errorMessage: '',
    data: defaultData,
    isError: false,
  };

  try {
    const {
      data,
      status,
      statusText,
      error,
    } = await requestPromise;

    response.error = error;
    response.status = status;
    response.statusText = statusText;
    response.data = mapFn(data);

    return response;
  } catch (error) {
    response.isCanceled = error instanceof Cancel;
    response.data = {
      ...defaultData,
      errorData: error?.response?.data || null,
      status: error?.response?.status || null,
    };
    response.errorMessage = error.code === ERROR_CODES.ECONNABORTED
      ? ERROR_TEXTS['AXIOS.TIMEOUT']
      : error.message;
    response.isError = error instanceof Error;
    response.isTimeoutError = error.code === ERROR_CODES.ECONNABORTED;
  }

  return response;
};

export default restRequestWrapper;
