import React from "react";

export const ButtonPreview = ({ isWhiteTheme }) => {
  return (
    <a
      href="https://nowpayments.io/donation?api_key=XXXXXXX-XXXXXXX-XXXXXXX-XXXXXXX"
      target="_blank"
      rel="noreferrer"
    >
      {isWhiteTheme ? (
        <img
          src="https://nowpayments.io/images/embeds/donation-button-white.svg"
          alt="Cryptocurrency & Bitcoin donation button by NOWPayments"
          width={"229"}
          height={"70"}
        />
      ) : (
        <img
          src="https://nowpayments.io/images/embeds/donation-button-black.svg"
          alt="Crypto donation button by NOWPayments"
          width={"229"}
          height={"70"}
        />
      )}
    </a>
  );
};
