import * as React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  partnerFetchedSelector,
  partnerFetchingSelector,
} from '../../../../store/partner/selectors';
import { useFetchPartnerProfile } from '../../../../api/modules/account/use-fetch-partner-profile';
import { LoaderIcon } from '../../../icons/loader-icon';
import { useAllCurrencies } from '../../../../hooks/use-all-currencies';
import { PageHeader } from '../../../ui/page-header';
import { usePageNamesToDisplayInfo } from '../../../../hooks/use-page-names-to-display-info';
import { PAGE_NAMES_TO_DISPLAY_INFO } from '../../../../constants/app-constants';
import { InnerNavigation } from '../../../shared/inner-navigation';
import { INNER_NAVIGATION_ITEMS } from '../../helpers';
import { CustomersInfoAboutUse } from '../customers-info-about-use';
import classes from './styles.module.scss';

export const PagesWrapper = (props) => {
  const {
    contentShow,
    CustodySteps,
    children,
  } = props;

  const isLoaderShow = useSelector(partnerFetchingSelector() || !partnerFetchedSelector());

  const {
    infoBoxShow,
    addPageNameToDisplayInfo,
    removePageNameToDisplayInfo,
  } = usePageNamesToDisplayInfo(PAGE_NAMES_TO_DISPLAY_INFO.CUSTOMERS);

  useFetchPartnerProfile();
  useAllCurrencies();

  return (
    <div className={classes.pagesWrapper}>
      <PageHeader
        className={classes.title}
        title="Customer management"
        showTooltipFirstText={!infoBoxShow}
        onClick={infoBoxShow ? removePageNameToDisplayInfo : addPageNameToDisplayInfo}
        infoTooltipShow={contentShow}
      />
      {isLoaderShow && (
        <LoaderIcon size={40} className={classes.loader} />
      )}
      {CustodySteps}
      {contentShow && (
        <>
          {infoBoxShow && (
            <CustomersInfoAboutUse
              className={classes.infoBlock}
              name={PAGE_NAMES_TO_DISPLAY_INFO.CUSTOMERS}
              onClose={removePageNameToDisplayInfo}
            />
          )}
          <InnerNavigation items={INNER_NAVIGATION_ITEMS} />
          <div className={classes.content}>
            {children}
          </div>
        </>
      )}
    </div>
  );
};

PagesWrapper.defaultProps = {
  contentShow: false,
  CustodySteps: null,
  children: null,
};

PagesWrapper.propTypes = {
  contentShow: PropTypes.bool,
  CustodySteps: PropTypes.element,
  children: PropTypes.node,
};
