import React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';

const currencyMapFnItem = (data) => ({
  currency: data?.currency ?? null,
  count: data?.count ?? null,
  amount: data?.amount ?? null,
  price: data?.price ?? null,
  baseCurrency: data?.base_currency ?? null,
  baseAmount: data?.base_amount ?? null,
});
const objectMapFn = (data) => {
  const result = {};

  for (const key in data) {
    const item = data[key];

    result[key] = {
      baseAmount: item?.base_amount ?? null,
      baseCurrency: item?.base_currency ?? null,
      count: item?.count ?? null,
      currencies: (item?.currencies ?? []).map(currencyMapFnItem),
    };
  }

  return result;
};
const mapFn = (data) => ({
  result: objectMapFn(data?.result ?? {}),
});

export const useStatisticsDates = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (options) => {
    const period = options?.period || null;
    const base_currency = options?.baseCurrency || null;
    const currency = options?.currency || null;
    const date_from = options?.dateFrom || null;
    const date_to = options?.dateTo || null;
    const [cancelToken] = getRequestSource();
    const requestPromise = client.get(
      '/stats/payments/date',
      {
        params: {
          period,
          base_currency,
          currency,
          date_from,
          date_to,
        },
        cancelToken,
      },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [client, getRequestSource]);
};
