import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useMediaQuery } from '../../hooks/use-media-query';
import { HistoryTableMemo } from '../history-table';
import { NewPagination } from '../shared/new-pagination';
import { hasElementsInArray } from '../../helpers/has-elements-in-array';
import classes from './styles.module.scss';

export const HistoryTableWithPagination = (props) => {
  const {
    className,
    classNameHeader,
    items,
    hasSearchData,
    fetching,
    apiError,
    minWidth,
    desktop,
    thsDesktop,
    thsMobile,
    perPage,
    emptyText,
    emptyElement,
    notFoundText,
    count,
    onPaginatorClick,
    page,
    children,
    HeaderElement,
  } = props;

  const isCurrentDesktop = desktop || useMediaQuery('(min-width: 1200px)');
  const thItems = isCurrentDesktop
    ? thsDesktop
    : thsMobile;
  const isPaginationShow = count > perPage;
  const hasItems = hasElementsInArray(items);

  return (
    <>
      {HeaderElement && (
        <div className={cn(classes.headerElement, classNameHeader)}>
          {HeaderElement}
        </div>
      )}
      <div className={cn(classes.historyTableWithPagination, className)}>
        <HistoryTableMemo
          className={classes.table}
          thItems={thItems}
          colSpan={6}
          loading={fetching}
          historyData={items}
          hasData={hasSearchData || hasItems}
          emptyText={emptyText}
          emptyElement={emptyElement}
          notFoundText={notFoundText}
          apiError={apiError}
          minWidth={minWidth}
        >
          {children}
        </HistoryTableMemo>
      </div>
      {isPaginationShow && (
        <NewPagination
          className={classes.paginator}
          currentPage={page}
          countItems={count}
          itemsPerPage={perPage}
          onChangePage={onPaginatorClick}
        />
      )}
    </>
  );
};

HistoryTableWithPagination.defaultProps = {
  className: null,
  classNameHeader: null,
  items: null,
  hasSearchData: false,
  fetching: false,
  apiError: null,
  minWidth: null,
  desktop: null,
  thsDesktop: null,
  thsMobile: null,
  perPage: null,
  emptyText: null,
  emptyElement: null,
  notFoundText: null,
  count: null,
  onPaginatorClick: null,
  page: null,
  children: null,
  HeaderElement: null,
};

HistoryTableWithPagination.propTypes = {
  className: PropTypes.string,
  classNameHeader: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  hasSearchData: PropTypes.bool,
  fetching: PropTypes.bool,
  apiError: PropTypes.string,
  minWidth: PropTypes.number,
  desktop: PropTypes.bool,
  thsDesktop: PropTypes.arrayOf(PropTypes.object),
  thsMobile: PropTypes.arrayOf(PropTypes.object),
  perPage: PropTypes.number,
  emptyText: PropTypes.string,
  emptyElement: PropTypes.node,
  notFoundText: PropTypes.string,
  count: PropTypes.number,
  onPaginatorClick: PropTypes.func,
  page: PropTypes.number,
  children: PropTypes.node,
  HeaderElement: PropTypes.node,
};
