import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { hasElementsInArray } from '../../helpers/has-elements-in-array';
import { MainMenuItemMemo } from '../main-menu-item';
import classes from './styles.module.scss';

export const MainMenu = (props) => {
  const {
    className,
    items,
    onSignOut,
    onClick,
  } = props;

  const hasItems = hasElementsInArray(items);

  if (!hasItems) {
    return null;
  }

  return (
    <ul className={cn(classes.mainMenu, className)}>
      {items.map((item) => (
        <MainMenuItemMemo
          key={item?.id}
          className={classes.mainMenuItem}
          name={item?.name}
          href={item?.href}
          submenu={item?.submenu}
          Icon={item?.Icon}
          externalLink={item?.isExternalLink}
          beta={item?.isBeta}
          onClick={item?.href === '/signout' ? onSignOut : onClick}
          hasNewItem={item?.hasNewItem}
        />
      ))}
    </ul>
  );
};

MainMenu.defaultProps = {
  className: null,
  items: null,
  onSignOut: null,
  onClick: null,
};

MainMenu.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    href: PropTypes.string,
    submenu: PropTypes.arrayOf(PropTypes.object),
    Icon: PropTypes.element,
  })),
  onSignOut: PropTypes.func,
  onClick: PropTypes.func,
};
