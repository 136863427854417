import React from 'react';
import { Popup } from '../../../popup';
import { Portal } from '../../../portal';
import { POPUP_TYPE } from '../../helpers';
import { NewIpnKey } from '../new-ipn-key';
import { RegenerateIpnKey } from '../regenerate-ipn-key';
import classes from './styles.module.scss';

type IpnPopupProps = {
  type: string | null,
  onClose: () => void,
  onProceed: () => void,
  onGenerateIpnKey: () => Promise<void>,
  newIpnKey: string | null,
  isNewIpnKeyLoading: boolean,
  newIpnKeyError: string | null,
};

export const IpnPopup: React.FC<IpnPopupProps> = (props) => {
  const {
    type,
    onClose,
    onProceed,
    onGenerateIpnKey,
    newIpnKey,
    isNewIpnKeyLoading,
    newIpnKeyError,
  } = props;

  return (
    <Portal>
      <Popup
        classNameContent={classes.ipnPopup}
        open={Boolean(type)}
        title={type}
        onClose={onClose}
        withCloseButton
        line
        mobileFullView
        withoutPadding
      >
        {type === POPUP_TYPE.newIpnKey ? (
          <NewIpnKey
            className={classes.ipnPopupContent}
            buttonClassName={classes.ipnPopupButton}
            onClose={onClose}
            onGenerateIpnKey={onGenerateIpnKey}
            newIpnKey={newIpnKey}
            isNewIpnKeyLoading={isNewIpnKeyLoading}
            newIpnKeyError={newIpnKeyError}
          />
        ) : (
          <RegenerateIpnKey
            className={classes.ipnPopupContent}
            buttonClassName={classes.ipnPopupButton}
            onClose={onClose}
            onProceed={onProceed}
          />
        )}
      </Popup>
    </Portal>
  );
};
