import React from 'react';
import PropTypes from 'prop-types';
import { QRCodeSVG } from 'qrcode.react';

export const QrCodeWithLogo = (props) => {
  const {
    value,
    size,
    bgColor,
    fgColor,
    imagePath,
    imageWidth,
    imageHeight,
    excavate,
    opacity,
  } = props;

  return (
    <QRCodeSVG
      value={value}
      size={size}
      bgColor={bgColor}
      fgColor={fgColor}
      imageSettings={{
        src: imagePath,
        width: imageWidth,
        height: imageHeight,
        excavate,
        opacity,
      }}
    />
  );
};

QrCodeWithLogo.defaultProps = {
  value: '',
  size: 160,
  bgColor: '#ffffff',
  fgColor: '#000000',
  imagePath: null,
  imageWidth: null,
  imageHeight: null,
  excavate: false,
  opacity: 1,
};

QrCodeWithLogo.propTypes = {
  value: PropTypes.string,
  size: PropTypes.number,
  bgColor: PropTypes.string,
  fgColor: PropTypes.string,
  imagePath: PropTypes.string,
  imageWidth: PropTypes.number,
  imageHeight: PropTypes.number,
  excavate: PropTypes.bool,
  opacity: PropTypes.number,
};
