import { safeToUpperCase } from './safe-to-upper-case';
import { toFormat } from './to-format';

/**
 * @param {number|string|null} amount
 * @param {string} ticker
 * @param {string} defaultResult
 * @returns {string}
 */
export const getAmountWithTicker = (amount = null, ticker = '', defaultResult = '-') => {
  const amountNormalized = amount || null;
  const amountFormatted = amount ? toFormat(amount) : null;
  const tickerUpperCased = ticker ? safeToUpperCase(ticker) : '';

  return amountNormalized ? `${amountFormatted} ${tickerUpperCased}` : defaultResult;
};
