import React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';

export const LOGIN_VERIFICATION_PATH = '/login-verification';

const mapFn = (data) => ({
  hasTeam: data?.hasTeam ?? false,
  partnerId: data?.partnerId ?? null,
  token: data?.token ?? null,
});

export const useLoginVerification = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (options) => {
    const uuid = options?.uuid ?? null;
    const [cancelToken] = getRequestSource();
    const requestPromise = client.post(
      LOGIN_VERIFICATION_PATH,
      { uuid },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
