import React from 'react';
import classes from '../whitelist-popup/styles.module.scss';
import { Button } from '../../../ui/button';
import { Field } from '../../../ui/field';
import { isValidIPAddress } from '../../../../helpers/is-valid-ip-address';
import { LoaderIcon } from '../../../icons/loader-icon';

type ContentForAddAddressType = {
  onClick: (value: string) => void,
  fetching: boolean,
  apiError: string | null,
};

export const ContentForAddAddress: React.FC<ContentForAddAddressType> = (props) => {
  const {
    onClick,
    fetching,
    apiError,
  } = props;

  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState<string | null>(null);

  const handleChange = (nextValue: string) => {
    setValue(nextValue);
    setError(null);
  };
  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (!value) {
      setError('Ip address is required field');
    }

    const isValid = isValidIPAddress(value);

    if (!isValid) {
      setError('Ip address is not valid');
      return;
    }

    onClick(value);
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <div>
        Once you add an IP address to the Whitelist, you will be able to request withdrawals with it.
      </div>
      <Field
        className={classes.whitelistPopupField}
        title="IP address"
        data={{
          id: 'ip-address',
          value,
          onChange: handleChange,
          name: 'additional-ip',
          placeholder: '000.111.222.255',
          error,
          pattern: /^[\w.,:/]*$/,
        }}
      />
      <div className={classes.whitelistPopupButtons}>
        <Button
          type="submit"
          className={classes.whitelistPopupButton}
        >
          {fetching ? (
            <LoaderIcon size={30} path="/images/loader-white-on-blue-icon.gif" />
          ) : 'Confirm'}
        </Button>
      </div>
      {apiError && (
        <div className={classes.whitelistPopupError}>{apiError}</div>
      )}
    </form>
  );
};
