import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from '../ui/field';
import { Button } from '../ui/button';
import { isFunction } from '../../helpers/is-function';
import { LoaderIcon } from '../icons/loader-icon';
import { CurrenciesSelectField } from '../currencies-select-field';
import { safeToLowerCase } from '../../helpers/safe-to-lower-case';
import { hasElementsInArray } from '../../helpers/has-elements-in-array';
import { getSafeErrorMessageText } from '../../helpers/get-safe-error-message-text';
import { useCreateApiKey } from '../../api/modules/account/use-create-api-key';
import { safeToUpperCase } from '../../helpers/safe-to-upper-case';
import { consoleErrorMessage } from '../../helpers/console-error-message';
import { useCreateStoreOutcomeWallet } from '../../api/modules/account/use-create-store-outcome-wallet';
import { setApiKeysFetched, setWallets } from '../../store/payment-settings/reducer';
import { apiKeysHasActiveKeySelector } from '../../store/payment-settings/selectors';
import { WARNING_CURRENCIES } from '../../constants/currencies-constants';
import classes from './styles.module.scss';

const ADDRESS_ERROR_TEXT = 'Please check your address';
const EXTRA_ID_ERROR_TEXT = 'Please check your extra id';
export const SCHEMA = yup.object({
  currency: yup.string().required('Currency is required field'),
  address: yup.string().required('Address is required field'),
  extraId: yup.string(),
});

export const AddWalletCurrencies = (props) => {
  const {
    className,
    currencies,
    onCallback,
    callbackFetching,
    formAttributes,
    onGenerateApiKey,
    isAddressHiddenFirst,
    showWarning,
  } = props;

  const {
    currencyTitle,
    currencyPlaceholder,
    isSmallTheme,
    walletTitle,
    walletPlaceholder,
    memoTitle,
    memoPlaceholder,
    submitButtonLabel,
  } = formAttributes;

  const dispatch = useDispatch();
  const hasApiKeys = useSelector(apiKeysHasActiveKeySelector());
  const fetchCreateStoreOutcomeWallet = useCreateStoreOutcomeWallet();
  const fetchCreateApiKey = useCreateApiKey();

  const {
    control,
    handleSubmit,
    formState,
    setError,
    resetField,
  } = useForm({
    resolver: yupResolver(SCHEMA),
  });
  const {
    currency,
    address,
    extraId,
  } = formState.errors;

  const currencyErrorMessage = currency?.message ?? null;
  const addressErrorMessage = address?.message ?? null;
  const extraIdErrorMessage = extraId?.message ?? null;

  const [currencySelected, setCurrencySelected] = React.useState(null);
  const [isFetching, setIsFetching] = React.useState(false);
  const [errorApiMessage, setErrorApiMessage] = React.useState(null);
  const [isWalletAddressValid, setIsWalletAddressValid] = React.useState(false);
  const [isExtraIdValid, setIsExtraIdValid] = React.useState(false);
  const [isAddressHidden, setIsAddressHidden] = React.useState(isAddressHiddenFirst);
  const [isWarningCurrency, setIsWarningCurrency] = React.useState(false);

  const walletReqEx = currencySelected?.wallet_regex ?? null;
  const isExtraIdExists = currencySelected?.extra_id_exists ?? false;
  const extraIdRegex = currencySelected?.extra_id_regex ?? null;

  const currenciesFiltered = React.useMemo(() => (
    hasElementsInArray(currencies)
      ? currencies.filter((item) => !item?.isFiat)
      : []
  ), [currencies]);

  const handleCurrencySelect = (func) => (currency) => {
    const ticker = currency?.code ?? '';
    const tickerLowerCased = safeToLowerCase(ticker);

    func(tickerLowerCased);
    setCurrencySelected(currency);
    setIsWalletAddressValid(false);
    setIsExtraIdValid(false);
    resetField('address');
    resetField('extraId');
    setIsAddressHidden(false);
    setIsWarningCurrency(WARNING_CURRENCIES.includes(ticker));
  };
  const handleWalletAddressChange = (func) => (value) =>  {
    func(value);

    const isValid = value.match(walletReqEx);

    if (isValid) {
      setIsWalletAddressValid(true);
    } else {
      setIsWalletAddressValid(false);
    }
  };
  const handleExtraIdChange = (func) => (value) =>  {
    func(value);

    const isValid = value.match(extraIdRegex);

    if (isValid) {
      setIsExtraIdValid(true);
    } else {
      setIsExtraIdValid(false);
    }
  };
  const handleFormSubmit = async (formData) => {
    const currency = formData?.currency ?? null;
    const currencyUpperCased = safeToUpperCase(currency);
    const address = formData?.address ?? null;
    const extraId = formData?.extraId ?? null;

    if (!isWalletAddressValid) {
      setError('address', { type: 'error', message: ADDRESS_ERROR_TEXT });

      return;
    }

    if (isExtraIdExists && !isExtraIdValid) {
      setError('extraId', { type: 'error', message: EXTRA_ID_ERROR_TEXT });

      return;
    }

    setIsFetching(true);
    const wallet = {
      outcomeCurrency: currencyUpperCased,
      outcomeAddress: address,
      eid: extraId,
    };
    const { data, status, errorMessage } = await fetchCreateStoreOutcomeWallet(wallet);


    if (status === 200) {
      dispatch(setWallets(data));

      if (!window.sessionStorage.getItem('register-api-key') && !hasApiKeys) {
        const {
          data,
          status,
          errorMessage,
        } = await fetchCreateApiKey();

        if (status === 200 && isFunction(onGenerateApiKey)) {
          onGenerateApiKey(data?.apiKey);
        } else if (status === 200 && !onGenerateApiKey) {
          dispatch(setApiKeysFetched(false));
        } else {
          const currentErrorMessage = getSafeErrorMessageText(data?.errorData?.message);
          consoleErrorMessage(errorMessage, currentErrorMessage, '/api-keys/create');
        }
        window.sessionStorage.setItem('register-api-key', true);
      }

      if (isFunction(onCallback)) {
        onCallback();
      }
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/store-outcome-wallet');
      setErrorApiMessage(errorDataMessage);
    }

    setIsFetching(false);
  };

  return (
    <div className={cn(classes.box, className)}>
      <div className={classes.title}>
        Add wallet address
      </div>
      <div className={classes.description}>
        We ask you to add your wallet address in order to access the full features of our service!
      </div>
      <div className={classes.line} />
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Controller
          name="currency"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <CurrenciesSelectField
              className={classes.formCurrencies}
              label={currencyTitle || "Currency"}
              placeholder={currencyPlaceholder || "All currencies"}
              placeholderText={currencyPlaceholder ? null : "Client can choose any supported crypto for payment"}
              currencies={currenciesFiltered}
              onSelect={handleCurrencySelect(field.onChange)}
              error={currencyErrorMessage}
              defaultTicker={currencyPlaceholder ? null : "BTC"}
              smallTheme={isSmallTheme}
            />
          )}
        />
        {!isAddressHidden && (
          <Controller
            name="address"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Field
                className={classes.formInput}
                title={walletTitle || "Wallet address"}
                data={{
                  type: 'text',
                  value: field.value,
                  onChange: handleWalletAddressChange(field.onChange),
                  placeholder: walletPlaceholder || '1HP2esjU9H914ML7NHg7rX4w4wPGpZkLez',
                  error: addressErrorMessage,
                  Icon: isWalletAddressValid
                    ? <img src="/images/copy-success-icon.svg" alt="Icon" />
                    : null,
                }}
              />
            )}
          />
        )}
        {isExtraIdExists && (
          <Controller
            name="extraId"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Field
                className={classes.formInput}
                title={memoTitle || "Destination tag (memo)"}
                data={{
                  type: 'text',
                  value: field.value,
                  onChange: handleExtraIdChange(field.onChange),
                  placeholder: memoPlaceholder || '123456789',
                  error: extraIdErrorMessage,
                  Icon: isExtraIdValid
                    ? <img src="/images/copy-success-icon.svg" alt="Icon" />
                    : null,
                }}
              />
            )}
          />
        )}
        {showWarning && isWarningCurrency && (
          <div className={classes.warning}>
            ETH network fees are quite high at the moment: you might consider temporarily switching USDTTRC20.
            Contact us at partner@nowpayments.io if you want to explore other options.
          </div>
        )}
        <div className={classes.apiError}>
          {errorApiMessage}
        </div>
        <Button
          type="submit"
          className={classes.button}
          disabled={isFetching || callbackFetching}
        >
          {isFetching || callbackFetching
            ? <LoaderIcon path="/images/loader-white-on-blue-icon.gif" />
            : submitButtonLabel || 'Confirm'}
        </Button>
      </form>
    </div>
  );
};

AddWalletCurrencies.defaultProps = {
  className: null,
  currencies: null,
  onCallback: null,
  callbackFetching: false,
  formAttributes: {},
  onGenerateApiKey: null,
  isAddressHiddenFirst: false,
  showWarning: false,
};

AddWalletCurrencies.propTypes = {
  className: PropTypes.string,
  currencies: PropTypes.arrayOf(PropTypes.shape({})),
  onCallback: PropTypes.func,
  callbackFetching: PropTypes.bool,
  formAttributes: PropTypes.shape({
    currencyTitle: PropTypes.string,
    currencyPlaceholder: PropTypes.string,
    isSmallTheme: PropTypes.bool,
    walletTitle: PropTypes.string,
    walletPlaceholder: PropTypes.string,
    memoTitle: PropTypes.string,
    memoPlaceholder: PropTypes.string,
    submitButtonLabel: PropTypes.string,
  }),
  showWarning: PropTypes.bool,
  isAddressHiddenFirst: PropTypes.bool,
  onGenerateApiKey: PropTypes.func,
};
