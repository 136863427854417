import React from 'react';
import { POPUP_OBJECT } from '../../helpers';
import { PopupData } from '../../types';
import classes from './styles.module.scss';

type ApiKeysOptionsProps = {
  onClick: (type: PopupData) => void,
};

export const ApiKeysOptions: React.FC<ApiKeysOptionsProps> = (props) => {
  const { onClick } = props;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const popup = POPUP_OBJECT[e.currentTarget.id as keyof typeof POPUP_OBJECT];
    onClick(popup);
  };

  return (
    <div className={classes.apiKeysOptions}>
      <button
        type="button"
        id={POPUP_OBJECT.edit.type}
        onClick={handleClick}
      >
        <img
          src="/images/settings-edit.svg"
          alt="Icon"
          decoding="async"
          loading="lazy"
          width={20}
          height={20}
        />
        Edit API key
      </button>
      <button
        type="button"
        id={POPUP_OBJECT.delete.type}
        onClick={handleClick}
      >
        <img
          src="/images/settings-delete.svg"
          alt="Icon"
          decoding="async"
          loading="lazy"
          width={20}
          height={20}
        />
        Delete API key
      </button>
    </div>
  );
};
