import React from 'react';
import { NumberStepperInput } from '../../../ui/number-stepper-input';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import classes from './styles.module.scss';

type SetUpNotificationsProps = {
  ipnTimeout: string | null,
  ipnNumRecurrent: string | null,
  onSaveNotifications: (ipnNumRecurrent: string, ipnTimeout: string) => Promise<void>;
};

export const SetUpNotifications: React.FC<SetUpNotificationsProps> = (props) => {
  const {
    ipnTimeout,
    ipnNumRecurrent,
    onSaveNotifications,
  } = props;

  const isDesktop = useMediaQuery('(min-width: 992px)');

  const [currentIpnTimeout, setCurrentIpnTimeout] = React.useState<null | string>(null);
  const [currentIpnNumRecurrent, setCurrentIpnNumRecurrent] = React.useState<null | string>(null);

  const handleIpnTimeoutChange = (value: string) => {
    setCurrentIpnTimeout(value);
  };
  const handleIpnNumRecurrentChange = (value: string) => {
    setCurrentIpnNumRecurrent(value);
  };

  React.useEffect(() => {
    let timerId: null | NodeJS.Timeout = null;

    const isChanged = currentIpnTimeout !== null || currentIpnNumRecurrent !== null;
    const isDifferentFromInitial = currentIpnTimeout !== ipnTimeout || currentIpnNumRecurrent !== ipnNumRecurrent;
    const timeout = currentIpnTimeout || ipnTimeout;
    const numRecurrent = currentIpnNumRecurrent || ipnNumRecurrent;

    if (isChanged && isDifferentFromInitial && timeout && numRecurrent) {
      timerId = setTimeout(() => {
        onSaveNotifications(numRecurrent, timeout);
      }, 3000);
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [onSaveNotifications, currentIpnTimeout, currentIpnNumRecurrent]);

  return (
    <li className={classes.setUpNotification}>
      <h3>
        2. Set up recurring notifications
      </h3>
      <p className={classes.notificationsText}>
        This will enable notifications for you in case webhook can&apos;t be delivered.
        The payment will be flagged and additional notifications will be sent, in this case.
      </p>
      <p className={classes.inputTitle}>
        {!isDesktop ? 'Number of notifications' : 'You will receive'}
      </p>
      <NumberStepperInput
        className={classes.input}
        minValue="0"
        maxValue="10"
        step={1}
        value={currentIpnNumRecurrent || ipnNumRecurrent || '0'}
        onChange={handleIpnNumRecurrentChange}
      />
      <p className={classes.inputTitle}>
        {!isDesktop ? 'Minute intervals between notifications' : 'notifications at'}
      </p>
      <NumberStepperInput
        className={classes.input}
        minValue="0"
        maxValue="60"
        step={1}
        value={currentIpnTimeout || ipnTimeout || '0'}
        onChange={handleIpnTimeoutChange}
      />
      {isDesktop && (
        <p className={classes.inputTitle}>
          minute intervals
        </p>
      )}
    </li>
  );
};
