import Cookies from "js-cookie";

export function setAuthCookieToken(token) {
  Cookies.set("authToken", token, { expires: 1, domain: ".nowpayments.io" });
}

export function getAuthCookieToken() {
  Cookies.get("authToken");
}

export function clearAuthCookieToken() {
  Cookies.remove("authToken", { domain: ".nowpayments.io" });
}
