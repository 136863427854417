import * as React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import {
  BILLING_PLANS_PAGE_THS_DESKTOP,
  BILLING_PLANS_PAGE_THS_MOBILE,
  BILLING_PLANS_PAGE_PER_PAGE,
} from '../../helpers';
import { BillingPlansTableRow } from '../billing-plans-table-row';
import { Button } from '../../../ui/button';
import { HistoryTableWithPagination } from '../../../history-table-with-pagination';
import { hasElementsInArray } from '../../../../helpers/has-elements-in-array';
import classes from './styles.module.scss';

export const BillingPlansTable = React.memo((props) => {
  const {
    items,
    hasSearchData,
    loading,
    onClick,
    count,
    onPaginatorClick,
    page,
    apiError,
    onCreatePlan,
    currenciesObject,
  } = props;

  const isDesktop = useMediaQuery('(min-width: 1200px)');
  const hasItems = hasElementsInArray(items);

  return (
    <HistoryTableWithPagination
      className={classes.billingPlansTable}
      items={items}
      hasSearchData={hasSearchData}
      fetching={loading}
      apiError={apiError}
      minWidth={600}
      desktop={isDesktop}
      thsDesktop={BILLING_PLANS_PAGE_THS_DESKTOP}
      thsMobile={BILLING_PLANS_PAGE_THS_MOBILE}
      perPage={BILLING_PLANS_PAGE_PER_PAGE}
      emptyText="Create a new plan to receive recurring payments!"
      notFoundText="No plans matching this criteria."
      emptyElement={(
        <Button
          className={classes.emptyBlockButton}
          onClick={onCreatePlan}
        >
          Create plan
        </Button>
      )}
      count={count}
      onPaginatorClick={onPaginatorClick}
      page={page}
    >
      {hasItems && items.map((item) => (
        <BillingPlansTableRow
          key={item?.id}
          data={item}
          currenciesObject={currenciesObject}
          onClick={onClick}
          isDesktop={isDesktop}
        />
      ))}
    </HistoryTableWithPagination>
  );
});

BillingPlansTable.defaultProps = {
  items: null,
  hasItems: false,
  hasSearchData: false,
  loading: false,
  onClick: null,
  count: 0,
  onPaginatorClick: null,
  page: 1,
  apiError: null,
  onCreatePlan: null,
  currenciesObject: {},
};

BillingPlansTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  hasItems: PropTypes.bool,
  hasSearchData: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  count: PropTypes.number,
  onPaginatorClick: PropTypes.func,
  page: PropTypes.number,
  apiError: PropTypes.string,
  onCreatePlan: PropTypes.func,
  currenciesObject: PropTypes.object,
};

BillingPlansTable.displayName = 'BillingPlansTable';
