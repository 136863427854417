import { formatDate } from '../../../helpers/format-date';
import { DATE_PATTERN } from '../../../constants/app-constants';
import { safeToUpperCase } from '../../../helpers/safe-to-upper-case';
import { getColorStatus } from '../../../helpers/get-color-status';
import { getPayinHashLink } from '../../../helpers/get-payin-hash-link';
import { normalizeAmountWithTickerString } from '../../../helpers/normalize-amount-with-ticker-string';
import { getCurrentNetworkForShow } from '../../../helpers/get-current-network-for-show';

/**
 * @param {Object} data
 * @param {Boolean} desktop
 * @returns {Array<object>}
 */
export const getUserInfoData = (data, desktop) => {
  const email = data?.subscriber?.email ?? null;
  const isActive = data?.isActive ?? false;
  const statusText = isActive ? 'Active' : 'Inactive';
  const currentStatusText = email ? statusText : '-';
  const createdAt = data?.createdAt ?? null;
  const updatedAt = data?.updatedAt ?? null;
  const createdDateFormatted = formatDate(createdAt, DATE_PATTERN);
  const createdDateNormalized = createdDateFormatted || '-';
  const updatedDateFormatted = formatDate(updatedAt, DATE_PATTERN);
  const updatedDateNormalized = updatedDateFormatted || '-';

  const defaultParams = {
    textSecond: null,
    isTextBlack: false,
    isTextPink: false,
    isTextGreen: false,
    isTextOverflow: false,
    isTextSecondBlack: false,
    isTextSecondOverflow: false,
    isTextSecondPink: false,
    isTextSecondGreen: false,
    textWithTitle: false,
    textSecondWithTitle: false,
  };
  const desktopData = [{
    id: '1',
    text: email,
    ...defaultParams,
    isTextBlack: true,
    isTextOverflow: true,
    textWithTitle: true,
  }, {
    id: '2',
    text: currentStatusText,
    ...defaultParams,
    isTextPink: !isActive,
    isTextGreen: isActive,
  }, {
    id: '3',
    text: createdDateNormalized,
    ...defaultParams,
  }, {
    id: '4',
    text: updatedDateNormalized,
    ...defaultParams,
  }];
  const mobileData = [{
    id: '1',
    text: email,
    ...defaultParams,
    textSecond: currentStatusText,
    isTextBlack: true,
    isTextOverflow: true,
    textWithTitle: true,
    isTextSecondPink: !isActive,
    isTextSecondGreen: isActive,
  }, {
    id: '2',
    text: createdDateNormalized,
    ...defaultParams,
    textSecond: updatedDateNormalized,
  }];

  return desktop ? desktopData : mobileData;
};

/**
 * @param {Object} payment
 * @param {Object} currenciesObject
 * @returns {Array}
 */
export const getPaymentInfoItems = (payment, currenciesObject = {}) => {
  const paymentId = payment?.paymentId ?? null;
  const invoiceId = payment?.invoiceId ?? null;

  const payTicker = payment?.payCurrency ?? '';
  const payTickerUpperCased = safeToUpperCase(payTicker);
  const amountSend = payment?.exchangeData?.amountSend ?? '0';
  const actuallyPaidAmountWithTicker = `${amountSend} ${payTicker}`;

  const originalPrice = normalizeAmountWithTickerString(payment?.originalPrice, currenciesObject);
  const payPrice = normalizeAmountWithTickerString(payment?.payPrice, currenciesObject, payTicker, null);
  const actuallyPaid = normalizeAmountWithTickerString(actuallyPaidAmountWithTicker, currenciesObject, payTicker, null);

  const outcomeAmount = payment?.outcomeAmount ?? '';
  const outcomeTicker = payment?.outcomeCurrency ?? '';
  const outcomeCurrentTicker = currenciesObject[outcomeTicker]?.ticker ?? outcomeTicker;
  const outcomePriceText = outcomeAmount
    ? `${outcomeAmount} ${safeToUpperCase(outcomeCurrentTicker)}`
    : 'N/A';

  const originalTicker = payment?.originalPrice?.split?.(' ')?.[1];
  const currentOriginalNetwork = getCurrentNetworkForShow(currenciesObject[originalTicker]?.network, originalTicker);
  const currentPayNetwork = getCurrentNetworkForShow(currenciesObject[payTicker]?.network, payTicker);
  const currentOutcomeNetwork = outcomeAmount
    ? getCurrentNetworkForShow(currenciesObject[outcomeTicker]?.network, outcomeTicker)
    : null;

  const type = payment?.type ?? null;
  const status = payment?.status ?? null;
  const statusColor = status && getColorStatus(status);
  const statusNode = status ? `<span style="color: ${statusColor}">${status}</span>` : null;
  const createdDate = payment?.createdAt ?? null;
  const createdDateNormalized = createdDate ? formatDate(createdDate, DATE_PATTERN) : null;
  const updatedDate = payment?.updatedAt ?? null;
  const updatedDateNormalized = updatedDate ? formatDate(updatedDate, DATE_PATTERN) : null;
  const payinAddress = payment?.exchangeData?.payinAddress ?? null;
  const payinAddressNode = payinAddress ? `<strong>${payinAddress}</strong>` : null;
  const currencyFound = currenciesObject[payTickerUpperCased] || {};
  const payinHash = payment?.exchangeData?.payinHash ?? '';
  const payinHashLink = getPayinHashLink(currencyFound?.explorer_link_hash, payinHash);
  const payoutHash = payment?.exchangeData?.payoutHash ?? '';

  return [{
    id: '1',
    key: 'Payment id',
    value: paymentId,
    network: null,
    link: null,
    isCopied: false,
  }, {
    id: '2',
    key: 'Invoice id',
    value: invoiceId,
    network: null,
    link: null,
    isCopied: false,
  }, {
    id: '3',
    key: 'Price Amount',
    value: originalPrice,
    network: currentOriginalNetwork,
    link: null,
    isCopied: false,
  }, {
    id: '4',
    key: 'Pay Amount',
    value: payPrice,
    network: currentPayNetwork,
    link: null,
    isCopied: false,
  }, {
    id: '5',
    key: 'Actually Paid',
    value: actuallyPaid,
    network: null,
    link: null,
    isCopied: false,
  }, {
    id: '6',
    key: 'Outcome Amount',
    value: outcomePriceText,
    network: currentOutcomeNetwork,
    link: null,
    isCopied: false,
  }, {
    id: '7',
    key: 'Type',
    value: type,
    network: null,
    link: null,
    isCopied: false,
  }, {
    id: '8',
    key: 'Status',
    value: statusNode,
    network: null,
    link: null,
    isCopied: false,
  }, {
    id: '9',
    key: 'Created at',
    value: createdDateNormalized,
    network: null,
    link: null,
    isCopied: false,
  }, {
    id: '10',
    key: 'Status updated',
    value: updatedDateNormalized,
    network: null,
    link: null,
    isCopied: false,
  }, {
    id: '11',
    key: 'Payin address',
    value: payinAddressNode,
    network: null,
    link: null,
    isCopied: false,
  }, {
    id: '12',
    key: 'Payin hash',
    value: payinHash,
    network: null,
    link: payinHashLink,
    isCopied: true,
  }, {
    id: '13',
    key: 'Payout hash',
    value: payoutHash,
    network: null,
    link: null,
    isCopied: true,
  }];
};
