import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { isFunction } from '../../../../helpers/is-function';
import { isCurrencyNetworkShow } from '../../../../helpers/is-currency-network-show';
import { CurrencyNetworkSup } from '../../../shared/currency-network-sup';
import classes from './styles.module.scss';

export const CurrencySelected = (props) => {
  const {
    className,
    ticker,
    currentTicker,
    name,
    imagePath,
    network,
    placeholder,
    placeholderText,
    onClick,
    error,
    disabled,
    smallTheme,
    themeWithAmount,
  } = props;

  const isNetworkShow = isCurrencyNetworkShow(network, ticker);

  const handleClick = () => {
    if (isFunction(onClick) && !disabled) {
      onClick();
    }
  };
  const handleKeyPress = () => {
    if (isFunction(onClick) && !disabled) {
      onClick();
    }
  };

  return (
    <div
      className={cn([
        classes.currency,
        className,
        error && classes.currencyError,
        disabled && classes.currencyDisabled,
        smallTheme && classes.currencySmallTheme,
        themeWithAmount && classes.currencyWithAmountField,
      ])}
      onClick={handleClick}
      onKeyDown={handleKeyPress}
      tabIndex={0}
      role="button"
    >
      {placeholder && !ticker && (
        <div className={classes.currencyPlaceholder}>
          {placeholder}
          {placeholderText && (
            <div className={classes.currencyPlaceholderText}>
              {placeholderText}
            </div>
          )}
        </div>
      )}
      {currentTicker && (
        <div className={classes.currencyCoin}>
          {imagePath && (
            <img
              className={classes.currencyCoinImage}
              width={35}
              height={35}
              src={imagePath}
              alt={name}
              decoding="async"
            />
          )}
          <div className={classes.currencyCoinBody}>
            <div className={classes.currencyCoinTicker}>
              {currentTicker}
              {isNetworkShow && (
                <CurrencyNetworkSup
                  className={classes.currencyCoinNetwork}
                  network={network}
                />
              )}
            </div>
            {name && (
              <div className={classes.currencyCoinName}>
                {name}
              </div>
            )}
          </div>
        </div>
      )}
      {!disabled && (
        <img
          className={classes.currenciesSelect}
          width={8}
          height={8}
          src="/images/select-arrow-icon.svg"
          alt="Icon"
          decoding="async"
        />
      )}
    </div>
  );
};

CurrencySelected.defaultProps = {
  className: null,
  ticker: null,
  currentTicker: null,
  name: null,
  imagePath: null,
  network: null,
  placeholder: null,
  placeholderText: null,
  onClick: null,
  error: false,
  disabled: false,
  smallTheme: false,
  themeWithAmount: false,
};

CurrencySelected.propTypes = {
  className: PropTypes.string,
  ticker: PropTypes.string,
  currentTicker: PropTypes.string,
  name: PropTypes.string,
  imagePath: PropTypes.string,
  network: PropTypes.string,
  placeholder: PropTypes.string,
  placeholderText: PropTypes.string,
  onClick: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  smallTheme: PropTypes.bool,
  themeWithAmount: PropTypes.bool,
};
