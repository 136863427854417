import React from "react";
import styles from "./styles.module.scss";

// import commonStyles from '../../../../css/common.module.scss';

function TfaTypeSelector(props) {
  const { setupAppTfa, setupEmailTfa, appTfaError, emailTfaError } = props;

  return (
    <>
      <h2>How would you like to receive your authentication codes?</h2>

      <p>
        You will be asked for an authentication code when you sign in to your
        account
      </p>

      {!emailTfaError ? (
        <div className={styles.tfaTypeButton} onClick={setupEmailTfa}>
          <div className={styles.tfaTypeDescription}>
            <h4>
              Email <div className={styles.arrow}></div>
            </h4>
            <p>Receive code to your email.</p>
          </div>
        </div>
      ) : (
        <div className={styles.errorNotify}>{emailTfaError}</div>
      )}

      {!appTfaError ? (
        <div className={styles.tfaTypeButton} onClick={setupAppTfa}>
          <div className={styles.tfaTypeDescription}>
            <h4>
              Use an app <div className={styles.arrow}></div>
            </h4>
            <p>
              Retrieve codes from authentication app on your device, like Google
              Authenticator.
            </p>
          </div>
        </div>
      ) : (
        <div className={styles.errorNotify}>{appTfaError}</div>
      )}
    </>
  );
}

export default TfaTypeSelector;
