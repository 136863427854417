import * as React from 'react';
import PropTypes from 'prop-types';
import { InfoAboutUse } from '../../../shared/info-about-use';

export const SubscriptionsInfoAboutUse = (props) => {
  const {
    className,
    name,
    onClose,
  } = props;

  return (
    <InfoAboutUse
      className={className}
      name={name}
      onClose={onClose}
    >
      Subscriptions feature enables you to handle regular payments by sending invoices at
      specified intervals to designated customers.
      <div>
        <strong>Benefits:</strong>
        <ol>
          <li>Ensure consistent cash flow.</li>
          <li>Avoid delays or missed payments.</li>
          <li>Strengthen customer relationships.</li>
        </ol>
      </div>
      <div>
        <strong>How to use:</strong>
        <ol>
          <li>Press “Create Subscription Plan”.</li>
          <li>Name the plan, select the period duration, and cost per period.</li>
          <li>
            Access Advanced settings: customize payment notifications, and choose where users
            will be redirected in case of successful payments, failed payments, or partial payments.
          </li>
        </ol>
      </div>
    </InfoAboutUse>
  );
};

SubscriptionsInfoAboutUse.defaultProps = {
  className: null,
  name: null,
  onClose: null,
};

SubscriptionsInfoAboutUse.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onClose: PropTypes.func,
};
