import React from 'react';
import { ELEMENTS_PER_PAGE } from '../helpers';
import { ApiKey } from '../../../default-types';

export const useFilteredApiKeysData = (apiKeys: Array<ApiKey>) => {
  const [page, setPage] = React.useState(1);

  const currentApiKeys = React.useMemo(() => {
    if (apiKeys.length <= ELEMENTS_PER_PAGE) {
      return apiKeys;
    }

    const startIndex = (page - 1) * ELEMENTS_PER_PAGE;
    const endIndex = page * ELEMENTS_PER_PAGE;

    return apiKeys.slice(startIndex, endIndex);
  }, [page, apiKeys]);

  const handlePageChange = (value: number) => {
    setPage(value);
  };

  return {
    currentApiKeys,
    handlePageChange,
    page,
  };
};
