import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { isFunction } from '../../../helpers/is-function';
import classes from './styles.module.scss';

export const ButtonBack = (props) => {
  const {
    className,
    onClick,
  } = props;

  const handleClick = () => {
    if (isFunction(onClick)) {
      onClick();
    }
  };

  return (
    <div
      className={cn(classes.buttonBack, className)}
      onClick={handleClick}
    />
  );
};

ButtonBack.defaultProps = {
  className: null,
  onClick: null,
};

ButtonBack.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
