import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Popup } from '../../../popup';
import { PaymentsInfoBox } from '../../../payments-info-box';
import { MassPayoutInfoButtons } from '../mass-payout-info-buttons';
import classes from './styles.module.scss';

export const PopupInfoAboutMassPayout = (props) => {
  const {
    className,
    open,
    onClose,
    id,
    items,
    title,
    status,
    changeStatusFetching,
    onChangeStatusToFinished,
    onVerify,
    verified,
  } = props;

  return (
    <Popup
      classNameContent={cn(classes.popup, className)}
      open={open}
      onClose={onClose}
    >
      <PaymentsInfoBox
        title={title}
        id={id}
        items={items}
      />
      <MassPayoutInfoButtons
        className={classes.popupButtons}
        status={status}
        changeStatusFetching={changeStatusFetching}
        onChangeStatusToFinished={onChangeStatusToFinished}
        onVerify={onVerify}
        verified={verified}
        onClose={onClose}
      />
    </Popup>
  );
};

PopupInfoAboutMassPayout.defaultProps = {
  className: null,
  open: null,
  onClose: null,
  id: null,
  items: null,
  title: null,
  status: null,
  changeStatusFetching: false,
  onChangeStatusToFinished: null,
  onVerify: null,
  verified: false,
};

PopupInfoAboutMassPayout.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  id: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  status: PropTypes.string,
  changeStatusFetching: PropTypes.bool,
  onChangeStatusToFinished: PropTypes.func,
  onVerify: PropTypes.func,
  verified: PropTypes.bool,
};
