import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import classes from './styles.module.scss';

export const SectionHistory = (props) => {
  const {
    className,
    title,
    Tabs,
    RightElement,
    filter,
    children,
  } = props;

  return (
    <div className={cn(classes.sectionHistory, className)}>
      <div className={classes.sectionHistoryHeader}>
        {title && (
          <h2 className={classes.sectionHistoryTitle}>
            {title}
          </h2>
        )}
        {Tabs}
        {RightElement && (
          <div className={classes.sectionHistoryRightElement}>
            {RightElement}
          </div>
        )}
      </div>
      {filter}
      <div className={classes.sectionHistoryBody}>
        {children}
      </div>
    </div>
  );
};

SectionHistory.defaultProps = {
  className: null,
  title: null,
  Tabs: null,
  RightElement: null,
  children: null,
};

SectionHistory.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  Tabs: PropTypes.node,
  RightElement: PropTypes.node,
  children: PropTypes.node,
};
