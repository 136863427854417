import React from 'react';
import { nanoid } from 'nanoid';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';

const itemMapFn = (data) => ({
  id: data?.id ?? nanoid(),
  name: data?.name ?? null,
  createdAt: data?.created_at ?? null,
  updatedAt: data?.updated_at ?? null,
});
const mapFn = (data) => ({
  result: (data?.result ?? []).map(itemMapFn),
  count: data?.count ?? null,
});

export const useSubPartners = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (options) => {
    const limit = options?.limit || -1;
    const page = options?.page || 1;
    const orderBy = options?.orderBy || 'desc';
    const sortBy = options?.sortBy || null;
    const filter = options?.filter || null;
    const id = options?.id || null;
    const [cancelToken] = getRequestSource();
    const requestPromise = client.get(
      '/sub-partners',
      {
        params: {
          limit,
          page,
          orderBy,
          sortBy,
          filter,
          id,
        },
      },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
