import * as React from 'react';
import { Helmet } from 'react-helmet';
import { TransactionsPage } from '../../components/customers-pages';

export const CustomerTransactions = () => (
  <>
    <Helmet>
      <title>Transactions</title>
    </Helmet>
    <TransactionsPage />
  </>
);
