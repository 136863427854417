import { hasElementsInArray } from '../../helpers/has-elements-in-array';
import { safeToLowerCase } from '../../helpers/safe-to-lower-case';
import { safeToUpperCase } from '../../helpers/safe-to-upper-case';

/**
 * @param {Array<object>} currencies
 * @param {String} searchValue
 * @returns {Array}
 */
export const getFilteredCurrencies = (currencies = [], searchValue = '') => {
  if (!hasElementsInArray(currencies)) {
    return [];
  }

  return currencies.filter((item) => {
    const tickerLowerCased = safeToLowerCase(item?.code);
    const nameLowerCased = safeToLowerCase(item?.name);
    const searchValueLowerCased = safeToLowerCase(searchValue);

    return tickerLowerCased?.includes(searchValueLowerCased)
      || nameLowerCased?.includes(searchValueLowerCased);
  });
};

/**
 * @param {Array<object>} currencies
 * @returns {{}}
 */
export const getGroupCurrencies = (currencies = []) => {
  const state = {
    popularGroupCurrencies: [],
    stableGroupCurrencies: [],
    fiatGroupCurrencies: [],
    otherGroupCurrencies: [],
  };

  if (!hasElementsInArray(currencies)) {
    return state;
  }

  currencies.forEach((item) => {
    if (item?.isPopular) {
      state.popularGroupCurrencies.push(item);

      return;
    }
    if (item?.isStable) {
      state.stableGroupCurrencies.push(item);

      return;
    }
    if (item?.isFiat) {
      state.fiatGroupCurrencies.push(item);

      return;
    }

    state.otherGroupCurrencies.push(item);
  });

  return state;
};

/**
 * @param {Array<object>} currencies
 * @returns {{}}
 */
export const getGroupTickers = (currencies = []) => {
  const state = {
    popularGroupTickers: [],
    stableGroupTickers: [],
    fiatGroupTickers: [],
    otherGroupTickers: [],
  };

  if (!hasElementsInArray(currencies)) {
    return state;
  }

  currencies.forEach((item) => {
    const tickerUpperCased = safeToUpperCase(item?.code);

    if (item?.isPopular) {
      state.popularGroupTickers.push(tickerUpperCased);

      return;
    }
    if (item?.isStable) {
      state.stableGroupTickers.push(tickerUpperCased);

      return;
    }
    if (item?.isFiat) {
      state.fiatGroupTickers.push(tickerUpperCased);

      return;
    }

    state.otherGroupTickers.push(tickerUpperCased);
  });

  return state;
};
