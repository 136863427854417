import * as React from 'react';
import PropTypes from 'prop-types';
import { InfoAboutUse } from '../../../shared/info-about-use';

export const PaymentsInfoAboutUse = (props) => {
  const {
    className,
    name,
    onClose,
  } = props;

  return (
    <InfoAboutUse
      className={className}
      name={name}
      onClose={onClose}
    >
      Effortlessly manage and monitor your payments with advanced filtering and export functions
      <div>
        <strong>Benefits:</strong>
        <ol>
          <li>Stay organized.</li>
          <li>Analyze your payment data to identify trends, patterns, and opportunities for optimization.</li>
          <li>Simplify reporting.</li>
        </ol>
      </div>
      <div>
        <strong>How to Use:</strong>
        <p>
          Navigate to the Payments section and explore the various filtering options available.
          Adjust the filters to refine your search and export the data in a format that works best
          for you.
        </p>
      </div>
    </InfoAboutUse>
  );
};

PaymentsInfoAboutUse.defaultProps = {
  className: null,
  name: null,
  onClose: null,
};

PaymentsInfoAboutUse.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onClose: PropTypes.func,
};
