import * as React from 'react';
import { OnboardingFormStepIconPropsType } from '../../../../types';

export const AccountStep: React.FC<OnboardingFormStepIconPropsType> = (props) => {
  const { isActive } = props;

  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13087_53921)">
        <circle
          cx="16.333"
          cy="16"
          r="15.5"
          fill="white"
          stroke={isActive ? '#64ACFF' : '#D2D2D2'}
        />
        <path
          d="M22.6465 12.6123H10.0195C9.06814 12.6123 8.33301 13.3061 8.33301 14.2041V22.4082C8.33301 23.2653 9.11138 24 10.0195 24H22.6465C23.5546 24 24.333 23.3061 24.333 22.4082V14.2041C24.333 13.3469 23.5979 12.6123 22.6465 12.6123ZM22.1708 21.9592H10.4952V14.6531H22.1708V21.9592ZM12.7871 12.0408C13.3925 12.0408 13.8681 11.5918 13.8681 11.0204V10.0408H18.8411V11.0204C18.8411 11.5918 19.3168 12.0408 19.9222 12.0408C20.5276 12.0408 21.0033 11.5918 21.0033 11.0204V9.59183C21.0033 8.73469 20.2249 8 19.3168 8H13.3925C12.4844 8 11.706 8.69387 11.706 9.59183V11.0204C11.706 11.551 12.1817 12.0408 12.7871 12.0408Z"
          fill={isActive ? '#64ACFF' : '#D2D2D2'}
        />
      </g>
      <defs>
        <clipPath id="clip0_13087_53921">
          <rect width="32" height="32" fill="white" transform="translate(0.333008)" />
        </clipPath>
      </defs>
    </svg>
  );
};
