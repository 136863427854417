import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { InfoAboutUse } from '../../../shared/info-about-use';
import { ROUTES } from '../../../../constants/routes';

export const MassPayoutsInfoAboutUse = (props) => {
  const {
    className,
    name,
    onClose,
  } = props;

  return (
    <InfoAboutUse
      className={className}
      name={name}
      onClose={onClose}
    >
      Effortlessly send crypto to multiple addresses in one click with Mass Payouts.
      <br />
      Note: This feature works only with enabled
      {' '}
      <Link to={ROUTES.CUSTODY}>
        Custody
      </Link>
      .
      <div>
        <strong>Benefits:</strong>
        <ul>
          <li>Simplify crypto distribution to multiple addresses in one click</li>
          <li>Ensure highest level of security with whitelisted addresses</li>
          <li>Save time and eliminate manual transactions.</li>
        </ul>
      </div>
      <div>
        <strong>Get started:</strong>
        <ol>
          <li>
            Send a whitelist request using &quot;Whitelist my addresses&quot; button below.
          </li>
          <li>Once addresses are whitelisted, download the provided .csv template.</li>
          <li>Fill out the template with necessary details.</li>
          <li>
            Upload the completed .csv file using the designated button, or use our
            {' '}
            <a
              href="https://documenter.getpostman.com/view/7907941/2s93JusNJt#3a87a0bb-2daa-431c-b072-d63560b03952"
              target="_blank"
              rel="nofollow noreferrer noopener"
            >
              API
            </a>
            {' '}
            to create a withdrawal.
          </li>
        </ol>
      </div>
    </InfoAboutUse>
  );
};

MassPayoutsInfoAboutUse.defaultProps = {
  className: null,
  name: null,
  onClose: null,
};

MassPayoutsInfoAboutUse.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onClose: PropTypes.func,
};
