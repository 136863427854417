import React, { Component } from "react";
import config from "../../config";
import styles from "./styles.module.scss";

class CookiesAlert extends Component {
  constructor() {
    super();
    this.handleCookieAlertButtonClick =
      this.handleCookieAlertButtonClick.bind(this);
    this.state = {
      show: false,
    };
  }

  componentDidMount() {
    this.setCookeisAlertShow();
  }

  setCookeisAlertShow() {
    if (this.isCookiesAccepted()) {
      this.setState({ show: false });
    } else {
      this.setState({ show: true });
    }
  }

  isCookiesAccepted() {
    return document.cookie
      .split(";")
      .filter((item) => item.trim().startsWith("cookie-accepted=")).length;
  }

  handleCookieAlertButtonClick() {
    document.cookie = "cookie-accepted=true";
    this.setCookeisAlertShow();
  }

  render() {
    if (this.state.show) {
      return (
        <div className={styles.cookiesAlert}>
          <div className={styles.row}>
            <div className={styles.text}>
              We use cookies to ensure you get the best experience. By using
              NOWPayments, you agree to our
              <a
                className={styles.link}
                href={`${config.front_domain}/doc/user_agreement-v1_2_3.pdf`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {" Terms of use "}
              </a>
              and
              <a
                className={styles.link}
                href={`${config.front_domain}/doc/privacy_policy-v1_2_2.pdf`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {" Privacy Policy "}
              </a>
            </div>

            <button
              className={styles.button}
              onClick={this.handleCookieAlertButtonClick}
            >
              Accept
            </button>
          </div>
        </div>
      );
    }

    return null;
  }
}

export default CookiesAlert;
