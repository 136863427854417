import React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';
import { fiatPayoutMapFn } from '../../helpers/account/map-fns';

const mapFn = (data) => {
  return data?.result ? fiatPayoutMapFn(data.result) : {};
};

export const useRequestFiatPayout = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (options) => {
    const cryptoCurrency = options?.ticker ?? null;
    const fiatCurrency = options?.fiatCurrency ?? null;
    const amount = options?.amount ?? null;
    const provider = options?.provider ?? null;
    const [cancelToken] = getRequestSource();
    const requestPromise = client.post(
      '/fiat-payouts',
      { cryptoCurrency, fiatCurrency, amount, provider },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
