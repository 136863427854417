import React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';

const mapFn = (data) => ({
  estimatedAmountTo: data?.estimatedAmountTo ?? null,
  minAmountFrom: data?.minAmountFrom ?? null,
});

export const useMinAmount = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (options) => {
    const from = options?.from ?? null;
    const to = options?.to ?? null;
    const [cancelToken] = getRequestSource();
    const requestPromise = client.get(
      '/min-amount',
      { params: { from, to } },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
