import React from 'react';
import PropTypes from 'prop-types';

export const LoaderIcon = (props) => {
  const {
    className,
    path,
    size,
  } = props;

  return (
    <img
      className={className}
      width={size}
      height={size}
      src={path}
      alt="Loader icon"
      decoding="async"
    />
  );
};

LoaderIcon.defaultProps = {
  className: null,
  path: '/images/loader-blue-icon.gif',
  size: 30,
};

LoaderIcon.propTypes = {
  className: PropTypes.string,
  path: PropTypes.string,
  size: PropTypes.number,
};
