import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { nanoid } from 'nanoid';
import { isFunction } from '../../../helpers/is-function';
import styles from './styles.module.scss';

export const InputSearch = (props) => {
  const {
    id,
    className,
    placeholder,
    onChange,
    disabled,
    imageSearch,
    ImageAdditional,
    value,
  } = props;

  const handleChange = (event) => {
    if (isFunction(onChange)) {
      onChange(event.target.value);
    }
  };

  const options = typeof value === 'string' ? { value } : {};

  return (
    <label
      htmlFor={id}
      className={cn([
        styles.label,
        ImageAdditional && styles.labelRightPadding,
        className,
      ])}
    >
      <input
        id={id}
        className={styles.input}
        placeholder={placeholder}
        onChange={handleChange}
        disabled={disabled}
        type="text"
        {...options}
      />
      <img
        src={imageSearch}
        alt="Icon search"
        className={styles.search}
        decoding="async"
      />
      {ImageAdditional && (
        <span className={styles.imageAdditional}>
          {ImageAdditional}
        </span>
      )}
    </label>
  );
};

InputSearch.defaultProps = {
  id: nanoid(),
  className: null,
  placeholder: null,
  onChange: null,
  disabled: false,
  imageSearch: '/images/search-icon.svg',
  ImageAdditional: null,
  value: null,
};

InputSearch.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  imageSearch: PropTypes.string,
  ImageAdditional: PropTypes.node,
  value: PropTypes.string,
};
