import React from 'react';
import { Popup } from '../../../popup';
import { AddNewAccountForm } from '../../../add-new-account-form';
import { FiatOffRampCurrency } from '../../../../types';
import classes from './styles.module.scss';

type AddAccountPopupProps = {
  open: boolean,
  onClose: () => void,
  onUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  fiatCurrencies: Array<FiatOffRampCurrency>;
};

export const AddAccountPopup: React.FC<AddAccountPopupProps> = (props) => {
  const {
    open,
    onClose,
    onUpdate,
    fiatCurrencies,
  } = props;

  return (
    <Popup
      classNameContent={classes.addAccountPopupContent}
      open={open}
      onClose={onClose}
      withCloseButton
      mobileFullView
    >
      <AddNewAccountForm
        onUpdate={onUpdate}
        closePopup={onClose}
        fiatCurrencies={fiatCurrencies}
      />
    </Popup>
  );
};
