import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { isFunction } from '../../../helpers/is-function';
import { Button } from '../../ui/button';
import classes from './styles.module.scss';

export const CustomFilter = (props) => {
  const {
    className,
    classNameFields,
    title,
    onClose,
    onSubmit,
    onReset,
    children,
    buttonSubmitText,
    buttonResetText,
  } = props;

  const handleClose = () => {
    if (isFunction(onClose)) {
      onClose();
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    if (isFunction(onSubmit)) {
      onSubmit();
    }
  };
  const handleResetForm = () => {
    if (isFunction(onReset)) {
      onReset();
    }
  };

  return (
    <div className={cn(classes.customFilter, className)}>
      {onClose && (
        <div
          className={classes.customFilterClose}
          onClick={handleClose}
        >
          <div className={classes.customFilterCloseLine} />
          <div className={classes.customFilterCloseLine} />
        </div>
      )}
      <div className={classes.customFilterTitle}>
        {title}
      </div>
      <div className={classes.customFilterLine} />
      <form onSubmit={handleSubmit}>
        <div className={cn([
          classes.customFilterFields,
          classNameFields,
        ])}>
          {children}
        </div>
        <div className={classes.customFilterButtons}>
          <Button
            type="submit"
            className={cn(classes.customFilterButton, classes.customFilterButtonSubmit)}
          >
            {buttonSubmitText}
          </Button>
          <Button
            className={cn(classes.customFilterButton, classes.customFilterButtonReset)}
            onClick={handleResetForm}
          >
            {buttonResetText}
          </Button>
        </div>
      </form>
    </div>
  );
};

CustomFilter.defaultProps = {
  className: null,
  classNameFields: null,
  title: 'Filter',
  onClose: null,
  onSubmit: null,
  onReset: null,
  children: null,
  buttonSubmitText: 'Apply',
  buttonResetText: 'Clear filters',
};

CustomFilter.propTypes = {
  className: PropTypes.string,
  classNameFields: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
  children: PropTypes.node,
  buttonSubmitText: PropTypes.string,
  buttonResetText: PropTypes.string,
};
