import * as React from 'react';
import { useSelector } from 'react-redux';
import { hasElementsInArray } from '../../../helpers/has-elements-in-array';
import { safeToUpperCase } from '../../../helpers/safe-to-upper-case';
import { partnerSelector } from '../../../store/partner/selectors';

/**
 * @param {Array<string>} popularGroupTickers
 * @param {Array<string>} stableGroupTickers
 * @param {Array<string>} fiatGroupTickers
 * @param {Array<string>} otherGroupTickers
 * @param {Array<object>} popularGroupCurrencies
 * @param {Array<object>} stableGroupCurrencies
 * @param {Array<object>} fiatGroupCurrencies
 * @param {Array<object>} otherGroupCurrencies
 * @param {Object} userData
 * @param {String} pendingCategoryName
 * @param {Boolean} pending
 * @param {Array<string>} selectedTickersByPartner
 * @param {Object} CATEGORY_NAMES
 * @returns {Array<object>}
 */
export const useArrayOfGroupData = ({
  popularGroupTickers,
  stableGroupTickers,
  fiatGroupTickers,
  otherGroupTickers,
  popularGroupCurrencies,
  stableGroupCurrencies,
  fiatGroupCurrencies,
  otherGroupCurrencies,
  pendingCategoryName,
  pending,
  selectedTickersByPartner,
  CATEGORY_NAMES,
}) => {
  const partner = useSelector(partnerSelector());
  const isOnrampEnabled = Boolean(partner?.isGuardarianEnabled || partner?.isOnrampEnabled);

  return React.useMemo(() => {
    const popularTickersSelectedByPartner = selectedTickersByPartner.filter((item) =>
      popularGroupTickers.includes(safeToUpperCase(item)),
    );
    const hasPopularTickersSelectedByPartner = hasElementsInArray(popularTickersSelectedByPartner);
    const stableTickersSelectedByPartner = selectedTickersByPartner.filter((item) =>
      stableGroupTickers.includes(safeToUpperCase(item)),
    );
    const hasStableTickersSelectedByPartner = hasElementsInArray(stableTickersSelectedByPartner);
    const fiatTickersSelectedByPartner = selectedTickersByPartner.filter((item) =>
      fiatGroupTickers.includes(safeToUpperCase(item)),
    );
    const hasFiatTickersSelectedByPartner = hasElementsInArray(fiatTickersSelectedByPartner);
    const otherTickersSelectedByPartner = selectedTickersByPartner.filter((item) =>
      otherGroupTickers.includes(safeToUpperCase(item)),
    );
    const hasOtherTickersSelectedByPartner = hasElementsInArray(otherTickersSelectedByPartner);

    const groupsData = [
      {
        title: CATEGORY_NAMES.POPULAR,
        currencies: popularGroupCurrencies,
        tickers: popularGroupTickers,
        isAllowed: true,
        pending: pending && CATEGORY_NAMES.POPULAR === pendingCategoryName,
        isSwitcherActive: hasPopularTickersSelectedByPartner,
      },
      {
        title: CATEGORY_NAMES.STABLE,
        currencies: stableGroupCurrencies,
        tickers: stableGroupTickers,
        isAllowed: true,
        pending: pending && CATEGORY_NAMES.STABLE === pendingCategoryName,
        isSwitcherActive: hasStableTickersSelectedByPartner,
      },
      {
        title: CATEGORY_NAMES.FIAT,
        currencies: fiatGroupCurrencies,
        tickers: fiatGroupTickers,
        isAllowed: isOnrampEnabled,
        pending: pending && CATEGORY_NAMES.FIAT === pendingCategoryName,
        isSwitcherActive: !isOnrampEnabled ? false : hasFiatTickersSelectedByPartner,
      },
      {
        title: CATEGORY_NAMES.OTHER,
        currencies: otherGroupCurrencies,
        tickers: otherGroupTickers,
        isAllowed: true,
        pending: pending && CATEGORY_NAMES.OTHER === pendingCategoryName,
        isSwitcherActive: hasOtherTickersSelectedByPartner,
      },
    ];

    if (!isOnrampEnabled) {
      [groupsData[2], groupsData[3]] = [groupsData[3], groupsData[2]];
    }

    return groupsData;
  }, [
    popularGroupTickers,
    stableGroupTickers,
    fiatGroupTickers,
    otherGroupTickers,
    popularGroupCurrencies,
    stableGroupCurrencies,
    fiatGroupCurrencies,
    otherGroupCurrencies,
    pendingCategoryName,
    pending,
    selectedTickersByPartner,
  ]);
};
