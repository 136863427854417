import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { hasElementsInArray } from '../../../helpers/has-elements-in-array';
import { CurrencyNetworkSup } from '../currency-network-sup';
import classes from './styles.module.scss';

export const UserDataInfoDesktop = (props) => {
  const {
    className,
    headerItems,
    bodyItems,
    landscape,
  } = props;

  const hasHeaderItems = hasElementsInArray(headerItems);
  const hasBodyItems = hasElementsInArray(bodyItems);

  return (
    <div className={cn([
      classes.userDataInfoBox,
      landscape && classes.landscape,
      className,
    ])}>
      <div className={classes.userDataInfo}>
        {hasHeaderItems && (
          <div className={classes.userDataInfoHeader}>
            {headerItems.map((item) => (
              <div
                key={item?.id}
                className={classes.userDataInfoHeaderItem}
                data-header="item"
              >
                <span dangerouslySetInnerHTML={{ __html: item?.name }} />
                {item?.element && (
                  <span className={classes.userDataInfoHeaderItemElement}>
                    {item.element}
                  </span>
                )}
              </div>
            ))}
          </div>
        )}
        {hasBodyItems && (
          <div className={classes.userDataInfoBody}>
            {bodyItems.map((item) => (
              <div
                className={cn([
                  classes.userDataInfoBodyItem,
                  item?.isTextBlack && classes.colorBlack,
                  item?.isWordBreak && classes.wordBreak,
                  item?.network && classes.priceFlex,
                ])}
                key={item?.id}
                data-body="item"
              >
                <span
                  title={item?.textWithTitle ? item?.text : null}
                  dangerouslySetInnerHTML={{ __html: item?.text }}
                />
                {item?.network && (
                  <CurrencyNetworkSup network={item.network} />
                )}
                {item?.element && (
                  <span className={classes.userDataInfoBodyItemElement}>
                    {item.element}
                  </span>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

UserDataInfoDesktop.defaultProps = {
  className: null,
  headerItems: null,
  bodyItems: null,
  landscape: false,
};

UserDataInfoDesktop.propTypes = {
  className: PropTypes.string,
  headerItems: PropTypes.arrayOf(PropTypes.object),
  bodyItems: PropTypes.arrayOf(PropTypes.object),
  landscape: PropTypes.bool,
};
