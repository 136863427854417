export const mapInvoiceFiltersToParameters = (filters) => {
  if (!filters) {
    return {};
  }

  const keysMap = {
    paymentLinkId: 'invoice_id',
    createdAt: 'created_at_days',
    orderId: 'order_id',
    currency: 'pay_currency',
    paymentId: 'payment_id',
    fixedRate: 'is_fixed_rate',
    feePaidByUser: 'is_fee_paid_by_user',
    isIdOrCondition: 'is_id_or_condition',
  };
  const emptyValues = [null, undefined, 0, ''];
  const filterParameters = Object.entries(filters).reduce((result, [key, value]) => {
    if (keysMap[key] && !emptyValues.includes(value)) {
      result[keysMap[key]] = value;
    }

    return result;
  }, {});

  return filterParameters;
};