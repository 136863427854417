import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import copyToClipboard from '../../../helpers/copy-to-clipboard';
import { CopyButton } from '../copy-button';
import styles from './styles.module.scss';

const TIMEOUT = 2000;

export const CopyText = (props) => {
  const {
    className,
    text,
    ellipsis,
    copyIconPath,
    copySuccessIconPath,
    copyText,
  } = props;

  const [isCopied, setIsCopied] = React.useState(false);
  const [timerId, setTimerId] = React.useState(0);

  const handleClick = () => {
    const isCopyToClipboard = copyToClipboard(copyText || text);

    if (!isCopyToClipboard) {
      return;
    }

    setIsCopied(true);
    clearTimeout(timerId);
    const timer = setTimeout(() => {
      setIsCopied(false);
    }, TIMEOUT);

    setTimerId(timer);
  };

  return (
    <div className={cn([
      styles.copyText,
      ellipsis && styles.copyTextEllipsis,
      className,
    ])}>
      <span
        onClick={handleClick}
        onKeyPress={handleClick}
        role="button"
        tabIndex={0}
      >
        {text}
      </span>
      <CopyButton
        onClick={handleClick}
        isCopied={isCopied}
        className={styles.copyTextButton}
        copyIconPath={copyIconPath}
        copySuccessIconPath={copySuccessIconPath}
      />
    </div>
  );
};

CopyText.defaultProps = {
  className: null,
  text: '',
  ellipsis: false,
  copyIconPath: null,
  copySuccessIconPath: null,
  copyText: null,
};

CopyText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  ellipsis: PropTypes.bool,
  copyIconPath: PropTypes.string,
  copySuccessIconPath: PropTypes.string,
  copyText: PropTypes.string,
};
