import React from 'react';
import { nanoid } from 'nanoid';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';

const itemMapFn = (data) => ({
  id: data?.id ?? nanoid(),
  fromSubId: data?.from_sub_id ?? null,
  toSubId: data?.to_sub_id ?? null,
  status: data?.status ?? null,
  amount: data?.amount ?? null,
  currency: data?.currency ?? null,
  destination: data?.destination ?? null,
  subPartnerId: data?.sub_partner_id ?? null,
  type: data?.type ?? null,
  createdAt: data?.created_at ?? null,
  updatedAt: data?.updated_at ?? null,
});
const mapFn = (data) => ({
  result: (data?.result ?? []).map(itemMapFn),
  count: data?.count ?? null,
});

export const useSubPartnersTransfers = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (options) => {
    const limit = options?.limit || -1;
    const page = options?.page || 1;
    const orderBy = options?.orderBy || 'desc';
    const sortBy = options?.sortBy || null;
    const id = options?.id || null;
    const sub_partner_id = options?.customerId ?? null;
    const from_id = options?.fromId || null;
    const to_id = options?.toId || null;
    const filter = options?.filter || null;
    const date_from = options?.dateFrom || null;
    const date_to = options?.dateTo || null;
    const status = options?.status || null;
    const destination = options?.destination || null;
    const type = options?.type || null;

    const [cancelToken] = getRequestSource();
    const requestPromise = client.get(
      '/sub-partners/transfers',
      {
        params: {
          limit,
          page,
          orderBy,
          sortBy,
          id,
          sub_partner_id,
          from_id,
          to_id,
          filter,
          date_from,
          date_to,
          status,
          destination,
          type,
        },
      },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
