import React from 'react';
import { useSelector } from 'react-redux';
import {
  apiKeysErrorSelector,
  apiKeysFetchedSelector,
  apiKeysHasActiveKeySelector,
  apiKeysSelector,
  isStoreDataFetchedSelector,
  paymentSettingsFetchingSelector,
  walletsSelector,
} from '../../../store/payment-settings/selectors';
import { availableCurrenciesSelector } from '../../../store/currencies/selectors';
import { ApiKey } from '../../../default-types';

export const useInitState = () => {
  const apiKeys = useSelector(apiKeysSelector());
  const hasActiveKeys = useSelector(apiKeysHasActiveKeySelector());
  const isFetched = useSelector(apiKeysFetchedSelector());
  const isFetching = useSelector(paymentSettingsFetchingSelector());
  const error = useSelector(apiKeysErrorSelector());
  const currencies = useSelector(availableCurrenciesSelector());
  const wallets = useSelector(walletsSelector());
  const isWalletsFetched = useSelector(isStoreDataFetchedSelector());

  const hasWallets = isWalletsFetched && wallets?.length > 0;
  const addWalletAndApiKeyShow = isFetched && !hasActiveKeys && !hasWallets;
  const activeApiKeysLength = apiKeys.length;

  const activeApiKeys = React.useMemo(() => {
    if (apiKeys.length === 0) return apiKeys;

    return apiKeys.filter((key: ApiKey) => key.isActive);
  }, [apiKeys]);

  return {
    activeApiKeys,
    activeApiKeysLength,
    addWalletAndApiKeyShow,
    isFetched,
    isFetching,
    error,
    currencies,
  };
};
