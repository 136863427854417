import { SUBSCRIPTIONS_REDUCER_NAME } from './reducer';

export const subscriptionsPlansSelector = () => (state) => state[SUBSCRIPTIONS_REDUCER_NAME]?.plans;

export const subscriptionsPlansCountSelector = () => (state) => state[SUBSCRIPTIONS_REDUCER_NAME]?.count;

export const subscriptionPlansFetchingSelector = () => (state) => state[SUBSCRIPTIONS_REDUCER_NAME]?.fetching;

export const subscriptionPlansFetchedSelector = () => (state) => state[SUBSCRIPTIONS_REDUCER_NAME]?.fetched;

export const subscriptionPlansErrorSelector = () => (state) => state[SUBSCRIPTIONS_REDUCER_NAME]?.error;
