import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import classes from './styles.module.scss';

export const CheckboxNew = (props) => {
  const {
    className,
    id,
    onChange,
    error,
    checked,
    disabled,
  } = props;

  return (
    <label className={cn([
      classes.checkboxNew,
      error && classes.checkboxNewError,
      className,
    ])}>
      <input
        id={id}
        className={classes.checkboxNewInput}
        type="checkbox"
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      <div className={classes.checkboxNewBox} />
    </label>
  );
};

CheckboxNew.defaultProps = {
  className: null,
  id: null,
  onChange: null,
  error: false,
  checked: null,
  disabled: null,
};

CheckboxNew.propTypes = {
  className: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  error: PropTypes.bool,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};
