import React from 'react';
import { Helmet } from 'react-helmet';
import { PaymentsSettingsPage } from '../../components/payments-settings-page';

export const PaymentsSettings = () => (
  <>
    <Helmet>
      <title>Payments Settings</title>
    </Helmet>
    <PaymentsSettingsPage />
  </>
);
