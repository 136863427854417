import React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';

export const useUpdateWhitelistedIpsInfo = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (addresses: Array<string>) => {
    const [cancelToken] = getRequestSource();
    const requestPromise = client.put(
      '/whitelisted-ips-info',
      { whitelistedIps: addresses },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      (data) => data,
    );
  }, [getRequestSource]);
};
