import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { isFunction } from '../../helpers/is-function';
import styles from './styles.module.scss';

const DATA_OVERLAY_TEXT = 'overlay';
const DATA_CLOSE_TEXT = 'close';

export const Popup = (props) => {
  const {
    className,
    classNameContent,
    title,
    description,
    Icon,
    open,
    children,
    onClose,
    withoutPadding,
    withCloseButton,
    mobileFullView,
    line,
  } = props;

  const [isActive, setIsActive] = React.useState(false);

  const handleClose = (event) => {
    const dataSetClick = event.target.dataset.click || null;
    const isOverlayClick = dataSetClick === DATA_OVERLAY_TEXT;
    const isButtonCloseClick = dataSetClick === DATA_CLOSE_TEXT;

    if ((isOverlayClick || isButtonCloseClick) && isFunction(onClose)) {
      onClose();
    }
  };

  React.useEffect(() => {
    const bodyElement = document.body;

    if (open) {
      setIsActive(true);
      bodyElement.classList.add('scroll-locked');
    }

    return () => {
      bodyElement.classList.remove('scroll-locked');
    };
  }, [open]);

  if (!open) {
    return null;
  }

  return (
    <div
      className={cn([
        styles.popup,
        isActive && styles.popupActive,
        mobileFullView && styles.popupMobileFullView,
        className,
      ])}
      onClick={handleClose}
      onKeyPress={handleClose}
      tabIndex={0}
      role="button"
      data-click={DATA_OVERLAY_TEXT}
    >
      <div className={cn([
        styles.content,
        mobileFullView && styles.contentMobileFullView,
        withoutPadding && styles.contentWithoutPadding,
        classNameContent,
      ])}>
        {withCloseButton && (
          <div
            className={styles.close}
            data-click={DATA_CLOSE_TEXT}
          >
            <div className={styles.closeLine} data-click={DATA_CLOSE_TEXT} />
            <div className={styles.closeLine} data-click={DATA_CLOSE_TEXT} />
          </div>
        )}
        {Icon && (
          <div className={styles.icon}>
            {Icon}
          </div>
        )}
        {title && (
          <div className={styles.title}>
            {title}
          </div>
        )}
        {description && (
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
        {line && (
          <div className={styles.line} />
        )}
        {children}
      </div>
    </div>
  );
};

Popup.defaultProps = {
  className: null,
  classNameContent: null,
  title: null,
  description: null,
  Icon: null,
  open: false,
  children: null,
  onClose: null,
  withoutPadding: false,
  withCloseButton: false,
  mobileFullView: false,
  line: false,
};

Popup.propTypes = {
  className: PropTypes.string,
  classNameContent: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  Icon: PropTypes.node,
  open: PropTypes.bool,
  children: PropTypes.node,
  onClose: PropTypes.func,
  withoutPadding: PropTypes.bool,
  withCloseButton: PropTypes.bool,
  mobileFullView: PropTypes.bool,
  line: PropTypes.bool,
};
