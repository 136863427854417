import * as React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from '../../../helpers/is-function';
import ToolTip from '../../tool-tip';
import { useMediaQuery } from '../../../hooks/use-media-query';

export const InfoAboutUseTooltip = (props) => {
  const {
    className,
    showFirstText,
    onClick,
  } = props;
  const isDesktop = useMediaQuery('(min-width: 992px)');

  const handleClick = () => {
    if (isFunction(onClick)) {
      onClick();
    }
  };

  const currentText = showFirstText
    ? 'Click here to learn about this feature'
    : 'Click here to close info about this feature';
  const text = isDesktop ? currentText : null;

  return (
    <>
      <img
        className={className}
        width={17}
        height={17}
        src="/images/info-icon.svg"
        alt="Icon help"
        data-tooltip-id="info-about-use-tooltip"
        data-tooltip-html={text}
        decoding="async"
        onClick={handleClick}
      />
      {isDesktop && (
        <ToolTip id="info-about-use-tooltip" />
      )}
    </>
  );
};

InfoAboutUseTooltip.defaultProps = {
  className: null,
  showFirstText: true,
  onClick: null,
};

InfoAboutUseTooltip.propTypes = {
  className: PropTypes.string,
  showFirstText: PropTypes.bool,
  onClick: PropTypes.func,
};
