import React from 'react';
import { useOffRampFiatCurrencies } from '../api/modules/account/use-off-ramp-fiat-currencies';
import { getSafeErrorMessageText } from '../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../helpers/console-error-message';
import { safeToLowerCase } from '../helpers/safe-to-lower-case';
import { getUrlWithDomain } from '../helpers/get-url-with-domain';
import { FiatOffRampCurrency } from '../types';

export const useFiatOffRampCurrencies = (provider = 'transfi') => {
  const fetchFiatCurrenciesRequestSend = useOffRampFiatCurrencies();
  const [fiatCurrencies, setFiatCurrencies] = React.useState<Array<FiatOffRampCurrency>>([]);

  const fetchFiatCurrencies = React.useCallback(async () => {
    const {
      data,
      status,
      errorMessage,
    }: { data: any, status: number, errorMessage: string } = await fetchFiatCurrenciesRequestSend({
      provider,
    });

    if (status === 200 && data) {
      const fiatCurrenciesWithOptions = data?.result
        .map((el: FiatOffRampCurrency) => ({
          ...el,
          name: el.currencyCode,
          logoUrl: getUrlWithDomain(`/images/coins/${safeToLowerCase(el.currencyCode)}.svg`),
        }))
        ?? [];
      setFiatCurrencies(fiatCurrenciesWithOptions);
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/fiat-payouts/fiat-currencies');
    }
  }, [fetchFiatCurrenciesRequestSend, provider]);

  React.useEffect(() => {
    const timerId = setTimeout(fetchFiatCurrencies);

    return () => clearTimeout(timerId);
  }, [fetchFiatCurrencies]);

  return fiatCurrencies;
};
