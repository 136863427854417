import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { hasElementsInArray } from '../../../../helpers/has-elements-in-array';
import { PaymentLinksItem } from '../payment-links-item';
import { LoaderIcon } from '../../../icons/loader-icon';
import { isFunction } from '../../../../helpers/is-function';
import styles from './styles.module.scss';

export const PaymentLinksTable = (props) => {
  const {
    className,
    data,
    loading,
    onClick,
    hasPayments,
    currenciesObject,
  } = props;

  const hasData = hasElementsInArray(data);

  const handleClick = (data) => () => {
    if (isFunction(onClick)) {
      onClick(data);
    }
  };

  return (
    <div className={cn(styles.linksTableBox, className)}>
      {!loading && !hasPayments && (
        <div className={styles.emptyText}>
          There are no payments yet. Please follow the link to create one!
        </div>
      )}
      {!loading && hasPayments && !hasData && (
        <div className={styles.emptyText}>
          No payments found matching this criteria.
        </div>
      )}
      {hasData && (
        <table className={styles.linksTable}>
          <thead className={styles.linksTableThead}>
            <tr>
              <th>Payment ID</th>
              <th>Amount sent</th>
              <th>Amount received</th>
              <th>Created at</th>
              <th>Status updated</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody className={styles.linksTableTbody}>
            {loading && (
              <tr className={styles.linksTableLoaderTr}>
                <td colSpan={6}>
                  <LoaderIcon />
                </td>
              </tr>
            )}
            {!loading && data.map((payment) => (
              <PaymentLinksItem
                key={payment?.id}
                className={styles.linksTableBodyTr}
                paymentId={payment?.id}
                orderId={payment?.order_id}
                payAmount={payment?.pay_amount}
                payTicker={payment?.pay_currency}
                outcomeAmount={payment?.outcome_amount}
                amountReceive={payment?.exchange_data?.amountReceive}
                receivedTicker={payment?.outcome_currency}
                created={payment?.created_at}
                updated={payment?.updated_at}
                status={payment?.status}
                originType={payment?.origin_type}
                onClick={handleClick(payment)}
                currenciesObject={currenciesObject}
              />
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

PaymentLinksTable.defaultProps = {
  className: null,
  data: null,
  loading: false,
  onClick: null,
  currenciesObject: null,
};

PaymentLinksTable.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  currenciesObject: PropTypes.object,
};
