import React from 'react';
import { useSelector } from 'react-redux';
import { isPartnerCustodySelector, partnerFetchedSelector } from '../../../store/partner/selectors';
import { useSubPartners } from '../../../api/modules/account/use-sub-partners';
import { balancesSelector } from '../../../store/payment-settings/selectors';

export const useCustomerPageInitState = (customerId, reFetchCustomerSubscriptions) => {
  const fetchSubPartners = useSubPartners();
  const isPartnerCustody = useSelector(isPartnerCustodySelector());
  const partnerFetched = useSelector(partnerFetchedSelector());
  const balances = useSelector(balancesSelector());

  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isCustomerNotFound, setIsCustomerNotFound] = React.useState(false);
  const [subPartner, setSubPartner] = React.useState(null);

  const init = async () => {
    const { data, status } = await fetchSubPartners({ limit: 1, id: customerId });
    const subPartnerData = data?.result?.[0] ?? null;

    if (status === 200 && subPartnerData) {
      setSubPartner(subPartnerData);
      setIsLoading(false);
      setIsLoaded(true);
    } else {
      setIsCustomerNotFound(true);
      setIsLoading(false);
      setIsLoaded(true);
    }
  };

  React.useEffect(() => {
    if (partnerFetched && isPartnerCustody) {
      void init();
      void reFetchCustomerSubscriptions();
    }
  }, [partnerFetched, isPartnerCustody]);

  return {
    isLoading,
    isLoaded,
    isCustomerNotFound,
    subPartner,
    partnerBalances: balances,
  };
};
