import * as yup from 'yup';

export const SCHEMA = yup.object({
  paymentId: yup.string().trim(),
  orderId: yup.string().trim(),
  payinAddress: yup.string().trim(),
  payinHash: yup.string().trim(),
  status: yup.string().trim(),
  createdAt: yup.string().trim(),
});

const emptyStringToUndefined = (_, originalValue) => originalValue === ''  ? undefined : _;
const nullToUndefined = (_, originalValue) => originalValue === null  ? undefined : _;
const stringCheck = yup.string().trim().transform(emptyStringToUndefined).optional();

export const INVOICE_FILTERS_SCHEMA = yup.object({
  paymentLinkId: stringCheck,
  createdAt: yup.number().transform(emptyStringToUndefined).oneOf([7, 30, 90, null]).optional(),
  orderId: stringCheck,
  currency: stringCheck,
  paymentId: stringCheck,
  fixedRate: yup.bool().transform(nullToUndefined),
  feePaidByUser: yup.bool().transform(nullToUndefined),
});

export const PAYMENT_CREATE_SCHEMA = yup.object({
  orderDescription: yup.string().trim().max(255, 'Description must be at most 255 characters'),
  orderId: yup.string().trim().max(30, 'Id must be at most 30 characters'),
  price: yup.string().required('Price is required field'),
});