import React from "react";
import { front_domain } from "../../config";
import styles from "./styles.module.scss";

export const CodePreview = ({ isWhiteTheme, apiKey }) => {
  return (
    <pre className={styles.wrapperCode}>
      <code className={styles.code}>
        <span>
          &lt;<span className={styles.tagName}>a</span> <span>href</span>=
          <span className={styles.tagString}>
            &quot;{front_domain}/donation?api_key={apiKey}&source=lk_donation&medium=referral&quot;
          </span>{" "}
          <span>target</span>=
          <span className={styles.tagString}>&quot;_blank&quot;</span>&gt;
        </span>
        <br />
        &nbsp;
        {!isWhiteTheme ? (
          <span>
            &lt;<span className={styles.tagName}>img</span> <span>src</span>=
            <span className={styles.tagString}>
              &quot;{front_domain}/images/embeds/donation-button-black.svg&quot;
            </span>{" "}
            <span>alt</span>=
            <span className={styles.tagString}>
              &quot;Crypto donation button by NOWPayments&quot;
            </span>
            &gt;
          </span>
        ) : (
          <span>
            &lt;<span className={styles.tagName}>img</span> <span>src</span>=
            <span className={styles.tagString}>
              &quot;{front_domain}/images/embeds/donation-button-white.svg&quot;
            </span>{" "}
            <span>alt</span>=
            <span className={styles.tagString}>
              &quot;Crypto donation button by NOWPayments&quot;
            </span>
            &gt;
          </span>
        )}
        <br />
        <span>
          &lt;/<span className={styles.tagName}>a</span>&gt;
        </span>
      </code>
    </pre>
  );
};
