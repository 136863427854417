import React from 'react';
import { getItemFromLocalStorage, setItemToLocalStorage } from '../libs/local-storage';
import { ENABLE_CUSTODY_LOCAL_STORAGE_NAME } from '../constants/app-constants';
import { useIsEnableCustody } from '../api/modules/account/use-is-enable-custody';

export const useCustodyCheck = () => {
  const fetchIsEnableCustody = useIsEnableCustody();

  const [isCustody, setIsCustody] = React.useState(false);
  const [isCustodyFetching, setIsCustodyFetching] = React.useState(false);
  const [isCustodyFetched, setIsCustodyFetched] = React.useState(false);

  const checkCustody = async () => {
    const isEnableCustodyFromLocalStorage = getItemFromLocalStorage(ENABLE_CUSTODY_LOCAL_STORAGE_NAME);

    if (isEnableCustodyFromLocalStorage === 'true') {
      setIsCustody(true);
      setIsCustodyFetched(true);

      return;
    }

    setIsCustodyFetching(true);
    const { data, status } = await fetchIsEnableCustody();
    setIsCustodyFetching(false);

    if (status === 200 && data?.result) {
      setIsCustody(true);
      setIsCustodyFetched(true);
      setItemToLocalStorage(ENABLE_CUSTODY_LOCAL_STORAGE_NAME, true);
    } else {
      setIsCustodyFetched(true);
    }
  };

  React.useEffect(() => {
    checkCustody();
  }, []);

  return {
    isCustody,
    isCustodyFetching,
    isCustodyFetched,
    setIsCustody,
    setIsCustodyFetching,
    setIsCustodyFetched,
  };
};
