import * as React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button } from '../ui/button';
import { PopupSuccess } from '../shared/popup-success';
import { New2faPopup } from '../new-2fa-popup';
import { balancesSelector } from '../../store/payment-settings/selectors';
import { useMediaQuery } from '../../hooks/use-media-query';
import { TRACK_EVENT_NAMES } from '../../constants/track-event-names';
import { METHOD_NAMES, SUCCESS_TEXTS } from './helpers';
import { PopupUploadCsv } from './components/popup-upload-csv';
import { useMassPayoutMethods } from './hooks';
import { PopupManualPayout } from './components/popup-manual-payout';
import classes from './styles.module.scss';

export const MassPayoutMethods = (props) => {
  const {
    className,
    reFetch,
  } = props;

  const balances = useSelector(balancesSelector());
  const isDesktop = useMediaQuery('(min-width: 768px)');

  const {
    isAutomaticMethodActive,
    isManualMethodActive,
    handleSetCurrentMethodName,
    handlePopupClose,
    withdrawalData,
    setWithdrawalData,
    handleRemoveWithdrawalData,
    withdrawalId,
    isWithdrawalSuccess,
    handleWithdrawalSuccessClose,
    handleCreateWithdrawal,
    withdrawalFetching,
    withdrawalApiError,
    isNeed2FA,
    handle2FACallback,
  } = useMassPayoutMethods(reFetch);

  return (
    <div className={cn(classes.massPayoutMethods, className)}>
      <div className={classes.massPayoutMethodsButtons}>
        <Button
          className={classes.massPayoutMethodsButton}
          onClick={handleSetCurrentMethodName(METHOD_NAMES.AUTO, TRACK_EVENT_NAMES.MASS_PAYOUTS_CSV)}
        >
          Upload CSV file
        </Button>
        <Button
          className={classes.massPayoutMethodsButton}
          onClick={handleSetCurrentMethodName(METHOD_NAMES.MANUAL, TRACK_EVENT_NAMES.MASS_PAYOUTS_MANUALLY)}
          displayBorder
        >
          Create manually
        </Button>
      </div>
      <PopupUploadCsv
        title="Upload CSV file"
        open={isAutomaticMethodActive}
        onClose={handlePopupClose}
        onWithdrawalData={setWithdrawalData}
        onRemoveWithdrawalData={handleRemoveWithdrawalData}
        withdrawalData={withdrawalData}
        onSubmit={handleCreateWithdrawal}
        fetching={withdrawalFetching}
        apiError={withdrawalApiError}
      />
      <PopupManualPayout
        open={isManualMethodActive}
        onClose={handlePopupClose}
        currencies={balances}
        desktop={isDesktop}
        onSubmit={handleCreateWithdrawal}
        fetching={withdrawalFetching}
        apiError={withdrawalApiError}
      />
      <PopupSuccess
        open={isWithdrawalSuccess}
        onClose={handleWithdrawalSuccessClose}
        title={SUCCESS_TEXTS.TITLE}
        text={SUCCESS_TEXTS.TEXT}
      />
      <New2faPopup
        open={isNeed2FA}
        onUpdate={handle2FACallback}
        withdrawalId={withdrawalId}
      />
    </div>
  );
};

MassPayoutMethods.defaultProps = {
  className: null,
  reFetch: null,
};

MassPayoutMethods.propTypes = {
  className: PropTypes.string,
  reFetch: PropTypes.func,
};
