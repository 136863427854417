import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useMediaQuery } from '../../hooks/use-media-query';
import { HistoryTableMemo } from '../history-table';
import { NewPagination } from '../shared/new-pagination';
import { Button } from '../ui/button';
import { PER_PAGE, THS_DESKTOP, THS_MOBILE } from './helpers';
import { PaymentHistoryTableRow } from './components/payment-history-table-row';
import classes from './styles.module.scss';

export const PaymentHistoryTable = React.memo((props) => {
  const {
    className,
    title,
    items,
    hasItems,
    hasSearchData,
    loading,
    onClick,
    count,
    onPaginatorClick,
    page,
    apiError,
    onRedirect,
    isTimeoutError,
    currenciesObject,
    withEmptyElement,
  } = props;

  const isDesktop = useMediaQuery('(min-width: 768px)');
  const thItems = isDesktop ? THS_DESKTOP : THS_MOBILE;
  const isPaginationShow = count > PER_PAGE;

  return (
    <>
      <div className={cn(classes.paymentHistoryTableBox, className)}>
        {title && (
          <div className={classes.paymentHistoryTableTitle}>
            {title}
          </div>
        )}
        <HistoryTableMemo
          className={classes.paymentHistoryTable}
          thItems={thItems}
          colSpan={6}
          loading={loading}
          historyData={items}
          hasData={hasItems || hasSearchData}
          emptyText="You don’t have any complete payments yet."
          notFoundText="No payments matching this criteria."
          apiError={apiError}
          emptyElement={withEmptyElement && (
            <Button
              className={classes.emptyBlockButton}
              onClick={onRedirect}
            >
              Create first payment
            </Button>
          )}
          isTimeoutError={isTimeoutError}
        >
          {hasItems && items.map((payment) => (
            <PaymentHistoryTableRow
              key={payment?.paymentId}
              onClick={onClick}
              isDesktop={isDesktop}
              currenciesObject={currenciesObject}
              payment={payment}
            />
          ))}
        </HistoryTableMemo>
      </div>
      {isPaginationShow && (
        <NewPagination
          className={classes.paginator}
          currentPage={page}
          countItems={count}
          itemsPerPage={PER_PAGE}
          onChangePage={onPaginatorClick}
        />
      )}
    </>
  );
});

PaymentHistoryTable.defaultProps = {
  className: null,
  title: null,
  items: null,
  hasItems: false,
  hasSearchData: false,
  loading: false,
  onClick: null,
  count: 0,
  onPaginatorClick: null,
  page: 1,
  apiError: null,
  isTimeoutError: false,
  currenciesObject: {},
  withEmptyElement: false,
};

PaymentHistoryTable.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  hasItems: PropTypes.bool,
  hasSearchData: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  count: PropTypes.number,
  onPaginatorClick: PropTypes.func,
  page: PropTypes.number,
  apiError: PropTypes.string,
  isTimeoutError: PropTypes.bool,
  currenciesObject: PropTypes.object,
  withEmptyElement: PropTypes.bool,
};

PaymentHistoryTable.displayName = 'PaymentHistoryTable';
