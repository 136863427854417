import * as React from 'react';
import { useObservePayment } from './use-observe-payment';
import { usePaymentsActions } from './use-payments-context';

export const useObservablePaymentPopup = (openPaymentInfoPopup) => {
  const [isObservablePaymentPopupOpen, setIsObservablePaymentPopupOpen] = React.useState(false);
  const [observablePaymentData, setObservablePaymentData] = React.useState(null);
  const { updatePaymentInCollection } = usePaymentsActions();
  const updatePaymentInCollectionRef = React.useRef(null);
  const {
    startObserve,
    stopObserve,
  } = useObservePayment();

  updatePaymentInCollectionRef.current = updatePaymentInCollection;
  
  const onPaymentUpdate = (payment, done = false) => {
    updatePaymentInCollectionRef.current?.(payment);

    if (done) {
      openPaymentInfoPopup(payment);
      closeObservablePaymentPopup();
    }
  };

  const openObservablePaymentPopup = React.useCallback((invoice, payment) => {
    setObservablePaymentData({
      invoice,
      payment,
    });

    if (payment) {
      startObserve(payment, onPaymentUpdate);
    }

    setIsObservablePaymentPopupOpen(true);
  }, []);

  const closeObservablePaymentPopup = React.useCallback(() => {
    stopObserve();
    setObservablePaymentData(null);
    setIsObservablePaymentPopupOpen(false);
  }, []);

  return {
    observablePaymentData,
    isObservablePaymentPopupOpen,
    openObservablePaymentPopup,
    closeObservablePaymentPopup,
  };
};