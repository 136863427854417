import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from '../../../popup';
import { NewInfoBox } from '../../../shared/new-info-box';

export const PaymentInfoPopup = (props) => {
  const {
    className,
    open,
    onClose,
    id,
    items,
    verified,
    onClick,
    on2faToggle,
    cryptoPayoutHistoryActive,
  } = props;

  return (
    <Popup
      classNameContent={className}
      open={open}
      onClose={onClose}
      withCloseButton
    >
      <NewInfoBox
        title="ID"
        id={id}
        items={items}
        verified={verified}
        onClick={onClick}
        on2faToggle={on2faToggle}
        showButtonVerify={cryptoPayoutHistoryActive}
      />
    </Popup>
  );
};

PaymentInfoPopup.defaultProps = {
  className: null,
  open: false,
  onClose: null,
  id: null,
  items: null,
  verified: false,
  onClick: null,
  on2faToggle: null,
  cryptoPayoutHistoryActive: false,
};

PaymentInfoPopup.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  id: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  verified: PropTypes.bool,
  onClick: PropTypes.func,
  on2faToggle: PropTypes.func,
  cryptoPayoutHistoryActive: PropTypes.bool,
};
