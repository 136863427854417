import { hasElementsInArray } from './has-elements-in-array';
import { safeToUpperCase } from './safe-to-upper-case';

/**
 * getPriceToUsd
 * @param {Array<object>} data
 * @param {Object} currenciesObject
 * @param {Boolean} isCode
 * @returns {String|null}
 */
export const getUniqueTickersForMarketInfo = (data, currenciesObject = {}, isCode = false) => {
  if (!hasElementsInArray(data)) {
    return null;
  }

  const tickers =  data.map((item) => (
    currenciesObject[safeToUpperCase(!isCode ? item?.ticker : item?.code)]?.ticker ?? null
  ));

  const uniqueTickers = new Set(tickers); // eslint-disable-line

  return Array.from(uniqueTickers).join(',');
};
