import React from 'react';
import { INDUSTRIES } from '../../constants';
import { SimpleSelectField } from '../../../ui/simple-select-field';
import { IndustrySelectProps } from '../../types';
import classes from './styles.module.scss';

export const IndustrySelectField: React.FC<IndustrySelectProps> = (props) => {
  const {
    className,
    title,
    placeholder,
    onSelect,
    activeIndustryKey,
  } = props;

  const activeIndustry = INDUSTRIES.find((el) => el.key === activeIndustryKey);

  return (
    <div className={className}>
      {title && (
        <p className={classes.industrySelectFieldTitle}>
          {title}
        </p>
      )}
      <SimpleSelectField
        className={classes.industrySelect}
        data={
          {
            items: INDUSTRIES,
            placeholder: placeholder || 'Choose your industry',
            onSelect,
            defaultValue: activeIndustry?.value,
            defaultImagePath: activeIndustry?.logoUrl,
          }
        }
      />
    </div>
  );
};
