import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { NavLink, useHistory } from 'react-router-dom';
import { MainMenuDropdown } from '../main-menu-dropdown';
import { isFunction } from '../../helpers/is-function';
import classes from './styles.module.scss';

const MainMenuItem = (props) => {
  const {
    className,
    name,
    href,
    submenu,
    Icon,
    externalLink,
    beta,
    onClick,
    hasNewItem,
  } = props;

  const history = useHistory();
  const pathName = history?.location?.pathname ?? '';

  const [isActive, setIsActive] = React.useState(false);

  const handleClick = (event) => {
    if (isFunction(onClick)) {
      onClick(event);
    }
  };
  const handleKeyPress = (event) => {
    if (isFunction(onClick)) {
      onClick(event);
    }
  };

  React.useEffect(() => {
    const isOtherPathName = pathName !== href;
    const isPathNameStartsWithHref = isOtherPathName ? pathName.startsWith(href) : false;

    if (href && isPathNameStartsWithHref) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [pathName, href]);

  return (
    <li className={cn(classes.mainMenuItem, className)}>
      {href && !externalLink && (
        <NavLink
          className={cn([
            classes.mainMenuItemLink,
            isActive && classes.mainMenuItemLinkActive,
          ])}
          activeClassName={classes.mainMenuItemLinkActive}
          to={href}
          exact
          onClick={handleClick}
          onKeyPress={handleKeyPress}
        >
          {Icon}
          {name}
          {beta && (
            <span className={classes.mainMenuItemLinkBeta}>
              Beta
            </span>
          )}
        </NavLink>
      )}
      {href && externalLink && (
        <a
          className={classes.mainMenuItemLink}
          href={href}
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          {Icon}
          {name}
        </a>
      )}
      {!href && (
        <MainMenuDropdown
          className={classes.mainMenuItemDropdown}
          Icon={Icon}
          name={name}
          items={submenu}
          onClick={onClick}
          hasNewItem={hasNewItem}
        />
      )}
    </li>
  );
};

MainMenuItem.defaultProps = {
  className: null,
  name: null,
  href: null,
  submenu: null,
  Icon: null,
  externalLink: false,
  beta: false,
  onClick: null,
  hasNewItem: false,
};

MainMenuItem.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  href: PropTypes.string,
  submenu: PropTypes.arrayOf(PropTypes.object),
  Icon: PropTypes.element,
  externalLink: PropTypes.bool,
  beta: PropTypes.bool,
  onClick: PropTypes.func,
  hasNewItem: PropTypes.bool,
};

export const MainMenuItemMemo = React.memo(MainMenuItem);
