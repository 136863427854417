import React from "react";
import cn from "classnames";
import styles from "../donation-link/styles.module.scss";
import localStyles from "./styles.module.scss";

export const SelectApiKeys = ({ apiKeys, handleSelectChange }) => {
  return (
    <select
      className={cn(styles.selectApiKey, localStyles.select)}
      onChange={handleSelectChange}
    >
      {apiKeys.map((api, index) => (
        <option key={index} value={api.api_key}>
          {api.api_key}
        </option>
      ))}
    </select>
  );
};
