import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Radio } from '../../../ui/radio';
import { CurrencyNetworkSup } from '../../../shared/currency-network-sup';
import classes from './styles.module.scss';

export const CustomerActiveBillingPlanInfo = (props) => {
  const {
    className,
    activeId,
    onChange,
    data,
  } = props;

  const id = data?.id ?? null;
  const planId = data?.planId ?? null;
  const name = data?.name ?? null;
  const amount = data?.amount ?? null;
  const ticker = data?.currency ?? null;
  const price = amount ? `${amount} ${ticker}` : null;
  const network = data?.network ?? null;
  const intervalDay = data?.intervalDay ?? null;
  const intervalDayText = intervalDay ? `${intervalDay} Days` : null;
  const isActive = activeId === id;

  const items = React.useMemo(() => ([{
    id: '1',
    key: 'Plan name',
    value: name,
    network: null,
  }, {
    id: '2',
    key: 'Cost per period',
    value: price,
    network,
  }, {
    id: '3',
    key: 'Period',
    value: intervalDayText,
    network: null,
  }]), [data]);

  return (
    <div className={cn([
      classes.planInfo,
      isActive && classes.planInfoActive,
      className,
    ])}>
      <Radio
        className={classes.radioButton}
        value={id}
        checked={isActive}
        onChange={onChange}
      >
        <div className={classes.planInfoKey}>
          Plan ID
        </div>
        <div className={classes.planInfoValue}>
          {planId}
        </div>
      </Radio>
      {isActive && (
        <div className={classes.planInfoContent}>
          {items.map((item) => (
            <div className={classes.planInfoItem} key={item.id}>
              <div className={classes.planInfoKey}>
                {item.key}
              </div>
              <div className={cn([
                classes.planInfoValue,
                item.network && classes.planInfoValueFlex,
              ])}>
                {item.value}
                {item.network && (
                  <CurrencyNetworkSup network={item.network} />
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

CustomerActiveBillingPlanInfo.defaultProps = {
  className: null,
  activeId: null,
  onChange: null,
  data: null,
};

CustomerActiveBillingPlanInfo.propTypes = {
  className: PropTypes.string,
  activeId: PropTypes.string,
  onChange: PropTypes.func,
  data: PropTypes.object,
};
