const currencyFormat = (currency) => {
  switch (currency) {
    case 'BUSD':
      return 'BUSDERC20';
    case 'ZKSYNC':
      return 'ETHZKSYNC';
    default:
      return currency;
  }
};

export default currencyFormat;
