import { hasElementsInArray } from './has-elements-in-array';
import { safeToLowerCase } from './safe-to-lower-case';

/**
 * @param data {array}
 * @returns {array}
 */
export const getCSVDataFiltered = (data) => {
  const hasData = hasElementsInArray(data);

  if (!hasData) {
    return [];
  }

  return data.filter((item, index) => {
    const itemData = item?.data ?? [];
    const hasData = itemData?.some((item) => item?.trim() !== '') || false;

    if (index === 0 && itemData[0] === 'Currency') {
      return false;
    }

    return hasData;
  });
};

/**
 * @param data {array}
 * @param isCurrencyOnly {boolean}
 * @returns {string|null}
 */
export const getCSVErrorMessage = (data, isCurrencyOnly = false) => {
  const hasData = hasElementsInArray(data);

  if (!hasData) {
    return null;
  }

  let errorFieldName = null;
  let errorLineIndex = null;

  const itemFound = data.find((item, index) => {
    const indexNormalized = index + 2;
    const currency = item?.currency ?? null;
    const address = item?.address ?? null;
    const amount = item?.amount ?? null;
    const fiatAmount = item?.fiat_amount ?? null;
    const fiatCurrency = item?.fiat_currency ?? null;
    const hasFiat = fiatAmount && fiatCurrency;

    if (!currency) {
      errorFieldName = 'Currency';
      errorLineIndex = indexNormalized;

      return item;
    }

    if (!address) {
      errorFieldName = 'Address';
      errorLineIndex = indexNormalized;

      return item;
    }

    if (isCurrencyOnly) {
      return null;
    }

    if (fiatAmount && !fiatCurrency) {
      errorFieldName = 'Fiat Currency';
      errorLineIndex = indexNormalized;

      return item;
    }

    if (fiatCurrency && !fiatAmount) {
      errorFieldName = 'Fiat amount';
      errorLineIndex = indexNormalized;

      return item;
    }

    if (!hasFiat && !amount) {
      errorFieldName = 'Amount';
      errorLineIndex = indexNormalized;

      return item;
    }

    return null;
  });

  if (itemFound) {
    return `Field ${errorFieldName} on line ${errorLineIndex} is required`;
  }

  return null;
};

/**
 * @param data {array}
 * @param isCurrencyOnly {boolean}
 * @returns {array}
 */
export const getCSVDataNormalized = (data, isCurrencyOnly = false) => {
  const hasData = hasElementsInArray(data);

  if (!hasData) {
    return [];
  }

  return data.map((item) => {
    const itemData = item?.data ?? [];
    const ticker = itemData[0]?.trim() || '';
    const tickerLowerCased = safeToLowerCase(ticker);
    const address = itemData[1]?.trim() || null;
    const extraId = itemData[2]?.trim() || null;

    if (isCurrencyOnly) {
      return {
        currency: tickerLowerCased,
        address,
        extra_id: extraId,
      };
    }

    const amount = Number(itemData[3]) || null;
    const fiatAmount = Number(itemData[4]) || null;
    const fiatTicker = itemData[5]?.trim() || null;
    const fiatTickerLowerCased = safeToLowerCase(fiatTicker);

    return {
      currency: tickerLowerCased,
      address,
      extra_id: extraId,
      amount,
      fiat_amount: fiatAmount,
      fiat_currency: fiatTickerLowerCased,
    };
  });
};
