import React from "react";
import withModal from "../../../../components/with-modal";
import styles from "./styles.module.scss";

function BackupModal(props) {
  const { backupKey, onClose } = props;

  return (
    <div className={styles.backupCodeModal}>
      <div className={styles.closeButton} onClick={onClose} />
      <h2 className={styles.modalHeader}>Backup code</h2>
      <p>
        If you ever lose access to your device, you can use this code to verify
        your identity. This code can only be used once.
      </p>
      <div>
        <div className={styles.backupKey}>{backupKey}</div>
      </div>
    </div>
  );
}

export default withModal(BackupModal);
