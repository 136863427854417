import React from 'react';
import cn from 'classnames';
import { PaymentDetailsSettings } from '../../../payment-details-settings';

type PaymentDetailsSectionProps = {
  className: string,
  classNameActive: string,
};

export const PaymentDetailsSection: React.FC<PaymentDetailsSectionProps> = (props) => {
  const {
    className,
    classNameActive,
  } = props;

  const [isRendered, setIsRendered] = React.useState(false);

  React.useEffect(() => {
    setIsRendered(true);
  }, []);

  return <PaymentDetailsSettings className={cn(className, isRendered && classNameActive)} />;
};
