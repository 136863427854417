import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import LoadingPage from '../../pages/loading';
import { ROUTES } from '../../constants/routes';

export const CurrentRoutes = (props) => {
  const {
    authFetched,
    auth,
    routes,
    onRouteComponent,
    privateRoutes,
  } = props;

  if (!authFetched) {
    return (
      <Switch>
        <Route path="/" component={LoadingPage} />
      </Switch>
    );
  }

  if (!auth) {
    return (
      <Switch>
        {routes.map((route) => (
          <Route
            exact
            component={onRouteComponent(route)}
            key={route.path}
            path={route.path}
          />
        ))}
        <Redirect from="/" to={ROUTES.SIGN_IN} key="route-sign-in" />
      </Switch>
    );
  }

  return (
    <Switch>
      <Redirect exact from="/" to={ROUTES.DASHBOARD} />
      <Redirect exact from="/sign-in" to={ROUTES.DASHBOARD} />
      <Redirect exact from="/create-account" to={ROUTES.DASHBOARD} />
      <Redirect exact from="/email-verification" to="/wallet-addition" />
      {privateRoutes.map((route) => (
        <Route
          exact
          component={onRouteComponent(route)}
          key={route.path}
          path={route.path}
        />
      ))}
    </Switch>
  );
};

CurrentRoutes.defaultProps = {
  authFetched: false,
  auth: false,
  routes: [],
  privateRoutes: [],
  onRouteComponent: () => {},
};

CurrentRoutes.protoTypes = {
  authFetched: PropTypes.bool,
  auth: PropTypes.bool,
  routes: PropTypes.arrayOf(PropTypes.object),
  privateRoutes: PropTypes.arrayOf(PropTypes.object),
  onRouteComponent: PropTypes.func,
};
