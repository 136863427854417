import * as React from 'react';
import cn from 'classnames';
import { isFunction } from '../../../../helpers/is-function';
import { isCurrencyNetworkShow } from '../../../../helpers/is-currency-network-show';
import { CurrencyNetworkSup } from '../../../shared/currency-network-sup';
import { GroupCurrencyProps } from '../../types';
import classes from './styles.module.scss';

export const GroupCurrency: React.FC<GroupCurrencyProps> = (props) => {
  const {
    className = null,
    image,
    ticker,
    currentTicker,
    network,
    name,
    onClick,
    active,
    categoryName,
    componentDisabled,
  } = props;

  const isNetworkShow: boolean = isCurrencyNetworkShow(network, ticker);

  const handleClick = () => {
    if (!componentDisabled && isFunction(onClick)) {
      onClick(ticker, active, categoryName);
    }
  };

  return (
    <div
      className={cn([
        classes.groupCurrency,
        !componentDisabled && classes.groupCurrencyInteractive,
        (!active || componentDisabled) && classes.groupCurrencyDisabled,
        className,
      ])}
      onClick={handleClick}
      onKeyDown={handleClick}
      tabIndex={0}
      role="button"
    >
      <img
        src={image}
        alt={ticker}
        className={classes.groupCurrencyImage}
        decoding="async"
        loading="lazy"
      />
      <div className={classes.groupCurrencyContent}>
        <div className={classes.groupCurrencyTicker}>
          {currentTicker}
          {isNetworkShow && (
            <CurrencyNetworkSup
              className={classes.groupCurrencyNetwork}
              network={network}
            />
          )}
        </div>
        {!componentDisabled && (
          <div className={classes.groupCurrencyName}>
            {name}
          </div>
        )}
      </div>
    </div>
  );
};
