import React from 'react';
import { PaymentDetailsTab } from './components/payment-details-tab';
import { TABS } from './helpers';
import { useInitState } from './hooks';
import { useBaseCurrencyForSelect } from '../../hooks/use-base-currency-for-select';

type PaymentDetailsSettingsProps = {
  className: string,
};

export const PaymentDetailsSettings: React.FC<PaymentDetailsSettingsProps> = (props) => {
  const { className } = props;

  const {
    currentBaseCurrencyCode,
    baseCurrencies,
  } = useBaseCurrencyForSelect();

  const {
    donationFirstCurrency,
    invoiceFirstCurrency,
    partnerCurrencies,
    isFeeOptimisationEnable,
    feePaidBy,
    markup,
    covering,
    isAutoPush,
    partnerId,
    setDonationFirstCurrency,
    setInvoiceFirstCurrency,
  } = useInitState();

  return (
    <div className={className}>
      {TABS.map((tab) => (
        <PaymentDetailsTab
          key={tab.title}
          title={tab.title}
          iconPath={tab.iconPath}
          currentBaseCurrencyCode={currentBaseCurrencyCode}
          baseCurrencies={baseCurrencies}
          donationFirstCurrency={donationFirstCurrency}
          invoiceFirstCurrency={invoiceFirstCurrency}
          partnerCurrencies={partnerCurrencies}
          isFeeOptimisationEnable={isFeeOptimisationEnable}
          feePaidBy={feePaidBy}
          markup={markup}
          covering={covering}
          isAutoPush={isAutoPush}
          partnerId={partnerId}
          onDonationFirstCurrencyChange={setDonationFirstCurrency}
          onInvoiceFirstCurrencyChange={setInvoiceFirstCurrency}
        />
      ))}
    </div>
  );
};
