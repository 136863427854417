import React from 'react';
import { nanoid } from 'nanoid';

export const usePagination = (currentPage, countPages) => {
  const [pages, setPages] = React.useState([]);

  React.useEffect(() => {
    const nextPages = [{
      page: currentPage,
      id: nanoid(),
    }];

    if (currentPage > 1) {
      nextPages.unshift({
        page: currentPage - 1,
        id: nanoid(),
      });
    }
    if (currentPage < countPages) {
      nextPages.push({
        page: currentPage + 1,
        id: nanoid(),
      });
    }
    if (currentPage - 2 > 1) {
      nextPages.unshift({
        page: 0,
        id: nanoid(),
      });
      nextPages.unshift({
        page: 1,
        id: nanoid(),
      });
    } else if (currentPage - 2 === 1) {
      nextPages.unshift({
        page: 1,
        id: nanoid(),
      });
    }
    if (currentPage + 2 < countPages) {
      nextPages.push({
        page: 0,
        id: nanoid(),
      });
      nextPages.push({
        page: countPages,
        id: nanoid(),
      });
    } else if (currentPage + 2 === countPages) {
      nextPages.push({
        page: countPages,
        id: nanoid(),
      });
    }
    setPages(nextPages);
  }, [currentPage, countPages]);

  return pages;
};
