import React from 'react';
import cn from 'classnames';
import { PAYMENT_SETTINGS_NAVIGATION } from './helpers';
import { InnerNavigationTabs } from '../inner-navigation-tabs';
import { useMediaQuery } from '../../hooks/use-media-query';
import { AddressesSection } from './components/addresses-section';
import { PaymentDetailsSection } from './components/payment-details-section';
import { IpnSection } from './components/ipn-section';
import { useRouteHashAndStepVariables } from './hooks';
import { useFetchPartnerProfile } from '../../api/modules/account/use-fetch-partner-profile';
import { useAllCurrencies } from '../../hooks/use-all-currencies';
import { useStoreOutcomeData } from '../../hooks/use-store-outcome-data';
import { useBaseCurrencyData } from '../../hooks/use-base-currency-data';
import { PayoutWalletsSection } from './components/payout-wallets-section';
import { useApiKeysData } from '../../hooks/use-api-keys-data';
import { ApiKeysSection } from './components/api-keys-section';
import classes from './styles.module.scss';

export const PaymentsSettingsPage = () => {
  const isDesktop = useMediaQuery('(min-width: 1200px)');

  const {
    steps,
    addHashToRoute,
    hashText,
  } = useRouteHashAndStepVariables();

  useFetchPartnerProfile();
  useAllCurrencies();
  useStoreOutcomeData();
  useBaseCurrencyData();
  useApiKeysData();

  return (
    <>
      <h1>Payments Settings</h1>
      <div className={classes.section}>
        <div className={classes.sectionNavigation}>
          {isDesktop && (
            <div className={classes.innerNavigationTitle}>
              Payment flow customization
            </div>
          )}
          <InnerNavigationTabs
            className={classes.innerNavigation}
            classNameList={classes.innerNavigationList}
            items={PAYMENT_SETTINGS_NAVIGATION}
            onClick={addHashToRoute}
            activeHash={hashText}
          />
        </div>
        <div className={cn([
          classes.sectionContent,
          steps.isPaymentNotifications && classes.sectionContentIpn,
        ])}
        >
          {steps.isPayoutWallets && (
            <PayoutWalletsSection
              className={classes.sectionComponent}
              classNameActive={classes.sectionComponentActive}
            />
          )}
          {steps.isApiKeys && (
            <ApiKeysSection
              className={classes.sectionComponent}
              classNameActive={classes.sectionComponentActive}
            />
          )}
          {steps.isIpAddresses && (
            <AddressesSection
              className={classes.sectionComponent}
              classNameActive={classes.sectionComponentActive}
            />
          )}
          {steps.isPaymentDetails && (
            <PaymentDetailsSection
              className={classes.sectionComponent}
              classNameActive={classes.sectionComponentActive}
            />
          )}
          {steps.isPaymentNotifications && (
            <IpnSection
              className={classes.sectionComponent}
              classNameActive={classes.sectionComponentActive}
            />
          )}
        </div>
      </div>
    </>
  );
};
