import React from 'react';
import PropTypes from 'prop-types';

export const CustodyWithdrawIcon = (props) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0263 7.45292C12.6194 7.45292 12.2895 7.12767 12.2895 6.72646C12.2895 6.32525 12.6194 6 13.0263 6H15.7632H17.1316H17.8158C17.8158 6 18.1842 6.01104 18.3421 6.20807C18.5 6.40509 18.5 6.69232 18.5 6.69232V7.34914V8.69828V11.3966C18.5 11.7978 18.1701 12.123 17.7632 12.123C17.3562 12.123 17.0263 11.7978 17.0263 11.3966V8.48029L7.75787 17.6181C7.47011 17.9018 7.00357 17.9018 6.71582 17.6181C6.42806 17.3344 6.42806 16.8745 6.71582 16.5908L15.9843 7.45292H13.0263Z"
        fill="#64ACFF"
      />
    </svg>
  );
};

CustodyWithdrawIcon.defaultProps = {
  className: null,
};

CustodyWithdrawIcon.propTypes = {
  className: PropTypes.string,
};
