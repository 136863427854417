import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import SubmitButton, { buttonState } from '../../../components/submit-button';
import ToolTip from '../../../components/tool-tip';
import { isFunction } from '../../../helpers/is-function';
import styles from './styles.module.scss';

const AffiliateHeader = (props) => {
  const {
    successMessage,
    errorMessage,
    payoutButtonState,
    onPayoutButtonClick,
  } = props;
  const currentSuccessMessage = successMessage || 'Payout request successfully sent';

  const handleClick = () => {
    if (isFunction(onPayoutButtonClick)) {
      onPayoutButtonClick();
    }
  };

  return (
    <>
      <div className={styles.affHeader}>
        <h1>Affiliate Program</h1>

        <SubmitButton
          successMessage={currentSuccessMessage}
          failureMessage={errorMessage}
          buttonState={payoutButtonState}
          className={payoutButtonState === buttonState.success ? styles.successDiv : styles.payoutBtn}
          onSubmit={handleClick}
        >
          Request payout
        </SubmitButton>

        {props.payoutButtonState === buttonState.normal && <FontAwesomeIcon
          icon={faInfoCircle}
          size="1x"
          className={styles.qIcon}
          data-tooltip-id="affiliate-header-tooltip"
          data-tooltip-content="Minimum payout sum is 50 USDT"
        />}
        <ToolTip id="affiliate-header-tooltip" className={styles.toolTip} />
      </div>
    </>
  );
};

AffiliateHeader.defaultProps = {
  successMessage: '',
  errorMessage: '',
  payoutButtonState: '',
  onPayoutButtonClick: null,
};

AffiliateHeader.propTypes = {
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  payoutButtonState: PropTypes.string,
  onPayoutButtonClick: PropTypes.func,
};

export default AffiliateHeader;
