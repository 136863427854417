import * as React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import cn from 'classnames';
import classes from './styles.module.scss';
import 'slick-carousel/slick/slick.css';

const OPTIONS = {
  dots: true,
  dotsClass: classes.slickSliderCustomDots,
  infinite: true,
  speed: 500,
  autoplay: false,
  autoplaySpeed: 3000,
  pauseOnHover: false,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export const SlickSliderCustom = (props) => {
  const {
    className,
    children,
    options,
  } = props;

  const currentOptions = { ...OPTIONS, ...options };

  return (
    <Slider
      className={cn(classes.slickSliderCustom, className)}
      {...currentOptions}
    >
      {children}
    </Slider>
  );
};

SlickSliderCustom.defaultProps = {
  className: null,
  options: OPTIONS,
  children: null,
};

SlickSliderCustom.propTypes = {
  className: PropTypes.string,
  options: PropTypes.shape({}),
  children: PropTypes.node,
};
