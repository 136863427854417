import * as React from 'react';
import cn from 'classnames';
import { ProvidersCards } from '../../providers-cards';
import { ExternalLinkWithUnderline } from '../../external-link-with-underline';
import { Button } from '../../ui/button';
import { PERMISSION_TO_PROCESS_DATA } from '../../../constants/providers-constants';
import { Provider } from '../../../types';
import classes from './styles.module.scss';

type ActivateProvidersProps = {
  providers: Array<Provider>;
  partnerEmail: string;
};

export const ActivateProviders: React.FC<ActivateProvidersProps> = (props) => {
  const {
    providers,
    partnerEmail,
  } = props;
  const [activationLink, setActivationLink] = React.useState('');
  const [isPermissionShow, setIsPermissionShow] = React.useState(false);

  const handleActiveButtonClick = (link: string) => {
    setActivationLink(link);
    setIsPermissionShow(true);
  };

  return (
    <div className={classes.activateProviders}>
      <div className={cn([
        classes.activateProvidersPermissionBlock,
        isPermissionShow && classes.activateProvidersPermissionBlockActive,
      ])}
      >
        <h3 className={classes.activateProvidersTitle}>
          {PERMISSION_TO_PROCESS_DATA.title}
        </h3>
        <p className={classes.activateProvidersDescription}>
          {PERMISSION_TO_PROCESS_DATA.text}
        </p>
        <Button
          className={classes.proceedButton}
          href={activationLink}
          target="_blank"
          rel="nofollow noreferrer noopener"
        >
          Proceed
        </Button>
      </div>
      <div className={cn([
        classes.activateProvidersInitialBlock,
        !isPermissionShow && classes.activateProvidersInitialBlockActive,
      ])}
      >
        <h3 className={classes.activateProvidersTitle}>
          Activate providers
        </h3>
        <p className={classes.activateProvidersDescription}>
          Please activate at least one provider to make a withdrawals in fiat.
        </p>
        <ProvidersCards
          cards={providers}
          onClick={handleActiveButtonClick}
          className={classes.activateProvidersCards}
          isSmallCards
          partnerEmail={partnerEmail}
        />
        <ExternalLinkWithUnderline
          href="https://nowpayments.notion.site/Off-ramp-Payment-Providers-Fees-Other-Details-d6fd8471035141f08a55631c0e705a85?pvs=4"
          text="All you need to know about providers"
        />
      </div>
    </div>
  );
};
