import React from 'react';
import Pagination from '../../components/pagination';
import apiClient from '../../api/api-client';
import DonationsTable from '../../components/donations-table';
import DonationLinkSettings from '../../components/donation-link';
import { DonationPreviewBtn } from '../../components/donation-preview-btn';
import { PageHeader } from '../../components/ui/page-header';
import { DonationPreviewWidget } from '../../components/donation-preview-widget';
import { usePageNamesToDisplayInfo } from '../../hooks/use-page-names-to-display-info';
import { PAGE_NAMES_TO_DISPLAY_INFO } from '../../constants/app-constants';
import { DonationsInfoAboutUse } from './components/donations-info-about-use';
import styles from './styles.module.scss';

const ELEMENTS_PER_PAGE = 5;

const DonationPage = () => {
  const {
    infoBoxShow,
    addPageNameToDisplayInfo,
    removePageNameToDisplayInfo,
  } = usePageNamesToDisplayInfo(PAGE_NAMES_TO_DISPLAY_INFO.DONATIONS);

  const [donations, setDonations] = React.useState([]);
  const [currencyDonations, setCurrencyDonations] = React.useState([]);
  const [page, setPage] = React.useState(1);

  const showPaymentsOnPage = (page) => {
    const start = (page - 1) * ELEMENTS_PER_PAGE;

    setCurrencyDonations(donations.slice(start, start + ELEMENTS_PER_PAGE));
  };

  const setUniversalState = (stateName, value) => {
    if (stateName === 'page') {
      setPage(value);
      showPaymentsOnPage(value);
    }
  };

  React.useEffect(() => {
    //eslint-disable-next-line
    (async () => {
      const { donation_payments } = await apiClient.getDonationPayments();

      setDonations(donation_payments);
      setCurrencyDonations(donation_payments);
    })();
  }, []);

  React.useEffect(() => {
    if (donations.length) {
      showPaymentsOnPage(page);
    }
  }, [donations, page]);

  return (
    <>
      <PageHeader
        title="Donations"
        showTooltipFirstText={!infoBoxShow}
        onClick={infoBoxShow ? removePageNameToDisplayInfo : addPageNameToDisplayInfo}
      />
      {infoBoxShow && (
        <DonationsInfoAboutUse
          className={styles.infoBlock}
          name={PAGE_NAMES_TO_DISPLAY_INFO.DONATIONS}
          onClose={removePageNameToDisplayInfo}
        />
      )}
      <DonationsTable payments={currencyDonations} />
      <div className={styles.paginator}>
        &nbsp;
        {currencyDonations.length > 0 && (
          <Pagination
            currentPage={page}
            setUniversalState={setUniversalState}
            isNextPageExist={page * ELEMENTS_PER_PAGE < donations.length}
          />
        )}
      </div>
      <DonationLinkSettings />
      <DonationPreviewBtn />
      <DonationPreviewWidget />
    </>
  );
};

export default DonationPage;
