import React from 'react';
import { BankAccount } from '../../../../types';
import classes from './styles.module.scss';

type AccountListProps = {
  accounts: Array<BankAccount>;
};

export const AccountList: React.FC<AccountListProps> = (props) => {
  const { accounts } = props;

  return (
    <ul className={classes.accountList}>
      {accounts && accounts.map((account) => (
        <li
          className={classes.accountListItem}
          key={account.createdAt}
        >
          <img
            className={classes.accountListItemImage}
            src={account.logoUrl}
            alt={account.fiatCurrencyCode}
            decoding="async"
            loading="lazy"
            width={20}
            height={20}
          />
          <p>
            {`${account.fiatCurrencyCode},`}
            &nbsp;
            <strong>{account.providerAccountInfo?.accountNumber}</strong>
          </p>
        </li>
      ))}
    </ul>
  );
};
