import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { isFunction } from '../../helpers/is-function';
import { CurrenciesSelectField } from '../currencies-select-field';
import { Field } from '../ui/field';
import { Button } from '../ui/button';
import { Switcher } from '../ui/switcher';
import { LoaderIcon } from '../icons/loader-icon';
import { toFixed } from '../../helpers/to-fixed';
import { safeToUpperCase } from '../../helpers/safe-to-upper-case';
import { hasElementsInArray } from '../../helpers/has-elements-in-array';
import { CustomSelectField } from '../ui/custom-select-field';
import { getSplitProvidersByStatus } from '../../helpers/get-split-providers-by-status';
import { AddWallet } from '../custody-page/components/add-wallet';
import { IP_ERROR_CODE } from '../custody-page/custody-page-helpers';
import { ActivateProviders } from './activate-providers';
import { getDefaultValue } from './helpers';
import classes from './styles.module.scss';

const REQUEST_PAYOUT_AMOUNT_ZERO_TEXT = 'No funds on the balance at the moment. Please note that it may take some time to update your balances';
const AMOUNT_MORE_THAN_BALANCE_TEXT = 'Amount is above balance value';
const AMOUNT_IS_REQUIRED_TEXT = 'Amount is required';
const PROVIDER_IS_REQUIRED_TEXT = 'Provider is required';
const BANK_ACCOUNT_IS_REQUIRED_TEXT = 'Bank account is required';
const ADD_BANK_ACCOUNT_TEXT = 'Please add bank account first';
const DEFAULT_DIGITS = 8;

export const RequestPayoutForm = (props) => {
  const {
    className,
    title,
    currencies,
    currency,
    onClick,
    errorMessage,
    onResetPayoutFormData,
    fetching,
    onAddAccountClick,
    providersData,
    walletData,
  } = props;

  const {
    providers,
    onSelectProvider,
    selectedProvider,
    accounts,
    isAccountsLoading,
    onChange,
    fiatEstimate,
    isFiatEstimateFetching,
    partnerEmail,
  } = providersData;

  const {
    address,
    onWalletSubmit,
    walletError,
    walletFetching,
  } = walletData;

  const ticker = currency?.code ?? '';
  const tickerUpperCased = safeToUpperCase(ticker);
  const currentTicker = currency?.ticker ?? '';
  const currentTickerUpperCased = safeToUpperCase(currentTicker);
  const amount = currency?.amount ?? 0;

  const { activatedProviders, availableProviders } = getSplitProvidersByStatus(providers);
  const defaultProvider = React.useMemo(() => getDefaultValue(activatedProviders), []);
  const defaultAccount = getDefaultValue(accounts);

  const [selectedAccount, setSelectedAccount] = React.useState(defaultAccount);
  const [withdrawalAmount, setWithdrawalAmount] = React.useState('');
  const [withdrawalAmountError, setWithdrawalAmountError] = React.useState(null);
  const [errorBalanceMessage, setErrorBalanceMessage] = React.useState(null);
  const [providerError, setProviderError] = React.useState(null);
  const [bankAccountError, setBankAccountError] = React.useState(null);
  const [addAccountsError, setAddAccountsError] = React.useState(null);
  const [isBankAccountTabActive, setIsBankAccountTabActive] = React.useState(false);

  const hasActivatedProviders = hasElementsInArray(activatedProviders);
  const hasBankAccounts = hasElementsInArray(accounts);
  const isBankAccountTabWithoutProviders = isBankAccountTabActive && !hasActivatedProviders;
  const isBankAccountTabWithProviders = isBankAccountTabActive && hasActivatedProviders;
  const isBankAccountTabWithoutAccounts = isBankAccountTabActive && hasActivatedProviders && !hasBankAccounts;
  const isBankAccountTabAllActivated = isBankAccountTabActive && hasActivatedProviders && hasBankAccounts;
  const isWalletTabWithoutAddress = !isBankAccountTabActive && !address;
  const isWalletTabWithAddress = !isBankAccountTabActive && address;
  const isFormSubmitShow = isBankAccountTabWithProviders || isWalletTabWithAddress;
  const withdrawalAmountNumber = Number(withdrawalAmount || null);
  const isEstimateAvailable = selectedProvider && isBankAccountTabActive && selectedAccount
    && Boolean(withdrawalAmountNumber) && !withdrawalAmountError;

  const currentBalanceToFixed = React.useMemo(() => (
    toFixed(amount, DEFAULT_DIGITS)
  ), [amount]);
  const outcomeBalanceToFixed = React.useMemo(() => {
    const amountNumber = Number(amount);
    const resultBalance = amountNumber - withdrawalAmountNumber;
    const isResultValid = !Number.isNaN(resultBalance) && resultBalance >= 0;
    const currentOutcomeBalance = isResultValid
      ? resultBalance
      : '0';

    return toFixed(currentOutcomeBalance, DEFAULT_DIGITS);
  }, [withdrawalAmount, amount, toFixed]);

  const handleAmountChange = (value) => {
    setWithdrawalAmount(value);
  };
  const handleSwitcherChange = () => {
    setIsBankAccountTabActive((prev) => !prev);
  };
  const handleAccountSelect = React.useCallback((account) => {
    setSelectedAccount(account || null);
  }, []);
  const handleFormSubmit = () => {
    const isError = Boolean(withdrawalAmountError);
    const isAmountZero = amount === 0;

    if (isAmountZero) {
      setErrorBalanceMessage(REQUEST_PAYOUT_AMOUNT_ZERO_TEXT);

      return;
    }

    if (!withdrawalAmountNumber) {
      setWithdrawalAmountError(AMOUNT_IS_REQUIRED_TEXT);

      return;
    }

    if (isBankAccountTabActive && !selectedProvider) {
      setProviderError(PROVIDER_IS_REQUIRED_TEXT);

      return;
    }

    if (isBankAccountTabWithoutAccounts) {
      setAddAccountsError(ADD_BANK_ACCOUNT_TEXT);

      return;
    }

    if (isBankAccountTabActive && !selectedAccount) {
      setBankAccountError(BANK_ACCOUNT_IS_REQUIRED_TEXT);

      return;
    }

    if (isError) {
      return;
    }

    const formData = isBankAccountTabActive
      ? {
        ticker: ticker,
        amount: withdrawalAmountNumber,
        fiatCurrency: selectedAccount?.fiatCurrencyCode,
      } : {
        ticker: ticker,
        amount: withdrawalAmountNumber,
        address,
      };

    if (isFunction(onClick)) {
      onClick({
        isFiatPayout: isBankAccountTabActive,
        formData,
      });
    }
  };

  React.useEffect(() => {
    if (amount) {
      setWithdrawalAmount(String(amount));
    }
  }, [amount]);

  React.useEffect(() => {
    onResetPayoutFormData();

    if (withdrawalAmountNumber > amount) {
      setWithdrawalAmountError(AMOUNT_MORE_THAN_BALANCE_TEXT);

      return;
    } else {
      setWithdrawalAmountError(null);
    }

    if (selectedProvider) {
      setProviderError(null);
    }

    if (selectedAccount) {
      setBankAccountError(null);
    }

    if (isEstimateAvailable) {
      onChange(selectedAccount?.fiatCurrencyCode, ticker, withdrawalAmountNumber);
    }
  }, [withdrawalAmount, amount, selectedProvider, selectedAccount, isBankAccountTabActive]);

  React.useEffect(() => {
    if (!isBankAccountTabWithoutAccounts) {
      setAddAccountsError(null);
    }
  }, [isBankAccountTabWithoutAccounts]);

  React.useEffect(() => {
    onSelectProvider(defaultProvider);
  }, [defaultProvider]);

  return (
    <div className={cn(classes.form, className)}>
      <div>
        {title && (
          <div className={classes.title}>
            {title}
          </div>
        )}
        <CurrenciesSelectField
          className={classes.select}
          label="From"
          currencies={currencies}
          defaultTicker={tickerUpperCased}
          disabled
        />
        <Field
          className={classes.field}
          title="Amount to withdraw"
          data={{
            value: withdrawalAmount,
            onChange: handleAmountChange,
            ticker: currentTickerUpperCased,
            placeholder: '0.00',
            error: withdrawalAmountError,
            pattern: /^\d*[.]?\d{0,8}$/,
            autoComplete: 'off',
          }}
        />
        <Switcher
          active={isBankAccountTabActive}
          onChange={handleSwitcherChange}
          className={classes.formSwitcher}
          activeClassName={classes.formSwitcherActive}
        >
          <div className={classes.formSwitcherTabs}>
            <p className={classes.formSwitcherTab}>To wallet</p>
            <p className={classes.formSwitcherTab}>To bank account</p>
          </div>
        </Switcher>
        {isWalletTabWithoutAddress && (
          <AddWallet
            currency={currency}
            onClick={onWalletSubmit}
            errorMessage={walletError}
            fetching={walletFetching}
          />
        )}
        {isWalletTabWithAddress && (
          <Field
            className={classes.field}
            title="Payout address"
            data={{
              type: 'text',
              value: address,
              readOnly: true,
              Icon: address && (
                <img
                  src="/images/copy-success-icon.svg"
                  alt="Icon"
                  decoding="async"
                />
              ),
            }}
          />
        )}
        {isBankAccountTabWithoutProviders && (
          <ActivateProviders
            providers={availableProviders}
            partnerEmail={partnerEmail}
          />
        )}
        {isBankAccountTabWithProviders && (
          <CustomSelectField
            className={cn([
              classes.field,
              classes.selectField,
            ])}
            label="Provider"
            placeholderText="Choose provider"
            items={activatedProviders}
            error={providerError}
            onSelect={onSelectProvider}
            defaultValue={selectedProvider}
            disabled={Boolean(defaultProvider)}
          />
        )}
        {isBankAccountTabWithoutAccounts && (
          <div
            className={cn([
              addAccountsError && classes.addAccountError,
              classes.addAccount,
            ])}
            onClick={onAddAccountClick}
          >
            <h3 className={classes.addAccountTitle}>Add bank account</h3>
            <p className={classes.addAccountDescription}>
              To make a withdrawal in fiat, please enter your bank account details.
            </p>
          </div>
        )}
        {isBankAccountTabAllActivated && (
          <CustomSelectField
            className={cn([
              classes.field,
              classes.selectField,
            ])}
            label="Bank account"
            placeholderText="Choose bank account"
            items={accounts}
            error={bankAccountError}
            onSelect={handleAccountSelect}
            defaultValue={selectedAccount}
            disabled={Boolean(defaultAccount)}
            isLoading={isAccountsLoading}
          />
        )}
      </div>
      {isFormSubmitShow && (
        <div>
          <div className={classes.details}>
            <div className={classes.detailsItem}>
              <div className={classes.detailsItemTitle}>
                Current balance
              </div>
              <div className={classes.detailsItemText}>
                {currentBalanceToFixed}
                {' '}
                {currentTickerUpperCased}
              </div>
            </div>
            <div className={classes.detailsItem}>
              <div className={classes.detailsItemTitle}>
                Outcome balance
              </div>
              <div className={classes.detailsItemText}>
                {outcomeBalanceToFixed}
                {' '}
                {currentTickerUpperCased}
              </div>
            </div>
          </div>
          <div className={cn([
            classes.estimate,
            isEstimateAvailable && classes.estimateAvailable,
          ])}>
            <div className={classes.estimateTitle}>
                You get&nbsp;
            </div>
            <div className={classes.estimateValue}>
              {isFiatEstimateFetching && (
                <LoaderIcon
                  size={20}
                  path="/images/loader-white-on-blue-icon.gif"
                />
              )}
              {!isFiatEstimateFetching && fiatEstimate && `~${fiatEstimate} ${selectedAccount?.fiatCurrencyCode}`}
            </div>
          </div>
          <div
            className={cn([
              classes.error,
              errorMessage && classes.errorActive,
            ])}
          >
            {errorMessage === IP_ERROR_CODE ? (
              <>
                Your withdrawal request comes from an unknown IP address. Please log in from a whitelisted IP
                or fill out the form on
                {' '}
                <Link
                  className={classes.errorLink}
                  to='/store-settings#addresses'
                >
                  &quot;Payments Settings&quot;
                </Link>
                {' '}
                page using &quot;Add an IP address to Whitelist&quot; button.
              </>
            ) : (
              errorMessage || errorBalanceMessage || addAccountsError
            )}
          </div>
          <Button
            type="button"
            className={classes.button}
            disabled={fetching}
            onClick={handleFormSubmit}
          >
            {fetching
              ? <LoaderIcon path="/images/loader-white-on-blue-icon.gif" />
              : 'Confirm'}
          </Button>
        </div>
      )}
    </div>
  );
};

RequestPayoutForm.defaultProps = {
  className: null,
  title: null,
  currencies: null,
  currency: null,
  onClick: null,
  errorMessage: null,
  onResetPayoutFormData: null,
  fetching: false,
  onAddAccountClick: null,
  providersData: {},
  walletData: {},
};

RequestPayoutForm.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  currencies: PropTypes.arrayOf(PropTypes.object),
  currency: PropTypes.shape({}),
  onClick: PropTypes.func,
  errorMessage: PropTypes.string,
  onResetPayoutFormData: PropTypes.func,
  fetching: PropTypes.bool,
  onAddAccountClick: PropTypes.func,
  providersData: PropTypes.shape({}),
  walletData: PropTypes.shape({}),
};
