import { isObject } from './is-object';

export const pickByKeys = (objectArg, keysArg, defaultValue) => {
  const object = objectArg && isObject(objectArg) ? objectArg : {};
  const keys = keysArg && Array.isArray(keysArg) ? keysArg : [];

  return keys.reduce((picked, key) => {
    if (object[key] !== undefined) {
      picked[key] = object[key];
    } else if (defaultValue !== undefined) {
      picked[key] = defaultValue;
    }
    
    return picked;
  }, {});
};
