import React from 'react';
import cn from 'classnames';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import { ApiKeysTableRowMemo } from '../api-keys-table-row';
import ToolTip from '../../../tool-tip';
import { ApiKeysOptions } from '../api-keys-options';
import { LoaderIcon } from '../../../icons/loader-icon';
import { EmptySearch } from '../../../empty-search';
import { Button } from '../../../ui/button';
import { NewPagination } from '../../../shared/new-pagination';
import { ELEMENTS_PER_PAGE } from '../../helpers';
import { ApiKey } from '../../../../default-types';
import { PopupData } from '../../types';
import classes from './styles.module.scss';

type ApiKeysTableProps = {
  keys: Array<ApiKey>;
  keysLength: number;
  onOptionsReset: () => void;
  onPopupOpen: (type: PopupData) => void;
  onAddWalletClick: () => void;
  selectedApiKey: ApiKey | null,
  onOptionsClick: (apiKey: ApiKey) => void,
  page: number,
  onPage: (value: number) => void,
  addWalletAndApiKeyShow: boolean,
  isFetched: boolean,
  isFetching: boolean,
  error: string | null,
};

export const ApiKeysTable: React.FC<ApiKeysTableProps> = (props) => {
  const {
    keys,
    keysLength,
    onOptionsReset,
    onPopupOpen,
    onAddWalletClick,
    onOptionsClick,
    selectedApiKey,
    page,
    onPage,
    addWalletAndApiKeyShow,
    isFetched,
    isFetching,
    error,
  } = props;

  const isDesktop = useMediaQuery('(min-width: 992px)');
  const loaderShown = !isFetched && isFetching;
  const errorShown = !isFetching && error;
  const dataShown = !loaderShown && !errorShown;
  const emptyBoxShown = isFetched && !errorShown && addWalletAndApiKeyShow;
  const isPaginationShow = keysLength > ELEMENTS_PER_PAGE;

  return (
    <>
      <div className={classes.apiKeysTable}>
        <div className={classes.apiKeysTableHeader}>
          <div className={classes.apiKeysTableTd}>API key</div>
          <div className={classes.apiKeysTableTd}>Public key</div>
          {isDesktop && (
            <div className={classes.apiKeysTableTd}>Created at </div>
          )}
          <div className={classes.apiKeysTableTd}>Actions</div>
        </div>
        <div className={cn([
          classes.apiKeysTableBody,
          emptyBoxShown && classes.apiKeysTableBodyEmpty,
        ])}
        >
          {loaderShown && (
            <div className={classes.apiKeysTableBodyLoader}>
              <LoaderIcon size={25} />
            </div>
          )}
          {errorShown && (
            <div className={classes.apiKeysTableBodyError}>
              {error}
            </div>
          )}
          {emptyBoxShown && (
            <EmptySearch imagePath="/images/api-keys-icon.svg">
              <p className={classes.apiKeysTableBodyAddText}>
                No API keys added yet. To add an API key, you need to add at least one wallet.
              </p>
              <Button
                className={classes.apiKeysTableBodyAdd}
                onClick={onAddWalletClick}
              >
                Add wallet
              </Button>
            </EmptySearch>
          )}
          {dataShown && keys.map((key) => (
            <ApiKeysTableRowMemo
              key={key.id}
              selectedApiKey={selectedApiKey}
              apiKeyData={key}
              tdClassname={classes.apiKeysTableTd}
              onClick={onOptionsClick}
            />
          ))}
        </div>
        <ToolTip
          className={classes.apiKeysTableTitleTooltip}
          id="api-key-title-tooltip"
          place="top-start"
          offset={4}
          render={({ content }) => (
            <div>
              {content}
            </div>
          )}
        />
        <ToolTip
          className={classes.apiKeysTableOptionsTooltip}
          id="api-key-options-tooltip"
          place="bottom-end"
          offset={4}
          openOnClick
          clickable
          render={() => (
            <ApiKeysOptions
              onClick={onPopupOpen}
            />
          )}
          afterHide={onOptionsReset}
        />
      </div>
      {isPaginationShow && (
        <NewPagination
          className={classes.apiKeysTablePagination}
          currentPage={page}
          countItems={keysLength}
          itemsPerPage={ELEMENTS_PER_PAGE}
          onChangePage={onPage}
        />
      )}
    </>
  );
};
