import React from 'react';
import SubmitButton, { buttonState } from '../../../../../components/submit-button';
import Input from '../../../../../components/input';
import TextArea from '../../../../../components/ui/textarea';
import OauthPermissions from '../../../../../components/oauth-permissions';
import apiClient from '../../../../../api/api-client';
import { formValidation } from './utils/validation-fields';
import styles from './styles.module.scss';

let timer = 0;

const ModalAdd = ({ isShow, onShow, setServices, permissions }) => {
  const [buttonLogoState, setButtonLogoState] = React.useState(
    buttonState.normal,
  );
  const divRef = React.useRef(null);
  const [fields, setFields] = React.useState({
    name: '',
    desc: '',
    link: '',
    secret: '',
  });
  const [appPermissions, setAppPermissions] = React.useState({});
  const [errorsFields, setErrorsFields] = React.useState({});

  const handleInputChange = (e) => {
    e.persist();
    setFields((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSwitchChange = (table, perm) => {
    const isPermission = appPermissions?.[table]?.[perm];

    setAppPermissions((prevState) => ({
      ...prevState,
      [table]: {
        ...prevState[table],
        [perm]: isPermission ? !isPermission : true,
      },
    }));
  };

  const handleAddClick = async () => {
    const { isValid, errors } = formValidation(
      fields.name,
      fields.desc,
      fields.secret,
      appPermissions,
      fields.link,
    );

    if (!isValid) {
      setErrorsFields(errors);

      return;
    }

    const newService = await apiClient.createService({
      name: fields.name,
      description: fields.desc,
      secret_key: fields.secret,
      permissions: appPermissions,
      redirect_url: fields.link,
    });

    setServices((prevState) =>
      prevState.concat({
        id: newService.id,
        name: fields.name,
        description: fields.desc,
        secret_key: fields.secret,
        permissions: appPermissions,
        redirect_url: newService.redirect_url,
      }),
    );
    onShow(false);
  };

  const handleCancelClick = () => {
    onShow(false);
  };

  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      onShow(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  React.useEffect(() => {
    if (
      buttonLogoState === buttonState.success ||
      buttonLogoState === buttonState.failure
    ) {
      timer = setTimeout(() => {
        setButtonLogoState(buttonState.normal);
      }, 3000);
    }

    return () => clearTimeout(timer);
  }, [buttonLogoState]);

  if (!isShow) {
    return null;
  }

  return (
    <div className={styles.externalWrapper}>
      <div className={styles.wrapper} ref={divRef}>
        <h3 className={styles.title}>Create App</h3>
        <div className={styles.middle}>
          <div className={styles.leftBlock}>
            <div className={styles.infoBlock}>
              <h4>Name</h4>
              <Input
                placeholder="App Name"
                value={fields.name}
                onChange={handleInputChange}
                name="name"
              />
              {errorsFields.name && (
                <span className={styles.errorSpan}>{errorsFields.name}</span>
              )}
              <h4>Description</h4>
              <TextArea
                placeholder="App Description"
                value={fields.desc}
                onChange={handleInputChange}
                name="desc"
              />
              {errorsFields.description && (
                <span className={styles.errorSpan}>
                  {errorsFields.description}
                </span>
              )}
              <h4>App Site</h4>
              <Input
                placeholder="App Site"
                value={fields.link}
                onChange={handleInputChange}
                name="link"
              />
              {errorsFields.link && (
                <span className={styles.errorSpan}>{errorsFields.link}</span>
              )}
              <h4>Secret key</h4>
              <Input
                placeholder="Some string"
                value={fields.secret}
                onChange={handleInputChange}
                name="secret"
              />
              {errorsFields.secret && (
                <span className={styles.errorSpan}>{errorsFields.secret}</span>
              )}
            </div>
          </div>
          <div className={styles.rightBlock}>
            <OauthPermissions
              permissions={permissions}
              onChangeSwitch={handleSwitchChange}
              appPermissions={appPermissions}
            />
            {errorsFields.permissions && (
              <span className={styles.errorSpan}>
                {errorsFields.permissions}
              </span>
            )}
          </div>
        </div>

        <div className={styles.endButtons}>
          <SubmitButton
            className={styles.btnCancel}
            buttonState="normal"
            onSubmit={handleCancelClick}
          >
            Cancel
          </SubmitButton>
          <SubmitButton
            className={styles.btnAdd}
            buttonState="normal"
            onSubmit={handleAddClick}
          >
            Create
          </SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default ModalAdd;
