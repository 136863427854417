import React from "react";
import SubmitButton from "../../../components/submit-button";
import CommonBlock from "../../../components/common-block";
import styles from "./styles.module.scss";

function PasswordSettings(props) {
  const {
    onCurrentPasswordChange,
    onNewPasswordChange,
    onConfirmPasswordChange,
    submitButtonState,
    submitButtonFailureMessage,
    onSubmit,
  } = props;

  const prepareValue = (callBack) => {
    return (event) => {
      const value = event.target.value.trim();

      callBack(value);
    };
  };

  return (
    <CommonBlock header="Password" className={styles.password}>
      <p>
        You can change your password at any time. After that you will have to
        sign in
      </p>

      <div className={styles.fieldContainer}>
        <input
          type="password"
          placeholder="Current password"
          onChange={prepareValue(onCurrentPasswordChange)}
        />
        <input
          type="password"
          placeholder="New password"
          onChange={prepareValue(onNewPasswordChange)}
        />
        <input
          type="password"
          placeholder="Confirm new password"
          onChange={prepareValue(onConfirmPasswordChange)}
        />
      </div>

      <SubmitButton
        successMessage="Your password has now been updated, and you are brought back to the sign in page"
        failureMessage={submitButtonFailureMessage}
        buttonState={submitButtonState}
        onSubmit={onSubmit}
      >
        Change password
      </SubmitButton>
    </CommonBlock>
  );
}

export default PasswordSettings;
