import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { hasElementsInArray } from '../../helpers/has-elements-in-array';
import useOutsideClick from '../../hooks/use-outside-click';
import { isFunction } from '../../helpers/is-function';
import { safeToLowerCase } from '../../helpers/safe-to-lower-case';
import { getUrlWithDomain } from '../../helpers/get-url-with-domain';
import { CustomSearchField } from '../ui/custom-search-field';
import { CustomInformationLink } from '../shared/custom-information-link';
import { CurrencySelected } from './components/currency-selected';
import { CurrenciesDropdown } from './components/currencies-dropdown';
import { CurrenciesItem } from './components/currencies-list';
import {
  getFilteredCurrencies,
  getFilteredOtherCurrencies,
  getGroupList,
  getCurrenciesByKey,
  getCurrencies,
  getFilteredCurrenciesByTab,
  INNER_MENU,
} from './currencies-select-helpers';
import classes from './styles.module.scss';

export const CurrenciesSelect = (props) => {
  const {
    className,
    currencies,
    placeholder,
    placeholderText,
    onSelect,
    defaultSelectTicker,
    reset,
    error,
    disabled,
    smallTheme,
    themeWithAmount,
    hasInnerMenu,
    isOnRampEnabled,
    withoutOtherCurrencies,
  } = props;

  const componentRef = React.useRef(null);
  const [searchValue, setSearchValue] = React.useState('');
  const [currencySelected, setCurrencySelected] = React.useState(null);
  const [isShow, setIsShow] = React.useState(false);
  const [isDropDownAnimated, setIsDropDownAnimated] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(INNER_MENU.ALL);

  const handleSearchChange = (value) => {
    setSearchValue(value);
  };
  const handleCurrencySelect = (currency, withoutOnSelect) => () => {
    setCurrencySelected(currency);
    setIsDropDownAnimated(false);
    setIsShow(false);

    if (isFunction(onSelect) && !withoutOnSelect) {
      onSelect(currency);
    }
  };
  const handleDropDownOpen = () => {
    setIsShow(true);
  };
  const handleDropDownClose = () => {
    setIsDropDownAnimated(false);
    setIsShow(false);
  };
  const handleActiveTab = (tab) => {
    setActiveTab(tab);
  };

  const currenciesFilteredByTab = React.useMemo(() => (
    getFilteredCurrenciesByTab(currencies, activeTab)
  ), [currencies, activeTab]);
  const popularCurrencies = React.useMemo(() => (
    getCurrenciesByKey(currenciesFilteredByTab, 'isPopular')
  ), [currenciesFilteredByTab]);
  const popularCurrenciesFiltered = React.useMemo(() => (
    getFilteredCurrencies(popularCurrencies, searchValue)
  ), [popularCurrencies, searchValue]);
  const fiatCurrencies = React.useMemo(() => (
    getCurrenciesByKey(currenciesFilteredByTab, 'isFiat')
  ), [currenciesFilteredByTab]);
  const fiatCurrenciesFiltered = React.useMemo(() => (
    getFilteredCurrencies(fiatCurrencies, searchValue)
  ), [fiatCurrencies, searchValue]);
  const stableCurrencies = React.useMemo(() => (
    getCurrenciesByKey(currenciesFilteredByTab, 'isStable')
  ), [currenciesFilteredByTab]);
  const stableCurrenciesFiltered = React.useMemo(() => (
    getFilteredCurrencies(stableCurrencies, searchValue)
  ), [stableCurrencies, searchValue]);
  const otherCurrencies = React.useMemo(() => (
    getCurrencies(currenciesFilteredByTab)
  ), [currenciesFilteredByTab]);
  const otherCurrenciesFiltered = React.useMemo(() => (
    getFilteredOtherCurrencies(otherCurrencies, searchValue)
  ), [otherCurrencies, searchValue]);
  const groupCurrencies = React.useMemo(() => (
    getGroupList(
      popularCurrenciesFiltered,
      fiatCurrenciesFiltered,
      stableCurrenciesFiltered,
      activeTab !== INNER_MENU.FIAT && !withoutOtherCurrencies && otherCurrenciesFiltered,
    )
  ), [
    popularCurrenciesFiltered,
    fiatCurrenciesFiltered,
    stableCurrenciesFiltered,
    otherCurrenciesFiltered,
  ]);

  React.useEffect(() => {
    const hasCurrencies = hasElementsInArray(currencies);

    if (defaultSelectTicker && hasCurrencies) {
      const defaultCoinFound = currencies.find((item) =>
        safeToLowerCase(item?.code) === safeToLowerCase(defaultSelectTicker));
      const currentDefaultCoinFound = defaultCoinFound || null;

      setCurrencySelected(currentDefaultCoinFound);

      if (isFunction(onSelect)) {
        onSelect(currentDefaultCoinFound);
      }
    }
  }, [defaultSelectTicker, currencies]);

  React.useEffect(() => {
    let nextTimerId = null;

    if (isShow) {
      nextTimerId = setTimeout(() => setIsDropDownAnimated(true), 0);
    }

    return () => {
      if (nextTimerId) {
        clearTimeout(nextTimerId);
      }
    };
  }, [isShow]);

  React.useEffect(() => {
    if (reset && currencySelected) {
      setCurrencySelected(null);

      if (isFunction(onSelect)) {
        onSelect(null);
      }
    }
  }, [reset]);

  useOutsideClick(componentRef, handleDropDownClose);

  const hasGroupCurrencies = hasElementsInArray(groupCurrencies);
  const currentPlaceholder = placeholder || 'Select a currency';
  const currencySelectedPlaceholder = !currencySelected ? currentPlaceholder : null;
  const hasInnerMenuWithInactiveOnramp = hasInnerMenu && !isOnRampEnabled;
  const isFiatTabWithInactiveOnramp = hasInnerMenuWithInactiveOnramp && activeTab === INNER_MENU.FIAT;

  return (
    <div
      className={cn([
        classes.currenciesSelect,
        themeWithAmount && classes.currenciesSelectWithAmountField,
        smallTheme && classes.currenciesSelectSmallTheme,
        className,
      ])}
      ref={componentRef}
    >
      <CurrencySelected
        ticker={currencySelected?.code}
        currentTicker={currencySelected?.ticker}
        name={currencySelected?.name}
        network={currencySelected?.network}
        imagePath={getUrlWithDomain(currencySelected?.logo_url || currencySelected?.logoUrl)}
        placeholder={currencySelectedPlaceholder}
        placeholderText={placeholderText}
        onClick={handleDropDownOpen}
        error={error}
        disabled={disabled}
        smallTheme={smallTheme}
        themeWithAmount={themeWithAmount}
      />
      {isShow && (
        <CurrenciesDropdown
          className={cn([
            classes.currenciesSelectBody,
            isDropDownAnimated && classes.currenciesSelectBodyAnimated,
          ])}
          Search={(
            <CustomSearchField
              value={searchValue}
              onChange={handleSearchChange}
              placeholder="Type a currency"
            />
          )}
          hasInnerMenu={hasInnerMenu}
          onClick={handleActiveTab}
          activeTab={activeTab}
          isOnRampEnabled={isOnRampEnabled}
        >
          {isFiatTabWithInactiveOnramp && (
            <CustomInformationLink
              className={classes.currenciesSelectLink}
              href="/fiat-operations-settings"
              isSpaLink
            >
              <p>
                To enable fiat currencies for payments make a request through the
                {' '}
                <span>fiat operations settings</span>.
              </p>
            </CustomInformationLink>
          )}
          {!hasGroupCurrencies && (
            <div className={classes.currenciesSelectNotFound}>
              No matches were found for your query
            </div>
          )}
          {hasGroupCurrencies && (
            <ul className={cn([
              classes.currenciesSelectList,
              hasInnerMenu && classes.currenciesSelectListShort,
              isFiatTabWithInactiveOnramp && classes.currenciesSelectListShortFiat,
            ])}>
              {groupCurrencies.map((item) => (
                <React.Fragment key={item?.groupName}>
                  {activeTab !== INNER_MENU.FIAT && item?.groupName && (
                    <li className={classes.currenciesSelectItemTitle}>
                      {item.groupName}
                    </li>
                  )}
                  {item?.items && item.items.map((currency) => (
                    <CurrenciesItem
                      key={currency?.key}
                      className={cn([
                        classes.currenciesSelectItem,
                        currencySelected?.id === currency?.id && classes.currenciesSelectItemActive,
                        hasInnerMenuWithInactiveOnramp && currency?.isFiat && classes.currenciesSelectItemDisabled,
                      ])}
                      ticker={currency?.code}
                      currentTicker={currency?.ticker}
                      name={currency?.name}
                      imagePath={getUrlWithDomain(currency?.logo_url || currency?.logoUrl)}
                      network={currency?.network}
                      onClick={!(hasInnerMenuWithInactiveOnramp && currency?.isFiat)
                        && handleCurrencySelect(currency, currencySelected?.id === currency?.id)}
                    />
                  ))}
                </React.Fragment>
              ))}
            </ul>
          )}
        </CurrenciesDropdown>
      )}
    </div>
  );
};

CurrenciesSelect.defaultProps = {
  className: null,
  currencies: [],
  placeholder: null,
  placeholderText: null,
  onSelect: null,
  defaultSelectTicker: null,
  reset: false,
  error: false,
  disabled: false,
  smallTheme: false,
  themeWithAmount: false,
  hasInnerMenu: false,
  isOnRampEnabled: false,
  withoutOtherCurrencies: false,
};

CurrenciesSelect.propTypes = {
  className: PropTypes.string,
  currencies: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  placeholderText: PropTypes.string,
  onSelect: PropTypes.func,
  defaultSelectTicker: PropTypes.string,
  reset: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  smallTheme: PropTypes.bool,
  themeWithAmount: PropTypes.bool,
  hasInnerMenu: PropTypes.bool,
  isOnRampEnabled: PropTypes.bool,
  withoutOtherCurrencies: PropTypes.bool,
};
