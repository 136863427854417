import * as React from 'react';
import cn from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { hasElementsInArray } from '../../../helpers/has-elements-in-array';
import { InnerNavigationProps } from './types';
import classes from './styles.module.scss';

export const InnerNavigation = React.memo((props: InnerNavigationProps) => {
  const {
    className,
    items,
  } = props;

  const location = useLocation();
  const hasItems = hasElementsInArray(items);

  if (!hasItems) {
    return null;
  }

  return (
    <div className={cn(classes.innerNavigation, className)}>
      {items.map((item) => (
        <Link
          key={item?.id}
          to={item?.href}
          className={cn([
            classes.innerNavigationItem,
            item?.href === location.pathname && classes.innerNavigationItemActive,
          ])}
        >
          {item?.name}
        </Link>
      ))}
    </div>
  );
});

InnerNavigation.displayName = 'InnerNavigation';
