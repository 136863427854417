import * as React from 'react';
import PropTypes from 'prop-types';
import { InputSearch } from '../../../ui/input-search';
import { LoaderIcon } from '../../../icons/loader-icon';
import { CheckboxWithText } from '../../../ui/checkbox-with-text';
import { Button } from '../../../ui/button';
import { hasElementsInArray } from '../../../../helpers/has-elements-in-array';
import { debounce } from '../../../../helpers/utils';
import { isFunction } from '../../../../helpers/is-function';
import classes from './styles.module.scss';

export const ManageCustomersForm = (props) => {
  const {
    className,
    onSubmit,
    apiErrorMessage,
    fetching,
    subPartners,
    subPartnersFetching,
    subPartnersFetched,
    onSubPartners,
  } = props;

  const [searchText, setSearchText] = React.useState('');
  const [ids, setIds] = React.useState([]);

  const subPartnerItems = React.useMemo(() => {
    if (!hasElementsInArray(subPartners)) {
      return [];
    }

    return subPartners.map((item) => ({
      id: item?.id ?? null,
      name: item?.name ?? null,
      checked: ids.includes(item?.id),
    }));
  }, [subPartners, ids]);
  const subPartnerItemsFiltered = React.useMemo(() => {
    const regExp = new RegExp(searchText, 'i');

    return subPartnerItems.filter((item) => (
      regExp.test(item?.id) || regExp.test(item?.name)
    ));
  }, [subPartnerItems, searchText]);

  const handleSearchText = React.useCallback(debounce((value) => {
    setSearchText(value);
  }, 300), []);
  const handleSubPartnerChecked = (id) => () => {
    setIds((prevState) => (
      prevState.includes(id)
        ? prevState.filter((item) => item !== id)
        : [...prevState, id]
    ));
  };
  const handleSubmit = () => {
    if (isFunction(onSubmit)) {
      onSubmit(ids);
    }
  };

  React.useEffect(() => {
    if (!subPartnersFetched && isFunction(onSubPartners)) {
      void onSubPartners();
    }
  },[subPartnersFetched, onSubPartners]);

  return (
    <div className={className}>
      <InputSearch
        className={classes.searchInput}
        placeholder="Search Customer"
        onChange={handleSearchText}
      />
      <div className={classes.subPartners}>
        {subPartnersFetching && (
          <div className={classes.subPartnersLoader}>
            <LoaderIcon />
          </div>
        )}
        {!subPartnersFetching && subPartnerItemsFiltered.map((item) => (
          <CheckboxWithText
            className={classes.subPartnersItem}
            key={item.id}
            id={item.id}
            onChange={handleSubPartnerChecked(item.id)}
            checked={item.checked}
          >
            <label htmlFor={item.id} className={classes.subPartnersItemLabel}>
              <span className={classes.subPartnersItemId}>
                {item.id}
              </span>
              {' '}
              <span className={classes.subPartnersItemName}>
                {item.name}
              </span>
            </label>
          </CheckboxWithText>
        ))}
      </div>
      <div className={classes.error}>
        {apiErrorMessage}
      </div>
      <Button
        className={classes.button}
        type="button"
        onClick={handleSubmit}
        disabled={fetching}
      >
        {fetching && (
          <LoaderIcon path="/images/loader-white-on-blue-icon.gif" />
        )}
        {!fetching && 'Save'}
      </Button>
    </div>
  );
};

ManageCustomersForm.defaultProps = {
  className: null,
  onSubmit: null,
  apiErrorMessage: null,
  fetching: false,
  subPartners: null,
  subPartnersFetching: false,
  subPartnersFetched: false,
  onSubPartners: null,
};

ManageCustomersForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  apiErrorMessage: PropTypes.string,
  fetching: PropTypes.bool,
  subPartners: PropTypes.arrayOf(PropTypes.object),
  subPartnersFetching: PropTypes.bool,
  subPartnersFetched: PropTypes.bool,
  onSubPartners: PropTypes.func,
};
