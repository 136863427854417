import React from 'react';
import cn from 'classnames';
import { CustomSearchField } from '../../../custom-search-field';
import { hasElementsInArray } from '../../../../../helpers/has-elements-in-array';
import { isFunction } from '../../../../../helpers/is-function';
import { safeToLowerCase } from '../../../../../helpers/safe-to-lower-case';
import useOutsideClick from '../../../../../hooks/use-outside-click';
import { LoaderIcon } from '../../../../icons/loader-icon';
import { SelectedItem } from '../selected-item';
import { ListItem } from '../list-item';
import { CustomSelectProps } from '../../types';
import classes from './styles.module.scss';

export const CustomSelect: React.FC<CustomSelectProps> = (props) => {
  const {
    items,
    placeholderText,
    onSelect,
    error,
    reset,
    noMatchesText,
    defaultValue,
    isLoading,
    searchPlaceholder,
    disabled,
    hasSearch,
    isSmallTheme,
  } = props;

  const componentRef = React.useRef(null);
  const [searchValue, setSearchValue] = React.useState('');
  const [selectedItem, setSelectedItem] = React.useState<any>(defaultValue);
  const [isShow, setIsShow] = React.useState(false);

  const handleDropDownOpen = () => {
    setIsShow(true);
  };
  const handleDropDownClose = () => {
    setIsShow(false);
  };
  const handleSearchChange = (value: string) => {
    setSearchValue(value);
  };
  const handleItemSelect = (item: any) => () => {
    setSelectedItem(item);
    setIsShow(false);
    if (onSelect && isFunction(onSelect)) {
      onSelect(item);
    }
  };

  React.useEffect(() => {
    if (reset) {
      setSelectedItem(null);
      if (onSelect && isFunction(onSelect)) {
        onSelect(null);
      }
    }
  }, [onSelect, reset]);

  React.useEffect(() => {
    if (!selectedItem && defaultValue) {
      setSelectedItem(defaultValue);
    }
  }, [selectedItem, defaultValue]);

  const itemsFiltered = React.useMemo(() => (
    items?.filter((item) => safeToLowerCase(item.name)
      ?.includes(safeToLowerCase(searchValue))) ?? []
  ), [items, searchValue]);

  const hasItems = hasElementsInArray(itemsFiltered);
  useOutsideClick(componentRef, handleDropDownClose);

  return (
    <div
      className={cn([
        classes.select,
        isSmallTheme && classes.selectSmall,
      ])}
      ref={componentRef}
    >
      <SelectedItem
        item={selectedItem}
        placeholderText={placeholderText}
        onClick={handleDropDownOpen}
        error={error}
        disabled={disabled}
      />
      {isShow && (
        <div className={classes.selectBody}>
          {hasSearch ? (
            <>
              <CustomSearchField
                value={searchValue}
                onChange={handleSearchChange}
                placeholder={searchPlaceholder || 'Type a currency'}
              />
              {!hasItems && (
                <div className={classes.selectNotFound}>
                  {noMatchesText || 'No matches were found for your query'}
                </div>
              )}
            </>
          ) : (
            <div className={classes.selectPlaceholderText}>
              {placeholderText}
            </div>
          )}
          {hasItems && isLoading && (
            <LoaderIcon
              className={classes.selectLoading}
            />
          )}
          {hasItems && !isLoading && (
            <ul className={classes.selectList}>
              {itemsFiltered.map((item) => (
                <ListItem
                  key={item?.id}
                  className={cn([
                    classes.selectItem,
                    selectedItem?.id === item?.id && classes.selectItemActive,
                  ])}
                  logoUrl={item?.logoUrl}
                  name={item?.name}
                  onClick={handleItemSelect(item)}
                  isSmallTheme={isSmallTheme}
                />
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};
