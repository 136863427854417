import * as React from 'react';
import { Link } from 'react-router-dom';
import { SETTINGS } from '../../constants';
import classes from './styles.module.scss';

export const DashboardSettings = () => (
  <div className={classes.dashboardSettings}>
    <ul className={classes.dashboardSettingsList}>
      {SETTINGS.map((item) => (
        <li
          className={classes.dashboardSettingsListItem}
          key={item.id}
        >
          {item.href ? (
            <Link
              to={item.href}
            >
              {item.logoUrl && (
                <img
                  src={item.logoUrl}
                  alt={item.name}
                  width={26}
                  height={26}
                  decoding="async"
                  loading="lazy"
                />
              )}
              {item.name}
            </Link>
          ) : (
            <>
              {item.logoUrl && (
                <img
                  src={item.logoUrl}
                  alt={item.name}
                  width={26}
                  height={26}
                  decoding="async"
                  loading="lazy"
                />
              )}
              {item.name}
            </>
          )}
        </li>
      ))}
    </ul>
  </div>
);
