import React from 'react';
import { useSelector } from 'react-redux';
import { isPartnerCustodySelector, partnerFetchedSelector } from '../../../store/partner/selectors';
import { useSubscriptionsPlans } from '../../../api/modules/account/use-subscriptions-plans';
import { BILLING_PLANS_PAGE_PER_PAGE } from '../helpers';
import { getSafeErrorMessageText } from '../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../helpers/console-error-message';
import { isObject } from '../../../helpers/is-object';
import { checkErrorCode } from '../../../helpers/check-error-code';
import { debounce } from '../../../helpers/utils';

const DEFAULT_DATA = {
  result: [],
  count: null,
};

export const useBillingPlansPageInitState = () => {
  const isPartnerCustody = useSelector(isPartnerCustodySelector());
  const partnerFetched = useSelector(partnerFetchedSelector());
  const fetchSubscriptionsPlans = useSubscriptionsPlans();

  const [isFetching, setIsFetching] = React.useState(false);
  const [plansData, setPlansData] = React.useState(DEFAULT_DATA);
  const [apiErrorMessage, setApiErrorMessage] = React.useState(null);
  const [searchText, setSearchText] = React.useState('');
  const [page, setPage] = React.useState(1);

  const reFetchPlans = async (page = 1, filter = null) => {
    setApiErrorMessage(null);
    setIsFetching(true);
    const { data, status, errorMessage } = await fetchSubscriptionsPlans({
      limit: BILLING_PLANS_PAGE_PER_PAGE,
      page,
      filter,
    });
    if (status === 200 && isObject(data)) {
      setPlansData(data);
      setIsFetching(false);
    } else {
      checkErrorCode(data?.errorData?.code);
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/subscriptions/plans');
      setApiErrorMessage(errorDataMessage);
      setIsFetching(false);
    }
  };
  const handleSearchChange = React.useCallback(debounce((value) => {
    setSearchText(value);
    setPage(1);
  }, 400), [setSearchText, setPage]);

  React.useEffect(() => {
    if (!partnerFetched || !isPartnerCustody) {
      return;
    }
    void reFetchPlans(page, searchText);
  }, [partnerFetched, isPartnerCustody, page, searchText]);

  return {
    isFetching,
    plansData: plansData.result,
    plansDataCount: plansData.count,
    apiErrorMessage,
    reFetchPlans,
    searchText,
    handleSearchChange,
    page,
    setPage,
  };
};
