import * as React from 'react';
import { OnboardingFormStepIconPropsType } from '../../../../types';

export const CustodyStep: React.FC<OnboardingFormStepIconPropsType> = (props) => {
  const { isActive } = props;

  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13087_53925)">
        <circle
          cx="16.667"
          cy="16"
          r="15.5"
          fill="white"
          stroke={isActive ? '#64ACFF' : '#D2D2D2'}
        />
        <path
          d="M24.2496 8.57475L17.7353 6.18897C17.0459 5.93701 16.287 5.93701 15.5976 6.18897L9.08328 8.57475C7.93676 8.99467 7.16699 10.0647 7.16699 11.2363V14.1768C7.16699 19.33 10.5609 24.0072 15.6182 25.8186C15.9558 25.9399 16.3109 26 16.6659 26C17.0209 26 17.3792 25.9388 17.7201 25.8165C22.773 24.0072 26.167 19.3289 26.167 14.1758V11.2353C26.167 10.0626 25.3972 8.9926 24.2507 8.57371L24.2496 8.57475ZM24.5373 14.1779C24.5373 18.6861 21.5679 22.7785 17.1414 24.3639C16.8353 24.4738 16.4976 24.4748 16.1839 24.3628C11.7639 22.7796 8.79339 18.6861 8.79339 14.1789V11.2384C8.79339 10.7055 9.14299 10.2192 9.66414 10.0284L16.1784 7.64263C16.3358 7.5856 16.4998 7.55657 16.6648 7.55657C16.8298 7.55657 16.9949 7.5856 17.1512 7.64263L23.6655 10.0284C24.1867 10.2192 24.5363 10.7055 24.5363 11.2384V14.1789L24.5373 14.1779ZM20.4681 13.31C20.8025 13.5972 20.8296 14.0887 20.5289 14.4091L16.6203 18.5565C16.4651 18.7203 16.2457 18.8136 16.0145 18.8136C15.7832 18.8136 15.5639 18.7203 15.4086 18.5565L12.8029 15.7912C12.5022 15.4719 12.5293 14.9804 12.8637 14.6921C13.1992 14.4049 13.7128 14.4309 14.0146 14.7502L16.0145 16.8726L19.3172 13.3671C19.6191 13.0477 20.1326 13.0228 20.4681 13.309V13.31Z"
          fill={isActive ? '#64ACFF' : '#D2D2D2'}
        />
      </g>
      <defs>
        <clipPath id="clip0_13087_53925">
          <rect width="32" height="32" fill="white" transform="translate(0.666992)" />
        </clipPath>
      </defs>
    </svg>
  );
};
