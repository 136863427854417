import React from 'react';
import withModal from '../../../../../components/with-modal';
import apiClient from '../../../../../api/api-client';
import SubmitButton, { buttonState } from '../../../../../components/submit-button';
import styles from './styles.module.scss';
const Modal = React.lazy(() => import('../../../../../components/alert-modal'));
let timer = 0;

const NewUserModal = ({ isOpen, onClose, accessData, setTeam }) => {
  const [error, setError] = React.useState('');
  const [buttonStateInvite, setButtonStateInvite] = React.useState(
    buttonState.normal,
  );
  const [fields, setFields] = React.useState({
    email: '',
    role: '',
  });
  const [isOpenModal, setIsOpenModal] = React.useState(false);

  const [access, setAccess] = React.useState({});

  const handleSave = async () => {
    const data = {
      access: {
        ...access,
      },
      fields: {
        ...fields,
      },
    };

    try {
      setButtonStateInvite(buttonState.processing);
      const newTeam = await apiClient.inviteTeam(data);

      setTeam((prevState) => [...prevState, newTeam]);
      setIsOpenModal(true);
    } catch (err) {
      setError(err.response.data.error);
      setButtonStateInvite(buttonState.failure);
    }
  };

  const handleModal = () => {
    onClose();
    setIsOpenModal(false);
  };

  const handleChangeAccess = (e, asset) => {
    e.persist();
    setAccess((prevState) => ({
      ...prevState,
      [asset]: e.target.value.toLowerCase(),
    }));
  };

  const handleInputChange = (e, field) => {
    e.persist();
    setFields((prevState) => ({ ...prevState, [field]: e.target.value }));
  };

  React.useEffect(() => {
    if (buttonStateInvite !== buttonState.normal) {
      timer = setTimeout(() => {
        setError('');
        setButtonStateInvite(buttonState.normal);
      }, 2000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [buttonStateInvite]);

  React.useEffect(() => {
    if (Object.keys(accessData).length) {
      const accessObj = {};

      for (const value of Object.values(accessData)) {
        accessObj[value.code] = 'view';
      }
      setAccess(accessObj);
    }
  }, [accessData]);

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <div className={styles.wrapper}>
        <h4>Add new user</h4>
        <span>This user should have a NOWPayments account</span>
        <div className={styles.container}>
          <div className={styles.row}>
            <input
              className={styles.input}
              type="text"
              placeholder="E-mail"
              value={fields.email}
              onChange={(e) => handleInputChange(e, 'email')}
            />
          </div>
          <div className={styles.row}>
            <input
              className={styles.input}
              type="text"
              placeholder="Role"
              value={fields.role}
              onChange={(e) => handleInputChange(e, 'role')}
            />
          </div>
          <table className={styles.table}>
            <thead className={styles.thead}>
              <tr>
                <td className={styles.td}>Assets</td>
                <td className={styles.td}>Access</td>
              </tr>
            </thead>
            <tbody className={styles.tbody}>
              {accessData.map((item, index) => (
                <tr key={index}>
                  <td className={styles.td}>{item.title}</td>
                  <td>
                    <select
                      className={styles.select}
                      type="text"
                      value={access[item.code]}
                      onChange={(e) => handleChangeAccess(e, item.code)}
                    >
                      <option>view</option>
                      <option>change</option>
                      <option>disable</option>
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={styles.rowButtons}>
          <button onClick={onClose}>Cancel</button>
          {error ? (
            <p className={styles.error}>{error}</p>
          ) : (
            <SubmitButton buttonState={buttonStateInvite} onSubmit={handleSave}>
              Invite
            </SubmitButton>
          )}
        </div>
      </div>
      {isOpenModal && (
        <React.Suspense fallback={null}>
          <Modal
            isShow={isOpenModal}
            setShow={setIsOpenModal}
            submitText={'OK'}
            submitHandler={handleModal}
          >
            <p>
              We have sent the invitation to the invitee&apos;s email address
            </p>
            <p>
              The invitee has to follow the link in this email to activate the
              subsidiary account
            </p>
          </Modal>
        </React.Suspense>
      )}
    </>
  );
};

export default withModal(NewUserModal);
