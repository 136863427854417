export const ROUTES = {
  DASHBOARD: '/dashboard',
  PAYMENTS: '/payments',
  INVOICES: '/invoices',
  CUSTODY: '/balances-and-payouts',
  MASS_PAYOUTS: '/mass-payouts',
  STORE_SETTINGS: '/store-settings',
  ACCOUNT_SETTINGS: '/account-settings',
  AFFILIATE_PROGRAM: '/affiliate-program',
  COINS_SETTINGS: '/coins-settings',
  SUBSCRIPTIONS: '/subscriptions',
  SUBSCRIPTION_PLAN: '/subscriptions/:planId',
  SUBSCRIPTION_USER: '/subscriptions/:planId/:userId',
  DONATIONS: '/donations',
  POS_TERMINAL: '/pos-terminal',
  OAUTH: '/oauth',
  EXTERNAL_REDIRECT: '/external-redirect',
  WALLET_ADDITION: '/wallet-addition',
  CURRENCIES_CHOICE: '/currencies-choice',
  SIGN_IN_SUCCESS: '/sign-up-success',
  SIGN_IN_SUCCESS_GET_STARTED: '/sign-up-success/getstarted',
  SIGN_IN_SUCCESS_ACCEPT_CRYPTO: '/sign-up-success/acceptcrypto',
  SIGN_IN_SUCCESS_START_ACCEPTING: '/sign-up-success/startaccepting',
  SIGN_IN: '/sign-in',
  CREATE_ACCOUNT: '/create-account',
  EMAIL_VERIFICATION: '/email-verification',
  REQUEST_RESET: '/request-reset',
  RESET_PASSWORD: '/reset-password/:id/:token',
  EXPIRED_RESET_PASSWORD: '/expired-reset-password',
  CUSTOMERS: '/customers',
  CUSTOMER: '/customers/:customerId',
  CUSTOMERS_TRANSACTIONS: '/customers/transactions',
  CUSTOMERS_BILLING_PLANS: '/customers/billing-plans',
  CUSTOMERS_BILLING_PLAN: '/customers/billing-plans/:planId',
  FIAT_OPERATIONS_SETTINGS: '/fiat-operations-settings',
  FIAT_OPERATIONS_SETTINGS_OFF_RAMP: '/fiat-operations-settings/off-ramp',
  ASTERISK: '*',
};

export const ROUTES_WITH_FOOTER = [
  ROUTES.SIGN_IN,
  ROUTES.CREATE_ACCOUNT,
  ROUTES.EMAIL_VERIFICATION,
  ROUTES.REQUEST_RESET,
  ROUTES.WALLET_ADDITION,
  ROUTES.EXPIRED_RESET_PASSWORD,
  ROUTES.OAUTH,
  ROUTES.CURRENCIES_CHOICE,
  ROUTES.SIGN_IN_SUCCESS,
  ROUTES.SIGN_IN_SUCCESS_GET_STARTED,
  ROUTES.SIGN_IN_SUCCESS_ACCEPT_CRYPTO,
  ROUTES.SIGN_IN_SUCCESS_START_ACCEPTING,
];
