import React from 'react';
import { useDispatch } from 'react-redux';
import CommonBlock from '../../../components/common-block';
import { buttonState } from '../../../components/submit-button';
import ConfirmModal from '../../../components/confirm-modal';
import SwitchCheckbox from '../../../components/switch-checkbox';
import apiClient from '../../../api/api-client';
import { useOffTwoFactor } from '../../../api/modules/account/use-off-two-factor';
import ModalTwoFactor from '../../../components/modal-two-factor';
import { useProfile } from '../../../api/modules/account/use-profile';
import { setPartner } from '../../../store/partner/reducer';
import SetTfaModal from './set-tfa-modal';
import BackupModal from './backup-modal';
import styles from './styles.module.scss';

const TFA_MODAL_STATE = {
  select: 'select',
  app: 'app',
  email: 'email',
};

function TwoFactorAuthentication(props) {
  const {
    hasTfa,
    backupKey,
    setTfa,
  } = props;

  const offTwoFactor = useOffTwoFactor();
  const fetchProfile = useProfile();
  const dispatch = useDispatch();

  const [secret, setSecret] = React.useState({
    key: '',
    qrCodeUrl: '',
  });
  const [verifyCode, setVerifyCode] = React.useState('');
  const [isTfaModalOpen, setIsTfaModalOpen] = React.useState(false);
  const [isBackupModalOpen, setIsBackupModalOpen] = React.useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [appTfaError, setAppTfaError] = React.useState('');
  const [emailTfaError, setEmailTfaError] = React.useState('');
  const [modalState, setModalState] = React.useState(TFA_MODAL_STATE.select);
  const [appTfaButtonSate, setAppTfaButtonState] = React.useState({
    state: buttonState.normal,
    errorMsg: '',
  });
  const [isShowTwoFactor, setIsShowTwoFactor] = React.useState(false);
  const [isInvalidVerificationCode, setIsInvalidVerificationCode] = React.useState(false);

  const updateProfile = async () => {
    const { data, status } = await fetchProfile();
    if (status === 200) {
      dispatch(setPartner(data));
    }
  };
  const closeTfaModal = () => {
    setIsTfaModalOpen(false);
    setSecret({
      key: '',
      qrCodeUrl: '',
    });
    setVerifyCode('');
    setModalState(TFA_MODAL_STATE.select);
  };
  const setupAppTfa = async () => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.get('two-factor/app-setup');

      setSecret({
        key: data.secret_key,
        qrCodeUrl: data.qr_code_url,
      });
      setModalState(TFA_MODAL_STATE.app);
    } catch (err) {
      if (err.response) {
        setAppTfaError(err.response.data.error);
        setTimeout(() => {
          setAppTfaError('');
        }, 3000);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const setupEmailTfa = async () => {
    try {
      setIsLoading(true);
      await apiClient.get('two-factor/email-setup');
      setModalState(TFA_MODAL_STATE.email);
    } catch (err) {
      if (err.response) {
        setEmailTfaError(err.response.data.error);
        setTimeout(() => {
          setEmailTfaError('');
        }, 3000);
      } else {
        console.error(err);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const addAppTfa = async () => {
    try {
      setAppTfaButtonState({
        ...appTfaButtonSate,
        state: buttonState.processing,
      });
      const { data } = await apiClient.post('two-factor/app-add', {
        secret_key: secret.key,
        verification_code: verifyCode,
      });

      setAppTfaButtonState({
        ...appTfaButtonSate,
        state: buttonState.normal,
      });
      setTfa(true, data.backup_key);
      void updateProfile();
      closeTfaModal();
    } catch (err) {
      let errorText = 'Error';

      if (err.response) {
        errorText = err.response.data.error;
      }
      setAppTfaButtonState({
        state: buttonState.failure,
        errorMsg: errorText,
      });
      setTimeout(() => {
        setAppTfaButtonState({
          state: buttonState.normal,
          errorMsg: '',
        });
      }, 2000);
    }
  };
  const openConfirmModal = () => {
    setIsConfirmModalOpen(true);
  };
  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };
  const handleCloseTfa = () => {
    setIsShowTwoFactor(false);
  };
  const handleShow2fa = () => {
    setIsConfirmModalOpen(false);
    setIsShowTwoFactor(true);
  };
  const handle2faOff = async (verifyCode, captchaToken) => {
    setIsLoading(true);
    const { data, status } = await offTwoFactor({
      captchaToken: captchaToken || null,
      verificationCode: verifyCode || null,
    });

    setIsLoading(false);

    if (status === 200) {
      void updateProfile();
      setTfa(false, '');
      handleCloseTfa();
    } else {
      if (data?.errorData?.message === 'Invalid verification code') {
        setIsInvalidVerificationCode(true);
        setTimeout(() => {
          setIsInvalidVerificationCode(false);
        }, 4000);
      }
    }
  };
  const openBackupModal = () => {
    if (backupKey && hasTfa) {
      setIsBackupModalOpen(true);
    }
  };
  const closeBackupModal = () => {
    setIsBackupModalOpen(false);
    setTfa(hasTfa, '');
  };

  React.useEffect(() => {
    openBackupModal();
  }, [backupKey]);

  return (
    <CommonBlock
      header="Two step authentification"
      className={styles.tfaSettings}
    >
      <div className={styles.tfaSettingsBlock}>
        <div>
          <p>Require a security key or code in addition to your password.</p>
          <SwitchCheckbox
            checked={hasTfa}
            onClick={hasTfa ? openConfirmModal : () => setIsTfaModalOpen(true)}
          />
        </div>
      </div>
      <SetTfaModal
        onClose={closeTfaModal}
        setVerifyCode={setVerifyCode}
        verifyCode={verifyCode}
        isOpen={isTfaModalOpen}
        addAppTfa={addAppTfa}
        setupAppTfa={setupAppTfa}
        setupEmailTfa={setupEmailTfa}
        secret={secret}
        isLoading={isLoading}
        modalState={modalState}
        appTfaError={appTfaError}
        emailTfaError={emailTfaError}
        appTfaButtonState={appTfaButtonSate.state}
        appTfaFailureMessage={appTfaButtonSate.errorMsg}
      />
      <BackupModal
        onClose={closeBackupModal}
        isOpen={isBackupModalOpen}
        backupKey={backupKey}
      />
      <ConfirmModal
        onClose={closeConfirmModal}
        isOpen={isConfirmModalOpen}
        onSubmit={handleShow2fa}
        processing={isLoading}
        message="Are you sure you want to remove two step authentification?"
      />
      <ModalTwoFactor
        isOpen={isShowTwoFactor}
        onSubmit={handle2faOff}
        onClose={handleCloseTfa}
        submitInvalidCode={isInvalidVerificationCode}
      />
    </CommonBlock>
  );
}

export default TwoFactorAuthentication;
