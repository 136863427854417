import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from '../../../popup';
import { ConversionCreated } from '../conversion-created';

export const ConversionCreatedPopup = (props) => {
  const {
    className,
    open,
    onClose,
  } = props;

  return (
    <Popup
      classNameContent={className}
      open={open}
      onClose={onClose}
    >
      <ConversionCreated onClick={onClose} />
    </Popup>
  );
};

ConversionCreatedPopup.defaultProps = {
  className: null,
  open: false,
  onClose: null,
};

ConversionCreatedPopup.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
