import * as React from 'react';
import PropTypes from 'prop-types';
import {
  SUBSCRIPTION_PLAN_PAGE_INFO_THS_DESKTOP,
  SUBSCRIPTION_PLAN_PAGE_INFO_THS_MOBILE,
  getPlanInfoData,
} from '../../helpers';
import { SubscriptionPlanInfoBox } from '../../../subscription-plan-info-box';

export const SubscriptionPlanInfo = React.memo((props) => {
  const {
    className,
    data,
    desktop,
    currenciesObject,
  } = props;

  const planHeaderItems = desktop
    ? SUBSCRIPTION_PLAN_PAGE_INFO_THS_DESKTOP
    : SUBSCRIPTION_PLAN_PAGE_INFO_THS_MOBILE;
  const bodyData = React.useMemo(() => (
    getPlanInfoData(data, desktop, currenciesObject)
  ), [data, desktop, currenciesObject]);

  return (
    <SubscriptionPlanInfoBox
      className={className}
      headerItems={planHeaderItems}
      bodyItems={bodyData}
    />
  );
});

SubscriptionPlanInfo.defaultProps = {
  className: null,
  data: {},
  desktop: false,
  currenciesObject: {},
};

SubscriptionPlanInfo.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    intervalDay: PropTypes.string,
    amount: PropTypes.number,
    ticker: PropTypes.string,
    period: PropTypes.string,
    createdAt: PropTypes.string,
  }),
  desktop: PropTypes.bool,
  currenciesObject: PropTypes.object,
};

SubscriptionPlanInfo.displayName = 'SubscriptionPlanInfo';
