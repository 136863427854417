import React from 'react';
import { getSafeErrorMessageText } from '../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../helpers/console-error-message';
import { useCreateSubscriptionsPlan } from '../api/modules/account/use-create-subscriptions-plan';
import { safeToLowerCase } from '../helpers/safe-to-lower-case';
import { getPeriodDays } from '../helpers/get-period-days';
import { isFunction } from '../helpers/is-function';
import { trackEvent } from '../helpers/utils/track-event';
import { TRACK_EVENT_NAMES } from '../constants/track-event-names';
import { useTimeout } from './use-timeout';

const DELAY = 4000;

/**
 * @param {{ reFetch: function|*, callback: function|* }} options
 * @returns {Object}
 */
export const useCreateSubscriptionPlan = (options = {}) => {
  const { reFetch, callback } = options;
  const fetchCreateSubscriptionsPlan = useCreateSubscriptionsPlan();

  const [isPopupShow, setIsPopupShow] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);
  const [apiError, setApiError] = React.useState(null);

  const handlePopupOpen = () => {
    setIsPopupShow(true);
    trackEvent(TRACK_EVENT_NAMES.CUSTOMERS_CREATE_PLAN, {
      action: 'click',
    });
  };
  const handlePopupClose = () => {
    setIsPopupShow(false);
  };
  const resetApiError = () => {
    setApiError(null);
  };
  const handleSubmit = React.useCallback(async (formData) => {
    const periodNumber = Number(formData.period);
    const periodSelectText = formData.periodSelect;
    const periodSelectTextLowerCased = safeToLowerCase(periodSelectText);
    const periodDays = String(getPeriodDays(periodNumber, periodSelectTextLowerCased));

    setIsFetching(true);
    const { data, status, errorMessage } = await fetchCreateSubscriptionsPlan({ ...formData, periodDays });

    if (status === 201) {
      if (isFunction(reFetch)) {
        await reFetch();
      }
      setIsFetching(false);
      setIsPopupShow(false);
      if (isFunction(callback)) {
        callback();
      }
      trackEvent(TRACK_EVENT_NAMES.CUSTOMERS_CREATED_PLAN, {
        action: 'submit',
      });
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/subscriptions/plans');
      setApiError(errorDataMessage);
      setIsFetching(false);
    }
  }, [fetchCreateSubscriptionsPlan, reFetch]);

  useTimeout(resetApiError, DELAY, apiError);

  return {
    isPopupShow,
    isFetching,
    apiError,
    handlePopupOpen,
    handlePopupClose,
    handleSubmit,
  };
};
