import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { hasElementsInArray } from '../../helpers/has-elements-in-array';
import { CopyText } from '../ui/copy-text';
import ToolTip from '../tool-tip';
import { CurrencyNetworkSup } from '../shared/currency-network-sup';
import styles from './styles.module.scss';

export const PaymentsInfoBox = (props) => {
  const {
    className,
    title,
    id,
    items,
  } = props;

  const hasItems = hasElementsInArray(items);

  return (
    <div className={cn(styles.info, className)}>
      <div className={styles.infoHeader}>
        <div className={styles.infoTitle}>
          {title}
        </div>
        <div className={styles.infoId}>
          {id}
        </div>
      </div>
      {hasItems && (
        <div className={styles.infoList}>
          {items.map((item) => item?.value && (
            <div
              key={item?.id}
              className={styles.infoListItem}
            >
              <div className={styles.infoListItemKey}>
                <span
                  className={cn(item?.keyNetwork && styles.infoListItemKeyFlex)}
                  data-tooltip-id="payment-info-box-tooltip"
                  data-tooltip-content={item?.tooltipText}
                >
                  {item?.key}
                  {item?.keyNetwork && (
                    <CurrencyNetworkSup network={item.keyNetwork} />
                  )}
                  {item?.tooltipText && (
                    <img
                      width={14}
                      height={14}
                      src="/images/info-icon.svg"
                      alt="Icon help"
                      decoding="async"
                    />
                  )}
                </span>
              </div>
              {!item?.isCopied && (
                <div
                  className={cn([
                    styles.infoListItemValue,
                    item?.network && styles.infoListItemValueFlex,
                  ])}
                >
                  <span dangerouslySetInnerHTML={{ __html: item.value }} />
                  {item?.network && (
                    <CurrencyNetworkSup network={item.network} />
                  )}
                </div>
              )}
              {item?.isCopied && !item?.link && (
                <div className={styles.infoListItemValue}>
                  <CopyText text={item.value} />
                </div>
              )}
              {item?.isCopied && item?.link && (
                <a
                  className={styles.infoListItemValue}
                  href={item.link}
                  target="_blank"
                  rel="nofollow noreferrer noopener"
                >
                  <CopyText
                    text={item.value}
                    copyIconPath="/images/copy-icon.svg"
                  />
                </a>
              )}
            </div>
          ))}
        </div>
      )}
      <ToolTip id="payment-info-box-tooltip" place="top" />
    </div>
  );
};

PaymentsInfoBox.defaultProps = {
  className: null,
  title: null,
  id: null,
  items: null,
};

PaymentsInfoBox.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    key: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
  })),
};
