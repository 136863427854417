import { hasElementsInArray } from '../../helpers/has-elements-in-array';
import { safeToUpperCase } from '../../helpers/safe-to-upper-case';
import { getColorStatus } from '../../helpers/get-color-status';
import { formatDate } from '../../helpers/format-date';
import { safeToLowerCase } from '../../helpers/safe-to-lower-case';
import { isDateMatchesDays } from '../../helpers/is-date-matches-days';
import { DATE_PATTERN } from '../../constants/app-constants';
import { getPaymentExtraIdsString } from '../../helpers/get-payment-extra-ids-string';
import { getArrayWithIndexId } from '../../helpers/get-array-with-index-id';
import { getPayinHashLink } from '../../helpers/get-payin-hash-link';
import { getCurrentNetworkForShow } from '../../helpers/get-current-network-for-show';

/**
 * @param payments {array<object>}
 * @param id {string}
 * @param paymentData {object}
 * @returns {array}
 */
export const getUpdatedPayments = (payments, id, paymentData) => {
  const hasPayments = hasElementsInArray(payments);

  if (!hasPayments) {
    return [];
  }

  const nextStatus = paymentData?.status ?? null;
  const nextExchangeData = paymentData?.exchange_data ?? {};
  const nextUpdatedAt = paymentData?.updated_at ?? null;

  return payments.map((payment) => {
    const nextPayment = payment;

    if (payment?.id === id) {
      nextPayment.exchange_data = nextExchangeData;
      nextPayment.status = nextStatus;
      nextPayment.updated_at = nextUpdatedAt;
    }

    return nextPayment;
  });
};


export const getPaymentInfoItems = (payment, isCustody, currenciesObject = {}) => {
  const parentPaymentId = payment?.parent_payment_id ?? null;
  const paymentExtraIds = getPaymentExtraIdsString(payment?.payment_extra_ids ?? null);

  const priceAmount = payment?.price_amount ?? null;
  const priceTicker = payment?.price_currency ?? '';
  const priceTickerUpperCased = safeToUpperCase(priceTicker);
  const priceCurrencyFound = currenciesObject[priceTickerUpperCased] || {};
  const priceCurrentTicker = priceCurrencyFound?.ticker ?? priceTicker;
  const priceAmountText = priceAmount ? `${priceAmount} ${priceCurrentTicker}` : '-';
  const currentPriceNetwork = getCurrentNetworkForShow(priceCurrencyFound?.network, priceTicker);

  const payAmount = payment?.pay_amount ?? '0';
  const payTicker = payment?.pay_currency ?? '';
  const payTickerUpperCased = safeToUpperCase(payTicker);
  const payCurrencyFound = currenciesObject[payTickerUpperCased] || {};
  const payCurrentTicker = payCurrencyFound?.ticker ?? payTickerUpperCased;
  const payAmountText = `${payAmount} ${payCurrentTicker}`;
  const currentPayNetwork = getCurrentNetworkForShow(payCurrencyFound?.network, payTicker);

  const amountSend = payment?.exchange_data?.amountSend ?? '0';
  const actuallyPaidText = `${amountSend} ${payCurrentTicker}`;

  const amountReceive = payment?.exchange_data?.amountReceive ?? 0;
  const outcomeTicker = payment?.outcome_currency ?? '';
  const outcomeTickerUpperCased = safeToUpperCase(outcomeTicker);
  const outcomeCurrencyFound = currenciesObject[outcomeTickerUpperCased] || {};
  const outcomeCurrentTicker = outcomeCurrencyFound?.ticker ?? outcomeTickerUpperCased;
  const outcomeAmountText = `${amountReceive} ${outcomeCurrentTicker}`;
  const currentOutcomeNetwork = getCurrentNetworkForShow(outcomeCurrencyFound?.network, outcomeTicker);

  const payinAddress = payment?.payin_address ?? null;
  const payinAddressNode = payinAddress ? `<strong>${payinAddress}</strong>` : null;
  const type = payment?.type ?? null;
  const status = payment?.status ?? null;
  const statusColor = status && getColorStatus(status);
  const statusNode = status ? `<span style="color: ${statusColor}">${status}</span>` : null;
  const createdDate = payment?.created_at ?? null;
  const createdDateNormalized = createdDate ? formatDate(createdDate, DATE_PATTERN) : null;
  const updatedDate = payment?.updated_at ?? null;
  const updatedDateNormalized = updatedDate ? formatDate(updatedDate, DATE_PATTERN) : null;
  const currencyFound = currenciesObject?.[payTickerUpperCased] || null;
  const payinHash = payment?.exchange_data?.payinHash ?? '';
  const payinHashLink = getPayinHashLink(currencyFound?.explorer_link_hash, payinHash);
  const payoutHash = payment?.exchange_data?.payoutHash ?? '';
  const payoutAddress = payment?.exchange_data?.payoutAddress ?? '';
  const isChangeNowPartner = payoutAddress?.startsWith('changenow_partner') ?? false;
  const currentPayoutAddress = isCustody && isChangeNowPartner ? 'Balance' : payoutAddress;
  const fixedRate = payment?.is_fixed_rate ? 'True' : 'False';
  const feePaidByUser = payment?.is_fee_paid_by_user ? 'True' : 'False';

  const result = [{
    key: 'Original Payment',
    value: parentPaymentId,
    network: null,
    link: null,
    isCopied: false,
  }, {
    key: 'Additional Payments',
    value: paymentExtraIds,
    network: null,
    link: null,
    isCopied: false,
  }, {
    key: 'Price Amount',
    value: priceAmountText,
    network: currentPriceNetwork,
    link: null,
    isCopied: false,
  }, {
    key: 'Pay Amount',
    value: payAmountText,
    network: currentPayNetwork,
    link: null,
    isCopied: false,
  }, {
    key: 'Actually Paid',
    value: actuallyPaidText,
    network: null,
    link: null,
    isCopied: false,
  }, {
    key: 'Outcome Amount',
    value: outcomeAmountText,
    network: currentOutcomeNetwork,
    link: null,
    isCopied: false,
  }, {
    key: 'Payin Address',
    value: payinAddressNode,
    network: null,
    link: null,
    isCopied: false,
  }, {
    key: 'Type',
    value: type,
    network: null,
    link: null,
    isCopied: false,
  }, {
    key: 'Status',
    value: statusNode,
    network: null,
    link: null,
    isCopied: false,
  }, {
    key: 'Created at',
    value: createdDateNormalized,
    network: null,
    link: null,
    isCopied: false,
  }, {
    key: 'Status updated',
    value: updatedDateNormalized,
    network: null,
    link: null,
    isCopied: false,
  }, {
    key: 'Payout address',
    value: currentPayoutAddress,
    network: null,
    link: null,
    isCopied: false,
  }, {
    key: 'Payin Hash',
    value: payinHash,
    network: null,
    link: payinHashLink,
    isCopied: true,
  }, {
    key: 'Payout Hash',
    value: payoutHash,
    network: null,
    link: null,
    isCopied: true,
  }, {
    key: 'Fixed rate',
    value: fixedRate,
    network: null,
    link: null,
    isCopied: false,
  }, {
    key: 'Fee paid by user',
    value: feePaidByUser,
    network: null,
    link: null,
    isCopied: false,
  }];

  return getArrayWithIndexId(result);
};

/**
 * @param data {Array<Object>}
 * @param searchedText {string}
 * @returns {Array}
 */
export const getFilteredPaymentsWithSearch = (data, searchedText) => {
  const hasData = hasElementsInArray(data);

  if (!hasData) {
    return [];
  }

  if (!searchedText) {
    return data;
  }

  const searchTextLowerCased = safeToLowerCase(searchedText);

  return data.filter((item) => {
    const id = item?.id ?? '';
    const orderId = item?.order_id ?? '';
    const hash = item?.payin_hash ?? '';

    const idLowerCased = safeToLowerCase(id);
    const orderIdLowerCased = safeToLowerCase(orderId);
    const hashLowerCased = safeToLowerCase(hash);

    return idLowerCased.indexOf(searchTextLowerCased) > -1
      || orderIdLowerCased.indexOf(searchTextLowerCased) > -1
      || hashLowerCased.indexOf(searchTextLowerCased) > -1;
  });
};

/**
 * @param payments {array}
 * @param formData {object}
 * @returns {array}
 */
export const getFilteredPaymentsWithSearchData = (payments, formData) => {
  const hasPayments = hasElementsInArray(payments);

  if (!hasPayments) {
    return [];
  }

  const searchPayinAddress = formData?.payinAddress ?? '';
  const searchDays = formData?.createdAt ?? '';
  const searchStatus = formData?.status ?? '';
  const searchPayinHash = formData?.payinHash ?? '';
  const searchPaymentId = formData?.paymentId ?? '';
  const searchOrderId = formData?.orderId ?? '';

  return payments.filter((item) => {
    const itemPayinAddress = item?.payin_address ?? '';
    const itemCreatedAt = item?.created_at ?? '';
    const itemStatus = item?.status ?? '';
    const itemPayinHash = item?.payin_hash ?? '';
    const itemPaymentId = item?.id ?? '';
    const itemOrderId = item?.order_id ?? '';
    const isPayinAddressMatch = itemPayinAddress.startsWith(searchPayinAddress);
    const isDateMatch = isDateMatchesDays(itemCreatedAt, searchDays);
    const isStatusMatch = itemStatus === searchStatus;
    const isPayinHashMatch = itemPayinHash.startsWith(searchPayinHash);
    const isPaymentIdMatch = itemPaymentId.startsWith(searchPaymentId);
    const isOrderIdMatch = itemOrderId.startsWith(searchOrderId);
    const isPayinAddressNotFound = Boolean(searchPayinAddress) && !isPayinAddressMatch;
    const isDateNotFound = Boolean(searchDays) && !isDateMatch;
    const isStatusNotFound = Boolean(searchStatus) && !isStatusMatch;
    const isPayinHashNotFound = Boolean(searchPayinHash) && !isPayinHashMatch;
    const isPaymentIdNotFound = Boolean(searchPaymentId) && !isPaymentIdMatch;
    const isOrderIdNotFound = Boolean(searchOrderId) && !isOrderIdMatch;

    const isNotAllowed = (
      isPayinAddressNotFound
      || isDateNotFound
      || isStatusNotFound
      || isPayinHashNotFound
      || isPaymentIdNotFound
      || isOrderIdNotFound
    );

    return !isNotAllowed;
  });
};
