import React from 'react';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import { StorePaymentSettingsDataType } from '../../helpers/types/use-update-store-payment-settings-types';
import { StorePaymentSettingsParams } from '../../../default-types';

const mapFn = (data: StorePaymentSettingsDataType) => ({
  markup: data?.data?.markup ?? null,
  covering: data?.data?.covering ?? null,
  feeOptimization: data?.data?.feeOptimization ?? false,
  withdrawalFeePaidBy: data?.data?.withdrawalFeePaidBy ?? null,
});

export const useUpdateStorePaymentSettings = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (params: StorePaymentSettingsParams) => {
    const [cancelToken] = getRequestSource();
    const requestPromise = client.patch(
      '/store-payment-settings',
      params,
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
