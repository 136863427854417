import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { isFunction } from '../../../helpers/is-function';
import styles from './styles.module.scss';

export const CopyButton = (props) => {
  const {
    className,
    onClick,
    isCopied,
    copyIconPath,
    copySuccessIconPath,
  } = props;

  const currentCopyIconPath = copyIconPath || '/images/copy-blue-icon.svg';
  const currentCopySuccessIcon = copySuccessIconPath || '/images/copy-success-icon.svg';

  const handleClick = () => {
    if (isFunction(onClick)) {
      onClick();
    }
  };

  return (
    <div
      className={cn(styles.copyButton, className)}
      onClick={handleClick}
      onKeyPress={handleClick}
      role="button"
      tabIndex={0}
    >
      {!isCopied
        ? (
          <img
            className={styles.copyButtonImgCopy}
            src={currentCopyIconPath}
            alt="Copy Icon"
            decoding="async"
          />
        ) : (
          <img
            className={styles.copyButtonImgSuccess}
            src={currentCopySuccessIcon}
            alt="Copy Icon"
            decoding="async"
          />
        )}
    </div>
  );
};

CopyButton.defaultProps = {
  className: null,
  onClick: null,
  isCopied: false,
  copyIconPath: null,
  copySuccessIconPath: null,
};

CopyButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  isCopied: PropTypes.bool,
  copyIconPath: PropTypes.string,
  copySuccessIconPath: PropTypes.string,
};
