import React from 'react';
import cn from 'classnames';
import { isCurrencyNetworkShow } from '../../helpers/is-currency-network-show';
import { NETWORKS } from '../../constants/networks';
import { safeToLowerCase } from '../../helpers/safe-to-lower-case';
import { safeToUpperCase } from '../../helpers/safe-to-upper-case';
import styles from './styles.module.scss';

type MinAmountTextProps = {
  className?: string | null,
  minAmount: number,
  ticker: string,
  currentTicker: string,
  network: string,
  baseCurrency: string,
};

export const MinAmountText: React.FC<MinAmountTextProps> = (props) => {
  const {
    className,
    minAmount,
    ticker,
    currentTicker,
    network,
    baseCurrency,
  } = props;

  const isNetworkShow = isCurrencyNetworkShow(network, ticker);
  const baseCurrencyUpperCase = safeToUpperCase(baseCurrency);
  const coinCurrentTicker = safeToUpperCase(currentTicker ?? ticker);
  const coinNetworkNormalized = NETWORKS[safeToLowerCase(network) as keyof typeof NETWORKS]?.name
    ?? safeToUpperCase(network);

  return (
    <div className={cn([
      className,
      styles.minAmountText,
    ])}
    >
      Сurrent
      {' '}
      <span>minimum payment amount</span>
      {' '}
      for
      {' '}
      {coinCurrentTicker}
      {' '}
      {isNetworkShow && (
        <>
          on
          {' '}
          {coinNetworkNormalized}
          {' '}
        </>
      )}
      is
      {' '}
      {minAmount}
      {' '}
      {baseCurrencyUpperCase}
    </div>
  );
};

MinAmountText.defaultProps = {
  className: null,
};
