import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteApiKey } from '../../../../store/payment-settings/reducer';
import { Button } from '../../../ui/button';
import { LoaderIcon } from '../../../icons/loader-icon';
import { useRevokeApiKey } from '../../../../api/modules/account/use-revoke-api-key';
import { getSafeErrorMessageText } from '../../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../../helpers/console-error-message';
import { ApiKey, ResponseType } from '../../../../default-types';
import classes from './styles.module.scss';

type DeleteApiKeyProps = {
  apiKey: ApiKey,
  onClose: () => void,
};

export const DeleteApiKey: React.FC<DeleteApiKeyProps> = (props) => {
  const {
    apiKey,
    onClose,
  } = props;

  const fetchRevokeApiKey = useRevokeApiKey();
  const dispatch = useDispatch();

  const [isFetching, setIsFetching] = React.useState(false);
  const [apiError, setApiError] = React.useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsFetching(true);

    const {
      data,
      status,
      errorMessage,
    }: ResponseType = await fetchRevokeApiKey(apiKey.id ?? '');

    if (status === 200 && data) {
      dispatch(deleteApiKey({ id: apiKey.id }));
      onClose();
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/api-keys/revoke');
      setApiError(errorDataMessage);
    }
    setIsFetching(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <p className={classes.deleteApiKeyText}>
        Are you sure that you want to delete
        {' '}
        <span>{apiKey.apiKey}</span>
        {' '}
        API key? Once deleted, you will lose access to data on all transactions (payments,
        payouts, conversions and transfers) that were created with this key.
      </p>
      <div className={classes.deleteApiKeyError}>
        {apiError}
      </div>
      <Button
        type="submit"
        displayRed
      >
        {
          isFetching
            ? <LoaderIcon path="/images/loader-white-on-red-icon.gif" />
            : 'I understand, delete'
        }
      </Button>
      <Button
        className={classes.closeButton}
        onClick={onClose}
        displayBorder
      >
        Cancel
      </Button>
    </form>
  );
};
