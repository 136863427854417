/* eslint no-param-reassign: [0] */
const dateFormat = () => {
  const token =
    /d{1,4}|m{1,4}|yy(?:yy)?|([HhMsTt])\1?|[LloSZ]|"[^"]*"|'[^']*'/g;
  // eslint-disable-next-line
  const timezone = /\b(?:[PMCEA][SDP]T|(?:Pacific|Mountain|Central|Eastern|Atlantic) (?:Standard|Daylight|Prevailing) Time|(?:GMT|UTC)(?:[-+]\d{4})?)\b/g;
  const timezoneClip = /[^-+\dA-Z]/g;

  const pad = (val = "", len = 2) => {
    while (String(val).length < len) {
      val = `0${val}`; // eslint-disable-line
    }

    return val;
  };

  return (date, mask, utc = false) => {
    const dF = dateFormat;

    if (mask === "transactions") {
      utc = false;
    }
    date = new Date(date);
    mask = String(dF.masks[mask] || mask || dF.masks.default);

    if (mask.slice(0, 4) === "UTC:") {
      mask = mask.slice(4);
    }

    const _ = utc ? "getUTC" : "get";
    const d = date[`${_}Date`]();
    const D = date[`${_}Day`]();
    const m = date[`${_}Month`]();
    const y = date[`${_}FullYear`]();
    const H = date[`${_}Hours`]();
    const M = date[`${_}Minutes`]();
    const s = date[`${_}Seconds`]();
    const L = date[`${_}Milliseconds`]();
    const o = utc ? 0 : date.getTimezoneOffset();
    const flags = {
      d,
      dd: pad(d),
      ddd: dF.i18n.dayNames[D],
      dddd: dF.i18n.dayNames[D + 7],
      m: m + 1,
      mm: pad(m + 1),
      mmm: dF.i18n.monthNames[m],
      mmmm: dF.i18n.monthNames[m + 12],
      yy: String(y).slice(2),
      yyyy: y,
      h: H % 12 || 12,
      hh: pad(H % 12 || 12),
      H,
      HH: pad(H),
      M,
      MM: pad(M),
      s,
      ss: pad(s),
      l: pad(L, 3),
      L: pad(L > 99 ? Math.round(L / 10) : L),
      t: H < 12 ? "a" : "p",
      tt: H < 12 ? "am" : "pm",
      T: H < 12 ? "A" : "P",
      TT: H < 12 ? "AM" : "PM",
      Z: utc
        ? "UTC"
        : (String(date).match(timezone) || [""])
          .pop()
          .replace(timezoneClip, ""),
      o:
        (o > 0 ? "-" : "+") +
        pad(Math.floor((Math.abs(o) / 60) * 100) + (Math.abs(o) % 60), 4),
      S: ["th", "st", "nd", "rd"][
        d % 10 > 3 ? 0 : (((d % 100) - (d % 10) !== 10) * d) % 10
      ],
    };

    return mask.replace(token, ($0) =>
      $0 in flags ? flags[$0] : $0.slice(1, $0.length - 1),
    );
  };
};

dateFormat.masks = {
  default: "dd mmm yyyy, hh:MM tt",
  transactions: "hh:MM tt",
  news: "mmm, d yyyy",
};

dateFormat.i18n = {
  dayNames: [
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  monthNames: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (date, mask) => dateFormat()(date, mask);
