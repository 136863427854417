export const METHOD_NAMES = {
  AUTO: 'automatic-method',
  MANUAL: 'manual-method',
};

export const SUCCESS_TEXTS = {
  TITLE: 'Your mass payout has been created.',
  TEXT: 'IMPORTANT: make sure that you are making a payout to the whitelisted address. If not, please contact us on <a href="mailto:partners@nowpayments.io">partners@nowpayments.io</a> to confirm your payout.',
};

export const ERROR_TEXTS = {
  INVALID_ADDRESS: 'Invalid address',
  INVALID_EXTRA_ID: 'Invalid memo',
  INVALID_AMOUNT: 'Invalid amount',
  REQUIRED: 'Required field',
};

export const MAXIMUM_MANUAL_PAYOUTS = 5;

export const NAME_FOR_PAYOUTS_SCHEMA = 'payouts';

export const DEFAULT_TEMPLATE_FOR_PAYOUT = {
  address: '',
  amount: '',
  currency: '',
  extra_id: '',
};

export const FIELD_NAMES = {
  CURRENCY: 'currency',
  ADDRESS: 'address',
  EXTRA_ID: 'extra_id',
  AMOUNT: 'amount',
};
