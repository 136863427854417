import React from 'react';
import cn from 'classnames';
import { Button } from '../../../ui/button';
import { ProductCardType } from '../../types';
import classes from './styles.module.scss';

type DashboardProductCardProps = {
  card: ProductCardType | undefined,
  isSmallCard: boolean,
};

export const DashboardProductCard: React.FC<DashboardProductCardProps> = (props) => {
  const {
    card,
    isSmallCard,
  } = props;

  return (
    <div className={cn([
      classes.dashboardProductCard,
      isSmallCard && classes.dashboardProductCardSmall,
    ])}
    >
      <div className={classes.dashboardProductCardImageContainer}>
        <img
          src={card?.imagePath}
          alt={card?.name}
          className={classes.dashboardProductCardImage}
          decoding="async"
          loading="lazy"
        />
        {card?.isApiIntegrationAvailable && card?.apiLink && (
          <a
            className={classes.dashboardProductCardApi}
            href={card.apiLink}
            target="_blank"
            rel="nofollow noreferrer noopener"
          >
            Integrate with API
          </a>
        )}
      </div>
      <div className={classes.dashboardProductCardContent}>
        <h3 className={classes.cardTitle}>
          {card?.name}
        </h3>
        <p
          className={classes.cardDescription}
          dangerouslySetInnerHTML={{ __html: card?.description || '' }} // eslint-disable-line react/no-danger
        />
        {card?.buttonLabel && card?.buttonLink && (
          <Button
            className={classes.cardButton}
            href={card.buttonLink}
            displayBorder
            isSpaLink
          >
            {card.buttonLabel}
          </Button>
        )}
        {card?.icons && (
          <div className={classes.cardIcons}>
            {card.icons.map((icon) => (
              <a
                href={icon.link}
                target="_blank"
                rel="nofollow noreferrer noopener"
              >
                <img
                  src={icon.imagePath}
                  alt={icon.name}
                  decoding="async"
                  loading="lazy"
                />
              </a>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
