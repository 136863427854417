import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { formatDate } from '../../../../helpers/format-date';
import { isFunction } from '../../../../helpers/is-function';
import { DATE_PATTERN } from '../../../../constants/app-constants';
import { getColorStatus } from '../../../../helpers/get-color-status';
import { normalizeAmountWithTickerString } from '../../../../helpers/normalize-amount-with-ticker-string';
import classes from './styles.module.scss';

export const SubscriptionUserTableRow = React.memo((props) => {
  const {
    className,
    payment,
    currenciesObject,
    onClick,
    isDesktop,
  } = props;

  const paymentId = payment?.paymentId ?? null;
  const invoiceId = payment?.invoiceId ?? null;
  const amountSent = normalizeAmountWithTickerString(payment?.payPrice, currenciesObject);
  const amountReceived = normalizeAmountWithTickerString(payment?.outcomePrice, currenciesObject);
  const status = payment?.status ?? null;
  const createdAt = payment?.createdAt ?? '';
  const updatedAt = payment?.updatedAt ?? '';
  const statusColor = status && getColorStatus(status);
  const statusNormalized = status || '-';
  const statusNode = `<span style="color: ${statusColor}">${statusNormalized}</span>`;
  const createdDateFormatted = formatDate(createdAt, DATE_PATTERN);
  const createdDateNormalized = createdDateFormatted || '-';
  const updatedDateFormatted = formatDate(updatedAt, DATE_PATTERN);
  const updatedDateNormalized = updatedDateFormatted || '-';

  const handleRowClick = () => {
    if (isFunction(onClick)) {
      onClick(payment);
    }
  };

  return (
    <tr
      className={cn(classes.row, className)}
      onClick={handleRowClick}
    >
      <td className={classes.rowTd}>
        {paymentId}
        <div className={classes.rowTdSecondItem}>
          {invoiceId}
        </div>
      </td>
      <td className={cn(classes.rowTd, classes.colorBlack)}>
        {amountSent}
        {!isDesktop && (
          <div className={classes.rowTdSecondItem}>
            {amountReceived}
          </div>
        )}
      </td>
      {isDesktop && (
        <td className={cn(classes.rowTd, classes.colorBlack)}>
          {amountReceived}
        </td>
      )}
      <td className={classes.rowTd}>
        <div dangerouslySetInnerHTML={{ __html: statusNode }}/>
      </td>
      {isDesktop && (
        <>
          <td className={classes.rowTd}>
            {createdDateNormalized}
          </td>
          <td className={classes.rowTd}>
            {updatedDateNormalized}
          </td>
        </>
      )}
    </tr>
  );
});

SubscriptionUserTableRow.defaultProps = {
  className: null,
  payment: null,
  currenciesObject: {},
  onClick: null,
  isDesktop: false,
};

SubscriptionUserTableRow.propTypes = {
  className: PropTypes.string,
  payment: PropTypes.object,
  currenciesObject: PropTypes.object,
  onClick: PropTypes.func,
  isDesktop: PropTypes.bool,
};

SubscriptionUserTableRow.displayName = 'SubscriptionUserTableRow';
