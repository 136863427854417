import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ToolTip from '../../components/tool-tip';
import { PageHeader } from '../ui/page-header';
import {
  DIFFERENT_FORMAT_HEADERS,
  headers,
  PAGE_NAMES_TO_DISPLAY_INFO,
} from '../../constants/app-constants';
import { saveToPdf } from '../../helpers/save-to-pdf';
import { saveToDifferentFileFormat } from '../../helpers/save-to-different-file-format';
import { hasElementsInArray } from '../../helpers/has-elements-in-array';
import { ExportPopup } from '../shared/export-popup';
import { PaymentsInfoBox } from '../payments-info-box';
import { Popup } from '../popup';
import { usePaymentFinishId } from '../../api/modules/account/use-payment-finish-id';
import { getSafeErrorMessageText } from '../../helpers/get-safe-error-message-text';
import { useRepeatedIpn } from '../../api/modules/account/use-repeated-ipn';
import { debounce } from '../../helpers/utils';
import {
  isPartnerCustodySelector,
  partnerFetchingSelector,
} from '../../store/partner/selectors';
import { useFetchPartnerProfile } from '../../api/modules/account/use-fetch-partner-profile';
import { PaymentsInfoButtons } from '../payments-info-buttons';
import { usePageNamesToDisplayInfo } from '../../hooks/use-page-names-to-display-info';
import { ROUTES } from '../../constants/routes';
import { consoleErrorMessage } from '../../helpers/console-error-message';
import { apiKeysSelector } from '../../store/payment-settings/selectors';
import { useApiKeysData } from '../../hooks/use-api-keys-data';
import { useAllCurrencies } from '../../hooks/use-all-currencies';
import { getPaymentInfoItems } from '../../helpers/get-payments-info-items';
import { PaymentHistoryTable } from '../payment-history-table';
import { createBodyForDocument } from './payments-page-helpers';
import { PaymentsInfoAboutUse } from './components/payments-info-about-use';
import { usePaymentsPageInitState, usePaymentsPageFilterState } from './hooks';
import { PaymentsFullFilter } from './components/payments-full-filter';
import styles from './styles.module.scss';

const DOCUMENT_NAME = 'NowPayments';

export const PaymentsPage = () => {
  const history = useHistory();
  const fetchPaymentFinishId = usePaymentFinishId();
  const fetchRepeatedIpn = useRepeatedIpn();
  const isPartnerFetching = useSelector(partnerFetchingSelector());
  const isPartnerCustody = useSelector(isPartnerCustodySelector());
  const apiKeys = useSelector(apiKeysSelector());

  const {
    infoBoxShow,
    addPageNameToDisplayInfo,
    removePageNameToDisplayInfo,
  } = usePageNamesToDisplayInfo(PAGE_NAMES_TO_DISPLAY_INFO.PAYMENTS);

  const {
    isPaymentsFetching,
    isApiTimeoutError,
    payments,
    paymentsCount,
    paymentsApiErrorMessage,
    reFetchPayments,
    isPaymentsForExportFetching,
    paymentsForExportErrorMessage,
    getPaymentsForExport,
    cryptoCurrencies,
    currenciesObject,
  } = usePaymentsPageInitState();

  const {
    searchText,
    setSearchText,
    page,
    setPage,
    filterData,
    numberOfActiveFilters,
    handleFilterReset,
    handleFilterSubmit,
  } = usePaymentsPageFilterState(reFetchPayments);

  const [activePaymentInfo, setActivePaymentInfo] = React.useState(null);
  const [errorApiMessage, setErrorApiMessage] = React.useState(null);
  const [isExportModalShow, setIsExportModalShow] = React.useState(false);
  const [isPopupInfoOpen, setIsPopupInfoOpen] = React.useState(false);
  const [isChangeStatusFetching, setIsChangeStatusFetching] = React.useState(false);
  const [isIpnFetching, setIsIpnFetching] = React.useState(false);
  const [isIpnSent, setIsIpnSent] = React.useState(false);

  const paymentInfoItems = React.useMemo(() => (
    getPaymentInfoItems(activePaymentInfo, isPartnerCustody, currenciesObject)
  ), [activePaymentInfo, isPartnerCustody, currenciesObject]);

  const handleSearchChange = React.useCallback(debounce(async (value) => {
    await reFetchPayments(1, value, filterData);
    setSearchText(value);
    setPage(1);
  }, 400), [filterData]);
  const handlePaginatorClick = React.useCallback(async (nextPage) => {
    await reFetchPayments(nextPage, searchText, filterData);
    setPage(nextPage);
  }, [searchText, filterData]);
  const handleTableRowClick = React.useCallback((payment) => {
    if (!payment) {
      return;
    }
    setActivePaymentInfo(payment);
    setIsPopupInfoOpen(true);
  }, []);
  const handleRedirectToInvoices = () => {
    history.push(`${ROUTES.INVOICES}?firstPayment=true`);
  };
  const handlePopupInfoClose = () => {
    setIsPopupInfoOpen(false);
    if (errorApiMessage) {
      setErrorApiMessage(null);
    }
  };

  const exportPDF = async () => {
    const paymentsForExport = await getPaymentsForExport(1, searchText, filterData);
    saveToPdf({
      name: DOCUMENT_NAME,
      headerData: [headers],
      bodyData: createBodyForDocument(paymentsForExport),
      columnOptions: {
        0: { cellWidth: 15 },
        1: { cellWidth: 15 },
        2: { cellWidth: 15 },
        3: { cellWidth: 15 },
        4: { cellWidth: 15 },
        5: { cellWidth: 15 },
        6: { cellWidth: 15 },
        9: { cellWidth: 25 },
        10: { cellWidth: 25 },
        11: { cellWidth: 25 },
        12: { cellWidth: 25 },
      },
      orientation: 'landscape',
    });
  };
  const exportDifferentFormat = async (format) => {
    const paymentsForExport = await getPaymentsForExport(1, searchText, filterData);
    saveToDifferentFileFormat({
      name: DOCUMENT_NAME,
      format: format,
      headerData: [DIFFERENT_FORMAT_HEADERS],
      bodyData: createBodyForDocument(paymentsForExport, false),
    });
  };
  const handleExportModalToggle = () => {
    setIsExportModalShow((prevState) => !prevState);
  };
  const handleChangeStatusToFinished = async () => {
    setIsChangeStatusFetching(true);
    setErrorApiMessage(null);
    const id = activePaymentInfo?.paymentId ?? null;

    const { data, status, errorMessage } = await fetchPaymentFinishId({ id });

    if (status === 200 && data) {
      const nextStatus = data?.status ?? null;
      const nextUpdatedAt = data?.updated_at ?? null;
      const nextPaymentDataTemplate = {
        status: nextStatus,
        updatedAt: nextUpdatedAt,
      };
      setActivePaymentInfo((prevState) => ({
        ...prevState,
        ...nextPaymentDataTemplate,
      }));

      await reFetchPayments(page, searchText, filterData);
      setIsChangeStatusFetching(false);
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/payment/finish/:id');

      setIsChangeStatusFetching(false);
      setErrorApiMessage(errorMessage);
    }
  };
  const handleIPNNotification = async () => {
    const id = activePaymentInfo?.paymentId ?? null;

    setIsIpnFetching(true);
    const { status } = await fetchRepeatedIpn({ id });
    setIsIpnFetching(false);

    if (status === 200) {
      setIsIpnSent(true);
      setTimeout(() => {
        setIsIpnSent(false);
      }, 2000);
    }
  };

  useFetchPartnerProfile();
  useAllCurrencies();
  useApiKeysData();

  const activePaymentInfoId = activePaymentInfo?.paymentId ?? null;
  const isExportDisabled = !hasElementsInArray(payments);

  return (
    <div>
      <PageHeader
        title="Payments"
        showTooltipFirstText={!infoBoxShow}
        onClick={infoBoxShow ? removePageNameToDisplayInfo : addPageNameToDisplayInfo}
      />
      {infoBoxShow && (
        <PaymentsInfoAboutUse
          className={styles.infoBlock}
          name={PAGE_NAMES_TO_DISPLAY_INFO.PAYMENTS}
          onClose={removePageNameToDisplayInfo}
        />
      )}
      <PaymentsFullFilter
        onChangeSearch={handleSearchChange}
        activeFilterItemsNumber={numberOfActiveFilters}
        onSubmit={handleFilterSubmit}
        onFilterReset={handleFilterReset}
        onExportModal={handleExportModalToggle}
        isExportDisabled={isExportDisabled}
        filterFormData={filterData}
        currencies={cryptoCurrencies}
        apiKeys={apiKeys}
      />
      <PaymentHistoryTable
        items={payments}
        hasItems={hasElementsInArray(payments)}
        hasSearchData={Boolean(searchText) || Boolean(filterData)}
        loading={isPartnerFetching || isPaymentsFetching}
        onClick={handleTableRowClick}
        count={paymentsCount}
        onPaginatorClick={handlePaginatorClick}
        page={page}
        apiError={paymentsApiErrorMessage}
        isTimeoutError={isApiTimeoutError}
        onRedirect={handleRedirectToInvoices}
        currenciesObject={currenciesObject}
        withEmptyElement
      />
      <ToolTip className={styles.toolTip} />
      <ExportPopup
        className={styles.exportPopup}
        open={isExportModalShow}
        onClose={handleExportModalToggle}
        onExportPdf={exportPDF}
        onExport={exportDifferentFormat}
        fetching={isPaymentsForExportFetching}
        errorMessage={paymentsForExportErrorMessage}
      />
      <Popup
        open={isPopupInfoOpen}
        onClose={handlePopupInfoClose}
        withCloseButton
      >
        <PaymentsInfoBox
          title="Payment ID"
          id={activePaymentInfoId}
          items={paymentInfoItems}
        />
        <PaymentsInfoButtons
          status={activePaymentInfo?.status ?? null}
          changeStatusFetching={isChangeStatusFetching}
          onChangeStatusToFinished={handleChangeStatusToFinished}
          onIPN={handleIPNNotification}
          ipnFetching={isIpnFetching}
          ipnSent={isIpnSent}
          onClose={handlePopupInfoClose}
        />
        <div className={styles.popupPaymentInfoError}>
          {errorApiMessage}
        </div>
      </Popup>
    </div>
  );
};
