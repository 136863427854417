import * as React from 'react';
import { Helmet } from 'react-helmet';
import { SubscriptionPlanPage } from '../../components/subscriptions-pages';

const SubscriptionPlan = () => (
  <>
    <Helmet>
      <title>Subscription Plan</title>
    </Helmet>
    <SubscriptionPlanPage />
  </>
);

export default SubscriptionPlan;
