import * as React from 'react';
import cn from 'classnames';
import { isFunction } from '../../helpers/is-function';
import classes from './styles.module.scss';

export const NotificationMessage = (props) => {
  const { children, onClose } = props;

  const handleClose = () => {
    if (isFunction(onClose)) {
      onClose();
    }
  };

  return (
    <div className={cn([
      classes.notificationMessage,
      children && classes.notificationMessageActive,
    ])}>
      <div
        className={classes.notificationMessageClose}
        onClick={handleClose}
      >
        <div className={classes.notificationMessageCloseLine} />
        <div className={classes.notificationMessageCloseLine} />
      </div>
      <div
        className={classes.notificationMessageText}
        dangerouslySetInnerHTML={{ __html: children }}
      />
    </div>
  );
};
