import React from 'react';
import { Portal } from '../../../portal';
import { Popup } from '../../../popup';
import { WhitelistTabs } from '../whitelist-tabs';
import { WhitelistManualForm } from '../whitelist-manual-form';
import { WhitelistPopupTypes } from '../../types';
import { WhitelistUploadCsv } from '../whitelist-upload-csv';
import { WhitelistTexts } from '../whitelist-texts';
import classes from './styles.module.scss';

export const WhitelistPopup: React.FC<WhitelistPopupTypes> = (props) => {
  const {
    open,
    onClose,
    activeType,
    onActiveType,
    onSubmit,
    isManualType,
    isUploadType,
    onWhitelistData,
    hasWhitelistData,
    currencies,
  } = props;

  return (
    <Portal>
      <Popup
        classNameContent={classes.popup}
        open={open}
        onClose={onClose}
        withCloseButton
        mobileFullView
        line
        title="Whitelist my addresses"
      >
        <WhitelistTabs
          activeType={activeType}
          onActiveType={onActiveType}
        />
        <WhitelistTexts
          isManualType={isManualType}
          isUploadType={isUploadType}
        />
        {isManualType && (
          <WhitelistManualForm
            currencies={currencies}
            onSubmit={onSubmit}
          />
        )}
        {isUploadType && (
          <WhitelistUploadCsv
            onCSVcb={onWhitelistData}
            onRemove={onWhitelistData}
            onSubmit={onSubmit}
            disabled={!hasWhitelistData}
          />
        )}
      </Popup>
    </Portal>
  );
};
