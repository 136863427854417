import * as React from 'react';
import { useSelector } from 'react-redux';
import { isPartnerCustodySelector, partnerFetchedSelector } from '../../../store/partner/selectors';
import { useWithdrawalPayments } from '../../../api/modules/account/use-withdrawal-payments';
import { isObject } from '../../../helpers/is-object';
import { checkErrorCode } from '../../../helpers/check-error-code';
import { getSafeErrorMessageText } from '../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../helpers/console-error-message';
import {
  getFilteredMassPayoutsWithFilter,
  getFilteredMassPayoutsWithSearch,
  MASS_PAYOUTS_PAGE_PER_PAGE,
  normalizeMassPayouts,
} from '../helpers';
import { debounce } from '../../../helpers/utils';
import { useNumberOfActiveFilters } from '../../../hooks/use-number-of-active-filters';
import { isFunction } from '../../../helpers/is-function';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { currencyListSelector } from '../../../store/currencies/selectors';
import { useCurrenciesToObject } from '../../../hooks/use-currencies-to-object';

export const useInitState = () => {
  const isDesktop = useMediaQuery('(min-width: 992px)');
  const fetchWithdrawalPayments = useWithdrawalPayments();
  const isPartnerCustody = useSelector(isPartnerCustodySelector());
  const partnerFetched = useSelector(partnerFetchedSelector());
  const currencyList = useSelector(currencyListSelector());
  const { currenciesObject } = useCurrenciesToObject(currencyList);

  const [massPayoutData, setMassPayoutData] = React.useState([]);
  const [isFetching, setIsFetching] = React.useState(false);
  const [apiError, setApiError] = React.useState(null);

  const [searchText, setSearchText] = React.useState('');
  const [filterData, setFilterData] = React.useState(null);
  const [page, setPage] = React.useState(1);

  const massPayoutsNormalized = React.useMemo(() => (
    normalizeMassPayouts(massPayoutData, currenciesObject)
  ), [massPayoutData, currenciesObject]);
  const massPayoutsFilteredWithSearch = React.useMemo(() => (
    getFilteredMassPayoutsWithSearch(massPayoutsNormalized, searchText)
  ), [massPayoutsNormalized, searchText]);
  const massPayoutsFilteredWithFilter = React.useMemo(() => (
    getFilteredMassPayoutsWithFilter(massPayoutsFilteredWithSearch, filterData)
  ), [massPayoutsFilteredWithSearch, filterData]);
  const massPayoutsForPage = React.useMemo(() => {
    if (massPayoutsFilteredWithFilter.length <= MASS_PAYOUTS_PAGE_PER_PAGE) {
      return massPayoutsFilteredWithFilter;
    }
    const startIndex = (page - 1) * MASS_PAYOUTS_PAGE_PER_PAGE;
    const endIndex = page * MASS_PAYOUTS_PAGE_PER_PAGE;

    return massPayoutsFilteredWithFilter.slice(startIndex, endIndex);
  }, [massPayoutsFilteredWithFilter, page]);

  const handleSearchChange = React.useCallback(debounce((value) => {
    setSearchText(value);
    setPage(1);
  }, 400), []);
  const handleFilterReset = React.useCallback(() => {
    setFilterData(null);
    setPage(1);
  }, []);
  const handleFilterSubmit = React.useCallback((data, setIsFilterActive) => {
    const hasData = Object.values(data).some((item) => Boolean(item));

    if (!hasData) {
      return;
    }
    setFilterData(data);
    setPage(1);

    if (!isDesktop && isFunction(setIsFilterActive)) {
      setIsFilterActive(false);
    }
  }, [isDesktop]);
  const reFetchWithdrawals = React.useCallback(async (page = 1, filter = null, filterData = null) => {
    setApiError(null);
    setIsFetching(true);
    const { data, status, errorMessage } = await fetchWithdrawalPayments({
      params: {
        type: 'mass_payouts',
        page,
        filter,
        dateFrom: filterData?.dateFrom,
        dateTo: filterData?.dateTo,
        status: filterData?.status,
      },
    });

    if (status === 200 && isObject(data)) {
      setMassPayoutData(data?.withdrawals ?? []);
      setIsFetching(false);
    } else {
      checkErrorCode(data?.errorData?.code);
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/withdrawal-payments');
      setApiError(errorDataMessage);
      setIsFetching(false);
    }
  }, []);

  const init = () => {
    void reFetchWithdrawals();
  };

  React.useEffect(() => {
    if (partnerFetched && isPartnerCustody) {
      void init();
    }
  }, [partnerFetched, isPartnerCustody]);

  const numberOfActiveFilters = useNumberOfActiveFilters(filterData);

  return {
    massPayoutsForExport: massPayoutsFilteredWithFilter,
    massPayoutsForPage: massPayoutsForPage,
    massPayoutsCount: massPayoutsFilteredWithFilter.length,
    isFetching,
    apiError,
    handleSearchChange,
    handleFilterReset,
    handleFilterSubmit,
    searchText,
    filterData,
    page,
    setPage,
    numberOfActiveFilters,
    reFetchWithdrawals,
  };
};
