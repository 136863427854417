import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Popup } from '../../../popup';
import { ManualPayoutForm } from '../manual-payout-form';
import classes from './styles.module.scss';

export const PopupManualPayout = (props) => {
  const {
    className,
    open,
    onClose,
    currencies,
    desktop,
    onSubmit,
    fetching,
    apiError,
  } = props;

  return (
    <Popup
      classNameContent={cn(classes.popupManualPayout, className)}
      title="Create mass payout"
      open={open}
      onClose={onClose}
      withCloseButton
      mobileFullView
      line
    >
      <div className={classes.description}>
        Please use the form below to create mass payout.
      </div>
      <ManualPayoutForm
        className={classes.popupForm}
        currencies={currencies}
        desktop={desktop}
        onSubmit={onSubmit}
        fetching={fetching}
        apiError={apiError}
      />
    </Popup>
  );
};

PopupManualPayout.defaultProps = {
  className: null,
  open: null,
  onClose: null,
  currencies: null,
  desktop: false,
  onSubmit: null,
  fetching: false,
  apiError: null,
};

PopupManualPayout.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  currencies: PropTypes.arrayOf(PropTypes.object),
  desktop: PropTypes.bool,
  onSubmit: PropTypes.func,
  fetching: PropTypes.bool,
  apiError: PropTypes.string,
};
