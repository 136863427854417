import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { CurrenciesSelect } from '../currencies-select';
import classes from './styles.module.scss';

export const CurrenciesSelectField = (props) => {
  const {
    className,
    label,
    Tooltip,
    placeholder,
    placeholderText,
    currencies,
    onSelect,
    error,
    defaultTicker,
    reset,
    rightElement,
    disabled,
    smallTheme,
    hasInnerMenu,
    isOnRampEnabled,
    withoutOtherCurrencies,
  } = props;

  return (
    <div className={cn([
      classes.currenciesSelectField,
      smallTheme && classes.currenciesSelectFieldSmallTheme,
      className,
    ])}>
      {label && (
        <div className={classes.currenciesSelectFieldHeader}>
          <div className={classes.currenciesSelectFieldLabel}>
            {label}
            {Tooltip && (
              <span className={classes.currenciesSelectFieldLabelTooltip}>
                {Tooltip}
              </span>
            )}
          </div>
          {rightElement && (
            <div className={classes.currenciesSelectFieldLabel}>
              {rightElement}
            </div>
          )}
        </div>
      )}
      <CurrenciesSelect
        className={classes.currenciesSelectFieldSelect}
        placeholder={placeholder}
        placeholderText={placeholderText}
        currencies={currencies}
        onSelect={onSelect}
        error={Boolean(error)}
        defaultSelectTicker={defaultTicker}
        reset={reset}
        disabled={disabled}
        smallTheme={smallTheme}
        hasInnerMenu={hasInnerMenu}
        isOnRampEnabled={isOnRampEnabled}
        withoutOtherCurrencies={withoutOtherCurrencies}
      />
      {error && (
        <div className={cn([
          classes.currenciesSelectError,
          error && classes.currenciesSelectErrorShow,
        ])}>
          <div className={classes.currenciesSelectErrorText}>
            {error}
          </div>
        </div>
      )}
    </div>
  );
};

CurrenciesSelectField.defaultProps = {
  className: null,
  label: null,
  Tooltip: null,
  placeholder: null,
  placeholderText: null,
  currencies: null,
  onSelect: null,
  error: null,
  defaultTicker: null,
  reset: false,
  rightElement: null,
  disabled: false,
  smallTheme: false,
  hasInnerMenu: false,
  isOnRampEnabled: false,
  withoutOtherCurrencies: false,
};

CurrenciesSelectField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  Tooltip: PropTypes.node,
  placeholder: PropTypes.string,
  placeholderText: PropTypes.string,
  currencies: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func,
  error: PropTypes.string,
  defaultTicker: PropTypes.string,
  reset: PropTypes.bool,
  rightElement: PropTypes.node,
  disabled: PropTypes.bool,
  smallTheme: PropTypes.bool,
  hasInnerMenu: PropTypes.bool,
  isOnRampEnabled: PropTypes.bool,
  withoutOtherCurrencies: PropTypes.bool,
};
