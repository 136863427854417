import * as React from 'react';
import { Helmet } from 'react-helmet';
import { CustomerPage } from '../../components/customers-pages';

export const Customer = () => (
  <>
    <Helmet>
      <title>Customer</title>
    </Helmet>
    <CustomerPage />
  </>
);
