import React from 'react';
import cn from 'classnames';
import { DotsIcon } from '../../../icons/dots-icon';
import ToolTip from '../../../tool-tip';
import { LoaderIcon } from '../../../icons/loader-icon';
import { hasElementsInArray } from '../../../../helpers/has-elements-in-array';
import { EmptySearch } from '../../../empty-search';
import { NewPagination } from '../../../shared/new-pagination';
import { ELEMENTS_PER_PAGE } from '../../helpers';
import { WhitelistTableType } from '../../types';
import classes from './styles.module.scss';

export const WhitelistTable: React.FC<WhitelistTableType> = (props) => {
  const {
    ips,
    fetching,
    fetched,
    error,
    onClick,
    page,
    onPage,
    ipsFilteredLength,
    hasSearchValue,
  } = props;

  const [addressId, setAddressId] = React.useState<string | null>(null);

  const handleButtonClick = (id: string) => () => {
    setAddressId(id);
  };
  const handleResetAddress = () => {
    setAddressId(null);
  };
  const handleTooltipClick = (address: string) => () => {
    onClick(address);
  };

  const loaderShown = !fetched && fetching;
  const errorShown = !fetching && error;
  const emptyBoxShown = fetched && !errorShown && !hasElementsInArray(ips);
  const isPaginationShow = ipsFilteredLength > ELEMENTS_PER_PAGE;

  return (
    <>
      <div className={classes.whitelistTable}>
        <div className={classes.whitelistTableHeader}>
          <div className={classes.whitelistTableTd}>№</div>
          <div className={classes.whitelistTableTd}>IP address</div>
          <div className={classes.whitelistTableTd}>Actions</div>
        </div>
        <div className={classes.whitelistTableBody}>
          {loaderShown && (
            <div className={classes.whitelistTableBodyLoader}>
              <LoaderIcon size={25} />
            </div>
          )}
          {errorShown && (
            <div className={classes.whitelistTableBodyError}>
              {error}
            </div>
          )}
          {emptyBoxShown && (
            <EmptySearch imagePath="/images/empty-search.svg">
              {!hasSearchValue ? 'No IP addresses added to Whitelist' : 'Sorry, no IP addresses were found'}
            </EmptySearch>
          )}
          {ips.map((ip) => (
            <div
              key={ip.id}
              className={cn([
                classes.whitelistTableTr,
                addressId === ip.id && classes.whitelistTableTrActive,
              ])}
            >
              <div className={`${classes.whitelistTableTd} ${classes.whitelistTableTdGrey}`}>{ip.number}</div>
              <div className={`${classes.whitelistTableTd} ${classes.whitelistTableTdAddress}`}>{ip.address}</div>
              <div className={classes.whitelistTableTd}>
                <button
                  type="button"
                  className={classes.whitelistTableTdButton}
                  data-tooltip-id="whitelist-row-tooltip"
                  data-tooltip-content={ip.address}
                  onClick={handleButtonClick(ip.id)}
                >
                  <DotsIcon />
                </button>
              </div>
            </div>
          ))}
        </div>
        <ToolTip
          id="whitelist-row-tooltip"
          className={classes.whitelistTableTooltip}
          place="bottom-end"
          offset={4}
          openOnClick
          clickable
          render={({ content }) => (
            <button
              type="button"
              className={classes.whitelistTableTooltipButton}
              onClick={handleTooltipClick(content)}
            >
              <img
                src="/images/settings-delete.svg"
                alt="Icon"
                decoding="async"
                loading="lazy"
                width={20}
                height={20}
              />
              Delete from Whitelist
            </button>
          )}
          afterHide={handleResetAddress}
        />
      </div>
      {isPaginationShow && (
        <NewPagination
          className={classes.whitelistTablePagination}
          currentPage={page}
          countItems={ipsFilteredLength}
          itemsPerPage={ELEMENTS_PER_PAGE}
          onChangePage={onPage}
        />
      )}
    </>
  );
};
