import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hasElementsInArray } from '../helpers/has-elements-in-array';
import { currenciesFetchingSelector, currenciesFetchedSelector } from '../store/currencies/selectors';
import {
  setCurrencyList,
  setAvailableCurrencies,
  setFiatCurrencies,
  setSelectedTickers,
  setFetching,
  setFetched,
} from '../store/currencies/reducer';
import { sortByKey } from '../helpers/sort-by-key';
import { partnerSelector } from '../store/partner/selectors';
import { useCoins } from '../api/modules/account/use-coins';
import { useFiatCurrencies } from '../api/modules/account/use-fiat-currencies';
import { useAvailableCurrencies } from '../api/modules/account/use-available-currencies';
import { useUpdateCoins } from '../api/modules/account/use-update-coins';
import { useAllFiatCurrencies } from '../api/modules/account/use-all-fiat-currencies';

export const useAllCurrencies = () => {
  const partner = useSelector(partnerSelector());
  const currenciesFetching = useSelector(currenciesFetchingSelector());
  const currenciesFetched = useSelector(currenciesFetchedSelector());
  const fetchAvailableCurrencies = useAvailableCurrencies();
  const fetchFiatCurrencies = useFiatCurrencies();
  const fetchAllFiatCurrencies = useAllFiatCurrencies();
  const fetchCoins = useCoins();
  const fetchUpdateCoins = useUpdateCoins();
  const dispatch = useDispatch();

  const getAvailableCurrencies = async () => {
    const { data, status } = await fetchAvailableCurrencies();

    return status === 200 && hasElementsInArray(data) ? data : [];
  };
  const getFiatCurrencies = async (isEnabled = false) => {
    if (!isEnabled) {
      return [];
    }

    const { data, status } = await fetchFiatCurrencies();

    return status === 200 && hasElementsInArray(data)
      ? data.sort(sortByKey('position'))
      : [];
  };
  const getAllFiatCurrencies = async () => {
    const { data, status } = await fetchAllFiatCurrencies();

    return status === 200 && hasElementsInArray(data)
      ? data.sort(sortByKey('position'))
      : [];
  };
  const getPartnerSelectedTickers = async () => {
    const { data, status } = await fetchCoins();

    if (status === 200 && hasElementsInArray(data?.selectedCurrencies)) {
      return data.selectedCurrencies;
    }

    return [];
  };

  const fetchAllCurrencies = React.useCallback(async () => {
    if (!partner || currenciesFetched || currenciesFetching) {
      return;
    }

    dispatch(setFetching(true));

    const isOnrampEnabled = partner?.isGuardarianEnabled || partner?.isOnrampEnabled;

    const [
      availableCurrenciesResponse,
      allFiatCurrenciesResponse,
      fiatCurrenciesResponse,
      partnerSelectedTickersResponse,
    ] = await Promise.allSettled([
      getAvailableCurrencies(),
      getAllFiatCurrencies(),
      getFiatCurrencies(isOnrampEnabled),
      getPartnerSelectedTickers(),
    ]);

    const availableCurrenciesValue = availableCurrenciesResponse?.value ?? [];
    const allFiatCurrenciesValue = allFiatCurrenciesResponse?.value ?? [];
    const currencyList = [...availableCurrenciesValue, ...allFiatCurrenciesValue];
    const fiatCurrenciesValue = fiatCurrenciesResponse?.value ?? [];
    const partnerSelectedTickersValue = partnerSelectedTickersResponse?.value ?? [];
    const hasPartnerSelectedTickers = hasElementsInArray(partnerSelectedTickersValue);
    let currentPartnerSelectedTickers = partnerSelectedTickersValue;

    if (!hasPartnerSelectedTickers) {
      const tickers = [...availableCurrenciesValue, ...fiatCurrenciesValue].map((coin) => coin.code);

      await fetchUpdateCoins(tickers);
      currentPartnerSelectedTickers = tickers;
    }

    dispatch(setCurrencyList(currencyList));
    dispatch(setAvailableCurrencies(availableCurrenciesValue));
    dispatch(setFiatCurrencies(fiatCurrenciesValue));
    dispatch(setSelectedTickers(currentPartnerSelectedTickers));
    dispatch(setFetching(false));
    dispatch(setFetched(true));
  }, [currenciesFetching, currenciesFetched, partner]);

  React.useEffect(() => {
    const timerId = setTimeout(fetchAllCurrencies);

    return () => clearTimeout(timerId);
  }, [fetchAllCurrencies]);
};
