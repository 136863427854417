import * as React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';
import { subscriptionPlanItemMapFn } from '../../helpers/account/map-fns';

const mapFn = (data) => ({
  result: subscriptionPlanItemMapFn(data?.result ?? {}),
});

export const useSubscriptionPlan = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (id) => {
    const [cancelToken] = getRequestSource();
    const requestPromise = client.get(
      `/subscriptions/plans/${id}`,
      null,
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
