import config from '../config';
import { safeToLowerCase } from './safe-to-lower-case';

/**
 * @param {string|null} network
 * @param {string} ticker
 * @returns {boolean}
 */
export const isCurrencyNetworkShow = (network, ticker) => {
  if (!network) {
    return false;
  }

  const networkLowerCased = safeToLowerCase(network);
  const tickerLowerCased = safeToLowerCase(ticker);

  return networkLowerCased !== tickerLowerCased || config.NETWORKS_TO_DISPLAY.includes(networkLowerCased);
};
