import React from 'react';
import PropTypes from 'prop-types';

export const CustodyConvertIcon = (props) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0013 11.5467C17.8764 11.6716 17.8062 11.841 17.8062 12.0177C17.811 13.2089 17.4213 14.369 16.6979 15.3154C15.9745 16.2618 14.9581 16.9421 13.8073 17.2501C12.6566 17.558 11.4363 17.4763 10.3369 17.0177C9.38063 16.6189 8.56264 15.9544 7.97707 15.1075L9.27393 15.3812C9.35962 15.4058 9.44939 15.4129 9.53794 15.4021C9.62955 15.391 9.71784 15.3609 9.79722 15.3138C9.8766 15.2668 9.94533 15.2037 9.99906 15.1287C10.0528 15.0536 10.0903 14.9683 10.1093 14.878C10.1283 14.7876 10.1283 14.6944 10.1094 14.6041C10.0904 14.5137 10.0529 14.4283 9.99918 14.3533C9.94548 14.2782 9.87677 14.2152 9.7974 14.1681C9.721 14.1227 9.63634 14.0932 9.5484 14.081L6.84159 13.4863C6.84159 13.4863 6.63704 13.4392 6.52102 13.4863C6.4849 13.5009 6.43355 13.5344 6.43355 13.5344L6.34287 13.5927L6.33464 13.5987C6.29535 13.627 6.26052 13.661 6.23126 13.6996C6.20712 13.7271 6.18588 13.7571 6.1679 13.789C6.11668 13.8798 6.11116 13.9575 6.09726 14.0078L5.5151 16.6829C5.49653 16.7686 5.49507 16.8572 5.51082 16.9435C5.52657 17.0298 5.55921 17.1121 5.60686 17.1858C5.65452 17.2594 5.71626 17.323 5.78853 17.3727C5.8608 17.4224 5.94218 17.4574 6.028 17.4756L6.0485 17.4799H6.16532C6.31937 17.4828 6.46968 17.4321 6.59062 17.3366C6.7122 17.2406 6.79667 17.1043 6.82917 16.9528L7.02752 16.0083C7.79732 17.0376 8.84831 17.8275 10.0584 18.2799C11.4159 18.7873 12.901 18.8426 14.2925 18.4377C15.684 18.0327 16.9075 17.189 17.7807 16.0324C18.6539 14.8758 19.1301 13.468 19.1384 12.0188V12.0177C19.1384 11.841 19.0682 11.6716 18.9433 11.5467C18.8184 11.4217 18.649 11.3516 18.4723 11.3516C18.2957 11.3516 18.1262 11.4217 18.0013 11.5467Z"
        fill="#64ACFF"
      />
      <path
        d="M6.63735 12.1594C6.76227 12.0345 6.83245 11.8651 6.83245 11.6884C6.82764 10.4972 7.21736 9.33707 7.94078 8.39065C8.66419 7.44424 9.68059 6.76396 10.8313 6.456C11.9821 6.14804 13.2024 6.22974 14.3018 6.68833C15.258 7.0872 16.076 7.75167 16.6616 8.59859L15.3647 8.32484C15.2791 8.30028 15.1893 8.29316 15.1007 8.30393C15.0091 8.31508 14.9208 8.34514 14.8415 8.39221C14.7621 8.43928 14.6933 8.50234 14.6396 8.57737C14.5859 8.65241 14.5483 8.73779 14.5293 8.8281C14.5104 8.91841 14.5103 9.01168 14.5293 9.102C14.5483 9.19232 14.5858 9.27771 14.6395 9.35276C14.6932 9.42782 14.7619 9.49089 14.8413 9.53799C14.9177 9.58332 15.0023 9.6129 15.0903 9.62502L17.7971 10.2198C17.7971 10.2198 18.0016 10.2668 18.1176 10.2198C18.1538 10.2051 18.2051 10.1717 18.2051 10.1717L18.2958 10.1133L18.304 10.1074C18.3433 10.079 18.3782 10.045 18.4074 10.0065C18.4316 9.97895 18.4528 9.94899 18.4708 9.9171C18.5191 9.83131 18.5414 9.6983 18.5414 9.6983L19.1236 7.02315C19.1421 6.93742 19.1436 6.84886 19.1279 6.76255C19.1121 6.67625 19.0795 6.59391 19.0318 6.52026C18.9842 6.44661 18.9224 6.3831 18.8501 6.33337C18.7779 6.28365 18.6965 6.24868 18.6107 6.2305L18.5902 6.22615H18.4734C18.3193 6.22328 18.169 6.27391 18.048 6.36946C17.9265 6.4655 17.842 6.60177 17.8095 6.75326L17.6112 7.69776C16.8414 6.66848 15.7904 5.87851 14.5803 5.42618C13.2228 4.91876 11.7377 4.86342 10.3462 5.2684C8.95466 5.67337 7.7312 6.517 6.858 7.67364C5.98479 8.83028 5.50857 10.238 5.50025 11.6872V11.6884C5.50025 11.8651 5.57043 12.0345 5.69535 12.1594C5.82026 12.2843 5.98969 12.3545 6.16635 12.3545C6.34301 12.3545 6.51243 12.2843 6.63735 12.1594Z"
        fill="#64ACFF"
      />
    </svg>
  );
};

CustodyConvertIcon.defaultProps = {
  className: null,
};

CustodyConvertIcon.propTypes = {
  className: PropTypes.string,
};
