import React from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { setAuthToken, setMasterId } from '../../../../libs/local-storage';
import { setAuthCookieToken } from '../../../../libs/cookies';
import config from '../../../../config';
import { isFunction } from '../../../../helpers/is-function';
import classes from './styles.module.scss';

const DEFAULT_TEAM_ITEM_TEXT = 'Space granted by';

const SelectTeam = (props) => {
  const {
    email,
    token,
    partnerId,
    onBack,
  } = props;

  const [myTeams, setMyTeams] = React.useState([]);

  const handleSelectTeamClick = (masterId) => {
    setMasterId(masterId);
    setAuthToken(token);
    setAuthCookieToken(token);
    window.location.reload();
  };
  const handleBackClick = () => {
    if (isFunction(onBack)) {
      onBack();
    }
  };

  React.useEffect(() => {
    async function fetchMyTeams() {
      try {
        const { data } = await axios.get(`${config.api_domain}/team-signin-list`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'X-Temp-Token': config.X_TEMP_TOKEN,
          },
        });

        setMyTeams(data);
      } catch {
        //ignore
      }
    }

    if (token && token.length !== 0) {
      fetchMyTeams();
    }
  }, [token]);

  return (
    <div>
      <div className={classes.formHeader}>
        <p className={classes.logo}>
          <span className={classNames(classes.logo, classes.blueText)}>NOW</span>
          Payments
        </p>
      </div>
      <div className={classes.divider} />
      <h3 className={classes.title}>Access to multiuser</h3>
      <p className={classes.subtitle}>Select the team to access</p>
      <ul className={classes.teamList}>
        <li
          className={classes.teamItem}
          onClick={() => handleSelectTeamClick(partnerId)}
        >
          {`My Account(${email})`}
          <FontAwesomeIcon
            icon={faArrowRight}
            size="1x"
            className={classes.arrowIcon}
            color="#64ACFF"
          />
        </li>
        {myTeams.map((team, index) => (
          <li
            key={index}
            className={classes.teamItem}
            onClick={() => handleSelectTeamClick(team.master_id)}
          >
            {team['Master.company_name'] || `${DEFAULT_TEAM_ITEM_TEXT} ${team['Master.email'] || ''}`}
            <FontAwesomeIcon
              icon={faArrowRight}
              size="1x"
              className={classes.arrowIcon}
              color="#64ACFF"
            />
          </li>
        ))}
      </ul>
      <div
        className={classes.link}
        onClick={handleBackClick}
        tabIndex={0}
        role="button"
      >
        Back to NOWPayments
      </div>
    </div>
  );
};

export default SelectTeam;
