import * as React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from '../../../../helpers/format-date';
import { isFunction } from '../../../../helpers/is-function';
import { DATE_PATTERN } from '../../../../constants/app-constants';
import { getAmountWithTicker } from '../../../../helpers/get-amount-with-ticker';
import { toFixed } from '../../../../helpers/to-fixed';

export const CustomersTableRow = (props) => {
  const {
    className,
    onClick,
    id,
    amount,
    ticker,
    name,
    updatedAt,
    isDesktop,
  } = props;

  const idNormalized = id || null;
  const amountFixed = toFixed(amount, 2);
  const amountWithTicker = getAmountWithTicker(amountFixed, ticker);
  const amountWithTickerNormalized = amount ? `~ ${amountWithTicker}` : amountWithTicker;
  const nameNormalized = name || '-';
  const updatedDateFormatted = formatDate(updatedAt, DATE_PATTERN);
  const updatedDateNormalized = updatedDateFormatted || '-';

  const handleRowClick = () => {
    if (isFunction(onClick)) {
      onClick(id);
    }
  };

  return (
    <tr
      className={className}
      onClick={handleRowClick}
    >
      <td>
        <div>
          {idNormalized}
        </div>
        {!isDesktop && (
          <div data-color="black">
            {amountWithTickerNormalized}
          </div>
        )}
      </td>
      {isDesktop && (
        <td data-color="black">
          {amountWithTickerNormalized}
        </td>
      )}
      <td data-color="black">
        <div>
          {nameNormalized}
        </div>
      </td>
      <td>
        {updatedDateNormalized}
      </td>
    </tr>
  );
};

CustomersTableRow.defaultProps = {
  className: null,
  onClick: null,
  id: null,
  name: null,
  amount: null,
  ticker: null,
  updatedAt: null,
  isDesktop: false,
};

CustomersTableRow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
  amount: PropTypes.number,
  ticker: PropTypes.string,
  updatedAt: PropTypes.string,
  isDesktop: PropTypes.bool,
};
