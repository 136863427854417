import * as React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import { HistoryTableMemo } from '../../../history-table';
import { NewPagination } from '../../../shared/new-pagination';
import {
  SUBSCRIPTION_PLAN_PAGE_PER_PAGE,
  SUBSCRIPTION_PLAN_PAGE_THS_DESKTOP,
  SUBSCRIPTION_PLAN_PAGE_THS_MOBILE,
} from '../../helpers';
import { Button } from '../../../ui/button';
import { SubscriptionPlanTableRow } from '../subscription-plan-table-row';
import classes from './styles.module.scss';

export const SubscriptionPlanTable = (props) => {
  const {
    items,
    hasItems,
    hasSearchData,
    loading,
    onClick,
    onButtonClick,
    count,
    onPaginatorClick,
    page,
    reFetching,
    apiError,
  } = props;

  const isDesktop = useMediaQuery('(min-width: 1200px)');
  const thItems = isDesktop
    ? SUBSCRIPTION_PLAN_PAGE_THS_DESKTOP
    : SUBSCRIPTION_PLAN_PAGE_THS_MOBILE;
  const isPaginationShow = count > SUBSCRIPTION_PLAN_PAGE_PER_PAGE;

  return (
    <>
      <div className={classes.subscriptionPlanTableBox}>
        <HistoryTableMemo
          className={classes.subscriptionPlanTable}
          thItems={thItems}
          colSpan={4}
          loading={loading || reFetching}
          historyData={items}
          hasData={hasSearchData || hasItems}
          emptyText="No subscribers yet."
          emptyElement={(
            <div className={classes.subscriptionPlanTableButton}>
              <Button onClick={onButtonClick}>
                Invite users
              </Button>
            </div>
          )}
          notFoundText="No subscribers matching this criteria."
          apiError={apiError}
        >
          {hasItems && items.map((item) => (
            <SubscriptionPlanTableRow
              key={item?.id}
              id={item?.id}
              email={item?.subscriber?.email}
              active={item?.isActive}
              createdAt={item?.createdAt}
              updatedAt={item?.updatedAt}
              isDesktop={isDesktop}
              onClick={onClick}
            />
          ))}
        </HistoryTableMemo>
      </div>
      {isPaginationShow && (
        <NewPagination
          className={classes.paginator}
          currentPage={page}
          countItems={count}
          itemsPerPage={SUBSCRIPTION_PLAN_PAGE_PER_PAGE}
          onChangePage={onPaginatorClick}
        />
      )}
    </>
  );
};

SubscriptionPlanTable.defaultProps = {
  items: null,
  hasUsers: false,
  hasSearchData: false,
  loading: false,
  onClick: null,
  onButtonClick: null,
  count: 0,
  onPaginatorClick: null,
  page: 1,
  reFetching: false,
  apiError: null,
};

SubscriptionPlanTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  hasUsers: PropTypes.bool,
  hasSearchData: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  onButtonClick: PropTypes.func,
  count: PropTypes.number,
  onPaginatorClick: PropTypes.func,
  page: PropTypes.number,
  reFetching: PropTypes.bool,
  apiError: PropTypes.string,
};
