import React from "react";

const TfaTEmailSetup = () => {
  return (
    <>
      <h2>Email 2 step authentification</h2>
      <p>
        We sent a verification link to you email , please follow the link to
        continue setup.
      </p>
    </>
  );
};

export default TfaTEmailSetup;
