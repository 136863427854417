import { safeToUpperCase } from '../../../helpers/safe-to-upper-case';
import { formatDate } from '../../../helpers/format-date';
import { DATE_PATTERN } from '../../../constants/app-constants';
import { getArrayWithIndexId } from '../../../helpers/get-array-with-index-id';
import { getCurrentNetworkForShow } from '../../../helpers/get-current-network-for-show';

/**
 * @param {Object} data
 * @param {Object} currenciesObject
 * @returns {Array<object>}
 */
export const getBillingPlanInfoData = (data, currenciesObject) => {
  const id = data?.id ?? null;
  const title = data?.title ?? '-';
  const amount = data?.amount ?? null;
  const ticker = data?.currency ?? '';
  const tickerUpperCased = safeToUpperCase(ticker);
  const coinFound = currenciesObject[tickerUpperCased] ?? {};
  const currentTicker = coinFound?.ticker ?? tickerUpperCased;
  const amountWithTicker = amount ? `${amount} ${currentTicker}` : '-';
  const currentNetwork = getCurrentNetworkForShow(coinFound?.network, ticker);
  const period = data?.intervalDay ?? null;
  const currentPeriodName = period === 1 ? 'Day' : 'Days';
  const periodNormalized = period ? `${period} ${currentPeriodName}` : '-';
  const createdAt = data?.createdAt ?? null;
  const createdDateFormatted = formatDate(createdAt, DATE_PATTERN);
  const createdDateNormalized = createdDateFormatted || '-';

  const defaultParams = {
    isTextBlack: false,
    textWithTitle: false,
    element: null,
    isWordBreak: false,
    network: null,
  };

  return getArrayWithIndexId([{
    text: id,
    ...defaultParams,
  }, {
    text: title,
    ...defaultParams,
    isTextBlack: true,
    isWordBreak: true,
  }, {
    text: amountWithTicker,
    ...defaultParams,
    isTextBlack: true,
    network: currentNetwork,
  }, {
    text: periodNormalized,
    ...defaultParams,
    isTextBlack: true,
  }, {
    text: createdDateNormalized,
    ...defaultParams,
    isTextBlack: true,
  }]);
};
