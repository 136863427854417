export const PER_PAGE = 20;

export const THS_DESKTOP = [{
  id: 1,
  name: 'Payment ID',
}, {
  id: 2,
  name: 'Order ID',
}, {
  id: 3,
  name: 'Original price',
}, {
  id: 4,
  name: 'Amount sent / received',
}, {
  id: 5,
  name: 'Status',
}, {
  id: 6,
  name: 'Created / Last update',
}];

export const THS_MOBILE = [{
  id: 1,
  name: 'Payment ID / <br />  Order ID',
}, {
  id: 2,
  name: 'Amount sent / <br /> received',
}, {
  id: 3,
  name: 'status',
}];
