import * as React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import {
  BILLING_PLAN_PAGE_PER_PAGE,
  BILLING_PLAN_PAGE_THS_DESKTOP,
  BILLING_PLAN_PAGE_THS_MOBILE,
} from '../../helpers';
import { BillingPlanUsersTableRow } from '../billing-plan-users-table-row';
import { HistoryTableWithPagination } from '../../../history-table-with-pagination';
import { hasElementsInArray } from '../../../../helpers/has-elements-in-array';
import classes from './styles.module.scss';

const EMPTY_TEXT = 'No active customers for chosen plan.';
const NOT_FOUND_TEXT = 'No customers matching this criteria.';

export const BillingPlanUsersTable = (props) => {
  const {
    items,
    hasSearchData,
    onClick,
    count,
    onPaginatorClick,
    page,
    fetching,
    apiError,
  } = props;

  const hasItems = hasElementsInArray(items);
  const isDesktop = useMediaQuery('(min-width: 768px)');

  return (
    <HistoryTableWithPagination
      className={classes.billingPlanTable}
      items={items}
      hasSearchData={hasSearchData}
      fetching={fetching}
      apiError={apiError}
      minWidth={400}
      desktop={isDesktop}
      thsDesktop={BILLING_PLAN_PAGE_THS_DESKTOP}
      thsMobile={BILLING_PLAN_PAGE_THS_MOBILE}
      perPage={BILLING_PLAN_PAGE_PER_PAGE}
      emptyText={EMPTY_TEXT}
      notFoundText={NOT_FOUND_TEXT}
      count={count}
      onPaginatorClick={onPaginatorClick}
      page={page}
    >
      {hasItems && items.map((item) => (
        <BillingPlanUsersTableRow
          key={item?.subscriber?.subPartnerId}
          subPartnerId={item?.subscriber?.subPartnerId}
          active={item?.isActive}
          createdAt={item?.createdAt}
          updatedAt={item?.updatedAt}
          isDesktop={isDesktop}
          onClick={onClick}
        />
      ))}
    </HistoryTableWithPagination>
  );
};

BillingPlanUsersTable.defaultProps = {
  items: null,
  hasSearchData: false,
  onClick: null,
  count: 0,
  onPaginatorClick: null,
  page: 1,
  fetching: false,
  apiError: null,
};

BillingPlanUsersTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  hasSearchData: PropTypes.bool,
  onClick: PropTypes.func,
  count: PropTypes.number,
  onPaginatorClick: PropTypes.func,
  page: PropTypes.number,
  fetching: PropTypes.bool,
  apiError: PropTypes.string,
};
