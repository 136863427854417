import React from 'react';
import ValidatorWrapper, { ValidatorField } from '@coxy/react-validator';
import classNames from 'classnames';
import root from '../styles.module.scss';
import apiClient from '../../../api/api-client';
import { setAuthToken } from '../../../libs/local-storage';
import { setAuthCookieToken } from '../../../libs/cookies';
import { verificationCodeRules } from '../../../libs/validators';
import WithdrawalInput from '../../../components/withdrawal-input';
import Button from '../../../components/button';
import Metatags from '../../../components/metatags';
import { trackEvent } from '../../../helpers/utils/track-event';
import { TRACK_EVENT_NAMES } from '../../../constants/track-event-names';
import styles from './styles.module.scss';

const QUERY_PARAMS_NAME = 'queryParams';

const EmailVerification = () => {
  let timeOut = React.useRef(null);
  const validator = React.useRef();

  const [verificationCode, setVerificationCode] = React.useState('');
  const [submitButtonPressed, setSubmitButtonPressed] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [serverError, setServerError] = React.useState('');
  const [isErrorShown, setIsErrorShow] = React.useState(false);
  const [errorServerOnInput, setErrorServerOnInput] = React.useState('');
  const [firstSendCode, setFirstSendCode] = React.useState(true);
  const [isSent, setIsSent] = React.useState(false);

  const timerSend =  () => {
    if (firstSendCode) {
      return;
    }
    setIsSent(true);
    timeOut = setTimeout(()=>{
      setIsSent(false);
    }, 2000);
  };

  const onSubmit = async() => {
    setSubmitButtonPressed(true);

    const { isValid } = validator.current.validate();

    if (!isValid) {
      return;
    }

    setIsLoading(true);

    try {
      const result = await apiClient.verifyLoginCode(verificationCode);
      const { token } = result;

      setAuthToken(token);
      setAuthCookieToken(token);

      const searchParams = new URLSearchParams(window.location.search);
      const queryParamsString = searchParams.toString();
      const utmEventCategory = searchParams.get('utm_category');
      const affiliateLinkId = searchParams.get('link_id');
      const hasQueryParams = sessionStorage.getItem(QUERY_PARAMS_NAME);

      const trackEventData = {
        action: 'submit',
        category: utmEventCategory,
      };
      if (affiliateLinkId) {
        trackEventData.affiliate_from = affiliateLinkId;
      }
      trackEvent(TRACK_EVENT_NAMES.SIGN_UP_EMAIL_CONFIRMED, trackEventData);

      if (queryParamsString) {
        sessionStorage.setItem(QUERY_PARAMS_NAME, window.location.search);
      }

      if (!queryParamsString && hasQueryParams) {
        sessionStorage.removeItem(QUERY_PARAMS_NAME);
      }

      window.location.reload();
    } catch (e) {

      if (e.response && e.response.data && e.response.data.message) {
        setErrorServerOnInput(e.response.data.message);
      } else {
        setServerError("Server error");
        setIsErrorShow(true);
      }
    }

    setIsLoading(false);
  };

  const sendCode = async() => {
    setIsLoading(true);
    try {
      await apiClient.sendLoginCode({ verificationCode });
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        if (e.response.data.message === 'Invalid verification code') {
          setErrorServerOnInput(e.response.data.message);
        } else {
          setServerError(e.response.data.message);
        }
      }
    }

    if (firstSendCode) {
      setFirstSendCode(false);
    }

    setIsLoading(false);

    await timerSend();

    if (timeOut.current) {
      clearTimeout(timeOut.current);
    }
  };

  const clearErrorState = () => {
    setSubmitButtonPressed(false);
    setServerError('');
    setIsErrorShow(false);
    setErrorServerOnInput('');
  };

  const changeValue = (e) => {
    setVerificationCode(e.target.value);
    clearErrorState();
  };

  React.useEffect(() => {
    sendCode();
  }, []);

  return (
    <div className={root.formContainer}>
      <Metatags
        title="Email verification | NOWPayments"
        description="Please provide the email address that you used when you signed up for your NOWPayments account. We will send you an email that will allow you to reset your password."
        url="https://account.nowpayments.io/email-varification"
      />
      <div className={root.form}>
        <div className={root.formHeader}>
          <p className={root.logo}>
            <span className={classNames(root.logo, root.blueText)}>NOW</span>
            Payments
          </p>
          <p className={root.stepsCount}>Step 2 of 3</p>
        </div>
        <div className={root.divider} />
        <div className={styles.dividerContent}>
          <p className={root.titleBig}>Email confirmation</p>
        </div>
        <div className={styles.dividerContent}>
          <p className={root.titleSmall}>Please enter the verification code</p>
          <p className={root.titleSmall}>we sent to your email address</p>
        </div>
        <ValidatorWrapper ref={validator}>
          {(isErrorShown && submitButtonPressed) && (
            <div className={root.serverError}>
              <span>{serverError}</span>
            </div>
          )}
          <ValidatorField value={verificationCode} rules={verificationCodeRules()}>
            {({ isValid, message }) => (
              <div className={root.inputContainer}>
                <WithdrawalInput
                  label="Enter the 6-digit code"
                  value={verificationCode}
                  placeholder="e.g.: 123456"
                  onChange={(e) => changeValue(e)}
                  errorMessage={(
                    !isValid && submitButtonPressed && message) || (errorServerOnInput.length > 1 && errorServerOnInput
                  )}
                />
              </div>
            )}
          </ValidatorField>
        </ValidatorWrapper>
        <div className={styles.footer}>
          <Button onClick={onSubmit} loading={isLoading}>
            Submit
          </Button>
          <div className={root.divider} />
          <p className={styles.resendText}>Didn&#39;t get the code? <span className={isSent ? styles.sent : styles.resend} onClick={sendCode}>{isSent ? 'Sent' : 'Resend'}</span></p>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
