import { ROUTES } from '../../../constants/routes';
import { STATUSES } from '../../../constants/app-constants';

export const INNER_NAVIGATION_ITEMS = [{
  id: '1',
  href: ROUTES.CUSTOMERS,
  name: 'Customers',
}, {
  id: '2',
  href: ROUTES.CUSTOMERS_TRANSACTIONS,
  name: 'Transactions',
}, {
  id: '3',
  href: ROUTES.CUSTOMERS_BILLING_PLANS,
  name: 'Billing Plans',
}];

export const TRANSACTION_STATUS_ITEMS = [
  { key: STATUSES.WAITING, value: 'Waiting' },
  { key: STATUSES.PROCESSING, value: 'Processing' },
  { key: STATUSES.FINISHED, value: 'Finished' },
  { key: STATUSES.FAILED, value: 'Failed' },
  { key: STATUSES.REJECTED, value: 'Rejected' },
];

export const CUSTOMERS_PAGE_PER_PAGE = 20;
export const TRANSACTIONS_AND_PAYMENTS_PER_PAGE = 20;
export const EMPTY_TRANSACTIONS_TEXT = 'You have no transactions yet';
export const NOT_FOUND_TRANSACTIONS_TEXT = 'No transactions matching this criteria.';
export const EMPTY_PAYMENTS_TEXT = 'You don’t have any complete payments yet.';
export const NOT_FOUND_PAYMENTS_TEXT = 'No payments matching this criteria.';
export const TRANSACTIONS_TAB = {
  KEY: 'transactions-key',
  VALUE: 'Transaction history',
};
export const PAYMENTS_TAB = {
  KEY: 'payments-key',
  VALUE: 'Payment history',
};
export const CUSTOMERS_PAGE_THS_DESKTOP = [{
  id: 1,
  name: 'Customer ID',
  tooltipText: null,
}, {
  id: 2,
  name: 'Balance',
  tooltipText: 'This is an estimated balance of your customer in USD.',
}, {
  id: 3,
  name: 'Customer name',
  tooltipText: null,
}, {
  id: 4,
  name: 'Last transaction',
  tooltipText: null,
}];

export const CUSTOMERS_PAGE_THS_MOBILE = [{
  id: 1,
  name: 'Customer ID /<br /> Balance',
  tooltipText: 'This is an estimated balance of your customer in USD.',
}, {
  id: 2,
  name: 'Customer name',
  tooltipText: null,
}, {
  id: 3,
  name: 'Last transaction',
  tooltipText: null,
}];

export const CUSTOMER_FORM_NAMES = {
  DEPOSIT: 'DEPOSIT',
  WITHDRAW: 'WITHDRAW',
  CREATE_BILLING_PLAN: 'CREATE_BILLING_PLAN',
  SAVE_BILLING_PLAN: 'SAVE_BILLING_PLAN',
  ACTIVE_BILLING_PLAN: 'ACTIVE_BILLING_PLAN',
};
