import React from 'react';
import { safeToLowerCase } from '../../../helpers/safe-to-lower-case';
import { BALANCE_ELEMENTS_PER_PAGE } from '../custody-page-helpers';

export const useFilteredBalancesData = (balances) => {
  const [searchValue, setSearchValue] = React.useState('');
  const [page, setPage] = React.useState(1);

  const balancesFiltered = React.useMemo(() => {
    if (!searchValue) return balances;

    return balances.filter((balance) => {
      const isTickerMatches = safeToLowerCase(balance.code).includes(safeToLowerCase(searchValue));
      const isNameMatches = safeToLowerCase(balance.name).includes(safeToLowerCase(searchValue));
      const isCurrentTickerMatches = safeToLowerCase(balance.ticker).includes(safeToLowerCase(searchValue));

      return isTickerMatches || isNameMatches || isCurrentTickerMatches;
    });
  }, [searchValue, balances]);
  const balancesFilteredLength = React.useMemo(() => balancesFiltered?.length ?? 0, [balancesFiltered]);
  const balancesSliced = React.useMemo(() => {
    if (balancesFilteredLength <= BALANCE_ELEMENTS_PER_PAGE) {
      return balancesFiltered;
    }

    const startIndex = (page - 1) * BALANCE_ELEMENTS_PER_PAGE;
    const endIndex = page * BALANCE_ELEMENTS_PER_PAGE;

    return balancesFiltered.slice(startIndex, endIndex);
  }, [page, balancesFiltered, balancesFilteredLength]);

  const handleSearchChange = (value) => {
    setSearchValue(value);
  };
  const handlePageChange = (value) => {
    setPage(value);
  };

  return {
    currentBalances: balancesSliced,
    balancesFilteredLength,
    handleBalanceSearchChange: handleSearchChange,
    handleBalancePageChange: handlePageChange,
    balancePage: page,
  };
};
