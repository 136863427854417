import React from 'react';
import { ResponseType, Wallet } from '../../../default-types';
import { useCheckWalletsWhitelist } from '../../../api/modules/account/use-check-wallets-whitelist';
import { safeToLowerCase } from '../../../helpers/safe-to-lower-case';
import { getSafeErrorMessageText } from '../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../helpers/console-error-message';
import { WalletWhitelistData } from '../../../api/helpers/types';
import { ExtendedWallet } from '../types';

export const useWhitelistWallets = (wallets: Array<Wallet> | null, isUpdate: boolean) => {
  const fetchCheckWalletsWhitelistRequestSend = useCheckWalletsWhitelist();

  const [walletsWhitelist, setWalletsWhitelist] = React.useState<Array<ExtendedWallet>>([]);
  const [isWhitelistDisabled, setIsWhitelistDisabled] = React.useState(false);
  const [isWhitelistError, setIsWhitelistError] = React.useState(false);

  const fetchCheckWalletsWhitelist = React.useCallback(async (currentWallets: Array<Wallet>) => {
    const walletsData = currentWallets.map((wallet) => ({
      currency: safeToLowerCase(wallet.baseCurrency),
      address: wallet.baseAddress,
    }));

    const {
      data,
      status,
      errorMessage,
    }: ResponseType = await fetchCheckWalletsWhitelistRequestSend(walletsData);

    if (status === 200 && data) {
      const walletsResponse = data.result?.map((el: WalletWhitelistData, i: number) => ({
        ...currentWallets[i],
        whitelistData: el,
      }));
      setIsWhitelistDisabled(data.result[0]?.whitelist === 'disabled');
      setWalletsWhitelist(walletsResponse);
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/whitelist/status');
      setIsWhitelistError(true);
    }
  }, [wallets]);

  React.useEffect(() => {
    if (wallets && isUpdate) {
      fetchCheckWalletsWhitelist(wallets);
    }
  }, [isUpdate]);

  return {
    walletsWhitelist,
    isWhitelistDisabled,
    isWhitelistError,
  };
};
