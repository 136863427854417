import React from 'react';
import { SettingTableWrapper } from '../setting-table-wrapper';
import { ApiKeysTable } from './components/api-keys-table';
import { ApiKeysPopup } from './components/api-keys-popup';
import {
  useActions,
  useInitState,
  useFilteredApiKeysData,
} from './hooks';
import classes from './styles.module.scss';

type ApiKeysSettingsProps = {
  className: string,
};

export const ApiKeysSettings: React.FC<ApiKeysSettingsProps> = (props) => {
  const { className } = props;

  const {
    activeApiKeys,
    activeApiKeysLength,
    addWalletAndApiKeyShow,
    isFetched,
    isFetching,
    error,
    currencies,
  } = useInitState();

  const {
    currentApiKeys,
    handlePageChange,
    page,
  } = useFilteredApiKeysData(activeApiKeys);

  const {
    popupType,
    selectedApiKey,
    handlePopupClose,
    resetSelectedApiKey,
    handlePopupOpen,
    handleAddApiKeyPopupOpen,
    handleAddWalletPopupOpen,
    handleOptionsClick,
    ref,
    newApiKeyData,
    getApiKey,
    saveNewApiKey,
    isNeedGenerateNewKey,
    isCreatingApiKey,
  } = useActions(activeApiKeys);

  return (
    <div className={className} ref={ref}>
      <h2 className={classes.apiKeysSettingsTitle}>
        API keys
      </h2>
      <SettingTableWrapper
        className={classes.apiKeysSettingsWrapper}
        onClick={handleAddApiKeyPopupOpen}
        buttonLabel="Add new API key"
        isNotActivated={addWalletAndApiKeyShow}
        buttonTwoLabel="Explore API docs"
        buttonTwoLink="https://documenter.getpostman.com/view/7907941/2s93JusNJt"
        buttonTwoTarget="_blank"
        buttonTwoRel="nofollow noopener noreferrer"
      >
        <ApiKeysTable
          keys={currentApiKeys}
          keysLength={activeApiKeysLength}
          onOptionsReset={resetSelectedApiKey}
          onPopupOpen={handlePopupOpen}
          onAddWalletClick={handleAddWalletPopupOpen}
          onOptionsClick={handleOptionsClick}
          selectedApiKey={selectedApiKey}
          page={page}
          onPage={handlePageChange}
          addWalletAndApiKeyShow={addWalletAndApiKeyShow}
          isFetched={isFetched}
          isFetching={isFetching}
          error={error}
        />
      </SettingTableWrapper>
      <ApiKeysPopup
        type={popupType}
        currencies={currencies}
        onClose={handlePopupClose}
        selectedApiKey={selectedApiKey}
        isLastApiKey={activeApiKeys.length === 1}
        newApiKey={newApiKeyData}
        onAddNewKey={getApiKey}
        onAddNewWallet={saveNewApiKey}
        isNeedGenerateNewKey={isNeedGenerateNewKey}
        isCreatingApiKey={isCreatingApiKey}
      />
    </div>
  );
};
