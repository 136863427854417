import * as React from 'react';
import PropTypes from 'prop-types';
import { Popup } from '../../../popup';
import { Field } from '../../../ui/field';
import { Button } from '../../../ui/button';
import { LoaderIcon } from '../../../icons/loader-icon';
import { isFunction } from '../../../../helpers/is-function';
import classes from './styles.module.scss';

export const PopupCreateUsers = (props) => {
  const {
    className,
    open,
    onClose,
    onSubmit,
    errorApiMessage,
    errorMessage,
    fetching,
    sent,
  } = props;

  const [usersValue, setUsersValue] = React.useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isFunction(onSubmit)) {
      onSubmit(usersValue);
    }
  };

  React.useEffect(() => {
    if (sent) {
      setUsersValue('');
    }
  }, [sent]);

  const buttonText = sent ? 'Sent' : 'Send invitations';

  return (
    <Popup
      classNameContent={className}
      open={open}
      onClose={onClose}
      withCloseButton
      mobileFullView
    >
      <form className={classes.box} onSubmit={handleSubmit}>
        <div className={classes.title}>
          Add subscribers
        </div>
        <div className={classes.hr} />
        <div className={classes.text}>
          Enter a list of emails separated by commas. We&apos;ll send them a link to subscribe.
        </div>
        <Field
          className={classes.field}
          title="Users"
          data={{
            value: usersValue,
            onChange: setUsersValue,
            placeholder: 'Enter emails separated with commas',
            error: errorMessage,
            disabled: sent,
          }}
        />
        <div className={classes.error}>
          {errorApiMessage}
        </div>
        <Button
          type="submit"
          className={classes.button}
          disabled={fetching || sent}
        >
          {fetching
            ? <LoaderIcon path="/images/loader-white-on-blue-icon.gif" />
            : buttonText}
        </Button>
      </form>
    </Popup>
  );
};

PopupCreateUsers.defaultProps = {
  className: null,
  open: null,
  onClose: null,
  onSubmit: null,
  errorApiMessage: null,
  errorMessage: null,
  fetching: false,
  sent: false,
};

PopupCreateUsers.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  errorApiMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  fetching: PropTypes.bool,
  sent: PropTypes.bool,
};
