import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isFunction } from '../../../../helpers/is-function';
import { SimpleSelectField } from '../../../ui/simple-select-field';
import { Field } from '../../../ui/field';
import { Button } from '../../../ui/button';
import { INVOICE_FILTERS_SCHEMA } from '../../utils';
import { CurrenciesSelect } from '../../../currencies-select';
import styles from './styles.module.scss';

const CREATED_ITEMS = [
  { key: '', value: 'All days' },
  { key: '7', value: 'Last 7 days' },
  { key: '30', value: 'Last 30 days' },
  { key: '90', value: 'Last 3 months' },
];
const FIX_RATE = [
  { key: null, value: 'None' },
  { key: 'true', value: 'True' },
  { key: 'false', value: 'False' },
];
const FEE_PAID_BY_USER = [
  { key: null, value: 'None' },
  { key: 'true', value: 'True' },
  { key: 'false', value: 'False' },
];

const InvoiceFiltersForm = (props) => {
  const {
    className,
    onSubmit: onSubmitCallback,
    onClose,
    onSearchFormData,
    currencies,
  } = props;

  const {
    control,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(INVOICE_FILTERS_SCHEMA),
  });

  const [isResetDate, setIsResetDate] = React.useState(false);

  const handleFormSubmit = (data) => {
    setIsResetDate(false);

    if (isFunction(onSubmitCallback)) {
      onSubmitCallback(data);
    }
  };
  const handleResetForm = () => {
    setIsResetDate(true);
    if (isFunction(reset)) {
      reset();
    }
    if (isFunction(onSearchFormData)) {
      onSearchFormData(null);
    }
  };
  const handleClose = () => {
    if (isFunction(onClose)) {
      onClose();
    }
  };
  const handleCurrencySelect = (func) => (currency) => {
    const ticker = currency?.code ?? '';

    func(ticker);
  };

  return (
    <form
      className={cn(styles.filters, className)}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <div className={styles.fields}>
        <Controller
          name="paymentLinkId"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Field
              className={styles.filterInput}
              title="Payment link ID"
              data={{
                id: field.name,
                value: field.value,
                onChange: field.onChange,
                name: field.name,
              }}
            />
          )}
        />
        <Controller
          name="createdAt"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <SimpleSelectField
              className={styles.filterInput}
              title="Created at"
              data={{
                id: field.name,
                items: CREATED_ITEMS,
                onSelect: field.onChange,
                defaultValue: 'All days',
                reset: isResetDate,
              }}
            />
          )}
        />
        <Controller
          name="orderId"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Field
              className={styles.filterInput}
              title="Order ID"
              data={{
                id: field.name,
                value: field.value,
                onChange: field.onChange,
                name: field.name,
              }}
            />
          )}
        />
        <Controller
          name="currency"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <div className={cn(styles.filterInput, styles.filterInputCurrency)}>
              <div className={styles.filterInputCurrencyLabel}>
                Currency
              </div>
              <CurrenciesSelect
                className={styles.filterInputCurrencySelect}
                currencies={currencies}
                onSelect={handleCurrencySelect(field.onChange)}
                reset={isResetDate}
                smallTheme
              />
            </div>
          )}
        />
        <Controller
          name="paymentId"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Field
              className={styles.filterInput}
              title="Payment ID"
              data={{
                id: field.name,
                value: field.value,
                onChange: field.onChange,
                name: field.name,
              }}
            />
          )}
        />
        <Controller
          name="fixedRate"
          control={control}
          defaultValue={null}
          render={({ field }) => (
            <SimpleSelectField
              className={cn(styles.filterInput, styles.filterFixRate)}
              title="Fixed rate"
              data={{
                id: field.name,
                items: FIX_RATE,
                onSelect: field.onChange,
                defaultValue: 'None',
                reset: isResetDate,
              }}
            />
          )}
        />
        <Controller
          name="feePaidByUser"
          control={control}
          defaultValue={null}
          render={({ field }) => (
            <SimpleSelectField
              className={cn(styles.filterInput, styles.filterFeePaid)}
              title="Fee paid by user"
              data={{
                id: field.name,
                items: FEE_PAID_BY_USER,
                onSelect: field.onChange,
                defaultValue: 'None',
                reset: isResetDate,
              }}
            />
          )}
        />
      </div>
      <div className={styles.buttons}>
        <Button
          className={cn(styles.button, styles.buttonReset)}
          onClick={handleResetForm}
        >
          Clear all filters
        </Button>
        <Button
          className={cn(styles.button, styles.buttonClose)}
          onClick={handleClose}
        >
          Close
        </Button>
        <Button
          type="submit"
          className={cn(styles.button, styles.buttonSubmit)}
        >
          Apply
        </Button>
      </div>
    </form>
  );
};

InvoiceFiltersForm.defaultProps = {
  className: null,
  onSubmit: null,
  onClose: null,
  onSearchFormData: null,
  currencies: [],
  onChangePayCurrency: null,
};

InvoiceFiltersForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  onSearchFormData: PropTypes.func,
  currencies: PropTypes.arrayOf(PropTypes.object),
};

export const InvoiceFiltersFormMemo = React.memo(InvoiceFiltersForm);
