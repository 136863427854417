import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

class AffilateTable extends Component {
  static propTypes = {
    referralsStat: PropTypes.arrayOf(PropTypes.object),
  };

  render() {
    const { referralsStat, ngClass } = this.props;

    return (
      <Fragment>
        <h2>My Referrals</h2>

        <table className={`${ngClass ? ngClass : ""} ${styles.table}`}>
          <tbody>
            <tr>
              <th>ID</th>
              <th>Referral email</th>
              <th>Finished transactions</th>
            </tr>

            {referralsStat.map((referralStat, i) => (
              <tr key={`payment${i}`}>
                <td>
                  <span className={styles.tableHeader}>ID</span>
                  <span className={styles.tableValue}>{i + 1}</span>
                </td>
                <td>
                  <span className={styles.tableHeader}>Referral email</span>
                  <span className={styles.tableValue}>
                    {referralStat.email}
                  </span>
                </td>
                <td>
                  <span className={styles.tableHeader}>
                    Finished transactions
                  </span>
                  <span className={styles.tableValue}>
                    {referralStat.finished_count}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {referralsStat.length === 0 && (
          <p className={styles.emptyMessage}>
            You don’t have any referrals yet. Invite people to use NOWPayments
            and get bonuses!
          </p>
        )}
      </Fragment>
    );
  }
}

export default AffilateTable;
