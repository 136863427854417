import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { hasElementsInArray } from '../../helpers/has-elements-in-array';
import { CurrencyNetworkSup } from '../shared/currency-network-sup';
import classes from './styles.module.scss';

export const SubscriptionPlanInfoBox = (props) => {
  const {
    className,
    headerItems,
    bodyItems,
    thText,
    tdElement,
  } = props;

  const hasHeaderItems = hasElementsInArray(headerItems);
  const hasBodyItems = hasElementsInArray(bodyItems);

  return (
    <div className={cn(classes.planInfoBox, className)}>
      <div className={classes.planInfo}>
        {hasHeaderItems && (
          <div className={classes.planInfoHeader}>
            {headerItems.map((item) => (
              <div
                key={item?.id}
                className={classes.planInfoHeaderItem}
                dangerouslySetInnerHTML={{ __html: item?.name }}
              />
            ))}
            {thText && (
              <div className={classes.planInfoHeaderItem}>
                {thText}
              </div>
            )}
          </div>
        )}
        {hasBodyItems && (
          <div className={classes.planInfoBody}>
            {bodyItems.map((item) => (
              <div className={classes.planInfoBodyItem} key={item?.id}>
                <div className={cn([
                  item?.network && classes.priceFlex,
                ])}>
                  <div
                    className={cn([
                      item?.isTextBlack && classes.colorBlack,
                      item?.isTextOverflow && classes.overflow,
                      item?.isTextGreen && classes.colorGreen,
                      item?.isTextPink && classes.colorPink,
                    ])}
                    title={item?.textWithTitle ? item?.text : null}
                    dangerouslySetInnerHTML={{ __html: item?.text }}
                  />
                  {item?.network && (
                    <CurrencyNetworkSup network={item.network} />
                  )}
                </div>
                {item?.textSecond && (
                  <div
                    className={cn([
                      classes.planInfoBodySecondItem,
                      item?.isTextSecondBlack && classes.colorBlack,
                      item?.isTextSecondOverflow && classes.overflow,
                      item?.isTextSecondGreen && classes.colorGreen,
                      item?.isTextSecondPink && classes.colorPink,
                    ])}
                    title={item.textSecondWithTitle ? item.textSecond : null}
                    dangerouslySetInnerHTML={{ __html: item.textSecond }}
                  />
                )}
              </div>
            ))}
            {tdElement && (
              <div className={classes.planInfoBodyItem}>
                {tdElement}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

SubscriptionPlanInfoBox.defaultProps = {
  className: null,
  headerItems: null,
  bodyItems: null,
  thText: null,
  tdElement: null,
};

SubscriptionPlanInfoBox.propTypes = {
  className: PropTypes.string,
  headerItems: PropTypes.arrayOf(PropTypes.object),
  bodyItems: PropTypes.arrayOf(PropTypes.object),
  thText: PropTypes.string,
  tdElement: PropTypes.element,
};
