import React from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { Switcher } from '../../../ui/switcher';
import { hasElementsInArray } from '../../../../helpers/has-elements-in-array';
import { GroupCurrency } from '../group-currency';
import { getUrlWithDomain } from '../../../../helpers/get-url-with-domain';
import { safeToUpperCase } from '../../../../helpers/safe-to-upper-case';
import { LoaderIcon } from '../../../icons/loader-icon';
import { isFunction } from '../../../../helpers/is-function';
import { partnerSelector } from '../../../../store/partner/selectors';
import { InfoAboutUse } from '../../../shared/info-about-use';
import { GroupCurrenciesProps } from '../../types';
import classes from './styles.module.scss';

export const GroupCurrencies: React.FC<GroupCurrenciesProps> = (props) => {
  const {
    className,
    title,
    currencies,
    tickers,
    selectedTickersByPartner,
    onCurrencyClick,
    pending,
    switcherActive,
    onSwitcherChange,
    categoryNames,
  } = props;

  const partner = useSelector(partnerSelector());
  const isOnrampEnabled = Boolean(partner?.isGuardarianEnabled || partner?.isOnrampEnabled);
  const isFiatAndOnrampDisabled = (title === categoryNames.FIAT) && !isOnrampEnabled;

  const handleSwitcherChange = (isActive: boolean): void => {
    if (isFunction(onSwitcherChange)) {
      onSwitcherChange(isActive, title, tickers);
    }
  };
  const hasCurrencies = hasElementsInArray(currencies);

  return (
    <div className={cn(classes.groupCurrencies, className)}>
      <div className={cn([
        classes.groupCurrenciesHeader,
        isFiatAndOnrampDisabled && classes.defaultCursor,
      ])}
      >
        <Switcher
          active={switcherActive}
          onChange={handleSwitcherChange}
        />
        {title && (
          <div className={classes.groupCurrenciesTitle}>
            {title}
          </div>
        )}
        {pending && <LoaderIcon className={classes.groupCurrenciesLoader} size={20} />}
      </div>
      {isFiatAndOnrampDisabled && (
        <InfoAboutUse className={classes.info}>
          To enable fiat currencies for payments make a request through the
          {' '}
          <a
            className={classes.link}
            href="/fiat-operations-settings"
          >
            fiat operations settings
          </a>
          .
        </InfoAboutUse>
      )}
      <div className={classes.groupCurrenciesContent}>
        {hasCurrencies && (
          <div className={classes.groupCurrenciesList}>
            {currencies.map((item) => (
              <GroupCurrency
                key={item?.id}
                image={getUrlWithDomain(item?.logo_url || item?.logoUrl)}
                ticker={item?.code}
                currentTicker={item?.ticker}
                network={item?.network}
                name={item?.name}
                onClick={onCurrencyClick}
                className={classes.groupCurrenciesItem}
                active={selectedTickersByPartner?.includes(safeToUpperCase(item?.code))}
                categoryName={title}
                componentDisabled={isFiatAndOnrampDisabled}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
