import React from 'react';
import { getSafeErrorMessageText } from '../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../helpers/console-error-message';
import { useIpnKey } from '../../../api/modules/account/use-ipn-key';
import { useIpnUrl } from '../../../api/modules/account/use-ipn-url';
import { useIpnRecurrentNotification } from '../../../api/modules/account/use-ipn-recurrent-notification';
import { useUpdateIpnUrl } from '../../../api/modules/account/use-update-ipn-url';
import { useUpdateIpnRecurrentNotification } from '../../../api/modules/account/use-update-ipn-recurrent-notification';
import { useTimeout } from '../../../hooks/use-timeout';
import { ResponseType } from '../../../default-types';

export const useInitState = () => {
  const fetchIpnKey = useIpnKey();
  const fetchIpnUrl = useIpnUrl();
  const fetchRecurrentNotification = useIpnRecurrentNotification();
  const fetchUpdateIpnCallbackUrl = useUpdateIpnUrl();
  const fetchUpdateIpnRecurrentNotification = useUpdateIpnRecurrentNotification();

  const [ipnKey, setIpnKey] = React.useState<string | null>(null);
  const [ipnCallbackUrl, setIpnCallbackUrl] = React.useState<string | null>(null);
  const [ipnTimeout, setIpnTimeout] = React.useState<string | null>(null);
  const [ipnNumRecurrent, setIpnNumRecurrent] = React.useState<string | null>(null);
  const [isIpnKeyLoading, setIsIpnKeyLoading] = React.useState(false);
  const [isIpnCallbackUrlLoading, setIsIpnCallbackUrlLoading] = React.useState(false);
  const [isIpnCallbackUrlUpdated, setIsIpnCallbackUrlUpdated] = React.useState(false);

  const getIpnKey = React.useCallback(async () => {
    setIsIpnKeyLoading(true);
    const {
      data,
      status,
      errorMessage,
    }: ResponseType = await fetchIpnKey();

    if (status === 200 && data) {
      setIpnKey(data.key);
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/instant-payment-notifications/secret-key');
    }
    setIsIpnKeyLoading(false);
  }, [fetchIpnKey]);

  const getIpnUrl = React.useCallback(async () => {
    setIsIpnCallbackUrlLoading(true);
    const {
      data,
      status,
      errorMessage,
    }: ResponseType = await fetchIpnUrl();

    if (status === 200 && data) {
      setIpnCallbackUrl(data.result);
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/get-ipn');
    }
    setIsIpnCallbackUrlLoading(false);
  }, [fetchIpnUrl]);

  const getRecurrentNotification = React.useCallback(async () => {
    const {
      data,
      status,
      errorMessage,
    }: ResponseType = await fetchRecurrentNotification();

    if (status === 200 && data) {
      setIpnTimeout(data.ipnTimeout);
      setIpnNumRecurrent(data.ipnNumRecurrent);
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/instant-payment-notifications/recurrent-notifications');
    }
  }, [fetchRecurrentNotification]);

  const updateIpnUrl = React.useCallback(async (newCallbackUrl: string) => {
    setIsIpnCallbackUrlLoading(true);
    const {
      data,
      status,
      errorMessage,
    }: ResponseType = await fetchUpdateIpnCallbackUrl(newCallbackUrl);

    if (status === 200) {
      setIpnCallbackUrl(newCallbackUrl);
      setIsIpnCallbackUrlUpdated(true);
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/save-ipn');
    }
    setIsIpnCallbackUrlLoading(false);
  }, [fetchUpdateIpnCallbackUrl]);

  const updateRecurrentNotifications = React.useCallback(async (numRecurrent: string, timeout: string) => {
    const {
      data,
      status,
      errorMessage,
    }: ResponseType = await fetchUpdateIpnRecurrentNotification(numRecurrent, timeout);

    if (status === 200 && data) {
      setIpnTimeout(data.ipnTimeout);
      setIpnNumRecurrent(data.ipnNumRecurrent);
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/instant-payment-notifications/recurrent-notifications');
    }
  }, [fetchUpdateIpnRecurrentNotification]);

  React.useEffect(() => {
    getIpnKey();
    getIpnUrl();
    getRecurrentNotification();
  }, []);

  useTimeout(() => setIsIpnCallbackUrlUpdated(false), 2000, isIpnCallbackUrlUpdated);

  return {
    ipnKey,
    ipnCallbackUrl,
    ipnTimeout,
    ipnNumRecurrent,
    isIpnKeyLoading,
    isIpnCallbackUrlLoading,
    isIpnCallbackUrlUpdated,
    getIpnKey,
    updateIpnUrl,
    updateRecurrentNotifications,
  };
};
