import React from 'react';
import { useCreateSubPartnerDeposit } from '../../../api/modules/account/use-create-sub-partner-deposit';
import { useCreateSubPartnerWriteOff } from '../../../api/modules/account/use-create-sub-partner-write-off';
import { getSafeErrorMessageText } from '../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../helpers/console-error-message';
import { useTimeout } from '../../../hooks/use-timeout';
import { safeToLowerCase } from '../../../helpers/safe-to-lower-case';
import { useCreateSubPartnerPayment } from '../../../api/modules/account/use-create-sub-partner-payment';
import { isFunction } from '../../../helpers/is-function';
import { CUSTOMER_FORM_NAMES } from '../helpers';

export const useCustomerPageActionsState = (options) => {
  const {
    customerId,
    reFetchBalances,
    reFetchTransactions,
    reFetchPayments,
  } = options;
  const createSubPartnerDeposit = useCreateSubPartnerDeposit();
  const createSubPartnerPayment = useCreateSubPartnerPayment();
  const createSubPartnerWriteOff = useCreateSubPartnerWriteOff();

  const [isFetching, setIsFetching] = React.useState(false);
  const [isPopupActionShow, setIsPopupActionShow] = React.useState(false);
  const [activeFormName, setActiveFormName] = React.useState(CUSTOMER_FORM_NAMES.DEPOSIT);
  const [actionsApiError, setActionsApiError] = React.useState(null);

  const resetError = () => {
    setActionsApiError(null);
  };
  const handlePopupActionOpen = (activeType) => {
    setActiveFormName(activeType);
    setIsPopupActionShow(true);
  };
  const handleActiveFormNameChange = (name) => {
    setActiveFormName(name);
  };
  const handlePopupActionClose = () => {
    setIsPopupActionShow(false);
  };
  const handleActionSubmit = async (formData, setObservablePaymentData) => {
    const isDeposit = activeFormName === CUSTOMER_FORM_NAMES.DEPOSIT;
    const isDepositWithPayment = formData?.isDepositWithPayment ?? false;
    const currentDepositFunc = isDeposit && isDepositWithPayment
      ? createSubPartnerPayment
      : createSubPartnerDeposit;
    const currentFunc = isDeposit ? currentDepositFunc : createSubPartnerWriteOff;

    setIsFetching(true);
    const { data, status, errorMessage } = await currentFunc({
      customerId,
      amount: Number(formData?.amount),
      ticker: safeToLowerCase(formData?.ticker),
    });

    if (status === 201) {
      setIsFetching(false);
      setIsPopupActionShow(false);
      if (!isDepositWithPayment) {
        void reFetchBalances();
        void reFetchTransactions();
      }
      if (isDeposit && isDepositWithPayment) {
        void reFetchPayments();

        if (isFunction(setObservablePaymentData)) {
          setObservablePaymentData(data?.result ?? {});
        }
      }
    } else {
      const currentEndpointText = isDeposit ? 'deposit' : 'write-off';
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, `/sub-partners/${currentEndpointText}`);
      setActionsApiError(errorDataMessage);
      setIsFetching(false);
    }
  };

  useTimeout(resetError, 3000, actionsApiError);

  return {
    isFetching,
    isPopupActionShow,
    activeFormName,
    handlePopupActionOpen,
    handlePopupActionClose,
    handleActionSubmit,
    actionsApiError,
    handleActiveFormNameChange,
  };
};
