import React from 'react';
import PropTypes from 'prop-types';

export const SignOutIcon = (props) => {
  const { color } = props;

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.42151 8.4225C7.09339 8.4225 6.83394 8.6775 6.83394 9C6.83394 9.315 7.09339 9.5775 7.42151 9.5775H12V13.1625C12 15 10.4815 16.5 8.60429 16.5H4.88808C3.01853 16.5 1.5 15.0075 1.5 13.17V4.8375C1.5 2.9925 3.02616 1.5 4.89571 1.5H8.61955C10.4815 1.5 12 2.9925 12 4.83V8.4225H7.42151ZM14.7227 6.40515L16.9127 8.58765C17.0251 8.70015 17.0851 8.84265 17.0851 9.00015C17.0851 9.15015 17.0251 9.30015 16.9127 9.40515L14.7227 11.5876C14.6102 11.7001 14.4601 11.7601 14.3176 11.7601C14.1677 11.7601 14.0177 11.7001 13.9052 11.5876C13.6802 11.3626 13.6802 10.9951 13.9052 10.7701L15.1052 9.57765H12.0002V8.42265H15.1052L13.9052 7.23015C13.6802 7.00515 13.6802 6.63765 13.9052 6.41265C14.1301 6.18015 14.4977 6.18015 14.7227 6.40515Z"
      />
    </svg>
  );
};

SignOutIcon.defaultProps = {
  color: '#616161',
};

SignOutIcon.propTypes = {
  color: PropTypes.string,
};
