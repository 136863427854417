import * as React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import { HistoryTableMemo } from '../../../history-table';
import {
  SUBSCRIPTIONS_PAGE_THS_DESKTOP,
  SUBSCRIPTIONS_PAGE_THS_MOBILE,
  SUBSCRIPTIONS_PAGE_PER_PAGE,
} from '../../helpers';
import { SubscriptionsTableRow } from '../subscriptions-table-row';
import { NewPagination } from '../../../shared/new-pagination';
import classes from './styles.module.scss';

const SubscriptionsTable = (props) => {
  const {
    items,
    hasItems,
    hasSearchData,
    loading,
    onClick,
    count,
    onPaginatorClick,
    page,
    apiError,
    currenciesObject,
  } = props;

  const isDesktop = useMediaQuery('(min-width: 1200px)');
  const thItems = isDesktop ? SUBSCRIPTIONS_PAGE_THS_DESKTOP : SUBSCRIPTIONS_PAGE_THS_MOBILE;
  const isPaginationShow = count > SUBSCRIPTIONS_PAGE_PER_PAGE;

  return (
    <>
      <div className={classes.subscriptionTableBox}>
        <HistoryTableMemo
          className={classes.subscriptionTable}
          thItems={thItems}
          colSpan={6}
          loading={loading}
          historyData={items}
          hasData={hasItems || hasSearchData}
          emptyText="No subscription plans yet. It takes a couple of clicks to create one!"
          notFoundText="No subscription plans matching this criteria."
          apiError={apiError}
        >
          {hasItems && items.map((item) => (
            <SubscriptionsTableRow
              key={item?.id}
              data={item}
              currenciesObject={currenciesObject}
              onClick={onClick}
              isDesktop={isDesktop}
            />
          ))}
        </HistoryTableMemo>
      </div>
      {isPaginationShow && (
        <NewPagination
          className={classes.paginator}
          currentPage={page}
          countItems={count}
          itemsPerPage={SUBSCRIPTIONS_PAGE_PER_PAGE}
          onChangePage={onPaginatorClick}
        />
      )}
    </>
  );
};

SubscriptionsTable.defaultProps = {
  items: null,
  hasItems: false,
  hasSearchData: false,
  loading: false,
  onClick: null,
  count: 0,
  onPaginatorClick: null,
  page: 1,
  apiError: null,
};

SubscriptionsTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  hasItems: PropTypes.bool,
  hasSearchData: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  count: PropTypes.number,
  onPaginatorClick: PropTypes.func,
  page: PropTypes.number,
  apiError: PropTypes.string,
};

export const SubscriptionsTableMemo = React.memo(SubscriptionsTable);
