import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import QRCodeComponent from '../qrcode';
import { STATUSES } from '../../constants/app-constants';
import { CopyText } from '../ui/copy-text';
import { CurrencyNetworkSup } from '../shared/currency-network-sup';
import styles from './styles.module.scss';

const QR_OPTIONS = {
  width: 120,
  heigth: 120,
  margin: 0,
};

export const InvoiceResultWithCurrency = (props) => {
  const {
    orderId,
    priceAmount,
    priceTicker,
    priceNetwork,
    payAmount,
    payTicker,
    payNetwork,
    payinAddress,
    payinExtraId,
    status,
    url,
  } = props;

  const formatStatus = (status) => {
    return typeof status === 'string'
      ? status.split('_').join(' ')
      : null;
  };

  const isNeedSpinner = (status) => (
    status === STATUSES.WAITING
    || status === STATUSES.SENDING
    || status === STATUSES.CONFIRMING
    || status === STATUSES.CONFIRMED
  );

  return (
    <div className={styles.flexColumn}>
      <div
        className={cn(
          styles.dFlex,
          styles.justifyCenter,
          styles.fs20,
          styles.fwBold,
          styles.mb20,
          styles.breakAll,
        )}
      >
        Order: #
        {orderId}
      </div>
      <div
        className={cn(
          styles.dFlex,
          styles.fwBold,
          styles.fs18,
          styles.justifyBetween,
        )}
      >
        <div className={cn(styles.flexColumn, styles.selfCenter)}>
          {priceAmount && (
            <div className={cn(styles.priceAmount, styles.mb10)}>
              Price:
              {' '}
              {priceAmount}
              {' '}
              {priceTicker}
              {priceNetwork && (
                <CurrencyNetworkSup network={priceNetwork} />
              )}
            </div>
          )}
          <div className={cn(styles.payAmount, styles.mb10)}>
            Amount:
            {' '}
            {payAmount}
            {' '}
            {payTicker}
            {payNetwork && (
              <CurrencyNetworkSup network={payNetwork} />
            )}
          </div>
          <div className={styles.dFlex}>
            <div className={cn(styles.breakAll)}>
              Address:
              {' '}
              <CopyText
                className={styles.displayInline}
                text={payinAddress}
              />
            </div>
          </div>
          {payinExtraId && (
            <div className={cn(styles.dFlex, styles.mt10)}>
              <div className={cn(styles.breakAll)}>
                Extra Id:
                {' '}
                <CopyText
                  className={styles.displayInline}
                  text={payinExtraId}
                />
              </div>
            </div>
          )}
        </div>
        <div className={cn(styles.flexColumn, styles.qrCode)}>
          {payinAddress && (
            <QRCodeComponent
              value={payinAddress}
              options={QR_OPTIONS}
            />
          )}
        </div>
      </div>
      <div className={styles.lineDelimiter} />
      <div
        className={cn(
          styles.dFlex,
          styles.fs16,
          styles.fwBold,
          styles.mb10,
        )}
      >
        <div className={styles.selfCenter}>Status:</div>
        <div
          className={cn(
            styles[status],
            styles.ml10,
            styles.selfCenter,
          )}
        >
          {formatStatus(status)}
        </div>
        {isNeedSpinner(status) && (
          <FontAwesomeIcon
            icon={faCircleNotch}
            size="2x"
            spin={true}
            className={styles[status]}
            style={{ marginLeft: '30px' }}
          />
        )}
      </div>
      <div className={cn(styles.dFlex, styles.fs16, styles.fwBold)}>
        Share a permanent link to a hosted page:
      </div>
      <div className={cn(styles.dFlex, styles.fs16, styles.fwBold)}>
        <CopyText text={url} />
      </div>
    </div>
  );
};

InvoiceResultWithCurrency.defaultProps = {
  orderId: null,
  priceAmount: null,
  priceTicker: null,
  priceNetwork: null,
  payNetwork: null,
  url: null,
};

InvoiceResultWithCurrency.propTypes = {
  orderId: PropTypes.string,
  priceAmount: PropTypes.string,
  priceTicker: PropTypes.string,
  priceNetwork: PropTypes.string,
  payNetwork: PropTypes.string,
  url: PropTypes.string,
};
