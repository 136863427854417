import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useGoogleLogin } from '@react-oauth/google';
import { LoaderIcon } from '../icons/loader-icon';
import { useGoogleUserInfo } from '../../api/modules/google/use-google-user-info';
import { isFunction } from '../../helpers/is-function';
import { getSafeErrorMessageText } from '../../helpers/get-safe-error-message-text';
import classes from './styles.module.scss';

export const GoogleAuthButton = (props) => {
  const {
    className,
    pending,
    children,
    onCallBack,
    errorMessage,
  } = props;

  const fetchGoogleUserInfo = useGoogleUserInfo();

  const [isFetching, setIsFetching] = React.useState(false);
  const [apiError, setApiError] = React.useState(null);

  const handleSuccess = async (response) => {
    const accessToken = response?.access_token ?? null;

    setIsFetching(true);
    const { data, status } = await fetchGoogleUserInfo(accessToken);
    setIsFetching(false);

    if (status === 200) {
      if (isFunction(onCallBack)) {
        onCallBack({
          accessToken,
          email: data?.email ?? null,
        });
      }
    } else {
      const errorMessage = getSafeErrorMessageText(data?.errorData?.message);
      setApiError(errorMessage);
      console.warn(errorMessage);
    }
  };
  const handleClick = React.useCallback(useGoogleLogin(
    { onSuccess: handleSuccess },
  ), [fetchGoogleUserInfo, onCallBack]);

  React.useEffect(() => {
    let timerId = null;
    if (apiError) {
      timerId = setTimeout(() => {
        setApiError(null);
      }, 3000);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [apiError]);

  const isLoaderShown = isFetching || pending;
  const currentErrorMessage = apiError || errorMessage;

  return (
    <>
      <button
        type="button"
        className={cn(classes.googleAuthButton, className)}
        onClick={handleClick}
        disabled={isFetching || Boolean(apiError)}
      >
        {isLoaderShown && (
          <LoaderIcon className={classes.googleAuthButtonLoader} />
        )}
        {!isLoaderShown && (
          <>
            <img
              className={classes.googleAuthButtonImg}
              src="/images/google.svg"
              alt="Google Auth Button"
              decoding="async"
              loading="lazy"
              width={25}
              height={25}
            />
            {children}
          </>
        )}
      </button>
      {currentErrorMessage && (
        <div className={classes.googleAuthButtonError}>
          {currentErrorMessage}
        </div>
      )}
    </>
  );
};

GoogleAuthButton.defaultProps = {
  className: null,
  pending: false,
  children: null,
  onCallBack: null,
  errorMessage: null,
};

GoogleAuthButton.propTypes = {
  className: PropTypes.string,
  pending: PropTypes.bool,
  children: PropTypes.node,
  onCallBack: PropTypes.func,
  errorMessage: PropTypes.string,
};
