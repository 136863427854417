import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { NavLink, useHistory } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { hasElementsInArray } from '../../helpers/has-elements-in-array';
import { isFunction } from '../../helpers/is-function';
import classes from './styles.module.scss';

export const MainMenuDropdown = (props) => {
  const {
    className,
    Icon,
    name,
    items,
    onClick,
    hasNewItem,
  } = props;

  const history = useHistory();
  const pathNameMainPart = history?.location?.pathname?.split('/')[1] ?? '';
  const basePath = `/${pathNameMainPart}`;
  const hasItems = hasElementsInArray(items);

  const [isOpen, setIsOpen] = React.useState(false);
  const [isMenuLinkActive, setIsMenuLinkActive] = React.useState(false);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };
  const handleClick = (event) => {
    if (isFunction(onClick)) {
      onClick(event);
    }
  };
  const handleKeyPress = (event) => {
    if (isFunction(onClick)) {
      onClick(event);
    }
  };

  React.useEffect(() => {
    const pathNames = hasItems ? items.map((item) => item.href) : null;
    const isMatch = pathNames ? pathNames.includes(basePath) : false;

    if (isMatch) {
      setIsOpen(true);
      setIsMenuLinkActive(true);
    } else {
      setIsMenuLinkActive(false);
    }
  }, [hasItems, items, basePath]);

  if (!hasItems) {
    return null;
  }

  return (
    <div className={cn([
      classes.mainMenuDropdown,
      isOpen && classes.mainMenuDropdownOpen,
      isMenuLinkActive && classes.mainMenuDropdownLinkActive,
      className,
    ])}>
      <div
        className={cn([
          classes.mainMenuDropdownHeader,
          hasNewItem && classes.mainMenuDropdownAttention,
        ])}
        onClick={handleToggle}
        onKeyPress={handleToggle}
        role="button"
        tabIndex={0}
      >
        {Icon}
        <span>{name}</span>
        <img
          className={classes.mainMenuDropdownArrow}
          src="/images/main-menu-arrow-icon.svg"
          alt="Icon"
          decoding="async"
          width={8}
          height={6}
        />
      </div>
      <div className={classes.mainMenuDropdownContent}>
        <ul className={classes.mainMenuDropdownList}>
          {items.map((item) => (
            <li
              key={item?.id ?? nanoid()}
              className={classes.mainMenuDropdownListItem}
            >
              <NavLink
                className={cn([
                  classes.mainMenuDropdownListLink,
                  item?.href === basePath && classes.mainMenuDropdownListLinkActive,
                  item?.isNew && classes.mainMenuDropdownListLinkNew,
                ])}
                to={item?.href}
                exact
                onClick={handleClick}
                onKeyPress={handleKeyPress}
              >
                {item?.name}
                {item?.isBeta && (
                  <span className={classes.mainMenuDropdownListLinkBeta}>
                    Beta
                  </span>
                )}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

MainMenuDropdown.defaultProps = {
  className: null,
  Icon: null,
  name: null,
  items: null,
  onClick: null,
  hasNewItem: false,
};

MainMenuDropdown.propTypes = {
  className: PropTypes.string,
  Icon: PropTypes.element,
  name: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
  hasNewItem: PropTypes.bool,
};
