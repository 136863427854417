import { ROUTES } from '../constants/routes';
import { lazyWithRetry } from '../helpers/lazy-with-retry';
import SigninPage from '../pages/signin';
import CreateAccount from '../pages/create-account';
import Payments from '../pages/payments';
import PaymentLink from '../pages/invoices';
import WalletAddition from '../pages/create-account/wallet-addition';
import EmailVerification from '../pages/create-account/email-verification';
import DashboardPage from '../pages/dashboard';
import Custody from '../pages/custody';
import MassPayouts from '../pages/mass-payouts';
import AccountSettingsPage from '../pages/account-settings';
import AffiliateProgramPage from '../pages/affiliate-program';
import Subscriptions from '../pages/subscriptions';
import SubscriptionPlan from '../pages/subscription-plan';
import SubscriptionUser from '../pages/subscription-user';
import DonationPage from '../pages/donations';
import SignUpSuccess from '../pages/create-account/sign-up-success';
import PosTerminal from '../pages/pos-terminal';
import CoinsSettings from '../pages/coins-settings';
import {
  Customers,
  Customer,
  CustomerTransactions,
  BillingPlans,
  BillingPlan,
} from '../pages/customers';
import {
  FiatOperationsSettings,
  OffRamp,
} from "../pages/fiat-operations-settings";
import { PaymentsSettings } from '../pages/store-settings/index';

export const routes = [
  {
    path: ROUTES.SIGN_IN,
    private: false,
    redirect: ROUTES.DASHBOARD,
    Component: SigninPage,
  },
  {
    path: ROUTES.CREATE_ACCOUNT,
    private: false,
    redirect: ROUTES.DASHBOARD,
    Component: CreateAccount,
  },
  {
    path: ROUTES.WALLET_ADDITION,
    private: false,
    redirect: ROUTES.SIGN_IN,
    Component: WalletAddition,
  },
  {
    path: ROUTES.EMAIL_VERIFICATION,
    private: false,
    redirect: ROUTES.WALLET_ADDITION,
    Component: EmailVerification,
  },
  {
    path: ROUTES.REQUEST_RESET,
    private: false,
    redirect: ROUTES.DASHBOARD,
    Component: lazyWithRetry(() => import('../pages/request-reset')),
  },
  {
    path: ROUTES.RESET_PASSWORD,
    private: false,
    redirect: ROUTES.DASHBOARD,
    Component: lazyWithRetry(() => import('../pages/create-account/reset-password')),
  },
  {
    path: ROUTES.EXPIRED_RESET_PASSWORD,
    private: false,
    redirect: ROUTES.DASHBOARD,
    Component: lazyWithRetry(() => import('../pages/expired-reset-password')),
  },
  {
    path: ROUTES.OAUTH,
    private: false,
    redirect: ROUTES.SIGN_IN,
    Component: lazyWithRetry(() => import('../pages/oauth')),
  },
];

export const privateRoutes = [
  {
    path: ROUTES.DASHBOARD,
    private: true,
    redirect: ROUTES.SIGN_IN,
    Component: DashboardPage,
  },
  {
    path: ROUTES.PAYMENTS,
    private: true,
    redirect: ROUTES.SIGN_IN,
    Component: Payments,
  },
  {
    path: ROUTES.INVOICES,
    private: true,
    redirect: ROUTES.SIGN_IN,
    Component: PaymentLink,
  },
  {
    path: ROUTES.CUSTODY,
    private: true,
    redirect: ROUTES.SIGN_IN,
    Component: Custody,
  },
  {
    path: ROUTES.MASS_PAYOUTS,
    private: true,
    redirect: ROUTES.SIGN_IN,
    Component: MassPayouts,
  },
  {
    path: ROUTES.STORE_SETTINGS,
    private: true,
    redirect: ROUTES.SIGN_IN,
    Component: PaymentsSettings,
  },
  {
    path: ROUTES.ACCOUNT_SETTINGS,
    private: true,
    redirect: ROUTES.SIGN_IN,
    Component: AccountSettingsPage,
  },
  {
    path: ROUTES.AFFILIATE_PROGRAM,
    private: true,
    redirect: ROUTES.SIGN_IN,
    Component: AffiliateProgramPage,
  },
  {
    path: ROUTES.COINS_SETTINGS,
    private: true,
    redirect: ROUTES.SIGN_IN,
    Component: CoinsSettings,
  },
  {
    path: ROUTES.SUBSCRIPTIONS,
    private: true,
    redirect: ROUTES.SIGN_IN,
    Component: Subscriptions,
  },
  {
    path: ROUTES.SUBSCRIPTION_PLAN,
    private: true,
    redirect: ROUTES.SIGN_IN,
    Component: SubscriptionPlan,
  },
  {
    path: ROUTES.SUBSCRIPTION_USER,
    private: true,
    redirect: ROUTES.SIGN_IN,
    Component: SubscriptionUser,
  },
  {
    path: ROUTES.DONATIONS,
    private: true,
    redirect: ROUTES.SIGN_IN,
    Component: DonationPage,
  },
  {
    path: ROUTES.OAUTH,
    private: false,
    redirect: ROUTES.SIGN_IN,
    Component: lazyWithRetry(() => import('../pages/oauth')),
  },
  {
    path: ROUTES.EXTERNAL_REDIRECT,
    private: true,
    redirect: ROUTES.SIGN_IN,
    Component: lazyWithRetry(() => import('../pages/external-redirect')),
  },
  {
    path: ROUTES.WALLET_ADDITION,
    private: false,
    redirect: ROUTES.SIGN_IN,
    Component: WalletAddition,
  },
  {
    path: ROUTES.CURRENCIES_CHOICE,
    private: true,
    redirect: ROUTES.SIGN_IN,
    Component: lazyWithRetry(() => import('../pages/create-account/currencies-choice')),
  },
  {
    path: ROUTES.SIGN_IN_SUCCESS,
    private: true,
    redirect: ROUTES.SIGN_IN,
    Component: SignUpSuccess,
  },
  {
    path: ROUTES.SIGN_IN_SUCCESS_GET_STARTED,
    private: true,
    redirect: ROUTES.SIGN_IN,
    Component: SignUpSuccess,
  },
  {
    path: ROUTES.SIGN_IN_SUCCESS_ACCEPT_CRYPTO,
    private: true,
    redirect: ROUTES.SIGN_IN,
    Component: SignUpSuccess,
  },
  {
    path: ROUTES.SIGN_IN_SUCCESS_START_ACCEPTING,
    private: true,
    redirect: ROUTES.SIGN_IN,
    Component: SignUpSuccess,
  },
  {
    path: ROUTES.POS_TERMINAL,
    private: true,
    redirect: ROUTES.SIGN_IN,
    Component: PosTerminal,
  },
  {
    path: ROUTES.CUSTOMERS,
    private: true,
    redirect: '/sign-in',
    Component: Customers,
  },
  {
    path: ROUTES.CUSTOMERS_TRANSACTIONS,
    private: true,
    redirect: '/sign-in',
    Component: CustomerTransactions,
  },
  {
    path: ROUTES.CUSTOMERS_BILLING_PLANS,
    private: true,
    redirect: '/sign-in',
    Component: BillingPlans,
  },
  {
    path: ROUTES.CUSTOMERS_BILLING_PLAN,
    private: true,
    redirect: '/sign-in',
    Component: BillingPlan,
  },
  {
    path: ROUTES.CUSTOMER,
    private: true,
    redirect: '/sign-in',
    Component: Customer,
  },
  {
    path: ROUTES.FIAT_OPERATIONS_SETTINGS,
    private: true,
    redirect: ROUTES.SIGN_IN,
    Component: FiatOperationsSettings,
  },
  {
    path: ROUTES.FIAT_OPERATIONS_SETTINGS_OFF_RAMP,
    private: true,
    redirect: ROUTES.SIGN_IN,
    Component: OffRamp,
  },
  {
    path: ROUTES.ASTERISK,
    private: false,
    redirect: null,
    Component: lazyWithRetry(() => import('../pages/not-found')),
  },
];
