import React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';

const mapFn = (data) => data;

export const useCreateSubPartnerDeposit = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (options) => {
    const sub_partner_id = options?.customerId ?? null;
    const amount = options?.amount ?? null;
    const currency = options?.ticker ?? null;

    const [cancelToken] = getRequestSource();
    const requestPromise = client.post(
      '/sub-partners/deposit',
      {
        sub_partner_id,
        amount,
        currency,
      },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
