import * as React from 'react';
import { useSelector } from 'react-redux';
import { useApiKeys } from '../../../api/modules/account/use-api-keys';
import { useBaseCurrency } from '../../../api/modules/account/use-base-currency';
import { getFilteredCurrencies } from '../../../helpers/get-filtered-currencies';
import { pickByKeys } from '../../../helpers/pick-by-keys';
import { hasActiveApiKey } from '../../../helpers/has-active-api-key';
import { CURRENCY_USD } from '../../../constants/app-constants';
import { partnerFetchingSelector, partnerSelector } from '../../../store/partner/selectors';
import { isObject } from '../../../helpers/is-object';
import { useFetchPartnerProfile } from '../../../api/modules/account/use-fetch-partner-profile';
import { useCurrenciesToObject } from '../../../hooks/use-currencies-to-object';
import {
  currencyListSelector,
  fiatCurrenciesSelector,
  selectedTickersSelector,
} from '../../../store/currencies/selectors';
import { useAllCurrencies } from '../../../hooks/use-all-currencies';
import { useCryptoCurrencies } from '../../../hooks/use-crypto-currencies';
import { useFiatCurrencies } from '../../../hooks/use-fiat-currencies';

const initialState = {
  profileInfo: {},
  isLoading: false,
};

const initialActionsState = {
  updateKeys: () => {},
  isUpdatingKeys: false,
};

const ProfileInfoStateContext = React.createContext(initialState);
const ProfileInfoActionsContext = React.createContext(initialActionsState);

export const useProfileInfoState = () => {
  const stateContextValue = React.useContext(ProfileInfoStateContext);

  if (!stateContextValue) {
    throw new Error('Missing profille info context');
  }

  return stateContextValue;
};

export const useProfileInfoActions = () => {
  const actionsContextValue = React.useContext(ProfileInfoActionsContext);

  if (!actionsContextValue) {
    throw new Error('Missing profille info context');
  }

  return actionsContextValue;
};

export const ProfileInfoContextProvider = (props) => {
  const partner = useSelector(partnerSelector());
  const isPartnerFetching = useSelector(partnerFetchingSelector());
  const isOnrampEnabled = partner?.isGuardarianEnabled || partner?.isOnrampEnabled;
  const fetchApiKeys = useApiKeys();
  const fetchBaseCurrency = useBaseCurrency();
  const currencyList = useSelector(currencyListSelector());
  const fiatCurrencies = useSelector(fiatCurrenciesSelector());
  const selectedTickers = useSelector(selectedTickersSelector());
  const { cryptoCurrencies } = useCryptoCurrencies(currencyList);
  const { currenciesObject } = useCurrenciesToObject(currencyList);
  const { fiatCurrencies: allFiatCurrencies } = useFiatCurrencies(currencyList, true);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isUpdatingKeys, setIsUpdatingKeys] = React.useState(false);
  const [profileInfo, setProfileInfo] = React.useState({});

  const collectedDataObject = React.useMemo(() => {
    const cryptoCurrenciesFiltered = getFilteredCurrencies(cryptoCurrencies, selectedTickers);
    const displayedFiatCurrencies = isOnrampEnabled
      ? getFilteredCurrencies(fiatCurrencies, selectedTickers)
      : allFiatCurrencies;
    const currenciesWithFiat = [...cryptoCurrencies, ...fiatCurrencies];
    const partnerCurrencies = [...cryptoCurrenciesFiltered, ...displayedFiatCurrencies];

    return {
      partnerCurrencies,
      isOnrampEnabled,
      currencies: currenciesWithFiat,
      currenciesObject,
    };
  }, [
    cryptoCurrencies,
    fiatCurrencies,
    allFiatCurrencies,
    currenciesObject,
    selectedTickers,
    isOnrampEnabled,
  ]);

  const getApiKeys = async () => {
    const { data, status } = await fetchApiKeys();

    if (status === 200) {
      return data?.keys ?? [];
    }

    return [];
  };
  const getBaseCurrency = async () => {
    const { data, status } = await fetchBaseCurrency();

    if (status === 200) {
      return data?.baseCurrency ?? CURRENCY_USD;
    }

    return CURRENCY_USD;
  };
  const init = async (partnerData = {}) => {
    setIsLoading(true);

    const profileFlags = pickByKeys(partnerData || {}, ['isGuardarianEnabled', 'isOnrampEnabled', 'isCustody'], false);

    const [
      getApiKeysResponse,
      getBaseCurrencyResponse,
    ] = await Promise.allSettled([
      getApiKeys(),
      getBaseCurrency(),
    ]);

    const hasActiveApiKeys = hasActiveApiKey(getApiKeysResponse.value);
    const baseCurrencyValue = getBaseCurrencyResponse?.value ?? CURRENCY_USD;

    setProfileInfo({
      flags: profileFlags,
      hasActiveApiKeys,
      baseCurrency: baseCurrencyValue,
    });
    setIsLoading(false);
    setIsLoaded(true);
  };

  useFetchPartnerProfile();
  useAllCurrencies();

  React.useEffect(() => {
    if (isObject(partner)) {
      void init(partner);
    }
  }, [partner]);

  React.useEffect(() => {
    if (isLoaded && isObject(collectedDataObject)) {
      setProfileInfo((prevState) => ({
        ...prevState,
        ...collectedDataObject,
      }));
    }
  }, [isLoaded, collectedDataObject]);

  const updateKeys = React.useCallback(async () => {
    setIsUpdatingKeys(true);

    const apiKeys = await getApiKeys();
    const hasActiveApiKeys = hasActiveApiKey(apiKeys);

    setProfileInfo(prevState => ({
      ...prevState,
      hasActiveApiKeys,
    }));

    setIsUpdatingKeys(false);
  }, []);

  const stateContextValue = React.useMemo(() => ({
    profileInfo,
    isLoading: isPartnerFetching || isLoading,
  }), [profileInfo, isPartnerFetching, isLoading]);

  const actionsContextValue = React.useMemo(() => ({
    updateKeys,
    isUpdatingKeys,
  }), [updateKeys, isUpdatingKeys]);

  return (
    <ProfileInfoActionsContext.Provider value={actionsContextValue}>
      <ProfileInfoStateContext.Provider value={stateContextValue}>
        {props.children}
      </ProfileInfoStateContext.Provider>
    </ProfileInfoActionsContext.Provider>
  );
};
