import React from 'react';
import { SettingTableWrapper } from '../setting-table-wrapper';
import { New2faPopup } from '../new-2fa-popup';
import { PayoutWalletsTable } from './components/payout-wallets-table';
import {
  useFilteredWalletsData,
  useInitState,
  useActions,
  useWhitelistWallets,
} from './hooks';
import { WalletsWhitelistActivation } from './components/wallets-whitelist-activation';
import { PayoutWalletsPopup } from './components/payout-wallets-popup';
import { WhitelistSuccessPopup } from '../mass-payout-whitelist-addresses/components/whitelist-success-popup';
import classes from './styles.module.scss';

type PayoutWalletsSettingsProps = {
  className: string,
};

export const PayoutWalletsSettings: React.FC<PayoutWalletsSettingsProps> = (props) => {
  const { className } = props;

  const {
    extendedWallets,
    isFetched,
    isFetching,
    error,
    hasWallets,
    currenciesFiltered,
    handleAddWalletInWhitelist,
  } = useInitState();

  const {
    walletsWhitelist,
    isWhitelistDisabled,
    isWhitelistError,
  } = useWhitelistWallets(extendedWallets, isFetched);

  const {
    currentWallets,
    walletsFilteredLength,
    handleSearchChange,
    handlePageChange,
    page,
    searchValue,
  } = useFilteredWalletsData(extendedWallets);

  const {
    handlePopupOpen,
    handlePopupClose,
    handleAddWalletPopupOpen,
    handleOptionsClick,
    resetActiveWallet,
    handleSetAsMainWallet,
    handle2faClose,
    ref,
    popupType,
    activeWallet,
    is2FAShow,
    whitelistData,
  } = useActions(extendedWallets, handleAddWalletInWhitelist);

  const {
    isAddInWhitelistPending,
    isSuccessWhitelistShow,
    handleSuccessPopupClose,
    whitelistApiError,
    handleWhitelistAddressSend,
    handleWhitelistSubmit,
  } = whitelistData;

  return (
    <div className={className} ref={ref}>
      <p className={classes.payoutWalletsSettingsTitle}>
        Payment is converted automatically and sent to one of this wallets.
      </p>
      <SettingTableWrapper
        onChange={handleSearchChange}
        onClick={handleAddWalletPopupOpen}
        placeholder="Search wallet (ticker, address)"
        buttonLabel="Add new wallet"
        isNotActivated={!hasWallets}
      >
        <PayoutWalletsTable
          wallets={currentWallets}
          walletsWhitelist={walletsWhitelist}
          isFetched={isFetched}
          isFetching={isFetching}
          error={error}
          hasSearchValue={Boolean(searchValue)}
          page={page}
          onPage={handlePageChange}
          walletsFilteredLength={walletsFilteredLength}
          onPopupOpen={handlePopupOpen}
          onOptionsClick={handleOptionsClick}
          onOptionsReset={resetActiveWallet}
          activeWallet={activeWallet}
          onSetAsMain={handleSetAsMainWallet}
          isWhitelistHidden={isWhitelistDisabled || isWhitelistError}
        />
      </SettingTableWrapper>
      {isWhitelistDisabled && (
        <WalletsWhitelistActivation />
      )}
      <PayoutWalletsPopup
        type={popupType}
        onClose={handlePopupClose}
        currencies={currenciesFiltered}
        allWallets={extendedWallets}
        activeWallet={activeWallet}
        onAddWalletInWhitelist={handleWhitelistSubmit}
      />
      <New2faPopup
        open={is2FAShow}
        onClose={handle2faClose}
        onUpdate={handleWhitelistAddressSend}
        is2faCode
        pending={isAddInWhitelistPending}
        apiErrorMessage={whitelistApiError}
      />
      <WhitelistSuccessPopup
        className={classes.payoutWalletsSettingsSuccessPopup}
        open={isSuccessWhitelistShow}
        onClose={handleSuccessPopupClose}
        title="Request successfully sent"
        description="Your wallet address will be added to the whitelist within 1 business day."
        imagePath="/images/success-image.svg"
        withOkButton
      />
    </div>
  );
};
