import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PAYMENT_CREATE_SCHEMA } from '../payment-link-page/utils';
import { isFunction } from '../../helpers/is-function';
import { Field } from '../ui/field';
import { Button } from '../ui/button';
import ToolTip from '../tool-tip';
import { CheckboxWithText } from '../ui/checkbox-with-text';
import { CurrenciesSelectField } from '../currencies-select-field';
import { useMinAmount } from '../payment-link-page/hooks';
import { isCurrencyNetworkShow } from '../../helpers/is-currency-network-show';
import { safeToUpperCase } from '../../helpers/safe-to-upper-case';
import { NETWORKS } from '../../constants/networks';
import { safeToLowerCase } from '../../helpers/safe-to-lower-case';
import styles from './styles.module.scss';

const MIN_AMOUNT_ERROR_TEXT = 'Amount is below minimum for this coin';

export const PaymentLinkForm = (props) => {
  const {
    className,
    ticker,
    currencies,
    error,
    onError,
    isLoading,
    onSubmit,
    isOnrampEnabled,
  } = props;

  const {
    control,
    handleSubmit,
    formState,
  } = useForm({
    resolver: yupResolver(PAYMENT_CREATE_SCHEMA),
  });
  const {
    orderDescription,
    orderId,
    price,
  } = formState.errors;
  const orderDescriptionErrorMessage = orderDescription?.message ?? null;
  const orderIdErrorMessage = orderId?.message ?? null;
  const priceErrorMessage = price?.message ?? null;

  const [minAmountErrorText, setMinAmountErrorText] = React.useState(null);
  const [selectedCurrency, setSelectedCurrency] = React.useState({});
  const [isFixedRate, setIsFixedRate] = React.useState(false);
  const [isFeePaidByUser, setIsFeePaidByUser] = React.useState(false);
  const { minAmount, changePayCurrency } = useMinAmount(onError);

  const handleFormSubmit = (data) => {
    const price = data?.price ?? 0;
    const priceNumber = Number(price);

    if (!priceNumber || (priceNumber < minAmount)) {
      setMinAmountErrorText(MIN_AMOUNT_ERROR_TEXT);

      return;
    }

    if (isFunction(onSubmit)) {
      const nextData = {
        ...data,
        isFixedRate,
        isFeePaidByUser,
      };

      onSubmit(nextData);
    }
  };
  const handlePriceChange = (func) => (value) => {
    func(value);

    if (value >= minAmount) {
      setMinAmountErrorText(null);
    }
  };
  const handleFixRateChange = () => {
    if (isFixedRate && isFeePaidByUser) {
      setIsFixedRate(false);
      setIsFeePaidByUser(false);
    } else {
      setIsFixedRate((prev) => !prev);
    }
  };
  const handleFeePaidChange = () => {
    if (!isFixedRate && !isFeePaidByUser) {
      setIsFeePaidByUser(true);
      setIsFixedRate(true);
    } else {
      setIsFeePaidByUser((prev) => !prev);
    }
  };
  const handleCurrencySelect = (func) => (currency) => {
    const ticker = currency?.code ?? '';

    func(ticker);

    setSelectedCurrency(currency);
  };

  React.useEffect(() => {
    void changePayCurrency(selectedCurrency?.code);
  }, [selectedCurrency?.code]);

  React.useEffect(() => {
    let nextTimerId = null;

    if (error && isFunction(onError)) {
      nextTimerId = setTimeout(() => {
        onError("");
      }, 3000);
    }

    return () => {
      if (nextTimerId) {
        clearTimeout(nextTimerId);
        if (isFunction(onError)) {
          onError("");
        }
      }
    };
  }, [error]);

  const isMinAmountTextShow = Boolean(minAmount) && selectedCurrency?.code;
  const selectedCoinCurrentTicker = selectedCurrency?.ticker ?? selectedCurrency?.code;
  const selectedCoinNetwork = selectedCurrency?.network ?? null;
  const selectedCoinNetworkUpperCased = safeToUpperCase(selectedCoinNetwork);
  const isNetworkShow = isCurrencyNetworkShow(selectedCoinNetwork, selectedCurrency?.code);
  const selectedCoinNetworkNormalized =
    NETWORKS[safeToLowerCase(selectedCoinNetwork)]?.name ?? selectedCoinNetworkUpperCased;

  return (
    <form
      className={cn(styles.form, className)}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <div className={styles.formTitle}>
        Create payment link
      </div>
      <div className={styles.formContent}>
        <Controller
          name="payCurrency"
          control={control}
          defaultValue={null}
          render={({ field }) => (
            <CurrenciesSelectField
              className={styles.formInputCurrencySelect}
              label="Pay currency"
              placeholder="All currencies"
              placeholderText="Client can choose any supported crypto for payment"
              currencies={currencies}
              onSelect={handleCurrencySelect(field.onChange)}
              hasInnerMenu
              isOnRampEnabled={isOnrampEnabled}
            />
          )}
        />
        <Controller
          name="price"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Field
              className={cn(styles.formInput, styles.formInputPrice)}
              title="Price"
              data={{
                id: field.name,
                value: field.value,
                onChange: handlePriceChange(field.onChange),
                name: field.name,
                ticker: ticker,
                placeholder: '0.00',
                error: priceErrorMessage || minAmountErrorText,
                pattern: /^\d*[.]?\d{0,8}$/,
              }}
            />
          )}
        />
        <Controller
          name="orderDescription"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Field
              className={cn(styles.formInput, styles.formInputDescription)}
              title="Order description"
              data={{
                id: field.name,
                value: field.value,
                onChange: field.onChange,
                name: field.name,
                placeholder: 'Optional',
                error: orderDescriptionErrorMessage,
              }}
            />
          )}
        />
        <Controller
          name="orderId"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Field
              className={cn(styles.formInput, styles.formInputOrderId)}
              title="Order ID"
              data={{
                id: field.name,
                value: field.value,
                onChange: field.onChange,
                name: field.name,
                placeholder: 'Optional',
                error: orderIdErrorMessage,
              }}
            />
          )}
        />
        <div className={styles.formCheckboxes}>
          <CheckboxWithText
            className={styles.formCheckbox}
            onChange={handleFixRateChange}
            checked={isFixedRate}
          >
            Fixed rate
            {' '}
            <img
              width={16}
              height={16}
              src="/images/info-icon.svg"
              alt="Icon help"
              data-tooltip-id="payment-link-form-tooltip"
              data-tooltip-content="The rate for this transaction will be frozen for 20 minutes."
              decoding="async"
            />
          </CheckboxWithText>
          <CheckboxWithText
            className={styles.formCheckbox}
            onChange={handleFeePaidChange}
            checked={isFeePaidByUser}
          >
            Fee paid by user
            {' '}
            <img
              width={16}
              height={16}
              src="/images/info-icon.svg"
              alt="Icon help"
              data-tooltip-id="payment-link-form-tooltip"
              data-tooltip-content="Your customer will cover all the fees for this payment."
              decoding="async"
            />
          </CheckboxWithText>
        </div>
        <div className={styles.formMinAmount}>
          {isMinAmountTextShow && (
            <>
              Сurrent
              {' '}
              <span>minimum payment amount</span>
              {' '}
              for
              {' '}
              {selectedCoinCurrentTicker}
              {' '}
              {isNetworkShow && (
                <>
                  on
                  {' '}
                  {selectedCoinNetworkNormalized}
                  {' '}
                </>
              )}
              is
              {' '}
              {minAmount}
              {' '}
              {ticker}
            </>
          )}
        </div>
        <Button
          type="submit"
          className={styles.formButton}
          disabled={isLoading}
        >
          {isLoading
            ? (
              <img
                width={30}
                height={30}
                src="/images/loader-white-on-blue-icon.gif"
                alt="Loader Icon"
                decoding="async"
              />
            )
            : 'Confirm'}
        </Button>
        {error && (
          <div className={styles.errorMessage}>
            {error}
          </div>
        )}
      </div>
      <ToolTip id="payment-link-form-tooltip" />
    </form>
  );
};

PaymentLinkForm.defaultProps = {
  className: null,
  ticker: '',
  currencies: [],
  minAmount: 0,
  error: null,
  onError: null,
  onChangePayCurrency: null,
  isLoading: false,
  onSubmit: null,
  isOnRampEnabled: false,
};

PaymentLinkForm.propTypes = {
  className: PropTypes.string,
  ticker: PropTypes.string,
  currencies: PropTypes.arrayOf(PropTypes.object),
  minAmount: PropTypes.number,
  error: PropTypes.string,
  onError: PropTypes.func,
  onChangePayCurrency: PropTypes.func,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  isOnRampEnabled: PropTypes.bool,
};
