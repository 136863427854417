import * as React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import {
  MASS_PAYOUTS_PAGE_THS_DESKTOP,
  MASS_PAYOUTS_PAGE_THS_MOBILE,
  MASS_PAYOUTS_PAGE_PER_PAGE,
} from '../../helpers';
import { MassPayoutsTableRow } from '../mass-payouts-table-row';
import { HistoryTableWithPagination } from '../../../history-table-with-pagination';
import { hasElementsInArray } from '../../../../helpers/has-elements-in-array';
import classes from './styles.module.scss';

export const MassPayoutsTable = React.memo((props) => {
  const {
    items,
    hasSearchData,
    loading,
    onClick,
    count,
    onPaginatorClick,
    page,
    apiError,
  } = props;

  const isDesktop = useMediaQuery('(min-width: 992px)');
  const hasItems = hasElementsInArray(items);

  return (
    <HistoryTableWithPagination
      className={classes.massPayoutsTable}
      items={items}
      hasSearchData={hasSearchData}
      fetching={loading}
      apiError={apiError}
      minWidth={450}
      desktop={isDesktop}
      thsDesktop={MASS_PAYOUTS_PAGE_THS_DESKTOP}
      thsMobile={MASS_PAYOUTS_PAGE_THS_MOBILE}
      perPage={MASS_PAYOUTS_PAGE_PER_PAGE}
      emptyText="You don’t have any payouts to display. It takes a couple of clicks to create one!"
      notFoundText="No payouts matching this criteria."
      count={count}
      onPaginatorClick={onPaginatorClick}
      page={page}
    >
      {hasItems && items.map((item) => (
        <MassPayoutsTableRow
          key={item?.key}
          isDesktop={isDesktop}
          onClick={onClick}
          payout={item}
        />
      ))}
    </HistoryTableWithPagination>
  );
});

MassPayoutsTable.defaultProps = {
  items: null,
  hasItems: false,
  hasSearchData: false,
  loading: false,
  onClick: null,
  count: 0,
  onPaginatorClick: null,
  page: 1,
  apiError: null,
};

MassPayoutsTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  hasItems: PropTypes.bool,
  hasSearchData: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  count: PropTypes.number,
  onPaginatorClick: PropTypes.func,
  page: PropTypes.number,
  apiError: PropTypes.string,
};

MassPayoutsTable.displayName = 'MassPayoutsTable';
