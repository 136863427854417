import JsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

/**
 * @param options {Object}
 */
export const saveToPdf = (options) => {
  const name = options?.name ?? 'document';
  const head = options?.headerData ?? [];
  const body = options?.bodyData ?? [];
  const columnStyles = options?.columnOptions ?? {};
  const orientation = options?.orientation ?? null;
  const margin = options?.margin ?? 2;
  const document = new JsPDF({ orientation });
  const table = {
    head,
    body,
    columnStyles,
    margin,
  };

  autoTable(document, table);
  document.save(`${name}.pdf`);
};
