import React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';
import {
  StatisticsTurnoverOptionsType,
  StatisticsTurnoverType,
} from '../../helpers/types';

const mapFn = (data: StatisticsTurnoverType) => ({
  result: {
    amount: data?.result?.amount ?? null,
    count: data?.result?.count ?? null,
    baseCurrency: data?.result?.base_currency ?? null,
  },
});

export const useStatisticsTurnover = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (options: StatisticsTurnoverOptionsType) => {
    const [cancelToken] = getRequestSource();
    const requestPromise = client.get(
      '/stats/payments/turnover',
      {
        params: {
          base_currency: options?.baseCurrency || null,
          date_from: options?.dateFrom || null,
          date_to: options?.dateTo || null,
        },
        cancelToken,
      },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [client, getRequestSource]);
};
