import React from 'react';
import { usePayoutAccounts } from '../api/modules/account/use-payout-accoutns';
import { getSafeErrorMessageText } from '../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../helpers/console-error-message';
import { BankAccount } from '../types';
import { getUrlWithDomain } from '../helpers/get-url-with-domain';
import { safeToLowerCase } from '../helpers/safe-to-lower-case';

export const useBankAccounts = (isAccountsUpdate: boolean, provider: null | string = null) => {
  const fetchAccountsRequestSend = usePayoutAccounts();
  const [accounts, setAccounts] = React.useState<Array<BankAccount>>([]);
  const [isAccountsLoading, setIsAccountsLoading] = React.useState(false);

  const fetchAccounts = React.useCallback(async () => {
    setIsAccountsLoading(true);
    const params = provider ? { provider } : null;
    const {
      data,
      status,
      errorMessage,
    }: { data: any, status: number, errorMessage: string } = await fetchAccountsRequestSend(params);

    if (status === 200 && data) {
      const accountsWithSelectOptions = data?.result
        .map((el: BankAccount) => ({
          ...el,
          name: `${el.fiatCurrencyCode}, ${el.providerAccountInfo?.accountNumber}`,
          logoUrl: getUrlWithDomain(`/images/coins/${safeToLowerCase(el.fiatCurrencyCode)}.svg`),
        }))
        ?? [];
      setAccounts(accountsWithSelectOptions);
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/fiat-payouts/accounts');
    }

    setIsAccountsLoading(false);
  }, [fetchAccountsRequestSend, provider]);

  React.useEffect(() => {
    if (isAccountsUpdate) {
      fetchAccounts();
    }
  }, [fetchAccounts, isAccountsUpdate]);

  return { accounts, isAccountsLoading };
};
