import * as React from 'react';
import PropTypes from 'prop-types';

export const DashboardIcon = (props) => {
  const { color } = props;

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.9958 8.98438C16.9958 9.54688 16.5791 9.9875 16.1068 9.9875H15.2179L15.2373 14.9937C15.2373 15.0781 15.2318 15.1625 15.2234 15.2469V15.75C15.2234 16.4406 14.7262 17 14.1122 17H13.6677C13.6372 17 13.6066 17 13.5761 16.9969C13.5372 17 13.4983 17 13.4594 17H12.5565H11.8898C11.2759 17 10.7786 16.4406 10.7786 15.75V15V13C10.7786 12.4469 10.3814 12 9.88965 12H8.11172C7.62001 12 7.22275 12.4469 7.22275 13V15V15.75C7.22275 16.4406 6.72549 17 6.11155 17H5.44482H4.55864C4.51697 17 4.4753 16.9969 4.43363 16.9937C4.40029 16.9969 4.36695 17 4.33362 17H3.88913C3.27519 17 2.77793 16.4406 2.77793 15.75V12.25C2.77793 12.2219 2.77793 12.1906 2.78071 12.1625V9.9875H1.88896C1.38892 9.9875 1 9.55 1 8.98438C1 8.70312 1.08334 8.45312 1.2778 8.23438L8.40063 1.25C8.59509 1.03125 8.81733 1 9.01179 1C9.20625 1 9.42849 1.0625 9.59518 1.21875L16.6902 8.23438C16.9125 8.45312 17.0236 8.70312 16.9958 8.98438Z" />
    </svg>
  );
};

DashboardIcon.defaultProps = {
  color: '#616161',
};

DashboardIcon.propTypes = {
  color: PropTypes.string,
};
