/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Layout } from '../layout';
import { CurrentRoutes } from '../current-routes';

export const RoutesSwitcher = (props) => {
  const {
    routes,
    privateRoutes,
    auth,
    authFetched,
  } = props;

  const getPrivateRouteComponent = React.useCallback(({ Component, redirect }) => {
    const PrivateRoute = (routeProps) => (
      auth
        ? <Component {...routeProps} />
        : <Redirect to={redirect} />
    );

    PrivateRoute.displayName = 'PrivateRoute';

    return PrivateRoute;
  }, [auth]);


  const getRouteComponent = React.useCallback((route) => {
    if (!authFetched && route.private) {
      return () => null;
    }

    return route.private
      ? getPrivateRouteComponent(route)
      : route.Component;
  }, [
    authFetched,
    getPrivateRouteComponent,
  ]);

  return (
    <Layout
      auth={auth}
      fetched={authFetched}
    >
      <CurrentRoutes
        authFetched={authFetched}
        auth={auth}
        routes={routes}
        privateRoutes={privateRoutes}
        onRouteComponent={getRouteComponent}
      />
    </Layout>
  );
};

RoutesSwitcher.defaultProps = {
  routes: [],
  privateRoutes: [],
  auth: false,
  authFetched: false,
};

RoutesSwitcher.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  privateRoutes: PropTypes.arrayOf(PropTypes.object),
  auth: PropTypes.bool,
  authFetched: PropTypes.bool,
};
