import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { MainHead } from '../main-head';
import { Footer } from '../footer';
import { MainNavigation } from '../main-navigation';
import { ROUTES_WITH_FOOTER } from '../../constants/routes';
import CookiesAlert from '../cookies-alert';
import { LoaderIcon } from '../icons/loader-icon';
import { getItemFromLocalStorage } from '../../libs/local-storage';
import { PAGE_NAMES_TO_DISPLAY_INFO_LOCAL_STORAGE_NAME } from '../../constants/app-constants';
import { setPageNamesToDisplayInfo } from '../../store/settings/reducer';
import { hasElementsInArray } from '../../helpers/has-elements-in-array';
import { useNotificationData } from '../../hooks/use-notification-data';
import { NotificationMessage } from '../notification-message';
import classes from './styles.module.scss';

export const Layout = (props) => {
  const {
    children,
    auth,
    fetched,
  } = props;

  const dispatch = useDispatch();
  const location = useLocation();
  const pathName = location?.pathname ?? null;
  const isRoutePathFound = ROUTES_WITH_FOOTER.includes(pathName);
  const isUserAuthorized = fetched && auth;
  const isUserNotAuthorized = fetched && !auth;
  const withMenu = isUserAuthorized && !isRoutePathFound;
  const withFooter = isUserNotAuthorized || isRoutePathFound;

  const {
    notificationMessage,
    checkNotificationMessage,
    handleNotificationMessageClose,
  } = useNotificationData();

  const LoaderTemplate = React.useMemo(() => (
    <div className={classes.loader}>
      <LoaderIcon size={50} />
    </div>
  ), []);

  React.useEffect(() => {
    const pageNamesToDisplayInfoFromLocalStorage =
      getItemFromLocalStorage(PAGE_NAMES_TO_DISPLAY_INFO_LOCAL_STORAGE_NAME);
    const pageNamesToDisplayInfoArray = pageNamesToDisplayInfoFromLocalStorage
      && JSON.parse(pageNamesToDisplayInfoFromLocalStorage);

    if (hasElementsInArray(pageNamesToDisplayInfoArray)) {
      dispatch(setPageNamesToDisplayInfo(pageNamesToDisplayInfoArray));
    }
  }, []);

  React.useEffect(() => {
    checkNotificationMessage();
  }, [pathName]);

  return (
    <>
      <MainHead />
      {withMenu && (
        <NotificationMessage onClose={handleNotificationMessageClose}>
          {notificationMessage}
        </NotificationMessage>
      )}
      <div className={cn([
        classes.body,
        withMenu && classes.bodyWithMenu,
      ])}>
        {withMenu && (
          <>
            <MainNavigation />
            <React.Suspense fallback={LoaderTemplate}>
              <div className={classes.content}>
                {children}
              </div>
            </React.Suspense>
          </>
        )}
        {withFooter && (
          <>
            <React.Suspense fallback={LoaderTemplate}>
              {children}
            </React.Suspense>
            <Footer />
          </>
        )}
      </div>
      <CookiesAlert />
    </>
  );
};

Layout.defaultProps = {
  children: null,
};

Layout.propTypes = {
  children: PropTypes.node,
};
