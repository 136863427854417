import React from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import classes from './styles.module.scss';
import { systemStatusSelector } from '../../store/settings/selectors';

export const SystemStatuses: React.FC<{ className: string }> = (props) => {
  const { className } = props;

  const systemStatus = useSelector(systemStatusSelector());
  const systemStatusNormalized = systemStatus || '-';

  return (
    <div className={cn(classes.systemStatuses, className)}>
      <div className={classes.systemStatusesItem}>
        <div className={classes.systemStatusesItemKey}>
          System status
        </div>
        <div className={classes.systemStatusesItemValue}>
          {systemStatusNormalized}
        </div>
      </div>
      <div className={classes.systemStatusesItem}>
        <div className={classes.systemStatusesItemKey}>
          Currencies availability
        </div>
        <div className={classes.systemStatusesItemValue}>
          99%
        </div>
      </div>
      <div className={classes.systemStatusesText}>
        Check the whole list on the
        <br />
        <a
          href="https://nowpayments.io/status-page"
          target="_blank"
          rel="noreferrer"
        >
          coin status page
        </a>
      </div>
    </div>
  );
};
