import classNames from 'classnames';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/button';
import Metatags from '../../../components/metatags';
import root from '../styles.module.scss';
import { ROUTES } from '../../../constants/routes';
import { trackEvent } from '../../../helpers/utils/track-event';
import { TRACK_EVENT_NAMES } from '../../../constants/track-event-names';
import styles from './styles.module.scss';

const QUERY_PARAMS_NAME = 'queryParams';

const SignUpSuccess = () => {
  const history = useHistory();

  const handleMoveToCreateInvoice = () => {
    history.push(ROUTES.INVOICES);
  };
  const handleMoveToHomaPage = () => {
    history.push(ROUTES.DASHBOARD);
  };

  React.useEffect(() => {
    const searchParams = new URLSearchParams(sessionStorage.getItem(QUERY_PARAMS_NAME));
    const utmEventCategory = searchParams.get('utm_category');
    const affiliateLinkId = searchParams.get('link_id');
    const trackEventData = {
      action: 'completed',
      category: utmEventCategory,
    };
    if (affiliateLinkId) {
      trackEventData.affiliate_from = affiliateLinkId;
    }
    trackEvent(TRACK_EVENT_NAMES.SIGN_UP_COMPLETE, trackEventData);
  }, []);

  return (
    <div className={root.formContainer}>
      <Metatags
        title={'Sign up success | NOWPayments'}
        description={'Please provide the email address that you used when you signed up for your NOWPayments account. We will send you an email that will allow you to reset your password.'}
        url={'https://account.nowpayments.io/sign-up-success'}
      />
      <div className={root.form}>
        <div className={root.formHeader}>
          <p className={root.logo}>
            <span className={classNames(root.logo, root.blueText)}>NOW</span>
            Payments
          </p>
        </div>
        <div className={root.divider} />
        <div className={styles.successImage} />
        <div className={styles.contentMsgSuccess}>
          <p className={root.titleBig}>Success!</p>
        </div>
        <div className={styles.contentText}>
          <p className={root.titleSmall}>You have everything set up to accept crypto.</p>
        </div>
        <div className={styles.contentSubText}>
          <p className={root.titleSmall}>Easiest way to test it out:</p>
        </div>
        <div className={styles.footer}>
          <Button variant="green" onClick={handleMoveToHomaPage}>
            Go to main page
          </Button>
          <div className={root.divider} />
          <p
            className={root.link}
            onClick={handleMoveToCreateInvoice}
          >
            Create first payment
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignUpSuccess;
