import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { InputSearch } from '../../../ui/input-search';
import { MenuFilterIcon } from '../../../icons/menu-filter-icon';
import styles from '../../styles.module.scss';
import { InvoiceFiltersFormMemo } from '../invoice-filters-form';
import { SEARCH_SOURCE } from '../../../../constants/app-constants';
import { debounce } from '../../../../helpers/utils';

/*
  Form data
  {
    createdAt: number,
    currency: string,
    orderId: string,
    paymentId: string,
    paymentLinkId: string,
    fixedRate: boolean,
    feePaidByUser?: boolean,
  }
 */

export const InvoiceFilters = ({
  buttonElement = null,
  currencies = [],
  isInvoiceFilterSet = false,
  setInvoiceFilters,
}) => {
  const [isFilterFormVisible, setIsFilterFormVisible] = React.useState(false);
  const handleIdFilterChange = React.useRef(null);

  React.useEffect(() => {
    handleIdFilterChange.current = debounce((valueRaw) => {
      const valueString = Boolean(valueRaw) && valueRaw.trim();

      const newFilterState = valueString ? {
        orderId: valueString,
        paymentLinkId: valueString,
      } : null;

      setInvoiceFilters(newFilterState, SEARCH_SOURCE.SEARCH_FIELD);
    }, 500);
  }, [setInvoiceFilters]);

  const handleFiltersToggle = React.useCallback(() => {
    setIsFilterFormVisible((prev) => !prev);
  }, []);

  const handleInvoiceFiltersSubmit = React.useCallback((data) => {
    const formData = { ...data };
    const hasFormValues = Object.values(formData).filter(v => v !== null || v !== '').length;
    const newFilterState = hasFormValues ? formData : null;

    setInvoiceFilters(newFilterState, SEARCH_SOURCE.SEARCH_FORM);
  }, [setInvoiceFilters]);

  const isFilterMenuIconActive = isFilterFormVisible || isInvoiceFilterSet;

  return (
    <>
      <div className={styles.searchBox}>
        {buttonElement}
        <InputSearch
          id="payment-link-search"
          className={styles.searchInput}
          placeholder="Payment link ID, Order ID"
          onChange={handleIdFilterChange.current}
          ImageAdditional={(
            <span
              onClick={handleFiltersToggle}
              className={cn([
                styles.searchFilterMenuIcon,
                isFilterMenuIconActive && styles.searchFilterMenuIconActive,
              ])}
            >
              <MenuFilterIcon />
            </span>
          )}
        />
      </div>
      <div className={cn([
        styles.invoiceFilters,
        isFilterFormVisible && styles.invoiceFiltersActive,
      ])}>
        <InvoiceFiltersFormMemo
          className={styles.invoiceFiltersBox}
          currencies={currencies}
          onSubmit={handleInvoiceFiltersSubmit}
          onClose={handleFiltersToggle}
          onSearchFormData={setInvoiceFilters}
        />
      </div>
    </>
  );
};

InvoiceFilters.propTypes = {
  setInvoiceFilters: PropTypes.func.isRequired,
  currencies: PropTypes.arrayOf(PropTypes.object).isRequired,
  isInvoiceFilterSet: PropTypes.bool,
  buttonElement: PropTypes.element,
};
