import React from 'react';
import { nanoid } from 'nanoid';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';

const itemMapFn = (data) => {
  const result = {};

  for (const key in data) {
    const item = data[key];

    result[key] = {
      key: nanoid(),
      amount: item?.amount ?? null,
      cgId: item?.cg_id ?? null,
      code: item?.code ?? null,
      enable: item?.enable ?? false,
      explorerLinkHash: item?.explorer_link_hash ?? null,
      extraIdExists: item?.extra_id_exists ?? false,
      extraIdRegex: item?.extra_id_regex ?? null,
      id: item?.id ?? null,
      isMaxLimit: item?.is_maxlimit ?? false,
      lastUsedAddress: item?.lastUsedAddress ?? null,
      logo_url: item?.logo_url ?? null,
      name: item?.name ?? null,
      network: item?.network ?? null,
      networkPrecision: item?.network_precision ?? null,
      pendingAmount: item?.pendingAmount ?? null,
      precision: item?.precision ?? null,
      priority: item?.priority ?? null,
      smartContract: item?.smart_contract ?? null,
      track: item?.track ?? false,
      walletRegex: item?.wallet_regex ?? null,
      convertedAmount: item?.convertedAmount ?? null,
      convertedCurrency: item?.convertedCurrency ?? null,
      availableForToConversion: item?.available_for_to_conversion ?? false,
      ticker: item?.ticker ?? false,
    };
  }

  return result;
};
const mapFn = (data) => ({
  balance: data?.balance ? itemMapFn(data?.balance) : {},
});

export const useBalance = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async () => {
    const [cancelToken] = getRequestSource();
    const requestPromise = client.get(
      '/balance',
      null,
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
