import * as React from 'react';
import PropTypes from 'prop-types';
import { InfoAboutUse } from '../../../../components/shared/info-about-use';

export const DonationsInfoAboutUse = (props) => {
  const {
    className,
    name,
    onClose,
  } = props;

  return (
    <InfoAboutUse
      className={className}
      name={name}
      onClose={onClose}
    >
      Unlock the power of donation tools and experience the joy of giving back!
      <div>
        With our <strong>donation widget</strong>, you can easily receive donations from anywhere
        on the Internet. Input your API key into the code snippet, embed the code into your
        website, and start receiving crypto donations now.
      </div>
      <div>
        <strong>Donation buttons</strong> make it simple to add a donation tool to your website, blog,
        forum signature, Twitch, or anything else.
      </div>
      <div>
        Use customizable <strong>donation links</strong> to receive donations on social media
        platforms like Twitter and YouTube.
      </div>
    </InfoAboutUse>
  );
};

DonationsInfoAboutUse.defaultProps = {
  className: null,
  name: null,
  onClose: null,
};

DonationsInfoAboutUse.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onClose: PropTypes.func,
};
