import React from 'react';
import { useSelector } from 'react-redux';
import {
  isStoreDataFetchedSelector,
  paymentSettingsFetchingSelector,
  storeDataErrorSelector,
  walletsSelector,
} from '../../../store/payment-settings/selectors';
import { useCurrenciesToObject } from '../../../hooks/use-currencies-to-object';
import {
  availableCurrenciesSelector,
  currenciesErrorSelector,
  currenciesFetchedSelector,
  currenciesFetchingSelector,
} from '../../../store/currencies/selectors';
import { safeToUpperCase } from '../../../helpers/safe-to-upper-case';
import { ExtendedWallet } from '../types';
import { Currency, Wallet } from '../../../default-types';

export const useInitState = () => {
  const wallets = useSelector(walletsSelector());
  const currencies = useSelector(availableCurrenciesSelector());
  const isWalletsFetched = useSelector(isStoreDataFetchedSelector());
  const isWalletsFetching = useSelector(paymentSettingsFetchingSelector());
  const walletsError = useSelector(storeDataErrorSelector());
  const isCurrencyFetching = useSelector(currenciesFetchingSelector());
  const isCurrencyFetched = useSelector(currenciesFetchedSelector());
  const currencyError = useSelector(currenciesErrorSelector());
  const { currenciesObject } = useCurrenciesToObject(currencies);
  const [whitelistRequestedWallets, setWhitelistRequestedWallets] = React.useState<Array<string>>([]);

  const isFetched = isWalletsFetched && isCurrencyFetched;
  const isFetching = isWalletsFetching || isCurrencyFetching;
  const error = walletsError || currencyError;
  const hasWallets = isWalletsFetched && wallets?.length > 0;

  const currenciesFiltered = React.useMemo(() => {
    if (!currencies || !wallets) return [];

    return currencies.filter((currency: Currency) => (
      !wallets.find((wallet: Wallet) => safeToUpperCase(wallet.baseCurrency) === currency.code)
    ));
  }, [currencies, wallets]);

  const walletsCurrency = React.useMemo(() => {
    if (!wallets) return [];

    return wallets.map((wallet: ExtendedWallet) => {
      const currentCurrencyCode = safeToUpperCase(wallet.baseCurrency);
      const currentCurrency = currenciesObject[currentCurrencyCode as
        keyof typeof currenciesObject] as never as Currency;
      return {
        ...wallet,
        currentTicker: currentCurrency?.ticker || '',
        ticker: currentCurrency?.code || '',
        walletRegex: currentCurrency?.wallet_regex || '',
        logoUrl: currentCurrency?.logo_url || '',
        network: currentCurrency?.network || '',
        extraIdRegex: currentCurrency?.extra_id_regex || '',
      };
    });
  }, [currenciesObject, wallets]);

  const extendedWallets = React.useMemo(() => {
    if (whitelistRequestedWallets.length === 0) return walletsCurrency;
    return walletsCurrency.map((wallet: ExtendedWallet) => {
      if (whitelistRequestedWallets.includes(wallet.id)) {
        return { ...wallet, whitelistRequest: true };
      }
      return wallet;
    });
  }, [whitelistRequestedWallets, walletsCurrency]);

  const handleAddWalletInWhitelist = (walletId: string | null) => {
    if (walletId) {
      setWhitelistRequestedWallets((prev) => [...prev, walletId]);
    }
  };

  return {
    extendedWallets,
    isFetched,
    isFetching,
    error,
    hasWallets,
    currenciesFiltered,
    handleAddWalletInWhitelist,
  };
};
