import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import apiClient from '../../api/api-client';
import { clearAuthToken, clearMasterId } from '../../libs/local-storage';
import { clearAuthCookieToken } from '../../libs/cookies';
import config from '../../config';
import { useMediaQuery } from '../../hooks/use-media-query';
import { Burger } from '../burger';
import { MainMenu } from '../main-menu';
import { MAIN_LINKS, MAIN_NAVIGATION } from '../../constants/navigation';
import { partnerSelector } from '../../store/partner/selectors';
import classes from './styles.module.scss';

const SITE_DOMAIN = config.front_domain;
const CURRENT_YEAR = new Date().getFullYear();

export const MainNavigation = () => {
  const partner = useSelector(partnerSelector());
  const isDesktop = useMediaQuery('(min-width: 992px)');

  const [isMobileMenuShow, setIsMobileMenuShow] = React.useState(false);

  const handleSignOutClick = (event) => {
    event.preventDefault();
    apiClient.logout();
    clearMasterId();
    clearAuthToken();
    clearAuthCookieToken();

    if (window.sessionStorage.getItem('register-api-key')) {
      window.sessionStorage.removeItem('register-api-key');
    }

    window.location.href = '/';
  };
  const handleBurgerToggle = () => {
    setIsMobileMenuShow((prev) => !prev);
  };
  const handleMenuCLose = () => {
    setIsMobileMenuShow(false);
  };

  return (
    <div className={classes.mainNavigation}>
      <div className={classes.mainNavigationHeader}>
        <a
          className={classes.mainNavigationLogo}
          href={SITE_DOMAIN}
        >
          <span>NOW</span>
          Payments
        </a>
        {!isDesktop && (
          <Burger
            className={classes.mainNavigationBurger}
            active={isMobileMenuShow}
            onClick={handleBurgerToggle}
          />
        )}
      </div>
      <div className={cn([
        classes.mainNavigationBody,
        !isDesktop && isMobileMenuShow && classes.mainNavigationBodyActive,
      ])}>
        <MainMenu
          className={classes.mainNavigationItems}
          items={MAIN_NAVIGATION}
          onClick={handleMenuCLose}
        />
        <MainMenu
          className={classes.mainNavigationListSecond}
          items={MAIN_LINKS}
          onSignOut={handleSignOutClick}
          onClick={handleMenuCLose}
        />
        {partner?.email && (
          <div
            className={classes.mainNavigationPartnerEmail}
            title={partner.email}
          >
            {partner.email}
          </div>
        )}
        <div className={classes.mainNavigationBodyFooter}>
          <a
            className={classes.mainNavigationSupport}
            href="mailto:support@nowpayments.io"
          >
            support@nowpayments.io
          </a>
          <p className={classes.mainNavigationCopyRight}>
            {CURRENT_YEAR}
            {' '}
            &copy;
            {' '}
            NOWPayments
          </p>
        </div>
      </div>
    </div>
  );
};
