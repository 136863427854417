import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { nanoid } from 'nanoid';
import { hasElementsInArray } from '../../helpers/has-elements-in-array';
import { LoaderIcon } from '../icons/loader-icon';
import ToolTip from '../tool-tip';
import classes from './styles.module.scss';

const HistoryTable = (props) => {
  const {
    className,
    thItems,
    colSpan,
    loading,
    historyData,
    hasData,
    emptyText,
    emptyElement,
    notFoundText,
    children,
    apiError,
    isTimeoutError,
    minWidth,
  } = props;

  const hasThItems = hasElementsInArray(thItems);
  const hasHistoryData = hasElementsInArray(historyData);
  const currentMinWidthTable = minWidth ? `${minWidth}px` : null;

  const isHistoryDataShow = !loading && !apiError && !isTimeoutError && hasData && hasHistoryData;
  const isHistoryDataNotFoundShow = !loading && !apiError && !isTimeoutError && hasData && !hasHistoryData;

  return (
    <div className={cn(classes.historyTableBox, className)} style={{ minWidth: currentMinWidthTable }}>
      <table cellSpacing={25} className={classes.historyTable}>
        {hasThItems && (
          <thead>
            <tr>
              {thItems.map((item) => (
                <th
                  key={item?.id ?? nanoid()}
                  className={classes.historyTableTh}
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: item?.name }}
                  />
                  {item?.tooltipText && (
                    <>
                      <img
                        className={classes.historyTableThImg}
                        width={15}
                        height={15}
                        src="/images/info-icon.svg"
                        alt="Icon help"
                        data-tooltip-id="history-table-tooltip"
                        data-tooltip-content={item.tooltipText}
                        decoding="async"
                      />
                      <ToolTip id="history-table-tooltip" />
                    </>
                  )}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody className={classes.historyTableTbody}>
          {loading && (
            <tr className={classes.historyTableLoaderTr}>
              <td colSpan={colSpan}>
                <LoaderIcon />
              </td>
            </tr>
          )}
          {!hasData && !loading && !apiError && (
            <tr className={classes.historyTableEmptyTr}>
              <td colSpan={colSpan}>
                {emptyText}
                {emptyElement}
              </td>
            </tr>
          )}
          {isHistoryDataNotFoundShow && (
            <tr className={classes.historyTableEmptyTr}>
              <td colSpan={colSpan}>
                {notFoundText}
              </td>
            </tr>
          )}
          {apiError && !loading && !isTimeoutError && (
            <tr className={classes.historyTableError}>
              <td colSpan={colSpan}>
                Error: {apiError}
              </td>
            </tr>
          )}
          {isTimeoutError && !loading && (
            <tr className={classes.historyTableError}>
              <td colSpan={6}>
                An error occured. Please try to reload the page or contact us at
                {' '}
                <a href="mailto:support@nowpayments.io">support@nowpayments.io</a>
              </td>
            </tr>
          )}
          {isHistoryDataShow && children}
        </tbody>
      </table>
    </div>
  );
};

HistoryTable.defaultProps = {
  className: null,
  thItems: null,
  colSpan: 6,
  loading: false,
  historyData: null,
  hasData: false,
  emptyText: null,
  emptyElement: null,
  notFoundText: null,
  children: null,
  apiError: null,
  isTimeoutError: false,
  minWidth: null,
};

HistoryTable.propTypes = {
  className: PropTypes.string,
  thItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    name: PropTypes.string,
  })),
  colSpan: PropTypes.number,
  loading: PropTypes.bool,
  historyData: PropTypes.arrayOf(PropTypes.object),
  hasData: PropTypes.bool,
  emptyText: PropTypes.string,
  emptyElement: PropTypes.node,
  notFoundText: PropTypes.string,
  children: PropTypes.node,
  apiError: PropTypes.string,
  isTimeoutError: PropTypes.bool,
  minWidth: PropTypes.number,
};

export const HistoryTableMemo = React.memo(HistoryTable);
