import React from 'react';
import cn from 'classnames';
import classes from './styles.module.scss';

type ExternalLinkWithUnderlineProps = {
  className?: string | null,
  href: string,
  text: string,
};
export const ExternalLinkWithUnderline: React.FC<ExternalLinkWithUnderlineProps> = (props) => {
  const {
    className,
    href,
    text,
  } = props;

  return (
    <a
      className={cn(classes.link, className)}
      href={href}
      target="_blank"
      rel="nofollow noopener noreferrer"
    >
      {text}
    </a>
  );
};

ExternalLinkWithUnderline.defaultProps = {
  className: null,
};
