import * as React from 'react';
import PropTypes from 'prop-types';
import { InfoAboutUse } from '../../../shared/info-about-use';

const POSTMAN_URL = 'https://documenter.getpostman.com/view/7907941/2s93JusNJt#2b3f0024-d9de-4b91-9db4-d3655e4eded9';

export const CustomersInfoAboutUse = (props) => {
  const {
    className,
    name,
    onClose,
  } = props;

  return (
    <InfoAboutUse
      className={className}
      name={name}
      onClose={onClose}
    >
      In this section you can create customers and manage their assets. Each customer has a
      distinct ID, which you can use to execute an
      {' '}
      <a
        href={POSTMAN_URL}
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        API
      </a>
      {' '}
      requests.
      <div>
        <strong>Quick Guide:</strong>
        <ol>
          <li>Create a new Customer.</li>
          <li>
            Make an
            {' '}
            <a
              href={POSTMAN_URL}
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              API
            </a>
            {' '}
            request to top-up balance of your customer.
          </li>
          <li>
            Your customers may top-up their balances directly. Check our
            {' '}
            <a
              href={POSTMAN_URL}
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              API docs
            </a>
            {' '}
            for more details.
          </li>
          <li>
            Withdraw funds from customers’ accounts to your balance once or on a regular basis.
          </li>
          <li>
            Reach us at
            {' '}
            <a href="mailto:partners@nowpayments.io">
              partners@nowpayments.io
            </a>
            {' '}
            if you’d like to learn more about customers feature.
          </li>
        </ol>
      </div>
    </InfoAboutUse>
  );
};

CustomersInfoAboutUse.defaultProps = {
  className: null,
  name: null,
  onClose: null,
};

CustomersInfoAboutUse.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onClose: PropTypes.func,
};
