import * as React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';
import { signInMapFn } from '../../helpers/account/map-fns';

export const useLogin = (isInnerLogin = false) => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (options) => {
    const email = options?.email ?? null;
    const password = options?.password ?? null;
    const captchaToken = options?.captchaToken ?? null;
    const [cancelToken] = getRequestSource();
    const bodyData = isInnerLogin ? {
      email,
      password,
    } : {
      email,
      password,
      captchaToken,
    };
    const requestPromise = client.post(
      isInnerLogin ? '/internal-login' : '/login',
      bodyData,
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      signInMapFn,
    );
  }, [getRequestSource]);
};
