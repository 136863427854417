import React from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { PlatformUpdatesItemLinkType, PlatformUpdatesType } from './types';
import classes from './styles.module.scss';
import { partnerSelector } from '../../store/partner/selectors';
import { newsSelector } from '../../store/statistics/selectors';
import { LAST_NEWS_API_PATH, useLastNews } from '../../api/modules/account/use-last-news';
import { ResponseType } from '../../default-types';
import { setNews } from '../../store/statistics/reducer';
import { checkErrorCode } from '../../helpers/check-error-code';
import { getSafeErrorMessageText } from '../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../helpers/console-error-message';
import { LoaderIcon } from '../icons/loader-icon';

export const PlatformUpdates: React.FC<PlatformUpdatesType> = (props) => {
  const { className } = props;

  const dispatch = useDispatch();
  const partner = useSelector(partnerSelector());
  const news = useSelector(newsSelector());
  const fetchLastNews = useLastNews();

  const [isFetching, setIsFetching] = React.useState<boolean>(false);

  const initLastNews = React.useCallback(async () => {
    setIsFetching(true);
    const { data, status, errorMessage }: ResponseType = await fetchLastNews();
    setIsFetching(false);

    if (status === 200) {
      dispatch(setNews(data));
    } else {
      checkErrorCode(data?.errorData?.code);
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, LAST_NEWS_API_PATH);
    }
  }, []);

  React.useEffect(() => {
    let timerId: ReturnType<typeof setTimeout> | null = null;
    if (partner) {
      timerId = setTimeout(initLastNews, 0);
    }
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [partner]);

  return (
    <div className={cn(classes.platformUpdates, className)}>
      <div className={classes.platformUpdatesTitle}>
        Useful resources
        {' '}
        {isFetching && (
          <LoaderIcon size={16} />
        )}
      </div>
      {Array.isArray(news) && news.map((item: PlatformUpdatesItemLinkType) => (
        <a
          key={item.id}
          className={classes.platformUpdatesText}
          href={item.link}
          target="_blank"
          rel="noreferrer"
        >
          {item.title}
        </a>
      ))}
    </div>
  );
};
