import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Popup } from '../../../popup';
import { CsvReader } from '../../../shared/csv-reader';
import { Button } from '../../../ui/button';
import { hasElementsInArray } from '../../../../helpers/has-elements-in-array';
import { LoaderIcon } from '../../../icons/loader-icon';
import classes from './styles.module.scss';

export const PopupUploadCsv = (props) => {
  const {
    className,
    title,
    open,
    onClose,
    onWithdrawalData,
    onRemoveWithdrawalData,
    withdrawalData,
    onSubmit,
    fetching,
    apiError,
  } = props;

  const hasWithdrawalData = hasElementsInArray(withdrawalData);

  return (
    <Popup
      classNameContent={cn(classes.popupUploadCsv, className)}
      title={title}
      open={open}
      onClose={onClose}
      withCloseButton
      mobileFullView
      line
    >
      <div className={classes.description}>
        <a
          href="/files/payouts-template.csv"
          download="PayoutsTemplate.csv"
        >
          Download the template
        </a>, fill it out and upload it using the button below.
        <br/><br/>
        For every payout specify a certain line like this:
        <picture>
          <source
            srcSet="/images/example-mass-payout-full.svg"
            media="(min-width: 768px)"
          />
          <img
            src="/images/example-mass-payout.svg"
            alt="Mass Payouts"
            decoding="async"
            loading="lazy"
          />
        </picture>
      </div>
      <div className={classes.buttons}>
        <CsvReader
          onCallBack={onWithdrawalData}
          onRemove={onRemoveWithdrawalData}
          apiError={apiError}
        />
        {hasWithdrawalData && (
          <Button
            className={classes.button}
            onClick={onSubmit}
            disabled={Boolean(apiError) || fetching}
          >
            {fetching ? (
              <LoaderIcon path="/images/loader-white-on-blue-icon.gif" />
            ) : 'Create mass payout'}
          </Button>
        )}
      </div>
    </Popup>
  );
};

PopupUploadCsv.defaultProps = {
  className: null,
  open: null,
  onClose: null,
  onWithdrawalData: null,
  onRemoveWithdrawalData: null,
  withdrawalData: null,
  onSubmit: null,
  fetching: false,
  apiError: null,
};

PopupUploadCsv.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onWithdrawalData: PropTypes.func,
  onRemoveWithdrawalData: PropTypes.func,
  withdrawalData: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func,
  fetching: PropTypes.bool,
  apiError: PropTypes.string,
};
