import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { InfoAboutUseTooltip } from '../../shared/info-about-use-tooltip';
import styles from './styles.module.scss';

export const PageHeader = (props) => {
  const {
    className,
    title,
    showTooltipFirstText,
    onClick,
    infoTooltipShow,
  } = props;

  return (
    <div className={cn(styles.header, className)}>
      <h1 dangerouslySetInnerHTML={{ __html: title }} />
      {onClick && infoTooltipShow && (
        <InfoAboutUseTooltip
          className={styles.image}
          showFirstText={showTooltipFirstText}
          onClick={onClick}
        />
      )}
    </div>
  );
};

PageHeader.defaultProps = {
  className: null,
  title: null,
  showTooltipFirstText: true,
  onClick: null,
  infoTooltipShow: true,
};

PageHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  showTooltipFirstText: PropTypes.bool,
  onClick: PropTypes.func,
  infoTooltipShow: PropTypes.bool,
};
