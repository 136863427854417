import * as React from 'react';
import cn from 'classnames';
import { ProvidersCard } from './components/providers-card';
import { ProvidersCardsProps } from './types';
import classes from './styles.module.scss';

export const ProvidersCards: React.FC<ProvidersCardsProps> = (props) => {
  const {
    cards,
    onClick,
    isActivatedBlock,
    className,
    isSmallCards,
    partnerEmail,
  } = props;

  return (
    <div
      className={cn([
        classes.cards,
        className,
      ])}
    >
      {cards?.map((card) => (
        <ProvidersCard
          key={card.id}
          card={card}
          onClick={onClick}
          isActivatedBlock={isActivatedBlock}
          isSmallCard={isSmallCards}
          partnerEmail={partnerEmail}
        />
      ))}
    </div>
  );
};
