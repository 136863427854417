/**
 * getPriceToUsd
 * @param {Number} value
 * @param {Number} rate
 * @param {Number} decimals
 * @returns {number}
 */
export const getFiatPriceFromCrypto = (value, rate, decimals = null) => {
  const currentValue = value || 0;
  const currentRate = rate || 0;
  const result = currentValue / currentRate;
  const isResultNumber = Number.isFinite(result);
  const currentResult = isResultNumber ? result : 0;
  const resultToFixed = decimals ? currentResult.toFixed(decimals) : currentResult;

  return Number(resultToFixed);
};
