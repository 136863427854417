import { getArrayWithIndexId } from '../../../helpers/get-array-with-index-id';

/**
 * @param {Boolean} isTransactions
 * @returns {{ desktopThs: (*|Array<Object>), mobileThs: (*|Array<Object>) }}
 */
export const getHistoryThs = (isTransactions) => {
  const currentIdText = isTransactions ? 'Transaction ID' : 'Payment ID';
  const currentTypeText = isTransactions ? 'Transaction type' : 'Payment type';

  const desktopThs = getArrayWithIndexId([{
    name: currentIdText,
  }, {
    name: 'Customer ID',
  }, {
    name: 'Status',
  }, {
    name: 'Amount',
  }, {
    name: currentTypeText,
  }, {
    name: 'Last update',
  }]);
  const mobileThs = getArrayWithIndexId([{
    name: `${currentIdText} / Customer&nbsp;ID`,
  }, {
    name: 'Status / Amount',
  }, {
    name: `${currentTypeText} / Last&nbsp;update`,
  }]);

  return { desktopThs, mobileThs };
};
