import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hasElementsInArray } from '../../../helpers/has-elements-in-array';
import {
  setCustomerBalancesFetching,
  setCustomerBalancesFetched,
  setCustomerBalances,
} from '../../../store/customer-management/reducer';
import { getSubPartnerBalance } from '../../../api/helpers/account/requests';
import { customerBalancesSelector } from '../../../store/customer-management/selectors';

export const useCustomerBalances = (subPartners) => {
  const dispatch = useDispatch();
  const hasSubPartners = hasElementsInArray(subPartners);
  const customerBalances = useSelector(customerBalancesSelector());

  const [isNeedMarketInfoRates, setIsNeedMarketInfoRates] = React.useState(false);

  const handleCustomerBalances = React.useCallback(async () => {
    if (!hasSubPartners) {
      return;
    }
    setIsNeedMarketInfoRates(true);

    const nextBalances = { ...customerBalances };

    dispatch(setCustomerBalancesFetching(true));
    const promisses = subPartners.map(({ id }) => getSubPartnerBalance(id));
    const promissesResponse = await Promise.allSettled(promisses);
    const responseFiltered = [...promissesResponse]
      .filter((item) => item?.value?.data?.result)
      .map((item) => item?.value?.data?.result);

    responseFiltered.forEach(({ subPartnerId, balances }) => {
      nextBalances[subPartnerId] = balances;
    });

    dispatch(setCustomerBalances(nextBalances));
    dispatch(setCustomerBalancesFetching(false));
    dispatch(setCustomerBalancesFetched(true));
    setIsNeedMarketInfoRates(false);
  }, [
    subPartners,
    hasSubPartners,
    customerBalances,
    dispatch,
    setCustomerBalancesFetching,
    setCustomerBalances,
    setCustomerBalancesFetched,
  ]);

  return {
    isNeedMarketInfoRates,
    handleCustomerBalances,
  };
};
