import React from 'react';
import classes from './styles.module.scss';
import { Button } from '../../../ui/button';
import { ROUTES } from '../../../../constants/routes';

export const ForActivateCustody = () => (
  <div className={classes.forActivateCustody}>
    Whitelisting is a part of Custody risk control system designed to protect your funds from
    unauthorized withdrawals.
    <br />
    Once you activate Custody storage, you can add addresses to Whitelist.
    <Button
      className={classes.forActivateCustodyButton}
      href={ROUTES.CUSTODY}
      isSpaLink
    >
      Activate Custody
    </Button>
  </div>
);
