import React from 'react';
import cn from 'classnames';
import { Button } from '../../../ui/button';
import { CopyText } from '../../../ui/copy-text';
import { LoaderIcon } from '../../../icons/loader-icon';
import classes from './styles.module.scss';

type NewIpnKeyProps = {
  className: string,
  buttonClassName: string,
  onClose: () => void,
  onGenerateIpnKey: () => Promise<void>,
  newIpnKey: string | null,
  isNewIpnKeyLoading: boolean,
  newIpnKeyError: string | null,
};

export const NewIpnKey: React.FC<NewIpnKeyProps> = (props) => {
  const {
    className,
    buttonClassName,
    onClose,
    onGenerateIpnKey,
    newIpnKey,
    isNewIpnKeyLoading,
    newIpnKeyError,
  } = props;

  React.useEffect(() => {
    onGenerateIpnKey();
  }, [onGenerateIpnKey]);

  return (
    <div className={className}>
      <p>
        Your new Instant Payment Notifications key has been created. Please save it securely, as we only
        display it once. If you lose this key, you can create a new one at any time.
      </p>
      <div className={classes.keyBlock}>
        <p className={classes.keyBlockText}>
          Your IPN secret key
        </p>
        {isNewIpnKeyLoading ? (
          <LoaderIcon size={24} />
        ) : (
          <CopyText
            className={classes.keyBlockCopy}
            text={newIpnKey}
            copyIconPath="/images/copy-icon-settings.svg"
          />
        )}
      </div>
      <div className={classes.generateIpnError}>
        {newIpnKeyError}
      </div>
      <Button
        className={cn(buttonClassName, classes.closeButton)}
        onClick={onClose}
      >
        I saved the key, close
      </Button>
    </div>
  );
};
