import * as React from 'react';
import { hasElementsInArray } from '../helpers/has-elements-in-array';

/**
 * @param {Array<object>} currencies
 * @returns {{ cryptoCurrencies: Array<object> }}
 */
export const useCryptoCurrencies = (currencies = []) => {
  const cryptoCurrencies = React.useMemo(() => (
    !hasElementsInArray(currencies)
      ? currencies
      : currencies.filter((currency) => !currency.isFiat)
  ), [currencies]);

  return { cryptoCurrencies };
};
