import React from 'react';
import Switch from '../switch-2';
import styles from './styles.module.scss';

const OauthPermissions = ({
  permissions,
  onChangeSwitch,
  appPermissions,
  isReadOnly = false,
  view = false,
}) => {
  return (
    <table className={styles.table}>
      <thead className={styles.table__thead}>
        <tr className={styles.table__thead__tr}>
          <th className={styles.table__thead__th}>
            <span>Access</span>
          </th>
          <th className={styles.table__thead__th}>
            <span>Read</span>
          </th>
          <th className={styles.table__thead__th}>
            <span>Write</span>
          </th>
        </tr>
      </thead>
      <tbody className={styles.tbody}>
        {Object.entries(permissions).map(([key, value], index) => (
          <tr key={index} className={styles.tbody__tr}>
            <td className={styles.tbody__td}>
              <span>{key}</span>
            </td>
            <td className={styles.tbody__td}>
              {view ? (
                <Switch
                  readOnly={isReadOnly}
                  onChange={() => onChangeSwitch(key, value.read)}
                  isChecked={value.read}
                />
              ) : (
                <Switch
                  onChange={() => onChangeSwitch(key, 'read')}
                  isChecked={appPermissions?.[key]?.read}
                />
              )}
            </td>
            <td className={styles.tbody__td}>
              {view ? (
                <Switch
                  readOnly={isReadOnly}
                  onChange={() => onChangeSwitch(key, value.write)}
                  isChecked={value.write}
                />
              ) : (
                <Switch
                  onChange={() => onChangeSwitch(key, 'write')}
                  isChecked={appPermissions?.[key]?.write}
                />
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default OauthPermissions;
