import BigNumber from 'bignumber.js';
import { isNumber } from './is-number';

/**
 * ToFixed
 *
 * @param value {string, number}
 * @param prefix {string|null}
 * @param suffix {string|null}
 * @param digits {number|null}
 * @returns {string|null}
 */
export const toFormat = (value, prefix = '', suffix = '', digits = null) => {
  if (!isNumber(value) && typeof value !== 'string') {
    return null;
  }
  const OPTIONS = {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ' ',
    groupSize: 3,
    suffix,
  };

  return new BigNumber(value).toFormat(digits, null, OPTIONS);
};
