import * as React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';
import { NOTIFICATION_NAME } from '../../../constants/app-constants';

const mapFn = (data) => ({
  text: data?.text ?? null,
});

export const useNotification = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (name = NOTIFICATION_NAME) => {
    const [cancelToken] = getRequestSource();
    const requestPromise = client.get(
      `external-services/notification`,
      {
        params: { name },
      },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
