import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  isPartnerCustodySelector,
  partnerFetchedSelector,
  partnerFetchingSelector,
} from '../../store/partner/selectors';
import { ROUTES } from '../../constants/routes';
import { usePartnerCustodyCheck } from '../../hooks/use-partner-custody-check';
import { currencyListSelector } from '../../store/currencies/selectors';
import { useCurrenciesToObject } from '../../hooks/use-currencies-to-object';
import {
  useTransactionOrPaymentInfo,
  useTransactionsAndPaymentsHistoryState,
} from './hooks';
import { TransactionsFullFilter } from './components/transactions-full-filter';
import { TransactionsTable } from './components/transactions-table';
import { PopupInfo } from './components/popup-info';
import { PagesWrapper } from './components/pages-wrapper';

export const TransactionsPage = () => {
  const partnerFetching = useSelector(partnerFetchingSelector());
  const partnerFetched = useSelector(partnerFetchedSelector());
  const isPartnerCustody = useSelector(isPartnerCustodySelector());
  const currencyList = useSelector(currencyListSelector());
  const { currenciesObject } = useCurrenciesToObject(currencyList);

  const {
    isHistoryPending,
    historyItems,
    historyItemsCount,
    historyApiErrorMessage,
    activeHistoryKey,
    setActiveHistoryKey,
    isTransactionsHistoryActive,
    searchText,
    handleSearchChange,
    filterData,
    page,
    setPage,
    numberOfActiveFilters,
    handleFilterReset,
    handleFilterSubmit,
  } = useTransactionsAndPaymentsHistoryState();

  const {
    isInfoPopupShow,
    infoItems,
    infoId,
    setDataForInfo,
    handleInfoPopupClose,
    infoTitle,
  } = useTransactionOrPaymentInfo(isTransactionsHistoryActive, currenciesObject);

  usePartnerCustodyCheck(ROUTES.CUSTOMERS, true);

  const isContentShow = !partnerFetching && partnerFetched && isPartnerCustody;
  const hasSearchData = Boolean(searchText) || Boolean(numberOfActiveFilters);

  return (
    <PagesWrapper contentShow={isContentShow}>
      <TransactionsFullFilter
        searchValue={searchText}
        onChangeSearch={handleSearchChange}
        activeFilterItemsNumber={numberOfActiveFilters}
        onSubmit={handleFilterSubmit}
        onFilterReset={handleFilterReset}
        transactionHistoryActive={isTransactionsHistoryActive}
        defaultData={filterData}
      />
      <TransactionsTable
        items={historyItems}
        hasSearchData={hasSearchData}
        fetching={isHistoryPending}
        onClick={setDataForInfo}
        count={historyItemsCount}
        onPaginatorClick={setPage}
        page={page}
        apiError={historyApiErrorMessage}
        activeHistoryKey={activeHistoryKey}
        onTabCLick={setActiveHistoryKey}
        transactionsHistory={isTransactionsHistoryActive}
        currenciesObject={currenciesObject}
      />
      <PopupInfo
        open={isInfoPopupShow}
        onClose={handleInfoPopupClose}
        items={infoItems}
        id={infoId}
        title={infoTitle}
      />
    </PagesWrapper>
  );
};
