import React from 'react';
import { useSelector } from 'react-redux';
import { getSafeErrorMessageText } from '../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../helpers/console-error-message';
import {
  TRANSACTIONS_AND_PAYMENTS_PER_PAGE,
  TRANSACTIONS_TAB,
  PAYMENTS_TAB,
} from '../helpers';
import { checkErrorCode } from '../../../helpers/check-error-code';
import { useSubPartnersTransfers } from '../../../api/modules/account/use-sub-partners-transfers';
import { safeToUpperCase } from '../../../helpers/safe-to-upper-case';
import { useSubPartnersPayments } from '../../../api/modules/account/use-sub-partners-payments';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { isFunction } from '../../../helpers/is-function';
import { useNumberOfActiveFilters } from '../../../hooks/use-number-of-active-filters';
import { isPartnerCustodySelector, partnerFetchedSelector } from '../../../store/partner/selectors';
import { useDebounce } from '../../../hooks/use-debounce';

const DEFAULT_HISTORY_DATA = {
  result: null,
  count: null,
};

/**
 * @param {String} customerId
 * @param {Boolean} transactionsAllowed
 * @returns {Object}
 */
export const useTransactionsAndPaymentsHistoryState = (customerId = null, transactionsAllowed = true) => {
  const isDesktop = useMediaQuery('(min-width: 992px)');
  const partnerFetched = useSelector(partnerFetchedSelector());
  const isPartnerCustody = useSelector(isPartnerCustodySelector());
  const fetchSubPartnersTransfers = useSubPartnersTransfers();
  const fetchSubPartnersPayments = useSubPartnersPayments();

  const [isPending, setIsPending] = React.useState(false);

  const [transactionsSearchText, setTransactionsSearchText] = React.useState('');
  const [transactionsData, setTransactionsData] = React.useState(DEFAULT_HISTORY_DATA);
  const [transactionsError, setTransactionsError] = React.useState(null);
  const [transactionsFilterData, setTransactionsFilterData] = React.useState(null);
  const [transactionsPage, setTransactionsPage] = React.useState(1);

  const [paymentsSearchText, setPaymentsSearchText] = React.useState('');
  const [paymentsData, setPaymentsData] = React.useState(DEFAULT_HISTORY_DATA);
  const [paymentsError, setPaymentsError] = React.useState(null);
  const [paymentsFilterData, setPaymentsFilterData] = React.useState(null);
  const [paymentsPage, setPaymentsPage] = React.useState(1);

  const [activeHistoryKey, setActiveHistoryKey] = React.useState(TRANSACTIONS_TAB.KEY);
  const [paymentsAllowed, setPaymentsAllowed] = React.useState(false);

  const isTransactionsHistoryActive = activeHistoryKey === TRANSACTIONS_TAB.KEY;
  const isPaymentsHistoryActive = activeHistoryKey === PAYMENTS_TAB.KEY;
  const historyItems = isTransactionsHistoryActive ? transactionsData.result : paymentsData.result;
  const historyItemsCount = isTransactionsHistoryActive ? transactionsData.count : paymentsData.count;
  const historyApiErrorMessage = isTransactionsHistoryActive ? transactionsError : paymentsError;

  const searchText = isTransactionsHistoryActive ? transactionsSearchText : paymentsSearchText;
  const setSearchText = isTransactionsHistoryActive ? setTransactionsSearchText : setPaymentsSearchText;
  const filterData = isTransactionsHistoryActive ? transactionsFilterData : paymentsFilterData;
  const page = isTransactionsHistoryActive ? transactionsPage : paymentsPage;
  const setPage = isTransactionsHistoryActive ? setTransactionsPage : setPaymentsPage;
  const numberOfActiveFilters = useNumberOfActiveFilters(filterData);

  const reFetchTransactions = React.useCallback(async (page = 1, filter = null, filterData = null) => {
    setTransactionsError(null);
    setIsPending(true);
    const { data, status, errorMessage } = await fetchSubPartnersTransfers({
      limit: TRANSACTIONS_AND_PAYMENTS_PER_PAGE,
      page,
      filter,
      customerId,
      dateFrom: filterData?.dateFrom,
      dateTo: filterData?.dateTo,
      status: safeToUpperCase(filterData?.status),
      type: filterData?.type,
    });

    if (status === 200) {
      setTransactionsData({
        result: data?.result ?? [],
        count: data?.count ?? null,
      });
      setIsPending(false);
    } else {
      checkErrorCode(data?.errorData?.code);
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/sub-partners/transfers');
      setTransactionsError(errorDataMessage);
      setIsPending(false);
    }
  }, [customerId]);

  const reFetchPayments = React.useCallback(async (page = 1, filter = null, filterData = null) =>   {
    setPaymentsError(null);
    setIsPending(true);
    const { data, status, errorMessage } = await fetchSubPartnersPayments({
      limit: TRANSACTIONS_AND_PAYMENTS_PER_PAGE,
      page,
      customerId,
      filter,
      dateFrom: filterData?.dateFrom,
      dateTo: filterData?.dateTo,
      status: filterData?.status,
      payCurrency: filterData?.payCurrency,
    });

    if (status === 200) {
      setPaymentsData({
        result: data?.result ?? [],
        count: data?.count ?? null,
      });
      setIsPending(false);
    } else {
      checkErrorCode(data?.errorData?.code);
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/sub-partners/payments');
      setPaymentsError(errorDataMessage);
      setIsPending(false);
    }
  }, [customerId]);

  const handleFilterReset = React.useCallback(() => {
    if (isTransactionsHistoryActive) {
      setTransactionsFilterData(null);
      setTransactionsPage(1);
    }

    if (isPaymentsHistoryActive) {
      setPaymentsFilterData(null);
      setPaymentsPage(1);
    }
  }, [
    isTransactionsHistoryActive,
    isPaymentsHistoryActive,
  ]);

  const handleFilterSubmit = React.useCallback((data, setIsFilterActive) => {
    const hasData = Object.values(data).some((item) => Boolean(item));

    if (!hasData) {
      return;
    }

    if (isTransactionsHistoryActive) {
      setTransactionsFilterData(data);
      setTransactionsPage(1);
    }
    if (isPaymentsHistoryActive) {
      setPaymentsFilterData(data);
      setPaymentsPage(1);
    }

    if (!isDesktop && isFunction(setIsFilterActive)) {
      setIsFilterActive(false);
    }
  }, [
    isTransactionsHistoryActive,
    isPaymentsHistoryActive,
    isDesktop,
  ]);

  const handleSearchChange = React.useCallback((value) => {
    setSearchText(value);
    setPage(1);
  }, [setSearchText]);

  const { call: reFetchDebounce } = useDebounce(async (func) => {
    void func();
  }, 400);

  React.useEffect(() => {
    if (isPaymentsHistoryActive && !paymentsAllowed) {
      setPaymentsAllowed(true);
    }
  }, [isPaymentsHistoryActive, paymentsAllowed]);

  React.useEffect(() => {
    if (partnerFetched && isPartnerCustody && transactionsAllowed) {
      reFetchDebounce(() => reFetchTransactions(transactionsPage, transactionsSearchText, transactionsFilterData));
    }
  }, [
    partnerFetched,
    isPartnerCustody,
    transactionsPage,
    transactionsSearchText,
    transactionsFilterData,
    transactionsAllowed,
  ]);

  React.useEffect(() => {
    if (paymentsAllowed) {
      reFetchDebounce(() => reFetchPayments(paymentsPage, paymentsSearchText, paymentsFilterData));
    }
  }, [
    paymentsAllowed,
    paymentsPage,
    paymentsSearchText,
    paymentsFilterData,
  ]);

  return {
    isHistoryPending: isPending,
    historyItems,
    historyItemsCount,
    historyApiErrorMessage,
    reFetchTransactions,
    reFetchPayments,
    activeHistoryKey,
    setActiveHistoryKey,
    isTransactionsHistoryActive,
    isPaymentsHistoryActive,
    searchText,
    handleSearchChange,
    filterData,
    page,
    setPage,
    numberOfActiveFilters,
    handleFilterReset,
    handleFilterSubmit,
  };
};
