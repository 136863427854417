import { STATUSES } from '../../../constants/app-constants';

export const MASS_PAYOUTS_PAGE_PER_PAGE = 20;

export const MASS_PAYOUTS_STATUS_ITEMS = [
  { key: STATUSES.WAITING, value: 'Waiting' },
  { key: STATUSES.PROCESSING, value: 'Processing' },
  { key: STATUSES.FINISHED, value: 'Finished' },
  { key: STATUSES.FAILED, value: 'Failed' },
  { key: STATUSES.REJECTED, value: 'Rejected' },
];

export const MASS_PAYOUTS_PAGE_THS_DESKTOP = [{
  id: '1',
  name: 'ID / Batch ID',
}, {
  id: '2',
  name: 'Amount',
}, {
  id: '3',
  name: 'Address',
}, {
  id: '4',
  name: 'Created at',
}, {
  id: '5',
  name: 'Status updated',
}, {
  id: '6',
  name: 'Status',
}];

export const MASS_PAYOUTS_PAGE_THS_MOBILE = [{
  id: '1',
  name: 'ID / Batch ID',
}, {
  id: '2',
  name: 'Amount / Address',
}, {
  id: '3',
  name: 'Status',
}];

export const DEFAULT_INFO_ERROR_TEXT = 'Internal Error: Please contact customer support';
