import React from 'react';
import { DashboardIcon } from '../components/icons/dashboard-icon';
import { PaymentToolsIcon } from '../components/icons/payment-tools-icon';
import { ApiDocsIcon } from '../components/icons/api-docs-icon';
import { CustomersIcon } from '../components/icons/customers-icon';
import { CustodyIcon } from '../components/icons/custody-icon';
import { AffiliateProgramIcon } from '../components/icons/affiliate-program-icon';
import { SettingsIcon } from '../components/icons/settings-icon';
import { SignOutIcon } from '../components/icons/sign-out-icon';
import { MassPayoutsIcon } from '../components/icons/mass-payouts-icon';
import { ROUTES } from './routes';

export const PAYMENT_TOOLS_SUBMENU = [{
  id: 1,
  name: 'Payment Link',
  href: ROUTES.INVOICES,
  isBeta: false,
  isNew: false,
}, {
  id: 2,
  name: 'Donations',
  href: ROUTES.DONATIONS,
  isBeta: false,
  isNew: false,
}, {
  id: 3,
  name: 'Subscriptions',
  href: ROUTES.SUBSCRIPTIONS,
  isBeta: false,
  isNew: false,
}, {
  id: 4,
  name: 'POS Terminal link',
  href: ROUTES.POS_TERMINAL,
  isBeta: false,
  isNew: false,
}];

export const SETTINGS_SUBMENU = [{
  id: 1,
  name: 'Payments',
  href: ROUTES.STORE_SETTINGS,
  isBeta: false,
  isNew: false,
}, {
  id: 2,
  name: 'Account',
  href: ROUTES.ACCOUNT_SETTINGS,
  isBeta: false,
  isNew: false,
}, {
  id: 3,
  name: 'Coins',
  href: ROUTES.COINS_SETTINGS,
  isBeta: false,
  isNew: false,
}, {
  id: 4,
  name: 'Fiat operations',
  href: ROUTES.FIAT_OPERATIONS_SETTINGS,
  isBeta: false,
  isNew: true,
}];

export const MAIN_NAVIGATION = [{
  id: 1,
  name: 'Dashboard',
  href: ROUTES.DASHBOARD,
  submenu: null,
  Icon: <DashboardIcon />,
  isExternalLink: false,
  isBeta: false,
  hasNewItem: false,
}, {
  id: 2,
  name: 'Payments History',
  href: ROUTES.PAYMENTS,
  submenu: null,
  Icon: <ApiDocsIcon />,
  isExternalLink: false,
  isBeta: false,
  hasNewItem: false,
}, {
  id: 3,
  name: 'Payment Tools',
  href: '',
  submenu: PAYMENT_TOOLS_SUBMENU,
  Icon: <PaymentToolsIcon />,
  isExternalLink: false,
  isBeta: false,
  hasNewItem: false,
}, {
  id: 4,
  name: 'Customers',
  href: ROUTES.CUSTOMERS,
  submenu: null,
  Icon: <CustomersIcon />,
  isExternalLink: false,
  isBeta: true,
  hasNewItem: false,
}, {
  id: 5,
  name: 'Custody',
  href: ROUTES.CUSTODY,
  submenu: null,
  Icon: <CustodyIcon />,
  isExternalLink: false,
  isBeta: false,
  hasNewItem: false,
}, {
  id: 6,
  name: 'Mass Payouts',
  href: ROUTES.MASS_PAYOUTS,
  submenu: null,
  Icon: <MassPayoutsIcon />,
  isExternalLink: false,
  isBeta: false,
  hasNewItem: false,
}, {
  id: 7,
  name: 'Affiliate Program',
  href: ROUTES.AFFILIATE_PROGRAM,
  submenu: null,
  Icon: <AffiliateProgramIcon />,
  isExternalLink: false,
  isBeta: false,
  hasNewItem: false,
}, {
  id: 8,
  name: 'Settings',
  href: null,
  submenu: SETTINGS_SUBMENU,
  Icon: <SettingsIcon />,
  isExternalLink: false,
  isBeta: false,
  hasNewItem: true,
}];

export const MAIN_LINKS = [{
  id: 1,
  name: 'API Docs',
  href: 'https://documenter.getpostman.com/view/7907941/2s93JusNJt',
  submenu: null,
  Icon: <ApiDocsIcon />,
  isExternalLink: true,
}, {
  id: 2,
  name: 'Sign Out',
  href: '/signout',
  submenu: null,
  Icon: <SignOutIcon />,
  isExternalLink: false,
}];
