import * as React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { isFunction } from '../../../../helpers/is-function';
import { CUSTOMER_FORM_NAMES } from '../../helpers';
import {
  customerSubscriptionsSelector,
  isCustomerSubscriptionsFetchingSelector,
} from '../../../../store/customer-management/selectors';
import { hasElementsInArray } from '../../../../helpers/has-elements-in-array';
import { CUSTOMER_ACTION_BUTTONS } from './helpers/constansts';
import classes from './styles.module.scss';

export const CustomerButtons = (props) => {
  const {
    className,
    onClick,
    hasBalances,
  } = props;

  const params = useParams();
  const { customerId } = params;
  const isCustomerSubscriptionsFetching = useSelector(isCustomerSubscriptionsFetchingSelector());
  const customerSubscriptions = useSelector(customerSubscriptionsSelector());
  const customerSubscriptionsItems = customerSubscriptions[customerId] || null;

  const handleClick = (type) => () => {
    if (type === CUSTOMER_FORM_NAMES.WITHDRAW && !hasBalances) {
      return;
    }
    if (isFunction(onClick)) {
      onClick(type);
    }
  };

  const buttons = React.useMemo(() => (
    CUSTOMER_ACTION_BUTTONS.map((item) => {
      if (item.formName === CUSTOMER_FORM_NAMES.WITHDRAW && !hasBalances) {
        return { ...item, disabled: !hasBalances };
      }

      if (item.formName === CUSTOMER_FORM_NAMES.CREATE_BILLING_PLAN) {
        if (isCustomerSubscriptionsFetching) {
          return { ...item, disabled: isCustomerSubscriptionsFetching };
        }
        if (hasElementsInArray(customerSubscriptionsItems)) {
          return { ...item, formName: CUSTOMER_FORM_NAMES.ACTIVE_BILLING_PLAN };
        }
      }

      return item;
    })
  ), [hasBalances, customerSubscriptionsItems, isCustomerSubscriptionsFetching]);

  return (
    <div className={cn(classes.customerButtons, className)}>
      {buttons.map((item) => (
        <div
          key={item.id}
          className={cn([
            classes.customerButton,
            item.disabled && classes.customerButtonDisabled,
          ])}
          onClick={handleClick(item.formName)}
        >
          <img
            src={item.imagePath}
            alt={item.name}
            decoding="async"
            width={24}
            height={24}
          />
          {item.name}
        </div>
      ))}
    </div>
  );
};

CustomerButtons.defaultProps = {
  className: null,
  onClick: null,
  hasBalances: false,
};

CustomerButtons.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  hasBalances: PropTypes.bool,
};
