import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCopy } from '@fortawesome/free-solid-svg-icons';
import { CurrencyNetworkSup } from '../../../shared/currency-network-sup';
import styles from './styles.module.scss';

export const InvoiceResultWithoutCurrency = (props) => {
  const {
    orderId,
    priceAmount,
    priceTicker,
    priceNetwork,
    url,
  } = props;

  const [copiedLink, setCopiedLink] = React.useState(null);

  const copyToClipboard = async (textToCopy) => {
    // eslint-disable-next-line no-undef
    await navigator.clipboard.writeText(textToCopy);

    setCopiedLink(textToCopy);
  };

  React.useEffect(() => {
    let nextTimerId = null;

    if (copiedLink) {
      nextTimerId = setTimeout(() => {
        setCopiedLink(null);
      }, 1000);
    }

    return () => {
      if (nextTimerId) {
        clearTimeout(nextTimerId);
      }
    };
  }, [copiedLink]);

  return (
    <div className={styles.flexColumn}>
      <div
        className={cn(
          styles.dFlex,
          styles.justifyCenter,
          styles.fs20,
          styles.fwBold,
          styles.mb20,
          styles.breakAll,
        )}
      >
        Order: #
        {orderId}
      </div>
      <div
        className={cn(
          styles.dFlex,
          styles.fwBold,
          styles.fs16,
          styles.mb20,
          styles.priceAmount,
        )}
      >
        Price:
        {' '}
        {priceAmount}
        {' '}
        {priceTicker}
        {' '}
        {priceNetwork && (
          <CurrencyNetworkSup network={priceNetwork} />
        )}
      </div>
      <div className={cn(styles.dFlex, styles.fs16, styles.fwBold)}>
        Share a permanent link to a hosted page:
      </div>
      <div className={cn(styles.dFlex, styles.fs16, styles.fwBold)}>
        <div className={styles.wordBreak}>
          {url}
        </div>
        {copiedLink && copiedLink === url  && (
          <FontAwesomeIcon
            icon={faCheck}
            size="1x"
            color="#1BDC66"
            style={{ marginLeft: '5px' }}
          />
        )}
        {!copiedLink && (
          <FontAwesomeIcon
            icon={faCopy}
            size="1x"
            color="#64ACFF"
            style={{ cursor: 'pointer', marginLeft: '5px' }}
            onClick={() => copyToClipboard(url, true)}
          />
        )}
      </div>
      <div className={styles.mt20}>
        To avoid payment difficulties, please note that the minimum
        payment amount varies from coin to coin.
      </div>
    </div>
  );
};

InvoiceResultWithoutCurrency.defaultProps = {
  orderId: null,
  priceAmount: null,
  priceTicker: null,
  priceNetwork: null,
  url: null,
};

InvoiceResultWithoutCurrency.propTypes = {
  orderId: PropTypes.string,
  priceAmount: PropTypes.string,
  priceTicker: PropTypes.string,
  priceNetwork: PropTypes.string,
  url: PropTypes.string,
};
