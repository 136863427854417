import { CUSTOMER_FORM_NAMES } from '../../../helpers';

export const CUSTOMER_ACTION_BUTTONS = [{
  id: '1',
  name: 'Deposit',
  imagePath: '/images/deposit-icon.svg',
  formName: CUSTOMER_FORM_NAMES.DEPOSIT,
  disabled: false,
},
{
  id: '2',
  name: 'Withdraw',
  imagePath: '/images/withdraw-icon.svg',
  formName: CUSTOMER_FORM_NAMES.WITHDRAW,
  disabled: false,
},
{
  id: '3',
  name: 'Recurring Payments',
  imagePath: '/images/recurring-payment-icon.svg',
  formName: CUSTOMER_FORM_NAMES.CREATE_BILLING_PLAN,
  disabled: false,
}];
