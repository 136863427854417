import React from 'react';
import cn from 'classnames';
import { isFunction } from '../../../../../helpers/is-function';
import { SelectedItemProps } from '../../types';
import classes from './styles.module.scss';

export const SelectedItem: React.FC<SelectedItemProps> = (props) => {
  const {
    item,
    placeholderText,
    onClick,
    error,
    disabled,
  } = props;

  const handleClick = () => {
    if (isFunction(onClick) && !disabled) {
      onClick();
    }
  };
  const handleKeyPress = () => {
    if (isFunction(onClick) && !disabled) {
      onClick();
    }
  };

  return (
    <div
      className={cn([
        classes.item,
        error && classes.itemError,
        disabled && classes.itemDisabled,
      ])}
      onClick={handleClick}
      onKeyDown={handleKeyPress}
      tabIndex={0}
      role="button"
    >
      {placeholderText && !item && (
        <div className={classes.itemPlaceholderText}>
          {placeholderText}
        </div>
      )}
      {item && (
        <div className={classes.itemContent}>
          {item.logoUrl && (
            <img
              className={classes.listItemImg}
              src={item.logoUrl}
              alt={item.name}
              width={20}
              height={20}
              decoding="async"
              loading="lazy"
            />
          )}
          {item.name}
        </div>
      )}
      {!disabled && (
        <img
          className={classes.itemsSelect}
          width={8}
          height={8}
          src="/images/select-arrow-icon.svg"
          alt="Icon"
          decoding="async"
        />
      )}
    </div>
  );
};
