import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import {
  getCustomerHistoryThs,
  TRANSACTIONS_AND_PAYMENTS_PER_PAGE,
  EMPTY_TRANSACTIONS_TEXT,
  EMPTY_PAYMENTS_TEXT,
  NOT_FOUND_TRANSACTIONS_TEXT,
  NOT_FOUND_PAYMENTS_TEXT,
  TRANSACTIONS_TAB,
  PAYMENTS_TAB,
} from '../../helpers';
import { HistoryTableWithPagination } from '../../../history-table-with-pagination';
import { TableTabs } from '../../../shared/table-tabs';
import { CustomerHistoryTableRow } from '../customer-history-table-row';
import { hasElementsInArray } from '../../../../helpers/has-elements-in-array';
import classes from './styles.module.scss';

export const CustomerHistoryTable = React.memo((props) => {
  const {
    items,
    hasSearchData,
    fetching,
    onClick,
    count,
    onPaginatorClick,
    page,
    apiError,
    activeHistoryKey,
    onTabCLick,
    currenciesObject,
  } = props;

  const isDesktop = useMediaQuery('(min-width: 1200px)');
  const hasItems = hasElementsInArray(items);
  const isTransactionHistoryActive = activeHistoryKey === TRANSACTIONS_TAB.KEY;
  const emptyText = isTransactionHistoryActive ? EMPTY_TRANSACTIONS_TEXT : EMPTY_PAYMENTS_TEXT;
  const notFoundText = isTransactionHistoryActive ? NOT_FOUND_TRANSACTIONS_TEXT : NOT_FOUND_PAYMENTS_TEXT;
  const { desktopThs, mobileThs } = getCustomerHistoryThs(isTransactionHistoryActive);

  return (
    <HistoryTableWithPagination
      className={classes.customerHistoryTable}
      classNameHeader={classes.customerHistoryTableHeader}
      items={items}
      hasSearchData={hasSearchData}
      fetching={fetching}
      apiError={apiError}
      minWidth={500}
      isDesktop={isDesktop}
      thsDesktop={desktopThs}
      thsMobile={mobileThs}
      perPage={TRANSACTIONS_AND_PAYMENTS_PER_PAGE}
      emptyText={emptyText}
      notFoundText={notFoundText}
      count={count}
      onPaginatorClick={onPaginatorClick}
      page={page}
      HeaderElement={(
        <TableTabs
          className={classes.customerHistoryTableTabs}
          activeTabKey={activeHistoryKey}
          firstTabKey={TRANSACTIONS_TAB.KEY}
          firstTabName={TRANSACTIONS_TAB.VALUE}
          secondTabKey={PAYMENTS_TAB.KEY}
          secondTabName={PAYMENTS_TAB.VALUE}
          onClick={onTabCLick}
        />
      )}
    >
      {hasItems && items.map((item) => (
        <CustomerHistoryTableRow
          key={item?.id || item?.paymentId}
          data={item}
          currenciesObject={currenciesObject}
          onClick={onClick}
          isDesktop={isDesktop}
        />
      ))}
    </HistoryTableWithPagination>
  );
});

CustomerHistoryTable.defaultProps = {
  items: null,
  hasSearchData: false,
  fetching: false,
  onClick: null,
  count: 0,
  onPaginatorClick: null,
  page: 1,
  apiError: null,
  activeHistoryKey: null,
  onTabCLick: null,
  currenciesObject: {},
};

CustomerHistoryTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  hasSearchData: PropTypes.bool,
  fetching: PropTypes.bool,
  onClick: PropTypes.func,
  count: PropTypes.number,
  onPaginatorClick: PropTypes.func,
  page: PropTypes.number,
  apiError: PropTypes.string,
  activeHistoryKey: PropTypes.string,
  onTabCLick: PropTypes.func,
  currenciesObject: PropTypes.object,
};

CustomerHistoryTable.displayName = 'CustomerHistoryTable';
