import React from "react";
import SubmitButton from "../../../../../components/submit-button";
import styles from "./styles.module.scss";

function TfaAppSetup(props) {
  const {
    addAppTfa,
    secret,
    setVerifyCode,
    buttonState,
    buttonFailureMessage,
    verifyCode,
  } = props;

  return (
    <>
      <h2 className={styles.modalHeader}>Two Factor Step</h2>
      <p>
        Take these two steps to enabled two-factor and make your account more
        secure.
      </p>
      <div className={styles.appTwoFactor}>
        <div className={styles.twoFactorSettings}>
          {secret.qrCodeUrl && (
            <div className={styles.QRCode}>
              <img src={secret.qrCodeUrl} alt="" />
            </div>
          )}
          <div className={styles.tfaKey}>
            Key: <span>{secret.key}</span>
          </div>
          <div className={styles.authenticationForm}>
            <div>
              <input
                value={verifyCode}
                type="text"
                placeholder="Authentication code"
                onChange={(evt) => setVerifyCode(evt.target.value.trim())}
              />
            </div>
          </div>
        </div>
        <div className={styles.twoFactorSteps}>
          <p>1. Install an authenticator.</p>
          <p>
            2. Scan QR code with the authenticator (or enter the key manually).
          </p>
          <p>3. Enter two step authentication code provided by your app.</p>
        </div>
      </div>
      <SubmitButton
        buttonState={buttonState}
        onSubmit={addAppTfa}
        failureMessage={buttonFailureMessage}
      >
        Enable
      </SubmitButton>
    </>
  );
}

export default TfaAppSetup;
