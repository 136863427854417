export const initialState = {
  storeData: null,
  wallets: [],
  isStoreDataFetched: false,
  storeDataError: null,
  balances: null,
  apiKeys: [],
  hasActiveApiKey: false,
  apiKeysFetched: false,
  apiKeysError: null,
  baseCurrency: null,
  baseCurrencyFetched: false,
  baseCurrencyError: null,
  depositAddresses: [],
  depositAddressesFetched: false,
  depositAddressesError: null,
  fetching: false,
};
