import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../../../../config';
import { Field } from '../../../../components/ui/field';
import { LoaderIcon } from '../../../../components/icons/loader-icon';
import { Button } from '../../../../components/ui/button';
import { isFunction } from '../../../../helpers/is-function';
import { TFA } from '../../../../constants/app-constants';
import styles from './styles.module.scss';

const TFA_SCREEN = {
  TO_FACTOR: 'tfa',
  BACKUP: 'backup',
  SUPPORT: 'support',
};

const TwoFactor = (props) => {
  const {
    verificationCode,
    backupKey,
    tfaErrorMessage,
    onTfaChange,
    onBackupChange,
    tfaSignIn,
    backupKeySignIn,
    onCaptchaChange,
    fetching,
    type2fa,
  } = props;
  const captchaRef = React.useRef(null);

  const [screen, setScreen] = React.useState(TFA_SCREEN.TO_FACTOR);
  const [captcha, setCaptcha] = React.useState('');
  const [tfaInputErrorMessage, setTfaInputErrorMessage] = React.useState(null);
  const [backupInputErrorMessage, setBackupInputErrorMessage] = React.useState(null);

  const handleTfaChange = (value) => {
    setTfaInputErrorMessage(null);
    if (isFunction(onTfaChange)) {
      onTfaChange(value);
    }
  };
  const handleCaptchaChange = (token) => {
    setCaptcha(token);
    onCaptchaChange(token);
  };
  const handleTfaButtonSubmit = () => {
    if (!verificationCode) {
      setTfaInputErrorMessage('Field is required');

      return;
    }
    tfaSignIn(captcha);
  };
  const handleBackupChange = (value) => {
    if (isFunction(onBackupChange)) {
      onBackupChange(value);
    }
  };
  const handleBackupButtonSubmit = () => {
    if (!backupKey) {
      setBackupInputErrorMessage('Field is required');

      return;
    }
    backupKeySignIn(captcha);
  };

  const isTFAScreen = screen === TFA_SCREEN.TO_FACTOR;
  const isBackupScreen = screen === TFA_SCREEN.BACKUP;
  const isSupportScreen = screen === TFA_SCREEN.SUPPORT;
  const currentTfaText = type2fa === TFA.TYPES.APP
    ? TFA.TEXTS.APP
    : TFA.TEXTS.EMAIL;

  return (
    <div className={styles.twoFactor}>
      <img
        src="/images/logo-two.svg"
        alt="MOWPayments"
        decoding="async"
      />
      <div className={styles.twoFactorDivider} />
      <p className={styles.twoFactorTitle}>
        Two step authentification
      </p>
      <div className={styles.twoFactorContent}>
        {isTFAScreen && (
          <>
            {currentTfaText && (
              <p className={styles.twoFactorDescription}>
                {currentTfaText}
              </p>
            )}
            <Field
              className={styles.twoFactorInput}
              data={{
                name: 'verificationCode',
                value: verificationCode,
                onChange: handleTfaChange,
                placeholder: 'Enter your authentification code',
                error: tfaInputErrorMessage,
              }}
            />
            <div className={styles.twoFactorBottom}>
              <div className={styles.twoFactorReCaptcha}>
                <ReCAPTCHA
                  sitekey={config.captcha_key}
                  onChange={handleCaptchaChange}
                  ref={captchaRef}
                  hl="en"
                />
              </div>
              <div className={styles.twoFactorApiError}>
                {tfaErrorMessage}
              </div>
              <Button
                className={styles.twoFactorButton}
                onClick={handleTfaButtonSubmit}
                disabled={fetching}
              >
                {fetching ? (
                  <LoaderIcon
                    size={25}
                    path="/images/loader-white-on-blue-icon.gif"
                  />
                ) : 'Sign in'}
              </Button>
              <div className={styles.twoFactorDivider} />
              <p
                onClick={() => setScreen(TFA_SCREEN.BACKUP)}
                className={styles.twoFactorLink}
              >
                Lost your code?
              </p>
            </div>
          </>
        )}
        {isBackupScreen && (
          <>
            <p className={styles.twoFactorDescription}>
              Please enter a backup code
            </p>
            <Field
              className={styles.twoFactorInput}
              data={{
                name: 'backupCode',
                value: backupKey,
                onChange: handleBackupChange,
                placeholder: 'Enter your backup code',
                error: backupInputErrorMessage,
              }}
            />
            <div className={styles.twoFactorBottom}>
              <div className={styles.twoFactorReCaptcha}>
                <ReCAPTCHA
                  sitekey={config.captcha_key}
                  onChange={handleCaptchaChange}
                  ref={captchaRef}
                  hl="en"
                />
              </div>
              <div className={styles.twoFactorApiError}>
                {tfaErrorMessage}
              </div>
              <Button
                className={styles.twoFactorButton}
                onClick={handleBackupButtonSubmit}
                disabled={fetching}
              >
                {fetching ? (
                  <LoaderIcon
                    size={25}
                    path="/images/loader-white-on-blue-icon.gif"
                  />
                ) : 'Sign in'}
              </Button>
              <div className={styles.twoFactorDivider} />
              <p
                onClick={() => setScreen(TFA_SCREEN.SUPPORT)}
                className={styles.twoFactorLink}
              >
                Lost your backup code?
              </p>
            </div>
          </>
        )}
        {isSupportScreen && (
          <div className={styles.twoFactorLoseCodeText}>
            If you lost your backup code please contact our live support team at
            {' '}
            <a href="mailto:support@nowpayments.io">
              support@nowpayments.io
            </a>
            {' '}
            to recover your two step authentification.
          </div>
        )}
      </div>
    </div>
  );
};

export default TwoFactor;
