import React from "react";
import withModal from "../with-modal";
import SubmitButton from "../submit-button";
import styles from "./styles.module.scss";

const confirmModal = ({
  message = "",
  onClose = () => {},
  onSubmit = () => {},
  processing,
  errorModal,
}) => {
  return (
    <div className={styles.confirmModal}>
      <p>{message}</p>
      <div className={styles.buttonsRow}>
        <SubmitButton buttonState={"normal"} onSubmit={onClose}>
          Close
        </SubmitButton>
        {!errorModal && (
          <SubmitButton
            buttonState={processing ? "processing" : "normal"}
            onSubmit={onSubmit}
          >
            OK
          </SubmitButton>
        )}
      </div>
    </div>
  );
};

export default withModal(confirmModal);
