import React from 'react';
import cn from 'classnames';
import { Button } from '../../../ui/button';
import classes from './styles.module.scss';

type AddWalletToWhitelistProps = {
  onClick: () => void;
};

export const AddWalletToWhitelist: React.FC<AddWalletToWhitelistProps> = (props) => {
  const { onClick } = props;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onClick();
  };

  return (
    <form
      className={cn([
        classes.form,
        classes.addWalletToWhitelist,
      ])}
      onSubmit={handleSubmit}
    >
      <p>
        Whitelisting is designed to
        {' '}
        <b>protect your funds from unauthorized withdrawals</b>
        {' '}
        from your account. With this system in place, fund withdrawal risk is
        controlled by only allowing transactions to whitelisted wallet addresses.
      </p>
      <Button
        className={classes.addWalletToWhitelistButton}
        type="submit"
      >
        Send request
      </Button>
    </form>
  );
};
