import { createSlice } from '@reduxjs/toolkit';
import { isObject } from '../../helpers/is-object';
import { initialState } from './initial-state';

export const PAYMENT_SETTINGS_REDUCER_NAME = 'paymentSettings';

const setStoreDataState = (state, { payload }) => {
  state.storeData = payload;
};
const setWalletsState = (state, { payload }) => {
  if (Array.isArray(state.wallets) && isObject(payload)) {
    state.wallets.push(payload);
  } else {
    state.wallets = payload;
  }
};
const updateWalletState = (state, { payload: { id, baseAddress, extraId } }) => {
  const walletIndex = state.wallets.findIndex(wallet => wallet.id === id);
  if (walletIndex !== -1) {
    state.wallets[walletIndex].baseAddress = baseAddress;
    state.wallets[walletIndex].extraId = extraId;
  }
};
const setIsStoreDataFetchedState = (state, { payload }) => {
  state.isStoreDataFetched = payload;
};
const setStoreDataErrorState = (state, { payload }) => {
  state.storeDataError = payload;
};
const setBalancesState = (state, { payload }) => {
  state.balances = payload;
};
const setApiKeysState = (state, { payload }) => {
  state.apiKeys = payload;
};
const updateApiKeysState = (state, { payload: { id, description } }) => {
  const apiKeysIndex = state.apiKeys.findIndex(apiKey => apiKey.id === id);
  if (apiKeysIndex !== -1) {
    state.apiKeys[apiKeysIndex].description = description;
  }
};
const deleteApiKeyFromState = (state, { payload: { id } }) => {
  const apiKeysIndex = state.apiKeys.findIndex(apiKey => apiKey.id === id);
  if (apiKeysIndex !== -1) {
    state.apiKeys.splice(apiKeysIndex, 1);
  }
};
const setHasActiveApiKeyState = (state, { payload }) => {
  state.hasActiveApiKey = payload;
};
const setApiKeysFetchedState = (state, { payload }) => {
  state.apiKeysFetched = payload;
};
const setApiKeysErrorState = (state, { payload }) => {
  state.apiKeysError = payload;
};
const setBaseCurrencyState = (state, { payload }) => {
  state.baseCurrency = payload;
};
const setBaseCurrencyFetchedState = (state, { payload }) => {
  state.baseCurrencyFetched = payload;
};
const setBaseCurrencyErrorState = (state, { payload }) => {
  state.baseCurrencyError = payload;
};
const setDepositAddressesState = (state, { payload }) => {
  state.depositAddresses = payload;
};
const setDepositAddressesFetchedState = (state, { payload }) => {
  state.depositAddressesFetched = payload;
};
const setDepositAddressesErrorState = (state, { payload }) => {
  state.depositAddressesError = payload;
};
const setFetchingState = (state, { payload }) => {
  state.fetching = payload;
};

export const paymentSettingsSlice = createSlice({
  name: PAYMENT_SETTINGS_REDUCER_NAME,
  initialState,
  reducers: {
    setStoreData: setStoreDataState,
    setWallets: setWalletsState,
    updateWallet: updateWalletState,
    setIsStoreDataFetched: setIsStoreDataFetchedState,
    setStoreDataError: setStoreDataErrorState,
    setBalances: setBalancesState,
    setApiKeys: setApiKeysState,
    updateApiKeys: updateApiKeysState,
    deleteApiKey: deleteApiKeyFromState,
    setHasActiveApiKey: setHasActiveApiKeyState,
    setApiKeysFetched: setApiKeysFetchedState,
    setApiKeysError: setApiKeysErrorState,
    setBaseCurrency: setBaseCurrencyState,
    setBaseCurrencyFetched: setBaseCurrencyFetchedState,
    setBaseCurrencyError: setBaseCurrencyErrorState,
    setDepositAddresses: setDepositAddressesState,
    setDepositAddressesFetched: setDepositAddressesFetchedState,
    setDepositAddressesError: setDepositAddressesErrorState,
    setPaymentSettingsFetching: setFetchingState,
  },
});

export const {
  setStoreData,
  setWallets,
  updateWallet,
  setIsStoreDataFetched,
  setStoreDataError,
  setBalances,
  setApiKeys,
  updateApiKeys,
  deleteApiKey,
  setHasActiveApiKey,
  setApiKeysFetched,
  setApiKeysError,
  setBaseCurrency,
  setBaseCurrencyFetched,
  setBaseCurrencyError,
  setDepositAddresses,
  setDepositAddressesFetched,
  setDepositAddressesError,
  setPaymentSettingsFetching,
} = paymentSettingsSlice.actions;
export const paymentSettingsReducer = paymentSettingsSlice.reducer;
