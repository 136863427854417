import React from 'react';
import PropTypes from 'prop-types';
import { InvoiceHistoryItemMemo } from '../invoice-history-item';
import { LoaderIcon } from '../../../icons/loader-icon';
import { isFunction } from '../../../../helpers/is-function';
import { Button } from '../../../ui/button';
import { useProfileInfoState } from '../../hooks';
import styles from './styles.module.scss';

const InvoiceHistoryTable = (props) => {
  const {
    invoices,
    loading,
    onInvoiceRowClick,
    totalCount,
    onCreatePaymentClick,
  } = props;

  const { profileInfo: { currenciesObject } } = useProfileInfoState();

  const handleClick = (invoice) => () => {
    if (isFunction(onInvoiceRowClick)) {
      onInvoiceRowClick(invoice);
    }
  };

  const hasInvoices = Boolean(totalCount);
  const hasData = Boolean(invoices?.length);
  const isInvoicesShow = !loading && hasInvoices && hasData;
  const isInvoicesNotFoundShow = !loading && hasInvoices && !hasData;

  return (
    <div className={styles.historyTableBox}>
      <table cellSpacing={25} className={styles.historyTable}>
        <thead className={styles.historyTableThead}>
          <tr>
            <th>Payment link ID</th>
            <th>Order ID</th>
            <th>Price</th>
            <th>Currency</th>
            <th>Invoice Url</th>
            <th>Created at</th>
          </tr>
        </thead>
        <tbody className={styles.historyTableTbody}>
          {loading && (
            <tr className={styles.historyTableLoaderTr}>
              <td colSpan={6}>
                <LoaderIcon />
              </td>
            </tr>
          )}
          {!hasInvoices && !loading && (
            <tr className={styles.historyTableEmptyTr}>
              <td colSpan={6}>
                No payment links yet. It takes a couple of clicks to create one!
                <Button
                  className={styles.historyTableEmptyButton}
                  onClick={onCreatePaymentClick}
                >
                  Create first payment
                </Button>
              </td>
            </tr>
          )}
          {isInvoicesNotFoundShow && (
            <tr className={styles.historyTableEmptyTr}>
              <td colSpan={6}>
                No payment links matching this criteria.
              </td>
            </tr>
          )}
          {isInvoicesShow && invoices.map((invoice) => (
            <InvoiceHistoryItemMemo
              key={invoice?.id}
              className={styles.historyTableBodyTr}
              invoiceId={invoice?.id}
              orderId={invoice?.order_id}
              priceAmount={invoice?.price_amount}
              priceTicker={invoice?.price_currency}
              payTicker={invoice?.pay_currency}
              url={invoice?.invoice_url}
              created={invoice?.created_at}
              onClick={handleClick(invoice)}
              currenciesObject={currenciesObject}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

InvoiceHistoryTable.defaultProps = {
  invoices: null,
  totalCount: 0,
  loading: false,
  onInvoiceRowClick: null,
  onCreatePaymentClick: null,
};

InvoiceHistoryTable.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.object),
  totalCount: PropTypes.number,
  loading: PropTypes.bool,
  onInvoiceRowClick: PropTypes.func,
  onCreatePaymentClick: PropTypes.func,
};

export const InvoiceHistoryTableMemo = React.memo(InvoiceHistoryTable);
