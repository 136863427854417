import * as yup from 'yup';

const emptyToNull = (_, originalValue) => originalValue || null;

export const CREATE_SUBSCRIPTION_PLAN_SCHEMA = yup.object({
  title: yup.string().trim().required('Title is required field').max(50, 'Title must be at most 50 characters'),
  period: yup.string().trim().required('Period is required field'),
  periodSelect: yup.string().required(),
  amount: yup.string().required('Cost per period is required field'),
  currency: yup.string().required(),
  ipnCallbackUrl: yup.string().trim().transform(emptyToNull).nullable(),
  successUrl: yup.string().trim().transform(emptyToNull).nullable(),
  cancelUrl: yup.string().trim().transform(emptyToNull).nullable(),
  partiallyPaidUrl: yup.string().trim().transform(emptyToNull).nullable(),
});

export const USER_PAYMENTS_FILTER_SCHEMA = yup.object({
  status: yup.string().trim(),
  payinAddress: yup.string().trim(),
  payinHash: yup.string().trim(),
  outcomeCurrency: yup.string().trim(),
});
