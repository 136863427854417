import * as React from 'react';
import cn from 'classnames';
import * as PropTypes from 'prop-types';
import { isFunction } from '../../../helpers/is-function';
import classes from './styles.module.scss';

export const Switcher = (props) => {
  const {
    onChange,
    active,
    className,
    activeClassName,
    children,
  } = props;

  const handleToggle = () => {
    if (isFunction(onChange)) {
      onChange(active);
    }
  };

  return (
    <div
      className={cn([
        classes.switcher,
        className,
        active && [
          classes.switcherActive,
          activeClassName,
        ],
      ])}
      onClick={handleToggle}
      onKeyPress={handleToggle}
      tabIndex={0}
      role="button"
    >
      <div className={classes.switcherBox} />
      {children}
    </div>
  );
};

Switcher.defaultProps = {
  onChange: null,
  active: false,
  className: null,
  activeClassName: null,
  children: null,
};

Switcher.propTypes = {
  onChange: PropTypes.func,
  active: PropTypes.bool,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  children: PropTypes.node,
};
