import axios from 'axios';
import config from '../../config';
import { getAuthToken, getMasterId } from '../../libs/local-storage';
import { MINUTE, SECOND } from '../../constants/datetime-constants';

const token = getAuthToken();
const masterId = getMasterId();

const SECONDS_IN_FIVE_MINUTE = (MINUTE * 5) * SECOND;

const nowPaymentsAccountApiRestTransport = () => axios.create({
  baseURL: config.api_domain,
  timeout: SECONDS_IN_FIVE_MINUTE,
  headers: {
    Authorization: `bearer ${token}`,
    'master-id': masterId,
    'X-Temp-Token': config.X_TEMP_TOKEN,
  },
});

export default nowPaymentsAccountApiRestTransport;
