/**
 * @param {String} explorerLinkHash
 * @param {string} payinHash
 * @returns {string|null}
 */
export const getPayinHashLink = (explorerLinkHash = null, payinHash = '') => {
  const explorerLinkHashNormalized = typeof explorerLinkHash === 'string'
    ? explorerLinkHash.split(':hash').join('')
    : null;

  return explorerLinkHashNormalized
    ? `${explorerLinkHashNormalized}${payinHash}`
    : null;
};
