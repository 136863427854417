import React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';

const mapLinkFn = (data) => ({
  id: data?.id ?? null,
  name: data?.name ?? null,
  apiKey: data?.api_key ?? null,
  isAllow: data?.is_allow ?? false,
  partnerId: data?.partner_id ?? null,
  theme: data?.theme ?? null,
  createdAt: data?.created_at ?? null,
  updatedAt: data?.updated_at ?? null,
});
const mapFn = (data) => ({
  link: mapLinkFn(data?.link),
});

export const usePosTerminalData = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async () => {
    const [cancelToken] = getRequestSource();
    const requestPromise = client.get(
      '/pos-terminal-link',
      null,
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
