import { CURRENCIES_REDUCER_NAME, currenciesReducer } from '../currencies/reducer';
import { PARTNER_REDUCER_NAME, partnerReducer } from '../partner/reducer';
import { PAYMENT_SETTINGS_REDUCER_NAME, paymentSettingsReducer } from '../payment-settings/reducer';
import { SUBSCRIPTIONS_REDUCER_NAME, subscriptionsReducer } from '../subscriptions/reducer';
import { SETTINGS_REDUCER_NAME, settingsReducer } from '../settings/reducer';
import {
  CUSTOMER_MANAGEMENT_REDUCER_NAME,
  customerManagementReducer,
} from '../customer-management/reducer';
import { MARKET_INFO_REDUCER_NAME, marketInfoReducer } from '../market-info/reducer';
import { STATISTICS_REDUCER_NAME, statisticsReducer } from '../statistics/reducer';
import * as switcher from './switchere';

export const reducers = {
  [PARTNER_REDUCER_NAME] : partnerReducer,
  [STATISTICS_REDUCER_NAME] : statisticsReducer,
  [SETTINGS_REDUCER_NAME]: settingsReducer,
  [PAYMENT_SETTINGS_REDUCER_NAME]: paymentSettingsReducer,
  [CURRENCIES_REDUCER_NAME]: currenciesReducer,
  [SUBSCRIPTIONS_REDUCER_NAME]: subscriptionsReducer,
  [CUSTOMER_MANAGEMENT_REDUCER_NAME]: customerManagementReducer,
  [MARKET_INFO_REDUCER_NAME]: marketInfoReducer,
  [switcher.storeKey]: switcher.reducer,
};
