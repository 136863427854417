import React from 'react';
import { useDispatch } from 'react-redux';
import { useUpdateStoreOutcomeWalletsPriority } from '../../../api/modules/account/use-update-store-outcome-wallets-priority';
import { getNormalizedWalletsPriority, POPUPS_OBJECT } from '../helpers';
import { setWallets } from '../../../store/payment-settings/reducer';
import { getSafeErrorMessageText } from '../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../helpers/console-error-message';
import {
  CREATE_WHITELIST_ADDRESSES_PATH,
  useCreateWhitelistAddresses,
} from '../../../api/modules/account/use-create-whitelist-addresses';
import { ExtendedWallet, PopupData } from '../types';
import { ResponseType } from '../../../default-types';

export const useActions = (
  wallets: Array<ExtendedWallet>,
  onAddWalletInWhitelist: (walletId: string | null) => void,
) => {
  const [popupType, setPopupType] = React.useState<PopupData | null>(null);
  const [activeWallet, setActiveWallet] = React.useState<ExtendedWallet | null>(null);
  const [is2FAShow, setIs2FAShow] = React.useState(false);
  const [isAddInWhitelistPending, setIsAddInWhitelistPending] = React.useState(false);
  const [isSuccessWhitelistShow, setIsSuccessWhitelistShow] = React.useState(false);
  const [whitelistApiError, setWhitelistApiError] = React.useState<string | null>(null);
  const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout | null>(null);

  const ref = React.useRef<HTMLDivElement>(null);
  const fetchUpdateStoreOutcomeWalletsPriority = useUpdateStoreOutcomeWalletsPriority();
  const createWhitelistAddresses = useCreateWhitelistAddresses();
  const dispatch = useDispatch();

  const handlePopupOpen = (type: PopupData) => {
    setPopupType(type);
  };
  const handlePopupClose = () => {
    setPopupType(null);
    ref.current?.click();
    const currentTimeoutId = setTimeout(() => setActiveWallet(null), 300);
    setTimeoutId(currentTimeoutId);
  };
  const handleAddWalletPopupOpen = () => {
    handlePopupOpen(POPUPS_OBJECT.add);
  };
  const handleOptionsClick = React.useCallback((wallet: ExtendedWallet) => {
    setActiveWallet(wallet);
  }, []);
  const resetActiveWallet = () => {
    if (!popupType && !is2FAShow) {
      setActiveWallet(null);
    }
  };
  const handle2faClose = () => {
    setIs2FAShow(false);
    setWhitelistApiError(null);
    handlePopupClose();
  };
  const handleSuccessPopupClose = () => {
    setIsSuccessWhitelistShow(false);
    handlePopupClose();
  };
  const handleWhitelistSubmit = () => {
    setIs2FAShow(true);
    setPopupType(null);
  };

  const handleWhitelistAddressSend = async () => {
    setIsAddInWhitelistPending(true);

    const whitelistData = [{
      address: activeWallet?.baseAddress,
      currency: activeWallet?.baseCurrency,
      extra_id: activeWallet?.extraId,
    }];

    const { data, status, errorMessage }: ResponseType = await createWhitelistAddresses(whitelistData);

    setIsAddInWhitelistPending(false);

    if (status === 200) {
      setIs2FAShow(false);
      setIsSuccessWhitelistShow(true);
      onAddWalletInWhitelist(activeWallet?.id || null);
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, CREATE_WHITELIST_ADDRESSES_PATH);
      setWhitelistApiError(errorDataMessage);
    }
  };

  const handleSetAsMainWallet = async () => {
    const walletsNormalized = getNormalizedWalletsPriority(wallets, activeWallet?.id || '');
    const {
      data,
      status,
      errorMessage,
    }: ResponseType = await fetchUpdateStoreOutcomeWalletsPriority(walletsNormalized);

    if (status === 200 && data) {
      dispatch(setWallets(data));
      ref.current?.click();
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/store-outcome-wallets-priority');
    }
  };

  React.useEffect(() => () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  }, []);

  return {
    handlePopupOpen,
    handlePopupClose,
    handleAddWalletPopupOpen,
    handleOptionsClick,
    resetActiveWallet,
    handleSetAsMainWallet,
    handle2faClose,
    ref,
    popupType,
    activeWallet,
    is2FAShow,
    whitelistData: {
      isAddInWhitelistPending,
      isSuccessWhitelistShow,
      whitelistApiError,
      handleWhitelistAddressSend,
      handleSuccessPopupClose,
      handleWhitelistSubmit,
    },
  };
};
