import * as React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';
import {
  StatisticsCurrenciesType,
  StatisticsCurrencyType,
  StatisticsCurrenciesOptionsType,
} from '../../helpers/types';

const mapItemFn = (data: StatisticsCurrencyType) => ({
  currency: data?.currency ?? null,
  amount: data?.amount ?? null,
  count: data?.count ?? null,
  price: data?.price ?? null,
  baseCurrency: data?.base_currency ?? null,
  baseAmount: data?.base_amount ?? null,
});
const mapFn = (data: StatisticsCurrenciesType) => ({
  result: (data?.result ?? []).map(mapItemFn),
});

export const useStatisticsCurrencies = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (options: StatisticsCurrenciesOptionsType) => {
    const [cancelToken] = getRequestSource();
    const requestPromise = client.get(
      '/stats/payments/currency',
      {
        params: {
          base_currency: options?.baseCurrency || null,
          limit: options?.limit || null,
          date_from: options?.dateFrom || null,
          date_to: options?.dateTo || null,
        },
        cancelToken,
      },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [client, getRequestSource]);
};
