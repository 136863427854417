export const initialState = {
  allPlans: {
    items: null,
    count: null,
    fetching: false,
    fetched: false,
    error: null,
  },
  customers: [],
  customerSubscriptions: {},
  customerSubscriptionsFetching: false,
  customerSubscriptionsFetched: false,
  balances: {},
  balancesFetching: false,
  balancesFetched: false,
  fetching: false,
  fetched: false,
  error: null,
};
