import React from 'react';
import { POPUP_TYPE } from '../helpers';
import { getSafeErrorMessageText } from '../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../helpers/console-error-message';
import { useGenerateIpnKey } from '../../../api/modules/account/use-generate-ipn-key';
import { ResponseType } from '../../../default-types';

export const useActions = (getIpnKey: () => Promise<void>) => {
  const fetchGenerateIpnUrl = useGenerateIpnKey();

  const [popupType, setPopupType] = React.useState<null | string>(null);
  const [newIpnKey, setNewIpnKey] = React.useState<null | string>(null);
  const [isNewIpnKeyLoading, setIsNewIpnKeyLoading] = React.useState(false);
  const [newIpnKeyError, setNewIpnKeyError] = React.useState<null | string>(null);

  const handlePopupNewIpnKeyOpen = () => {
    setPopupType(POPUP_TYPE.newIpnKey);
  };

  const handlePopupRegenerateOpen = () => {
    setPopupType(POPUP_TYPE.regenerateIpnKey);
  };

  const handlePopupClose = () => {
    setPopupType(null);
    setNewIpnKey(null);
  };

  const regenerateIpnKey = React.useCallback(async () => {
    setIsNewIpnKeyLoading(true);

    const {
      data,
      status,
      errorMessage,
    }: ResponseType = await fetchGenerateIpnUrl();

    if (status === 200 && data) {
      setNewIpnKey(data.secretKey);
      getIpnKey();
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/instant-payment-notifications/secret-key');
      setNewIpnKeyError(errorDataMessage);
    }

    setIsNewIpnKeyLoading(false);
  }, [fetchGenerateIpnUrl]);

  return {
    popupType,
    handlePopupNewIpnKeyOpen,
    handlePopupRegenerateOpen,
    handlePopupClose,
    regenerateIpnKey,
    newIpnKey,
    isNewIpnKeyLoading,
    newIpnKeyError,
  };
};
