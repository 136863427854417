import * as React from 'react';
import { Helmet } from 'react-helmet';
import { BillingPlanPage } from '../../components/customers-pages';

export const BillingPlan = () => (
  <>
    <Helmet>
      <title>Billing Plan</title>
    </Helmet>
    <BillingPlanPage />
  </>
);
