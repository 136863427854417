import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initial-state';

export const PARTNER_REDUCER_NAME = 'partner';

const setPartnerDataState = (state, { payload }) => {
  state.data = payload;
};
const setPartnerFetchingState = (state, { payload }) => {
  state.fetching = payload;
};
const setPartnerFetchedState = (state, { payload }) => {
  state.fetched = payload;
};
const setPartnerErrorState = (state, { payload }) => {
  state.error = payload;
};
const setPartnerIsAutoPushState = (state, { payload }) => {
  if (state.data.isAutoPush === undefined) {
    return;
  }
  state.data.isAutoPush = payload;
};

export const partnerSlice = createSlice({
  name: PARTNER_REDUCER_NAME,
  initialState,
  reducers: {
    setPartner: setPartnerDataState,
    setPartnerFetching: setPartnerFetchingState,
    setPartnerFetched: setPartnerFetchedState,
    setPartnerError: setPartnerErrorState,
    setPartnerIsAutoPush: setPartnerIsAutoPushState,
  },
});

export const {
  setPartner,
  setPartnerFetching,
  setPartnerFetched,
  setPartnerError,
  setPartnerIsAutoPush,
} = partnerSlice.actions;
export const partnerReducer = partnerSlice.reducer;
