import React from 'react';
import cn from 'classnames';
import { Radio } from '../../../ui/radio';
import classes from './styles.module.scss';

type PaymentDetailsRadioData = {
  title: string,
  isActive: boolean,
  description: string,
  value: string,
};

type PaymentDetailsRadioButtonsProps = {
  firstRadioData: PaymentDetailsRadioData,
  secondRadioData: PaymentDetailsRadioData,
  onChange: () => void,
  name: string,
  isLoading: boolean,
  isError: boolean,
};

export const PaymentDetailsRadioButtons: React.FC<PaymentDetailsRadioButtonsProps> = (props) => {
  const {
    firstRadioData,
    secondRadioData,
    onChange,
    name,
    isLoading,
    isError,
  } = props;

  const isFirstRadioActive = !secondRadioData.isActive && firstRadioData.isActive;
  const isSecondRadioActive = !firstRadioData.isActive && secondRadioData.isActive;

  return (
    <div className={classes.radioButtons}>
      <Radio
        className={cn([
          classes.radioButton,
          isFirstRadioActive && classes.radioButtonActive,
          isLoading && isSecondRadioActive && classes.radioButtonLoading,
          isError && isSecondRadioActive && classes.radioButtonError,
        ])}
        name={name}
        checked={isFirstRadioActive}
        onChange={onChange}
        value={firstRadioData.value}
      >
        <>
          <p className={classes.radioButtonTitle}>
            {firstRadioData.title}
          </p>
          <p className={classes.radioButtonText}>
            {firstRadioData.description}
          </p>
        </>
      </Radio>
      <Radio
        className={cn([
          classes.radioButton,
          isSecondRadioActive && classes.radioButtonActive,
          isLoading && isFirstRadioActive && classes.radioButtonLoading,
          isError && isFirstRadioActive && classes.radioButtonError,
        ])}
        name={name}
        checked={isSecondRadioActive}
        onChange={onChange}
        value={secondRadioData.value}
      >
        <>
          <p className={classes.radioButtonTitle}>
            {secondRadioData.title}
          </p>
          <p className={classes.radioButtonText}>
            {secondRadioData.description}
          </p>
        </>
      </Radio>
    </div>
  );
};
