import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { InputSearch } from '../../../ui/input-search';
import { FilterButton } from '../../../ui/filter-button';
import { isFunction } from '../../../../helpers/is-function';
import { DateField } from '../../../ui/date-field';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import { Button } from '../../../ui/button';
import { PaymentsFilterMemo } from '../payments-filter';
import classes from './styles.module.scss';

export const PaymentsFullFilter = (props) => {
  const {
    onChangeSearch,
    activeFilterItemsNumber,
    onSubmit,
    onFilterReset,
    onExportModal,
    isExportDisabled,
    filterFormData,
    currencies,
    apiKeys,
  } = props;

  const isDesktop = useMediaQuery('(min-width: 992px)');

  const [isFilterActive, setIsFilterActive] = React.useState(false);
  const [createdFrom, setCreatedFrom] = React.useState(null);
  const [createdTo, setCreatedTo] = React.useState(null);

  const handleDatesReset = () => {
    setCreatedFrom(null);
    setCreatedTo(null);
  };
  const handleDateRangeChange = ({ from, to }) => {
    setCreatedFrom(from);
    setCreatedTo(to);
  };
  const handleFilterToggle = () => {
    setIsFilterActive((prevState) => !prevState);
  };
  const handleFilterClose = () => {
    setIsFilterActive(false);
  };
  const handleFilterSubmit = (data) => {
    const nextData = { ...data, dateFrom: createdFrom, dateTo: createdTo };

    if (isFunction(onSubmit)) {
      onSubmit(nextData, setIsFilterActive);
    }
  };

  return (
    <>
      <div className={classes.paymentsFullFilter}>
        <InputSearch
          id="payments-search"
          className={classes.searchInput}
          placeholder="Search Payment ID, Order ID..."
          onChange={onChangeSearch}
        />
        <FilterButton
          className={classes.filterButton}
          onClick={handleFilterToggle}
          active={isFilterActive}
          count={activeFilterItemsNumber}
        />
        {isDesktop && (
          <div className={cn([
            classes.searchFilterDateBox,
            isFilterActive && classes.searchFilterDateBoxActive,
          ])}>
            {isFilterActive && (
              <DateField
                className={classes.searchFilterDate}
                createdFrom={createdFrom}
                createdTo={createdTo}
                onChange={handleDateRangeChange}
                isPlaceholderDate
              />
            )}
          </div>
        )}
        <Button
          className={classes.exportButton}
          onClick={onExportModal}
          disabled={isExportDisabled}
        >
          Export
        </Button>
      </div>
      {isFilterActive && (
        <PaymentsFilterMemo
          className={classes.searchFilter}
          onSubmit={handleFilterSubmit}
          onClose={handleFilterClose}
          currencies={currencies}
          onResetFormData={onFilterReset}
          defaultData={filterFormData}
          onExport={onExportModal}
          exportDisabled={isExportDisabled}
          createdFrom={createdFrom}
          createdTo={createdTo}
          onDateChange={handleDateRangeChange}
          dateShow={!isDesktop}
          apiKeys={apiKeys}
          onDatesReset={handleDatesReset}
        />
      )}
    </>
  );
};

PaymentsFullFilter.defaultProps = {
  onChangeSearch: null,
  activeFilterItemsNumber: null,
  onSubmit: null,
  onFilterReset: null,
  onExportModal: null,
  isExportDisabled: false,
  filterFormData: null,
  currencies: null,
  apiKeys: null,
};

PaymentsFullFilter.propTypes = {
  onChangeSearch: PropTypes.func,
  activeFilterItemsNumber: PropTypes.number,
  onSubmit: PropTypes.func,
  onFilterReset: PropTypes.func,
  onExportModal: PropTypes.func,
  isExportDisabled: PropTypes.bool,
  filterFormData: PropTypes.object,
  currencies: PropTypes.arrayOf(PropTypes.object),
  apiKeys: PropTypes.arrayOf(PropTypes.object),
};
