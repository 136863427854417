import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPaymentSettingsFetching,
  setBaseCurrency,
  setBaseCurrencyFetched,
  setBaseCurrencyError,
} from '../store/payment-settings/reducer';
import { getSafeErrorMessageText } from '../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../helpers/console-error-message';
import {
  baseCurrencyFetchedSelector,
  baseCurrencySelector,
  paymentSettingsFetchingSelector,
} from '../store/payment-settings/selectors';
import { useBaseCurrency } from '../api/modules/account/use-base-currency';
import { initialState } from '../store/payment-settings/initial-state';
import { useTimeout } from './use-timeout';

export const useBaseCurrencyData = () => {
  const dispatch = useDispatch();
  const baseCurrency = useSelector(baseCurrencySelector());
  const paymentSettingsFetching = useSelector(paymentSettingsFetchingSelector());
  const baseCurrencyFetched = useSelector(baseCurrencyFetchedSelector());
  const fetchBaseCurrency = useBaseCurrency();

  const reFetchBaseCurrency = React.useCallback(async () => {
    if (baseCurrency || paymentSettingsFetching || baseCurrencyFetched) {
      return;
    }

    dispatch(setPaymentSettingsFetching(true));
    const { data, status, errorMessage } = await fetchBaseCurrency();

    if (status === 200) {
      const nextBaseCurrency = data?.baseCurrency ?? initialState.baseCurrency;

      dispatch(setBaseCurrency(nextBaseCurrency));
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/base-currency');
      dispatch(setBaseCurrencyError(`Base currency: ${errorDataMessage}`));
    }
    dispatch(setPaymentSettingsFetching(false));
    dispatch(setBaseCurrencyFetched(true));
  }, [
    baseCurrency,
    paymentSettingsFetching,
    baseCurrencyFetched,
  ]);

  useTimeout(reFetchBaseCurrency, 0, reFetchBaseCurrency);
};
