import React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';

const countryMapFn = (data) => ({
  id: data?.code ?? null,
  code: data?.code ?? null,
  forbidden: data?.forbidden ?? null,
  forbiddenCard: data?.forbidden_card ?? null,
  name: data?.name ?? null,
});

const mapFn = (data) => (
  (data ?? []).map(countryMapFn)
);

export const useCountries = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async () => {
    const [cancelToken] = getRequestSource();
    const params = {
      forbidden: 0,
      forbidden_card: 0,
    };
    const requestPromise = client.get(
      '/switchere/country/list',
      { cancelToken, params },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
