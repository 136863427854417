import React from "react";
import cn from "classnames";
import dateFormat from "../../libs/date-format";
import styles from "../payments-table-new/styles.module.scss";

const DonationsTable = ({ payments }) => {
  const [hideNav, setHideNav] = React.useState(false);
  const [hideMinPayment, setHideMinPayment] = React.useState(true);
  const [showPaymentDetails, setShowPaymentDetails] = React.useState(null);

  React.useEffect(() => {
    window.addEventListener("resize", resize.bind(this));
    resize();

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const resize = () => {
    setHideNav(window.innerWidth <= 760);
  };

  const handleArrowClick = (paymentId) => {
    setHideMinPayment(!hideMinPayment);
    if (showPaymentDetails === paymentId) {
      setShowPaymentDetails(null);

      return;
    }
    setShowPaymentDetails(paymentId);
  };

  return (
    <>
      {!hideNav && (
        <table className={styles.table}>
          <thead className={styles.thead}>
            <tr className={styles.tr}>
              <th className={styles.th}>
                <span>Payment ID</span>
              </th>
              <th className={styles.th}>
                <span>Status</span>
              </th>
              <th className={styles.th}>
                <span>Email</span>
              </th>
              <th className={styles.th}>
                <span>Phone</span>
              </th>
              <th className={styles.th}>
                <span>Name</span>
              </th>
              <th className={styles.th}>
                <span>Social Link</span>
              </th>
              <th className={styles.th}>
                <span>Address</span>
              </th>
              <th className={styles.th}>
                <span>Comment</span>
              </th>
              <th className={styles.th}>
                <span>Created at</span>
                <span>Last update at</span>
              </th>
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            {!hideNav &&
              payments.map((payment) => (
                <div className={styles.tdRow} key={payment.payment_id}>
                  <td className={styles.td}>
                    <span>{payment.payment_id}</span>
                  </td>
                  <td className={styles.td}>
                    <span className={cn(styles[payment.Payment.status])}>
                      {payment.Payment.status}
                    </span>
                  </td>
                  <td className={styles.td}>
                    <span>{payment.donation_data.email}</span>
                  </td>
                  <td className={styles.td}>
                    <span>{payment.donation_data.phone}</span>
                  </td>
                  <td className={styles.td}>
                    <span>{payment.donation_data.name}</span>
                  </td>
                  <td className={styles.td}>
                    <span>{payment.donation_data.social_link}</span>
                  </td>
                  <td className={styles.td}>
                    <span>{payment.donation_data.address}</span>
                  </td>
                  <td className={styles.td}>
                    <span>{payment.donation_data.comment}</span>
                  </td>
                  <td className={styles.td}>
                    <span>{dateFormat(payment.created_at)}</span>
                    <span>{dateFormat(payment.updated_at)}</span>
                  </td>
                </div>
              ))}
          </tbody>
        </table>
      )}
      {hideNav &&
        payments.map((payment) => (
          <div key={payment.id} className={styles.tableRow}>
            <div
              className={cn(styles.tableCell, { [styles.mb0]: hideMinPayment })}
            >
              <span>Payment ID</span>
              <div className={styles.value}>
                <span>{payment.payment_id}</span>
              </div>
              <span
                className={styles.arrow}
                onClick={() => handleArrowClick(payment.id)}
              />
            </div>
            {showPaymentDetails && payment.id === showPaymentDetails && (
              <>
                <div className={styles.tableCell}>
                  <span>Status</span>
                  <div className={styles.value}>
                    <span
                      className={cn(
                        styles[payment.Payment.status],
                        styles.tableValue,
                      )}
                    >
                      {payment.Payment.status}
                    </span>
                  </div>
                </div>
                <div className={styles.tableCell}>
                  <span>Email</span>
                  <div className={styles.value}>
                    <span className={styles.tableValue}>
                      {payment.donation_data.email}
                    </span>
                  </div>
                </div>
                <div className={styles.tableCell}>
                  <span>Phone</span>
                  <div className={styles.value}>
                    <span className={styles.tableValue}>
                      {payment.donation_data.phone}
                    </span>
                  </div>
                </div>
                <div className={styles.tableCell}>
                  <span>Name</span>
                  <div className={styles.value}>
                    <span className={styles.tableValue}>
                      {payment.donation_data.name}
                    </span>
                  </div>
                </div>
                <div className={styles.tableCell}>
                  <span>Social Link</span>
                  <div className={styles.value}>
                    <span className={styles.tableValue}>
                      {payment.donation_data.social_link}
                    </span>
                  </div>
                </div>
                <div className={styles.tableCell}>
                  <span>Address</span>
                  <div className={styles.value}>
                    <span className={styles.tableValue}>
                      {payment.donation_data.address}
                    </span>
                  </div>
                </div>
                <div className={styles.tableCell}>
                  <span>Comment</span>
                  <div className={styles.value}>
                    <span className={styles.tableValue}>
                      {payment.donation_data.comment}
                    </span>
                  </div>
                </div>
                <div className={styles.tableCell}>
                  <div>
                    <span>Create at</span>
                    <span>Last update at</span>
                  </div>
                  <div className={styles.value}>
                    <span className={styles.tableValue}>
                      {dateFormat(payment.created_at)}
                    </span>
                    <span className={styles.tableValue}>
                      {dateFormat(payment.updated_at)}
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
        ))}
      {payments.length === 0 && (
        <div className={styles.emptyBlock}>
          <p className={styles.emptyMessage}>No orders for now</p>
        </div>
      )}
    </>
  );
};

export default DonationsTable;
