import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { isFunction } from '../../../../helpers/is-function';
import { isCurrencyNetworkShow } from '../../../../helpers/is-currency-network-show';
import { CurrencyNetworkSup } from '../../../shared/currency-network-sup';
import classes from './styles.module.scss';

export const CurrenciesItem = (props) => {
  const {
    className,
    ticker,
    currentTicker,
    name,
    imagePath,
    network,
    onClick,
  } = props;

  const isNetworkShow = isCurrencyNetworkShow(network, ticker);

  const handleClick = () => {
    if (isFunction(onClick)) {
      onClick();
    }
  };
  const handleKeyPress = () => {
    if (isFunction(onClick)) {
      onClick();
    }
  };

  return (
    <li
      className={cn(classes.currenciesItem, className)}
      onClick={handleClick}
      onKeyDown={handleKeyPress}
      tabIndex={0}
      role="button"
    >
      {imagePath && (
        <img
          className={classes.currenciesItemImage}
          width={35}
          height={35}
          src={imagePath}
          alt={name}
          decoding="async"
        />
      )}
      <div className={classes.currenciesItemCoin}>
        <div className={classes.currenciesItemTicker}>
          {currentTicker}
          {isNetworkShow && (
            <CurrencyNetworkSup
              className={classes.currenciesItemNetwork}
              network={network}
            />
          )}
        </div>
        {name && (
          <div className={classes.currenciesItemName}>
            {name}
          </div>
        )}
      </div>
    </li>
  );
};

CurrenciesItem.defaultProps = {
  className: null,
  ticker: null,
  currentTicker: null,
  name: null,
  imagePath: null,
  network: null,
  onClick: null,
};

CurrenciesItem.propTypes = {
  className: PropTypes.string,
  ticker: PropTypes.string,
  currentTicker: PropTypes.string,
  name: PropTypes.string,
  imagePath: PropTypes.string,
  network: PropTypes.string,
  onClick: PropTypes.func,
};
