import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { safeToUpperCase } from '../../../../helpers/safe-to-upper-case';
import { formatDate } from '../../../../helpers/format-date';
import { isFunction } from '../../../../helpers/is-function';
import { safeToLowerCase } from '../../../../helpers/safe-to-lower-case';
import { getColorStatus } from '../../../../helpers/get-color-status';
import { STATUSES, DATE_PATTERN } from '../../../../constants/app-constants';
import { toFixed } from '../../../../helpers/to-fixed';
import { CurrencyNetworkSup } from '../../../shared/currency-network-sup';
import { getShortStringWithEllipsis } from '../../../../helpers/get-short-string-with-ellipsis';
import classes from './styles.module.scss';

const CustodyHistoryItem = (props) => {
  const {
    className,
    onClick,
    data,
    conversionHistory,
    fiatHistory,
    currenciesObject,
  } = props;

  const id = data?.id ?? null;
  const amount = fiatHistory ? data?.cryptoCurrencyAmount : data?.amount ?? '';
  const ticker = fiatHistory ? data?.cryptoCurrencyCode : data?.currency ?? '';
  const address = data?.address ?? '';
  const formattedAddress = getShortStringWithEllipsis(address);
  const bankAccount = data?.fiatAccountNumber ?? '';
  const formattedBankAccount = getShortStringWithEllipsis(bankAccount);
  const createdDate = data?.createdAt ?? null;
  const updatedDate = data?.updatedAt ?? null;
  const status = data?.status ?? null;
  const amountNumber = Number(amount);
  const currentAmount = !Number.isNaN(amountNumber) ? amountNumber : 0;
  const tickerUpperCased = safeToUpperCase(ticker);
  const currentTicker = currenciesObject[tickerUpperCased]?.ticker ?? tickerUpperCased;
  const currentTickerUpperCased = safeToUpperCase(currentTicker);
  const currentNetwork = currenciesObject[tickerUpperCased]?.network;
  const amountWithTicker = `${currentAmount} ${currentTickerUpperCased}`;
  const createdDateFormatted = formatDate(createdDate, DATE_PATTERN);
  const createdDateNormalized = createdDateFormatted || '-';
  const updatedDateFormatted = formatDate(updatedDate, DATE_PATTERN);
  const updatedDateNormalized = updatedDateFormatted || '-';
  const statusLowerCased = safeToLowerCase(status);
  const statusColor = status && getColorStatus(statusLowerCased);
  const isStatusWaiting = statusLowerCased === STATUSES.WAITING;
  const conversionTickerFrom = conversionHistory ? data?.currencyFrom : '';
  const conversionTickerTo = conversionHistory ? data?.currencyTo : '';
  const conversionTickerFromUpperCased = safeToUpperCase(conversionTickerFrom);
  const conversionTickerToUpperCased = safeToUpperCase(conversionTickerTo);
  const conversionCurrentTickerFrom = (
    currenciesObject[conversionTickerFromUpperCased]?.ticker ?? conversionTickerFromUpperCased
  );
  const conversionCurrentTickerTo = (
    currenciesObject[conversionTickerToUpperCased]?.ticker ?? conversionTickerToUpperCased
  );
  const conversionCurrentTickerFromUpperCased = safeToUpperCase(conversionCurrentTickerFrom);
  const conversionCurrentTickerToUpperCased = safeToUpperCase(conversionCurrentTickerTo);
  const conversionAmountFrom = conversionHistory ? data?.amountFrom : null;
  const conversionAmountTo = conversionHistory ? data?.amountTo : null;
  const conversionAmountFromFixed = toFixed(conversionAmountFrom, 8);
  const conversionAmountToFixed = toFixed(conversionAmountTo, 8);
  const conversionAmountFromNormalized = conversionAmountFromFixed || '-';
  const conversionAmountFromText = conversionAmountFrom
    ? `${conversionAmountFromFixed} ${conversionCurrentTickerFromUpperCased}`
    : conversionAmountFromNormalized;
  const conversionAmountToEstimating = !conversionAmountTo && isStatusWaiting ? 'Estimating' : null;
  const conversionAmountToNormalized = conversionAmountTo
    ? `${conversionAmountToFixed} ${conversionCurrentTickerToUpperCased}`
    : '-';
  const conversionAmountToText = conversionAmountToEstimating || conversionAmountToNormalized;
  const conversionNetworkFrom = conversionAmountFrom ? currenciesObject[conversionTickerFromUpperCased]?.network : null;
  const conversionNetworkTo = conversionAmountTo && !conversionAmountToEstimating
    ? currenciesObject[conversionTickerToUpperCased]?.network
    : null;

  const handleRowClick = () => {
    if (isFunction(onClick)) {
      onClick(data);
    }
  };

  return (
    <tr
      className={cn(classes.historyItem, className)}
      onClick={handleRowClick}
    >
      <td className={classes.historyItemTd}>
        <div>
          {id}
        </div>
      </td>
      <td className={cn(classes.historyItemTd, classes.historyItemColorBlack)}>
        {conversionHistory ? (
          <div>
            {conversionAmountFromText}
            {conversionNetworkFrom && (
              <CurrencyNetworkSup network={conversionNetworkFrom}/>
            )}
          </div>
        ) : (
          <div>
            {amountWithTicker}
            {currentNetwork && (
              <CurrencyNetworkSup network={currentNetwork}/>
            )}
          </div>
        )}
      </td>
      <td className={cn(classes.historyItemTd, classes.historyItemColorBlack)}>
        {fiatHistory && formattedBankAccount}
        {conversionHistory && (
          <div>
            {conversionAmountToText}
            {conversionNetworkTo && (
              <CurrencyNetworkSup network={conversionNetworkTo} />
            )}
          </div>
        )}
        {!conversionHistory && !fiatHistory && formattedAddress}
      </td>
      <td className={classes.historyItemTd}>
        {createdDateNormalized}
      </td>
      <td className={classes.historyItemTd}>
        {updatedDateNormalized}
      </td>
      <td className={cn(classes.historyItemTd, classes.historyItemTdCapitalized)} style={{ color: statusColor }}>
        {statusLowerCased}
      </td>
    </tr>
  );
};

CustodyHistoryItem.defaultProps = {
  className: null,
  onClick: null,
  data: null,
  conversionHistory: false,
  fiatHistory: false,
  currenciesObject: {},
};

CustodyHistoryItem.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  data: PropTypes.object,
  conversionHistory: PropTypes.bool,
  fiatHistory: PropTypes.bool,
  currenciesObject: PropTypes.object,
};

export const CustodyHistoryItemMemo = React.memo(CustodyHistoryItem);
