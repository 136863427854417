import moment from 'moment';

/**
 * @param currentDate {string}
 * @param daysNumber {string}
 * @returns {boolean}
 */
export const isDateMatchesDays = (currentDate, daysNumber = '0') => {
  const isCurrentDateString = typeof currentDate === 'string';

  if (!isCurrentDateString) {
    return false;
  }

  const newDate = new Date(currentDate);

  return moment().diff(newDate, 'days') <= daysNumber;
};
