import React from 'react';
import cn from 'classnames';
import { NETWORKS } from '../../../constants/networks';
import { CurrencyNetworkSupProps, NetworksDynamicKeys } from './types';
import classes from './styles.module.scss';

const DEFAULT_COLOR = '#c6c6c6';

export const CurrencyNetworkSup: React.FC<CurrencyNetworkSupProps> = (props) => {
  const {
    network,
    className = null,
    blinking = false,
  } = props;

  const networkObject: NetworksDynamicKeys = NETWORKS;
  const { name, color } = networkObject[network] || {};

  return (
    <sup
      className={cn(
        classes.currencySup,
        blinking && classes.currencySupBlinking,
        className,
      )}
      style={{ backgroundColor: color || DEFAULT_COLOR }}
    >
      {name || network}
    </sup>
  );
};
