import React from 'react';
import PropTypes from 'prop-types';

export const SettingsIcon = (props) => {
  const { color } = props;

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3018 10.1851C15.57 10.3276 15.777 10.5526 15.9226 10.7776C16.2062 11.2426 16.1832 11.8126 15.9073 12.3151L15.3707 13.2151C15.0871 13.6951 14.5583 13.9951 14.0141 13.9951C13.7458 13.9951 13.4469 13.9201 13.2016 13.7701C13.0024 13.6426 12.7724 13.5976 12.5271 13.5976C11.7683 13.5976 11.1322 14.2201 11.1092 14.9626C11.1092 15.8251 10.404 16.5001 9.52259 16.5001H8.48019C7.59109 16.5001 6.88594 15.8251 6.88594 14.9626C6.87061 14.2201 6.23444 13.5976 5.47564 13.5976C5.2227 13.5976 4.99276 13.6426 4.80115 13.7701C4.55588 13.9201 4.24929 13.9951 3.98869 13.9951C3.43683 13.9951 2.90797 13.6951 2.62438 13.2151L2.09551 12.3151C1.81192 11.8276 1.79659 11.2426 2.08019 10.7776C2.20282 10.5526 2.43276 10.3276 2.69336 10.1851C2.90797 10.0801 3.04594 9.90756 3.17623 9.70506C3.55947 9.06006 3.32953 8.21256 2.67803 7.83006C1.91923 7.40256 1.67396 6.45006 2.11084 5.70756L2.62438 4.82256C3.06893 4.08006 4.01935 3.81756 4.78582 4.25256C5.45264 4.61256 6.31875 4.37256 6.70965 3.73506C6.83229 3.52506 6.90127 3.30006 6.88594 3.07506C6.87061 2.78256 6.95492 2.50506 7.10055 2.28006C7.38414 1.81506 7.89768 1.51506 8.4572 1.50006H9.53792C10.1051 1.50006 10.6186 1.81506 10.9022 2.28006C11.0402 2.50506 11.1322 2.78256 11.1092 3.07506C11.0938 3.30006 11.1628 3.52506 11.2855 3.73506C11.6764 4.37256 12.5425 4.61256 13.217 4.25256C13.9758 3.81756 14.9339 4.08006 15.3707 4.82256L15.8843 5.70756C16.3288 6.45006 16.0836 7.40256 15.3171 7.83006C14.6656 8.21256 14.4356 9.06006 14.8265 9.70506C14.9492 9.90756 15.0871 10.0801 15.3018 10.1851ZM6.83229 9.00756C6.83229 10.1851 7.8057 11.1226 9.00906 11.1226C10.2124 11.1226 11.1628 10.1851 11.1628 9.00756C11.1628 7.83006 10.2124 6.88506 9.00906 6.88506C7.8057 6.88506 6.83229 7.83006 6.83229 9.00756Z"
      />
    </svg>
  );
};

SettingsIcon.defaultProps = {
  color: '#616161',
};

SettingsIcon.propTypes = {
  color: PropTypes.string,
};
