import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { safeToUpperCase } from '../../../../helpers/safe-to-upper-case';
import { formatDate } from '../../../../helpers/format-date';
import { isFunction } from '../../../../helpers/is-function';
import { DATE_PATTERN } from '../../../../constants/app-constants';
import classes from './styles.module.scss';

export const SubscriptionsTableRow = (props) => {
  const {
    className,
    data,
    currenciesObject,
    onClick,
    isDesktop,
  } = props;

  const id = data?.id ?? null;
  const title = data?.title ?? '-';
  const amount = data?.amount ?? null;
  const ticker = data?.currency ?? '';
  const tickerUpperCased = safeToUpperCase(ticker);
  const currentTicker = currenciesObject?.[tickerUpperCased]?.ticker ?? tickerUpperCased;
  const amountWithTicker = amount ? `${amount} ${currentTicker}` : '-';
  const period = data?.intervalDay ?? null;
  const currentPeriodName = period === 1 ? 'Day' : 'Days';
  const periodNormalized = period ? `${period} ${currentPeriodName}` : null;
  const createdAt = data?.createdAt ?? '';
  const createdDateFormatted = formatDate(createdAt, DATE_PATTERN);
  const createdDateNormalized = createdDateFormatted || '-';

  const handleRowClick = () => {
    if (isFunction(onClick)) {
      onClick(id);
    }
  };

  return (
    <tr
      className={cn(classes.row, className)}
      onClick={handleRowClick}
    >
      <td className={classes.rowTd}>
        <div className={classes.rowTdFirstItem}>
          {id}
        </div>
        {!isDesktop && (
          <div className={cn(classes.rowTdSecondItem, classes.rowTdColorBlack, classes.rowTdOverflow)}>
            {title}
          </div>
        )}
      </td>
      {isDesktop && (
        <td className={cn(classes.rowTd, classes.rowTdColorBlack, classes.rowTdOverflow)}>
          {title}
        </td>
      )}
      <td className={classes.rowTd}>
        <div className={cn(classes.rowTdFirstItem, classes.rowTdColorBlack)}>
          {amountWithTicker}
        </div>
        {!isDesktop && (
          <div className={cn(classes.rowTdSecondItem, classes.rowTdColorBlack)}>
            {periodNormalized}
          </div>
        )}
      </td>
      {isDesktop && (
        <td className={cn(classes.rowTd, classes.rowTdColorBlack)}>
          {periodNormalized}
        </td>
      )}
      <td className={classes.rowTd}>
        {createdDateNormalized}
      </td>
    </tr>
  );
};

SubscriptionsTableRow.defaultProps = {
  className: null,
  data: null,
  currenciesObject: {},
  onClick: null,
  isDesktop: false,
};

SubscriptionsTableRow.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    amount: PropTypes.number,
    currency: PropTypes.string,
    intervalDay: PropTypes.string,
    createdAt: PropTypes.string,
  }),
  currenciesObject: PropTypes.object,
  onClick: PropTypes.func,
  isDesktop: PropTypes.bool,
};
