import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { isFunction } from '../../../helpers/is-function';
import { DEFAULT_PER_PAGE } from '../../../constants/app-constants';
import { usePagination } from './hooks/use-pagination';
import styles from './styles.module.scss';

export const NewPagination = (props) => {
  const {
    className,
    currentPage,
    onChangePage,
    countItems,
    itemsPerPage,
  } = props;

  const [page, setPage] = React.useState(currentPage);

  const totalPagesNumber = React.useMemo(() => (
    Math.ceil(countItems / itemsPerPage)
  ), [countItems, itemsPerPage]);

  const pages = usePagination(page, totalPagesNumber);

  const changePage = React.useCallback((nextPage) => {
    if (nextPage < 1 || nextPage > totalPagesNumber || nextPage === page) {
      return;
    }
    if (isFunction(onChangePage)) {
      onChangePage(nextPage);
    }
    setPage(nextPage);
  }, [page, currentPage, onChangePage, totalPagesNumber]);

  const handlePageClick = (nextPage) => () => {
    changePage(nextPage);
  };

  React.useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  return (
    <div className={cn(styles.newPagination, className)}>
      {pages.map((pageItem) => (
        <React.Fragment key={pageItem.id}>
          {pageItem.page > 0 && (
            <button
              className={cn(
                styles.newPaginationButton,
                pageItem.page === page && styles.newPaginationButtonActive,
              )}
              type="button"
              onClick={handlePageClick(pageItem.page)}
            >
              {pageItem.page}
            </button>
          )}
          {pageItem.page === 0 && (
            <span className={styles.newPaginationSplitter}>
              ...
            </span>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

NewPagination.defaultProps = {
  className: null,
  currentPage: 1,
  onChangePage: null,
  countItems: 0,
  itemsPerPage: DEFAULT_PER_PAGE,
};

NewPagination.propTypes = {
  className: PropTypes.string,
  currentPage: PropTypes.number,
  onChangePage: PropTypes.func,
  countItems: PropTypes.number,
  itemsPerPage: PropTypes.number,
};
