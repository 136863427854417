import React from 'react';
import classes from './styles.module.scss';
import { TABS_OBJECT } from '../../helpers';
import { PaymentDetailsCurrencies } from '../payment-details-currencies';
import { PaymentDetailsFees } from '../payment-details-fees';
import { PaymentDetailsDeposits } from '../payment-details-deposits';
import { Currency, FiatCurrency } from '../../../../default-types';

type PaymentDetailsBlockProps = {
  title: string;
  iconPath: string;
  currentBaseCurrencyCode: string | null,
  baseCurrencies: Array<FiatCurrency | Currency>,
  donationFirstCurrency: Currency | null,
  invoiceFirstCurrency: Currency | null,
  partnerCurrencies: Array<Currency>,
  isFeeOptimisationEnable: boolean | null,
  feePaidBy: string | null,
  markup: string,
  covering: string,
  isAutoPush: boolean | null,
  partnerId: string,
  onDonationFirstCurrencyChange: React.Dispatch<React.SetStateAction<Currency | null>>,
  onInvoiceFirstCurrencyChange: React.Dispatch<React.SetStateAction<Currency | null>>,
};

export const PaymentDetailsTab: React.FC<PaymentDetailsBlockProps> = (props) => {
  const {
    title,
    iconPath,
    currentBaseCurrencyCode,
    baseCurrencies,
    donationFirstCurrency,
    invoiceFirstCurrency,
    partnerCurrencies,
    isFeeOptimisationEnable,
    feePaidBy,
    markup,
    covering,
    isAutoPush,
    partnerId,
    onDonationFirstCurrencyChange,
    onInvoiceFirstCurrencyChange,
  } = props;

  return (
    <div className={classes.paymentDetailsTab}>
      <div className={classes.paymentDetailsTabHeader}>
        <img
          src={iconPath}
          alt={title}
          width={32}
          height={32}
          decoding="async"
          loading="lazy"
        />
        {title}
      </div>
      <div className={classes.paymentDetailsTabContent}>
        {title === TABS_OBJECT.currencies.title && (
          <PaymentDetailsCurrencies
            currentBaseCurrencyCode={currentBaseCurrencyCode}
            baseCurrencies={baseCurrencies}
            donationFirstCurrency={donationFirstCurrency}
            invoiceFirstCurrency={invoiceFirstCurrency}
            partnerCurrencies={partnerCurrencies}
            onDonationFirstCurrencyChange={onDonationFirstCurrencyChange}
            onInvoiceFirstCurrencyChange={onInvoiceFirstCurrencyChange}
          />
        )}
        {title === TABS_OBJECT.fees.title && (
          <PaymentDetailsFees
            isFeeOptimisationEnable={isFeeOptimisationEnable}
            feePaidBy={feePaidBy}
            markup={markup}
            covering={covering}
          />
        )}
        {title === TABS_OBJECT.deposits.title && (
          <PaymentDetailsDeposits
            isAutoPush={isAutoPush}
            partnerId={partnerId}
          />
        )}
      </div>
    </div>
  );
};
