import * as React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';
import { PermanentDepositAddress } from '../../../default-types';

const mapItemFn = (data: PermanentDepositAddress) => ({
  address: data?.address ?? null,
  networks: data?.networks || [],
  chains: data?.chains || [],
  createdAt: data?.createdAt ?? null,
  updatedAt: data?.updatedAt ?? null,
});
const mapFn = (data: {
  status: string | null,
  result: Array<PermanentDepositAddress>,
  error: string | null,
}) => ({
  status: data?.status ?? null,
  result: Array.isArray(data?.result) ? data.result.map(mapItemFn) : [],
  error: data?.error ?? null,
});

export const useDepositAddresses = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async () => {
    const [cancelToken] = getRequestSource();
    const requestPromise = client.get(
      '/deposits/addresses',
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [client, getRequestSource]);
};
