import * as React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';
import { signInMapFn } from '../../helpers/account/map-fns';

export const useLoginWithGoogle = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (options) => {
    const access_token = options?.accessToken ?? null;
    const [cancelToken] = getRequestSource();

    const requestPromise = client.post(
      '/login-google',
      { access_token },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      signInMapFn,
    );
  }, [getRequestSource]);
};
