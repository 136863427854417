import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Popup } from '../../../popup';
import { PaymentLinkForm } from '../../../payment-link-form';
import { useProfileInfoState } from '../../hooks';
import { useCreateInvoice } from '../../../../api/modules/account/use-create-invoice';
import { isFunction } from '../../../../helpers/is-function';
import { identity } from '../../../../helpers/utils';
import { getSafeErrorMessageText } from '../../../../helpers/get-safe-error-message-text';
import { trackEvent } from '../../../../helpers/utils/track-event';
import { TRACK_EVENT_NAMES } from '../../../../constants/track-event-names';
import styles from '../../styles.module.scss';

const CreateInvoicePopup = (props) => {
  const {
    isOpen = false,
    onClose = identity,
    openObservablePayment,
    updateInvoices,
  } = props;
  const { profileInfo } = useProfileInfoState();
  const [showError, setShowError] = React.useState('');
  const {
    partnerCurrencies,
    isOnrampEnabled,
    baseCurrency,
  } = profileInfo;
  const postCreateInvoice = useCreateInvoice();
  const [isInvoiceCreating, setIsInvoiceCreating] = React.useState(false);

  const handleSubmitInvoice = async (formData) => {
    setIsInvoiceCreating(true);

    const { data, status } = await postCreateInvoice({
      priceAmount: formData?.price,
      priceCurrency: baseCurrency,
      payCurrency: formData?.payCurrency,
      orderId: formData?.orderId,
      orderDescription: formData?.orderDescription,
      isFixedRate: formData?.isFixedRate,
      isFeePaidByUser: formData?.isFeePaidByUser,
    });

    setIsInvoiceCreating(false);

    if (status === 200 && data) {
      const newInvoice = data?.invoice ?? null;
      const newPayment = data?.payment ?? null;
      let currentEventName = TRACK_EVENT_NAMES.INVOICE_CREATED;

      if (isFunction(openObservablePayment) && isFunction(updateInvoices)) {
        openObservablePayment(newInvoice, newPayment);
        void updateInvoices();
      }
      if (formData?.isFixedRate && !formData?.isFeePaidByUser) {
        currentEventName = TRACK_EVENT_NAMES.INVOICE_CREATED_WITH_FIXED_RATE;
      }
      if (formData?.isFeePaidByUser) {
        currentEventName = TRACK_EVENT_NAMES.INVOICE_CREATED_ALL_OPTIONS;
      }
      trackEvent(currentEventName, {
        action: 'submit',
      });
      onClose();
    } else {
      const errorMessage = data?.errorData?.message ?? null;
      const currentErrorMessage = getSafeErrorMessageText(errorMessage);
      setShowError(currentErrorMessage);
    }
  };

  return (
    <Popup
      className={styles.popupPaymentLinkCreate}
      open={isOpen}
      onClose={onClose}
      withCloseButton
    >
      <PaymentLinkForm
        ticker={baseCurrency}
        currencies={partnerCurrencies}
        error={showError}
        onError={setShowError}
        isLoading={isInvoiceCreating}
        onSubmit={handleSubmitInvoice}
        isOnrampEnabled={isOnrampEnabled}
      />
    </Popup>
  );
};

CreateInvoicePopup.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  openObservablePayment: PropTypes.func,
  updateInvoices: PropTypes.func,
};

export const CreateInvoicePopupMemo = React.memo(CreateInvoicePopup);
