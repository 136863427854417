import React from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { ValueFromStatistic } from '../value-from-statistic';
import {
  balanceSelector,
  countOfPayoutsSelector,
  turnoverSelector,
} from '../../../../store/statistics/selectors';
import classes from './styles.module.scss';
import { toFormat } from '../../../../helpers/to-format';
import { toFixed } from '../../../../helpers/to-fixed';
import { safeToUpperCase } from '../../../../helpers/safe-to-upper-case';
import { getCurrentNetworkForShow } from '../../../../helpers/get-current-network-for-show';
import { DashboardStatisticsPropsType } from '../../types';
import { partnerSelector } from '../../../../store/partner/selectors';
import { ROUTES } from '../../../../constants/routes';

export const DashboardStatistics: React.FC<DashboardStatisticsPropsType> = (props) => {
  const { currenciesObject } = props;
  const partner = useSelector(partnerSelector());
  const { amount: balanceAmount, currency: balanceCurrency } = useSelector(balanceSelector());
  const countOfPayouts = useSelector(countOfPayoutsSelector());
  const turnover = useSelector(turnoverSelector());
  const { ticker: turnoverTicker, amount: turnoverAmount } = turnover;
  const balanceAmountNormalized = Number(balanceAmount) ? `~ ${balanceAmount}` : balanceAmount;
  const balanceCurrencyNormalized = Number(balanceAmount) ? balanceCurrency : null;
  const countOfPayoutsFormatted = toFormat(countOfPayouts);
  const turnoverAmountFixed = toFixed(turnoverAmount, 2);
  const turnoverAmountFormatted = toFormat(turnoverAmountFixed);
  const coinFound = currenciesObject[safeToUpperCase(turnoverTicker)] || {};
  const currentTurnoverTicker = coinFound?.ticker ?? turnoverTicker;
  const turnoverNetwork = getCurrentNetworkForShow(coinFound?.network, turnoverTicker);
  const isCustody = partner?.isCustody ?? false;

  return (
    <div className={cn([
      classes.dashboardStatisticValues,
      !isCustody && classes.dashboardStatisticValuesReverse,
    ])}
    >
      <ValueFromStatistic
        className={classes.dashboardStatisticValue}
        title="Balance"
        amount={balanceAmountNormalized}
        ticker={balanceCurrencyNormalized}
        href={!isCustody ? ROUTES.CUSTODY : null}
        text={!isCustody
          ? 'Activate Custody to safely store funds and get access to off-chain convertions.'
          : null}
      />
      {partner?.isCustody && (
        <ValueFromStatistic
          className={classes.dashboardStatisticValue}
          title="Payouts"
          amount={countOfPayoutsFormatted || '0'}
        />
      )}
      <ValueFromStatistic
        className={classes.dashboardStatisticValue}
        title="Turnover"
        currency={currentTurnoverTicker}
        network={turnoverNetwork}
        amount={turnoverAmountFormatted || '0'}
        tickersShow
      />
    </div>
  );
};
