import React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';
import { createWithdrawalPaymentsRequestDataMapFn } from '../../helpers/account';

export const useCreateWithdrawalPayments = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (options) => {
    const withdrawals = options?.withdrawals ?? null;
    const [cancelToken] = getRequestSource();
    const requestPromise = client.post(
      '/withdrawal-payments',
      { withdrawals },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      createWithdrawalPaymentsRequestDataMapFn,
    );
  }, [getRequestSource]);
};
