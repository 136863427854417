import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ToolTip from '../../../components/tool-tip';
import commonStyles from '../../../css/common.module.scss';
import SubmitButton from '../../../components/submit-button';
import { isFunction } from '../../../helpers/is-function';
import styles from './styles.module.scss';

const SUCCESS_TEXT = 'Success';
const PATTERN = /^[^<>&\\]*$/;

const ProfileSettings = (props) => {
  const {
    companyText,
    onCompanyText,
    storeDetailText,
    onStoreDetailText,
    onSubmit,
    errorMessage,
    buttonState,
    storeDetailsErrorMessage,
  } = props;

  const handleCompanyChange = (event) => {
    const { value } = event.target;

    if (!PATTERN.test(value)) {
      return;
    }

    onCompanyText(value);
  };
  const handleStoreDetailsChange = (event) => {
    const { value } = event.target;

    if (!PATTERN.test(value)) {
      return;
    }

    onStoreDetailText(value);
  };
  const handleSubmit = () => {
    if (isFunction(onSubmit)) {
      onSubmit(companyText, storeDetailText);
    }
  };

  return (
    <>
      <h2>Profile</h2>
      <div className={`${styles.profile} ${commonStyles.panel}`}>
        <div className={styles.profileBox}>
          <div className={styles.profileItem}>
            <div className={cn(styles.profileItemTitle, commonStyles.label)}>
              <label>Company</label>
              <img
                className={styles.profileItemIcon}
                width={14}
                height={14}
                src="/images/info-icon.svg"
                alt="Icon help"
                data-tooltip-id="profile-settings"
                data-tooltip-content="Company name. Required for internal use in the system."
                decoding="async"
              />
            </div>
            <input
              className={cn(styles.profileCompanyInput, commonStyles.textField)}
              type="text"
              value={companyText}
              onChange={handleCompanyChange}
            />
          </div>
          <div className={cn(styles.profileItem, styles.profileItemStore)}>
            <div className={cn(styles.profileItemTitle, commonStyles.label)}>
              <label>Store Details</label>
              <img
                className={styles.profileItemIcon}
                width={14}
                height={14}
                src="/images/info-icon.svg"
                alt="Icon help"
                data-tooltip-id="profile-settings"
                data-tooltip-content="Share some brief company description or contact details which your customers will see on Payment Links"
                decoding="async"
              />
            </div>
            <textarea
              className={cn(styles.profileItemTextarea, commonStyles.textField)}
              value={storeDetailText}
              onChange={handleStoreDetailsChange}
            />
            <div className={styles.profileItemError}>
              {storeDetailsErrorMessage}
            </div>
          </div>
        </div>
        <SubmitButton
          className={styles.profileButton}
          successMessage={SUCCESS_TEXT}
          failureMessage={errorMessage}
          buttonState={buttonState}
          onSubmit={handleSubmit}
        >
          Save changes
        </SubmitButton>
      </div>
      <ToolTip id="profile-settings" className={styles.toolTip} />
    </>
  );
};

ProfileSettings.defaultProps = {
  companyText: '',
  onCompanyText: null,
  storeDetailText: '',
  onStoreDetailText: null,
  onSubmit: null,
  buttonState: null,
  errorMessage: null,
  storeDetailsErrorMessage: null,
};

ProfileSettings.propTypes = {
  companyText: PropTypes.string,
  onCompanyText: PropTypes.func,
  storeDetailText: PropTypes.string,
  onStoreDetailText: PropTypes.func,
  onSubmit: PropTypes.func,
  buttonState: PropTypes.string,
  errorMessage: PropTypes.string,
  storeDetailsErrorMessage: PropTypes.string,
};

export default ProfileSettings;
