import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Popup } from '../../../popup';
import { PaymentsInfoBox } from '../../../payments-info-box';
import { Button } from '../../../ui/button';
import classes from './styles.module.scss';

export const PopupInfo = (props) => {
  const {
    className,
    open,
    onClose,
    id,
    items,
    title,
  } = props;

  return (
    <Popup
      classNameContent={cn(classes.popup, className)}
      open={open}
      onClose={onClose}
    >
      <PaymentsInfoBox
        title={title}
        id={id}
        items={items}
      />
      <Button
        className={classes.button}
        onClick={onClose}
      >
        Ok
      </Button>
    </Popup>
  );
};

PopupInfo.defaultProps = {
  className: null,
  open: null,
  onClose: null,
  id: null,
  items: null,
  title: null,
};

PopupInfo.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  id: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};
