import React from 'react';
import { useSelector } from 'react-redux';
import { useAllCurrencies } from '../../../hooks/use-all-currencies';
import {
  partnerErrorSelector,
  partnerFetchedSelector,
} from '../../../store/partner/selectors';
import {
  baseCurrencyErrorSelector,
  baseCurrencyFetchedSelector,
} from '../../../store/payment-settings/selectors';
import { useFetchPartnerProfile } from '../../../api/modules/account/use-fetch-partner-profile';
import { useBaseCurrencyData } from '../../../hooks/use-base-currency-data';
import { useTurnover } from './use-turnover';
import { turnoverSelector } from '../../../store/statistics/selectors';

export const useInitState = () => {
  const isPartnerFetched = useSelector(partnerFetchedSelector());
  const partnerError = useSelector(partnerErrorSelector());
  const baseCurrencyError = useSelector(baseCurrencyErrorSelector());
  const isBaseCurrencyFetched = useSelector(baseCurrencyFetchedSelector());
  const turnover = useSelector(turnoverSelector());

  const {
    amount: turnoverAmount,
    isLoaded: isTurnoverLoaded,
    errorMessage: turnoverError,
  } = turnover;

  const [isTurnoverUpdated, setIsTurnoverUpdated] = React.useState(false);

  React.useEffect(() => {
    if (isTurnoverLoaded) {
      setIsTurnoverUpdated(true);
    }
  }, [turnover]);

  useFetchPartnerProfile();
  useBaseCurrencyData();
  useTurnover(isTurnoverUpdated);
  useAllCurrencies();

  const isTransactionHistoryShow = Boolean(Number(turnoverAmount));
  const hasErrors = turnoverError || partnerError || baseCurrencyError;
  const isLoaderShow = !isPartnerFetched && !isBaseCurrencyFetched && !isTurnoverLoaded && !hasErrors;

  return {
    isTransactionHistoryShow,
    hasErrors,
    isLoaderShow,
    isTurnoverLoaded,
  };
};
