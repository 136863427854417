import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { isFunction } from '../../../helpers/is-function';
import styles from './styles.module.scss';

export const Input = React.forwardRef((props, ref) => {
  const {
    className,
    id,
    name,
    type,
    value,
    placeholder,
    onChange,
    onFocus,
    onBlur,
    disabled,
    readOnly,
    autoComplete,
    error,
    success,
    pattern,
  } = props;

  const handleChange = (event) => {
    const targetValue = event.target.value;

    if (pattern && !pattern.test(targetValue)) {
      return;
    }

    if (isFunction(onChange)) {
      onChange(targetValue);
    }
  };
  const handleFocus = () => {
    if (isFunction(onFocus)) {
      onFocus();
    }
  };
  const handleBlur = () => {
    if (isFunction(onBlur)) {
      onBlur();
    }
  };

  return (
    <input
      className={cn([
        styles.input,
        className,
        error && styles.inputError,
        success && styles.inputSuccess,
      ])}
      type={type}
      id={id}
      name={name}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      disabled={disabled}
      readOnly={readOnly}
      value={value}
      placeholder={placeholder}
      spellCheck="false"
      autoComplete={autoComplete}
      ref={ref}
    />
  );
});

Input.defaultProps = {
  className: null,
  id: null,
  name: null,
  type: 'text',
  value: null,
  placeholder: null,
  onChange: null,
  onFocus: null,
  onBlur: null,
  disabled: false,
  readOnly: false,
  autoComplete: null,
  error: false,
  success: false,
  pattern: null,
};

Input.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  autoComplete: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  pattern: PropTypes.instanceOf(RegExp),
};

Input.displayName = 'Input';
