import React from 'react';
import { buttonState } from '../../components/submit-button';
import AffilateTable from '../../components/affilate-table';
import apiClient from '../../api/api-client';
import { useReferralProgramRequestPayout } from '../../api/modules/account/use-referral-program-request-payout';
import AffiliateStatistic from './affiliate-statistic';
import ReferralProgram from './referal-program';
import AffiliateHeader from './affiliate-header';

let timer = 0;

const AffiliateProgramPage = () => {
  const fetchReferralProgramRequestPayout = useReferralProgramRequestPayout();
  const [copyButtonState, setCopyButtonState] = React.useState(buttonState.normal);
  const [payoutButtonState, setPayoutButtonState] = React.useState(buttonState.normal);
  const [errorMessage, setErrorMessage] = React.useState('Error');
  const [successMessage, setSuccessMessage] = React.useState(null);
  const [referralLink, setReferralLink] = React.useState('');
  const [referralCount, setReferralCount] = React.useState(0);
  const [referralCountLastWeek, setReferralCountLastWeek] = React.useState(0);
  const [referralRewards, setReferralRewards] = React.useState([]);
  const [referralsStat, setReferralsStat] = React.useState([]);

  const fetch = async () => {
    const dataCurrencies = await apiClient.getAvailableCurrencies();

    const { data } = await apiClient.getStatistics();
    const {
      referralCount,
      referralCountLastWeek,
      referralRewards,
      referralsStat,
    } = data;

    for (const referralReward of referralRewards) {
      const currency = dataCurrencies.find(
        (c) => c.code.toUpperCase() === referralReward.currency.toUpperCase(),
      );

      if (currency) {
        referralReward.logoUrl = currency.logoUrl;
      }
    }

    setReferralCount(referralCount);
    setReferralCountLastWeek(referralCountLastWeek);
    setReferralRewards(referralRewards || []);
    setReferralsStat(referralsStat || []);

    const refLinkData = await apiClient.loadReferralLink();
    const { link_id } = refLinkData.data;

    if (link_id) {
      setReferralLink(`https://account.nowpayments.io/create-account?link_id=${link_id}`);
    }
  };
  const handleCopyButtonClick = () => {
    setCopyButtonState(buttonState.success);
    timer = setTimeout(() => {
      setCopyButtonState(buttonState.normal);
    }, 2000);
  };
  const handlePayoutButtonClick = async () => {
    const { data, status } = await fetchReferralProgramRequestPayout();

    if (status === 200) {
      if (data?.message) {
        setSuccessMessage(data.message);
      }
      setPayoutButtonState(buttonState.success);
    } else {
      let errorMessage = data?.errorData?.message ?? '';

      if (status === 403) {
        errorMessage = 'Access denied';
      }
      setErrorMessage(errorMessage);
      setPayoutButtonState(buttonState.failure);
    }
  };

  React.useEffect(() => {
    fetch();

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      <AffiliateHeader
        payoutButtonState={payoutButtonState}
        onPayoutButtonClick={handlePayoutButtonClick}
        errorMessage={errorMessage}
        successMessage={successMessage}
      />
      <AffiliateStatistic
        referralCount={referralCount}
        referralCountLastWeek={referralCountLastWeek}
        referralRewards={referralRewards}
      />
      <ReferralProgram
        referralLink={referralLink}
        copyButtonState={copyButtonState}
        onCopyButtonClick={handleCopyButtonClick}
      />
      <AffilateTable referralsStat={referralsStat} />
    </>
  );
};

export default AffiliateProgramPage;
