import React from 'react';
import cn from 'classnames';
import { ExternalLinkWithUnderline } from '../../../external-link-with-underline';
import { POPUP_CONTENT_TYPE } from '../../helpers';
import { LoaderIcon } from '../../../icons/loader-icon';
import classes from './styles.module.scss';

const TITLE_FOR_EMAIL = 'IP address whitelisting';
const BODY_FOR_ENABLE_IP = 'I\'d like to confirm to enable IP whitelisting.';

type WhitelistActivationType = {
  isActive: boolean,
  fetching: boolean,
  onPopupOpen: (type: string) => void,
};

export const WhitelistActivation = React.memo((props: WhitelistActivationType) => {
  const { isActive, fetching, onPopupOpen } = props;
  const iconPathName = isActive ? 'whitelist-active' : 'whitelist-disabled';

  const handleClick = () => {
    onPopupOpen(POPUP_CONTENT_TYPE.INFO);
  };
  const handleKeyPress = () => {
    onPopupOpen(POPUP_CONTENT_TYPE.INFO);
  };

  return (
    <div
      className={cn([
        classes.whitelistActivation,
        isActive && classes.whitelistActivationHover,
      ])}
      onClick={isActive ? handleClick : undefined}
      onKeyPress={isActive ? handleKeyPress : undefined}
      tabIndex={isActive ? 0 : undefined}
      role={isActive ? 'button' : undefined}
    >
      {fetching ? (
        <LoaderIcon size={20} />
      ) : (
        <>
          <img
            src={`/images/${iconPathName}.svg`}
            alt="Icon"
            decoding="async"
            loading="lazy"
            width={24}
            height={24}
          />
          <div className={classes.whitelistActivationText}>
            {isActive ? 'All funds are secured' : 'Risk control disabled'}
          </div>
          {isActive ? (
            <img
              className={classes.whitelistActivationInfoIcon}
              src="/images/whitelist-info.svg"
              alt="Icon"
              decoding="async"
              loading="lazy"
            />
          ) : (
            <ExternalLinkWithUnderline
              className={classes.whitelistActivationLink}
              href={`mailto:partners@nowpayments.io?subject=${TITLE_FOR_EMAIL}&body=${BODY_FOR_ENABLE_IP}`}
              text="Activate Whitelisting"
            />
          )}
        </>
      )}
    </div>
  );
});
