import * as React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../ui/button';
import { InputSearch } from '../../../ui/input-search';
import classes from './styles.module.scss';

export const BillingPlanFilter = (props) => {
  const {
    onClick,
    onChangeSearch,
    isAllowed,
  } = props;

  return (
    <div className={classes.billingPlanFilter}>
      <Button
        className={classes.button}
        onClick={onClick}
      >
        Manage customers
      </Button>
      <InputSearch
        className={classes.searchInput}
        placeholder="Search Customer ID"
        onChange={onChangeSearch}
        disabled={!isAllowed}
      />
    </div>
  );
};

BillingPlanFilter.defaultProps = {
  onClick: null,
  onChangeSearch: null,
  isAllowed: false,
};

BillingPlanFilter.propTypes = {
  onClick: PropTypes.func,
  onChangeSearch: PropTypes.func,
  isAllowed: PropTypes.bool,
};
