import { DATE_PATTERN, TOOLTIP_TEXTS } from '../constants/app-constants';
import { getPaymentExtraIdsString } from './get-payment-extra-ids-string';
import { safeToUpperCase } from './safe-to-upper-case';
import { getColorStatus } from './get-color-status';
import { formatDate } from './format-date';
import { safeToLowerCase } from './safe-to-lower-case';
import { getPaymentNetworkFee } from './get-payment-network-fee';
import { getPaymentServiceFee } from './get-payment-service-fee';
import { getArrayWithIndexId } from './get-array-with-index-id';
import { normalizeAmountWithTickerString } from './normalize-amount-with-ticker-string';
import { getPayinHashLink } from './get-payin-hash-link';
import { getCurrentNetworkForShow } from './get-current-network-for-show';

/**
 * @param {Object} payment
 * @param {Boolean} isCustody
 * @param {Object} currenciesObject
 * @returns {Array}
 */
export const getPaymentInfoItems = (payment = {}, isCustody, currenciesObject = {}) => {
  const invoiceId = payment?.invoiceId ?? null;
  const parentPaymentId = payment?.parentPaymentId ?? null;
  const paymentExtraIds = getPaymentExtraIdsString(payment?.paymentExtraIds ?? null);
  const orderId = payment?.orderId ?? null;
  const orderDescription = payment?.orderDescription ?? null;

  const payTicker = payment?.payCurrency ?? '';
  const amountSend = payment?.exchangeData?.amountSend ?? '0';
  const actuallyPaidAmountWithTicker = `${amountSend} ${payTicker}`;

  const originalPrice = normalizeAmountWithTickerString(payment?.originalPrice, currenciesObject);
  const payPrice = normalizeAmountWithTickerString(payment?.payPrice, currenciesObject, payTicker, null);
  const actuallyPaid = normalizeAmountWithTickerString(actuallyPaidAmountWithTicker, currenciesObject, payTicker, null);

  const outcomeAmount = payment?.outcomeAmount ?? '';
  const outcomeTicker = payment?.outcomeCurrency ?? '';
  const outcomeCurrentTicker = currenciesObject?.[outcomeTicker]?.ticker ?? outcomeTicker;
  const outcomeCurrentTickerUpperCased = safeToUpperCase(outcomeCurrentTicker);
  const outcomePriceText = outcomeAmount ? `${outcomeAmount} ${outcomeCurrentTickerUpperCased}` : 'N/A';

  const type = payment?.type ?? null;
  const status = payment?.status ?? null;
  const statusColor = status && getColorStatus(status);
  const statusNode = status ? `<span style="color: ${statusColor}">${status}</span>` : null;
  const createdDate = payment?.createdAt ?? null;
  const createdDateNormalized = createdDate ? formatDate(createdDate, DATE_PATTERN) : null;
  const updatedDate = payment?.updatedAt ?? null;
  const updatedDateNormalized = updatedDate ? formatDate(updatedDate, DATE_PATTERN) : null;
  const payinAddress = payment?.exchangeData?.payinAddress ?? null;
  const payinAddressNode = payinAddress ? `<strong>${payinAddress}</strong>` : null;
  const payinExtraId = payment?.exchangeData?.payinExtraId ?? null;
  const payoutAddress = payment?.exchangeData?.payoutAddress ?? '';
  const isChangeNowPartner = payoutAddress?.startsWith('changenow_partner') ?? false;
  const currentPayoutAddress = isCustody && isChangeNowPartner ? 'Balance' : payoutAddress;
  const currencyFound = currenciesObject?.[safeToLowerCase(payTicker)] || {};
  const payinHash = payment?.exchangeData?.payinHash ?? '';
  const payinHashLink = getPayinHashLink(currencyFound?.explorer_link_hash, payinHash);
  const payoutHash = payment?.exchangeData?.payoutHash ?? '';
  const fixedRate = payment?.isFixedRate ? 'True' : 'False';
  const feePaidByUser = payment?.isFeePaidByUser ? 'True' : 'False';
  const networkFee = getPaymentNetworkFee(payment, 8, currenciesObject);
  const serviceFee = getPaymentServiceFee(payment, 8, currenciesObject);
  const ipnCallbackUrl = payment?.ipnCallbackUrl;

  const originalTicker = payment?.originalPrice?.split?.(' ')?.[1];
  const currentOriginalNetwork = getCurrentNetworkForShow(currenciesObject[originalTicker]?.network, originalTicker);
  const currentPayNetwork = getCurrentNetworkForShow(currenciesObject[payTicker]?.network, payTicker);
  const currentOutcomeNetwork = outcomeAmount
    ? getCurrentNetworkForShow(currenciesObject[outcomeTicker]?.network, outcomeTicker)
    : null;

  const result = [{
    key: 'Original Payment',
    value: parentPaymentId,
    network: null,
    link: null,
    isCopied: false,
  }, {
    key: 'Additional Payments',
    value: paymentExtraIds,
    network: null,
    link: null,
    isCopied: false,
  }, {
    key: 'Invoice id',
    value: invoiceId,
    network: null,
    link: null,
    isCopied: false,
  }, {
    key: 'Order id',
    value: orderId,
    network: null,
    link: null,
    isCopied: false,
  }, {
    key: 'Order description',
    value: orderDescription,
    network: null,
    link: null,
    isCopied: false,
  }, {
    key: 'Original price',
    value: originalPrice,
    network: currentOriginalNetwork,
    link: null,
    isCopied: false,
  }, {
    key: 'Pay price',
    value: payPrice,
    network: currentPayNetwork,
    link: null,
    isCopied: false,
  }, {
    key: 'Actually paid',
    value: actuallyPaid,
    network: null,
    link: null,
    isCopied: false,
  }, {
    key: 'Outcome Price',
    value: outcomePriceText,
    network: currentOutcomeNetwork,
    link: null,
    isCopied: false,
  }, {
    key: 'Type',
    value: type,
    network: null,
    link: null,
    isCopied: false,
  }, {
    key: 'Status',
    value: statusNode,
    network: null,
    link: null,
    isCopied: false,
  }, {
    key: 'Created at',
    value: createdDateNormalized,
    network: null,
    link: null,
    isCopied: false,
  }, {
    key: 'Updated at',
    value: updatedDateNormalized,
    network: null,
    link: null,
    isCopied: false,
  }, {
    key: 'Payin address',
    value: payinAddressNode,
    network: null,
    link: null,
    isCopied: false,
  }, {
    key: 'Payin Extra ID',
    value: payinExtraId,
    network: null,
    link: null,
    isCopied: false,
  }, {
    key: 'Payout address',
    value: currentPayoutAddress,
    network: null,
    link: null,
    isCopied: false,
  }, {
    key: 'Payin hash',
    value: payinHash,
    network: null,
    link: payinHashLink,
    isCopied: true,
  }, {
    key: 'Payout hash',
    value: payoutHash,
    network: null,
    link: null,
    isCopied: true,
  }, {
    key: 'Fixed rate',
    value: fixedRate,
    network: null,
    link: null,
    isCopied: false,
  }, {
    key: 'Fee paid by user',
    value: feePaidByUser,
    network: null,
    link: null,
    isCopied: false,
  }, {
    key: 'Network fee',
    value: networkFee,
    network: null,
    link: null,
    isCopied: false,
    tooltipText: TOOLTIP_TEXTS.NETWORK_FEE,
  }, {
    key: 'Service fee',
    value: serviceFee,
    network: null,
    link: null,
    isCopied: false,
    tooltipText: TOOLTIP_TEXTS.SERVICE_FEE,
  },
  {
    key: 'Ipn callback url',
    value: ipnCallbackUrl,
    network: null,
    link: null,
    isCopied: false,
  }];

  return getArrayWithIndexId(result);
};
