import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { isFunction } from '../../../../helpers/is-function';
import { Field } from '../../../ui/field';
import { LoaderIcon } from '../../../icons/loader-icon';
import { Button } from '../../../ui/button';
import { safeToLowerCase } from '../../../../helpers/safe-to-lower-case';
import { hasElementsInArray } from '../../../../helpers/has-elements-in-array';
import { Radio } from '../../../ui/radio';
import { CustomerPageContext } from '../../customer-page';
import classes from './styles.module.scss';

const AMOUNT_REQUIRED = 'Amount is required field';
const NOT_ENOUGH_BALANCE = 'Not enough balance';
const CURRENCY_REQUIRED = 'Currency is required';

export const CustomerDepositOrWithdrawForm = (props) => {
  const {
    className,
    currencies,
    defaultTicker,
    buttonText,
    tabsShow,
  } = props;

  const customerPageContext = React.useContext(CustomerPageContext);
  const {
    onDepositOrWithdraw: onSubmit,
    depositOrWithdrawFetching: fetching,
    depositOrWithdrawApiError: errorApiMessage,
  } = customerPageContext;

  const [amount, setAmount] = React.useState('');
  const [ticker, setTicker] = React.useState(defaultTicker);
  const [isDepositWithPayment, setIsDepositWithPayment] = React.useState(false);
  const [error, setError] = React.useState(null);

  const currentBalance = React.useMemo(() => {
    if (!hasElementsInArray(currencies)) {
      return 0;
    }

    return currencies.find((item) => (
      safeToLowerCase(item?.code) === safeToLowerCase(ticker)
    ))?.amount ?? 0;
  }, [currencies, ticker]);

  const resetError = () => {
    setError(null);
  };
  const handleInputChange = React.useCallback((value) => {
    setAmount(value);
    resetError();
  }, []);
  const handleSelect = React.useCallback((data) => {
    const currentAmount = data?.amount ? String(data.amount) : '';
    setAmount(currentAmount);
    setTicker(data?.code ?? null);
    resetError();
  }, []);
  const handleDepositTypeChange = () => {
    setIsDepositWithPayment((prevState) => !prevState);
    resetError();
  };
  const checkFormIsValid = () => {
    if (!amount || Number(amount) <= 0) {
      setError(AMOUNT_REQUIRED);

      return false;
    }

    if (!isDepositWithPayment && Number(amount) > currentBalance) {
      setError(NOT_ENOUGH_BALANCE);

      return false;
    }

    if (!ticker) {
      setError(CURRENCY_REQUIRED);

      return false;
    }

    return true;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const isValid = checkFormIsValid();

    if (isFunction(onSubmit) && isValid) {
      onSubmit({
        amount,
        ticker,
        isDepositWithPayment,
      });
    }
  };

  return (
    <form
      className={cn(classes.customerDepositOrWithdrawForm, className)}
      onSubmit={handleSubmit}
    >
      <Field
        title="Amount"
        data={{
          type: 'text',
          value: amount,
          onChange: handleInputChange,
          error,
          currencies,
          onSelect: handleSelect,
          defaultTicker,
          placeholder: '0.00',
          pattern: /^\d*[.]?\d*$/,
        }}
      />
      {tabsShow && (
        <div className={classes.radioButtons}>
          <Radio
            className={classes.radioButton}
            value="deposit-with-master"
            checked={!isDepositWithPayment}
            onChange={handleDepositTypeChange}
          >
            Deposit from master
          </Radio>
          <Radio
            className={classes.radioButton}
            value="deposit-with-payment"
            checked={isDepositWithPayment}
            onChange={handleDepositTypeChange}
          >
            Deposit with payment
          </Radio>
        </div>
      )}
      <div className={classes.error}>
        {errorApiMessage}
      </div>
      <Button
        className={classes.button}
        type="submit"
        disabled={fetching}
      >
        {fetching && (
          <LoaderIcon path="/images/loader-white-on-blue-icon.gif" />
        )}
        {!fetching && buttonText}
      </Button>
    </form>
  );
};

CustomerDepositOrWithdrawForm.defaultProps = {
  className: null,
  currencies: null,
  defaultTicker: null,
  buttonText: null,
  tabsShow: false,
};

CustomerDepositOrWithdrawForm.propTypes = {
  className: PropTypes.string,
  currencies: PropTypes.arrayOf(PropTypes.object),
  defaultTicker: PropTypes.string,
  buttonText: PropTypes.string,
  tabsShow: PropTypes.bool,
};
