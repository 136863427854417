import React from 'react';
import { useSelector } from 'react-redux';
import { useGetFirstCurrencies } from '../../../api/modules/account/use-get-first-currencies';
import { getSafeErrorMessageText } from '../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../helpers/console-error-message';
import { getFilteredCurrencies } from '../../../helpers/get-filtered-currencies';
import { currencyListSelector, selectedTickersSelector } from '../../../store/currencies/selectors';
import { useCryptoCurrencies } from '../../../hooks/use-crypto-currencies';
import { storeDataSelector } from '../../../store/payment-settings/selectors';
import { partnerSelector } from '../../../store/partner/selectors';
import { Currency, ResponseType } from '../../../default-types';

export const useInitState = () => {
  const tickersSelectedByPartner = useSelector(selectedTickersSelector());
  const currencyList = useSelector(currencyListSelector());
  const { cryptoCurrencies } = useCryptoCurrencies(currencyList);
  const fetchFirstCurrencies = useGetFirstCurrencies();
  const storeData = useSelector(storeDataSelector());
  const partner = useSelector(partnerSelector());
  const isFeeOptimisationEnable = storeData?.feeOptimization ?? null;
  const feePaidBy = storeData?.withdrawalFeePaidBy ?? null;
  const markup = storeData?.markup ?? '';
  const covering = storeData?.covering ?? '';
  const isAutoPush = partner?.isAutoPush ?? null;
  const partnerId = partner?.id ?? '';

  const [donationFirstCurrency, setDonationFirstCurrency] = React.useState<Currency | null>(null);
  const [invoiceFirstCurrency, setInvoiceFirstCurrency] = React.useState<Currency | null>(null);

  const partnerCurrencies = React.useMemo(() => (
    getFilteredCurrencies(cryptoCurrencies, tickersSelectedByPartner)
  ), [currencyList, tickersSelectedByPartner]);

  const getFirstCurrencies = React.useCallback(async () => {
    const {
      data,
      status,
      errorMessage,
    }: ResponseType = await fetchFirstCurrencies();

    if (status === 200 && data) {
      setDonationFirstCurrency(data.data?.firstCurrencies?.donation);
      setInvoiceFirstCurrency(data.data?.firstCurrencies?.invoice);
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/base-currency');
    }
  }, [fetchFirstCurrencies]);

  React.useEffect(() => {
    getFirstCurrencies();
  }, []);

  return {
    donationFirstCurrency,
    invoiceFirstCurrency,
    partnerCurrencies,
    isFeeOptimisationEnable,
    feePaidBy,
    markup,
    covering,
    isAutoPush,
    partnerId,
    setDonationFirstCurrency,
    setInvoiceFirstCurrency,
  };
};
