import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { safeToUpperCase } from '../../../../helpers/safe-to-upper-case';
import { formatDate } from '../../../../helpers/format-date';
import { isFunction } from '../../../../helpers/is-function';
import { getColorStatus } from '../../../../helpers/get-color-status';
import { STATUSES, DATE_PATTERN, PAYMENT_ORIGIN_TYPES } from '../../../../constants/app-constants';
import styles from './styles.module.scss';

export const PaymentLinksItem = (props) => {
  const {
    className,
    paymentId,
    orderId,
    payAmount,
    payTicker,
    outcomeAmount,
    amountReceive,
    receivedTicker,
    created,
    updated,
    status,
    originType,
    onClick,
    currenciesObject,
  } = props;

  const currentPayAmount = payAmount || null;
  const payTickerUpperCased = payTicker ? safeToUpperCase(payTicker) : '';
  const currentPayTicker = currenciesObject?.[payTickerUpperCased]?.ticker ?? payTickerUpperCased;
  const amountSent = currentPayAmount ? `${currentPayAmount} ${currentPayTicker}` : '-';
  const currentReceivedAmount = outcomeAmount || amountReceive || null;
  const receivedTickerUpperCased = receivedTicker ? safeToUpperCase(receivedTicker) : '';
  const currentReceivedTicker = currenciesObject?.[receivedTickerUpperCased]?.ticker ?? receivedTickerUpperCased;
  const amountReceived = currentReceivedAmount ? `${currentReceivedAmount} ${currentReceivedTicker}` : '-';
  const createdDateFormatted = formatDate(created, DATE_PATTERN);
  const createdDateNormalized = createdDateFormatted || '-';
  const updatedDateFormatted = formatDate(updated, DATE_PATTERN);
  const updatedDateNormalized = updatedDateFormatted || '-';
  const isIconShow = status === STATUSES.PARTIALLY_PAID || status === STATUSES.FAILED;
  const originTypeRepeatedText = originType === PAYMENT_ORIGIN_TYPES.REPEATED ? 'Re-deposit' : null;
  const originTypeChildText = originType === PAYMENT_ORIGIN_TYPES.CHILD ? 'Wrong Asset' : null;
  const currentOriginTypeText = originTypeRepeatedText || originTypeChildText || null;

  const handleRowClick = () => {
    if (isFunction(onClick)) {
      onClick();
    }
  };

  const color = React.useMemo(() => (
    getColorStatus(status)
  ), [status]);

  return (
    <tr
      className={cn(styles.item, className)}
      onClick={handleRowClick}
    >
      <td>
        {paymentId && (
          <div>
            {paymentId}
          </div>
        )}
        {orderId && (
          <div>
            {orderId}
          </div>
        )}
      </td>
      <td className={styles.colorBlack}>
        {amountSent}
      </td>
      <td className={styles.colorBlack}>
        {amountReceived}
      </td>
      <td>
        {createdDateNormalized}
      </td>
      <td>
        {updatedDateNormalized}
      </td>
      <td>
        {currentOriginTypeText && (
          <div className={styles.marginBottom}>
            {currentOriginTypeText}
          </div>
        )}
        <div style={{ color }}>
          {status}
          {isIconShow && (
            <img
              className={styles.icon}
              src="/images/edit-icon.svg"
              alt="Edit Icon"
              decoding="async"
            />
          )}
        </div>
      </td>
    </tr>
  );
};

PaymentLinksItem.defaultProps = {
  className: null,
  paymentId: null,
  payAmount: null,
  payTicker: null,
  outcomeAmount: null,
  expectedReceivedAmount: null,
  receivedTicker: null,
  created: null,
  updated: null,
  status: null,
  originType: null,
  onClick: null,
  currenciesObject: null,
};

PaymentLinksItem.propTypes = {
  className: PropTypes.string,
  paymentId: PropTypes.string,
  payAmount: PropTypes.string,
  payTicker: PropTypes.string,
  outcomeAmount: PropTypes.string,
  expectedReceivedAmount: PropTypes.number,
  receivedTicker: PropTypes.string,
  created: PropTypes.string,
  updated: PropTypes.string,
  status: PropTypes.string,
  originType: PropTypes.string,
  onClick: PropTypes.func,
  currenciesObject: PropTypes.object,
};
