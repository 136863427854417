import * as React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { store } from './store';
import { RoutesSwitcher } from './components/routes-switcher';
import { routes, privateRoutes } from './routes';
import { useVerify } from './api/modules/account/use-verify';
import config from './config';

export const App = () => {
  const fetchVerify = useVerify();

  const [isAuthorized, setIsAuthorized] = React.useState(false);
  const [isFetched, setIsFetched] = React.useState(false);

  const getIsAuth = async () => {
    const token = localStorage.getItem('authToken');
    let isAuth = false;

    if (!token) {
      return isAuth;
    }

    const { data, status } = await fetchVerify();

    if (status === 200 && data) {
      isAuth = true;
    }

    return isAuth;
  };

  const setAuthData = async () => {
    const isAuth = await getIsAuth();

    setIsAuthorized(isAuth);
    setIsFetched(true);
  };

  React.useEffect(() => {
    setAuthData();

    const nextTimerId = setTimeout(() => {
      ReactGA.initialize(config.GOOGLE_GTAG_ID);
    }, 5000);

    return () => {
      clearTimeout(nextTimerId);
    };
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <RoutesSwitcher
          routes={routes}
          privateRoutes={privateRoutes}
          auth={isAuthorized}
          authFetched={isFetched}
        />
      </Router>
    </Provider>
  );
};
