import React from 'react';
import cn from 'classnames';
import {
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import { StatsPieChartProps, CustomizedLabelForPieChart } from '../../types';
import { getCustomizedLabelForPieChart } from '../../helpers';
import { TopCoins } from '../top-coins';
import classes from './styles.module.scss';

export const StatsPieChart: React.FC<StatsPieChartProps> = (props) => {
  const {
    className,
    data,
  } = props;

  const isEmptyData = data?.[0]?.isEmptyData;

  const handleLabel = React.useCallback((options: CustomizedLabelForPieChart): React.ReactElement => {
    const { coordinateX, coordinateY, label } = getCustomizedLabelForPieChart(options);
    return (
      <text x={coordinateX} y={coordinateY} fill="white" dominantBaseline="central">
        {isEmptyData ? ' ' : label}
      </text>
    );
  }, [isEmptyData]);

  return (
    <div className={cn(classes.statsPieChart, className)}>
      <PieChart width={200} height={200} className={classes.statsPieChartBox}>
        <Pie
          data={data}
          innerRadius={50}
          outerRadius={100}
          dataKey="amount"
          label={handleLabel}
          labelLine={false}
          animationDuration={600}
        >
          {data.map((item) => <Cell key={item.id} fill={item.color} />)}
        </Pie>
      </PieChart>
      <TopCoins
        className={classes.statsPieChartTopCoins}
        coins={data}
        emptyData={isEmptyData}
      />
    </div>
  );
};
