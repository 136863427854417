import { CUSTOMER_MANAGEMENT_REDUCER_NAME } from './reducer';

export const customersSelector = () => (state) =>
  state[CUSTOMER_MANAGEMENT_REDUCER_NAME].customers;

export const customerBalancesSelector = () => (state) =>
  state[CUSTOMER_MANAGEMENT_REDUCER_NAME].balances;

export const customerBalancesFetchingSelector = () => (state) =>
  state[CUSTOMER_MANAGEMENT_REDUCER_NAME].balancesFetching;

export const customerBalancesFetchedSelector = () => (state) =>
  state[CUSTOMER_MANAGEMENT_REDUCER_NAME].balancesFetched;

export const customersFetchingSelector = () => (state) =>
  state[CUSTOMER_MANAGEMENT_REDUCER_NAME].fetching;

export const customersFetchedSelector = () => (state) =>
  state[CUSTOMER_MANAGEMENT_REDUCER_NAME].fetched;

export const customersErrorSelector = () => (state) =>
  state[CUSTOMER_MANAGEMENT_REDUCER_NAME].error;

export const allPlansSelector = () => (state) =>
  state[CUSTOMER_MANAGEMENT_REDUCER_NAME].allPlans.items;

export const allPlansCountSelector = () => (state) =>
  state[CUSTOMER_MANAGEMENT_REDUCER_NAME].allPlans.count;

export const allPlansFetchingSelector = () => (state) =>
  state[CUSTOMER_MANAGEMENT_REDUCER_NAME].allPlans.fetching;

export const allPlansFetchedSelector = () => (state) =>
  state[CUSTOMER_MANAGEMENT_REDUCER_NAME].allPlans.fetched;

export const allPlansErrorSelector = () => (state) =>
  state[CUSTOMER_MANAGEMENT_REDUCER_NAME].allPlans.error;

export const customerSubscriptionsSelector = () => (state) =>
  state[CUSTOMER_MANAGEMENT_REDUCER_NAME].customerSubscriptions;

export const isCustomerSubscriptionsFetchingSelector = () => (state) =>
  state[CUSTOMER_MANAGEMENT_REDUCER_NAME].customerSubscriptionsFetching;

export const isCustomerSubscriptionsFetchedSelector = () => (state) =>
  state[CUSTOMER_MANAGEMENT_REDUCER_NAME].customerSubscriptionsFetched;
