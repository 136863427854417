import React from 'react';
import withModal from '../../../../../components/with-modal';
import ConfirmModal from '../../../../../components/confirm-modal';
import SubmitButton, { buttonState } from '../../../../../components/submit-button';
import apiClient from '../../../../../api/api-client';
import styles from './styles.module.scss';

let timer = 0;

const EditUserModal = ({
  isOpen,
  onClose,
  accessData,
  activeTeamData,
  setTeam,
  onChangeTeamAccessById,
}) => {
  const [error, setError] = React.useState('');
  const [buttonStateInvite, setButtonStateInvite] = React.useState(
    buttonState.normal,
  );
  const [buttonStateDelete, setButtonStateDelete] = React.useState(
    buttonState.normal,
  );
  const [role, setRole] = React.useState('');
  const [access, setAccess] = React.useState({});
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = React.useState(false);

  const handleSave = async () => {
    const data = {
      access: {
        ...access,
      },
      fields: {
        role,
        slave_id: activeTeamData.slave_id,
      },
    };

    try {
      setButtonStateInvite(buttonState.processing);
      await apiClient.editTeam(data);
      onChangeTeamAccessById({ ...activeTeamData, access }, activeTeamData.id);
      onClose();
    } catch (err) {
      setError(err.response.data.error);
      setButtonStateInvite(buttonState.failure);
    }
  };

  const handleDeleteClick = async () => {
    setIsOpenConfirmDelete(true);
  };

  const deleteTeam = async () => {
    try {
      setButtonStateDelete(buttonState.processing);
      await apiClient.deleteTeam(activeTeamData.id);
      setTeam((prevState) =>
        prevState.filter((item) => item.id !== activeTeamData.id),
      );
      onClose();
    } catch (err) {
      setError(err.response.data.error);
      setButtonStateDelete(buttonState.failure);
    }
  };

  const handleChangeAccess = (e, asset) => {
    e.persist();
    setAccess((prevState) => ({
      ...prevState,
      [asset]: e.target.value.toLowerCase(),
    }));
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const handleConfirmClick = async () => {
    setIsOpenConfirmDelete(false);
    await deleteTeam();
  };

  React.useEffect(() => {
    if (buttonStateInvite !== buttonState.normal) {
      timer = setTimeout(() => {
        setError('');
        setButtonStateInvite(buttonState.normal);
      }, 2000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [buttonStateInvite]);

  React.useEffect(() => {
    if (Object.keys(activeTeamData).length) {
      setAccess(activeTeamData.access);
      setRole(activeTeamData.role);
    }
  }, [activeTeamData]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <h4>Edit user access</h4>
      <div className={styles.container}>
        <div className={styles.row}>
          <input
            className={styles.input}
            type="text"
            placeholder="Role"
            value={role}
            onChange={handleRoleChange}
          />
        </div>
        <table className={styles.table}>
          <thead className={styles.thead}>
            <tr>
              <td className={styles.td}>Assets</td>
              <td className={styles.td}>Access</td>
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            {accessData.map((item, index) => (
              <tr key={index}>
                <td className={styles.td}>{item.title}</td>
                <td>
                  <select
                    className={styles.select}
                    type="text"
                    value={access[item.code]}
                    onChange={(e) => handleChangeAccess(e, item.code)}
                  >
                    <option>view</option>
                    <option>change</option>
                    <option>disable</option>
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={styles.rowButtons}>
        <button onClick={onClose}>Cancel</button>
        <SubmitButton
          buttonState={buttonStateDelete}
          onSubmit={handleDeleteClick}
        >
          Delete
        </SubmitButton>
        {error ? (
          <p className={styles.error}>{error}</p>
        ) : (
          <SubmitButton buttonState={buttonStateInvite} onSubmit={handleSave}>
            Save
          </SubmitButton>
        )}
      </div>
      <ConfirmModal
        isOpen={isOpenConfirmDelete}
        onClose={() => setIsOpenConfirmDelete(false)}
        onSubmit={handleConfirmClick}
        className="confirm-modal"
        message="Are you sure that you want to delete this team member?"
      ></ConfirmModal>
    </div>
  );
};

export default withModal(EditUserModal);
