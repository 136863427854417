import React from 'react';
import cn from 'classnames';
import { LoaderIcon } from '../../../icons/loader-icon';
import { Field } from '../../../ui/field';
import { Button } from '../../../ui/button';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import classes from './styles.module.scss';

type SetUpIpnProps = {
  ipnKey: null | string,
  isIpnKeyLoading: boolean,
  ipnCallbackUrl: null | string,
  isIpnCallbackUrlLoading: boolean,
  isIpnCallbackUrlUpdated: boolean,
  onPopupRegenerate: () => void,
  onPopupNewIpnKey: () => void,
  onSaveCallbackUrl: (newCallbackUrl: string) => Promise<void>,
};

export const SetUpIpn: React.FC<SetUpIpnProps> = (props) => {
  const {
    ipnKey,
    isIpnKeyLoading,
    ipnCallbackUrl,
    isIpnCallbackUrlLoading,
    isIpnCallbackUrlUpdated,
    onPopupRegenerate,
    onPopupNewIpnKey,
    onSaveCallbackUrl,
  } = props;

  const [callbackUrl, setCallbackUrl] = React.useState<string | null>(null);
  const [callbackUrlError, setCallbackUrlError] = React.useState<string | null>(null);

  const handleUrlChange = (value: string) => {
    setCallbackUrlError(null);
    setCallbackUrl(value);
  };
  const handleUrlSave = async () => {
    if (!callbackUrl || callbackUrl === ipnCallbackUrl) return;

    try {
      const newUrl = new URL(callbackUrl);
      const isValid = Boolean(newUrl);
      if (isValid) {
        await onSaveCallbackUrl(callbackUrl);
      }
    } catch (error) {
      setCallbackUrlError('Invalid URL address');
    }
  };

  const isDesktop = useMediaQuery('(min-width: 992px)');
  const saveButtonText = isDesktop ? 'Save' : 'Save webhook URL';
  const isLoaderShow = !ipnKey && isIpnKeyLoading;
  const hasNoIpn = !ipnKey && !isIpnKeyLoading;

  return (
    <li className={classes.setUpIpn}>
      <h3>
        1. Set up IPN
      </h3>
      <p className={cn([
        !ipnKey && classes.ipnTitle,
      ])}
      >
        IPN
        {' '}
        <span>(Instant payment notifications, or callbacks)</span>
        {' '}
        are used to notify you
        when transaction status is changed.
      </p>
      {isLoaderShow && (
        <LoaderIcon className={classes.setUpIpnLoader} />
      )}
      {ipnKey && (
        <>
          <div className={classes.ipnKey}>
            <div>
              <p>
                Your IPN secret key
              </p>
              {isIpnKeyLoading ? (
                <LoaderIcon size={21} />
              ) : (
                <p className={classes.ipnKeyData}>
                  {ipnKey}
                </p>
              )}
            </div>
            <button
              className={classes.ipnKeyRegenerate}
              type="button"
              onClick={onPopupRegenerate}
            >
              Regenerate
            </button>
          </div>
          <div className={classes.ipnUrl}>
            <p className={classes.ipnUrlTitle}>Webhook URL</p>
            <div className={classes.ipnUrlFieldContainer}>
              <Field
                className={classes.ipnUrlField}
                data={{
                  value: callbackUrl ?? ipnCallbackUrl,
                  placeholder: 'Enter URL',
                  error: callbackUrlError,
                  onChange: handleUrlChange,
                }}
              />
              <p className={cn(callbackUrlError && classes.ipnUrlTextError)}>
                {callbackUrlError || 'You will receive payment updates (statuses) to this URL address'}
              </p>
              <Button
                className={cn([
                  classes.ipnUrlButton,
                  isIpnCallbackUrlUpdated && classes.ipnUrlButtonSaved,
                ])}
                onClick={handleUrlSave}
              >
                {isIpnCallbackUrlUpdated && !isIpnCallbackUrlLoading && (
                <img
                  src="/images/check.svg"
                  loading="lazy"
                  decoding="async"
                  alt="ok"
                  width={13}
                  height={10}
                />
                )}
                {isIpnCallbackUrlLoading && !isIpnCallbackUrlUpdated && (
                <LoaderIcon
                  path="/images/loader-white-on-blue-icon.gif"
                  size={20}
                />
                )}
                {!isIpnCallbackUrlLoading && !isIpnCallbackUrlUpdated && saveButtonText}
              </Button>
            </div>
          </div>
        </>
      )}
      {hasNoIpn && (
        <>
          <div className={classes.ipnAttention}>
            <img
              className={classes.ipnAttentionImage}
              src="/images/info-blue-icon.svg"
              alt="attention"
              width={20}
              height={20}
              decoding="async"
              loading="lazy"
            />
            <p className={classes.ipnAttentionText}>
              Pay attention: we will only display the IPN secret key
              {' '}
              <b>once</b>
              {' '}
              - when created, for security reasons.
            </p>
          </div>
          <Button
            className={classes.generateFirstIpnButton}
            onClick={onPopupNewIpnKey}
          >
            Generate IPN secret key
          </Button>
        </>
      )}
    </li>
  );
};
