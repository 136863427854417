const TITLE_FOR_TRANSAK_EMAIL = 'Fiat "Transak" activation';
const BODY_FOR_TRANSAK_EMAIL = 'I would like to activate the on-ramp fiat feature with the provider "Transak"';

export const OFF_RAMP_PROVIDERS = [{
  id: 'transfi',
  code: 'transfi',
  name: 'Transfi',
  logoUrl: '/images/logo/transfi.svg',
  paymentSystems: [{
    name: 'sepa',
    logoUrl: '/images/logo/sepa.svg',
  }, {
    name: 'wire-transfers',
    logoUrl: '/images/logo/wire-transfers.svg',
  }],
  info: {
    firstRow: '14+ fiat currencies supported',
    tooltipText: 'IDR, PHP, MYR, VND, JPY, THB, INR, EGP, EUR, USD, GBP, DKK, AUD, NZD',
  },
  verification: {
    firstRow: 'Fees: <span>1.5% max</span>',
    secondRow: 'KYB & company documents <span>required</span>',
  },
  activationLink: 'https://3b5l3mcv14x.typeform.com/to/pODlM8oB',
  target: '_blank',
  shouldAppendEmailLink: false,
  isAvailableSoon: false,
  isPermissionRequired: true,
  isActivated: false,
  highlightedInfo: null,
}, {
  id: 'guardarian-off-ramp',
  code: 'guardarian',
  name: 'Guardarian',
  logoUrl: '/images/logo/guardarian.svg',
  paymentSystems: [{
    name: 'sepa',
    logoUrl: '/images/logo/sepa.svg',
  }],
  info: {
    firstRow: 'EUR, ',
    secondRow: '29+ cryptocurrencies supported',
    secondRowTooltipText: `BTC, XMR, ETH, ETH (ZkSync), DOGE, ADA, USDT (Matic), USDT (BSC), USDT (TRX), USDT (BTC), USDT (ETH), 
    LINK (ETH), XRP, USDC (ETH), USDC (Matic), SHIB (ETH), XLM, BCH, BNB (BSC), BNB, ATOM, ZIL, CHZ (ETH), DOT, UNI (ETH), 
    ENJ (ETH), TRX, MATIC, GRT (ETH)`,
  },
  verification: {
    firstRow: 'Fees: <span>0.8% + 25 EUR</span>',
    secondRow: 'Limits: 250 000 USD per transaction',
  },
  activationLink: 'https://3b5l3mcv14x.typeform.com/to/TBdrE3hk#source=NP&questionary_type=B2B&external_id=',
  target: '_blank',
  shouldAppendEmailLink: true,
  isAvailableSoon: false,
  isPermissionRequired: true,
  isActivated: false,
  highlightedInfo: null,
}];

export const ON_RAMP_PROVIDERS = [{
  id: 'guardarian',
  code: 'guardarian',
  name: 'Guardarian',
  logoUrl: '/images/logo/guardarian.svg',
  paymentSystems: [{
    name: 'visa',
    logoUrl: '/images/logo/visa.svg',
  }, {
    name: 'master-card',
    logoUrl: '/images/logo/master-card.svg',
  }, {
    name: 'google-pay',
    logoUrl: '/images/logo/google-pay.svg',
  }, {
    name: 'apple-pay',
    logoUrl: '/images/logo/apple-pay.svg',
  }, {
    name: 'sepa',
    logoUrl: '/images/logo/sepa.svg',
  }],
  info: {
    firstRow: '140+ countries ',
    secondRow: '(except the US, the UK & Canada)',
    isSecondRowGrey: true,
  },
  verification: {
    firstRow: 'KYC <span>required</span>',
    secondRow: 'Light KYB <span>required</span>',
  },
  activationLink: 'https://3b5l3mcv14x.typeform.com/to/TBdrE3hk#&source=NP&questionary_type=AFFILIATE&external_id=',
  target: '_blank',
  shouldAppendEmailLink: true,
  isAvailableSoon: false,
  isPermissionRequired: false,
  isActivated: false,
  highlightedInfo: null,
}, {
  id: 'simplex',
  code: 'simplex',
  name: 'Simplex',
  logoUrl: '/images/logo/simplex.svg',
  paymentSystems: [{
    name: 'visa',
    logoUrl: '/images/logo/visa.svg',
  }, {
    name: 'master-card',
    logoUrl: '/images/logo/master-card.svg',
  }, {
    name: 'apple-pay',
    logoUrl: '/images/logo/apple-pay.svg',
  }, {
    name: 'sepa',
    logoUrl: '/images/logo/sepa.svg',
  }, {
    name: 'pay-4-fun',
    logoUrl: '/images/logo/pay-4-fun.svg',
  }],
  info: {
    firstRow: '150+ countries ',
    secondRow: '(including some states of US)',
    isSecondRowGrey: true,
  },
  verification: {
    firstRow: 'KYC <span>required</span>',
    secondRow: 'KYB <span>required</span>',
  },
  activationLink: 'https://3b5l3mcv14x.typeform.com/to/xyn75dJp',
  target: '_blank',
  shouldAppendEmailLink: false,
  isAvailableSoon: false,
  isPermissionRequired: false,
  isActivated: false,
  highlightedInfo: null,
}, {
  id: 'transak',
  code: 'transak',
  name: 'Transak',
  logoUrl: '/images/logo/transak.svg',
  paymentSystems: [{
    name: 'visa',
    logoUrl: '/images/logo/visa.svg',
  }, {
    name: 'master-card',
    logoUrl: '/images/logo/master-card.svg',
  }, {
    name: 'apple-pay',
    logoUrl: '/images/logo/apple-pay.svg',
  }, {
    name: 'sepa',
    logoUrl: '/images/logo/sepa.svg',
  }, {
    name: '16',
    logoUrl: '/images/logo/16-payment-systems.svg',
  }],
  info: {
    firstRow: '185+ countries ',
    secondRow: '(including the US)',
    isSecondRowGrey: true,
  },
  verification: {
    firstRow: 'KYC <span>required</span>',
    secondRow: 'KYB <span>required</span>',
  },
  activationLink: `mailto:partners@nowpayments.io?subject=${TITLE_FOR_TRANSAK_EMAIL}&body=${BODY_FOR_TRANSAK_EMAIL}`,
  target: '_self',
  shouldAppendEmailLink: false,
  isAvailableSoon: false,
  isPermissionRequired: false,
  isActivated: false,
  highlightedInfo: null,
}];

export const PERMISSION_TO_PROCESS_DATA = {
  title: 'Permission to process data',
  text: 'By clicking "Proceed", I consent that my payment data may be shared with third party service solely for proper service functioning purposes.',
};
