import { handleActions } from 'redux-actions';
import apiClient from '../../../api/api-client';

export const storeKey = '@redux/orders';

const SET_ORDERS = '@orders/SET_ORDERS';
const ADD_ORDERS = '@orders/ADD_ORDERS';
const UPDATE_ORDER_STATUS = '@orders/UPDATE_ORDER_STATUS';

const initialState = {
  orders: [],
};

export const reducer = handleActions(
  {
    [SET_ORDERS]: (state, { orders }) => ({
      ...state,
      orders,
    }),
    [ADD_ORDERS]: (state, { order }) => ({
      ...state,
      orders: [].concat(state.orders, order),
    }),
    [UPDATE_ORDER_STATUS]: (state, { order }) => {

      return {
        ...state,
        orders:state.orders.map((or)=>{
          if (or.external_id === order.partner_order_id ) {
            return {
              ...or,
              status:order.status,
            };
          }

          return or;
        }),
      };
    },
  },
  initialState,
);

export const getOrders = (store) => store[storeKey].orders;

export const addOrder = (order) => async (dispatch) => dispatch({ type: ADD_ORDERS, order });

export const fetchOrders = () => async (dispatch) => {
  const orders = await apiClient.switchereGetTransactions();

  dispatch({ type: SET_ORDERS, orders });
};

export const updateSwitchereOrderStatus = (order) => async (dispatch) => {
  dispatch({ type: UPDATE_ORDER_STATUS, order });
};
