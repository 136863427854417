import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSafeErrorMessageText } from '../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../helpers/console-error-message';
import {
  setCustomerSubscriptions,
  setCustomerSubscriptionsFetched,
  setCustomerSubscriptionsFetching,
} from '../store/customer-management/reducer';
import { useEmailSubscriptions } from '../api/modules/account/use-email-subscriptions';
import {
  isCustomerSubscriptionsFetchedSelector,
  isCustomerSubscriptionsFetchingSelector,
} from '../store/customer-management/selectors';

export const useCustomerSubscriptions = (subPartnerId) => {
  const dispatch = useDispatch();
  const fetchSubscriptions = useEmailSubscriptions();
  const isCustomerSubscriptionsFetching = useSelector(isCustomerSubscriptionsFetchingSelector());
  const isCustomerSubscriptionsFetched = useSelector(isCustomerSubscriptionsFetchedSelector());

  const reFetch = React.useCallback(async () => {
    dispatch(setCustomerSubscriptionsFetching(true));
    const { data, status, errorMessage } = await fetchSubscriptions({ subPartnerId });

    if (status === 200) {
      dispatch(setCustomerSubscriptions({ id: subPartnerId, data: data?.result }));
      dispatch(setCustomerSubscriptionsFetching(false));
      dispatch(setCustomerSubscriptionsFetched(true));
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/subscriptions');
      dispatch(setCustomerSubscriptionsFetching(false));
    }
  }, [subPartnerId]);

  return {
    reFetchCustomerSubscriptions: reFetch,
    isCustomerSubscriptionsFetching,
    isCustomerSubscriptionsFetched,
  };
};
