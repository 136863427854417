import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { LoaderIcon } from '../icons/loader-icon';
import { Button } from '../ui/button';
import { STATUSES } from '../../constants/app-constants';
import classes from './styles.module.scss';

export const PaymentsInfoButtons = (props) => {
  const {
    className,
    status,
    changeStatusFetching,
    onChangeStatusToFinished,
    onIPN,
    ipnFetching,
    ipnSent,
    onClose,
  } = props;

  const buttonChangeStatusShow = status === STATUSES.PARTIALLY_PAID && onChangeStatusToFinished;

  return (
    <div className={cn(classes.buttons, className)}>
      {buttonChangeStatusShow && (
        <Button
          onClick={onChangeStatusToFinished}
          className={classes.button}
          disabled={changeStatusFetching}
        >
          {changeStatusFetching && (
            <LoaderIcon
              size={26}
              path="/images/loader-white-on-blue-icon.gif"
            />
          )}
          {!changeStatusFetching && 'Change status to finished'}
        </Button>
      )}
      {onIPN && (
        <Button
          onClick={onIPN}
          className={classes.button}
          disabled={ipnFetching}
        >
          {ipnFetching && (
            <LoaderIcon
              size={26}
              path="/images/loader-white-on-blue-icon.gif"
            />
          )}
          {!ipnFetching && !ipnSent && 'Send IPN notification'}
          {!ipnFetching && ipnSent && 'Sent'}
        </Button>
      )}
      <Button
        onClick={onClose}
        className={classes.button}
      >
        OK
      </Button>
    </div>
  );
};

PaymentsInfoButtons.defaultProps = {
  className: null,
  status: null,
  changeStatusFetching: null,
  onChangeStatusToFinished: null,
  onIPN: null,
  ipnFetching: false,
  ipnSent: false,
  onClose: null,
};

PaymentsInfoButtons.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string,
  changeStatusFetching: PropTypes.bool,
  onChangeStatusToFinished: PropTypes.func,
  onIPN: PropTypes.func,
  ipnFetching: PropTypes.bool,
  ipnSent: PropTypes.bool,
  onClose: PropTypes.func,
};
