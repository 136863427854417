import React from 'react';
import { Section } from '../../../section';
import { Button } from '../../../ui/button';
import { AccountList } from '../account-list';
import { LoaderIcon } from '../../../icons/loader-icon';
import { BankAccount } from '../../../../types';
import classes from './styles.module.scss';

type AddAccountSectionProps = {
  onClick: () => void;
  accounts: Array<BankAccount>;
  isLoading: boolean;
};

export const AddAccountSection: React.FC<AddAccountSectionProps> = (props) => {
  const {
    onClick,
    accounts,
    isLoading,
  } = props;

  return (
    <Section
      title="Payout bank accounts"
      className={classes.addAccountSection}
    >
      {isLoading && (
        <LoaderIcon />
      )}
      {(!isLoading && accounts?.length > 0) && (
        <AccountList
          accounts={accounts}
        />
      )}
      {(!isLoading && accounts?.length === 0) && (
        <p className={classes.addAccountSectionDescription}>
          Provide your billing information to enable fund withdrawals from your Custody
          balance in fiat currencies.
        </p>
      )}
      <Button
        className={classes.addAccountSectionButton}
        onClick={onClick}
      >
        Add account
      </Button>
    </Section>
  );
};
