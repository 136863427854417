import classNames from "classnames";
import React from "react";
import styles from "./styles.module.scss";

const Switch = ({ onChange, isChecked, variant, readOnly = false }) => {
  const handleChangeSwitch = () => {
    onChange(!isChecked);
  };

  return (
    <label 
      className={ 
        classNames({
          [styles.switch]: isChecked,
          [styles.switchBlue]: variant === 'blue',
          [styles.switchDisable]: !isChecked,
        }) 
      } 
    >
      {!readOnly ? (
        <input
          type="checkbox"
          onChange={handleChangeSwitch}
          checked={isChecked}
        />
      ) : (
        <input type="checkbox" checked={isChecked} readOnly={readOnly} />
      )}
      <div className={styles.marker}/>
    </label>
  );
};

export default Switch;
