import React from 'react';
import { useBanks } from '../../../api/modules/account/use-banks';
import { getSafeErrorMessageText } from '../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../helpers/console-error-message';
import { Bank } from '../types';

export const useBankList = (selectedCurrencyCode: string | null) => {
  const fetchBanksRequestSend = useBanks();
  const [banks, setBanks] = React.useState<Array<Bank>>([]);
  const [isBanksLoading, setIsBanksLoading] = React.useState(false);
  const [noMatchesBankText, setNoMatchesBankText] = React.useState<string | null>('Please select a fiat currency first');

  const fetchBanks = React.useCallback(async (currency: string | null, provider = 'transfi') => {
    if (currency) {
      setIsBanksLoading(true);
      const {
        data,
        status,
        errorMessage,
      }: { data: any, status: number, errorMessage: string } = await fetchBanksRequestSend({
        currency,
        provider,
      });

      if (status === 200 && data) {
        setBanks(data?.result ?? []);
      } else {
        const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
        consoleErrorMessage(errorMessage, errorDataMessage, '/fiat-payouts/payment-methods');
      }

      setIsBanksLoading(false);
      setNoMatchesBankText(null);
    }
  }, [fetchBanksRequestSend]);

  React.useEffect(() => {
    fetchBanks(selectedCurrencyCode);
  }, [fetchBanks, selectedCurrencyCode]);

  return { banks, noMatchesBankText, isBanksLoading };
};
