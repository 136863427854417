import axios from 'axios';
import { api_domain, X_TEMP_TOKEN } from '../config';
import {
  clearAuthToken,
  getAuthToken,
  getMasterId,
} from '../libs/local-storage';
import { clearAuthCookieToken } from '../libs/cookies';
import { getSafeErrorMessageText } from '../helpers/get-safe-error-message-text';

const token = getAuthToken();
const masterId = getMasterId();

const apiClient = axios.create({
  baseURL: api_domain,
  headers: {
    Authorization: 'bearer ' + token,
    'master-id': masterId,
    'X-Temp-Token': X_TEMP_TOKEN,
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const token = getAuthToken();

    config.headers = {
      'Authorization': `Bearer ${token}`,
      'master-id': masterId,
      'X-Temp-Token': X_TEMP_TOKEN,
    };

    return config;
  },
  (error) => {
    throw error;
  },
);

apiClient.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response && err.response.data.code === 'INVALID_TOKEN') {
      clearAuthToken();
      clearAuthCookieToken();
      document.location.reload();
    }

    throw err;
  },
);

apiClient.getPurchases = async function (
  params,
  onlyWithPartialPayments = true,
) {
  try {
    if (!params) params = {};

    if (onlyWithPartialPayments) {
      params.only_partial = true;
    }

    const result = await apiClient.get('/purchases', {
      params: params,
    });

    return result.data;
  } catch (e) {
    return [];
  }
};

apiClient.getInvoices = async function (params) {
  try {
    if (!params) params = {};
    const result = await apiClient.get('/invoices', {
      params: params,
    });

    return result.data;
  } catch (e) {
    return [];
  }
};

apiClient.getMinAmount = async function (from, to) {
  try {
    const result = await apiClient.get(
      `/min-amount?from=${from}&to=${to.toLowerCase()}`,
    );

    return result.data;
  } catch (e) {
    return { error: e?.response?.data?.message };
  }
};

apiClient.createInvoice = async function (
  priceAmount,
  priceCurrency,
  payCurrency,
  orderId,
  orderDescription,
  iid = null,
  callbackUrl = null,
  successUrl = null,
  cancelUrl = null,
) {
  try {
    const body = {
      price_amount: priceAmount,
      price_currency: priceCurrency,
      pay_currency: payCurrency,
      order_id: orderId,
      order_description: orderDescription,
      iid: iid,
      ipn_callback_url: callbackUrl,
      success_url: successUrl,
      cancel_url: cancelUrl,
    };

    const result = await apiClient.post('/invoices', body);

    return result.data;
  } catch (e) {
    return {
      error: e?.response?.data?.error || e?.response?.data?.message,
    };
  }
};

apiClient.getPaymentById = async function (id) {
  try {
    const result = await apiClient.get(`/payment/${id}`);

    return result.data;
  } catch (e) {
    return {};
  }
};

apiClient.getAvailableCurrencies = async function () {
  try {
    const result = await apiClient.get('/available-currencies');

    return result.data;
  } catch (e) {
    return [];
  }
};

apiClient.getBaseCurrencies = async function () {
  try {
    const result = await apiClient.get('/base-currency');

    return result.data;
  } catch (e) {
    return {};
  }
};

apiClient.getApiKeys = async function (params) {
  try {
    if (!params) params = {};

    const result = await apiClient.get('/api-keys', {
      params: params,
    });

    return result.data;
  } catch (e) {
    return {};
  }
};

apiClient.getProfile = async function () {
  try {
    const result = await apiClient.get('/profile');

    return result.data;
  } catch (e) {
    return {};
  }
};

apiClient.getSteps = async function () {
  const { data } = await apiClient.get('/profile/fiat-withdrawals-steps');

  return data;
};

apiClient.loadAccountBalance = async function () {
  try {
    const result = await apiClient.get('/balance');

    return { data: result.data };
  } catch (e) {
    return { data: null, error: e.response.data.error };
  }
};

apiClient.fiatRequestPayout = async function (
  sendAmount,
  sendCurrency,
  getAmount,
  getCurrency,
) {
  try {
    const result = await apiClient.post('/fiat-withdrawals/request-payout', {
      sendAmount,
      sendCurrency,
      getAmount,
      getCurrency,
    });

    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

apiClient.postRequestPayout = async function (currency, address, password) {
  try {
    const result = await apiClient.post('/request-payout', {
      currency,
      address,
      password,
    });

    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

apiClient.resendActivationLink = async (email) => {
  const result = await apiClient.post('/resend-link', { email });

  return result.data;
};

apiClient.verifyLoginCode = async (verificationCode) => {
  const result = await apiClient.post('/login/verify-code', {
    verification_code: verificationCode,
  });

  return result.data;
};

apiClient.sendLoginCode = async () => {
  return await apiClient.post('/login/send-code');
};

apiClient.resetPassword = async (email, captchaToken) => {
  const result = await apiClient.post('/request-reset-password', {
    email,
    captchaToken,
  });

  return result.data;
};

apiClient.getStoreSettings = async () => {
  try {
    const result = await apiClient.get('/store-outcome-wallets');

    return result.data;
  } catch (e) {
    return {};
  }
};

apiClient.getOutcomeWalletAddressByCurrency = async (currency) => {
  const result = await apiClient.get(`/outcome-wallets/${currency}`);

  return result.data;
};

apiClient.finishPayment = async (id) => {
  try {
    const result = await apiClient.put(`/payment/finish/${id}`);

    return {
      data: result?.data ?? {},
      status: result?.status ?? 0,
      errorMessage: null,
    };
  } catch (error) {
    const errorMessage = getSafeErrorMessageText(error?.response?.data?.message);

    return {
      data: null,
      status: error?.response?.data?.statusCode ?? 0,
      errorMessage: errorMessage,
    };
  }
};

apiClient.useRepeatedIPN = async (payment_id) => {
  try {
    const result = await apiClient.post('/send-repeated-ipn', { payment_id });

    return {
      data: result.data,
      status: result?.status ?? 0,
      errorMessage: null,
    };
  } catch (error) {
    const errorMessage = getSafeErrorMessageText(error?.response?.data?.message);

    return {
      data: null,
      status: error?.response?.data?.statusCode ?? 0,
      errorMessage: errorMessage,
    };
  }
};

apiClient.logout = async () => {
  await apiClient.post('/logout');
};

apiClient.getStatistics = async () => {
  return apiClient.get('/referral-program/statistic');
};

apiClient.getPaymentsStatistics = async () => {
  return apiClient.get('/payments-statistic');
};

apiClient.getPayments = async (params) => {
  return apiClient.get('/payments', { params });
};

apiClient.getWithdrawalPayments = async (params) => {
  const { data } = await apiClient.get('/withdrawal-payments', { params });

  return data;
};

apiClient.verify = async () => {
  return await apiClient.get('/verify');
};

apiClient.loadReferralLink = async () => {
  return apiClient.get('/referral-program/link');
};

apiClient.saveProfile = async (data) => {
  try {
    const result = await apiClient.post('/profile', data);

    return {
      data: result?.data ?? {},
      status: result?.status ?? 0,
      errorMessage: null,
    };
  } catch (error) {
    const errorMessage = getSafeErrorMessageText(error?.response?.data?.error);

    return {
      data: null,
      status: error?.response?.data?.statusCode ?? 0,
      errorMessage: errorMessage,
    };
  }
};

apiClient.createApiKey = async () => {
  return apiClient.post('/api-keys/create');
};

apiClient.saveWallets = async (data) => {
  return apiClient.put('/store-outcome-wallets', data);
};

apiClient.changeBaseCurrency = async (currency) => {
  return apiClient.post('/base-currency', { base_currency: currency });
};

apiClient.uploadFileFiatWithdrawals = async (file, fileName, stepNumber) => {
  return apiClient.post(
    `/fiat-withdrawals/file/${fileName}/${stepNumber}`,
    file,
    {
      headers: {
        'Content-Type':
          'multipart/form-data; boundary=<calculated when request is sent>',
      },
    },
  );
};

apiClient.getShopifyInfo = async function () {
  try {
    const { data } = await apiClient.get('/integrations');

    return data;
  } catch (e) {
    return { status: false, data: null };
  }
};

apiClient.saveShopifyInfo = async ({
  shopifyApiKey,
  shopifyAdminApiAccessToken,
  domain,
  shopName,
  apiKey,
}) => {
  try {
    return await apiClient.post('/integrations', {
      shop_name: shopName,
      domain,
      api_key: shopifyApiKey,
      password: shopifyAdminApiAccessToken,
      token_id: apiKey,
    });
  } catch (e) {
    return e.response;
  }
};

apiClient.getEstimatedCurrencies = async () => {
  try {
    const { data } = await apiClient.get('/estimated-currencies');

    return data;
  } catch (e) {
    return e.response;
  }
};

apiClient.getMerchantEstimate = async (fromCurrency, toCurrency, amount) => {
  try {
    const { data } = await apiClient.get('/merchant-estimate', {
      params: {
        fromCurrency,
        toCurrency,
        amount,
      },
    });

    return data;
  } catch (e) {
    return e.response;
  }
};

apiClient.getCoins = async () => {
  try {
    const { data: selectedCurrencies } = await apiClient.get('/coins');

    return selectedCurrencies;
  } catch (e) {
    return e.response;
  }
};

apiClient.updateCoins = async (currencies) => {
  try {
    const { data } = await apiClient.post('/coins', {
      currencies,
    });

    return data;
  } catch (e) {
    return e.response;
  }
};

apiClient.deleteCoins = async (currencies) => {
  try {
    const { data } = await apiClient.post('/coins-delete', {
      currencies,
      all: false,
    });

    return data;
  } catch (e) {
    return e.response;
  }
};

apiClient.updateShowOnboard = async (data) => {
  return apiClient.put('/profile/update-show-onboard', data);
};

apiClient.createSubscriptionGroup = async (invoiceId, intervalDays) => {
  return await apiClient.post('/subscription-groups', {
    invoiceId,
    intervalDays,
  });
};

apiClient.deleteSubscriptionGroup = async (subsId) => {
  return await apiClient.delete(`/subscription-groups/${subsId}`);
};

apiClient.getSubscriptionGroups = async () => {
  return await apiClient.get('/subscription-groups');
};

apiClient.addEmailInSubscriptionGroup = async (subsId, emails, subject) => {
  return await apiClient.post('/subscription-email', {
    subsId,
    emails,
    subject,
  });
};

apiClient.deleteEmailInSubscriptionGroup = async (subsId, email) => {
  return await apiClient.delete(`/subscription-email/${subsId}/${email}`);
};

apiClient.getSubscriptionEmailsInGroup = async (subsId) => {
  return await apiClient.get(`/subscription-email/${subsId}`);
};

apiClient.getDonationLink = async () => {
  const { data } = await apiClient.get('/donation-link');

  return data;
};

apiClient.applyDonationLink = async (link, apiKey, settings) => {
  const { data } = await apiClient.post('/donation-link', {
    link,
    apiKey,
    settings,
  });

  return data;
};

apiClient.getPosTerminalLink = async () => {
  const { data } = await apiClient.get('/pos-terminal-link');

  return data;
};

apiClient.applyPosTerminalLink = async (link, apiKey, theme) => {
  const { data } = await apiClient.post('/pos-terminal-link', {
    link,
    apiKey,
    theme,
  });

  return data;
};

apiClient.getTeam = async (tokenKey) => {
  const { data } = await apiClient.get('/team', {
    headers: {
      Authorization: `Bearer ${tokenKey || token}`,
    },
  });

  return data;
};

apiClient.getTeamForSignIn = async (tokenKey) => {

  const { data } = await apiClient.get('/team-signin-list', {
    headers: {
      Authorization: `Bearer ${tokenKey || token}`,
    },
  });

  return data;
};

export const getTeamForSignIn = async (tokenKey) => {

  const { data } = await axios.get(`${api_domain}/team-signin-list`, {
    headers: {
      Authorization: `Bearer ${tokenKey}`,
      'X-Temp-Token': X_TEMP_TOKEN,
    },
  });

  return data;
};

apiClient.inviteTeam = async (params) => {
  const { data } = await apiClient.post('/team-invite', params);

  return data;
};

apiClient.editTeam = async (params) => {
  const { data } = await apiClient.put('/team-invite', params);

  return data;
};

apiClient.deleteTeam = async (id) => {
  const { data } = await apiClient.delete(`/team-invite/${id}`);

  return data;
};

apiClient.checkPartnerPassword = async (password) => {
  const { data } = await apiClient.post('/profile/check-password', {
    password,
  });

  return data.status;
};

apiClient.createWithdrawals = async (withdrawals, password) => {
  const { data } = await apiClient.post('/withdrawal-payments', {
    withdrawals,
    password,
  });

  return data;
};

apiClient.sendTwoFactorCode = async () => {
  const { data } = await apiClient.post('/two-factor/send-code');

  return data;
};

apiClient.verifyTwoFactorCode = async (captchaToken, verification_code) => {
  const { data } = await apiClient.post('/two-factor/verify-code', {
    captchaToken,
    verification_code,
  });

  return data.status;
};

apiClient.getTfaTokenType = async () => {
  const { data } = await apiClient.get('/two-factor/type');

  return data.type;
};

apiClient.getDonationPayments = async () => {
  const { data } = await apiClient.get('/donation-payments');

  return data;
};

apiClient.getAllServices = async () => {
  const { data } = await apiClient.get(`/apps-services`);

  return data.data;
};

apiClient.getAllPermissions = async () => {
  try {
    const { data } = await apiClient.get(`/apps-services-permissions`);

    return data.data;
  } catch (e) {
    console.log(e.message);

    return {};
  }
};

apiClient.createService = async ({
  name,
  description,
  secret_key,
  permissions,
  redirect_url,
}) => {
  const { data } = await apiClient.post(`/apps-services`, {
    name,
    description,
    secret_key,
    permissions,
    redirect_url,
  });

  return data.data;
};

apiClient.deleteService = async (id) => {
  await apiClient.delete(`/apps-services/${id}`);
};

apiClient.addPartnerToService = async (serviceId, creatorId) => {
  try {
    const { data } = await apiClient.post('/partner-to-apps-service', {
      serviceId,
      creatorId,
    });

    return data;
  } catch (err) {
    return {};
  }
};

apiClient.getBurningPercents = async () => {
  const { data } = await apiClient.get('/payment-settings/burning');

  return data;
};

apiClient.putBurningPercents = async (params) => {
  await apiClient.put('/payment-settings/burning', {
    params,
  });
};

apiClient.getBurningPercentsStats = async () => {
  const { data } = await apiClient.get('/payment-settings/burning/stats');

  return data;
};

apiClient.switcherLogin = async (account) => {
  const { data } = await apiClient.post('/switchere/login', account);

  return data;
};
apiClient.checkSwitchereAccount = async () => {
  const { data } = await apiClient.get('/switchere/check');

  return data;
};
apiClient.switchereOrderFee = async (value) => {
  const { data } = await apiClient.post('/switchere/order/fee', value);

  return data;
};

apiClient.switchereCreateOrder = async (value) => {
  const { data } = await apiClient.post(
    '/switchere/partner/order/balance',
    value,
  );

  return data;
};

apiClient.switchereGetPayments = async () => {
  const { data } = await apiClient.get('/switchere/partner/payment');

  return data;
};

apiClient.switchereGetTransactions = async () => {
  const { data } = await apiClient.get('/switchere/transactions');

  return data;
};
apiClient.switcherLoginPut = async (account) => {
  const { data } = await apiClient.put('/switchere/login', account);

  return data;
};
apiClient.switchereGetOrder = async (order_id) => {
  const { data } = await apiClient.get('/switchere/partner/order/' + order_id);

  return data;
};

apiClient.switchereCreatePayment = async (value) => {
  const { data } = await apiClient.post(
    '/switchere/partner/order/payment',
    value,
  );

  return data;
};

apiClient.switchereCountries = async () => {
  const { data } = await apiClient.get('/switchere/country/list', {
    params: {
      forbidden: 0,
      forbidden_card: 0,
    },
  });

  return data;
};

/* apiClient.getFeeOptimizationSettings = async () => {
  return apiClient.get('/store/fee-optimization');
};

apiClient.updateFeeOptimizationSettings = async (fee_optimization) => {
  return apiClient.put('/store/fee-optimization', { fee_optimization });
}; */

export default apiClient;
