import React from 'react';
import cn from 'classnames';
import { Popup } from '../popup';
import { Button } from '../ui/button';
import { isFunction } from '../../helpers/is-function';
import { PermissionPopupProps } from './types';
import { LoaderIcon } from '../icons/loader-icon';
import classes from './styles.module.scss';

export const PermissionPopup: React.FC<PermissionPopupProps> = (props) => {
  const {
    className,
    open,
    onClose,
    activationLink,
    onConfirm,
    title,
    description,
    buttonLabel,
    pending,
    mobileFullView,
  } = props;

  return (
    <Popup
      classNameContent={cn(classes.permission, className)}
      open={open}
      onClose={onClose}
      withCloseButton
      title={title}
      line
      mobileFullView={mobileFullView}
    >
      <p className={classes.permissionDescription}>
        {description}
      </p>
      {activationLink && !isFunction(onConfirm) && (
        <a
          className={classes.permissionProceed}
          onClick={onClose}
          href={activationLink}
          target="_blank"
          rel="nofollow noreferrer noopener"
        >
          {buttonLabel}
        </a>
      )}
      {!activationLink && isFunction(onConfirm) && (
        <Button
          className={classes.permissionProceed}
          onClick={onConfirm}
          disabled={pending}
        >
          {pending ? (
            <LoaderIcon path="/images/loader-white-on-blue-icon.gif" />
          ) : buttonLabel}
        </Button>
      )}
      <Button
        className={classes.permissionCancel}
        onClick={onClose}
        displayBorder
      >
        Cancel
      </Button>
    </Popup>
  );
};
