import { BankAccount, FiatOffRampCurrency } from '../../../types';

export const getFilteredCurrenciesByAddedAccounts = (
  currencies: Array<FiatOffRampCurrency>,
  accounts: Array<BankAccount>,
) => {
  if (accounts.length === 0) return currencies;

  return currencies
    .filter((el: FiatOffRampCurrency) => !accounts.some(
      (account) => account.fiatCurrencyCode === el.currencyCode,
    ));
};
