import React, { useRef } from 'react';
import cn from 'classnames';
import apiClient from '../../api/api-client';
import commonStyles from '../../css/common.module.scss';
import { front_domain } from '../../config';
import { CodePreview } from './code-preview';
import { ButtonPreview } from './button-preview';
import { SelectApiKeys } from './select-api-keys';
import styles from './styles.module.scss';

export const DonationPreviewBtn = () => {
  const [apiKeys, setApiKeys] = React.useState([]);
  const [activeKey, setActiveKey] = React.useState('');
  const [isWhiteTheme, setIsWhiteTheme] = React.useState(true);
  const [isCopied, setIsCopied] = React.useState(false);
  const timer = useRef(null);

  const handleSelectChange = (e) => {
    setActiveKey(e.target.value);
  };

  const handleCopyTo = () => {
    const color = isWhiteTheme ? 'white' : 'black';
    const text = `
    <a href="${front_domain}/donation?api_key=${activeKey}&source=lk_donation&medium=referral" target="_blank">
     <img src="${front_domain}/images/embeds/donation-button-${color}.svg" alt="Crypto donation button by NOWPayments">
    </a>
    `;

    navigator.clipboard.writeText(text);
    setIsCopied(true);
    timer.current = setTimeout(() => setIsCopied(false), 300);
  };

  const fetch = async () => {
    try {
      const { keys } = await apiClient.getApiKeys();

      if (keys.length) {
        setApiKeys(keys);
        setActiveKey(keys[0].api_key);
      }
    } catch (err) {
      // ignore
    }
  };

  React.useEffect(() => {
    fetch();

    return () => clearTimeout(timer.current);
  }, []);

  return (
    <div className={`${styles.wrapper} ${commonStyles.panel}`}>
      <h3>Choose the donation button style</h3>
      <div className={styles.group}>
        <div className={styles.groupBtns}>
          <button
            className={cn(styles.btn, styles.btnBlack, {
              [styles.btnBlack__active]: !isWhiteTheme,
            })}
            data-tab-label="black"
            onClick={() => setIsWhiteTheme(false)}
          >
            Black
          </button>
          <button
            className={cn(styles.btn, styles.btnWhite, {
              [styles.btnWhite__active]: isWhiteTheme,
            })}
            data-tab-label="white"
            onClick={() => setIsWhiteTheme(true)}
          >
            White
          </button>
        </div>
        <ButtonPreview isWhiteTheme={isWhiteTheme} />
      </div>
      <h3>Choose your api key</h3>
      <SelectApiKeys apiKeys={apiKeys} handleSelectChange={handleSelectChange} />
      <CodePreview isWhiteTheme={isWhiteTheme} apiKey={activeKey} />
      <button className={cn(styles.btn, styles.btnWhite)} onClick={handleCopyTo}>
        {!isCopied ? 'Copy to clipboard' : 'Copied to clipboard'}
      </button>
    </div>
  );
};
