import React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';

const mapFn = (data) => ({
  estimatedAmount: data?.estimated_amount ?? null,
  estimatedCurrency: data?.estimated_сurrency ?? null,
});

export const useTopUpEstimate = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (options) => {
    const from_currency = options?.fromTicker ?? null;
    const to_currency = options?.toTicker ?? null;
    const base_currency = options?.baseTicker ?? null;
    const base_amount = options?.amount ?? null;
    const [cancelToken] = getRequestSource();
    const requestPromise = client.get(
      '/top-up-estimate',
      {
        params: {
          from_currency,
          to_currency,
          base_currency,
          base_amount,
        },
      },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
