import React from 'react';
import PropTypes from 'prop-types';

export const MenuFilterIcon = (props) => {
  const { color } = props;

  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.47335 12.428H0.712337C0.333817 12.428 0.0266113 12.1208 0.0266113 11.7422C0.0266113 11.3637 0.333817 11.0565 0.712337 11.0565H6.47335C6.85187 11.0565 7.15908 11.3637 7.15908 11.7422C7.15908 12.1208 6.85187 12.428 6.47335 12.428Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8032 3.56622H9.04315C8.66463 3.56622 8.35742 3.25901 8.35742 2.88049C8.35742 2.50197 8.66463 2.19476 9.04315 2.19476H14.8032C15.1818 2.19476 15.489 2.50197 15.489 2.88049C15.489 3.25901 15.1818 3.56622 14.8032 3.56622Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.84599 1.37152C2.03318 1.37152 1.37122 2.02616 1.37122 2.83166C1.37122 3.63624 2.03318 4.28997 2.84599 4.28997C3.65972 4.28997 4.32076 3.63624 4.32076 2.83166C4.32076 2.02616 3.65972 1.37152 2.84599 1.37152ZM2.84598 5.66139C1.27703 5.66139 -0.000244141 4.39233 -0.000244141 2.83162C-0.000244141 1.27091 1.27703 3.05176e-05 2.84598 3.05176e-05C4.41583 3.05176e-05 5.6922 1.27091 5.6922 2.83162C5.6922 4.39233 4.41583 5.66139 2.84598 5.66139Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1547 10.2475C12.341 10.2475 11.6791 10.9021 11.6791 11.7067C11.6791 12.5122 12.341 13.166 13.1547 13.166C13.9676 13.166 14.6286 12.5122 14.6286 11.7067C14.6286 10.9021 13.9676 10.2475 13.1547 10.2475ZM13.1548 14.5374C11.5849 14.5374 10.3076 13.2675 10.3076 11.7067C10.3076 10.146 11.5849 8.87607 13.1548 8.87607C14.7237 8.87607 16.0001 10.146 16.0001 11.7067C16.0001 13.2675 14.7237 14.5374 13.1548 14.5374Z"
      />
    </svg>
  );
};

MenuFilterIcon.defaultProps = {
  color: '#CFCFCF',
};

MenuFilterIcon.propTypes = {
  color: PropTypes.string,
};
