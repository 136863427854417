import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isFunction } from '../../../../helpers/is-function';
import { Field } from '../../../ui/field';
import { CurrenciesSelectField } from '../../../currencies-select-field';
import { Button } from '../../../ui/button';
import {
  MAXIMUM_MANUAL_PAYOUTS,
  NAME_FOR_PAYOUTS_SCHEMA,
  MANUAL_PAYOUT_FORM_SCHEMA,
  DEFAULT_TEMPLATE_FOR_PAYOUT,
  FIELD_NAMES,
  ERROR_TEXTS,
} from '../../helpers';
import { LoaderIcon } from '../../../icons/loader-icon';
import { ButtonWithIcon } from '../../../shared/button-with-icon';
import classes from './styles.module.scss';

const USE_FORM_DATA = {
  resolver: yupResolver(MANUAL_PAYOUT_FORM_SCHEMA),
  defaultValues: {
    payouts: [DEFAULT_TEMPLATE_FOR_PAYOUT],
  },
};

export const ManualPayoutForm = (props) => {
  const {
    className,
    currencies,
    onSubmit,
    fetching,
    desktop,
    apiError,
  } = props;

  const {
    control, handleSubmit, formState, setError, clearErrors,
  } = useForm(USE_FORM_DATA);
  const { fields, append, remove } = useFieldArray({
    control,
    name: NAME_FOR_PAYOUTS_SCHEMA,
  });

  const [selectedCurrencies, setSelectedCurrencies] = React.useState({});
  const [activeFieldIndex, setActiveFieldIndex] = React.useState(1);

  const paginationForMobile = React.useMemo(() => (
    desktop || fields?.length <= 1 ? null : fields.map((_, index) => index + 1)
  ), [desktop, fields]);

  const handleCurrencySelect = React.useCallback((func, index) => (currency) => {
    const ticker = currency?.code ?? '';
    func(ticker);
    setSelectedCurrencies((prevState) => ({
      ...prevState,
      [index]: currency,
    }));
  }, []);
  const handleAddressChange = React.useCallback((func, index) => (value) => {
    func(value);

    const errorPath = `${NAME_FOR_PAYOUTS_SCHEMA}.${index}.${FIELD_NAMES.ADDRESS}`;
    const walletRegex = selectedCurrencies[index]?.walletRegex ?? '';
    const isValid = value.match(walletRegex);

    if (!value) {
      clearErrors(errorPath);

      return;
    }

    if (!isValid) {
      setError(errorPath, { type: 'error', message: ERROR_TEXTS.INVALID_ADDRESS });
    } else {
      clearErrors(errorPath);
    }
  }, [selectedCurrencies]);
  const handleExtraIdChange = React.useCallback((func, index) => (value) => {
    func(value);
    const errorPath = `${NAME_FOR_PAYOUTS_SCHEMA}.${index}.${FIELD_NAMES.EXTRA_ID}`;
    const extraIdRegex = selectedCurrencies[index]?.extraIdRegex ?? '';
    const isValid = value.match(extraIdRegex);
    if (!value) {
      clearErrors(errorPath);

      return;
    }

    if (!isValid) {
      setError(errorPath, { type: 'error', message: ERROR_TEXTS.INVALID_EXTRA_ID });
    } else {
      clearErrors(errorPath);
    }
  }, [selectedCurrencies]);
  const handleButtonAppendClick = React.useCallback(() => {
    append(DEFAULT_TEMPLATE_FOR_PAYOUT);
    if (!desktop) {
      const nextIndex = fields.length + 1;
      setActiveFieldIndex(nextIndex);
    }
  }, [append, desktop, fields]);
  const handleDeletePayout = React.useCallback((index) => () => {
    remove(index);
    const nextSelectedCurrencies = { ...selectedCurrencies };
    delete nextSelectedCurrencies[index];
    setSelectedCurrencies(nextSelectedCurrencies);
    if (!desktop) {
      const currentIndex = index + 1;
      const fieldsCount = fields.length;
      const currentActiveIndex = currentIndex < fieldsCount ? currentIndex : currentIndex - 1;
      setActiveFieldIndex(currentActiveIndex);
    }
  }, [remove, selectedCurrencies, desktop, fields]);
  const isFormDataValid = React.useCallback((data = []) => {
    let isValid = true;
    data.forEach(({ address, extra_id, amount }, index) => {
      const { walletRegex, extraIdRegex, amount: amountSelectedCurrency } = selectedCurrencies[index] ?? {};
      const amountNumber = Number(amount);
      const isWalletValid = address.match(walletRegex);
      const isExtraIdValid = extra_id ? extra_id.match(extraIdRegex) : false;
      const addressPath = `${NAME_FOR_PAYOUTS_SCHEMA}.${index}.${FIELD_NAMES.ADDRESS}`;
      const extraIdPath = `${NAME_FOR_PAYOUTS_SCHEMA}.${index}.${FIELD_NAMES.EXTRA_ID}`;
      const amountPath = `${NAME_FOR_PAYOUTS_SCHEMA}.${index}.${FIELD_NAMES.AMOUNT}`;

      if (!amountNumber || (amountNumber > amountSelectedCurrency)) {
        isValid = false;
        setError(amountPath, { type: 'error', message: ERROR_TEXTS.INVALID_AMOUNT });
      }
      if (!isWalletValid) {
        isValid = false;
        setError(addressPath, { type: 'error', message: ERROR_TEXTS.INVALID_ADDRESS });
      }
      if (extra_id && !isExtraIdValid) {
        isValid = false;
        setError(extraIdPath, { type: 'error', message: ERROR_TEXTS.INVALID_EXTRA_ID });
      }
    });

    return isValid;
  }, [selectedCurrencies]);
  const handleFormSubmit = React.useCallback(async (formData) => {
    const payouts = formData?.payouts ?? [];
    if (isFormDataValid(payouts) && isFunction(onSubmit)) {
      onSubmit(payouts, true);
    }
  }, [selectedCurrencies]);
  const handleFormEvent = React.useCallback((event) => {
    if (desktop) {
      return;
    }

    const errorFieldsIndex = event.payouts ? Object.keys(event.payouts).shift() : null;
    if (errorFieldsIndex) {
      setActiveFieldIndex(Number(errorFieldsIndex) + 1);
    }
  }, [desktop]);
  const handlePaginationClick = (number) => () => {
    setActiveFieldIndex(number);
  };

  const errorArray = formState.errors?.payouts ?? [];
  const isButtonDeleteShow = fields?.length > 1;
  const isButtonAddPayoutShow = fields?.length < MAXIMUM_MANUAL_PAYOUTS;
  const isButtonAddPayoutTextShow = desktop || fields?.length <= 1;

  return (
    <form
      className={cn(classes.manualPayoutForm, className)}
      onSubmit={handleSubmit(handleFormSubmit, handleFormEvent)}
    >
      <div className={classes.manualPayoutFormContent}>
        {fields.map((item, index) => (
          <div
            key={item.id}
            className={cn([
              classes.manualPayoutFormFields,
              activeFieldIndex === index + 1 && classes.manualPayoutFormFieldsActive,
            ])}
          >
            <Controller
              name={`${NAME_FOR_PAYOUTS_SCHEMA}.${index}.${FIELD_NAMES.CURRENCY}`}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div className={classes.formCurrenciesBox}>
                  <CurrenciesSelectField
                    className={classes.formCurrencies}
                    label="Currency"
                    placeholder="Type a currency"
                    currencies={currencies}
                    onSelect={handleCurrencySelect(field.onChange, index)}
                    error={errorArray[index]?.[FIELD_NAMES.CURRENCY]?.message ?? null}
                    smallTheme
                  />
                  <div className={classes.formCurrenciesText}>
                    {selectedCurrencies[index] && (
                      <>
                        Balance:
                        {' '}
                        {selectedCurrencies[index]?.amount ?? '-'}
                      </>
                    )}
                  </div>
                </div>
              )}
            />
            <Controller
              name={`${NAME_FOR_PAYOUTS_SCHEMA}.${index}.${FIELD_NAMES.ADDRESS}`}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Field
                  className={cn(classes.formField, classes.formFieldAddress)}
                  title="Address"
                  data={{
                    id: field.name,
                    value: field.value,
                    onChange: handleAddressChange(field.onChange, index),
                    name: field.name,
                    placeholder: 'Type an address',
                    error: errorArray[index]?.[FIELD_NAMES.ADDRESS]?.message ?? null,
                    ref: field.ref,
                  }}
                />
              )}
            />
            {selectedCurrencies[index]?.extraIdExists && (
              <Controller
                name={`${NAME_FOR_PAYOUTS_SCHEMA}.${index}.${FIELD_NAMES.EXTRA_ID}`}
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Field
                    className={cn(classes.formField, classes.formFieldExtraId)}
                    title="Memo"
                    data={{
                      id: field.name,
                      value: field.value,
                      onChange: handleExtraIdChange(field.onChange, index),
                      name: field.name,
                      placeholder: 'Type a memo',
                      error: errorArray[index]?.[FIELD_NAMES.EXTRA_ID]?.message ?? null,
                      ref: field.ref,
                    }}
                  />
                )}
              />
            )}
            <Controller
              name={`${NAME_FOR_PAYOUTS_SCHEMA}.${index}.${FIELD_NAMES.AMOUNT}`}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Field
                  className={cn(classes.formField, classes.formFieldAmount)}
                  title="Amount"
                  data={{
                    id: field.name,
                    value: field.value,
                    onChange: field.onChange,
                    name: field.name,
                    placeholder: 'Type an amount',
                    error: errorArray[index]?.[FIELD_NAMES.AMOUNT]?.message ?? null,
                    ref: field.ref,
                    pattern: /^\d*[.]?\d{0,8}$/,
                  }}
                />
              )}
            />
            {isButtonDeleteShow && (
              <Button
                className={classes.formButtonDelete}
                onClick={handleDeletePayout(index)}
              >
                Delete
              </Button>
            )}
          </div>
        ))}
      </div>
      <div className={classes.buttons}>
        <div className={classes.buttonAppendBox}>
          {paginationForMobile && paginationForMobile.map((number) => (
            <button
              key={`pagination-${number}`}
              type="button"
              className={cn([
                classes.buttonAppendPaginationItem,
                activeFieldIndex === number && classes.buttonAppendPaginationItemActive,
              ])}
              onClick={handlePaginationClick(number)}
            >
              {number}
            </button>
          ))}
          {isButtonAddPayoutShow && (
            <ButtonWithIcon
              onClick={handleButtonAppendClick}
              textShow={isButtonAddPayoutTextShow}
            >
              Add another payout
            </ButtonWithIcon>
          )}
        </div>
        <div className={classes.error}>
          {apiError}
        </div>
        <Button
          type="submit"
          className={classes.buttonSubmit}
          disabled={fetching}
        >
          {fetching ? (
            <LoaderIcon path="/images/loader-white-on-blue-icon.gif" />
          ) : 'Create mass payout'}
        </Button>
      </div>
    </form>
  );
};

ManualPayoutForm.defaultProps = {
  className: null,
  currencies: null,
  onSubmit: null,
  fetching: false,
  desktop: false,
  apiError: false,
};

ManualPayoutForm.propTypes = {
  className: PropTypes.string,
  currencies: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func,
  fetching: PropTypes.bool,
  desktop: PropTypes.bool,
  apiError: PropTypes.string,
};
