import * as React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { PageHeader } from '../../../ui/page-header';
import { LoaderIcon } from '../../../icons/loader-icon';
import { EnableCustody } from '../../../enable-custody';
import { MassPayoutsInfoAboutUse } from '../mass-payouts-info-about-use';
import { PAGE_NAMES_TO_DISPLAY_INFO } from '../../../../constants/app-constants';
import { usePageNamesToDisplayInfo } from '../../../../hooks/use-page-names-to-display-info';
import {
  isPartnerCustodySelector,
  partnerFetchedSelector,
  partnerFetchingSelector,
} from '../../../../store/partner/selectors';
import classes from '../../styles.module.scss';

export const PageWrapper = (props) => {
  const { children } = props;
  const partnerFetching = useSelector(partnerFetchingSelector());
  const partnerFetched = useSelector(partnerFetchedSelector());
  const isPartnerCustody = useSelector(isPartnerCustodySelector());

  const {
    infoBoxShow,
    addPageNameToDisplayInfo,
    removePageNameToDisplayInfo,
  } = usePageNamesToDisplayInfo(PAGE_NAMES_TO_DISPLAY_INFO.MASS_PAYOUTS);

  const isEnableCustodyStepsShow = partnerFetched && !isPartnerCustody;
  const isContentShow = partnerFetched && isPartnerCustody;

  return (
    <div>
      <PageHeader
        title="Mass Payouts"
        showTooltipFirstText={!infoBoxShow}
        onClick={infoBoxShow ? removePageNameToDisplayInfo : addPageNameToDisplayInfo}
        infoTooltipShow={isContentShow}
      />
      {partnerFetching && (
        <div className={classes.loaderBlock}>
          <LoaderIcon size={40} />
        </div>
      )}
      {isEnableCustodyStepsShow && (
        <EnableCustody />
      )}
      {isContentShow && (
        <>
          {infoBoxShow && (
            <MassPayoutsInfoAboutUse
              className={classes.infoBlock}
              name={PAGE_NAMES_TO_DISPLAY_INFO.MASS_PAYOUTS}
              onClose={removePageNameToDisplayInfo}
            />
          )}
          {children}
        </>
      )}
    </div>
  );
};

PageWrapper.defaultProps = {
  children: null,
};

PageWrapper.propTypes = {
  children: PropTypes.node,
};
