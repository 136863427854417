import * as React from 'react';
import PropTypes from 'prop-types';
import { InfoAboutUse } from '../../../shared/info-about-use';

export const CoinsSettingsInfoAboutUse = (props) => {
  const {
    className,
    name,
    onClose,
  } = props;

  return (
    <InfoAboutUse
      className={className}
      name={name}
      onClose={onClose}
    >
      In this section you can manage cryptocurrencies to accept.
      <div>
        <strong>Benefits:</strong>
        <ul>
          <li>Accept payments in over 200 cryptocurrencies</li>
          <li>Automatically convert and receive funds in your preferred payout wallet(s).</li>
        </ul>
      </div>
      <div>
        <strong>How to start:</strong>
        <ol>
          <li>Type your desired coin name in the search field.</li>
          <li>Use the icon search to find the specific coin you want to enable.</li>
          <li>Explore popular coins, stablecoins, and other currencies.</li>
          <li>Click on the coin&apos;s image to enable it as a payment currency.</li>
        </ol>
      </div>
    </InfoAboutUse>
  );
};

CoinsSettingsInfoAboutUse.defaultProps = {
  className: null,
  name: null,
  onClose: null,
};

CoinsSettingsInfoAboutUse.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onClose: PropTypes.func,
};
