import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initial-state';

export const SETTINGS_REDUCER_NAME = 'settings';

const setPageNamesToDisplayInfoState = (state, { payload }) => {
  state.pageNamesToDisplayInfo = payload;
};
const setNotificationMessageState = (state, { payload }) => {
  state.notificationMessage = payload;
};
const setSystemStatusState = (state, { payload }) => {
  state.systemStatus = payload;
};

export const settingsSlice = createSlice({
  name: SETTINGS_REDUCER_NAME,
  initialState,
  reducers: {
    setPageNamesToDisplayInfo: setPageNamesToDisplayInfoState,
    setNotificationMessage: setNotificationMessageState,
    setSystemStatus: setSystemStatusState,
  },
});

export const {
  setPageNamesToDisplayInfo,
  setNotificationMessage,
  setSystemStatus,
} = settingsSlice.actions;
export const settingsReducer = settingsSlice.reducer;
