import * as React from 'react';
import PropTypes from 'prop-types';
import { Popup } from '../../../popup';
import { Field } from '../../../ui/field';
import { Button } from '../../../ui/button';
import { LoaderIcon } from '../../../icons/loader-icon';
import { isFunction } from '../../../../helpers/is-function';
import classes from './styles.module.scss';

export const PopupCreateCustomers = (props) => {
  const {
    className,
    open,
    onClose,
    onSubmit,
    errorApiMessage,
    errorMessage,
    fetching,
  } = props;

  const [namesValue, setNamesValue] = React.useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isFunction(onSubmit)) {
      onSubmit(namesValue);
    }
  };

  React.useEffect(() => {
    if (!open) {
      setNamesValue('');
    }
  }, [open]);

  return (
    <Popup
      classNameContent={className}
      open={open}
      onClose={onClose}
      withCloseButton
      mobileFullView
    >
      <form className={classes.box} onSubmit={handleSubmit}>
        <div className={classes.title}>
          Add customers
        </div>
        <div className={classes.hr} />
        <div className={classes.text}>
          Enter a list of customer names separated by commas.
        </div>
        <Field
          className={classes.field}
          title="Customers"
          data={{
            value: namesValue,
            onChange: setNamesValue,
            placeholder: 'Enter names separated with commas',
            error: errorMessage,
            disabled: fetching,
          }}
          errorTopRight
        />
        <div className={classes.error}>
          {errorApiMessage}
        </div>
        <Button
          type="submit"
          className={classes.button}
          disabled={fetching}
        >
          {fetching
            ? <LoaderIcon path="/images/loader-white-on-blue-icon.gif" />
            : 'Add'}
        </Button>
      </form>
    </Popup>
  );
};

PopupCreateCustomers.defaultProps = {
  className: null,
  open: null,
  onClose: null,
  onSubmit: null,
  errorApiMessage: null,
  errorMessage: null,
  fetching: false,
};

PopupCreateCustomers.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  errorApiMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  fetching: PropTypes.bool,
};
