import React from 'react';
import cn from 'classnames';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Field } from '../../../ui/field';
import { CurrenciesSelectField } from '../../../currencies-select-field';
import { Button } from '../../../ui/button';
import {
  MAXIMUM_MANUAL_PAYOUTS,
  NAME_FOR_PAYOUTS_SCHEMA,
  DEFAULT_TEMPLATE,
  FIELD_NAMES,
  ERROR_TEXTS,
  WHITELIST_MANUAL_FORM_SCHEMA,
} from '../../helpers';
import { LoaderIcon } from '../../../icons/loader-icon';
import { ButtonWithIcon } from '../../../shared/button-with-icon';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import { WhitelistManualFormTypes, CurrencyTypes, ReactHookFormFuncType } from '../../types';
import classes from './styles.module.scss';

export const WhitelistManualForm: React.FC<WhitelistManualFormTypes> = (props) => {
  const {
    currencies,
    onSubmit,
    fetching = false,
    apiError = '',
  } = props;

  const isDesktop = useMediaQuery('(min-width: 768px)');

  const {
    control, handleSubmit, formState, setError, clearErrors,
  } = useForm({
    resolver: yupResolver(WHITELIST_MANUAL_FORM_SCHEMA),
    defaultValues: {
      [NAME_FOR_PAYOUTS_SCHEMA]: [DEFAULT_TEMPLATE],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: NAME_FOR_PAYOUTS_SCHEMA,
  });

  const [selectedCurrencies, setSelectedCurrencies] = React.useState<{ [index: number]: CurrencyTypes }>({});
  const [activeFieldIndex, setActiveFieldIndex] = React.useState<number>(1);

  const paginationForMobile = React.useMemo(() => (
    isDesktop || fields?.length <= 1 ? null : fields.map((_, index) => index + 1)
  ), [isDesktop, fields]);

  const handleCurrencySelect = React.useCallback((
    func: ReactHookFormFuncType,
    index: number,
  ) => (currency: CurrencyTypes) => {
    const ticker = currency?.code ?? '';
    func(ticker);
    setSelectedCurrencies((prevState) => ({
      ...prevState,
      [index]: currency,
    }));
  }, []);
  const handleAddressChange = React.useCallback((
    func: ReactHookFormFuncType,
    index: number,
  ) => (value: string) => {
    func(value);

    const errorPath = `${NAME_FOR_PAYOUTS_SCHEMA}.${index}.${FIELD_NAMES.ADDRESS}`;
    const walletRegex = selectedCurrencies[index]?.wallet_regex ?? '';
    const isValid = value.match(walletRegex);

    if (!value) {
      // @ts-ignore
      clearErrors(errorPath);

      return;
    }

    if (!isValid) {
      // @ts-ignore
      setError(errorPath, { type: 'error', message: ERROR_TEXTS.INVALID_ADDRESS });
    } else {
      // @ts-ignore
      clearErrors(errorPath);
    }
  }, [selectedCurrencies]);
  const handleExtraIdChange = React.useCallback((
    func: ReactHookFormFuncType,
    index: number,
  ) => (value: string) => {
    func(value);
    const errorPath = `${NAME_FOR_PAYOUTS_SCHEMA}.${index}.${FIELD_NAMES.EXTRA_ID}`;
    const extraIdRegex = selectedCurrencies[index]?.extra_id_regex ?? '';
    const isValid = value.match(extraIdRegex);
    if (!value) {
      // @ts-ignore
      clearErrors(errorPath);

      return;
    }

    if (!isValid) {
      // @ts-ignore
      setError(errorPath, { type: 'error', message: ERROR_TEXTS.INVALID_EXTRA_ID });
    } else {
      // @ts-ignore
      clearErrors(errorPath);
    }
  }, [selectedCurrencies]);
  const handleButtonAppendClick = React.useCallback(() => {
    append(DEFAULT_TEMPLATE);
    if (!isDesktop) {
      const nextIndex = fields.length + 1;
      setActiveFieldIndex(nextIndex);
    }
  }, [append, isDesktop, fields]);
  const handleDeletePayout = React.useCallback((index: number) => () => {
    remove(index);
    const nextSelectedCurrencies = { ...selectedCurrencies };
    delete nextSelectedCurrencies[index];
    setSelectedCurrencies(nextSelectedCurrencies);
    if (!isDesktop) {
      const currentIndex = index + 1;
      const fieldsCount = fields.length;
      const currentActiveIndex = currentIndex < fieldsCount ? currentIndex : currentIndex - 1;
      setActiveFieldIndex(currentActiveIndex);
    }
  }, [remove, selectedCurrencies, isDesktop, fields]);
  const isFormDataValid = React.useCallback((data: Array<{ address?: string, extra_id?: string }>): boolean => {
    let isValid = true;
    data.forEach(({ address = '', extra_id = '' }, index: number) => {
      const { wallet_regex: walletRegex, extra_id_regex: extraIdRegex } = selectedCurrencies[index] ?? {};
      const isWalletValid = address.match(walletRegex);
      const isExtraIdValid = extra_id ? extra_id.match(extraIdRegex) : false;
      const addressPath = `${NAME_FOR_PAYOUTS_SCHEMA}.${index}.${FIELD_NAMES.ADDRESS}`;
      const extraIdPath = `${NAME_FOR_PAYOUTS_SCHEMA}.${index}.${FIELD_NAMES.EXTRA_ID}`;

      if (!isWalletValid) {
        isValid = false;
        // @ts-ignore
        setError(addressPath, { type: 'error', message: ERROR_TEXTS.INVALID_ADDRESS });
      }
      if (extra_id && !isExtraIdValid) {
        isValid = false;
        // @ts-ignore
        setError(extraIdPath, { type: 'error', message: ERROR_TEXTS.INVALID_EXTRA_ID });
      }
    });

    return isValid;
  }, [selectedCurrencies]);
  const handleFormSubmit = React.useCallback(async (formData: { [NAME_FOR_PAYOUTS_SCHEMA]: Array<object> }) => {
    const currentData = formData?.[NAME_FOR_PAYOUTS_SCHEMA] ?? [];
    if (isFormDataValid(currentData) && onSubmit) {
      onSubmit(currentData);
    }
  }, [selectedCurrencies]);
  const handleFormEvent = React.useCallback((data: any) => {
    if (isDesktop) {
      return;
    }

    const errorFieldsIndex = data?.[NAME_FOR_PAYOUTS_SCHEMA]
      ? Object.keys(data?.[NAME_FOR_PAYOUTS_SCHEMA]).shift()
      : null;
    if (errorFieldsIndex) {
      setActiveFieldIndex(Number(errorFieldsIndex) + 1);
    }
  }, [isDesktop]);
  const handlePaginationClick = (number: number) => () => {
    setActiveFieldIndex(number);
  };

  const errorArray = formState.errors?.[NAME_FOR_PAYOUTS_SCHEMA] ?? [];
  const isButtonDeleteShow = fields?.length > 1;
  const isButtonAddPayoutShow = fields?.length < MAXIMUM_MANUAL_PAYOUTS;
  const isButtonAddPayoutTextShow = isDesktop || fields?.length <= 1;

  return (
    <form
      className={cn(classes.whitelistManualForm)}
      onSubmit={handleSubmit(handleFormSubmit, handleFormEvent)}
    >
      <div className={classes.whitelistManualFormContent}>
        {fields.map((item, index) => (
          <div
            key={item.id}
            className={cn([
              classes.formFields,
              activeFieldIndex === index + 1 && classes.formFieldsActive,
            ])}
          >
            <Controller
              name={`whitelistManual.${index}.currency`}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <CurrenciesSelectField
                  className={classes.formCurrencies}
                  label="Currency"
                  placeholder="Type a currency"
                  currencies={currencies}
                  onSelect={handleCurrencySelect(field.onChange, index)}
                  error={errorArray[index]?.currency?.message ?? null}
                  smallTheme
                />
              )}
            />
            <Controller
              name={`whitelistManual.${index}.address`}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Field
                  className={cn(classes.formField, classes.formFieldAddress)}
                  title="Address"
                  data={{
                    id: field.name,
                    // @ts-ignore
                    value: field.value,
                    onChange: handleAddressChange(field.onChange, index),
                    name: field.name,
                    placeholder: 'Type an address',
                    error: errorArray[index]?.address?.message ?? null,
                    ref: field.ref,
                  }}
                />
              )}
            />
            {selectedCurrencies[index]?.extra_id_exists && (
              <Controller
                name={`whitelistManual.${index}.extra_id`}
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Field
                    className={cn(classes.formField, classes.formFieldExtraId)}
                    title="Memo"
                    data={{
                      id: field.name,
                      // @ts-ignore
                      value: field.value,
                      onChange: handleExtraIdChange(field.onChange, index),
                      name: field.name,
                      placeholder: 'Type a memo',
                      error: errorArray[index]?.extra_id?.message ?? null,
                      ref: field.ref,
                    }}
                  />
                )}
              />
            )}
            {isButtonDeleteShow && (
              <Button
                className={classes.formButtonDelete}
                onClick={handleDeletePayout(index)}
              >
                Delete
              </Button>
            )}
          </div>
        ))}
      </div>
      <div className={classes.buttons}>
        <div className={classes.buttonAppendBox}>
          {paginationForMobile && paginationForMobile.map((number) => (
            <button
              key={`pagination-${number}`}
              type="button"
              className={cn([
                classes.buttonAppendPaginationItem,
                activeFieldIndex === number && classes.buttonAppendPaginationItemActive,
              ])}
              onClick={handlePaginationClick(number)}
            >
              {number}
            </button>
          ))}
          {isButtonAddPayoutShow && (
            <ButtonWithIcon
              onClick={handleButtonAppendClick}
              textShow={isButtonAddPayoutTextShow}
            >
              Add address
            </ButtonWithIcon>
          )}
        </div>
        <div className={classes.error}>
          {apiError}
        </div>
        <Button
          type="submit"
          className={classes.buttonSubmit}
          disabled={fetching}
        >
          {fetching ? (
            <LoaderIcon path="/images/loader-white-on-blue-icon.gif" />
          ) : 'Continue'}
        </Button>
      </div>
    </form>
  );
};
