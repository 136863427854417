import * as React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import { HistoryTableMemo } from '../../../history-table';
import { NewPagination } from '../../../shared/new-pagination';
import {
  SUBSCRIPTION_USER_PAGE_PER_PAGE,
  SUBSCRIPTION_USER_PAGE_THS_DESKTOP,
  SUBSCRIPTION_USER_PAGE_THS_MOBILE,
} from '../../helpers';
import { SubscriptionUserTableRow } from '../subscription-user-table-row';
import { isFunction } from '../../../../helpers/is-function';
import classes from './styles.module.scss';

export const SubscriptionUserTable = React.memo((props) => {
  const {
    items,
    hasItems,
    hasSearchData,
    loading,
    onClick,
    count,
    onPaginatorClick,
    page,
    apiError,
    currenciesObject,
  } = props;

  const isDesktop = useMediaQuery('(min-width: 1200px)');
  const thItems = isDesktop
    ? SUBSCRIPTION_USER_PAGE_THS_DESKTOP
    : SUBSCRIPTION_USER_PAGE_THS_MOBILE;
  const isPaginationShow = count > SUBSCRIPTION_USER_PAGE_PER_PAGE;

  const handleRowClick = React.useCallback((transaction) => {
    if (isFunction(onClick)) {
      onClick(transaction);
    }
  }, []);

  return (
    <>
      <div className={classes.userTransactionsTableBox}>
        <HistoryTableMemo
          className={classes.userTransactionsTable}
          thItems={thItems}
          colSpan={6}
          loading={loading}
          historyData={items}
          hasData={hasSearchData || hasItems}
          emptyText="There are no payments yet."
          notFoundText="No payments matching this criteria."
          apiError={apiError}
        >
          {hasItems && items.map((item, index) => (
            <SubscriptionUserTableRow
              key={`${item?.paymentId}-${index}`}
              payment={item}
              currenciesObject={currenciesObject}
              onClick={handleRowClick}
              isDesktop={isDesktop}
            />
          ))}
        </HistoryTableMemo>
      </div>
      {isPaginationShow && (
        <NewPagination
          className={classes.paginator}
          currentPage={page}
          countItems={count}
          itemsPerPage={SUBSCRIPTION_USER_PAGE_PER_PAGE}
          onChangePage={onPaginatorClick}
        />
      )}
    </>
  );
});

SubscriptionUserTable.defaultProps = {
  items: null,
  hasItems: false,
  hasSearchData: false,
  loading: false,
  onClick: null,
  onButtonClick: null,
  count: 0,
  onPaginatorClick: null,
  page: 1,
  reFetching: false,
  apiError: null,
  currenciesObject: {},
};

SubscriptionUserTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  hasItems: PropTypes.bool,
  hasSearchData: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  onButtonClick: PropTypes.func,
  count: PropTypes.number,
  onPaginatorClick: PropTypes.func,
  page: PropTypes.number,
  reFetching: PropTypes.bool,
  apiError: PropTypes.string,
  currenciesObject: PropTypes.object,
};

SubscriptionUserTable.displayName = 'SubscriptionUserTable';
