import React from 'react';
import { isNeedStatusUpdate } from '../../../helpers/is-need-status-update';
import { usePayment } from '../../../api/modules/account/use-payment';

const TIMEOUT = 4000;
const MAX_RECURSION_COUNT = 10;

export const useObservablePaymentState = () => {
  const fetchPaymentById = usePayment();

  const [paymentShow, setPaymentShow] = React.useState(null);
  const [payment, setPayment] = React.useState(null);
  const timerId = React.useRef(null);

  const paymentNormalized = React.useMemo(() => {
    if (!payment) {
      return null;
    }

    return {
      invoiceId: payment?.invoiceId || payment?.invoice_id || null,
      paymentId: payment?.paymentId || payment?.id || null,
      payAmount: payment?.payAmount || payment?.pay_amount || null,
      payCurrency: payment?.payCurrency || payment?.pay_currency || null,
      payinAddress: payment?.payAddress
        || payment?.exchangeData?.payinAddress
        || payment?.exchange_data?.payinAddress
        || null,
      payinExtraId: payment?.payinExtraId || payment?.payin_extra_id || null,
      status: payment?.paymentStatus || payment?.status || null,
    };
  }, [payment]);

  const handlePaymentInfoOpen = (data) => {
    setPayment(data);
    setPaymentShow(true);
  };
  const handlePaymentInfoClose = () => {
    setPayment(null);
    setPaymentShow(false);
  };

  const startObservePayment = React.useCallback((paymentInfo) => {
    const updatePayment = async (paymentData, recursionCount) => {
      if (!isNeedStatusUpdate(paymentData?.status)) {
        return;
      }

      if (recursionCount >= MAX_RECURSION_COUNT) {
        handlePaymentInfoClose();

        return;
      }

      const { data, status } = await fetchPaymentById(paymentData?.paymentId);

      if (status === 200) {
        setPayment(data);
        timerId.current = setTimeout(() => {
          void updatePayment(data, recursionCount);
        }, TIMEOUT);
      } else {
        timerId.current = setTimeout(() => {
          void updatePayment(paymentData, recursionCount + 1);
        }, TIMEOUT);
      }
    };

    timerId.current = setTimeout(() => {
      void updatePayment(paymentInfo, 0);
    }, TIMEOUT);
  }, []);

  React.useEffect(() => {
    if (paymentShow && paymentNormalized) {
      startObservePayment(paymentNormalized);
    }

    return () => {
      clearTimeout(timerId.current);
    };
  }, [paymentShow, paymentNormalized]);

  return {
    observablePaymentShow: paymentShow,
    observablePaymentData: paymentNormalized,
    handlePaymentInfoOpen,
    handlePaymentInfoClose,
  };
};
