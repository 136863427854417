import React from 'react';
import cn from 'classnames';
import { DashboardTransactionHistory } from './components/dashboard-transaction-history';
import { DashboardOnboarding } from './components/dasboard-onboarding';
import { LoaderIcon } from '../icons/loader-icon';
import { useNotifications, useInitState } from './hooks';
import { DashboardNotificationPopup } from './components/dashboard-notification-popup';
import { TeamActivatePopup } from '../team-activate-popup';
import classes from './styles.module.scss';

export const DashboardPage = () => {
  const {
    isTransactionHistoryShow,
    hasErrors,
    isLoaderShow,
    isTurnoverLoaded,
  } = useInitState();

  const {
    isNotificationPopupShow,
    handleNotificationPopupClose,
  } = useNotifications();

  return (
    <div className={classes.dashboardPage}>
      <h1 className={cn(!isTransactionHistoryShow && classes.onboardingTitle)}>
        Dashboard
      </h1>
      {isLoaderShow && (
        <LoaderIcon
          className={classes.dashboardPageLoading}
          size={20}
        />
      )}
      {(hasErrors || isTransactionHistoryShow) && (
        <DashboardTransactionHistory />
      )}
      {(!hasErrors && isTurnoverLoaded && !isTransactionHistoryShow) && (
        <DashboardOnboarding />
      )}
      <DashboardNotificationPopup
        open={isNotificationPopupShow}
        onClose={handleNotificationPopupClose}
      />
      <TeamActivatePopup />
    </div>
  );
};
