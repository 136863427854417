import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { endOfDay } from 'date-fns';
import { useStatisticsDates } from '../../../api/modules/account/use-statistics-dates';
import { ResponseType } from '../../../default-types';
import { checkErrorCode } from '../../../helpers/check-error-code';
import { getSafeErrorMessageText } from '../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../helpers/console-error-message';
import {
  activePeriodTypeSelector,
  activeStatsTypeSelector,
  errorMessageSelector,
  isFetchingSelector,
  lastTransactionsSelector,
  periodSelector,
  statsCoinsSelector,
  statsTurnoverSelector,
  turnoverSelector,
} from '../../../store/statistics/selectors';
import {
  setStatsTurnover,
  setStatsCoins,
  setActiveStatsType,
  setActivePeriodType,
  setPeriod,
  setIsFetching,
  setErrorMessage,
} from '../../../store/statistics/reducer';
import {
  TYPES,
  getNormalizedTurnoverStatisticsForAreaChart,
  getPeriodDates,
  getNormalizedStatisticsForPieChart,
} from '../helpers';
import {
  ReFetchStatisticsOptionsType,
  StatsPieChartCoinType,
  TurnoverStatsDataType,
} from '../types';
import {
  baseCurrencyErrorSelector,
  baseCurrencySelector,
} from '../../../store/payment-settings/selectors';
import { useStatisticsCurrencies } from '../../../api/modules/account/use-statistics-currencies';
import { hasElementsInArray } from '../../../helpers/has-elements-in-array';
import { partnerErrorSelector } from '../../../store/partner/selectors';

export const useInitialState = (currenciesObject: object) => {
  const dispatch = useDispatch();
  const fetchStatisticsDates = useStatisticsDates();
  const fetchStatisticsCurrencies = useStatisticsCurrencies();
  const lastTransactions = useSelector(lastTransactionsSelector());
  const turnover = useSelector(turnoverSelector());
  const activeStatsType = useSelector(activeStatsTypeSelector());
  const activePeriodType = useSelector(activePeriodTypeSelector());
  const statsTurnover = useSelector(statsTurnoverSelector());
  const statsCoins = useSelector(statsCoinsSelector());
  const isStatsFetching = useSelector(isFetchingSelector());
  const baseCurrency = useSelector(baseCurrencySelector());
  const statsPeriod = useSelector(periodSelector());
  const statsErrorMessage = useSelector(errorMessageSelector());
  const baseCurrencyError = useSelector(baseCurrencyErrorSelector());
  const partnerError = useSelector(partnerErrorSelector());
  const { startDate, endDate } = statsPeriod;
  const {
    isLoaded: isTurnoverLoaded,
    amount: turnoverAmount,
    errorMessage: turnoverErrorMessage,
  } = turnover;

  const isNoTransactions = isTurnoverLoaded
    && !Number(turnoverAmount)
    && !hasElementsInArray(lastTransactions);

  const [isCalendarShow, setIsCalendarShow] = React.useState<boolean>(false);

  const handleGraphTabClick = React.useCallback((type: string) => {
    if (isNoTransactions) {
      return;
    }
    dispatch(setActiveStatsType(type));
  }, [dispatch, isNoTransactions]);
  const handlePeriodTabClick = React.useCallback((period: string) => {
    if (isNoTransactions || activeStatsType === TYPES.COINS) {
      return;
    }
    if (period === TYPES.CALENDAR) {
      setIsCalendarShow(true);
      return;
    }
    const periodDates = getPeriodDates(period, { start: startDate, end: endDate });
    dispatch(setActivePeriodType(period));
    dispatch(setPeriod(periodDates));
  }, [dispatch, startDate, endDate, isNoTransactions, activeStatsType]);
  const handleDateRangeChange = React.useCallback(({ from, to }: { from: Date, to: Date | null }) => {
    const dateFromString = from.toISOString();
    const currentDateTo = to || from;
    const currenDateToEndOfDay = endOfDay(currentDateTo);
    const dateToString = currenDateToEndOfDay.toISOString();
    dispatch(setPeriod({ startDate: dateFromString, endDate: dateToString }));

    if (activePeriodType !== TYPES.CALENDAR) {
      dispatch(setActivePeriodType(TYPES.CALENDAR));
    }
  }, [dispatch, activePeriodType]);
  const handleCalendarClose = () => {
    setIsCalendarShow(false);
  };

  const simpleAreaChartsData: TurnoverStatsDataType[] = React.useMemo(() => (
    getNormalizedTurnoverStatisticsForAreaChart(
      statsTurnover,
      startDate,
      endDate,
    )
  ), [statsTurnover, startDate, endDate]);
  const pieChartData: StatsPieChartCoinType[] = React.useMemo(() => (
    getNormalizedStatisticsForPieChart(statsCoins, currenciesObject)
  ), [statsCoins, currenciesObject]);

  const reFetchStatistics = React.useCallback(async ({ dateFrom, dateTo }: ReFetchStatisticsOptionsType) => {
    dispatch(setErrorMessage(null));
    dispatch(setIsFetching(true));
    const { data, status, errorMessage, isCanceled }: ResponseType = await fetchStatisticsDates({
      period: TYPES.DAY,
      baseCurrency,
      dateFrom,
      dateTo,
    });
    if (status === 200) {
      dispatch(setStatsTurnover(data.result));
      dispatch(setIsFetching(false));
    } else {
      checkErrorCode(data?.errorData?.code);
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/stats/payments/date');
      if (!isCanceled) {
        dispatch(setErrorMessage(`Statistics: ${errorDataMessage}`));
      }
      dispatch(setIsFetching(false));
    }
  }, [
    fetchStatisticsDates,
    dispatch,
    baseCurrency,
  ]);
  const reFetchCoinStatistics = React.useCallback(async () => {
    dispatch(setErrorMessage(null));
    dispatch(setIsFetching(true));
    const { data, status, errorMessage, isCanceled }: ResponseType = await fetchStatisticsCurrencies({});
    if (status === 200) {
      dispatch(setStatsCoins(data.result));
      dispatch(setIsFetching(false));
    } else {
      checkErrorCode(data?.errorData?.code);
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/stats/payments/currency');
      if (!isCanceled) {
        dispatch(setErrorMessage(`Statistics: ${errorDataMessage}`));
      }
      dispatch(setIsFetching(false));
    }
  }, [fetchStatisticsCurrencies, dispatch]);

  React.useEffect(() => {
    if (!isTurnoverLoaded || (!startDate && !endDate)) {
      return () => {};
    }

    let nextTimerId: ReturnType<typeof setTimeout> | null = null;

    if (activeStatsType === TYPES.TURNOVER) {
      nextTimerId = setTimeout(() => {
        reFetchStatistics({ dateFrom: startDate, dateTo: endDate });
      }, 0);
    } else {
      nextTimerId = setTimeout(() => {
        reFetchCoinStatistics();
      }, 0);
    }

    return () => {
      if (nextTimerId) {
        clearTimeout(nextTimerId);
      }
    };
  }, [
    isTurnoverLoaded,
    activeStatsType,
    startDate,
    endDate,
  ]);

  const isTabsDisabled = !isTurnoverLoaded || isNoTransactions || turnoverErrorMessage;
  const isStatsChartDisabled = isTabsDisabled || statsErrorMessage;
  const isTurnoverStatsActive = TYPES.TURNOVER === activeStatsType;
  const isCoinsStatsActive = TYPES.COINS === activeStatsType;

  return {
    activeStatsType,
    activePeriodType,
    calendarDateFrom: new Date(startDate),
    calendarDateTo: new Date(endDate),
    simpleAreaChartsData,
    pieChartData,
    errorMessage: turnoverErrorMessage || statsErrorMessage || baseCurrencyError || partnerError,
    handleGraphTabClick,
    handlePeriodTabClick,
    handleDateRangeChange,
    handleCalendarClose,
    isCalendarShow,
    isNoTransactions,
    isTabsDisabled,
    isStatsChartDisabled,
    isStatsFetching,
    isTurnoverStatsActive,
    isCoinsStatsActive,
  };
};
