import * as React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from '../../../../helpers/format-date';
import { isFunction } from '../../../../helpers/is-function';
import { DATE_PATTERN } from '../../../../constants/app-constants';

export const BillingPlanUsersTableRow = React.memo((props) => {
  const {
    className,
    onClick,
    subPartnerId,
    active,
    createdAt,
    updatedAt,
    isDesktop,
  } = props;

  const subPartnerIdNormalized = subPartnerId || '-';
  const statusText = active ? 'Active' : 'Inactive';
  const createdDateFormatted = formatDate(createdAt, DATE_PATTERN);
  const createdDateNormalized = createdDateFormatted || '-';
  const updatedDateFormatted = formatDate(updatedAt, DATE_PATTERN);
  const updatedDateNormalized = updatedDateFormatted || '-';

  const handleRowClick = () => {
    if (isFunction(onClick)) {
      onClick(subPartnerId);
    }
  };

  return (
    <tr
      className={className}
      onClick={handleRowClick}
    >
      <td data-color="black">
        {subPartnerIdNormalized}
      </td>
      <td>
        <span data-color={active ? 'green' : 'pink'}>
          {statusText}
        </span>
      </td>
      <td>
        {createdDateNormalized}
        {!isDesktop && (
          <div data-margin="top">
            {updatedDateNormalized}
          </div>
        )}
      </td>
      {isDesktop && (
        <td>
          {updatedDateNormalized}
        </td>
      )}
    </tr>
  );
});

BillingPlanUsersTableRow.defaultProps = {
  className: null,
  onClick: null,
  subPartnerId: null,
  active: false,
  createdAt: null,
  updatedAt: null,
  isDesktop: false,
};

BillingPlanUsersTableRow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  subPartnerId: PropTypes.string,
  active: PropTypes.bool,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  isDesktop: PropTypes.bool,
};

BillingPlanUsersTableRow.displayName = 'BillingPlanUsersTableRow';
