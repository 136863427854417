import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { SimpleSelect } from '../simple-select';
import styles from './styles.module.scss';

export const SimpleSelectField = (props) => {
  const {
    className,
    title,
    data,
    fetching,
  } = props;

  const {
    id,
    onSelect,
    items,
    defaultValue,
    defaultImagePath,
    error,
    reset,
    placeholder,
  } = data;

  return (
    <div className={cn(styles.fieldSelect, className)}>
      {title && (
        <div className={styles.fieldTitle}>
          {title}
        </div>
      )}
      <SimpleSelect
        className={styles.fieldSelect}
        id={id}
        onSelect={onSelect}
        items={items}
        defaultValue={defaultValue}
        defaultImagePath={defaultImagePath}
        reset={reset}
        fetching={fetching}
        placeholder={placeholder}
      />
      {error && (
        <div className={cn([
          styles.error,
          error && styles.errorShow,
        ])}>
          <div className={styles.errorText}>
            {error}
          </div>
        </div>
      )}
    </div>
  );
};

SimpleSelectField.defaultProps = {
  className: null,
  title: null,
  fetching: false,
  data: {
    id: null,
    onSelect: null,
    items: null,
    defaultValue: null,
    defaultImagePath: null,
    error: null,
    reset: false,
    placeholder: null,
  },
};

SimpleSelectField.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  fetching: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.string,
    onSelect: PropTypes.func,
    items: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
      logoUrl: PropTypes.string,
    })),
    defaultValue: PropTypes.string,
    defaultImagePath: PropTypes.string,
    error: PropTypes.string,
    reset: PropTypes.bool,
    placeholder: PropTypes.string,
  }),
};
