import setDate from 'date-fns/setDate';
import setMonth from 'date-fns/setMonth';

export const calculateDate = (
  date: Date,
  number: number,
  periodType: string,
  isMinus: boolean = false,
): Date => {
  switch (periodType) {
    case 'day': {
      return !isMinus
        ? setDate(date, date.getDate() + number)
        : setDate(date, date.getDate() - number);
    }
    case 'month': {
      return !isMinus
        ? setMonth(date, date.getMonth() + number)
        : setMonth(date, date.getMonth() - number);
    }
    default: {
      return date;
    }
  }
};
