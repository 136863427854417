import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isFunction } from '../../../../helpers/is-function';
import { SimpleSelectField } from '../../../ui/simple-select-field';
import { Field } from '../../../ui/field';
import { Button } from '../../../ui/button';
import { HISTORY_SCHEMA } from '../../custody-page-helpers';
import { STATUSES } from '../../../../constants/app-constants';
import { DateField } from '../../../ui/date-field';
import classes from './styles.module.scss';

const STATUS_ITEMS = [
  { key: '', value: 'All' },
  { key: STATUSES.FINISHED, value: 'Finished' },
  { key: STATUSES.PARTIALLY_PAID, value: 'Partially paid' },
  { key: STATUSES.FAILED, value: 'Failed' },
  { key: STATUSES.EXPIRED, value: 'Expired' },
];

const HistoryFilter = (props) => {
  const {
    className,
    onSubmit,
    onClose,
    onSearchFormData,
    hasData,
    onExport,
  } = props;

  const {
    control,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(HISTORY_SCHEMA),
  });

  const [isResetData, setIsResetData] = React.useState(false);
  const [createdFrom, setCreatedFrom] = React.useState(null);
  const [createdTo, setCreatedTo] = React.useState(null);

  const handleDateRangeChange = ({ from, to }) => {
    setCreatedFrom(from);
    setCreatedTo(to);
  };
  const handleFormSubmit = (data) => {
    setIsResetData(false);
    const date = createdFrom ? { from: createdFrom, to: createdTo } : null;

    if (isFunction(onSubmit)) {
      onSubmit({
        ...data,
        date,
      });
    }
  };
  const handleResetForm = () => {
    setIsResetData(true);
    setCreatedFrom(null);
    setCreatedTo(null);
    if (isFunction(reset)) {
      reset();
    }
    if (isFunction(onSearchFormData)) {
      onSearchFormData(null);
    }
  };
  const handleClose = () => {
    if (isFunction(onClose)) {
      onClose();
    }
  };
  const handleExportClick = () => {
    if (isFunction(onExport)) {
      onExport();
    }
  };

  return (
    <form
      className={cn(classes.filters, className)}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <div className={classes.fields}>
        <DateField
          className={cn(classes.filterFieldDate, classes.filterInput)}
          title="Date"
          createdFrom={createdFrom}
          createdTo={createdTo}
          onChange={handleDateRangeChange}
        />
        <Controller
          name="status"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <SimpleSelectField
              className={classes.filterInput}
              title="Status"
              data={{
                id: field.name,
                items: STATUS_ITEMS,
                onSelect: field.onChange,
                defaultValue: 'All',
                reset: isResetData,
              }}
            />
          )}
        />
        <Controller
          name="id"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Field
              className={classes.filterInput}
              title="ID"
              data={{
                id: field.name,
                value: field.value,
                onChange: field.onChange,
                name: field.name,
              }}
            />
          )}
        />
        <Controller
          name="payoutDescription"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Field
              className={classes.filterInput}
              title="Payout description"
              data={{
                id: field.name,
                value: field.value,
                onChange: field.onChange,
                name: field.name,
              }}
            />
          )}
        />
      </div>
      <div className={classes.buttons}>
        <Button
          className={cn(classes.button, classes.buttonReset)}
          onClick={handleResetForm}
        >
          Clear all filters
        </Button>
        <Button
          className={cn(classes.buttonExport)}
          onClick={handleExportClick}
          disabled={!hasData}
        >
          Export
        </Button>
        <Button
          className={cn(classes.button, classes.buttonClose)}
          onClick={handleClose}
        >
          Close
        </Button>
        <Button
          type="submit"
          className={cn(classes.button, classes.buttonSubmit)}
        >
          Apply
        </Button>
      </div>
    </form>
  );
};

HistoryFilter.defaultProps = {
  className: null,
  onSubmit: null,
  onClose: null,
  onSearchFormData: null,
  currencies: [],
  onChangePayCurrency: null,
  hasData: true,
  onExport: null,
};

HistoryFilter.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  onSearchFormData: PropTypes.func,
  currencies: PropTypes.arrayOf(PropTypes.object),
  onChangePayCurrency: PropTypes.func,
  hasData: PropTypes.bool,
  onExport: PropTypes.func,
};

export const HistoryFilterMemo = React.memo(HistoryFilter);
