module.exports = {
  api_domain: process.env.REACT_APP_API_DOMAIN,
  oauth_api_domain: process.env.REACT_APP_OAUTH_API_DOMAIN,
  front_domain: process.env.REACT_APP_FRONT_DOMAIN,
  acc_front_domain: process.env.REACT_APP_ACCOUNT_FRONT_DOMAIN,
  captcha_key: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  popularCoins:
    process.env.REACT_APP_POPULAR_COINS &&
    process.env.REACT_APP_POPULAR_COINS.split(","),
  stableCoins:
    process.env.REACT_APP_STABLE_COINS &&
    process.env.REACT_APP_STABLE_COINS.split(","),
  GOOGLE_API_DOMAIN: process.env.REACT_APP_GOOGLE_API_DOMAIN,
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  GOOGLE_GTAG_ID: process.env.REACT_APP_GOOGLE_GTAG_ID,
  NETWORKS_TO_DISPLAY: process.env?.REACT_APP_NETWORKS_TO_DISPLAY?.split(',') ?? [],
  X_TEMP_TOKEN: process.env.REACT_APP_X_TEMP_TOKEN,
};
