import React from 'react';
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAddPayoutAccount } from '../../api/modules/account/use-add-payout-account';
import {
  ADD_ACCOUNT_SCHEMA_FIRST_STEP,
  ADD_ACCOUNT_SCHEMA_SECOND_STEP_EURO,
  ADD_ACCOUNT_SCHEMA_SECOND_STEP_INR,
  ADD_ACCOUNT_SCHEMA_SECOND_STEP_OTHER,
} from './helpers';
import { LoaderIcon } from '../icons/loader-icon';
import { Button } from '../ui/button';
import { Field } from '../ui/field';
import { CustomSelectField } from '../ui/custom-select-field';
import { CheckboxWithText } from '../ui/checkbox-with-text';
import { Item } from '../ui/custom-select-field/types';
import { getSafeErrorMessageText } from '../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../helpers/console-error-message';
import { useCountryList } from '../../hooks/use-country-list';
import { useBankList } from './hooks';
import { AddNewAccountFormProps, Bank, Country } from './types';
import { FiatOffRampCurrency } from '../../types';
import classes from './styles.module.scss';

export const AddNewAccountForm: React.FC<AddNewAccountFormProps> = (props) => {
  const {
    onUpdate,
    closePopup,
    fiatCurrencies,
    hasBackArrow = false,
    onBack,
  } = props;

  const [activeStep, setActiveStep] = React.useState(1);
  const [validationSchemaSecondStep, setValidationSchemaSecondStep] = React
    .useState<any>(ADD_ACCOUNT_SCHEMA_SECOND_STEP_OTHER);

  const {
    control,
    formState,
    handleSubmit,
    trigger,
  } = useForm({
    resolver: yupResolver(activeStep === 1
      ? ADD_ACCOUNT_SCHEMA_FIRST_STEP
      : validationSchemaSecondStep),
  });
  const {
    fiatCurrency,
    paymentCode,
    countryCode,
    accountNumber,
    iban,
    swiftBic,
    processingConsent,
  } = formState.errors;
  const fiatCurrencyErrorMessage = (fiatCurrency?.message as string) ?? null;
  const paymentCodeErrorMessage = (paymentCode?.message as string) ?? null;
  const countryErrorMessage = (countryCode?.message as string) ?? null;
  const accountNumberErrorMessage = (accountNumber?.message as string) ?? null;
  const ibanErrorMessage = (iban?.message as string) ?? null;
  const swiftBicErrorMessage = (swiftBic?.message as string) ?? null;

  const [selectedCurrency, setSelectedCurrency] = React.useState<FiatOffRampCurrency | null>(null);
  const [selectedBank, setSelectedBank] = React.useState<Bank | null>(null);
  const [isResetSelectedBank, setIsResetSelectedBank] = React.useState(false);
  const [selectedCountry, setSelectedCountry] = React.useState<Country | null>(null);
  const [apiErrorMessage, setApiErrorMessage] = React.useState<string | null>(null);
  const [isSubmitLoading, setIsSubmitLoading] = React.useState(false);
  const { banks, noMatchesBankText, isBanksLoading } = useBankList(
    selectedCurrency?.currencyCode || null,
  );
  const postAddNewAccount = useAddPayoutAccount();
  const countryList = useCountryList();

  const handleCurrencySelect = React.useCallback(
    (func: (value: string) => void) => (currency: FiatOffRampCurrency) => {
      func(currency?.currencyCode);
      setSelectedCurrency(currency);
      setIsResetSelectedBank(true);
    },
    [],
  );
  const handleBankSelect = React.useCallback(
    (func: (value: string) => void) => (bank: Bank) => {
      func(bank?.paymentCode);
      setSelectedBank(bank);
      setIsResetSelectedBank(false);
    },
    [],
  );
  const handleCountrySelect = React.useCallback(
    (func: (value: string) => void) => (country: Country) => {
      func(country?.code);
      setSelectedCountry(country);
    },
    [],
  );
  const handleBack = () => {
    if (activeStep === 1 && onBack) {
      onBack();
    } else {
      setActiveStep(1);
    }
  };
  const handleFormSubmitSecondStep: SubmitHandler<FieldValues> = async (formData) => {
    setIsSubmitLoading(true);
    onUpdate(false);
    const { data, status, errorMessage }: {
      data: any, status: number, errorMessage: string
    } = await postAddNewAccount({
      currency: formData?.fiatCurrency,
      paymentCode: formData?.paymentCode,
      accountNumber: formData?.accountNumber,
      provider: 'transfi',
      countryCode: formData?.countryCode,
      iban: formData?.iban || null,
      swiftBic: formData?.swiftBic || null,
    });
    setIsSubmitLoading(false);
    if (status === 200 && data) {
      onUpdate(true);
      closePopup();
    } else {
      const currentErrorMessage = getSafeErrorMessageText(data?.errorData?.message);
      setApiErrorMessage(currentErrorMessage);
      consoleErrorMessage(errorMessage, currentErrorMessage, '/fiat-payouts/account');
    }
  };
  const handleFormSubmitFirstStep: SubmitHandler<FieldValues> = async () => {
    const isValid = await trigger(['fiatCurrency', 'paymentCode', 'country']);
    if (isValid) {
      switch (selectedCurrency?.currencyCode) {
        case 'EUR':
          setValidationSchemaSecondStep(ADD_ACCOUNT_SCHEMA_SECOND_STEP_EURO);
          break;
        case 'INR':
          setValidationSchemaSecondStep(ADD_ACCOUNT_SCHEMA_SECOND_STEP_INR);
          break;
        default:
          setValidationSchemaSecondStep(ADD_ACCOUNT_SCHEMA_SECOND_STEP_OTHER);
      }
      setActiveStep(2);
    }
  };

  return (
    <form
      className={classes.addNewAccountForm}
      onSubmit={handleSubmit(activeStep === 1
        ? handleFormSubmitFirstStep
        : handleFormSubmitSecondStep)}
    >
      <div className={classes.addNewAccountFormHeader}>
        {hasBackArrow && (
          <button
            className={classes.addNewAccountFormHeaderButton}
            type="button"
            onClick={handleBack}
          >
            <span
              className={classes.addNewAccountFormHeaderBackArrow}
            />
          </button>
        )}
        <h3 className={classes.addNewAccountFormTitle}>
          Add account
        </h3>
      </div>
      {activeStep === 1 && (
        <div className={classes.addNewAccountFormContent}>
          <div className={classes.addNewAccountFormInputsSection}>
            <Controller
              name="fiatCurrency"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <CustomSelectField
                  className={classes.addNewAccountFormSelect}
                  label="Fiat currency"
                  placeholderText="Choose fiat currency"
                  items={fiatCurrencies as Array<Item>}
                  onSelect={handleCurrencySelect(field.onChange)}
                  error={fiatCurrencyErrorMessage}
                  defaultValue={selectedCurrency}
                  hasSearch
                />
              )}
            />
            <Controller
              name="paymentCode"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <CustomSelectField
                  className={classes.addNewAccountFormSelect}
                  label="Bank name"
                  placeholderText="Choose payment code"
                  items={banks as Array<Item>}
                  onSelect={handleBankSelect(field.onChange)}
                  reset={isResetSelectedBank}
                  error={paymentCodeErrorMessage}
                  noMatchesText={noMatchesBankText}
                  defaultValue={selectedBank}
                  isLoading={isBanksLoading}
                  searchPlaceholder="Type a payment code"
                  hasSearch
                />
              )}
            />
            <Controller
              name="countryCode"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <CustomSelectField
                  className={classes.addNewAccountFormSelect}
                  label="Country"
                  placeholderText="Please enter your country"
                  items={countryList as Array<Item>}
                  onSelect={handleCountrySelect(field.onChange)}
                  error={countryErrorMessage}
                  defaultValue={selectedCountry}
                  searchPlaceholder="Type a country"
                  hasSearch
                />
              )}
            />
          </div>
          <div className={classes.addNewAccountFormSubmitSection}>
            <Button
              type="submit"
              className={classes.addNewAccountFormButton}
            >
              Next
            </Button>
          </div>
        </div>
      )}
      {activeStep === 2 && (
        <div className={classes.addNewAccountFormContent}>
          <div className={classes.addNewAccountFormInputsSection}>
            <button
              className={hasBackArrow
                ? classes.addNewAccountFormBackInfo
                : classes.addNewAccountFormBackButton}
              type="button"
              onClick={handleBack}
            >
              {!hasBackArrow && <span className={classes.addNewAccountFormBackArrow} />}
              <img
                src={selectedCurrency?.logoUrl}
                alt={selectedCurrency?.currencyCode}
              />
              <p className={classes.addNewAccountFormBackCurrency}>
                {selectedCurrency?.currencyCode}
              </p>
            </button>
            {selectedCurrency?.currencyCode === 'EUR' && (
              <Controller
                name="iban"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Field
                    className={classes.addNewAccountFormInput}
                    title="IBAN"
                    data={{
                      id: field.name,
                      value: field.value,
                      name: field.name,
                      onChange: field.onChange,
                      placeholder: 'Please enter your IBAN',
                      error: ibanErrorMessage,
                    }}
                  />
                )}
              />
            )}
            {selectedCurrency?.currencyCode === 'INR' && (
              <Controller
                name="swiftBic"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Field
                    className={classes.addNewAccountFormInput}
                    title="SWIFT/BIC"
                    data={{
                      id: field.name,
                      value: field.value,
                      name: field.name,
                      onChange: field.onChange,
                      placeholder: 'Please enter your SWIFT/BIC Code',
                      error: swiftBicErrorMessage,
                    }}
                  />
                )}
              />
            )}
            <Controller
              name="accountNumber"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Field
                  className={classes.addNewAccountFormInput}
                  title="Account number"
                  data={{
                    id: field.name,
                    value: field.value,
                    name: field.name,
                    onChange: field.onChange,
                    placeholder: 'Please enter your account number',
                    error: accountNumberErrorMessage,
                  }}
                />
              )}
            />
          </div>
          <div className={classes.addNewAccountFormSubmitSection}>
            {apiErrorMessage
              ? (
                <p className={classes.addNewAccountFormError}>
                  {apiErrorMessage}
                </p>
              )
              : (
                <Controller
                  name="processingConsent"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <CheckboxWithText
                      className={classes.addNewAccountFormCheckbox}
                      id={field.name}
                      onChange={field.onChange}
                      error={!!processingConsent}
                    >
                      I consent that my payment data may be shared with third party
                      service solely for proper service functioning purposes.
                    </CheckboxWithText>
                  )}
                />
              )}
            <Button
              type="submit"
              className={classes.addNewAccountFormButton}
            >
              {isSubmitLoading ? <LoaderIcon /> : 'Confirm'}
            </Button>
          </div>
        </div>
      )}
    </form>
  );
};
