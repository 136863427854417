import * as React from 'react';
import cn from 'classnames';
import { TabsType } from '../../types';
import { StatisticsTabCalendarIcon } from '../../../icons/statistics-tab-calendar-icon';
import classes from './styles.module.scss';

export const Tabs: React.FC<TabsType> = (props) => {
  const {
    className = null,
    items,
    activeTabType,
    onClick,
  } = props;

  const handleTabClick = (type: string) => () => {
    onClick(type);
  };

  return (
    <div className={cn(classes.tabs, className)}>
      {items.map((item) => (
        <button
          key={item.id}
          className={cn([
            classes.tab,
            activeTabType === item.type && classes.tabActive,
          ])}
          type="button"
          onClick={handleTabClick(item.type)}
        >
          {!item.isCalendarIcon
            ? item.name
            : <StatisticsTabCalendarIcon color={activeTabType === item.type ? '#ffffff' : undefined} />}
        </button>
      ))}
    </div>
  );
};
