import React from 'react';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';

const mapFn = (data: { is_auto_push: boolean }) => ({
  isAutoPush: data?.is_auto_push ?? false,
});

export const useUpdateAutoProcessing = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (partnerId: string, isAutoPush: boolean) => {
    const [cancelToken] = getRequestSource();
    const requestPromise = client.put(
      `/profile/${partnerId}/update-auto-push`,
      { is_auto_push: isAutoPush },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
