import { isObject } from './is-object';

/**
 * @param {Object} balancesObject
 * @returns {{ticker, amount, pendingAmount}[]|*[]}
 */
export const getSubPartnerBalancesConvertedToArray = (balancesObject) => {
  if (!isObject(balancesObject)) {
    return [];
  }

  return Object.entries(balancesObject).map((item) => ({
    ticker: item[0] || null,
    amount: item[1]?.amount ?? null,
    pendingAmount: item[1]?.pendingAmount ?? null,
  }));
};
