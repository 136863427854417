import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { DayPicker } from 'react-day-picker';
import { isValid, startOfDay, endOfDay } from 'date-fns';
import { isFunction } from '../../helpers/is-function';
import classes from './styles.module.scss';

export const RangeDatepicker = (props) => {
  const {
    from,
    to,
    onChange,
    className,
  } = props;

  const range = { from, to };

  const handleDayClick = (nextRange) => {
    const nextFrom = isValid(startOfDay(nextRange?.from))
      ? startOfDay(nextRange?.from)
      : null;
    const nextTo = isValid(endOfDay(nextRange?.to))
      ? endOfDay(nextRange?.to)
      : null;

    if (isFunction(onChange) && (nextFrom || nextTo)) {
      onChange({
        from: nextFrom,
        to: nextTo,
      });
    }
  };

  return (
    <DayPicker
      className={cn([
        classes.datepicker,
        className,
      ])}
      mode="range"
      numberOfMonths={1}
      disabled={{ after: new Date() }}
      selected={range}
      onSelect={handleDayClick}
      modifiersClassNames={{
        selected: classes.selected,
        today: classes.today,
        disabled: classes.disabled,
        range_start: classes.rangeStart,
        range_end: classes.rangeEnd,
      }}
    />
  );
};

RangeDatepicker.defaultProps = {
  className: null,
  from: null,
  to: null,
  onChange: null,
};

RangeDatepicker.propTypes = {
  className: PropTypes.string,
  from: PropTypes.instanceOf(Date),
  to: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
};
