import React from 'react';
import cn from 'classnames';
import { WhitelistingSettings } from '../../../whitelisting-settings';

export const AddressesSection = (props) => {
  const {
    className,
    classNameActive,
  } = props;

  const [isRendered, setIsRendered] = React.useState(false);

  React.useEffect(() => {
    setIsRendered(true);
  }, []);

  return <WhitelistingSettings className={cn(className, isRendered && classNameActive)} />;
};
