import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initial-state';

export const SUBSCRIPTIONS_REDUCER_NAME = 'subscriptions';

const setPlansState = (state, { payload }) => {
  state.plans = payload;
};
const setCountState = (state, { payload }) => {
  state.count = payload;
};
const setFetchingState = (state, { payload }) => {
  state.fetching = payload;
};
const setFetchedState = (state, { payload }) => {
  state.fetched = payload;
};
const setErrorState = (state, { payload }) => {
  state.error = payload;
};

export const subscriptionsSlice = createSlice({
  name: SUBSCRIPTIONS_REDUCER_NAME,
  initialState,
  reducers: {
    setSubscriptionsPlans: setPlansState,
    setSubscriptionsPlansCount: setCountState,
    setSubscriptionsFetching: setFetchingState,
    setSubscriptionsFetched: setFetchedState,
    setSubscriptionsError: setErrorState,
  },
});

export const {
  setSubscriptionsPlans,
  setSubscriptionsPlansCount,
  setSubscriptionsFetching,
  setSubscriptionsFetched,
  setSubscriptionsError,
} = subscriptionsSlice.actions;
export const subscriptionsReducer = subscriptionsSlice.reducer;
