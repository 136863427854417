import * as React from 'react';
import { useInvoices } from '../../../api/modules/account/use-invoices';
import { mapInvoiceFiltersToParameters } from '../utils';
import { areObjectsShallowEqual } from '../../../helpers/are-objects-shalow-equal';
import { SEARCH_SOURCE } from '../../../constants/app-constants';

export const useInvoicesState = () => {
  const fetchInvoicesRequestSend = useInvoices();
  const [isInvoicesLoading, setIsInvoicesLoading] = React.useState(false);
  const [invoices, setInvoices] = React.useState([]);
  const [invoicesTotalCount, setInvoicesTotalCount] = React.useState(0);
  const [invoicesPageState, setInvoicesPageState] = React.useState(1);
  const invoicesFiltersRef = React.useRef(null);

  const loadInvoices = async (page, filtersState, getCount = false, isIdOrCondition = false) => {
    setIsInvoicesLoading(true);
    const { invoices, totalCount } = await fetchInvoices(page, {
      ...filtersState,
      isIdOrCondition,
    }, getCount);

    setInvoices(invoices);
    if (totalCount !== undefined) {
      setInvoicesTotalCount(totalCount);
    }

    setIsInvoicesLoading(false);
  };

  const reloadInvoices = React.useCallback(() => {
    void loadInvoices(invoicesPageState, invoicesFiltersRef.current, true);
  }, [invoicesPageState]);

  React.useEffect(function initialLoad() {
    void loadInvoices(invoicesPageState, invoicesFiltersRef.current, true);
  }, []);

  const setInvoicesFilters = React.useCallback((newFiltersState = null, filterSource = null) => {
    if (areObjectsShallowEqual(newFiltersState, invoicesFiltersRef.current)) {
      return;
    }

    setInvoicesPageState(1);
    const filtersHasKeys = newFiltersState !== null && Object.keys(newFiltersState).length > 0;
    const isIdOrCondition = filtersHasKeys && filterSource === SEARCH_SOURCE.SEARCH_FIELD;
    invoicesFiltersRef.current = filtersHasKeys ? newFiltersState : null;
    loadInvoices(1, newFiltersState, true, isIdOrCondition);
  }, [setInvoicesPageState, loadInvoices]);

  const setInvoicesPage = React.useCallback((pageNumber) => {
    if (!pageNumber || !Number(pageNumber)) {
      return;
    }

    setInvoicesPageState(pageNumber);
    loadInvoices(pageNumber, invoicesFiltersRef.current, false);
  }, []);

  const fetchInvoices = async (pageToFetch = 1, filters = {}, getCount = false) => {
    const filterParameters = mapInvoiceFiltersToParameters(filters);
    const { data, status } = await fetchInvoicesRequestSend({
      page: pageToFetch,
      get_count: getCount,
      ...filterParameters,
    });

    if (status !== 200) {
      return {
        invoices: [],
      };
    }

    return {
      invoices: data?.invoices ?? [],
      totalCount: data?.totalCount,
    };
  };

  const isInvoiceFilterSet = invoicesFiltersRef.current !== null;

  return {
    invoices,
    invoicesTotalCount,
    isInvoiceFilterSet,
    invoicesPage: invoicesPageState,
    isInvoicesLoading,
    setInvoicesPage,
    setInvoicesFilters,
    reloadInvoices,
  };
};
