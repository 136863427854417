import React from 'react';
import { nanoid } from "nanoid";
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';

const bankFieldMapFn = (data) => ({
  name: data?.name ?? null,
  type: data?.type ?? null,
  mandatory: data?.mandatory ?? null,
  description: data?.description ?? null,
});

const bankMapFn = (data) => ({
  id: nanoid(),
  name: data?.name ?? null,
  paymentCode: data?.paymentCode ?? null,
  fields: (Array.isArray(data?.fields) ? data.fields : []).map(bankFieldMapFn),
  provider: data?.provider ?? null,
});

const mapFn = (data) => ({
  result: (data?.result ?? []).map(bankMapFn),
});

export const useBanks = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (params) => {
    const [cancelToken] = getRequestSource();
    const requestPromise = client.get(
      '/fiat-payouts/payment-methods',
      { cancelToken, params },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
