import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import classes from './styles.module.scss';

export const Section = (props) => {
  const {
    className,
    title,
    rightElement,
    children,
  } = props;

  return (
    <div className={cn(classes.section, className)}>
      <div className={classes.sectionHeader}>
        {title && (
          <h2 className={classes.sectionTitle}>
            {title}
          </h2>
        )}
        {rightElement}
      </div>
      <div className={classes.sectionContent}>
        {children}
      </div>
    </div>
  );
};

Section.defaultProps = {
  className: null,
  title: null,
  rightElement: null,
  children: null,
};

Section.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  rightElement: PropTypes.node,
  children: PropTypes.node,
};
