import React from 'react';
import cn from 'classnames';
import classes from './styles.module.scss';
import { OPTIONS_OBJECT } from '../../helpers';
import { PopupData } from '../../types';

type PayoutWalletsOptionProps = {
  type: string,
  isDisable: boolean,
  text: string | null,
  onClick: (type: PopupData) => void,
  onSetAsMain: () => Promise<void>,
};

export const PayoutWalletsOption: React.FC<PayoutWalletsOptionProps> = (props) => {
  const {
    type,
    isDisable,
    text,
    onClick,
    onSetAsMain,
  } = props;

  const option = OPTIONS_OBJECT[type as keyof typeof OPTIONS_OBJECT];

  const handleClick = () => {
    if (isDisable) return;

    if (option && option.popup) {
      onClick(option.popup);
    } else if (option && option.isSetAsMAinOption) {
      onSetAsMain();
    }
  };

  return (
    <button
      className={cn([
        classes.payoutWalletsOption,
        isDisable && classes.payoutWalletsOptionDisable,
      ])}
      type="button"
      onClick={handleClick}
    >
      <img
        src={option.image}
        alt="Icon"
        decoding="async"
        loading="lazy"
        width={20}
        height={20}
      />
      {text || option.text}
    </button>
  );
};
