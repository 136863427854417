import { CURRENCY_USD } from '../../constants/app-constants';
import { MARKET_INFO_REDUCER_NAME } from './reducer';

export const marketInfoFromCurrencySelector = () => (state) => (
  state[MARKET_INFO_REDUCER_NAME]?.fromCurrency ?? CURRENCY_USD
);

export const marketInfoRatesSelector = () => (state) => (
  state[MARKET_INFO_REDUCER_NAME]?.result
);

export const marketInfoUnprocessedRatesSelector = () => (state) => (
  state[MARKET_INFO_REDUCER_NAME]?.unprocessed
);

export const marketInfoFetchingSelector = () => (state) => (
  state[MARKET_INFO_REDUCER_NAME]?.fetching
);

export const marketInfoFetchedSelector = () => (state) => (
  state[MARKET_INFO_REDUCER_NAME]?.fetched
);

export const marketInfoErrorSelector = () => (state) => (
  state[MARKET_INFO_REDUCER_NAME]?.error
);
