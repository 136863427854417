import React from 'react';
import PropTypes from 'prop-types';

export const ApiAddressesIcon = (props) => {
  const { color } = props;

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.44445 5.25316H3.57553C3.08706 5.25316 2.68945 5.65056 2.68945 6.13924V12.3418C2.68945 12.8304 3.08706 13.2278 3.57553 13.2278H6.67778C6.66848 13.5813 6.60098 14.1466 6.34754 14.4135C6.25452 14.5113 6.14809 14.557 6.01223 14.557C5.98313 14.5569 5.95432 14.5626 5.92743 14.5738C5.90055 14.5849 5.87612 14.6012 5.85554 14.6218C5.83497 14.6424 5.81865 14.6668 5.80753 14.6937C5.7964 14.7206 5.79068 14.7494 5.7907 14.7785C5.79068 14.8076 5.7964 14.8364 5.80753 14.8633C5.81865 14.8902 5.83497 14.9146 5.85554 14.9352C5.87612 14.9557 5.90055 14.9721 5.92743 14.9832C5.95432 14.9943 5.98313 15 6.01223 15H9.99956C10.0287 15 10.0575 14.9943 10.0844 14.9832C10.1112 14.9721 10.1357 14.9557 10.1562 14.9352C10.1768 14.9146 10.1931 14.8902 10.2043 14.8633C10.2154 14.8364 10.2211 14.8076 10.2211 14.7785C10.2211 14.7494 10.2154 14.7206 10.2043 14.6937C10.1931 14.6668 10.1768 14.6424 10.1562 14.6218C10.1357 14.6012 10.1112 14.5849 10.0844 14.5738C10.0575 14.5626 10.0287 14.5569 9.99956 14.557C9.8637 14.557 9.75727 14.5115 9.66468 14.4137C9.41186 14.1481 9.34393 13.582 9.33428 13.2278H12.4363C12.9247 13.2278 13.3224 12.8305 13.3224 12.3418V6.13924C13.3223 5.65056 12.9247 5.25316 12.4363 5.25316H11.5674C11.4869 5.5442 11.3795 5.84139 11.2524 6.13924H12.4363L12.4366 11.4557H3.57553V6.13924H4.7594C4.63234 5.84139 4.52486 5.5442 4.44445 5.25316ZM7.56155 12.3417C7.56155 12.5866 7.761 12.7861 8.00589 12.7861C8.25078 12.7861 8.45023 12.5866 8.45023 12.3417C8.45023 12.0969 8.25078 11.8974 8.00589 11.8974C7.761 11.8974 7.56155 12.0969 7.56155 12.3417Z" />
      <path d="M8.0059 1C6.247 1 4.81603 2.43097 4.81603 4.18985C4.81603 6.37269 7.67065 9.57721 7.79219 9.71256C7.90635 9.83971 8.10566 9.83949 8.21961 9.71256C8.34115 9.57721 11.1958 6.37269 11.1958 4.18985C11.1957 2.43097 9.76479 1 8.0059 1ZM8.0059 5.79476C7.12095 5.79476 6.40102 5.0748 6.40102 4.18985C6.40102 3.3049 7.12097 2.58497 8.0059 2.58497C8.89083 2.58497 9.61077 3.30492 9.61077 4.18987C9.61077 5.07482 8.89083 5.79476 8.0059 5.79476Z" />
    </svg>
  );
};

ApiAddressesIcon.defaultProps = {
  color: '#A4A4A4',
};

ApiAddressesIcon.propTypes = {
  color: PropTypes.string,
};
