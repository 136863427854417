import { startOfDay, endOfDay } from 'date-fns';
import {
  STATISTICS_GRAPH_PERIOD_TABS,
  STATISTICS_GRAPH_TABS, TYPES,
} from '../../components/statistics-graph/helpers';
import { calculateDate } from '../../helpers/calculate-date';

const DEFAULT_DATE = new Date();

export const initialState = {
  balance: {
    amount: null,
    currency: null,
  },
  countOfPayouts: null,
  turnover: {
    amount: null,
    ticker: null,
    count: null,
    isLoaded: false,
    isFetching: false,
    errorMessage: null,
  },
  statsTurnover: {},
  statsCoins: [],
  activeStatsType: STATISTICS_GRAPH_TABS[0].type,
  activePeriodType: STATISTICS_GRAPH_PERIOD_TABS[0].type,
  period: {
    startDate: calculateDate(startOfDay(DEFAULT_DATE), 7, TYPES.DAY, true).toISOString(),
    endDate: endOfDay(DEFAULT_DATE).toISOString(),
  },
  lastTransactions: [],
  news: [],
  industry: null,
  isLoaded: false,
  isFetching: false,
  errorMessage: null,
};
