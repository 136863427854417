import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Button } from '../../../ui/button';
import styles from '../../styles.module.scss';

const CreateInvoiceButton = ({
  disabled,
  onClick,
}) => {
  return (
    <Button
      className={styles.searchBoxButton}
      disabled={disabled}
      onClick={onClick}
    >
      Create payment link
    </Button>
  );
};

CreateInvoiceButton.defaultProps = {
  disabled: false,
  onClick: undefined,
};

CreateInvoiceButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export const CreateInvoiceButtonMemo = React.memo(CreateInvoiceButton);
