import * as React from 'react';
import PropTypes from 'prop-types';
import { InfoAboutUse } from '../../../shared/info-about-use';

export const PaymentLinkInfoAboutUse = (props) => {
  const {
    className,
    name,
    onClose,
  } = props;

  return (
    <InfoAboutUse
      className={className}
      name={name}
      onClose={onClose}
    >
      Here you can create a Payment link (also known as Invoice), share it with your
      customers, and see all the information about the Payment links created previously.
      <div>
        <strong>How to Use:</strong>
        <ul>
          <li>Press “Create payment link”</li>
          <li>Enter the details of your payment link and click “Confirm”</li>
          <li>Send the link to your customer.</li>
        </ul>
      </div>
    </InfoAboutUse>
  );
};

PaymentLinkInfoAboutUse.defaultProps = {
  className: null,
  name: null,
  onClose: null,
};

PaymentLinkInfoAboutUse.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onClose: PropTypes.func,
};
