import React from 'react';
import PropTypes from 'prop-types';

export const CustodyTopUpIcon = (props) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0356 15.0153C11.0356 15.1772 11.0805 15.3326 11.1634 15.4529C11.2457 15.5725 11.3796 15.6737 11.5514 15.6737C11.7232 15.6737 11.857 15.5725 11.9394 15.453C12.0223 15.3326 12.0672 15.1773 12.0672 15.0155L12.0672 6.45834C12.0672 6.29644 12.0223 6.14112 11.9394 6.02075C11.8571 5.90123 11.7232 5.8 11.5514 5.8C11.3796 5.8 11.2457 5.90123 11.1634 6.02075C11.0805 6.14112 11.0356 6.29644 11.0356 6.45834L11.0356 15.0153Z"
        fill="#64ACFF"
        stroke="#64ACFF"
        strokeWidth="0.4"
      />
      <path
        d="M11.0605 15.8498C10.8081 15.5973 10.8081 15.1883 11.0605 14.9359L13.0921 12.9042C13.3445 12.6517 13.7535 12.6517 14.006 12.9042C14.2584 13.1566 14.2584 13.5656 14.006 13.818L11.9742 15.8498C11.722 16.102 11.3128 16.102 11.0605 15.8498Z"
        fill="#64ACFF"
      />
      <path
        d="M8.99998 12.9042C9.25241 12.6517 9.66139 12.6517 9.91381 12.9042L11.9454 14.9358C12.1979 15.1882 12.1979 15.5972 11.9454 15.8496C11.693 16.102 11.284 16.102 11.0316 15.8496L8.99998 13.818C8.74756 13.5656 8.74756 13.1566 8.99998 12.9042Z"
        fill="#64ACFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.98851 16.9688C6.78805 16.9688 6.58797 17.0204 6.42575 17.1252C6.26756 17.2274 6.09961 17.416 6.09961 17.6845C6.09961 17.953 6.26755 18.1417 6.4257 18.2438C6.58789 18.3486 6.78793 18.4003 6.98836 18.4003L16.116 18.4003C16.3164 18.4003 16.5165 18.3486 16.6787 18.2439C16.8369 18.1417 17.0049 17.953 17.0049 17.6845C17.0049 17.416 16.8369 17.2274 16.6787 17.1252C16.5165 17.0204 16.3164 16.9688 16.116 16.9688H6.98851Z"
        fill="#64ACFF"
      />
    </svg>
  );
};

CustodyTopUpIcon.defaultProps = {
  className: null,
};

CustodyTopUpIcon.propTypes = {
  className: PropTypes.string,
};
