import { hasElementsInArray } from './has-elements-in-array';

/**
 * @param {Array<object>} array
 * @returns {*|Array<object>}
 */
export const getArrayWithIndexId = (array) => {
  if (!hasElementsInArray(array)) {
    return array;
  }

  return array.map((item, index) => ({ ...item, id: String(index) }));
};
