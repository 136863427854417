import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import ValidatorWrapper, { ValidatorField } from '@coxy/react-validator';
import ReCAPTCHA from "react-google-recaptcha";
import classNames from 'classnames';
import { GoogleOAuthProvider } from '@react-oauth/google';
import config from '../../config';
import {
  removeItemFromLocalStorage,
  setAuthToken,
  setItemToLocalStorage,
} from '../../libs/local-storage';
import { setAuthCookieToken } from '../../libs/cookies';
import {
  emailRules,
  passwordRules,
  captchaRules,
} from '../../libs/validators';
import Button from "../../components/button";
import Checkbox from '../../components/ui/checkbox';
import WithdrawalInput from '../../components/withdrawal-input';
import passwordShowIcon from '../../images/svg/password-show.svg';
import Metatags from '../../components/metatags';
import {
  ENABLE_CUSTODY_LOCAL_STORAGE_NAME,
  PAGE_NAMES_TO_DISPLAY_INFO_LOCAL_STORAGE_NAME,
  PAGE_NAMES_TO_DISPLAY_INFO_SHOW,
} from '../../constants/app-constants';
import { sendUtmMetrics } from '../../helpers/send-utm-metrics';
import { GoogleAuthButton } from '../../components/google-auth-button';
import { useRegisterWithGoogle } from '../../api/modules/account/use-register-with-google';
import { getSafeErrorMessageText } from '../../helpers/get-safe-error-message-text';
import { ROUTES } from '../../constants/routes';
import { trackEvent } from '../../helpers/utils/track-event';
import { TRACK_EVENT_NAMES } from '../../constants/track-event-names';
import { useCreateRegistration } from '../../api/modules/account/use-create-registration';
import { consoleErrorMessage } from '../../helpers/console-error-message';
import styles from './styles.module.scss';

const QUERY_PARAMS_NAME = 'queryParams';

const CreateAccount = () => {
  const history = useHistory();
  const createRegistration = useCreateRegistration();
  const fetchRegisterWithGoogle = useRegisterWithGoogle();

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [passwordType, setPasswordType] = React.useState('password');
  const [passwordConfirm, setPasswordConfirm] = React.useState('');
  const [confirmPasswordType, setConfirmPasswordType] = React.useState('password');
  const [captcha, setCaptcha] = React.useState('');
  const [linkId, setLinkId] = React.useState(undefined);
  const [isSubsChecked, setIsSubsChecked] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [submitButtonPressed, setSubmitButtonPressed] = React.useState(false);
  const [serverError, setServerError] = React.useState('Error');
  const [serverErrorOnTopInput, setServerErrorOnTopInput] = React.useState('');
  const [isServerErrorShown, setIsServerErrorShown] = React.useState(false);
  const [isPending, setIsPending] = React.useState(false);
  const [registerGoogleApiError, setRegisterGoogleApiError] = React.useState(null);

  const validator = React.useRef();
  const captchaRef = React.useRef();

  const handleCreateSuccess = (token, email, isGoogleAuth = false, isEmailActivated = false) => {
    const queryParams = new URLSearchParams(window.location.search);
    const queryParamsString = queryParams.toString();
    const queryParamsNormalized = queryParamsString.split('&').join('  ');
    const utmEventCategory = queryParams.get('utm_category');

    const trackEventData = {
      action: 'submit',
      category: utmEventCategory,
    };
    if (linkId) {
      trackEventData.affiliate_from = linkId;
    }
    trackEvent(TRACK_EVENT_NAMES.SIGN_UP_REGISTERED, trackEventData);

    removeItemFromLocalStorage(ENABLE_CUSTODY_LOCAL_STORAGE_NAME);
    setAuthToken(token);
    setAuthCookieToken(token);

    const pageNamesToDisplayInfoString = JSON.stringify(PAGE_NAMES_TO_DISPLAY_INFO_SHOW);
    setItemToLocalStorage(PAGE_NAMES_TO_DISPLAY_INFO_LOCAL_STORAGE_NAME, pageNamesToDisplayInfoString);

    if (!linkId && queryParamsNormalized) {
      void sendUtmMetrics(email, queryParamsNormalized);
    }

    if (isGoogleAuth) {
      sessionStorage.setItem('GOOGLE_AUTH_EMAIL', email);
    }

    if (isGoogleAuth && isEmailActivated) {
      const hasQueryParamsInSessionStorage = sessionStorage.getItem(QUERY_PARAMS_NAME);
      if (queryParamsString) {
        sessionStorage.setItem(QUERY_PARAMS_NAME, queryParamsString);
      }
      if (!queryParamsString && hasQueryParamsInSessionStorage) {
        sessionStorage.removeItem(QUERY_PARAMS_NAME);
      }
      window.location.pathname = ROUTES.WALLET_ADDITION;
    } else {
      const queryParamsForRout = queryParamsString ? `?${queryParamsString}` : '';
      history.push(`/email-verification${queryParamsForRout}`);
    }
  };
  const handleGoogleAuthSubmit = async ({ accessToken, email }) => {
    setIsPending(true);
    const { data, status } = await fetchRegisterWithGoogle({
      accessToken,
      linkId,
      isAgreeSubs: isSubsChecked,
    });

    if (status === 200) {
      const token = data?.token ?? null;
      const isEmailActivated = data?.emailIsActivated ?? false;
      handleCreateSuccess(token, email, true, isEmailActivated);
    } else {
      const errorMessage = getSafeErrorMessageText(data?.errorData?.message);
      setRegisterGoogleApiError(errorMessage);
    }
    setIsPending(false);
  };
  const handleCreateClick = async () => {
    setIsServerErrorShown(false);
    setSubmitButtonPressed(true);
    const { isValid } = validator.current.validate();

    if (!isValid || passwordConfirm !== password) {
      return;
    }

    setIsLoading(true);
    const { data, status, errorMessage } = await createRegistration({
      email,
      password,
      linkId,
      captchaToken: captcha,
      isAgreeSubs: isSubsChecked,
    });

    if (status === 200) {
      const { token } = data;
      handleCreateSuccess(token, email, false, false);
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/register');
      if (errorDataMessage) {
        setServerErrorOnTopInput(errorDataMessage);
      } else {
        setServerError('Error');
        setIsServerErrorShown(true);
      }
    }
    captchaRef.current.reset();
    setCaptcha('');
    setSubmitButtonPressed(false);
    setIsLoading(false);
  };

  const clearErrorState = () => {
    setSubmitButtonPressed(false);
    setIsServerErrorShown(false);
    setServerErrorOnTopInput('');
  };

  const changePasswordType = () => {
    if (passwordType === 'password') setPasswordType('text');
    else setPasswordType('password');
  };

  const changeConfirmPasswordType = () => {
    if (confirmPasswordType === 'password') setConfirmPasswordType('text');
    else setConfirmPasswordType('password');
  };

  React.useEffect(() => {
    const linkIdFromSearchParams = new URLSearchParams(window.location.search).get('link_id');

    if (linkIdFromSearchParams) {
      setLinkId(linkIdFromSearchParams);
    }
  }, []);

  React.useEffect(() => {
    let timerId = null;
    if (registerGoogleApiError) {
      timerId = setTimeout(() => {
        setRegisterGoogleApiError(null);
      }, 4000);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [registerGoogleApiError]);

  return (
    <div className={styles.formContainer}>
      <Metatags
        title={'Register with the NOWPayments'}
        description={'Sign up for a NOWPayments account to accept cryptocurrency as a payment on your website. Enter the email address and password to use our gateway.'}
        url={'https://account.nowpayments.io/create-account'}
      />
      <div className={styles.form}>
        {(isServerErrorShown && submitButtonPressed) && (
          <div className={styles.serverError}>
            <span>{serverError}</span>
          </div>
        )}
        <div className={styles.formHeader}>
          <p className={styles.logo}>
            <span className={classNames(styles.logo, styles.blueText)}>NOW</span>
            Payments
          </p>
          <p className={styles.stepsCount}>Step 1 of 3</p>
        </div>
        <div className={styles.divider} />
        <div style={{ margin: '15px 0px' }}>
          <p className={styles.titleBig}>Create an account</p>
        </div>
        <ValidatorWrapper ref={validator}>
          <ValidatorField value={email} rules={emailRules()}>
            {({ isValid, message }) => (
              <div className={styles.inputContainer}>
                <WithdrawalInput
                  label="E-mail"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    clearErrorState();
                  }}
                  error={((!isValid && submitButtonPressed))}
                  errorMessage={(!isValid && submitButtonPressed && message)
                    || (serverErrorOnTopInput.length > 1 && serverErrorOnTopInput)}
                  placeholder={'john.doe@gmail.com'}
                />
              </div>
            )}
          </ValidatorField>
          <ValidatorField value={password} rules={passwordRules()}>
            {({ isValid, message }) => (
              <div className={styles.inputContainer}>
                <WithdrawalInput
                  type={passwordType}
                  label="Password"
                  value={password}
                  info="Password should have 1 uppercase, 1 number, and more than 6 characters in total"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    clearErrorState();
                  }}
                  error={((!isValid && submitButtonPressed))}
                  errorMessage={!isValid && submitButtonPressed && message}
                  placeholder={'1 uppercase, 1 number, >6 characters'}
                />
                <div className={styles.passwordEye} onClick={changePasswordType}>
                  <img src={passwordShowIcon} alt="password eye" width={14} height={11} />
                </div>
              </div>
            )}
          </ValidatorField>
          <div className={styles.inputContainer}>
            <WithdrawalInput
              type={confirmPasswordType}
              label="Confirm Password"
              value={passwordConfirm}
              onChange={(e) => {
                setPasswordConfirm(e.target.value);
                clearErrorState();
              }}
              error={(password !== passwordConfirm) && submitButtonPressed}
              errorMessage={(password !== passwordConfirm) && submitButtonPressed && ("Passwords don't match")}
              placeholder={'1 uppercase, 1 number, >6 characters'}
            />
            <div className={styles.passwordEye} onClick={changeConfirmPasswordType}>
              <img src={passwordShowIcon} alt="password eye" width={14} height={11} />
            </div>
          </div>
          <div className={styles.checkboxRow}>
            <Checkbox
              checked={isSubsChecked}
              onChange={() => {
                setIsSubsChecked(!isSubsChecked);
                clearErrorState();
              }}
            />
            <span>Subscribe to the newsletter</span>
          </div>
          <ValidatorField value={captcha} rules={captchaRules()}>
            {({ isValid, message }) => (
              <div className={styles.reCaptchaContainer}>
                {!isValid && submitButtonPressed && <div className={styles.error}>{message}</div>}
                <ReCAPTCHA
                  sitekey={config.captcha_key}
                  onChange={async(token) => {
                    setCaptcha(token);
                    clearErrorState();
                  }}
                  onExpired={async() => {
                    setCaptcha('');
                    clearErrorState();
                  }}
                  ref={captchaRef}
                  hl="en"
                />
              </div>
            )}
          </ValidatorField>
          <div className={styles.agreements}>
            By creating an account you agree with
            {' '}
            <a
              href={`${config.front_domain}/doc/AML_KYC_Policy_NOWPayments-v1_3_1.pdf`}
              target="_blank"
              rel="noopener noreferrer"
            >
              AML/KYC&nbsp;Policy
            </a>,
            {' '}
            <a
              href={`${config.front_domain}/doc/tos.pdf`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms&nbsp;of&nbsp;Service
            </a>.
          </div>
          <Button onClick={handleCreateClick} loading={isLoading}>
            Create an account
          </Button>
          {config.GOOGLE_CLIENT_ID && (
            <GoogleOAuthProvider clientId={config.GOOGLE_CLIENT_ID}>
              <GoogleAuthButton
                className={styles.googleButton}
                onCallBack={handleGoogleAuthSubmit}
                pending={isPending}
                errorMessage={registerGoogleApiError}
              >
                Create an account with Google
              </GoogleAuthButton>
            </GoogleOAuthProvider>
          )}
          <div className={styles.divider} />
          <Link to={'/sign-in'} className={styles.link}>
            <p>Sign in</p>
          </Link>
        </ValidatorWrapper>
      </div>
    </div>
  );
};

export default CreateAccount;
