import { nanoid } from 'nanoid';
import { hasElementsInArray } from '../../helpers/has-elements-in-array';
import { safeToLowerCase } from '../../helpers/safe-to-lower-case';

/**
 * @param currencies {array<object>}
 * @param searchValue {string}
 * @returns {array<object>}
 */
export const getFilteredOtherCurrencies = (currencies, searchValue) => {
  const hasCurrencies = hasElementsInArray(currencies);

  if (!hasCurrencies) {
    return [];
  }

  if (!searchValue) {
    return currencies;
  }

  return currencies.filter((item) => {
    const ticker = item?.code ?? '';
    const name = item?.name ?? '';
    const isPopular = item?.isPopular ?? false;
    const isStable = item?.isStable ?? false;
    const isFiat = item?.isFiat ?? false;
    const tickerLowerCased = safeToLowerCase(ticker);
    const nameLowerCased = safeToLowerCase(name);
    const searchValueLowerCased = safeToLowerCase(searchValue);

    if (isPopular || isStable || isFiat) {
      return false;
    }

    return tickerLowerCased.indexOf(searchValueLowerCased) > -1
      || nameLowerCased.indexOf(searchValueLowerCased) > -1;
  });
};

/**
 * @param currencies {array<object>}
 * @param searchValue {string}
 * @returns {array<object>}
 */
export const getFilteredCurrencies = (currencies, searchValue) => {
  const hasCurrencies = hasElementsInArray(currencies);

  if (!hasCurrencies) {
    return [];
  }

  if (!searchValue) {
    return currencies;
  }

  return currencies.filter((item) => {
    const ticker = item?.code ?? '';
    const name = item?.name ?? '';
    const tickerLowerCased = safeToLowerCase(ticker);
    const nameLowerCased = safeToLowerCase(name);
    const searchValueLowerCased = safeToLowerCase(searchValue);

    return tickerLowerCased.indexOf(searchValueLowerCased) > -1
      || nameLowerCased.indexOf(searchValueLowerCased) > -1;
  });
};

/**
 * @param currencies {array<object>}
 * @param key {string}
 * @returns {array}
 */
export const getCurrenciesByKey = (currencies, key) => {
  const hasCurrencies = hasElementsInArray(currencies);

  if (!hasCurrencies) {
    return [];
  }

  const currenciesFiltered = currencies.filter((item) => item[key]);

  return currenciesFiltered.map((currency) =>  ({ ...currency, key: nanoid() }));
};

/**
 * @param currencies {array<object>}
 * @returns {array}
 */
export const getCurrencies = (currencies) => {
  const hasCurrencies = hasElementsInArray(currencies);

  if (!hasCurrencies) {
    return [];
  }

  return currencies.map((currency) =>  ({ ...currency, key: nanoid() }));
};

/**
 * @param popularCoins {array<object>}
 * @param fiatCoins {array<object>}
 * @param stableCoins {array<object>}
 * @param otherCoins {array<object>}
 * @returns {array}
 */
export const getGroupList = (popularCoins, fiatCoins, stableCoins, otherCoins) => {
  const result = [
    {
      groupName: 'Popular',
      items: popularCoins,
    },
    {
      groupName: 'Fiat',
      items: fiatCoins,
    },
    {
      groupName: 'Stablecoins',
      items: stableCoins,
    },
    {
      groupName: 'All currencies',
      items: otherCoins,
    },
  ];

  return result.filter((group) => hasElementsInArray(group?.items));
};

export const INNER_MENU = {
  ALL: 'All',
  CRYPTO: 'Crypto',
  FIAT: 'Fiat',
};

/**
 * @param currencies {array<object>}
 * @param tab {string}
 * @returns {array<object>}
 */
export const getFilteredCurrenciesByTab = (currencies, tab) => {
  const hasCurrencies = hasElementsInArray(currencies);

  if (!hasCurrencies) {
    return [];
  }

  if (tab === INNER_MENU.FIAT) {
    return currencies.filter((item) => item?.isFiat);
  } else if (tab === INNER_MENU.CRYPTO) {
    return currencies.filter((item) => !item?.isFiat);
  }

  return currencies;
};
