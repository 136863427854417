import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCheck } from '@fortawesome/free-solid-svg-icons';
import styles from './styles.module.scss';

function CopyBtn({ textToCopy, isMargin = false }) {
  const [isClicked, setIsClicked] = useState(false);

  const margin = isMargin ? '10px' : 0;

  const handleClick = () => {
    navigator.clipboard.writeText(textToCopy);
    setIsClicked(true);
  };

  return (
    <>
      {
        isClicked ?
          <FontAwesomeIcon
            icon={faCheck}
            size="1x"
            color="#00c26f"
            style={{ marginLeft: margin }}
          /> :
          <FontAwesomeIcon
            icon={faCopy}
            size="1x"
            color="#64ACFF"
            onClick={handleClick}
            style={{ marginLeft: margin }}
            className={styles.copyBtn}
          />
      }

    </>
  );
}

export default CopyBtn;
