import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button } from '../../ui/button';
import { isFunction } from '../../../helpers/is-function';
import { LoaderIcon } from '../../icons/loader-icon';
import classes from './styles.module.scss';

const CSV_FORMAT_NAME = '.csv';
const XLS_FORMAT_NAME = '.xls';
const BUTTON_NAMES = {
  PDF: 'PDF',
  CSV: 'CSV',
  XLS: 'XLS',
};

export const ExportButtons = (props) => {
  const {
    className,
    onExportPdf,
    onExport,
    fetching,
    errorMessage,
  } = props;

  const [activeButtonName, setActiveButtonName] = React.useState(null);

  const handleExportPdfClick = async () => {
    setActiveButtonName(BUTTON_NAMES.PDF);
    if (isFunction(onExportPdf)) {
      await onExportPdf();
    }
    setActiveButtonName(null);
  };
  const handleExportCsvClick = async () => {
    setActiveButtonName(BUTTON_NAMES.CSV);
    if (isFunction(onExport)) {
      await onExport(CSV_FORMAT_NAME);
    }
    setActiveButtonName(null);
  };
  const handleExportXlsClick = async () => {
    setActiveButtonName(BUTTON_NAMES.XLS);
    if (isFunction(onExport)) {
      await onExport(XLS_FORMAT_NAME);
    }
    setActiveButtonName(null);
  };

  const isPDFFetching = activeButtonName === BUTTON_NAMES.PDF && fetching;
  const isCSVFetching = activeButtonName === BUTTON_NAMES.CSV && fetching;
  const isXLSFetching = activeButtonName === BUTTON_NAMES.XLS && fetching;

  return (
    <div className={cn(classes.exportButtons, className)}>
      <Button
        className={classes.exportButton}
        onClick={handleExportPdfClick}
        disabled={fetching}
      >
        {isPDFFetching ? (
          <LoaderIcon path="/images/loader-white-on-blue-icon.gif" size={25} />
        ) : BUTTON_NAMES.PDF}
      </Button>
      <Button
        className={classes.exportButton}
        onClick={handleExportCsvClick}
        disabled={fetching}
      >
        {isCSVFetching ? (
          <LoaderIcon path="/images/loader-white-on-blue-icon.gif" size={25} />
        ) : BUTTON_NAMES.CSV}
      </Button>
      <Button
        className={classes.exportButton}
        onClick={handleExportXlsClick}
        disabled={fetching}
      >
        {isXLSFetching ? (
          <LoaderIcon path="/images/loader-white-on-blue-icon.gif" size={25} />
        ) : BUTTON_NAMES.XLS}
      </Button>
      {errorMessage && (
        <div className={classes.exportButtonsError}>
          {errorMessage}
        </div>
      )}
    </div>
  );
};

ExportButtons.defaultProps = {
  className: null,
  onExportPdf: null,
  onExport: null,
  fetching: false,
  errorMessage: null,
};

ExportButtons.propTypes = {
  className: PropTypes.string,
  onExportPdf: PropTypes.func,
  onExport: PropTypes.func,
  fetching: PropTypes.bool,
  errorMessage: PropTypes.string,
};
