import { hasElementsInArray } from './has-elements-in-array';
import { getTransactionType, getTransactionTypeText } from './get-transaction-type';

/**
 * @param {Array<object>} items
 * @returns {Array<object>}
 */
export const getTransactionTypeSelectItems = (items) => {
  if (!hasElementsInArray(items)) {
    return [];
  }

  return items.map((item) => ({
    key: item.key,
    value: `${getTransactionType(item.value)} ${getTransactionTypeText(item.value)}`,
  }));
};
