import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isFunction } from '../../helpers/is-function';
import { CONVERSION_SCHEMA } from '../custody-page/custody-page-helpers';
import { CurrenciesSelectField } from '../currencies-select-field';
import { Field } from '../ui/field';
import { safeToUpperCase } from '../../helpers/safe-to-upper-case';
import { Button } from '../ui/button';
import { safeToLowerCase } from '../../helpers/safe-to-lower-case';
import { hasElementsInArray } from '../../helpers/has-elements-in-array';
import { LoaderIcon } from '../icons/loader-icon';
import ToolTip from '../tool-tip';
import classes from './styles.module.scss';

export const ConversionForm = (props) => {
  const {
    className,
    title,
    currencies,
    availableCurrencies,
    isLoading,
    onSubmit,
    defaultTicker,
    onAmountChange,
    estimate,
    estimateFetching,
    errorApiMessage,
    onCurrencyChange,
  } = props;

  const {
    control,
    handleSubmit,
    formState,
    setError,
    resetField,
  } = useForm({
    resolver: yupResolver(CONVERSION_SCHEMA),
  });
  const {
    fromCurrency,
    toCurrency,
    amount,
  } = formState.errors;

  const fromCurrencyErrorMessage = fromCurrency?.message ?? null;
  const toCurrencyErrorMessage = toCurrency?.message ?? null;
  const amountErrorMessage = amount?.message ?? null;
  const hasTwoItems = Array.isArray(currencies) && currencies.length === 2;

  const [selectedCurrencyFrom, setSelectedCurrencyFrom] = React.useState(null);
  const [selectedCurrencyTo, setSelectedCurrencyTo] = React.useState(null);
  const [payAmount, setPayAmount] = React.useState(null);
  const [defaultTickerTo, setDefaultTickerTo] = React.useState(null);
  const [isResetTo, setIsResetTo] = React.useState(false);

  const currenciesTo = React.useMemo(() => {
    const hasCurrencies = hasElementsInArray(availableCurrencies);

    if (!hasCurrencies) {
      return [];
    }

    const availableForToConversion = availableCurrencies.filter((item) => item?.available_for_to_conversion);

    return availableForToConversion.filter((item) =>
      safeToLowerCase(item?.code) !== safeToLowerCase(selectedCurrencyFrom?.code));
  }, [availableCurrencies, selectedCurrencyFrom]);

  const handleCurrencyFromSelect = (func) => (currency) => {
    const ticker = currency?.code ?? '';
    const tickerLowerCased = safeToLowerCase(ticker);
    const toTicker = selectedCurrencyTo?.code ?? null;
    const toTickerLowerCased = safeToLowerCase(toTicker);
    const isTickerSame = tickerLowerCased === toTickerLowerCased;

    func(ticker);
    setSelectedCurrencyFrom(currency);
    setError('fromCurrency', { type: 'error', message: null });

    if (defaultTickerTo) {
      setDefaultTickerTo(null);
    }

    if (isTickerSame) {
      resetField('toCurrency');
      setSelectedCurrencyTo(null);
      setIsResetTo(true);

      return;
    }

    if (isFunction(onCurrencyChange)) {
      onCurrencyChange(tickerLowerCased, toTickerLowerCased, payAmount);
    }
  };
  const handleCurrencyToSelect = (func) => (currency) => {
    const ticker = currency?.code ?? '';

    func(ticker);
    setSelectedCurrencyTo(currency);
    setError('toCurrency', { type: 'error', message: null });

    if (isResetTo) {
      setIsResetTo(false);
    }

    if (isFunction(onCurrencyChange)) {
      const fromTicker = selectedCurrencyFrom?.code ?? null;
      const fromTickerLowerCased = safeToLowerCase(fromTicker);

      onCurrencyChange(fromTickerLowerCased, ticker, payAmount);
    }
  };
  const handleAmountChange = (func) => (value) => {
    if (!selectedCurrencyFrom) {
      setError('fromCurrency', { type: 'error', message: 'From currency is required field' });
    }

    if (!selectedCurrencyTo) {
      setError('toCurrency', { type: 'error', message: 'To currency is required field' });
    }

    func(value);
    setPayAmount(value);

    if (isFunction(onAmountChange)) {
      const fromTicker = selectedCurrencyFrom?.code ?? null;
      const fromTickerLowerCased = safeToLowerCase(fromTicker);
      const toTicker = selectedCurrencyTo?.code ?? null;
      const toTickerLowerCased = safeToLowerCase(toTicker);

      onAmountChange(value, fromTickerLowerCased, toTickerLowerCased);
    }
  };
  const handleFormSubmit = (data) => {
    const currentBalance = selectedCurrencyFrom?.amount ?? 0;
    const currentBalanceNumber = Number(currentBalance);
    const amount = data?.amount ?? 0;
    const amountNumber = Number(amount);

    if (amountNumber > currentBalanceNumber) {
      setError('amount', { type: 'error', message: 'Not enough balance to convert' });

      return;
    }

    if (isFunction(onSubmit)) {
      onSubmit(data);
    }
  };

  React.useEffect(() => {
    if (hasTwoItems) {
      const currenciesFiltered = currencies.filter((item) => (
        safeToLowerCase(item?.code) !== safeToLowerCase(defaultTicker)
      ));
      setDefaultTickerTo(currenciesFiltered[0]?.code ?? null);
    }
  }, [hasTwoItems, defaultTicker]);

  const currentBalanceText = selectedCurrencyFrom?.amount ?? '0';
  const tickerFrom = selectedCurrencyFrom?.ticker ?? null;
  const tickerFromUpperCased = safeToUpperCase(tickerFrom);
  const tickerTo = selectedCurrencyTo?.ticker ?? null;
  const tickerToUpperCased = safeToUpperCase(tickerTo);
  const currentEstimateNormalized = estimate || null;
  const currentEstimate = currentEstimateNormalized
    ? `${currentEstimateNormalized} ${tickerToUpperCased}`
    : '-';
  const hasCurrenciesTo = hasElementsInArray(currenciesTo);
  const currentCurrenciesToPlaceholder = !hasCurrenciesTo
    ? 'Open another custody'
    : 'Select a currency';
  const currentCurrenciesToPlaceholderText = !hasCurrenciesTo
    ? 'to access conversion'
    : 'That you want to receive';

  return (
    <form
      className={cn(classes.conversionForm, className)}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      {title && (
        <div className={classes.conversionFormTitle}>
          {title}
        </div>
      )}
      <Controller
        name="fromCurrency"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <CurrenciesSelectField
            className={classes.conversionFormSelectFrom}
            label="From"
            placeholder="All currencies"
            placeholderText="Client can choose any supported crypto for payment"
            currencies={currencies}
            onSelect={handleCurrencyFromSelect(field.onChange)}
            error={fromCurrencyErrorMessage}
            defaultTicker={defaultTicker}
          />
        )}
      />
      <Controller
        name="toCurrency"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <CurrenciesSelectField
            className={classes.conversionFormSelectTo}
            label="To"
            Tooltip={(
              <img
                width={14}
                height={14}
                src="/images/info-icon.svg"
                alt="Icon help"
                data-tooltip-id="conversion-form"
                data-tooltip-content="You can convert your custody to the following currencies: Tether (ERC20), Tether (TRC20), Tether (BSC), USDC (ERC20), BUSD (BSC), BTC, ETH, DOGE, XMR, DGB, NOW"
                decoding="async"
              />
            )}
            placeholder={currentCurrenciesToPlaceholder}
            placeholderText={currentCurrenciesToPlaceholderText}
            currencies={currenciesTo}
            onSelect={handleCurrencyToSelect(field.onChange)}
            error={toCurrencyErrorMessage}
            reset={isResetTo}
            defaultTicker={defaultTickerTo}
          />
        )}
      />
      <Controller
        name="amount"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Field
            className={classes.conversionFormInput}
            title="You pay"
            data={{
              id: field.name,
              value: field.value,
              onChange: handleAmountChange(field.onChange),
              name: field.name,
              ticker: tickerFromUpperCased,
              placeholder: '0.00',
              error: amountErrorMessage,
              pattern: /^\d*[.]?\d{0,8}$/,
              autoComplete: 'off',
            }}
          />
        )}
      />
      <div className={classes.conversionFormEstimateBox}>
        <div className={classes.conversionFormCurrentBalance}>
          <div className={classes.conversionFormCurrentBalanceTitle}>
            Current balance
          </div>
          <div className={classes.conversionFormCurrentBalanceText}>
            {currentBalanceText}
            {' '}
            {tickerFromUpperCased}
          </div>
        </div>
        <div
          className={cn([
            classes.conversionFormEstimate,
            estimate && classes.conversionFormEstimateShow,
          ])}>
          <div className={classes.conversionFormEstimateTitle}>
            You receive
          </div>
          <div className={classes.conversionFormEstimateText}>
            {estimateFetching && (
              <LoaderIcon
                size={20}
                path="/images/loader-white-on-blue-icon.gif"
              />
            )}
            {!estimateFetching && currentEstimate}
          </div>
        </div>
      </div>
      <div className={classes.conversionFormError}>
        {errorApiMessage}
      </div>
      <Button
        type="submit"
        className={classes.conversionFormButton}
        disabled={isLoading}
      >
        {isLoading
          ? <LoaderIcon path="/images/loader-white-on-blue-icon.gif" />
          : 'Confirm'}
      </Button>
      <ToolTip id="conversion-form" />
    </form>
  );
};

ConversionForm.defaultProps = {
  className: null,
  title: null,
  currencies: null,
  availableCurrencies: null,
  isLoading: false,
  onSubmit: null,
  defaultTicker: null,
  onAmountChange: null,
  estimate: null,
  estimateFetching: false,
  errorApiMessage: null,
  onCurrencyChange: null,
};

ConversionForm.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  currencies: PropTypes.arrayOf(PropTypes.object),
  availableCurrencies: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  defaultTicker: PropTypes.string,
  onAmountChange: PropTypes.func,
  estimate: PropTypes.string,
  estimateFetching: PropTypes.bool,
  errorApiMessage: PropTypes.string,
  onCurrencyChange: PropTypes.func,
};
