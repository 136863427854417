import React from 'react';
import cn from 'classnames';
import { nanoid } from 'nanoid';
import { hasElementsInArray } from '../../../../helpers/has-elements-in-array';
import { CurrencyNetworkSup } from '../../../shared/currency-network-sup';
import { TopCoinsPropsType } from '../../types';
import classes from './styles.module.scss';

export const TopCoins: React.FC<TopCoinsPropsType> = (props) => {
  const {
    className,
    title = 'Top coins',
    coins,
    emptyData,
  } = props;

  if (!hasElementsInArray(coins)) {
    return null;
  }

  return (
    <div className={cn(classes.topCoins, className)}>
      <div className={classes.topCoinsTitle}>
        {emptyData ? 'No data available yet' : title}
      </div>
      <div className={classes.topCoinsList}>
        {!emptyData && coins.map((item) => (
          <div
            key={item.id ?? nanoid()}
            className={classes.topCoinsItem}
          >
            <div
              className={classes.topCoinsItemName}
              style={{ backgroundColor: item?.color }}
            >
              {item?.name}
            </div>
            <div className={classes.topCoinsItemRight}>
              <div className={classes.topCoinsItemAmount}>
                {item?.amount}
              </div>
              <div className={classes.topCoinsItemTicker}>
                {item?.ticker}
              </div>
              {item?.network && (
                <CurrencyNetworkSup
                  className={classes.topCoinsItemNetwork}
                  network={item?.network}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
