import React from "react";
import SubmitButton from "../../../../../components/submit-button";
import styles from "./styles.module.scss";

const ModalAlert = ({ isShow, onShow, onDelete, app }) => {
  const divRef = React.useRef(null);

  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      onShow(false);
    }
  };

  const handleCancelClick = () => {
    onShow(false);
  };

  const handleDeleteClick = () => {
    onDelete(app);
    onShow(false);
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  if (!isShow) {
    return null;
  }

  return (
    <div className={styles.externalWrapper}>
      <div className={styles.wrapper} ref={divRef}>
        <span>Are you sure to delete?</span>
        <div className={styles.btns}>
          <SubmitButton
            className={styles.btnCancel}
            buttonState="normal"
            onSubmit={handleCancelClick}
          >
            Cancel
          </SubmitButton>
          <SubmitButton buttonState="normal" onSubmit={handleDeleteClick}>
            Delete
          </SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default ModalAlert;
