import { CUSTOMER_TRANSACTION_TYPES } from '../constants/app-constants';

/**
 * @param {String} type
 * @returns {string}
 */
export const getTransactionType = (type) => {
  switch (type) {
    case CUSTOMER_TRANSACTION_TYPES.DEPOSIT: {
      return 'Deposit';
    }
    case CUSTOMER_TRANSACTION_TYPES.WITHDRAWAL: {
      return 'Withdrawal';
    }
    case CUSTOMER_TRANSACTION_TYPES.RECURRING_PAYMENT: {
      return 'Recurring payment';
    }
    case CUSTOMER_TRANSACTION_TYPES.SUB_PARTNERS_TRANSFER: {
      return 'Transfer';
    }
    case CUSTOMER_TRANSACTION_TYPES.PAYMENT: {
      return 'Deposit';
    }
    default: {
      return '-';
    }
  }
};

/**
 * @param {String} type
 * @returns {string}
 */
export const getTransactionTypeText = (type) => {
  switch (type) {
    case CUSTOMER_TRANSACTION_TYPES.DEPOSIT: {
      return 'from master';
    }
    case CUSTOMER_TRANSACTION_TYPES.WITHDRAWAL: {
      return 'to master';
    }
    case CUSTOMER_TRANSACTION_TYPES.PAYMENT: {
      return 'with payment';
    }
    default: {
      return '';
    }
  }
};
