export const TYPES = {
  TURNOVER: 'turnover',
  COINS: 'coins',
  SEVEN_DAYS: 'sevenDays',
  ONE_MONTH: 'oneMonth',
  THREE_MONTH: 'threeMonth',
  CALENDAR: 'calendar',
  MONTH: 'month',
  DAY: 'day',
};

export const STATISTICS_GRAPH_TABS = [{
  id: '1',
  name: 'Turnover',
  type: TYPES.TURNOVER,
}, {
  id: '2',
  name: 'Coins',
  type: TYPES.COINS,
}];

export const STATISTICS_GRAPH_PERIOD_TABS = [{
  id: '1',
  name: '7D',
  type: TYPES.SEVEN_DAYS,
}, {
  id: '2',
  name: '1M',
  type: TYPES.ONE_MONTH,
}, {
  id: '3',
  name: '3M',
  type: TYPES.THREE_MONTH,
}, {
  id: '4',
  name: 'Calendar',
  type: TYPES.CALENDAR,
  isCalendarIcon: true,
}];

export const COLORS_FOR_PIE_CHART = ['#FF5E22', '#39CEF3', '#9BDA61', '#A0A0A0'];
