import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { isFunction } from '../../../../helpers/is-function';
import { getUrlWithDomain } from '../../../../helpers/get-url-with-domain';
import { CURRENCY_USD } from '../../../../constants/app-constants';
import { safeToUpperCase } from '../../../../helpers/safe-to-upper-case';
import { marketInfoFetchingSelector } from '../../../../store/market-info/selectors';
import { LoaderIcon } from '../../../icons/loader-icon';
import { CustodyTopUpIcon } from '../../../icons/custody-top-up-icon';
import { CustodyConvertIcon } from '../../../icons/custody-convert-icon';
import { CustodyWithdrawIcon } from '../../../icons/custody-withdraw-icon';
import classes from './styles.module.scss';

const BalanceRow = (props) => {
  const {
    className,
    currency,
    onConvert,
    onTopUp,
    onRequestPayout,
  } = props;

  const marketInfoFetching = useSelector(marketInfoFetchingSelector());
  const imagePath = currency?.logo_url ?? null;
  const imagePathNormalized = getUrlWithDomain(imagePath);
  const name = currency?.name ?? null;
  const amount = currency?.amount ?? null;
  const ticker = currency?.code ?? null;
  const fiatAmount = currency?.fiatAmount ?? 0;
  const fiatAmountNormalized = fiatAmount ? `~${fiatAmount}` : fiatAmount;
  const fiatCurrency = currency?.fiatCurrency ?? CURRENCY_USD;
  const fiatCurrencyUpperCased = safeToUpperCase(fiatCurrency);
  const currentTickerUpperCased = safeToUpperCase(currency?.ticker ?? ticker);

  const handleConvertClick = () => {
    if (isFunction(onConvert)) {
      onConvert(ticker);
    }
  };
  const handleTopUpClick = () => {
    if (isFunction(onTopUp)) {
      onTopUp(ticker);
    }
  };
  const handleRequestPayoutClick = () => {
    if (isFunction(onRequestPayout)) {
      onRequestPayout(currency);
    }
  };

  return (
    <tr className={cn(classes.balanceRow, className)}>
      <td className={cn(classes.balanceItem, classes.balanceItemCoin)}>
        {imagePath && (
          <img
            className={classes.coinImage}
            src={imagePathNormalized}
            alt="Icon"
            decoding="async"
            width={40}
            height={40}
          />
        )}
      </td>
      <td className={classes.balanceItem}>
        <div className={classes.balanceItemInfo}>
          <div className={classes.balanceItemInfoMain}>
            <div className={classes.balanceItemCurrency}>
              <p className={classes.balanceItemPrimaryText}>
                {currentTickerUpperCased}
              </p>
              <p className={classes.balanceItemSecondaryText}>
                {name}
              </p>
            </div>
            <div className={classes.balanceItemValues}>
              <p className={cn(classes.balanceAmount, classes.balanceItemPrimaryText)}>
                {`${amount} ${currentTickerUpperCased}`}
              </p>
              {marketInfoFetching && (
                <LoaderIcon
                  className={classes.balanceItemLoader}
                  size={14}
                />
              )}
              {!marketInfoFetching && (
                <p className={cn(classes.balanceAmount, classes.balanceItemSecondaryText)}>
                  {`${fiatAmountNormalized} ${fiatCurrencyUpperCased}`}
                </p>
              )}
            </div>
          </div>
          <div className={classes.balanceItemInfoHandlers}>
            {onTopUp && (
              <div>
                <button
                  className={classes.balanceButton}
                  type="button"
                  onClick={handleTopUpClick}
                >
                  <CustodyTopUpIcon className={classes.balanceButtonIcon} />
                  <p className={classes.balanceButtonText}>
                    Top-up
                  </p>
                </button>
              </div>
            )}
            {onRequestPayout && (
              <div>
                <button
                  className={classes.balanceButton}
                  type="button"
                  onClick={handleRequestPayoutClick}
                >
                  <CustodyWithdrawIcon className={classes.balanceButtonIcon} />
                  <p className={classes.balanceButtonText}>
                    Withdraw
                  </p>
                </button>
              </div>
            )}
            {onConvert && (
              <div>
                <button
                  className={classes.balanceButton}
                  type="button"
                  onClick={handleConvertClick}
                >
                  <CustodyConvertIcon className={classes.balanceButtonIcon} />
                  <p className={classes.balanceButtonText}>
                    Convert
                  </p>
                </button>
              </div>
            )}
          </div>
        </div>
      </td>
    </tr>
  );
};

BalanceRow.defaultProps = {
  className: null,
  currency: null,
  onConvert: null,
  onTopUp: null,
  onRequestPayout: null,
};

BalanceRow.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.shape({}),
  onConvert: PropTypes.func,
  onTopUp: PropTypes.func,
  onRequestPayout: PropTypes.func,
};

export const BalanceRowMemo = React.memo(BalanceRow);
