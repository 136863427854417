import * as React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from '../../../../helpers/format-date';
import { isFunction } from '../../../../helpers/is-function';
import { DATE_PATTERN } from '../../../../constants/app-constants';
import { getColorStatus } from '../../../../helpers/get-color-status';
import { toFixed } from '../../../../helpers/to-fixed';
import { getAmountWithTicker } from '../../../../helpers/get-amount-with-ticker';
import currencyFormat from '../../../../libs/currency-format';
import { safeToLowerCase } from '../../../../helpers/safe-to-lower-case';

export const MassPayoutsTableRow = (props) => {
  const {
    className,
    onClick,
    isDesktop,
    payout,
  } = props;

  const id = payout?.id || null;
  const batchIdNormalized = payout?.batchWithdrawalId || null;
  const amount = payout?.amount || null;
  const amountFixed = toFixed(amount, 8);
  const ticker = payout?.ticker || null;
  const tickerFormatted = currencyFormat(ticker);
  const amountWithTicker = getAmountWithTicker(Number(amountFixed), tickerFormatted);
  const addressNormalized = payout?.address || null;
  const createdDateFormatted = formatDate(payout?.createdAt, DATE_PATTERN);
  const createdDateNormalized = createdDateFormatted || '-';
  const updatedDateFormatted = formatDate(payout?.updatedAt, DATE_PATTERN);
  const updatedDateNormalized = updatedDateFormatted || '-';
  const status = payout?.status || null;
  const statusColor = status ? getColorStatus(status) : null;
  const statusNormalized = safeToLowerCase(status) || '-';

  const handleRowClick = () => {
    if (isFunction(onClick)) {
      onClick(payout);
    }
  };

  return (
    <tr
      className={className}
      onClick={handleRowClick}
    >
      <td>
        <div>
          {id}
        </div>
        <div data-margin="top">
          {batchIdNormalized}
        </div>
      </td>
      <td>
        <div data-color="black">
          {amountWithTicker}
        </div>
        {!isDesktop && (
          <div data-color="black" data-margin="top" data-overflow="hidden">
            {addressNormalized}
          </div>
        )}
      </td>
      {isDesktop && (
        <>
          <td data-color="black" data-overflow="hidden">
            {addressNormalized}
          </td>
          <td>
            {createdDateNormalized}
          </td>
          <td>
            {updatedDateNormalized}
          </td>
        </>
      )}
      <td style={{ color: statusColor, textTransform: 'capitalize' }}>
        {statusNormalized}
      </td>
    </tr>
  );
};

MassPayoutsTableRow.defaultProps = {
  className: null,
  onClick: null,
  isDesktop: false,
  payout: null,
};

MassPayoutsTableRow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  isDesktop: PropTypes.bool,
  payout: PropTypes.shape({
    id: PropTypes.string,
    batchWithdrawalId: PropTypes.string,
    amount: PropTypes.string,
    currency: PropTypes.string,
    address: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    status: PropTypes.string,
  }),
};
