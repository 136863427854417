import { isObject } from './is-object';

export const areObjectsShallowEqual = (objA, objB) => {
  const isSame = objA === objB;
  if (isSame) {
    return true;
  }

  const areObjects = [objA, objB].every(isObject);
  if (!areObjects) {
    return false;
  }

  const [objAKeys, objBKeys] = [objA, objB].map(Object.keys);

  const sameKeysLength = objAKeys.length === objBKeys.length;
  if (!sameKeysLength) {
    return false;
  }

  const areValuesEqual = objAKeys.every(key => {
    const valueA = objA[key];
    const valueB = objB[key];

    return valueA === valueB;
  });
  
  return areValuesEqual;
};