import React from 'react';
import cn from 'classnames';
import { CustomSelect } from './components/custom-select';
import { CustomSelectFieldProps } from './types';
import classes from './styles.module.scss';

export const CustomSelectField: React.FC<CustomSelectFieldProps> = (props) => {
  const {
    className,
    label,
    placeholderText,
    items,
    onSelect,
    error,
    reset,
    noMatchesText,
    defaultValue,
    isLoading,
    searchPlaceholder,
    disabled,
    hasSearch,
    isSmallTheme,
  } = props;

  return (
    <div className={cn([
      classes.selectField,
      className,
    ])}
    >
      <div className={classes.selectFieldHeader}>
        {label && (
          <div className={classes.selectFieldLabel}>
            {label}
          </div>
        )}
        {error && (
          <div className={cn([
            classes.selectFieldError,
            error && classes.selectFieldErrorShow,
          ])}
          >
            <div className={classes.selectFieldErrorText}>
              {error}
            </div>
          </div>
        )}
      </div>
      <CustomSelect
        placeholderText={placeholderText}
        items={items}
        onSelect={onSelect}
        error={Boolean(error)}
        reset={reset}
        noMatchesText={noMatchesText}
        defaultValue={defaultValue}
        isLoading={isLoading}
        searchPlaceholder={searchPlaceholder}
        disabled={disabled}
        hasSearch={hasSearch}
        isSmallTheme={isSmallTheme}
      />
    </div>
  );
};
