export const TYPES = {
  MANUALLY: 'manually',
  UPLOAD: 'upload',
};

export const ERROR_TEXTS = {
  INVALID_ADDRESS: 'Invalid address',
  INVALID_EXTRA_ID: 'Invalid memo',
  REQUIRED: 'Required field',
};

export const MAXIMUM_MANUAL_PAYOUTS = 5;

export const NAME_FOR_PAYOUTS_SCHEMA = 'whitelistManual';

export const DEFAULT_TEMPLATE = {
  address: '',
  currency: '',
  extra_id: '',
};

export const FIELD_NAMES = {
  CURRENCY: 'currency',
  ADDRESS: 'address',
  EXTRA_ID: 'extra_id',
};
