import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button } from '../../../ui/button';
import { useAllSubscriptionPlans } from '../../../../hooks/use-all-subscription-plans';
import {
  allPlansFetchedSelector,
  allPlansFetchingSelector,
  allPlansSelector,
  customerSubscriptionsSelector,
} from '../../../../store/customer-management/selectors';
import { hasElementsInArray } from '../../../../helpers/has-elements-in-array';
import { CustomerActiveBillingPlanInfo } from '../customer-active-billing-plan-info';
import { LoaderIcon } from '../../../icons/loader-icon';
import { CustomerPageContext } from '../../customer-page';
import { safeToUpperCase } from '../../../../helpers/safe-to-upper-case';
import { getCurrentNetworkForShow } from '../../../../helpers/get-current-network-for-show';
import classes from './styles.module.scss';

export const CustomerActiveBillingPlans = (props) => {
  const { className } = props;

  const customerPageContext = React.useContext(CustomerPageContext);
  const { onClose, onUnsubscribe, currenciesObject } = customerPageContext;
  const params = useParams();
  const { customerId } = params;
  const customerSubscriptions = useSelector(customerSubscriptionsSelector());
  const customerSubscriptionsItems = customerSubscriptions[customerId] || null;
  const allPlans = useSelector(allPlansSelector());
  const allPlansFetching = useSelector(allPlansFetchingSelector());
  const allPlansFetched = useSelector(allPlansFetchedSelector());
  const { reFetchAllSubscriptionPlans } = useAllSubscriptionPlans();

  const [activeSubscribeId, setActiveSubscribeId] = React.useState(null);

  const allPlansObject = React.useMemo(() => {
    if (!hasElementsInArray(allPlans)) {
      return {};
    }

    return allPlans.reduce((acc, item) => {
      if (item?.id) {
        acc[item.id] = item;
      }

      return acc;
    }, {});
  }, [allPlans]);
  const activeSubscriptionsNormalized = React.useMemo(() => {
    if (!hasElementsInArray(customerSubscriptionsItems)) {
      return [];
    }

    return customerSubscriptionsItems.map((item) => {
      const planId = item?.subscriptionPlanId ?? null;
      const planData = allPlansObject[planId] || {};
      const ticker = planData?.currency ?? '';
      const tickerUpperCased = safeToUpperCase(ticker);
      const currencyFound = currenciesObject?.[tickerUpperCased] || {};
      const currentTicker = currencyFound?.ticker ?? tickerUpperCased;
      const network = getCurrentNetworkForShow(currencyFound?.network, ticker);

      return {
        id: item?.id ?? null,
        planId,
        name: planData?.title ?? null,
        amount: planData?.amount ?? null,
        currency: currentTicker,
        network,
        intervalDay: planData?.intervalDay ?? null,
      };
    });
  }, [customerSubscriptionsItems, allPlansObject, currenciesObject]);

  const handleRadioButtonChange = (value) => {
    setActiveSubscribeId(value);
  };
  const handleUnsubscribe = () => {
    onUnsubscribe(activeSubscribeId);
  };

  React.useEffect(() => {
    if (!allPlansFetched) {
      void reFetchAllSubscriptionPlans();
    }
  },[allPlansFetched]);

  React.useEffect(() => {
    if (hasElementsInArray(activeSubscriptionsNormalized)) {
      setActiveSubscribeId(activeSubscriptionsNormalized[0]?.id ?? null);
    }
  }, [activeSubscriptionsNormalized]);

  const hasSubscriptions = hasElementsInArray(customerSubscriptionsItems);

  if (allPlansFetching) {
    return (
      <LoaderIcon className={classes.loader}/>
    );
  }

  return (
    <div className={cn(classes.customerActiveBillingPlans, className)}>
      <div className={classes.customerActiveBillingPlansWrapper}>
        {!hasSubscriptions && 'No billing plans yet.'}
        {hasSubscriptions && activeSubscriptionsNormalized.map((item) => (
          <CustomerActiveBillingPlanInfo
            key={item?.id}
            activeId={activeSubscribeId}
            data={item}
            onChange={handleRadioButtonChange}
          />
        ))}
      </div>
      <Button
        className={classes.button}
        onClick={onClose}
      >
        OK
      </Button>
      {hasSubscriptions && (
        <Button
          className={classes.buttonUnsubscribe}
          onClick={handleUnsubscribe}
          displayBorder
        >
          Unsubscribe
        </Button>
      )}
    </div>
  );
};

CustomerActiveBillingPlans.defaultProps = {
  className: null,
};

CustomerActiveBillingPlans.propTypes = {
  className: PropTypes.string,
};
