import React from 'react';
import cn from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCopy,
  faCheck,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import SubmitButton, { buttonState } from '../submit-button';
import Switch2 from '../switch-2';
import WarningBlock from '../warning-block';
import commonStyles from '../../css/common.module.scss';
import apiClient from '../../api/api-client';
import CopyBtn from '../copy-btn';
import config from '../../config';
import styles from './styles.module.scss';

let timer = 0;
let timeout = 0;
let timeoutError = 0;

const DonationLinkSettings = () => {
  const [link, setLink] = React.useState('');
  const [buttonStateNow, setButtonStateNow] = React.useState(buttonState.normal);
  const [apiKeys, setApiKeys] = React.useState([]);
  const [activeKey, setActiveKey] = React.useState('');
  const [resultLink, setResultLink] = React.useState('');
  const [copied, setCopied] = React.useState(false);
  const [error, setError] = React.useState('');
  const [fieldsDonation, setFieldsDonation] = React.useState({
    email_show: false,
    email_require: false,
    phone_show: false,
    phone_require: false,
    name_show: false,
    name_require: false,
    social_link_show: false,
    social_link_require: false,
    address_show: false,
    address_require: false,
    comment_show: false,
    comment_require: false,
  });

  const handleChangeLink = (e) => {
    const value = e.target.value;

    if (value.length <= 18) {
      const clearString = String(value).replace(/[^a-z0-9]/gi, '');

      setLink(clearString);
    }
  };

  const handleSelectChange = (e) => {
    setActiveKey(e.target.value);
  };

  const handleSwitchChange = (field) => {
    // 'social_link_show'
    const dest = field.split('_'); // ['social', 'link', 'show']
    const type = dest[dest.length - 1]; // show
    const fieldKey = dest.slice(0, -1).join('_'); // social_link

    if (
      type === 'require' &&
      !fieldsDonation[`${fieldKey}_show`] &&
      !fieldsDonation[field]
    ) {
      // if type require and show === false, set show:true
      setFieldsDonation((prevState) => ({
        ...prevState,
        [field]: !prevState[field],
        [`${fieldKey}_show`]: true,
      }));

      return;
    }
    setFieldsDonation((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleApplyDonationLink = async () => {
    if (!link.length) {
      setError('Donation link must not be empty');

      return;
    }

    try {
      await apiClient.applyDonationLink(link, activeKey, fieldsDonation);
      setButtonStateNow(buttonState.success);
      setResultLink(`${config.front_domain}/donation/${link}`);
    } catch (err) {
      setButtonStateNow(buttonState.failure);
      setError(String(err.response.data.error));
    }
  };

  const handleCopyClick = () => {
    setCopied(true);

    timeout = setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  React.useEffect(() => {
    if (error.length) {
      timeoutError = setTimeout(() => {
        setError('');
      }, 3000);
    }
  }, [error]);

  React.useEffect(() => {
    if (buttonStateNow !== buttonState.normal) {
      timer = setTimeout(() => {
        setButtonStateNow(buttonState.normal);
      }, 2000);
    }

    return () => {
      clearTimeout(timer);
      clearTimeout(timeout);
      clearTimeout(timeoutError);
    };
  }, [buttonStateNow]);

  React.useEffect(() => {
    async function fetchApiKeys() {
      try {
        const { keys } = await apiClient.getApiKeys();

        if (keys.length) {
          setApiKeys(keys);
          setActiveKey(keys[0].api_key);
        }
      } catch (err) {
        // ignore
      }
    }

    fetchApiKeys();
  }, []);

  React.useEffect(() => {
    async function fetchDonationLink() {
      try {
        const { link, settings } = await apiClient.getDonationLink();

        if (link && settings) {
          setLink(link.donation_link);
          setFieldsDonation(settings);
        }
      } catch (err) {
        // ignore
      }
    }

    fetchDonationLink();
  }, []);

  return (
    <>
      <div className={styles.titleWrapper}>
        <h2>Donation Link</h2>
        <a href="https://nowpayments.io/donation-tools">
          <FontAwesomeIcon
            icon={faInfoCircle}
            size="1x"
            color="#64ACFF"
            style={{ cursor: 'pointer' }}
          />
        </a>
      </div>
      <div className={`${styles.wrapper} ${commonStyles.panel}`}>
        <WarningBlock apiKeys={apiKeys} />
        <p>Create your own donation link here</p>
        <div className={styles.linkBlock}>
          <select className={styles.selectApiKey} onChange={handleSelectChange}>
            {apiKeys.map((api, index) => (
              <option key={index} value={api.api_key}>
                {api.api_key}
              </option>
            ))}
          </select>
          <div className={styles.inputBlock}>
            <span>nowpayments.io/donation/</span>
            <input
              className={cn(commonStyles.textField, styles.inputField)}
              type="text"
              placeholder="example"
              value={link}
              onChange={handleChangeLink}
            />
            <CopyBtn
              textToCopy={'https://nowpayments.io/donation/' + link}
              isMargin={true}
            />
          </div>
          <div className={styles.table}>
            <div className={styles.table__head}>
              <div className={styles.table__head__row}>
                <span className={styles.table__head__row__item}>Field</span>
                <span className={styles.table__head__row__item}>Show</span>
                <span className={styles.table__head__row__item}>Require</span>
              </div>
            </div>
            <div className={styles.table__body}>
              <div className={styles.table__body__row}>
                <span className={styles.table__body__row__item}>Email</span>
                <span className={styles.table__body__row__item}>
                  <Switch2
                    isChecked={fieldsDonation.email_show}
                    onChange={() => handleSwitchChange('email_show')}
                  />
                </span>
                <span className={styles.table__body__row__item}>
                  <Switch2
                    isChecked={fieldsDonation.email_require}
                    onChange={() => handleSwitchChange('email_require')}
                  />
                </span>
              </div>
              <div className={styles.table__body__row}>
                <span className={styles.table__body__row__item}>Phone</span>
                <span className={styles.table__body__row__item}>
                  <Switch2
                    isChecked={fieldsDonation.phone_show}
                    onChange={() => handleSwitchChange('phone_show')}
                  />
                </span>
                <span className={styles.table__body__row__item}>
                  <Switch2
                    isChecked={fieldsDonation.phone_require}
                    onChange={() => handleSwitchChange('phone_require')}
                  />
                </span>
              </div>
              <div className={styles.table__body__row}>
                <span className={styles.table__body__row__item}>Name</span>
                <span className={styles.table__body__row__item}>
                  <Switch2
                    isChecked={fieldsDonation.name_show}
                    onChange={() => handleSwitchChange('name_show')}
                  />
                </span>
                <span className={styles.table__body__row__item}>
                  <Switch2
                    isChecked={fieldsDonation.name_require}
                    onChange={() => handleSwitchChange('name_require')}
                  />
                </span>
              </div>
              <div className={styles.table__body__row}>
                <span className={styles.table__body__row__item}>
                  Social Link
                </span>
                <span className={styles.table__body__row__item}>
                  <Switch2
                    isChecked={fieldsDonation.social_link_show}
                    onChange={() => handleSwitchChange('social_link_show')}
                  />
                </span>
                <span className={styles.table__body__row__item}>
                  <Switch2
                    isChecked={fieldsDonation.social_link_require}
                    onChange={() => handleSwitchChange('social_link_require')}
                  />
                </span>
              </div>
              <div className={styles.table__body__row}>
                <span className={styles.table__body__row__item}>Address</span>
                <span className={styles.table__body__row__item}>
                  <Switch2
                    isChecked={fieldsDonation.address_show}
                    onChange={() => handleSwitchChange('address_show')}
                  />
                </span>
                <span className={styles.table__body__row__item}>
                  <Switch2
                    isChecked={fieldsDonation.address_require}
                    onChange={() => handleSwitchChange('address_require')}
                  />
                </span>
              </div>
              <div className={styles.table__body__row}>
                <span className={styles.table__body__row__item}>Comment</span>
                <span className={styles.table__body__row__item}>
                  <Switch2
                    isChecked={fieldsDonation.comment_show}
                    onChange={() => handleSwitchChange('comment_show')}
                  />
                </span>
                <span className={styles.table__body__row__item}>
                  <Switch2
                    isChecked={fieldsDonation.comment_require}
                    onChange={() => handleSwitchChange('comment_require')}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        {resultLink ? (
          <div className={styles.resultLinkBlock}>
            <p>{resultLink}</p>
            {!copied ? (
              <CopyToClipboard text={resultLink} onCopy={handleCopyClick}>
                <FontAwesomeIcon icon={faCopy} size="1x" color="#00c26f" />
              </CopyToClipboard>
            ) : (
              <FontAwesomeIcon icon={faCheck} size="1x" color="#00c26f" />
            )}
          </div>
        ) : null}
        {error.length > 0 ? (
          <div className={styles.errorBlock}>
            <p>{error}</p>
          </div>
        ) : null}
        <div className={styles.buttonsBlock}>
          <SubmitButton
            successMessage="Success"
            failureMessage="Error"
            buttonState={buttonStateNow}
            onSubmit={handleApplyDonationLink}
          >
            Save
          </SubmitButton>
        </div>
      </div>
    </>
  );
};

export default DonationLinkSettings;
