export const ELEMENTS_PER_PAGE = 10;
export const TITLE_FOR_WALLET_WHITELIST_EMAIL = 'Wallets whitelisting';
export const BODY_FOR_WALLET_WHITELIST_EMAIL = 'I\'d like to confirm to enable wallets whitelisting.';

export const POPUPS_OBJECT = {
  add: {
    type: 'add-wallet',
    title: 'Add new wallet',
  },
  edit: {
    type: 'edit-wallet',
    title: 'Edit wallet address',
  },
  whitelist: {
    type: 'edd-to-whitelist',
    title: 'Add wallet address to Whitelist',
  },
  delete: {
    type: 'delete',
    title: 'Delete wallet address',
  },
};

export const OPTIONS_OBJECT = {
  edit: {
    image: '/images/settings-edit.svg',
    text: 'Edit address',
    popup: POPUPS_OBJECT.edit,
    isSetAsMAinOption: false,
  },
  addToWhitelist: {
    image: '/images/settings-whitelist-wallet.svg',
    text: 'Add address to Whitelist',
    popup: POPUPS_OBJECT.whitelist,
    isSetAsMAinOption: false,
  },
  setAsMain: {
    image: '/images/settings-main-wallet.svg',
    text: 'Set as Main wallet',
    popup: null,
    isSetAsMAinOption: true,
  },
  delete: {
    image: '/images/settings-delete.svg',
    text: 'Delete wallet',
    popup: POPUPS_OBJECT.delete,
    isSetAsMAinOption: false,
  },
};

export const OPTIONS = Object.keys(OPTIONS_OBJECT);
