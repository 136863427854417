import ReactGA from 'react-ga4';

/**
 * @param {String} name
 * @param {{ category: string|*, action: string, label: string|*, value: string|* }} event
 */
export const trackEvent = (name = '', event = {}) => {
  ReactGA.event(name, {
    ...event,
    category: event?.category ?? name,
    action: event?.action ?? '',
    label: event?.label ?? '',
    value: event?.value ?? '',
  });
};
