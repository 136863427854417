import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import styles from './styles.module.scss';

const ToolTip = (props) => {
  const {
    className,
    classNameArrow,
    id,
    place,
    offset,
    variant,
    openOnClick,
    clickable,
    render,
    afterHide,
    noArrow,
  } = props;

  return (
    <ReactTooltip
      id={id}
      className={cn(styles.toolTip, className)}
      classNameArrow={classNameArrow}
      place={place}
      offset={offset}
      variant={variant}
      openOnClick={openOnClick}
      clickable={clickable}
      render={render}
      afterHide={afterHide}
      noArrow={noArrow}
    />
  );
};

ToolTip.defaultProps = {
  className: null,
  classNameArrow: null,
  id: null,
  place: 'right',
  offset: 10,
  variant: 'light',
  openOnClick: false,
  clickable: false,
  render: null,
  afterHide: null,
  noArrow: false,
};

ToolTip.propTypes = {
  className: PropTypes.string,
  classNameArrow: PropTypes.string,
  id: PropTypes.string,
  place: PropTypes.string,
  offset: PropTypes.number,
  variant: PropTypes.string,
  openOnClick: PropTypes.bool,
  clickable: PropTypes.bool,
  render: PropTypes.func,
  afterHide: PropTypes.func,
  noArrow: PropTypes.bool,
};

export default ToolTip;
