import React from 'react';
import cn from 'classnames';
import { InputSearch } from '../ui/input-search';
import { Button } from '../ui/button';
import { useMediaQuery } from '../../hooks/use-media-query';
import { ExternalLinkWithUnderline } from '../external-link-with-underline';
import classes from './styles.module.scss';

type SettingTableWrapperType = {
  className?: string | null,
  onChange?: null | ((value: string) => void),
  onClick: (type: string) => void,
  placeholder?: string | null,
  buttonLabel: string,
  buttonTwoLabel?: string | null,
  buttonTwoLink?: string | null,
  buttonTwoTarget?: string | null,
  buttonTwoRel?: string | null,
  isNotActivated: boolean | null,
  children: React.ReactNode,
};

export const SettingTableWrapper: React.FC<SettingTableWrapperType> = (props) => {
  const {
    className,
    onChange,
    onClick,
    placeholder,
    buttonLabel,
    buttonTwoLabel,
    buttonTwoLink,
    buttonTwoTarget,
    buttonTwoRel,
    isNotActivated,
    children,
  } = props;

  const isDesktop = useMediaQuery('(min-width: 1200px)');

  return (
    <div className={cn([
      className,
      classes.settingTableWrapper,
    ])}
    >
      {!isNotActivated && onChange && (
        <InputSearch
          id="setting-table-search"
          className={classes.searchInput}
          placeholder={placeholder}
          onChange={onChange}
          imageSearch="/images/search-dark-gray-icon.svg"
        />
      )}
      {children && (
        <div className={classes.content}>
          {children}
        </div>
      )}
      {!isNotActivated && (
        <Button
          onClick={onClick}
          className={classes.button}
        >
          {buttonLabel}
        </Button>
      )}
      {buttonTwoLabel && buttonTwoLink && isDesktop && (
        <ExternalLinkWithUnderline
          className={classes.buttonTwoArrows}
          href={buttonTwoLink}
          text={buttonTwoLabel}
        />
      )}
      {buttonTwoLabel && buttonTwoLink && !isDesktop && (
        <Button
          className={cn(classes.buttonTwo, classes.buttonTwoArrows)}
          href={buttonTwoLink}
          target={buttonTwoTarget}
          rel={buttonTwoRel}
          displayBorder
        >
          {buttonTwoLabel}
        </Button>
      )}
    </div>
  );
};

SettingTableWrapper.defaultProps = {
  className: null,
  onChange: null,
  placeholder: null,
  buttonTwoLabel: null,
  buttonTwoLink: null,
  buttonTwoTarget: null,
  buttonTwoRel: null,
};
