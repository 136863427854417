import * as React from 'react';
import { usePaymentsActions } from './use-payments-context';

export const useInvoicePopup = () => {
  const { loadPaymentsByInvoiceId, resetPayments } = usePaymentsActions();

  const [isInvoicePopupVisible, setIsInvoicePopupVisible] = React.useState(false);
  const [activeInvoice, setActiveInvoice] = React.useState(null);

  const openInvoicePopup = React.useCallback((invoice) => {
    if (!invoice) {
      return;
    }

    setActiveInvoice(invoice);
    setIsInvoicePopupVisible(true);
    void loadPaymentsByInvoiceId(invoice.id);
  }, []);

  const closeInvoicePopup = React.useCallback(() => {
    setIsInvoicePopupVisible(false);
    setActiveInvoice(null);
    resetPayments();
  }, []);

  return {
    activeInvoice,
    isInvoicePopupVisible,
    openInvoicePopup,
    closeInvoicePopup,
  };
};
