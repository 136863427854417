import React from 'react';
import cn from 'classnames';
import classes from './styles.module.scss';
import { InfoCardProps } from '../../types';

export const InfoCard: React.FC<InfoCardProps> = (props) => {
  const { className, children } = props;

  return (
    <div className={cn(classes.infoCard, className)}>
      {children}
    </div>
  );
};
