import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initial-state';

export const STATISTICS_REDUCER_NAME = 'statistics';

const setBalanceState = (state, { payload }) => {
  state.balance.amount = payload.amount;
  state.balance.currency = payload.currency;
};
const setCountOfPayoutsState = (state, { payload }) => {
  state.countOfPayouts = payload;
};
const setTurnoverState = (state, { payload }) => {
  if (payload?.amount) {
    state.turnover.amount = payload.amount;
  }
  if (payload?.ticker) {
    state.turnover.ticker = payload.ticker;
  }
  if (payload?.count) {
    state.turnover.count = payload.count;
  }
  if (payload?.isFetching !== undefined) {
    state.turnover.isFetching = payload.isFetching;
  }
  if (!state.turnover.isLoaded) {
    state.turnover.isLoaded = payload?.isLoaded ?? false;
  }
  state.turnover.errorMessage = payload?.errorMessage ?? null;
};
const setStatsTurnoverState = (state, { payload }) => {
  state.statsTurnover = payload;
};
const setStatsCoinsState = (state, { payload }) => {
  state.statsCoins = payload;
};
const setActiveStatsTypeState = (state, { payload }) => {
  state.activeStatsType = payload;
};
const setActivePeriodTypeState = (state, { payload }) => {
  state.activePeriodType = payload;
};
const setPeriodState = (state, { payload }) => {
  if (payload?.startDate) {
    state.period.startDate = payload.startDate;
  }
  if (payload?.endDate) {
    state.period.endDate = payload.endDate;
  }
};
const setLastTransactionsState = (state, { payload }) => {
  state.lastTransactions = payload;
};
const setNewsState = (state, { payload }) => {
  state.news = payload;
};
const setIndustryState = (state, { payload }) => {
  state.industry = payload;
};
const setIsLoadedState = (state, { payload }) => {
  state.isLoaded = payload;
};
const setIsFetchingState = (state, { payload }) => {
  state.isFetching = payload;
};
const setErrorMessageState = (state, { payload }) => {
  state.errorMessage = payload;
};


export const statisticsSlice = createSlice({
  name: STATISTICS_REDUCER_NAME,
  initialState,
  reducers: {
    setBalance: setBalanceState,
    setCountOfPayouts: setCountOfPayoutsState,
    setTurnover: setTurnoverState,
    setStatsTurnover: setStatsTurnoverState,
    setStatsCoins: setStatsCoinsState,
    setActiveStatsType: setActiveStatsTypeState,
    setActivePeriodType: setActivePeriodTypeState,
    setPeriod: setPeriodState,
    setLastTransactions: setLastTransactionsState,
    setNews: setNewsState,
    setIndustry: setIndustryState,
    setIsLoaded: setIsLoadedState,
    setIsFetching: setIsFetchingState,
    setErrorMessage: setErrorMessageState,
  },
});

export const {
  setBalance,
  setCountOfPayouts,
  setTurnover,
  setStatsTurnover,
  setStatsCoins,
  setActiveStatsType,
  setActivePeriodType,
  setPeriod,
  setIsLoaded,
  setLastTransactions,
  setNews,
  setIndustry,
  setIsFetching,
  setErrorMessage,
} = statisticsSlice.actions;
export const statisticsReducer = statisticsSlice.reducer;
