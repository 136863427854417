import * as React from 'react';
import { AddWalletCurrencies } from '../../../../../add-wallet-currencies';
import { CopyText } from '../../../../../ui/copy-text';
import { LoaderIcon } from '../../../../../icons/loader-icon';
import { Button } from '../../../../../ui/button';
import { OnboardingStepsHeader } from '../onboarding-steps-header';
import classes from './styles.module.scss';

type GenerateApiKeyStepTypes = {
  currencies: Array<{}>,
  onContinue: () => void,
  generatedApiKey: string | null,
  isFetching: boolean,
  onAddKey: (apiKey: string) => void,
};

export const GenerateApiKeyStep: React.FC<GenerateApiKeyStepTypes> = (props) => {
  const {
    currencies,
    onContinue,
    generatedApiKey,
    isFetching,
    onAddKey,
  } = props;

  return (
    <div className={classes.generateApiKeyStep}>
      {isFetching && (
        <LoaderIcon size={20} />
      )}
      {!isFetching && (
        <>
          <OnboardingStepsHeader
            title="Generate API-key"
            description={generatedApiKey ? 'Your API-key is successfully generated.' : 'Add your payout wallet to generate an API-key.'}
            hasBackButton={false}
          />
          {generatedApiKey ? (
            <>
              <p className={classes.copyTitle}>
                API-key
              </p>
              <CopyText
                className={classes.copyText}
                text={generatedApiKey}
                copyIconPath="\images\copy-small-icon.svg"
              />
              <Button
                className={classes.continueButton}
                onClick={onContinue}
              >
                Continue
              </Button>
            </>
          ) : (
            <AddWalletCurrencies
              className={classes.generateApiKeyStepAddWallet}
              currencies={currencies}
              onGenerateApiKey={onAddKey}
              formAttributes={{
                currencyTitle: 'Payout currency',
                currencyPlaceholder: 'Choose a currency',
                isSmallTheme: true,
                walletPlaceholder: 'Paste your address here',
                submitButtonLabel: 'Generate API-key',
              }}
            />
          )}
        </>
      )}
    </div>
  );
};
