import React from 'react';

/**
 * @param query {string}
 * @returns {boolean}
 */
export const useMediaQuery = (query) => {
  const [isMatch, setIsMatch] = React.useState(false);

  React.useEffect(() => {
    const mediaQuery = window.matchMedia(query);

    if (mediaQuery.matches !== isMatch) {
      setIsMatch(mediaQuery.matches);
    }
    const handleMediaQuery = () => {
      setIsMatch(Boolean(mediaQuery.matches));
    };

    mediaQuery.addListener(handleMediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQuery);
    };
  }, [isMatch, query]);

  return isMatch;
};
