import React from 'react';
import { useDispatch } from 'react-redux';
import { setBaseCurrency } from '../../../../store/payment-settings/reducer';
import { getSafeErrorMessageText } from '../../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../../helpers/console-error-message';
import { useUpdateBaseCurrency } from '../../../../api/modules/account/use-update-base-currency';
import { useUpdateFirstCurrency } from '../../../../api/modules/account/use-update-first-currency';
import { CurrenciesSelectField } from '../../../currencies-select-field';
import {
  Currency,
  ResponseType,
  FiatCurrency,
} from '../../../../default-types';
import classes from './styles.module.scss';

type PaymentDetailsCurrenciesProps = {
  currentBaseCurrencyCode: string | null,
  baseCurrencies: Array<FiatCurrency | Currency>,
  donationFirstCurrency: Currency | null,
  invoiceFirstCurrency: Currency | null,
  partnerCurrencies: Array<Currency>,
  onDonationFirstCurrencyChange: React.Dispatch<React.SetStateAction<Currency | null>>,
  onInvoiceFirstCurrencyChange: React.Dispatch<React.SetStateAction<Currency | null>>,
};

export const PaymentDetailsCurrencies: React.FC<PaymentDetailsCurrenciesProps> = (props) => {
  const {
    currentBaseCurrencyCode,
    baseCurrencies,
    donationFirstCurrency,
    invoiceFirstCurrency,
    partnerCurrencies,
    onDonationFirstCurrencyChange,
    onInvoiceFirstCurrencyChange,
  } = props;

  const fetchUpdateBaseCurrency = useUpdateBaseCurrency();
  const fetchUpdateFirstCurrency = useUpdateFirstCurrency();
  const dispatch = useDispatch();

  const [isResetFirstCurrencyInvoice, setIsResetFirstCurrencyInvoice] = React.useState(false);
  const [isResetFirstCurrencyDonation, setIsResetFirstCurrencyDonation] = React.useState(false);

  const handleBaseCurrencySelect = async (baseCurrency: FiatCurrency | Currency) => {
    const baseCurrencyCode = baseCurrency.code || '';

    const {
      data,
      status,
      errorMessage,
    }: ResponseType = await fetchUpdateBaseCurrency(baseCurrencyCode);

    if (status === 200) {
      dispatch(setBaseCurrency(baseCurrencyCode));
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/base-currency');
    }
  };

  const updateFirstCurrency = async (currency: Currency | null, currentCurrency: Currency | null, type: 'invoice' | 'donation') => {
    const currencyCode = currency?.code;

    if (currencyCode === currentCurrency?.code) return;

    const {
      data,
      status,
      errorMessage,
    }: ResponseType = await fetchUpdateFirstCurrency(currencyCode || '', type);

    if (status === 200) {
      const isInvoice = type === 'invoice';

      const stateUpdateFunction = isInvoice
        ? onInvoiceFirstCurrencyChange
        : onDonationFirstCurrencyChange;
      const resetFunction = isInvoice
        ? setIsResetFirstCurrencyInvoice
        : setIsResetFirstCurrencyDonation;

      stateUpdateFunction(currency);
      resetFunction(currency === null);
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/first-currencies');
    }
  };

  const handleFirstCurrencyInvoice = (currency: Currency) => updateFirstCurrency(currency, invoiceFirstCurrency, 'invoice');
  const handleFirstCurrencyDonation = (currency: Currency) => updateFirstCurrency(currency, donationFirstCurrency, 'donation');
  const resetFirstCurrencyInvoice = () => updateFirstCurrency(null, invoiceFirstCurrency, 'invoice');
  const resetFirstCurrencyDonation = () => updateFirstCurrency(null, donationFirstCurrency, 'donation');

  return (
    <ul>
      <li>
        <h3>Base fiat currency</h3>
        <p className={classes.selectLabel}>
          The currency used for pricing product pricing and fiat equivalents displays.
        </p>
        <CurrenciesSelectField
          className={classes.paymentDetailsCurrencySelect}
          currencies={baseCurrencies}
          defaultTicker={currentBaseCurrencyCode}
          onSelect={handleBaseCurrencySelect}
          smallTheme
          withoutOtherCurrencies
        />
      </li>
      <li>
        <h3>Preferred currencies</h3>
        <p>Selected currencies will be displayed at the top of the asset list for your clients.</p>
        <div className={classes.preferredCurrencies}>
          <div className={classes.preferredCurrency}>
            {invoiceFirstCurrency && (
              <button
                className={classes.clearButton}
                type="button"
                onClick={resetFirstCurrencyInvoice}
              >
                Clear
              </button>
            )}
            <CurrenciesSelectField
              className={classes.paymentDetailsCurrencySelect}
              label="For invoices"
              placeholder="Choose currency (optional)"
              currencies={partnerCurrencies}
              defaultTicker={invoiceFirstCurrency?.code}
              onSelect={handleFirstCurrencyInvoice}
              reset={isResetFirstCurrencyInvoice}
              smallTheme
            />
          </div>
          <div className={classes.preferredCurrency}>
            {donationFirstCurrency && (
              <button
                className={classes.clearButton}
                type="button"
                onClick={resetFirstCurrencyDonation}
              >
                Clear
              </button>
            )}
            <CurrenciesSelectField
              className={classes.paymentDetailsCurrencySelect}
              label="For donations"
              placeholder="Choose currency (optional)"
              currencies={partnerCurrencies}
              defaultTicker={donationFirstCurrency?.code}
              onSelect={handleFirstCurrencyDonation}
              reset={isResetFirstCurrencyDonation}
              smallTheme
            />
          </div>
        </div>
      </li>
    </ul>
  );
};
