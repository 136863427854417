import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { isFunction } from '../../../helpers/is-function';
import classes from './styles.module.scss';

export const CustomSearchField = (props) => {
  const {
    className,
    value,
    onChange,
    placeholder,
  } = props;

  const handleChange = (event) => {
    if (isFunction(onChange)) {
      onChange(event.target.value);
    }
  };

  return (
    <div className={cn(classes.field, className)}>
      <img
        className={classes.fieldIcon}
        src="/images/search-icon.svg"
        alt="Icon"
        decoding="async"
        width={16}
        height={16}
      />
      <input
        className={classes.fieldInput}
        type="text"
        onChange={handleChange}
        value={value}
        placeholder={placeholder}
      />
    </div>
  );
};

CustomSearchField.defaultProps = {
  className: null,
  value: null,
  onChange: null,
  placeholder: null,
};

CustomSearchField.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};
