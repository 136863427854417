import * as React from 'react';
import { OnboardingStepsHeaderProps } from '../../../../types';
import { isFunction } from '../../../../../../helpers/is-function';
import classes from './styles.module.scss';

export const OnboardingStepsHeader:React.FC<OnboardingStepsHeaderProps> = (props) => {
  const {
    title,
    description,
    hasBackButton,
    onClick,
  } = props;

  return (
    <div className={classes.onboardingStepsHeader}>
      <div className={classes.onboardingStepsHeaderContainer}>
        {hasBackButton && isFunction(onClick) && (
          <button
            className={classes.onboardingStepsHeaderBackButton}
            type="button"
            onClick={onClick}
          />
        )}
        <h3 className={classes.onboardingStepsHeaderTitle}>
          {title}
        </h3>
      </div>
      {description && (
        <p className={classes.onboardingStepsHeaderDescription}>
          {description}
        </p>
      )}
    </div>
  );
};
