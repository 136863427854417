import * as React from 'react';
import PropTypes from 'prop-types';

export const CustomersIcon = (props) => {
  const { color } = props;

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.4 5.5C3.4 4.57174 3.73714 3.6815 4.33726 3.02513C4.93737 2.36875 5.75131 2 6.6 2C7.44869 2 8.26263 2.36875 8.86274 3.02513C9.46286 3.6815 9.8 4.57174 9.8 5.5C9.8 6.42826 9.46286 7.3185 8.86274 7.97487C8.26263 8.63125 7.44869 9 6.6 9C5.75131 9 4.93737 8.63125 4.33726 7.97487C3.73714 7.3185 3.4 6.42826 3.4 5.5ZM1 15.1879C1 12.4945 2.995 10.3125 5.4575 10.3125H7.7425C10.205 10.3125 12.2 12.4945 12.2 15.1879C12.2 15.6363 11.8675 16 11.4575 16H1.7425C1.3325 16 1 15.6363 1 15.1879ZM16.2325 16H12.785C12.92 15.743 13 15.4449 13 15.125V14.9062C13 13.2465 12.3225 11.7563 11.255 10.7555C11.315 10.7527 11.3725 10.75 11.4325 10.75H12.9675C15.195 10.75 17 12.7242 17 15.1605C17 15.6254 16.655 16 16.2325 16ZM11.8 9C11.025 9 10.325 8.65547 9.8175 8.10039C10.31 7.37305 10.6 6.47344 10.6 5.5C10.6 4.76719 10.435 4.07539 10.1425 3.46836C10.6075 3.09648 11.18 2.875 11.8 2.875C13.3475 2.875 14.6 4.24492 14.6 5.9375C14.6 7.63008 13.3475 9 11.8 9Z" />
    </svg>
  );
};

CustomersIcon.defaultProps = {
  color: '#616161',
};

CustomersIcon.propTypes = {
  color: PropTypes.string,
};
