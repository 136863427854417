import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { CheckboxWithText } from '../../../ui/checkbox-with-text';
import { isFunction } from '../../../../helpers/is-function';
import { Button } from '../../../ui/button';
import { Field } from '../../../ui/field';
import { getIPsData, getNotValidIPAddress } from '../../enable-custody-steps-helpers';
import { useIpAddress } from '../../../../api/modules/account/use-ip-address';
import { LoaderIcon } from '../../../icons/loader-icon';
import classes from './styles.module.scss';

export const WhiteList = (props) => {
  const {
    className,
    onClick,
    apiErrorMessage,
    fetching,
  } = props;

  const fetchIpAddress = useIpAddress();

  const [currentIpAddress, setCurrentIpAddress] = React.useState(null);
  const [additionalIPs, setAdditionalIPs] = React.useState('');
  const [isCurrentIPChecked, setIsCurrentIPChecked] = React.useState(true);
  const [isChecked, setIsChecked] = React.useState(false);
  const [isCurrentIPError, setIsCurrentIPError] = React.useState(false);
  const [isCheckedError, setIsCheckedError] = React.useState(false);
  const [wrongIPAddressText, setWrongIPAddressText] = React.useState(null);
  const [isIpAddressFetching, setIsIpAddressFetching] = React.useState(false);
  const [ipAddressErrorMessage, setIpAddressErrorMessage] = React.useState(null);

  const handleCurrentIPCheck = () => {
    setIsCurrentIPChecked((prev) => !prev);
    setIsCurrentIPError(false);
  };
  const handleAdditionalIPChange = (value) => {
    if (wrongIPAddressText) {
      setWrongIPAddressText(null);
    }
    setAdditionalIPs(value);
  };
  const handleAgreementCheck = () => {
    setIsChecked((prev) => !prev);
    setIsCheckedError(false);
  };
  const handleButtonClick = () => {
    let isValid = true;

    if (!isCurrentIPChecked && !additionalIPs) {
      setIsCurrentIPError(true);
      isValid = false;
    }

    if (additionalIPs) {
      const notValidAddress = getNotValidIPAddress(additionalIPs);

      if (notValidAddress) {
        const template = `IP-address ${notValidAddress} is not valid.`;
        setWrongIPAddressText(template);
        isValid = false;
      }
    }

    if (!isChecked) {
      setIsCheckedError(true);
      isValid = false;
    }

    if (isValid && isFunction(onClick)) {
      const currentIpAddressNormalized = isCurrentIPChecked ? currentIpAddress : null;
      const ips = getIPsData(currentIpAddressNormalized, additionalIPs);
      onClick(ips);
    }
  };
  const getIpAddress = async () => {
    setIsIpAddressFetching(true);
    const { data, status } = await fetchIpAddress();
    setIsIpAddressFetching(false);

    if (status === 200) {
      const ipAddress = data?.ip ?? null;

      setCurrentIpAddress(ipAddress);
    } else {
      const errorMessage = data?.errorData?.message ?? null;
      const currentErrorMessage = typeof errorMessage === 'string'
        ? errorMessage
        : 'Something went wrong';
      setIpAddressErrorMessage(currentErrorMessage);
    }
  };

  React.useEffect(() => {
    getIpAddress();
  }, []);

  const isErrorShow = isCurrentIPError || isCheckedError;

  return (
    <div className={cn(classes.whiteList, className)}>
      <div className={classes.whiteListTitle}>
        Whitelist your IP-addresses
      </div>
      <CheckboxWithText
        className={classes.whiteListCurrentIP}
        onChange={handleCurrentIPCheck}
        checked={isCurrentIPChecked}
        error={isCurrentIPError}
      >
        Include in the list my
        {' '}
        <strong>current IP-address:</strong>
        {' '}
        {isIpAddressFetching && (
          <LoaderIcon size={20}  className={classes.whiteListCurrentIPLoader} />
        )}
        {!isIpAddressFetching && (
          <>
            {currentIpAddress && (
              <strong>{currentIpAddress}</strong>
            )}
            {!currentIpAddress && ipAddressErrorMessage && (
              <span className={classes.whiteListCurrentIPError}>
                <img
                  src="/images/attention-red-icon.svg"
                  alt="Icon"
                  decoding="async"
                  width={19}
                  height={19}
                />
                {' '}
                {ipAddressErrorMessage}
              </span>
            )}
          </>
        )}
      </CheckboxWithText>
      <div className={classes.whiteListAdditionalIP}>
        <Field
          className={classes.whiteListAdditionalIPField}
          title="Additional IP-addresses"
          data={{
            id: 'additional-IP',
            value: additionalIPs,
            onChange: handleAdditionalIPChange,
            name: 'additional-IP',
            placeholder: 'Example: 193.233.155.111,193.233.155.112',
            error: wrongIPAddressText,
            pattern: /^[\w.,:]*$/,
          }}
        />
        <div className={classes.whiteListAdditionalIPText}>
          <img
            src="/images/attention-icon.svg"
            alt="Icon"
            decoding="async"
            width={19}
            height={19}
          />
          <div>
            To change or add IP address in future, an official request to
            {' '}
            <a href="mailto:partners@nowpayments.io">partners@nowpayments.io</a>
            {' '}
            is required
          </div>
        </div>
      </div>
      <CheckboxWithText
        className={classes.whiteListAgreement}
        onChange={handleAgreementCheck}
        checked={isChecked}
        error={isCheckedError}
      >
        I intent to include the aforementioned IP address(es) to whitelist and take all the risks related to it
      </CheckboxWithText>
      <div className={classes.whiteListButtonBox}>
        <Button
          className={classes.whiteListButton}
          onClick={handleButtonClick}
        >
          {fetching && (
            <LoaderIcon
              size={25}
              path="/images/loader-white-on-blue-icon.gif"
            />
          )}
          {!fetching && 'Continue'}
        </Button>
        {isErrorShow && (
          <span className={classes.whiteListButtonError}>
            <img
              src="/images/attention-icon.svg"
              alt="Icon"
              decoding="async"
              width={19}
              height={19}
            />
            Please check the box
          </span>
        )}
        {apiErrorMessage && !isErrorShow && (
          <span className={cn(classes.whiteListButtonError, classes.whiteListButtonApiError)}>
            {apiErrorMessage}
          </span>
        )}
      </div>
    </div>
  );
};

WhiteList.defaultProps = {
  className: null,
  onClick: null,
  apiErrorMessage: null,
  fetching: false,
};

WhiteList.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  apiErrorMessage: PropTypes.string,
  fetching: PropTypes.bool,
};
