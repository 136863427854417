import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { safeToUpperCase } from '../../../../helpers/safe-to-upper-case';
import { formatDate } from '../../../../helpers/format-date';
import { isFunction } from '../../../../helpers/is-function';
import { DATE_PATTERN } from '../../../../constants/app-constants';
import styles from './styles.module.scss';

const InvoiceHistoryItem = (props) => {
  const {
    className,
    invoiceId,
    orderId,
    priceAmount,
    priceTicker,
    payTicker,
    url,
    created,
    onClick,
    currenciesObject,
  } = props;

  const currentOrderId = orderId || '-';
  const currentPriceAmount = priceAmount || null;

  const priceTickerUpperCased = safeToUpperCase(priceTicker) || '';
  const priceCurrencyFound = currenciesObject[priceTickerUpperCased] || {};
  const priceCurrentTicker = priceCurrencyFound?.ticker ?? priceTickerUpperCased;
  const priceNormalized = currentPriceAmount ? `${currentPriceAmount} ${priceCurrentTicker}` : '-';

  const payTickerUpperCased = safeToUpperCase(payTicker) || null;
  const payCurrencyFound = currenciesObject[payTickerUpperCased] || {};
  const currentPayTicker = payCurrencyFound?.ticker ?? payTickerUpperCased;
  const payTickerNormalized = currentPayTicker || '-';

  const dateFormatted = formatDate(created, DATE_PATTERN);
  const dateNormalized = dateFormatted || '-';
  const urlTextNormalized = typeof url === 'string'
    ? url.split('https://').join('')
    : url;

  const handleRowClick = () => {
    if (isFunction(onClick)) {
      onClick(invoiceId);
    }
  };

  const onLinkClick = (e) => {
    e.stopPropagation();
  };

  return (
    <tr
      className={cn(styles.historyItem, className)}
      onClick={handleRowClick}
    >
      <td>
        {invoiceId}
      </td>
      <td>
        {currentOrderId}
      </td>
      <td className={styles.historyItemColorBlack}>
        {priceNormalized}
      </td>
      <td>
        {payTickerNormalized}
      </td>
      <td>
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          onClick={onLinkClick}
        >
          {urlTextNormalized}
        </a>
      </td>
      <td>
        {dateNormalized}
      </td>
    </tr>
  );
};

InvoiceHistoryItem.defaultProps = {
  className: null,
  invoiceId: null,
  orderId: null,
  priceAmount: null,
  priceTicker: null,
  payTicker: null,
  url: null,
  created: null,
  onClick: null,
  currenciesObject: {},
};

InvoiceHistoryItem.propTypes = {
  className: PropTypes.string,
  invoiceId: PropTypes.string,
  orderId: PropTypes.string,
  priceAmount: PropTypes.string,
  priceTicker: PropTypes.string,
  payTicker: PropTypes.string,
  url: PropTypes.string,
  created: PropTypes.string,
  onClick: PropTypes.func,
  currenciesObject: PropTypes.object,
};

export const InvoiceHistoryItemMemo = React.memo(InvoiceHistoryItem);
