import React from 'react';
import { CopyText } from '../ui/copy-text';
import { QrCodeWithLogo } from '../shared/qr-code-with-logo';
import { getUrlWithDomain } from '../../helpers/get-url-with-domain';
import { MinAmountText } from '../min-amount-text';
import { LoaderIcon } from '../icons/loader-icon';
import { Currency } from '../../default-types';
import classes from './styles.module.scss';

type DepositAddressTopUpProps = {
  address: string,
  baseCurrency: string,
  minAmount: number,
  error: string | null,
  currency: Currency,
  isFetching: boolean,
};

export const DepositAddressTopUp: React.FC<DepositAddressTopUpProps> = (props) => {
  const {
    address,
    baseCurrency,
    minAmount,
    error,
    currency,
    isFetching,
  } = props;

  const ticker = currency?.code || '';
  const currentTicker = currency?.ticker || '';
  const network = currency?.network || '';
  const name = currency?.name || '';
  const imagePath = getUrlWithDomain(currency?.logo_url || '');

  return (
    <div className={classes.depositAddressTopUp}>
      <p className={classes.depositAddressTopUpText}>
        To top up your balance, please send the required amount to the address provided below.
      </p>
      <p className={classes.depositAddressTopUpCurrency}>
        {`Your deposit ${ticker} (${name})`}
      </p>
      {isFetching && (
        <LoaderIcon size={20} />
      )}
      {!isFetching && !error && (
        <>
          <QrCodeWithLogo
            value={address}
            size={180}
            imagePath={imagePath}
            imageWidth={40}
            imageHeight={40}
            excavate
          />
          <CopyText
            className={classes.depositAddressTopUpCopy}
            copyIconPath="/images/copy-small-icon.svg"
            text={address}
          />
        </>
      )}
      <MinAmountText
        className={classes.depositAddressTopUpMinAmount}
        minAmount={minAmount}
        ticker={ticker}
        currentTicker={currentTicker}
        network={network}
        baseCurrency={baseCurrency}
      />
      {error}
    </div>
  );
};
