import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSafeErrorMessageText } from '../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../helpers/console-error-message';
import { useStatus } from '../api/modules/account/use-status';
import { setSystemStatus } from '../store/settings/reducer';
import { ResponseType } from '../default-types';
import { systemStatusSelector } from '../store/settings/selectors';

export const useSystemStatus = () => {
  const dispatch = useDispatch();
  const fetchSystemStatus = useStatus();
  const systemStatus = useSelector(systemStatusSelector());

  const init = React.useCallback(async (): Promise<void> => {
    if (systemStatus) {
      return;
    }
    const { data, status, errorMessage }: ResponseType = await fetchSystemStatus();

    if (status === 200) {
      dispatch(setSystemStatus(data));
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/status');
    }
  }, [dispatch, fetchSystemStatus, systemStatus]);

  React.useEffect(() => {
    if (systemStatus) {
      return () => {};
    }
    const timerId = setTimeout(() => {
      init();
    }, 0);

    return () => {
      clearTimeout(timerId);
    };
  }, [systemStatus]);
};
