import React from 'react';
import { PaymentsInfoBox } from '../../../payments-info-box';
import { PaymentsInfoButtons } from '../../../payments-info-buttons';
import { Popup } from '../../../popup';
import { DashboardTransactionInfoPopupType } from '../../types';

export const DashboardTransactionInfoPopup: React.FC<DashboardTransactionInfoPopupType> = (props) => {
  const {
    open,
    onClose,
    paymentId,
    items,
  } = props;

  return (
    <Popup
      open={open}
      onClose={onClose}
      withCloseButton
    >
      <PaymentsInfoBox
        title="Payment ID"
        id={paymentId}
        items={items}
      />
      <PaymentsInfoButtons onClose={onClose} />
    </Popup>
  );
};
