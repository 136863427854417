import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../ui/button';
import { Portal } from '../../../portal';
import { Popup } from '../../../popup';
import { OnboardingSteps } from '../onboarding-steps';
import { useApiKeysData } from '../../../../hooks/use-api-keys-data';
import {
  apiKeysFetchedSelector,
  apiKeysHasActiveKeySelector,
  walletsSelector,
} from '../../../../store/payment-settings/selectors';
import { LoaderIcon } from '../../../icons/loader-icon';
import { setApiKeysFetched } from '../../../../store/payment-settings/reducer';
import { getSafeErrorMessageText } from '../../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../../helpers/console-error-message';
import { useCreateApiKey } from '../../../../api/modules/account/use-create-api-key';
import { useStoreOutcomeData } from '../../../../hooks/use-store-outcome-data';
import { hasElementsInArray } from '../../../../helpers/has-elements-in-array';
import { ResponseType } from '../../../../default-types';
import classes from './stylse.module.scss';

export const DashboardGetStarted = () => {
  const hasActiveKey = useSelector(apiKeysHasActiveKeySelector());
  const isKeysFetched = useSelector(apiKeysFetchedSelector());
  const wallets = useSelector(walletsSelector());
  const fetchCreateApiKey = useCreateApiKey();
  const dispatch = useDispatch();
  const hasWallets = hasElementsInArray(wallets);

  const [isOpen, setIsOpen] = React.useState(false);
  const [generatedApiKey, setGeneratedApiKey] = React.useState<string | null>(null);
  const [isFetching, setIsFetching] = React.useState(false);

  const getApiKey = React.useCallback(async () => {
    setIsFetching(true);
    const {
      data,
      status,
      errorMessage,
    }: ResponseType = await fetchCreateApiKey();

    if (status === 200) {
      setGeneratedApiKey(data?.apiKey);
    } else {
      const currentErrorMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, currentErrorMessage, '/api-keys/create');
    }
    setIsFetching(false);
  }, [fetchCreateApiKey]);

  const handleModalOpen = async () => {
    if (!hasActiveKey && hasWallets) {
      await getApiKey();
    }
    setIsOpen(true);
  };
  const handleModalClose = () => {
    if (generatedApiKey) {
      dispatch(setApiKeysFetched(false));
    }
    setGeneratedApiKey(null);
    setIsOpen(false);
  };
  const handleAddKey = (apiKey: string) => {
    setGeneratedApiKey(apiKey);
  };

  useApiKeysData();
  useStoreOutcomeData();

  return (
    <div className={classes.dashboardGetStarted}>
      <h2>
        Get started
        <span> NOW</span>
      </h2>
      <p className={classes.dashboardGetStartedDescription}>
        Complete a quick account setup and start accepting crypto in just 5 minutes.
      </p>
      <Button
        className={classes.dashboardGetStartedButton}
        onClick={handleModalOpen}
      >
        Start integration
      </Button>
      <Portal>
        <Popup
          className={classes.dashboardGetStartedPopup}
          open={isOpen}
          onClose={handleModalClose}
          withCloseButton
          mobileFullView
        >
          {isKeysFetched ? (
            <OnboardingSteps
              startStep={hasActiveKey ? 2 : 1}
              generatedApiKey={generatedApiKey}
              isFetching={isFetching}
              onAddKey={handleAddKey}
            />
          ) : (
            <LoaderIcon size={20} />
          )}
        </Popup>
      </Portal>
    </div>
  );
};
