import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

const TextArea = (props, { readOnly = false }) => {
  const {
    value,
    className,
    ...propsWithoutValue
  } = props;

  if (readOnly) {
    return (
      <textarea
        {...propsWithoutValue}
        className={cn(styles.wrapper, className)}
        readOnly={readOnly}
        defaultValue={value}
      />
    );
  }

  return <textarea {...props} className={cn(styles.wrapper, className)} />;
};

export default TextArea;
