import * as React from 'react';
import { safeToLowerCase } from '../../../helpers/safe-to-lower-case';
import { isFunction } from '../../../helpers/is-function';
import { useMinAmount as useFetchMinAmount } from '../../../api/modules/account/use-min-amount';
import { useProfileInfoState } from './use-profile-info-context';

export const useMinAmount = (setShowError) => {
  const [minAmount, setMinAmount] = React.useState(0);
  const fetchMinAmount = useFetchMinAmount();
  const { profileInfo: { baseCurrency } } = useProfileInfoState();

  const changePayCurrency = React.useCallback(async (value) => {
    setMinAmount(0);

    if (!value) {
      return;
    }

    const valueLowerCased = safeToLowerCase(value);
    const { data, errorMessage } = await fetchMinAmount({ from: valueLowerCased, to: baseCurrency });
    const { estimatedAmountTo } = data || {};

    if (errorMessage && isFunction(setShowError)) {
      setShowError(errorMessage);
    } else {
      setMinAmount(estimatedAmountTo);
    }
  }, [setMinAmount, baseCurrency, setShowError]);

  return {
    minAmount,
    changePayCurrency,
  };
};
