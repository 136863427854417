import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Popup } from '../../popup';
import { ExportButtons } from '../export-buttons';
import { Portal } from '../../portal';
import classes from './styles.module.scss';

export const ExportPopup = (props) => {
  const {
    className,
    open,
    onClose,
    onExportPdf,
    onExport,
    fetching,
    errorMessage,
  } = props;

  return (
    <Portal>
      <Popup
        classNameContent={cn(classes.exportPopup, className)}
        open={open}
        onClose={onClose}
      >
        <ExportButtons
          onExportPdf={onExportPdf}
          onExport={onExport}
          fetching={fetching}
          errorMessage={errorMessage}
        />
      </Popup>
    </Portal>
  );
};

ExportPopup.defaultProps = {
  className: null,
  open: false,
  onClose: null,
  onExportPdf: null,
  onExport: null,
  fetching: false,
  errorMessage: null,
};

ExportPopup.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onExportPdf: PropTypes.func,
  onExport: PropTypes.func,
  fetching: PropTypes.bool,
  errorMessage: PropTypes.string,
};
