import React from 'react';
import PropTypes from 'prop-types';

export const AffiliateProgramIcon = (props) => {
  const { color } = props;

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9705 5.46829C12.9705 7.67106 11.2043 9.43733 9 9.43733C6.79642 9.43733 5.02951 7.67106 5.02951 5.46829C5.02951 3.26552 6.79642 1.5 9 1.5C11.2043 1.5 12.9705 3.26552 12.9705 5.46829ZM9 16.5C5.74678 16.5 3 15.9712 3 13.9312C3 11.8905 5.76404 11.3804 9 11.3804C12.254 11.3804 15 11.9092 15 13.9492C15 15.99 12.236 16.5 9 16.5Z"
      />
    </svg>
  );
};

AffiliateProgramIcon.defaultProps = {
  color: '#616161',
};

AffiliateProgramIcon.propTypes = {
  color: PropTypes.string,
};
