import { hasElementsInArray } from './has-elements-in-array';
import { getFiatPriceFromCrypto } from './get-fiat-price-from-crypto';
import { safeToUpperCase } from './safe-to-upper-case';
import { safeToLowerCase } from './safe-to-lower-case';

/**
 * @param {Array<object>} balances
 * @param {Object} currenciesObject
 * @param {Object} rates
 * @param {Boolean} isLegacyTicker
 * @returns {*|number}
 */
export const getFiatAmountOfBalances = (balances = [], currenciesObject, rates, isLegacyTicker = false) => {
  if (!hasElementsInArray(balances)) {
    return 0;
  }

  return balances.reduce((acc, item) => {
    const amount = item?.amount ?? 0;
    const currentTicker = !isLegacyTicker ? item.ticker : item.code;
    const tickerUpperCased = safeToUpperCase(currentTicker);
    const currencyFoundTicker = currenciesObject?.[tickerUpperCased]?.ticker ?? null;
    const currentRate = rates?.[safeToLowerCase(currencyFoundTicker)] || null;
    const fiatPrice = getFiatPriceFromCrypto(amount, currentRate);
    acc += fiatPrice;

    return acc;
  }, 0);
};
