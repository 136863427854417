import React from 'react';
import PropTypes from 'prop-types';

export const KeysIcon = (props) => {
  const { color } = props;

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.5364 3.2945C11.5364 3.52971 11.654 3.80432 11.8707 4.02103C12.0504 4.20012 12.2784 4.32012 12.4963 4.34877C12.6886 4.37384 12.8539 4.32788 12.962 4.21923C13.2056 3.97625 13.1124 3.47658 12.7638 3.12794C12.4146 2.77871 11.9149 2.68797 11.6725 2.92974C11.5806 3.02168 11.5364 3.15063 11.5364 3.2945Z" />
      <path d="M7.4707 6.65716C7.49755 6.71283 7.50646 6.77547 7.4962 6.83642C7.48593 6.89738 7.457 6.95364 7.41339 6.99744L6.67194 7.7389L6.36449 9.03733C6.35181 9.09074 6.32465 9.13961 6.286 9.17858C6.24734 9.21755 6.19869 9.2451 6.1454 9.25822L4.86785 9.57462L4.57533 10.8271C4.56293 10.8799 4.53637 10.9284 4.49851 10.9673C4.46065 11.0063 4.41292 11.0341 4.36042 11.048L3.10019 11.3787L2.78677 12.6174C2.77399 12.6693 2.74745 12.7168 2.70994 12.7548C2.67242 12.7928 2.62534 12.82 2.57365 12.8335L1.32834 13.1613L1 15L2.93423 14.6537L9.00196 8.58721C9.09151 8.49766 9.22822 8.47378 9.34224 8.5293C10.0803 8.88257 10.9098 8.99804 11.7163 8.85978C12.5228 8.72152 13.2664 8.33634 13.8447 7.7574C14.5927 7.00938 15.0046 6.01421 15.0046 4.95636C15.0046 3.8985 14.5927 2.90333 13.8447 2.15531C12.3027 0.613305 9.79117 0.615096 8.24678 2.15949C7.66758 2.73664 7.28185 3.47936 7.14283 4.28511C7.00382 5.09086 7.11838 5.9193 7.4707 6.65716ZM13.1838 2.70752C13.5181 3.04064 13.6913 3.4639 13.6913 3.85552C13.6913 4.15401 13.5898 4.43519 13.3826 4.64354C13.1438 4.88233 12.7916 4.99098 12.4167 4.94203C12.0657 4.89666 11.7212 4.71936 11.4466 4.44474C10.858 3.85671 10.769 2.9881 11.2484 2.50932C11.7278 2.02995 12.5958 2.11949 13.1838 2.70752Z" />
    </svg>
  );
};

KeysIcon.defaultProps = {
  color: '#A4A4A4',
};

KeysIcon.propTypes = {
  color: PropTypes.string,
};
