import * as React from 'react';
import { PageHeader } from '../../../ui/page-header';
import { InnerNavigation } from '../../../shared/inner-navigation';
import { INNER_NAVIGATION_ITEMS } from '../../helpers';
import { PagesWrapperProps } from '../../types';
import classes from './styles.module.scss';

export const PagesWrapper: React.FC<PagesWrapperProps> = (props) => {
  const { children } = props;

  return (
    <div className={classes.pagesWrapper}>
      <PageHeader
        className={classes.title}
        title="Fiat operations"
      />
      <InnerNavigation
        className={classes.navigation}
        items={INNER_NAVIGATION_ITEMS}
      />
      <div className={classes.content}>
        {children}
      </div>
    </div>
  );
};
