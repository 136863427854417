import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { hasElementsInArray } from '../../../helpers/has-elements-in-array';
import { CopyText } from '../../ui/copy-text';
import { Button } from '../../ui/button';
import { CurrencyNetworkSup } from '../currency-network-sup';
import classes from './styles.module.scss';

export const NewInfoBox = (props) => {
  const {
    className,
    title,
    id,
    items,
    verified,
    onClick,
    on2faToggle,
    showButtonVerify,
  } = props;

  const hasItems = hasElementsInArray(items);

  return (
    <div className={cn(classes.newInfoBox, className)}>
      <div className={classes.newInfoBoxHeader}>
        <div className={classes.newInfoBoxTitle}>
          {title}
        </div>
        <div className={classes.newInfoBoxId}>
          {id}
        </div>
      </div>
      {hasItems && (
        <div className={classes.newInfoBoxList}>
          {items.map((item) => item?.value && (
            <div
              key={item?.id}
              className={classes.newInfoBoxItem}
            >
              <div className={classes.newInfoBoxItemKey}>
                {item?.key}
              </div>
              {!item?.isCopied && !item?.link && (
                <div className={cn([
                  classes.newInfoBoxItemValue,
                  item?.network && classes.newInfoBoxItemValueFlex,
                ])}
                >
                  <span dangerouslySetInnerHTML={{ __html: item.value }} />
                  {item?.network && (
                    <CurrencyNetworkSup network={item.network} />
                  )}
                </div>
              )}
              {item?.isCopied && (
                <div className={classes.newInfoBoxItemValue}>
                  <CopyText
                    text={item.value}
                    ellipsis={item?.isShort}
                  />
                </div>
              )}
              {item?.link && (
                <a
                  className={classes.newInfoBoxItemValue}
                  href={item.link}
                  target="_blank"
                  rel="nofollow noreferrer noopener"
                >
                  <CopyText
                    text={item.value}
                    ellipsis={item?.isShort}
                    copyIconPath="/images/copy-icon.svg"
                  />
                </a>
              )}
            </div>
          ))}
        </div>
      )}
      <div className={classes.newInfoBoxButtons}>
        <Button
          onClick={onClick}
          className={classes.newInfoBoxButton}
        >
          Ok
        </Button>
        {!verified && showButtonVerify && (
          <Button
            onClick={on2faToggle}
            className={classes.newInfoBoxButton}
          >
            Verify
          </Button>
        )}
      </div>
    </div>
  );
};

NewInfoBox.defaultProps = {
  className: null,
  title: null,
  id: null,
  items: null,
  verified: true,
  onClick: null,
  on2faToggle: null,
  showButtonVerify: true,
};

NewInfoBox.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    key: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
  })),
  verified: PropTypes.bool,
  onClick: PropTypes.func,
  on2faToggle: PropTypes.func,
  showButtonVerify: PropTypes.bool,
};
