import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';
import { buttonState } from '../../components/submit-button';
import apiClient from '../../api/api-client';
import {
  PASSWORD_MIN_LENGTH,
  ERROR_MESSAGES,
} from '../../constants/app-constants';
import AppsSettings from './apps-settings';
import PasswordSettings from './password-settings';
import ProfileSettings from './profile-settings';
import TwoFactorAuthentication from './two-factor-authentication';
import ShopifySettings from './shopify-settings';
import MyTeam from './my-team';

const STORE_DETAILS_SHORT_TEXT = 'Store details length must be at least 16 symbols.';
const STORE_DETAILS_LONG_TEXT = 'Store details length must be no more than 256 symbols.';

class AccountSettingsPage extends Component {
  state = {
    companyName: '',
    storeDetails: '',
    hasTfa: false,
    backupKey: '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    profileSettingsErrorMessage: '',
    profileSettingsButtonState: buttonState.normal,
    changePasswordButtonState: buttonState.normal,
    changePasswordButtonFailureMessage: '',
    storeDetailsErrorMessage: '',
  }

  init = async () => {
    const { backup_key } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });

    this.props.history.replace('/account-settings');

    try {
      const result = await apiClient.getProfile();

      const { company_name, has_tfa, store_details = '' } = result;

      this.setState({
        companyName: company_name,
        storeDetails: store_details,
        hasTfa: has_tfa,
        backupKey: backup_key || '',
      });
    } catch (e) {
      // TODO: show error
    }
  }

  handleCompanyNameChange = (value) => {
    this.setState({ companyName: value });
  }

  handleStoreDetailsChange = (value) => {
    this.setState({
      storeDetails: value,
      storeDetailsErrorMessage: '',
    });
  }

  handleCurrentPasswordChange = (value) => {
    this.setState({ currentPassword: value });
  }

  handleNewPasswordChange = (value) => {
    this.setState({ newPassword: value });
  }

  handleConfirmPasswordChange = (value) => {
    this.setState({ confirmPassword: value });
  }

  handleProfileSettingsSubmit = async (companyNameValue, storeDetailsValue) => {
    const storeDetailsLength = storeDetailsValue.length;
    const isStoreDetailsShort = storeDetailsLength < 16;
    const isStoreDetailsLong = storeDetailsLength > 256;

    if (storeDetailsValue && isStoreDetailsShort) {
      this.setState({
        storeDetailsErrorMessage: STORE_DETAILS_SHORT_TEXT,
      });

      return;
    }

    if (storeDetailsValue && isStoreDetailsLong) {
      this.setState({
        storeDetailsErrorMessage: STORE_DETAILS_LONG_TEXT,
      });

      return;
    }

    this.setState({ profileSettingsButtonState: buttonState.processing });
    const { status, errorMessage } = await apiClient.saveProfile({
      company_name: companyNameValue || null,
      store_details: storeDetailsValue || null,
    });

    if (status === 200) {
      this.setState({ profileSettingsButtonState: buttonState.success });
    } else {
      this.setState({
        profileSettingsButtonState: buttonState.failure,
        profileSettingsErrorMessage: errorMessage,
      });
    }

    setTimeout(() => {
      this.setState({ profileSettingsButtonState: buttonState.normal });
    }, 2000);
  }

  setChangePasswordButtonState(state, message = '') {
    this.setState({
      changePasswordButtonState: state,
      changePasswordButtonFailureMessage: message,
    });
    setTimeout(
      () =>
        this.setState({
          changePasswordButtonState: buttonState.normal,
          changePasswordButtonFailureMessage: '',
        }),
      2000,
    );
  }

  validatePassword() {
    const { currentPassword, newPassword, confirmPassword } = this.state;

    if (!currentPassword || !newPassword || !confirmPassword) {
      return ERROR_MESSAGES.empty_fields;
    }
    if (newPassword.length < PASSWORD_MIN_LENGTH) {
      return ERROR_MESSAGES.password_length;
    }
    if (newPassword !== confirmPassword) {
      return ERROR_MESSAGES.not_match_passwors;
    }
  }

  changePassword = async () => {
    const { currentPassword, newPassword } = this.state;
    const validError = this.validatePassword();

    if (validError !== undefined) {
      this.setChangePasswordButtonState(buttonState.failure, validError);

      return;
    }
    try {
      this.setState({
        changePasswordButtonState: buttonState.processing,
      });
      await apiClient.post('profile/change-password', {
        password: currentPassword,
        new_password: newPassword,
      });
      this.setChangePasswordButtonState(buttonState.success);
      setTimeout(() => {
        localStorage.removeItem('authToken');
        window.location.reload();
      }, 3000);
    } catch (error) {
      let changePasswordError = 'Error';

      if (error.response && error.response.data && error.response.data.error) {
        changePasswordError = error.response.data.error;
      }
      this.setChangePasswordButtonState(
        buttonState.failure,
        changePasswordError,
      );
    }
  }

  setTfa = (isHasTfa, backupKey) => {
    this.setState({
      hasTfa: isHasTfa,
      backupKey,
    });
  }

  componentDidMount() {
    this.init();
  }

  render() {
    const {
      companyName,
      storeDetails,
      profileSettingsErrorMessage,
      profileSettingsButtonState,
      storeDetailsErrorMessage,
    } = this.state;

    return (
      <>
        <h1>Account Settings</h1>
        <MyTeam />
        <ProfileSettings
          companyText={companyName}
          onCompanyText={this.handleCompanyNameChange}
          storeDetailText={storeDetails}
          onStoreDetailText={this.handleStoreDetailsChange}
          onSubmit={this.handleProfileSettingsSubmit}
          errorMessage={profileSettingsErrorMessage}
          buttonState={profileSettingsButtonState}
          storeDetailsErrorMessage={storeDetailsErrorMessage}
        />
        <ShopifySettings />
        <PasswordSettings
          onCurrentPasswordChange={this.handleCurrentPasswordChange}
          onNewPasswordChange={this.handleNewPasswordChange}
          onConfirmPasswordChange={this.handleConfirmPasswordChange}
          submitButtonState={this.state.changePasswordButtonState}
          submitButtonFailureMessage={
            this.state.changePasswordButtonFailureMessage
          }
          onSubmit={this.changePassword}
        />
        <TwoFactorAuthentication
          hasTfa={this.state.hasTfa}
          setTfa={this.setTfa}
          backupKey={this.state.backupKey}
        />
        <AppsSettings />
      </>
    );
  }
}

export default withRouter(AccountSettingsPage);
