import React from 'react';
import { getMasterId } from '../../libs/local-storage';
import styles from './styles.module.scss';

const WarningBlock = ({ apiKeys }) => {
  const masterId = getMasterId();

  if (masterId && !apiKeys.length) {
    return (
      <div className={styles.warningBlock}>
        Donate links depend on api keys. Get access to change api keys from your
        manager to be able to edit donate links.
      </div>
    );
  }

  if (!apiKeys.length) {
    return (
      <div className={styles.warningBlock}>
        Please generate your API key in Store Settings first.
      </div>
    );
  }

  return null;
};

export default WarningBlock;
