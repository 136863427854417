import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Popup } from '../popup';
import { CreateSubscriptionPlanForm } from '../create-subscription-plan-form';
import classes from './styles.module.scss';

export const PopupCreateSubscriptionPlan = (props) => {
  const {
    className,
    title,
    open,
    onClose,
    fullBaseCurrency,
    onSubmit,
    errorApiMessage,
    pending,
    advanceSettingsShow,
  } = props;

  return (
    <Popup
      className={classes.popup}
      classNameContent={cn(classes.popupContent, className)}
      title={title}
      open={open}
      onClose={onClose}
      withCloseButton
      mobileFullView
      line
    >
      <CreateSubscriptionPlanForm
        className={classes.popupForm}
        fullBaseCurrency={fullBaseCurrency}
        onSubmit={onSubmit}
        errorApiMessage={errorApiMessage}
        pending={pending}
        advanceSettingsShow={advanceSettingsShow}
      />
    </Popup>
  );
};

PopupCreateSubscriptionPlan.defaultProps = {
  className: null,
  open: null,
  onClose: null,
  fullBaseCurrency: null,
  onSubmit: null,
  errorApiMessage: null,
  pending: false,
  advanceSettingsShow: false,
};

PopupCreateSubscriptionPlan.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  fullBaseCurrency: PropTypes.object,
  onSubmit: PropTypes.func,
  errorApiMessage: PropTypes.string,
  pending: PropTypes.bool,
  advanceSettingsShow: PropTypes.bool,
};
