import { PAYMENT_SETTINGS_REDUCER_NAME } from './reducer';

export const storeDataSelector = () => (state) => (
  state[PAYMENT_SETTINGS_REDUCER_NAME]?.storeData
);
export const isStoreDataFetchedSelector = () => (state) => (
  state[PAYMENT_SETTINGS_REDUCER_NAME]?.isStoreDataFetched
);
export const storeDataErrorSelector = () => (state) => (
  state[PAYMENT_SETTINGS_REDUCER_NAME]?.storeDataError
);
export const balancesSelector = () => (state) => (
  state[PAYMENT_SETTINGS_REDUCER_NAME]?.balances
);

export const walletsSelector = () => (state) => (
  state[PAYMENT_SETTINGS_REDUCER_NAME]?.wallets
);

export const apiKeysSelector = () => (state) => state[PAYMENT_SETTINGS_REDUCER_NAME]?.apiKeys;

export const apiKeysHasActiveKeySelector = () => (state) => state[PAYMENT_SETTINGS_REDUCER_NAME]?.hasActiveApiKey;

export const apiKeysFetchedSelector = () => (state) => state[PAYMENT_SETTINGS_REDUCER_NAME]?.apiKeysFetched;

export const apiKeysErrorSelector = () => (state) => state[PAYMENT_SETTINGS_REDUCER_NAME]?.apiKeysError;

export const baseCurrencySelector = () => (state) => state[PAYMENT_SETTINGS_REDUCER_NAME]?.baseCurrency;

export const baseCurrencyFetchedSelector = () => (state) => state[PAYMENT_SETTINGS_REDUCER_NAME]?.baseCurrencyFetched;

export const baseCurrencyErrorSelector = () => (state) => state[PAYMENT_SETTINGS_REDUCER_NAME]?.baseCurrencyError;

export const depositAddressesSelector = () => (state) => state[PAYMENT_SETTINGS_REDUCER_NAME]?.depositAddresses;

export const depositAddressesFetchedSelector = () => (state) => state[PAYMENT_SETTINGS_REDUCER_NAME]
  ?.depositAddressesFetched;

export const depositAddressesErrorSelector = () => (state) => state[PAYMENT_SETTINGS_REDUCER_NAME]
  ?.depositAddressesError;

export const paymentSettingsFetchingSelector = () => (state) => state[PAYMENT_SETTINGS_REDUCER_NAME]?.fetching;
