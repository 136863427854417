import React from 'react';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { useCreateStoreOutcomeWallets } from '../../../../api/modules/account/use-create-store-outcome-wallets';
import { Button } from '../../../ui/button';
import { getSafeErrorMessageText } from '../../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../../helpers/console-error-message';
import { LoaderIcon } from '../../../icons/loader-icon';
import { setWallets } from '../../../../store/payment-settings/reducer';
import { ResponseType } from '../../../../default-types';
import { ExtendedWallet } from '../../types';
import classes from './styles.module.scss';

type DeleteWalletProps = {
  activeWallet: ExtendedWallet;
  wallets: Array<ExtendedWallet>;
  onClose: () => void;
  isLastWallet: boolean;
};

export const DeleteWallet: React.FC<DeleteWalletProps> = (props) => {
  const {
    activeWallet,
    wallets,
    onClose,
    isLastWallet,
  } = props;

  const fetchUpdateOutcomeWallets = useCreateStoreOutcomeWallets();
  const dispatch = useDispatch();
  const submitButtonText = isLastWallet ? 'OK, close' : 'Yes, delete';

  const [apiError, setApiError] = React.useState<string | null>(null);
  const [isFetching, setIsFetching] = React.useState(false);

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isLastWallet) {
      onClose();
      return;
    }

    const walletsData = wallets
      .filter((wallet) => wallet.id !== activeWallet.id)
      .map((wallet) => (
        {
          c: wallet.baseCurrency,
          addr: wallet.baseAddress,
          eid: wallet.extraId,
        }
      ));

    setIsFetching(true);

    const {
      data,
      status,
      errorMessage,
    }: ResponseType = await fetchUpdateOutcomeWallets({ wallets: walletsData });

    if (status === 200 && data) {
      dispatch(setWallets(data));
      onClose();
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/store-outcome-wallets');
      setApiError(errorDataMessage);
    }
    setIsFetching(false);
  };

  return (
    <form
      className={cn([
        classes.form,
        classes.deleteWallet,
        isLastWallet && classes.deleteWalletLast,
      ])}
      onSubmit={handleFormSubmit}
    >
      {isLastWallet ? (
        <p>
          You cannot delete the only payout wallet in your account. If you wish to delete this address,
          first add a new one.
          <br />
          <br />
          Please note: Once deleted, you will not be able to receive payments to this address.
        </p>
      ) : (
        <p>
          Are you sure you want to delete the
          {' '}
          <b>{activeWallet.baseCurrency}</b>
          {' '}
          wallet address from payout wallets? Once you do that, you will not be able to receive
          payments to this address.
        </p>
      )}
      <div className={classes.deleteWalletApiError}>
        {apiError}
      </div>
      <div className={classes.deleteWalletButtons}>
        <Button
          type="submit"
          displayRed={!isLastWallet}
        >
          {
            isFetching
              ? <LoaderIcon path="/images/loader-white-on-red-icon.gif" />
              : submitButtonText
          }
        </Button>
        {!isLastWallet && (
          <Button
            displayBorder
            onClick={onClose}
          >
            Cancel
          </Button>
        )}
      </div>
    </form>
  );
};
