import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Popup } from '../../../popup';
import styles from '../../styles.module.scss';
import { InvoiceDetails } from '../invoice-details';

export const InvoiceDetailsPopup = (props) => {
  const { invoice, isOpen, onClose, onPaymentRowClick } = props;

  return (
    <Popup
      className={styles.popupInvoice}
      open={isOpen}
      onClose={onClose}
      withCloseButton
    >
      <InvoiceDetails
        invoice={invoice}
        onPaymentRowClick={onPaymentRowClick}
      />
    </Popup>
  );
};

InvoiceDetailsPopup.defaultProps = {
  invoice: null,
  isOpen: false,
  onClose: undefined,
  onPaymentRowClick: undefined,
};

InvoiceDetailsPopup.propTypes = {
  invoice: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onPaymentRowClick: PropTypes.func,
};
