import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSafeErrorMessageText } from '../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../helpers/console-error-message';
import { checkErrorCode } from '../../../helpers/check-error-code';
import { useSubPartnerBalance } from '../../../api/modules/account/use-sub-partner-balance';
import {
  customerBalancesFetchingSelector,
  customerBalancesSelector,
} from '../../../store/customer-management/selectors';
import { hasElementsInArray } from '../../../helpers/has-elements-in-array';
import {
  setCustomerBalance,
  setCustomerBalancesFetched,
  setCustomerBalancesFetching,
} from '../../../store/customer-management/reducer';
import { isObject } from '../../../helpers/is-object';
import { getBalancesCurrencies, getBalancesInfoItems } from '../helpers';
import { useMarketInfoRates } from '../../../hooks/use-market-info-rates';
import {
  marketInfoFetchingSelector,
  marketInfoFromCurrencySelector,
  marketInfoRatesSelector,
} from '../../../store/market-info/selectors';
import { getFiatAmountOfBalances } from '../../../helpers/get-fiat-amount-of-balances';
import { getUniqueTickersForMarketInfo } from '../../../helpers/get-unique-tickers-for-market-info';
import { useTimeout } from '../../../hooks/use-timeout';

export const useCustomerPageBalancesState = (
  customerId,
  allowedInit = false,
  subPartnerData = null,
  currenciesObject = {},
) => {
  const dispatch = useDispatch();
  const fetchSubPartnerBalance = useSubPartnerBalance();
  const marketInfoFromCurrency = useSelector(marketInfoFromCurrencySelector());
  const marketInfoRates = useSelector(marketInfoRatesSelector());
  const marketInfoFetching = useSelector(marketInfoFetchingSelector());
  const customerBalancesFetching = useSelector(customerBalancesFetchingSelector());
  const customerBalances = useSelector(customerBalancesSelector());
  const customerBalanceData = customerBalances[customerId] || null;
  const hasBalances = hasElementsInArray(customerBalanceData);

  const [isNeedMarketInfoRates, setIsNeedMarketInfoRates] = React.useState(false);
  const [isBalancesPopupShow, setIsBalancesPopupShow] = React.useState(false);

  const tickersForMarketInfoRates = React.useMemo(() => (
    getUniqueTickersForMarketInfo(customerBalanceData, currenciesObject)
  ), [customerBalanceData, currenciesObject]);

  const subPartnerWithBalance = React.useMemo(() => {
    const fiatAmount = getFiatAmountOfBalances(customerBalanceData, currenciesObject, marketInfoRates);
    const fiatAmountNormalized = marketInfoRates && customerBalanceData ? fiatAmount : null;

    return isObject(subPartnerData) ? {
      ...subPartnerData,
      amount: fiatAmountNormalized,
      ticker: marketInfoFromCurrency,
    } : null;
  }, [
    subPartnerData,
    customerBalanceData,
    currenciesObject,
    marketInfoRates,
    marketInfoFromCurrency,
  ]);

  const balancesInfoItems = React.useMemo(() => (
    getBalancesInfoItems(customerBalanceData, currenciesObject)
  ), [customerBalanceData, currenciesObject]);

  const subPartnerBalances = React.useMemo(() => (
    getBalancesCurrencies(customerBalanceData, currenciesObject)
  ), [customerBalanceData, currenciesObject]);

  const handleBalancesPopupToggle = () => {
    setIsBalancesPopupShow((prevState) => !prevState);
  };
  const reFetchBalances = async () => {
    dispatch(setCustomerBalancesFetching(true));
    const { data, status, errorMessage } = await fetchSubPartnerBalance(customerId);

    if (status === 200) {
      const id = data?.result?.subPartnerId ?? null;
      const balances = data?.result?.balances ?? [];
      dispatch(setCustomerBalance({ id, balances }));
      dispatch(setCustomerBalancesFetching(false));
      dispatch(setCustomerBalancesFetched(true));
      if (hasElementsInArray(balances)) {
        setIsNeedMarketInfoRates(true);
      }
    } else {
      checkErrorCode(data?.errorData?.code);
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/sub-partners/balance');
      dispatch(setCustomerBalancesFetching(false));
      dispatch(setCustomerBalancesFetched(true));
    }
  };

  React.useEffect(() => {
    if (allowedInit && !hasBalances) {
      void reFetchBalances();
    }
  }, [allowedInit, hasBalances]);

  useMarketInfoRates({
    isNeedRates: isNeedMarketInfoRates,
    tickers: tickersForMarketInfoRates,
  });
  useTimeout(() => { setIsNeedMarketInfoRates(false); }, 2000, isNeedMarketInfoRates);

  return {
    isBalancesFetching: customerBalancesFetching || marketInfoFetching,
    reFetchBalances,
    subPartnerFullData: subPartnerWithBalance,
    balancesInfoItems,
    subPartnerBalances,
    isBalancesPopupShow,
    handleBalancesPopupToggle,
    hasBalances,
  };
};
