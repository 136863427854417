import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { InputSearch } from '../../../ui/input-search';
import { Button } from '../../../ui/button';
import classes from './styles.module.scss';

export const SubscriptionPlanFilter = (props) => {
  const {
    className,
    disabled,
    onClick,
    onChangeSearch,
  } = props;

  return (
    <div className={cn(classes.planFilter, className)}>
      <Button
        className={classes.planFilterButton}
        disabled={disabled}
        onClick={onClick}
      >
        Add users
      </Button>
      <InputSearch
        id="subscription-plan-search"
        className={classes.planFilterSearch}
        placeholder="Search User Email"
        onChange={onChangeSearch}
      />
    </div>
  );
};

SubscriptionPlanFilter.defaultProps = {
  className: null,
  disabled: false,
  onClick: null,
  onChangeSearch: null,
};

SubscriptionPlanFilter.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onChangeSearch: PropTypes.func,
};
