import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initial-state';

export const CUSTOMER_MANAGEMENT_REDUCER_NAME = 'customerManagement';

const setCustomersState = (state, { payload }) => {
  state.customers = payload;
};
const setCustomerBalancesState = (state, { payload }) => {
  state.balances = payload;
};
const setCustomerBalanceState = (state, { payload }) => {
  if (!payload?.id) {
    return;
  }

  state.balances[payload.id] = payload?.balances ?? [];
};
const setCustomerBalancesFetchingState = (state, { payload }) => {
  state.balancesFetching = payload;
};
const setCustomerBalancesFetchedState = (state, { payload }) => {
  state.balancesFetched = payload;
};
const setCustomersFetchingState = (state, { payload }) => {
  state.fetching = payload;
};
const setCustomersFetchedState = (state, { payload }) => {
  state.fetched = payload;
};
const setCustomersErrorState = (state, { payload }) => {
  state.error = payload;
};
const setAllPlansState = (state, { payload }) => {
  state.allPlans = {
    ...state.allPlans,
    items: payload.items,
    count: payload.count,
  };
};
const setAllPlansFetchingState = (state, { payload }) => {
  state.allPlans.fetching = payload;
};
const setAllPlansFetchedState = (state, { payload }) => {
  state.allPlans.fetched = payload;
};
const setAllPlansErrorState = (state, { payload }) => {
  state.allPlans.error = payload;
};
const setCustomerSubscriptionsState = (state, { payload }) => {
  state.customerSubscriptions[payload.id] = payload?.data ?? null;
};
const setCustomerSubscriptionsFetchingState = (state, { payload }) => {
  state.customerSubscriptionsFetching = payload;
};
const setCustomerSubscriptionsFetchedState = (state, { payload }) => {
  state.customerSubscriptionsFetched = payload;
};

export const customerManagementSlice = createSlice({
  name: CUSTOMER_MANAGEMENT_REDUCER_NAME,
  initialState,
  reducers: {
    setCustomers: setCustomersState,
    setCustomerBalances: setCustomerBalancesState,
    setCustomerBalance: setCustomerBalanceState,
    setCustomerBalancesFetching: setCustomerBalancesFetchingState,
    setCustomerBalancesFetched: setCustomerBalancesFetchedState,
    setCustomersFetching: setCustomersFetchingState,
    setCustomersFetched: setCustomersFetchedState,
    setCustomersError: setCustomersErrorState,
    setAllPlans: setAllPlansState,
    setAllPlansFetching: setAllPlansFetchingState,
    setAllPlansFetched: setAllPlansFetchedState,
    setAllPlansError: setAllPlansErrorState,
    setCustomerSubscriptions: setCustomerSubscriptionsState,
    setCustomerSubscriptionsFetching: setCustomerSubscriptionsFetchingState,
    setCustomerSubscriptionsFetched: setCustomerSubscriptionsFetchedState,
  },
});

export const {
  setCustomers,
  setCustomerBalances,
  setCustomerBalance,
  setCustomerBalancesFetching,
  setCustomerBalancesFetched,
  setCustomersFetching,
  setCustomersFetched,
  setCustomersError,
  setAllPlans,
  setAllPlansFetching,
  setAllPlansFetched,
  setAllPlansError,
  setCustomerSubscriptions,
  setCustomerSubscriptionsFetching,
  setCustomerSubscriptionsFetched,
} = customerManagementSlice.actions;
export const customerManagementReducer = customerManagementSlice.reducer;
