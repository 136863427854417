export function setAuthToken(token) {
  localStorage.setItem("authToken", token);
}

export function clearAuthToken() {
  localStorage.removeItem("authToken");
}

export function getAuthToken() {
  return localStorage.getItem("authToken");
}

export function setMasterId(id) {
  localStorage.setItem("masterId", id);
}

export function clearMasterId() {
  localStorage.removeItem("masterId");
}

export function getMasterId() {
  return localStorage.getItem("masterId");
}

export function setDestroyTfa(toggle) {
  localStorage.setItem("destroyTfa", toggle);
}

export const getRedirectLink = () => {
  return localStorage.getItem("redirect");
};

export const setRedirectLink = (link) => {
  return localStorage.setItem("redirect", link);
};

export const setRedirectServiceLink = (link) => {
  return localStorage.setItem("redirect-service", link);
};

export const getItemFromLocalStorage = (name) => (
  localStorage.getItem(name)
);

export const setItemToLocalStorage = (name, value) => (
  localStorage.setItem(name, value)
);

export const removeItemFromLocalStorage = (name) => {
  localStorage.removeItem(name);
};
