import React from 'react';
import { getSafeErrorMessageText } from '../helpers/get-safe-error-message-text';
import { useDeleteUserSubscription } from '../api/modules/account/use-delete-user-subscription';
import { consoleErrorMessage } from '../helpers/console-error-message';
import { isFunction } from '../helpers/is-function';
import { useTimeout } from './use-timeout';

export const useUnsubscribeUser = (options = {}) => {
  const { callback } = options;
  const fetchDeleteUserSubscription = useDeleteUserSubscription();

  const [pending, setPending] = React.useState(false);
  const [apiError, setApiError] = React.useState(null);
  const [subscribeId, setSubscribeId] = React.useState(null);

  const resetError = () => {
    setApiError(null);
  };
  const handleSubscribeIdAdd = (id) => {
    setSubscribeId(id);
  };
  const handleSubscribeIdRemove = () => {
    setSubscribeId(null);
  };
  const handleUnsubscribe = React.useCallback(async () => {
    setPending(true);
    const { status, data } = await fetchDeleteUserSubscription(subscribeId);

    if (status === 200) {
      if (isFunction(callback)) {
        await callback();
      }
      setPending(false);
      setSubscribeId(null);
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorDataMessage, errorDataMessage, '/subscriptions/:id');
      setPending(false);
      setApiError(errorDataMessage);
    }
  }, [subscribeId, callback]);

  useTimeout(resetError, 3000, apiError);

  return {
    hasSubscribeId: Boolean(subscribeId),
    pending,
    apiError,
    handleUnsubscribe,
    handleSubscribeIdAdd,
    handleSubscribeIdRemove,
  };
};
