import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Popup } from '../../../popup';
import styles from '../../styles.module.scss';
import { getPaymentInfoItems } from '../../payment-link-page-helpers';
import { usePaymentsState, usePaymentsActions, useProfileInfoState } from '../../hooks';
import { PaymentsInfoBox } from '../../../payments-info-box';
import { STATUSES } from '../../../../constants/app-constants';
import { Button } from '../../../ui/button';
import { LoaderIcon } from '../../../icons/loader-icon';

const PaymentInfoPopup = (props) => {
  const {
    isOpen = false,
    onClose,
    payment = null,
    setActivePayment,
  } = props;
  const { profileInfo: { flags, currenciesObject } } = useProfileInfoState();
  const { isChangingPaymentStatus } = usePaymentsState();
  const { setFinishedStatusToPayment } = usePaymentsActions();

  const paymentId = payment?.id ?? null;
  const paymentStatus = payment?.status ?? null;

  const paymentInfoItems = React.useMemo(() => (
    isOpen ? getPaymentInfoItems(payment, flags?.isCustody, currenciesObject) : []
  ), [payment, isOpen, flags, currenciesObject]);

  const isButtonChangedShow = [STATUSES.PARTIALLY_PAID, STATUSES.FAILED].includes(paymentStatus);

  const onFinishPaymentClick = async () => {
    const paymentId = payment?.id;

    if (!paymentId) {
      return;
    }

    const partialUpdate = await setFinishedStatusToPayment(paymentId);
    const newActivePayment = { ...payment, ...partialUpdate };
    setActivePayment(newActivePayment);
  };

  return (
    <Popup
      className={styles.popupPaymentInfo}
      open={isOpen}
      onClose={onClose}
      withCloseButton
    >
      <PaymentsInfoBox
        title="Payment ID"
        id={paymentId}
        items={paymentInfoItems}
      />
      <div className={styles.paymentInfoButtons}>
        {isButtonChangedShow && (
          <Button
            onClick={onFinishPaymentClick}
            className={styles.paymentInfoButton}
            disabled={isChangingPaymentStatus}
          >
            {isChangingPaymentStatus ? (
              <LoaderIcon
                size={25}
                path="/images/loader-white-on-blue-icon.gif"
              />
            ) : 'Change status to finished'}
          </Button>
        )}
        <Button
          onClick={onClose}
          className={styles.paymentInfoButton}
        >
            Ok
        </Button>
      </div>
    </Popup>
  );
};

PaymentInfoPopup.defaultProps = {
  payment: null,
  setActivePayment: () => {},
  isOpen: false,
  onClose: undefined,
};

PaymentInfoPopup.propTypes = {
  payment: PropTypes.object,
  setActivePayment: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

const PaymentInfoPopupMemo = React.memo(PaymentInfoPopup);

export default PaymentInfoPopupMemo;
