import { CURRENCIES_REDUCER_NAME } from './reducer';

export const currencyListSelector = () => (state) => state[CURRENCIES_REDUCER_NAME]?.currencyList;

export const availableCurrenciesSelector = () => (state) => state[CURRENCIES_REDUCER_NAME]?.availableCurrencies;

export const fiatCurrenciesSelector = () => (state) => state[CURRENCIES_REDUCER_NAME]?.fiatCurrencies;

export const selectedTickersSelector = () => (state) => state[CURRENCIES_REDUCER_NAME]?.selectedTickers;

export const currenciesFetchingSelector = () => (state) => state[CURRENCIES_REDUCER_NAME]?.fetching;

export const currenciesFetchedSelector = () => (state) => state[CURRENCIES_REDUCER_NAME]?.fetched;

export const currenciesErrorSelector = () => (state) => state[CURRENCIES_REDUCER_NAME]?.error;
