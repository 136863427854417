import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Popup } from '../../../popup';
import { InvoiceResultWithCurrency } from '../../../invoice-result-with-currency';
import config from '../../../../config';
import { safeToUpperCase } from '../../../../helpers/safe-to-upper-case';
import { getCurrentNetworkForShow } from '../../../../helpers/get-current-network-for-show';
import classes from './styles.module.scss';

export const PopupObservablePaymentResult = (props) => {
  const {
    className,
    open,
    onClose,
    data,
    currenciesObject,
  } = props;

  const invoiceOrderId = data?.orderId ?? null;
  const invoiceId = data?.invoiceId ?? null;
  const paymentId = data?.paymentId ?? null;
  const payAmount = data?.payAmount ?? null;
  const payCurrency = data?.payCurrency ?? null;
  const payCurrencyUpperCased = safeToUpperCase(payCurrency);
  const priceCurrencyFound = currenciesObject[payCurrencyUpperCased] || {};
  const priceCurrentTicker = priceCurrencyFound?.ticker ?? payCurrencyUpperCased;
  const payCurrentNetwork = getCurrentNetworkForShow(priceCurrencyFound?.network, payCurrency);
  const paymentPayinAddress = data?.payinAddress ?? null;
  const paymentPayinExtraId = data?.payinExtraId ?? null;
  const paymentStatus = data?.status ?? null;
  const paymentUrl = invoiceId && paymentId
    ? `${config.front_domain}/payment/?iid=${invoiceId}&paymentId=${paymentId}`
    : null;

  return (
    <Popup
      classNameContent={cn(classes.popup, className)}
      open={open}
      onClose={onClose}
      withCloseButton
    >
      <InvoiceResultWithCurrency
        orderId={invoiceOrderId}
        payAmount={payAmount}
        payTicker={priceCurrentTicker}
        payNetwork={payCurrentNetwork}
        payinAddress={paymentPayinAddress}
        payinExtraId={paymentPayinExtraId}
        status={paymentStatus}
        url={paymentUrl}
      />
    </Popup>
  );
};

PopupObservablePaymentResult.defaultProps = {
  className: null,
  open: null,
  onClose: null,
  data: null,
  currenciesObject: {},
};

PopupObservablePaymentResult.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.object,
  currenciesObject: PropTypes.object,
};
