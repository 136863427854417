import React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';

const mapFn = (data: { ipn_timeout: string, ipn_num_recurrent: string }) => ({
  ipnTimeout: data?.ipn_timeout ?? null,
  ipnNumRecurrent: data?.ipn_num_recurrent ?? null,
});

export const useIpnRecurrentNotification = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async () => {
    const [cancelToken] = getRequestSource();
    const requestPromise = client.get(
      '/instant-payment-notifications/recurrent-notifications',
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
