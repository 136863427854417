import { toFixed } from './to-fixed';
import { safeToUpperCase } from './safe-to-upper-case';

/**
 * @param {Object} payment
 * @param {Number} fixedDigits
 * @param {Object} currenciesObject
 * @returns {string|null}
 */

export const getPaymentNetworkFee = (payment = {}, fixedDigits = 8, currenciesObject = {}) => {
  const outcomeTicker = payment?.outcomeCurrency ?? '';
  const outcomeTickerUpperCased = safeToUpperCase(outcomeTicker);
  const outcomeCurrentTicker = currenciesObject?.[outcomeTickerUpperCased]?.ticker || outcomeTickerUpperCased;
  const depositFeeAmount = payment?.exchangeData?.depositFee?.amount ?? null;
  const depositFeeCurrency = payment?.exchangeData?.depositFee?.currency ?? null;
  const estimatedDepositFeeAmount = payment?.exchangeData?.estimatedDepositFee?.amount ?? null;
  const currentDepositFeeAmount = safeToUpperCase(depositFeeCurrency) === outcomeTickerUpperCased
    ? depositFeeAmount
    : estimatedDepositFeeAmount;
  const withdrawalFeeAmount = payment?.exchangeData?.withdrawalFee?.amount ?? null;
  const networkFee = Number(currentDepositFeeAmount) + Number(withdrawalFeeAmount);
  const networkFeeToFixed = networkFee && !Number.isNaN(networkFee)
    ? toFixed(networkFee, fixedDigits)
    : null;

  return networkFeeToFixed && outcomeCurrentTicker
    ? `${networkFeeToFixed} ${outcomeCurrentTicker}`
    : networkFeeToFixed;
};
