import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPaymentSettingsFetching,
  setDepositAddresses,
  setDepositAddressesFetched,
  setDepositAddressesError,
} from '../store/payment-settings/reducer';
import { getSafeErrorMessageText } from '../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../helpers/console-error-message';
import { depositAddressesFetchedSelector } from '../store/payment-settings/selectors';
import { useDepositAddresses } from '../api/modules/account/use-deposit-addresses';
import { ResponseType } from '../default-types';

export const useDepositAddressesData = () => {
  const dispatch = useDispatch();
  const depositAddressesFetched = useSelector(depositAddressesFetchedSelector());
  const fetchDepositAddresses = useDepositAddresses();

  const fetchAddresses = React.useCallback(async () => {
    if (depositAddressesFetched) {
      return;
    }

    dispatch(setPaymentSettingsFetching(true));
    const { data, status, errorMessage }: ResponseType = await fetchDepositAddresses();

    if (status === 200 && data?.result) {
      dispatch(setDepositAddresses(data?.result));
      dispatch(setPaymentSettingsFetching(false));
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/deposits/addresses');
      dispatch(setDepositAddressesError(`Deposits addresses: ${errorDataMessage}`));
      dispatch(setPaymentSettingsFetching(false));
    }
    dispatch(setDepositAddressesFetched(true));
  }, [depositAddressesFetched]);

  React.useEffect(() => {
    const timerId = setTimeout(fetchAddresses);

    return () => clearTimeout(timerId);
  }, [fetchAddresses]);
};
