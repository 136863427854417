import PropTypes from 'prop-types';
import * as React from 'react';
import { CopyText } from '../../../ui/copy-text';
import { Button } from '../../../ui/button';
import classes from './styles.module.scss';

export const RequestPayoutCreated = (props) => {
  const {
    payoutData,
    onClick,
  } = props;

  const isFiatPayout = payoutData?.fiatAccountNumber !== undefined;
  const currency = isFiatPayout ? payoutData?.cryptoCurrencyCode : payoutData?.currency;
  const amount = isFiatPayout ? payoutData?.cryptoCurrencyAmount : payoutData?.amount;

  return (
    <div className={classes.requestPayoutCreated}>
      <div className={classes.requestPayoutCreatedTitle}>
        Request payout
      </div>
      <div className={classes.requestPayoutCreatedContent}>
        <img
          className={classes.requestPayoutCreatedImage}
          src="/images/success-image.svg"
          alt="Payout has been created"
          decoding="async"
          width={261}
          height={200}
        />
        <div className={classes.requestPayoutCreatedMainText}>
          Your payout has been created
        </div>
        <div className={classes.requestPayoutCreatedDescription}>
          {`We’ve received your payout of ${amount} ${currency}, it is currently being processed.`}
        </div>
        <div className={classes.requestPayoutCreatedId}>
          <div className={classes.requestPayoutCreatedIdTitle}>
            Payout ID&nbsp;
          </div>
          <div className={classes.requestPayoutCreatedIdValue}>
            <CopyText
              text={payoutData?.id}
              copyIconPath="/images/copy-icon-small.svg"
            />
          </div>
        </div>
      </div>
      <div className={classes.requestPayoutCreatedFooter}>
        {!isFiatPayout && (
          <div className={classes.requestPayoutCreatedNotice}>
            <span>IMPORTANT:</span> make sure that you are making a payout to the whitelisted address.<br />
            If not, please contact us on <a href="mailto:partners@nowpayments.io">partners@nowpayments.io</a> to
            confirm your payout.
          </div>
        )}
        <Button
          className={classes.requestPayoutCreatedButton}
          onClick={onClick}
        >
          OK
        </Button>
      </div>
    </div>
  );
};

RequestPayoutCreated.defaultProps = {
  payoutData: null,
  onClick: null,
};

RequestPayoutCreated.propTypes = {
  payoutData: PropTypes.shape({}),
  onClick: PropTypes.func,
};
