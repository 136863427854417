import React from 'react';
import { v4 } from 'uuid';
import classNames from 'classnames';
import Info from '../info';
import styles from './styles.module.scss';

export default function WithdrawalInput({
  label,
  placeholder,
  value,
  error,
  errorMessage = '',
  onChange,
  info = '',
  type = 'text',
  errorBottom = false,
  disabled = false,
  classes = [],
  pattern,
  min,
  step,
  required = true,
  autoComplete = 'nope',
}) {
  const id = v4();

  return (
    <label
      htmlFor={id}
      className={classNames(
        styles.label,
        {
          [styles.error]: error || errorMessage,
          [styles.errorBottom]: errorBottom,
        },
        ...classes,
      )}
    >
      <div className={styles.infoRow}>
        {label && (
          <Info text={info} variant="small">
            <span className={styles.title}>{label}</span>
          </Info>
        )}
        {errorMessage && (
          <span className={styles.errorText}>{errorMessage}</span>
        )}
      </div>

      <input
        id={id}
        className={styles.input}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        type={type}
        pattern={pattern}
        min={min}
        step={step}
        required={required}
        autoComplete={autoComplete}
      />
      {error && (
        <span className={classNames(styles.errorMsg)}>{error}</span>
      )}
    </label>
  );
}
