import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import commonStyles from '../../css/common.module.scss';
import styles from '../donation-preview-btn/styles.module.scss';
import apiClient from '../../api/api-client';
import { SelectApiKeys } from '../donation-preview-btn/select-api-keys';
import { front_domain } from '../../config';
import { CodePreview } from './code-preview';

export const DonationPreviewWidget = () => {
  const [isCopied, setIsCopied] = useState(false);
  const [apiKeys, setApiKeys] = useState([]);
  const [activeKey, setActiveKey] = useState('');
  const timer = useRef(null);

  const handleSelectChange = (e) => {
    setActiveKey(e.target.value);
  };

  const handleCopyTo = () => {
    const text = `<iframe src="${front_domain}/embeds/donation-widget?api_key=${activeKey}&source=lk_donation&medium=referral" frameborder="0" scrolling="no" style="overflow-y: hidden;" width="354" height="680"></iframe>`;

    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    timer.current = setTimeout(() => setIsCopied(false), 300);
  };

  const fetch = async () => {
    try {
      const { keys } = await apiClient.getApiKeys();

      if (keys.length) {
        setApiKeys(keys);
        setActiveKey(keys[0].api_key);
      }
    } catch (err) {
      // ignore
    }
  };

  useEffect(() => {
    fetch();

    return () => clearTimeout(timer.current);
  }, []);

  return (
    <div className={`${styles.wrapper} ${commonStyles.panel}`}>
      <h3>Place a donation widget to your web page</h3>
      <SelectApiKeys
        apiKeys={apiKeys}
        handleSelectChange={handleSelectChange}
      />
      <CodePreview apiKey={activeKey} />
      <button
        className={cn(styles.btn, styles.btnWhite)}
        onClick={handleCopyTo}
      >
        {!isCopied ? 'Copy to clipboard' : 'Copied to clipboard'}
      </button>
    </div>
  );
};
