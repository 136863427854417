import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { partnerSelector } from '../store/partner/selectors';
import { useStoreOutcomeWallets } from '../api/modules/account/use-store-outcome-wallets';
import {
  setStoreData,
  setPaymentSettingsFetching,
  setIsStoreDataFetched,
  setStoreDataError,
  setWallets,
} from '../store/payment-settings/reducer';
import { isObject } from '../helpers/is-object';
import { getSafeErrorMessageText } from '../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../helpers/console-error-message';
import {
  isStoreDataFetchedSelector,
  paymentSettingsFetchingSelector,
} from '../store/payment-settings/selectors';
import { useTimeout } from './use-timeout';

export const useStoreOutcomeData = () => {
  const dispatch = useDispatch();
  const partner = useSelector(partnerSelector());
  const isStoreDataFetched = useSelector(isStoreDataFetchedSelector());
  const paymentSettingsFetching = useSelector(paymentSettingsFetchingSelector());
  const fetchStoreOutcomeWallets = useStoreOutcomeWallets();

  const fetchWallets = React.useCallback(async () => {
    if (!partner || isStoreDataFetched || paymentSettingsFetching) {
      return;
    }

    dispatch(setPaymentSettingsFetching(true));
    const { data, status, errorMessage } = await fetchStoreOutcomeWallets();

    if (status === 200 && isObject(data)) {
      const { wallets, ...storeData } = data;
      dispatch(setWallets(wallets));
      dispatch(setStoreData(storeData));
    } else {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/store-outcome-wallets');
      dispatch(setStoreDataError(`Store outcome wallets: ${errorDataMessage}`));
    }
    dispatch(setPaymentSettingsFetching(false));
    dispatch(setIsStoreDataFetched(true));
  }, [partner, paymentSettingsFetching, isStoreDataFetched]);

  useTimeout(fetchWallets, 0, fetchWallets);
};
