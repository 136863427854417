import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { InputSearch } from '../../../ui/input-search';
import { FilterButton } from '../../../ui/filter-button';
import { SubscriptionUserFilter } from '../subscription-user-filter';
import { currencyListSelector } from '../../../../store/currencies/selectors';
import { isFunction } from '../../../../helpers/is-function';
import { useCryptoCurrencies } from '../../../../hooks/use-crypto-currencies';
import classes from './styles.module.scss';

export const SubscriptionUserFullFilter = React.memo((props) => {
  const {
    className,
    onSearchChange,
    activeFilterItemsNumber,
    createdFrom,
    createdTo,
    onDateRangeChange,
    onSubmit,
    onFilterReset,
    filterFormData,
  } = props;

  const currencyList = useSelector(currencyListSelector());

  const [isFilterActive, setIsFilterActive] = React.useState(false);

  const { cryptoCurrencies } = useCryptoCurrencies(currencyList);

  const handleFilterToggle = React.useCallback(() => {
    setIsFilterActive((prevState) => !prevState);
  }, []);
  const handleFilterSubmit = (data) => {
    if (isFunction(onSubmit)) {
      onSubmit(data, setIsFilterActive);
    }
  };

  return (
    <>
      <div className={cn(classes.fullFilter, className)}>
        <InputSearch
          id="payments-search"
          className={classes.fullFilterSearchInput}
          placeholder="Search Payment ID, Invoice ID..."
          onChange={onSearchChange}
        />
        <FilterButton
          className={classes.fullFilterButton}
          onClick={handleFilterToggle}
          active={isFilterActive}
          count={activeFilterItemsNumber}
        />
      </div>
      {isFilterActive && (
        <SubscriptionUserFilter
          className={classes.userPaymentsFilter}
          onSubmit={handleFilterSubmit}
          onClose={handleFilterToggle}
          currencies={cryptoCurrencies}
          onResetFormData={onFilterReset}
          defaultData={filterFormData}
          createdFrom={createdFrom}
          createdTo={createdTo}
          onDateChange={onDateRangeChange}
        />
      )}
    </>
  );
});

SubscriptionUserFullFilter.defaultProps = {
  className: null,
  onSearchChange: null,
  activeFilterItemsNumber: null,
  createdFrom: null,
  createdTo: null,
  onDateRangeChange: null,
  onSubmit: null,
  onFilterReset: null,
  filterFormData: null,
};

SubscriptionUserFullFilter.propTypes = {
  className: PropTypes.string,
  onSearchChange: PropTypes.func,
  activeFilterItemsNumber: PropTypes.number,
  createdFrom: PropTypes.instanceOf(Date),
  createdTo: PropTypes.instanceOf(Date),
  onDateRangeChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onFilterReset: PropTypes.func,
  filterFormData: PropTypes.shape({}),
};

SubscriptionUserFullFilter.displayName = 'SubscriptionUserFullFilter';
