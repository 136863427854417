import React from 'react';
import PropTypes from 'prop-types';

export const PaymentNotificationsIcon = (props) => {
  const { color } = props;

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.83366 15.0013C8.89011 15.0013 9.77389 14.2481 9.97683 13.2513H5.69048C5.89354 14.2481 6.77729 15.0013 7.83366 15.0013ZM11.924 8.00072C11.9217 8.00072 11.9193 8.00127 11.917 8.00127C9.66591 8.00127 7.83366 6.16956 7.83366 3.91797C7.83366 3.29899 7.97604 2.71389 8.22394 2.18774C8.09556 2.17554 7.96546 2.16797 7.83366 2.16797C5.57854 2.16797 3.75036 3.99604 3.75036 6.25127V7.87759C3.75036 9.03199 3.24461 10.1217 2.35731 10.8718C2.06156 11.1245 1.93031 11.5269 2.03646 11.9195C2.16013 12.3763 2.61163 12.668 3.08536 12.668H12.5791C13.076 12.668 13.5445 12.3459 13.6454 11.8589C13.7236 11.482 13.5894 11.1057 13.2971 10.8602C12.4489 10.1491 11.9584 9.10431 11.924 8.00072Z" />
      <path d="M14.8333 3.9167C14.8333 5.52752 13.5275 6.8333 11.9166 6.8333C10.3058 6.8333 9 5.52752 9 3.9167C9 2.30588 10.3058 1 11.9166 1C13.5275 1 14.8333 2.30588 14.8333 3.9167Z" />
    </svg>
  );
};

PaymentNotificationsIcon.defaultProps = {
  color: '#A4A4A4',
};

PaymentNotificationsIcon.propTypes = {
  color: PropTypes.string,
};
