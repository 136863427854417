import React from 'react';
import { useSelector } from 'react-redux';
import { getTransactionInfoItems } from '../../../helpers/get-transactions-info-items';
import { getPaymentInfoItems } from '../../../helpers/get-payments-info-items';
import { isPartnerCustodySelector } from '../../../store/partner/selectors';

export const useTransactionOrPaymentInfo = (isTransactionsHistory, currenciesObject) => {
  const isPartnerCustody = useSelector(isPartnerCustodySelector());

  const [dataForInfo, setDataForInfo] = React.useState(null);

  const infoItems = React.useMemo(() => (
    isTransactionsHistory
      ? getTransactionInfoItems(dataForInfo, currenciesObject)
      : getPaymentInfoItems(dataForInfo, isPartnerCustody, currenciesObject)
  ), [dataForInfo, isPartnerCustody, currenciesObject]);

  const handleInfoPopupClose = () => {
    setDataForInfo(null);
  };

  const infoId = isTransactionsHistory ? dataForInfo?.id : dataForInfo?.paymentId;
  const infoTitle = isTransactionsHistory ? 'Transaction ID' : 'Payment ID';

  return {
    isInfoPopupShow: Boolean(dataForInfo),
    infoItems,
    infoId,
    setDataForInfo,
    handleInfoPopupClose,
    infoTitle,
  };
};
