import * as React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';
import { fiatCurrenciesMapFn } from '../../helpers/account/map-fns';

export const useAllFiatCurrencies = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async () => {
    const [cancelToken] = getRequestSource();
    const requestPromise = client.get(
      '/estimated-currencies',
      null,
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      fiatCurrenciesMapFn,
    );
  }, [getRequestSource]);
};
