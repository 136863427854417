export const NETWORKS = {
  cchain: {
    name: 'AVAX C',
    symbol: 'cchain',
    color: '#EE3932',
  },
  kcs: {
    name: 'KCS',
    symbol: 'kcs',
    color: '#0093DD',
  },
  ln: {
    name: 'Lightning',
    symbol: 'ln',
    color: '#4DA0E1',
  },
  bsc: {
    name: 'BSC',
    symbol: 'bsc',
    color: '#FFC241',
  },
  eos: {
    name: 'EOS',
    symbol: 'eos',
    color: '#35354C',
  },
  bnb: {
    name: 'BEP2',
    symbol: 'bnb',
    color: '#B4BFCE',
  },
  avaxc: {
    name: 'AVAX C',
    symbol: 'avaxc',
    color: '#EE3932',
  },
  luna: {
    name: 'LUNA',
    symbol: 'luna',
    color: '#B4BFCE',
  },
  btc: {
    name: 'OMNI',
    symbol: 'btc',
    color: '#F39321',
  },
  op: {
    name: 'OP',
    symbol: 'op',
    color: '#FE0420',
  },
  xtz: {
    name: 'XTZ',
    symbol: 'xtz',
    color: '#2C7DF7',
  },
  xrp: {
    name: 'XRP',
    symbol: 'xrp',
    color: '#6F22F5',
  },
  matic: {
    name: 'POLYGON',
    symbol: 'matic',
    color: '#DA0050',
  },
  cfxevm: {
    name: 'CFX EVM',
    symbol: 'cfxevm',
    color: '#E31337',
  },
  theta: {
    name: 'THETA',
    symbol: 'theta',
    color: '#B4BFCE',
  },
  sol: {
    name: 'SOLANA',
    symbol: 'sol',
    color: '#8F4AF6',
  },
  eth: {
    name: 'ETH',
    symbol: 'eth',
    color: '#4FADD0',
  },
  algo: {
    name: 'ALGO',
    symbol: 'algo',
    color: '#000000',
  },
  trx: {
    name: 'TRX',
    symbol: 'trx',
    color: '#EE3932',
  },
  arbitrum: {
    name: 'Arbitrum',
    symbol: 'arbitrum',
    color: '#28A0F0',
  },
  croevm: {
    name: 'EVM',
    symbol: 'croevm',
    color: '#103F68',
  },
  xchain: {
    name: 'AVAX X',
    symbol: 'xchain',
    color: '#5E17FE',
  },
  cro: {
    name: 'Cosmos CRO',
    symbol: 'cro',
    color: '#103F68',
  },
  cfxmainnet: {
    name: 'Mainnet',
    symbol: 'cfxmainnet',
    color: '#E31337',
  },
  zksync: {
    name: 'zkSync Era',
    symbol: 'zksync',
    color: '#1E69FF',
  },
  dot: {
    name: 'Polkadot',
    symbol: 'dot',
    color: '#E6007A',
  },
  epic: {
    name: 'Epic',
    symbol: 'epic',
    color: '#D59D4B',
  },
  near: {
    name: 'NEAR',
    symbol: 'near',
    color: '#6CE89E',
  },
  chiliz: {
    name: 'Chiliz',
    symbol: 'chiliz',
    color: '#FF1156',
  },
  base: {
    name: 'Base',
    symbol: 'base',
    color: '#2151F5',
  },
  xlm: {
    name: 'XLM',
    symbol: 'xlm',
    color: '#391DD2',
  },
  sei: {
    name: 'Sei',
    symbol: 'sei',
    color: '#BC0000',
  },
  ada: {
    name: 'ADA',
    symbol: 'ada',
    color: '#3CC8C8',
  },
  ron: {
    name: 'RON',
    symbol: 'ron',
    color: '#004DE5',
  },
  kavaevm: {
    name: 'KAVAEVM',
    symbol: 'kavaevm',
    color: '#FF564F',
  },
  opbnb: {
    name: 'opBNB',
    symbol: 'opbnb',
    color: '#F6E100',
  },
  kcc: {
    name: 'KCC',
    symbol: 'kcc',
    color: '#23AF91',
  },
  chz: {
    name: 'chz',
    symbol: 'chz',
    color: '#FF1256',
  },
  strk: {
    name: 'strk',
    symbol: 'strk',
    color: '#0C0C4F',
  },
  lna: {
    name: 'lna',
    symbol: 'lna',
    color: '#84DCFB',
  },
  mnt: {
    name: 'MNT',
    symbol: 'mnt',
    color: '#84DCFB',
  },
};
