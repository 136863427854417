import * as React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../ui/button';
import { InputSearch } from '../../../ui/input-search';
import classes from './styles.module.scss';

export const BillingPlansFilter = (props) => {
  const {
    onClick,
    onChangeSearch,
    isAllowed,
  } = props;

  return (
    <div className={classes.billingPlansFilter}>
      {isAllowed && (
        <Button
          className={classes.button}
          onClick={onClick}
        >
          Create new plan
        </Button>
      )}
      <InputSearch
        className={classes.searchInput}
        placeholder="Search Plan ID, Name"
        onChange={onChangeSearch}
        disabled={!isAllowed}
      />
    </div>
  );
};

BillingPlansFilter.defaultProps = {
  onClick: null,
  onChangeSearch: null,
  isAllowed: false,
};

BillingPlansFilter.propTypes = {
  onClick: PropTypes.func,
  onChangeSearch: PropTypes.func,
  isAllowed: PropTypes.bool,
};
