import * as React from 'react';
import { Helmet } from 'react-helmet';
import { SubscriptionUserPage } from '../../components/subscriptions-pages';

const SubscriptionUser = () => (
  <>
    <Helmet>
      <title>Subscription User Transactions</title>
    </Helmet>
    <SubscriptionUserPage />
  </>
);

export default SubscriptionUser;
