import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from '../../../button';
import { isFunction } from '../../../../helpers/is-function';
import { INNER_MENU } from '../../currencies-select-helpers';
import classes from './styles.module.scss';

const INNER_MENU_TABS = Object.values(INNER_MENU);

export const CurrenciesDropdownInnerMenu = (props) => {
  const {
    onClick,
    activeTab,
    isOnRampEnabled,
  } = props;

  const handleClick = (event) => {
    if (isFunction(onClick) && event?.target?.innerText) {
      onClick(event.target.innerText);
    }
  };

  return (
    <div className={classes.currenciesDropdownInnerMenu}>
      {INNER_MENU_TABS.map((tab) => (
        <Button
          className={cn([
            classes.currenciesDropdownInnerMenuItem,
            tab === activeTab && classes.currenciesDropdownInnerMenuItemActive,
            tab === INNER_MENU.FIAT && !isOnRampEnabled && classes.currenciesDropdownInnerMenuItemNew,
          ])}
          key={tab}
          onClick={handleClick}
          variant="outline"
        >
          {tab}
        </Button>
      ))}
    </div>
  );
};

CurrenciesDropdownInnerMenu.defaultProps = {
  onClick: null,
  activeTab: null,
  isOnRampEnabled: false,
};

CurrenciesDropdownInnerMenu.propTypes = {
  onClick: PropTypes.func,
  activeTab: PropTypes.string,
  isOnRampEnabled: PropTypes.bool,
};
