import { ROUTES } from '../../../constants/routes';
import { IndustryType, PluginType, ProductCardType } from '../types';

export const PLUGINS: Array<PluginType> = [
  {
    name: 'Prestashop',
    imagePath: '/images/dashboard-page/plugins/prestashop.svg',
    link: 'https://nowpayments.io/payment-integration/prestashop-plugin',
  },
  {
    name: 'Woocommerce',
    imagePath: '/images/dashboard-page/plugins/woocommerce.svg',
    link: 'https://nowpayments.io/woocommerce-plugin',
  },
  {
    name: 'Magento-2',
    imagePath: '/images/dashboard-page/plugins/magento-2.svg',
    link: 'https://nowpayments.io/magento-2-plugin',
  },
  {
    name: 'Whmcs',
    imagePath: '/images/dashboard-page/plugins/whmcs.svg',
    link: 'https://nowpayments.io/whmcs-plugin',
  },
  {
    name: 'Opencart',
    imagePath: '/images/dashboard-page/plugins/opencart.svg',
    link: 'https://nowpayments.io/opencart-plugin',
  },
  {
    name: 'Ecwid',
    imagePath: '/images/dashboard-page/plugins/ecwid.svg',
    link: 'https://nowpayments.io/payment-integration/ecwid-plugin',
  },
  {
    name: 'Zencart',
    imagePath: '/images/dashboard-page/plugins/zencart.png',
    link: 'https://nowpayments.io/payment-integration/zen-cart-plugin',
  },
  {
    name: 'Shopify',
    imagePath: '/images/dashboard-page/plugins/shopify.svg',
    link: 'https://nowpayments.io/payment-integration/shopify-plugin/',
  },
  {
    name: 'Shopware',
    imagePath: '/images/dashboard-page/plugins/shopware.svg',
    link: 'https://nowpayments.io/payment-integration/shopware-plugin/',
  },
];

const PRODUCTS_OBJECT = {
  'crypto-payments': {
    name: 'Crypto payments',
    description: 'Accept crypto payments in over 300 cryptocurrencies using your dashboard or with API integration. Share payment links with customers and get paid directly to your wallet.',
    buttonLabel: 'Start accepting payments',
    buttonLink: ROUTES.INVOICES,
    isApiIntegrationAvailable: true,
    apiLink: 'https://documenter.getpostman.com/view/7907941/2s93JusNJt#4391e27f-1c54-499f-8623-36c7cd322542',
    imagePath: '/images/dashboard-page/product-images/crypto-payments.svg',
    icons: null,
  },
  'process-mass-payouts': {
    name: 'Store, convert, and process mass payouts',
    description: 'Activate Custody service for secure asset storage, off-chain conversions, and saving on network fees. Withdraw funds from your Custody balance to 1000 addresses in a single API call.',
    buttonLabel: 'Activate Custody',
    buttonLink: ROUTES.CUSTODY,
    isApiIntegrationAvailable: true,
    apiLink: 'https://documenter.getpostman.com/view/7907941/2s93JusNJt#5a1edec7-f187-409b-83cc-0c9e61f032c2',
    imagePath: '/images/dashboard-page/product-images/process-mass-payouts.svg',
    icons: null,
  },
  'fiat-operations': {
    name: 'Fiat operations',
    description: '<ul><li>Receive fiat payments and withdraw directly to your wallet in any of 300+ cryptocurrencies.</li><li>Allow your customers to pay in crypto and get profit in fiat directly to your bank account.</li></ul>',
    buttonLabel: 'Choose your fiat provider',
    buttonLink: ROUTES.FIAT_OPERATIONS_SETTINGS,
    isApiIntegrationAvailable: false,
    apiLink: null,
    imagePath: '/images/dashboard-page/product-images/fiat-operations.svg',
    icons: null,
  },
  'customer-management': {
    name: 'Customer Management',
    description: 'Create user accounts via API or dashboard and efficiently manage balances and operations, saving on network or service fees when transferring funds between accounts.',
    buttonLabel: 'Activate feature',
    buttonLink: ROUTES.CUSTOMERS,
    isApiIntegrationAvailable: true,
    apiLink: 'https://documenter.getpostman.com/view/7907941/2s93JusNJt#2b3f0024-d9de-4b91-9db4-d3655e4eded9',
    imagePath: '/images/dashboard-page/product-images/customer-management.svg',
    icons: null,
  },
  'mass-payouts': {
    name: 'Mass Payouts',
    description: 'Send out cryptocurrency payouts to a wide audience in a single API call. Set up a crypto payroll, distribute freelance commissions and affiliate rewards with ease.',
    buttonLabel: 'Activate feature',
    buttonLink: ROUTES.MASS_PAYOUTS,
    isApiIntegrationAvailable: true,
    apiLink: 'https://documenter.getpostman.com/view/7907941/2s93JusNJt#5a1edec7-f187-409b-83cc-0c9e61f032c2',
    imagePath: '/images/dashboard-page/product-images/mass-payouts.svg',
    icons: null,
  },
  'fiat-payments': {
    name: 'Fiat payments',
    description: 'Receive fiat payments and withdraw profit directly to your wallet in any of 300+ cryptocurrencies.',
    buttonLabel: 'Choose your fiat provider',
    buttonLink: ROUTES.FIAT_OPERATIONS_SETTINGS,
    isApiIntegrationAvailable: false,
    apiLink: null,
    imagePath: '/images/dashboard-page/product-images/fiat-payments.svg',
    icons: null,
  },
  'manage-user-accounts': {
    name: 'Store, convert and manage user accounts',
    description: 'Activate Custody service for secure asset storage, off-chain conversions, and network fee savings. Create user accounts and efficiently manage their balances and operations.',
    buttonLabel: 'Activate feature',
    buttonLink: ROUTES.CUSTODY,
    isApiIntegrationAvailable: true,
    apiLink: 'https://documenter.getpostman.com/view/7907941/2s93JusNJt#2b3f0024-d9de-4b91-9db4-d3655e4eded9',
    imagePath: '/images/dashboard-page/product-images/user-accounts.svg',
    icons: null,
  },
  'shop-plugins': {
    name: 'Shop plugins',
    description: 'Integrate crypto payments into major CMS solutions like WooCommerce, Magento 2, Zencart, OpenCart, and WHMCS.',
    buttonLabel: null,
    buttonLink: null,
    isApiIntegrationAvailable: false,
    apiLink: null,
    imagePath: '/images/dashboard-page/product-images/shop-plugins.svg',
    icons: PLUGINS,
  },
  'affiliate-profit': {
    name: 'Affiliate profit',
    description: 'Partner with the crypto payments industry leader and get a 25% reward from the profit NOWPayments gains from every transaction.',
    buttonLabel: 'Get affiliate link',
    buttonLink: ROUTES.AFFILIATE_PROGRAM,
    isApiIntegrationAvailable: false,
    apiLink: null,
    imagePath: '/images/dashboard-page/product-images/affiliate.svg',
    icons: null,
  },
  'donation-tools': {
    name: 'Donation tools',
    description: 'Integrate a donation widget or button, or simply generate a donation link to share with your audience and get donations directly to your wallet.',
    buttonLabel: 'Integrate donations',
    buttonLink: ROUTES.DONATIONS,
    isApiIntegrationAvailable: false,
    apiLink: null,
    imagePath: '/images/dashboard-page/product-images/donation-tools.svg',
    icons: null,
  },
  'payment-links': {
    name: 'Payment links',
    description: 'Create an invoice in just a few clicks, share payment links supporting 300+ cryptocurrencies, and receive funds directly to your preferred wallet.',
    buttonLabel: 'Create your first invoice',
    buttonLink: ROUTES.INVOICES,
    isApiIntegrationAvailable: false,
    apiLink: null,
    imagePath: '/images/dashboard-page/product-images/crypto-payments.svg',
    icons: null,
  },
  custody: {
    name: 'Custody storage and conversions',
    description: 'Use a convenient and safe money storage solution and gain access to off-chain operations, enabling crypto exchanges without network fees.',
    buttonLabel: 'Activate Custody',
    buttonLink: ROUTES.CUSTODY,
    isApiIntegrationAvailable: false,
    apiLink: null,
    imagePath: '/images/dashboard-page/product-images/custody.svg',
    icons: null,
  },
};

export const INDUSTRIES: Array<IndustryType> = [
  {
    key: 'casino',
    value: 'Casino',
    logoUrl: '/images/dashboard-page/industry-icons/casino.svg',
    cards: ['customer-management', 'mass-payouts', 'fiat-payments'],
  },
  {
    key: 'trading',
    value: 'Trading Platform',
    logoUrl: '/images/dashboard-page/industry-icons/trading.svg',
    cards: ['crypto-payments', 'mass-payouts', 'manage-user-accounts'],
  },
  {
    key: 'adult',
    value: 'Adult',
    logoUrl: '/images/dashboard-page/industry-icons/adult.svg',
    cards: ['crypto-payments', 'manage-user-accounts', 'mass-payouts'],
  },
  {
    key: 'gaming',
    value: 'Gaming',
    logoUrl: '/images/dashboard-page/industry-icons/gaming.svg',
    cards: ['customer-management', 'mass-payouts', 'fiat-payments'],
  },
  {
    key: 'tge',
    value: 'Token Generation Event',
    logoUrl: '/images/dashboard-page/industry-icons/tge.svg',
    cards: ['crypto-payments', 'mass-payouts'],
  },
  {
    key: 'e-commerce',
    value: 'E-commerce',
    logoUrl: '/images/dashboard-page/industry-icons/e-commerce.svg',
    cards: ['crypto-payments', 'fiat-payments', 'shop-plugins'],
  },
  {
    key: 'psp',
    value: 'Payment Service Provider',
    logoUrl: '/images/dashboard-page/industry-icons/psp.svg',
    cards: ['crypto-payments', 'affiliate-profit'],
  },
  {
    key: 'marketplace',
    value: 'Marketplace',
    logoUrl: '/images/dashboard-page/industry-icons/marketplace.svg',
    cards: ['crypto-payments', 'manage-user-accounts', 'fiat-payments'],
  },
  {
    key: 'charity',
    value: 'Charity',
    logoUrl: '/images/dashboard-page/industry-icons/charity.svg',
    cards: ['donation-tools', 'crypto-payments'],
  },
  {
    key: 'personal-needs',
    value: 'Personal Needs',
    logoUrl: '/images/dashboard-page/industry-icons/personal-needs.svg',
    cards: ['payment-links', 'custody', 'affiliate-profit'],
  },
  {
    key: 'other',
    value: 'Other',
    logoUrl: '/images/dashboard-page/industry-icons/other.svg',
    cards: ['crypto-payments', 'process-mass-payouts', 'fiat-operations'],
  },
];

export const INDUSTRIES_CARDS_OBJECT = INDUSTRIES.reduce((acc, industry) => {
  acc[industry.key] = industry.cards.map((cardId) => PRODUCTS_OBJECT[cardId as keyof typeof PRODUCTS_OBJECT]);
  return acc;
}, {} as { [key: string ]: Array<ProductCardType> });

export const SETTINGS = [{
  id: 'ipn',
  name: 'Generate IPN key',
  logoUrl: '/images/dashboard-page/setting-icons/ipn.svg',
  href: `${ROUTES.STORE_SETTINGS}#notifications`,
}, {
  id: '2fa',
  name: 'Enable 2FA',
  logoUrl: '/images/dashboard-page/setting-icons/2fa.svg',
  href: ROUTES.ACCOUNT_SETTINGS,
}, {
  id: 'currencies',
  name: 'Specify currencies list',
  logoUrl: '/images/dashboard-page/setting-icons/currencies.svg',
  href: ROUTES.COINS_SETTINGS,
}];
