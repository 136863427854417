import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStatisticsTurnover } from '../../../api/modules/account/use-statistics-turnover';
import { partnerSelector } from '../../../store/partner/selectors';
import { isObject } from '../../../helpers/is-object';
import { baseCurrencySelector } from '../../../store/payment-settings/selectors';
import { setTurnover } from '../../../store/statistics/reducer';
import { ResponseType } from '../../../default-types';
import { checkErrorCode } from '../../../helpers/check-error-code';
import { getSafeErrorMessageText } from '../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../helpers/console-error-message';

export const useTurnover = (isTurnoverUpdated: boolean) => {
  const dispatch = useDispatch();
  const fetchStatisticsTurnover = useStatisticsTurnover();
  const partner = useSelector(partnerSelector());
  const baseCurrency = useSelector(baseCurrencySelector());

  const initTurnover = React.useCallback(async (): Promise<void> => {
    dispatch(setTurnover({ errorMessage: null, isFetching: true }));
    const { data, status, errorMessage }: ResponseType = await fetchStatisticsTurnover({
      baseCurrency,
    });

    if (status === 200 && isObject(data?.result)) {
      dispatch(setTurnover({
        amount: data.result.amount || null,
        ticker: data.result.baseCurrency || null,
        count: data.result.count || null,
        isLoaded: true,
        isFetching: false,
      }));
    } else {
      checkErrorCode(data?.errorData?.code);
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/stats/payments/turnover');
      dispatch(setTurnover({ errorMessage: `Turnover: ${errorDataMessage}`, isFetching: false }));
    }
  }, [
    dispatch,
    fetchStatisticsTurnover,
    baseCurrency,
  ]);

  React.useEffect(() => {
    if (isObject(partner) && baseCurrency && !isTurnoverUpdated) {
      initTurnover();
    }
  }, [partner, baseCurrency, isTurnoverUpdated]);
};
