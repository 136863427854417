import React from 'react';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import commonStyles from '../../../css/common.module.scss';
import SubmitButton from '../../../components/submit-button';
import apiClient from '../../../api/api-client';
import ModalAdd from './components/modal-add';
import ModalAlert from './components/modal-alert';
import ModalView from './components/modal-view';
import styles from './styles.module.scss';

const AppsSettings = () => {
  const [isShowAddModal, setIsShowAddModal] = React.useState(false);
  const [isShowAlertModal, setIsShowAlertModal] = React.useState(false);
  const [isShowViewModal, setIsShowViewModal] = React.useState(false);
  const [activeApp, setActiveApp] = React.useState({});
  const [services, setServices] = React.useState([]);
  const [permissions, setPermissions] = React.useState({});

  const handleAddClick = () => {
    setIsShowAddModal(true);
  };

  const handleDeleteClick = (service) => {
    setActiveApp(service);
    setIsShowAlertModal(true);
  };

  const deleteService = async (service) => {
    const serviceFilters = services.filter((s) => s.id !== service.id);

    setServices(serviceFilters);
    await apiClient.deleteService(service.id);
  };

  const handleViewClick = (service) => {
    setActiveApp(service);
    setIsShowViewModal(true);
  };

  const fetch = async () => {
    const services = await apiClient.getAllServices();
    const permissions = await apiClient.getAllPermissions();

    setPermissions(permissions);
    setServices(services);
  };

  React.useEffect(() => {
    fetch();
  }, []);

  return (
    <div>
      <ModalAdd
        isShow={isShowAddModal}
        onShow={setIsShowAddModal}
        setServices={setServices}
        permissions={permissions}
      />
      <ModalAlert
        isShow={isShowAlertModal}
        onShow={setIsShowAlertModal}
        app={activeApp}
        onDelete={deleteService}
      />
      <ModalView
        isShow={isShowViewModal}
        onShow={setIsShowViewModal}
        service={activeApp}
        permissions={permissions}
      />
      <h2>Apps</h2>
      <div className={commonStyles.panel}>
        <div className={styles.header}>
          <span className={styles.desc}>
            If you are a service owner, you can link NOWPayments with your
            application. Thanks to 0auth technology, NOWPayments provides access
            to your app both for you and your users.
          </span>
          <SubmitButton buttonState="normal" onSubmit={handleAddClick}>
            Create app
          </SubmitButton>
        </div>
        {services.map((service) => (
          <div key={service.id} className={styles.appBlock}>
            <div className={styles.appInfo}>
              <h3>{service.name}</h3>
              <span>{service.description}</span>
            </div>
            <div className={styles.appButtons}>
              <FontAwesomeIcon
                icon={faTrashAlt}
                className={styles.deleteIcon}
                onClick={() => handleDeleteClick(service)}
              />
              <SubmitButton
                buttonState="normal"
                onSubmit={() => handleViewClick(service)}
              >
                View
              </SubmitButton>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AppsSettings;
