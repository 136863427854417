import * as React from 'react';
import PropTypes from 'prop-types';
import { CSVReader } from 'react-papaparse';
import { isFunction } from '../../../helpers/is-function';
import { hasElementsInArray } from '../../../helpers/has-elements-in-array';
import {
  getCSVDataFiltered,
  getCSVDataNormalized,
  getCSVErrorMessage,
} from '../../../helpers/csv';
import { useMediaQuery } from '../../../hooks/use-media-query';
import classes from './styles.module.scss';

export const CsvReader = (props) => {
  const {
    onCallBack,
    onRemove,
    apiError,
    isCurrencyOnly,
  } = props;
  const isDesktop = useMediaQuery('(min-width: 768px)');
  const [error, setError] = React.useState(null);

  const handleAddCSV = (data) => {
    setError(null);

    const hasData = hasElementsInArray(data);

    if (!hasData) {
      return;
    }

    const dataFiltered = getCSVDataFiltered(data);
    const dataNormalized = getCSVDataNormalized(dataFiltered, isCurrencyOnly);
    const CSVErrorMessage = getCSVErrorMessage(dataNormalized, isCurrencyOnly);

    if (CSVErrorMessage) {
      setError(CSVErrorMessage);

      if (isFunction(onRemove)) {
        onRemove(null);
      }

      return;
    }

    if (isFunction(onCallBack)) {
      onCallBack(dataNormalized);
    }
  };
  const handleRemoveCSV = () => {
    if (isFunction(onRemove)) {
      onRemove(null);
    }
    setError(null);
  };

  const currentError = apiError || error;

  return (
    <div className={classes.csvReader}>
      {currentError && (
        <div className={classes.csvReaderError}>
          {currentError}
        </div>
      )}
      <CSVReader
        onDrop={handleAddCSV}
        onRemoveFile={handleRemoveCSV}
        addRemoveButton
        style={{
          dropFile: {
            width: '100%',
            boxSizing: 'border-box',
            border: 'none',
            height: '100%',
            background: 'transparent',
          },
          dropArea: {
            borderRadius: '4px',
            width: !isDesktop ? '100%' : '180px',
            height: '50px',
            padding: '0',
            fontWeight: 'bold',
            boxSizing: 'border-box',
            flexShrink: '0',
          },
        }}
      >
        <span className={classes.csvReaderText}>Upload a .csv file</span>
      </CSVReader>
    </div>
  );
};

CsvReader.defaultProps = {
  className: null,
  onCallBack: null,
  onRemove: null,
  apiError: null,
  isCurrencyOnly: false,
};

CsvReader.propTypes = {
  className: PropTypes.string,
  onCallBack: PropTypes.func,
  onRemove: PropTypes.func,
  apiError: PropTypes.string,
  isCurrencyOnly: PropTypes.bool,
};
