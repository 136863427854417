import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button } from '../../../ui/button';
import { Field } from '../../../ui/field';
import { isFunction } from '../../../../helpers/is-function';
import { LoaderIcon } from '../../../icons/loader-icon';
import classes from './styles.module.scss';

const ADDRESS_ERROR_TEXT = 'Please check your address';
const EXTRA_ID_ERROR_TEXT = 'Please check your memo';

export const AddWallet = (props) => {
  const {
    currency,
    onClick,
    errorMessage,
    fetching,
  } = props;

  const name = currency?.name ?? null;
  const reqEx = currency?.walletRegex ?? null;
  const isExtraIdExists = currency?.extraIdExists ?? false;
  const extraIdRegex = currency?.extraIdRegex ?? null;

  const [address, setAddress] = React.useState('');
  const [extraId, setExtraId] = React.useState('');
  const [addressErrorText, setAddressErrorText] = React.useState(null);
  const [extraIdErrorText, setExtraIdErrorText] = React.useState(null);

  const handleInputChange = (value) => {
    setAddress(value);
    setAddressErrorText(null);
  };
  const handleExtraIdChange = (value) => {
    setExtraId(value);
    setExtraIdErrorText(null);
  };
  const handleClick = () => {
    const isValid = address.match(reqEx);
    const isExtraIdValid = extraId.match(extraIdRegex);

    if (!isValid) {
      setAddressErrorText(ADDRESS_ERROR_TEXT);

      return;
    }

    if (isExtraIdExists && !isExtraIdValid) {
      setExtraIdErrorText(EXTRA_ID_ERROR_TEXT);

      return;
    }

    if (isFunction(onClick)) {
      onClick(address, extraId);
    }
  };

  return (
    <div className={classes.addWallet}>
      <div className={classes.addWalletText}>
        You don’t have any payout
        {' '}
        {name}
        {' '}
        wallet. Please add one below.
      </div>
      <p className={classes.addWalletSubTitle}>Payout address</p>
      <Field
        className={cn([
          classes.addWalletInput,
          isExtraIdExists && classes.addWalletInputWithExtraId,
        ])}
        data={{
          type: 'text',
          value: address,
          onChange: handleInputChange,
          placeholder: '1HP2esjU9H914ML7NHg7rX4w4wPGpZkLez',
          error: addressErrorText,
        }}
      />
      {isExtraIdExists && (
        <Field
          className={classes.addWalletInputExtraId}
          title="Destination tag (memo)"
          data={{
            type: 'text',
            value: extraId,
            onChange: handleExtraIdChange,
            placeholder: '123456789',
            error: extraIdErrorText,
          }}
        />
      )}
      {errorMessage && (
        <div className={classes.addWalletErrorMessage}>
          {errorMessage}
        </div>
      )}
      <Button
        className={classes.addWalletButton}
        onClick={handleClick}
        disabled={fetching}
      >
        {fetching && (
          <LoaderIcon
            size={25}
            path="/images/loader-white-on-blue-icon.gif"
          />
        )}
        {!fetching && 'Add Wallet'}
      </Button>
    </div>
  );
};

AddWallet.defaultProps = {
  className: null,
  currency: null,
  onClick: null,
  onClose: null,
  errorMessage: null,
  fetching: false,
};

AddWallet.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.shape({}),
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  errorMessage: PropTypes.string,
  fetching: PropTypes.bool,
};
