import React from 'react';
import { METHOD_NAMES } from '../helpers';
import { hasElementsInArray } from '../../../helpers/has-elements-in-array';
import { useCreateWithdrawalPayments } from '../../../api/modules/account/use-create-withdrawal-payments';
import { isFunction } from '../../../helpers/is-function';
import { getSafeErrorMessageText } from '../../../helpers/get-safe-error-message-text';
import { MASS_PAYOUTS_ERRORS } from '../../../constants/errors-constants';
import { useTimeout } from '../../../hooks/use-timeout';
import { trackEvent } from '../../../helpers/utils/track-event';
import { TRACK_EVENT_NAMES } from '../../../constants/track-event-names';

export const useMassPayoutMethods = (reFetch) => {
  const fetchCreateWithdrawalPayments = useCreateWithdrawalPayments();

  const [activeMethodName, setActiveMethodName] = React.useState(null);
  const [withdrawalId, setWithdrawalId] = React.useState(null);
  const [withdrawalData, setWithdrawalData] = React.useState(null);
  const [fetching, setFetching] = React.useState(false);
  const [apiError, setApiError] = React.useState(null);
  const [isWithdrawalSuccess, setIsWithdrawalSuccess] = React.useState(false);
  const [isNeed2FA, setIsNeed2FA] = React.useState(false);

  const handleResetApiError = () => {
    setApiError(null);
  };
  const handleSetCurrentMethodName = (methodName, trackEventName) => () => {
    setActiveMethodName(methodName);

    trackEvent(trackEventName, {
      action: 'click',
    });
  };
  const handlePopupClose = () => {
    setActiveMethodName(null);
    setWithdrawalId(null);
    setWithdrawalData(null);
  };
  const handleRemoveWithdrawalData = () => {
    setWithdrawalData(null);
  };
  const handleWithdrawalSuccessClose = () => {
    setIsWithdrawalSuccess(false);
  };
  const handle2FACallback = () => {
    if (isFunction(reFetch)) {
      void reFetch();
    }
    setIsNeed2FA(false);
    setWithdrawalData(null);
    setActiveMethodName(null);
    setIsWithdrawalSuccess(true);
    trackEvent(TRACK_EVENT_NAMES.MASS_PAYOUTS_2FA_SUCCESS, {
      action: 'submit',
    });
  };
  const handleCreateWithdrawal = React.useCallback(async (payoutFormData, isManualPayout = false) => {
    const currentWithdrawalData = payoutFormData || withdrawalData;
    const hasWithdrawals = hasElementsInArray(currentWithdrawalData);

    if (!hasWithdrawals) {
      return;
    }

    setFetching(true);
    const { data, status } = await fetchCreateWithdrawalPayments({ withdrawals: currentWithdrawalData });
    setFetching(false);

    if (status !== 200) {
      const errorMessage = getSafeErrorMessageText(data?.errorData?.message);
      const currentMessage = errorMessage.includes(MASS_PAYOUTS_ERRORS.INVALID_IP.MESSAGE)
        ? MASS_PAYOUTS_ERRORS.INVALID_IP.NEW_MESSAGE
        : errorMessage;
      setApiError(currentMessage);

      return;
    }

    const withdrawalId = data?.id ?? null;
    const isVerified = data?.isVerified ?? false;

    setWithdrawalId(withdrawalId);
    const trackEventName = !isManualPayout
      ? TRACK_EVENT_NAMES.MASS_PAYOUTS_CSV_UPLOADED
      : TRACK_EVENT_NAMES.MASS_PAYOUTS_MANUALLY_CREATED;
    const trackEventAction = !isManualPayout ? 'upload_csv' : 'submit';
    trackEvent(trackEventName, {
      action: trackEventAction,
    });

    if (isVerified) {
      if (isFunction(reFetch)) {
        void reFetch();
      }
      setWithdrawalData(null);
      setActiveMethodName(null);
      setIsWithdrawalSuccess(true);
    }

    if (!isVerified) {
      setIsNeed2FA(true);
    }
  }, [withdrawalData, reFetch]);

  useTimeout(handleResetApiError, 4000, apiError);

  const isAutomaticMethodActive = activeMethodName === METHOD_NAMES.AUTO;
  const isManualMethodActive = activeMethodName === METHOD_NAMES.MANUAL;

  return {
    isAutomaticMethodActive,
    isManualMethodActive,
    handleSetCurrentMethodName,
    handlePopupClose,
    withdrawalData,
    setWithdrawalData,
    withdrawalId,
    handleRemoveWithdrawalData,
    isWithdrawalSuccess,
    handleWithdrawalSuccessClose,
    handleCreateWithdrawal,
    withdrawalFetching: fetching,
    withdrawalApiError: apiError,
    isNeed2FA,
    handle2FACallback,
  };
};
