import { DATE_PATTERN } from '../constants/app-constants';
import { getColorStatus } from './get-color-status';
import { safeToUpperCase } from './safe-to-upper-case';
import { getTransactionType, getTransactionTypeText } from './get-transaction-type';
import { formatDate } from './format-date';
import { getArrayWithIndexId } from './get-array-with-index-id';
import { getCurrentNetworkForShow } from './get-current-network-for-show';
import { toFixed } from './to-fixed';

/**
 * @param {Object} payment
 * @param {Object} currenciesObject
 * @returns {Array<object>}
 */
export const getTransactionInfoItems = (payment, currenciesObject = {}) => {
  if (!payment) {
    return [];
  }

  const customerId = payment?.fromSubId ?? null;
  const status = payment?.status ?? null;
  const statusColor = status && getColorStatus(status);
  const statusNode = status ? `<span style="color: ${statusColor}">${status}</span>` : null;
  const amount = payment?.amount ?? null;
  const ticker = payment?.currency ?? '';
  const coinFound = currenciesObject[safeToUpperCase(ticker)] || {};
  const currentTicker = coinFound?.ticker ?? ticker;
  const amountText = amount ? `${toFixed(amount, 8)} ${currentTicker}` : '-';
  const network = getCurrentNetworkForShow(coinFound?.network, ticker);
  const typeUpperCased = safeToUpperCase(payment?.type);
  const transactionType = getTransactionType(typeUpperCased);
  const transactionTypeText = getTransactionTypeText(typeUpperCased);
  const createdDate = payment?.createdAt ?? null;
  const createdDateNormalized = createdDate ? formatDate(createdDate, DATE_PATTERN) : null;
  const updatedDate = payment?.updatedAt ?? null;
  const updatedDateNormalized = updatedDate ? formatDate(updatedDate, DATE_PATTERN) : null;

  return getArrayWithIndexId([{
    key: 'Customer id',
    value: customerId,
    link: null,
    isCopied: false,
  }, {
    key: 'Status',
    value: statusNode,
    link: null,
    isCopied: false,
  }, {
    key: 'Amount',
    value: amountText,
    network: network,
    link: null,
    isCopied: false,
  }, {
    key: 'Transaction type',
    value: `${transactionType} ${transactionTypeText}`,
    link: null,
    isCopied: false,
  }, {
    key: 'Created at',
    value: createdDateNormalized,
    link: null,
    isCopied: false,
  }, {
    key: 'Updated at',
    value: updatedDateNormalized,
    link: null,
    isCopied: false,
  }]);
};
