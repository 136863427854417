import React from 'react';
import cn from 'classnames';
import { DotsIcon } from '../../../icons/dots-icon';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import { getShortStringWithEllipsis } from '../../../../helpers/get-short-string-with-ellipsis';
import { CopyText } from '../../../ui/copy-text';
import { LogoTickerNetwork } from '../../../logo-ticker-network';
import { ExtendedWallet } from '../../types';
import classes from './styles.module.scss';

type PayoutWalletsTableRowProps = {
  wallet: ExtendedWallet,
  isActive: boolean,
  onClick: (wallet: ExtendedWallet) => void,
};

const MAX_DESKTOP_ADDRESS_LENGTH = 50;
const MAX_SHORT_ADDRESS_LENGTH = 27;
const VISIBLE_PART_LENGTH = 12;

const PayoutWalletsTableRow: React.FC<PayoutWalletsTableRowProps> = (props) => {
  const {
    wallet,
    isActive,
    onClick,
  } = props;

  const isDesktop = useMediaQuery('(min-width: 992px)');
  const isAddressShort = !isDesktop || wallet.extraId
    || (isDesktop && wallet.baseAddress?.length > MAX_DESKTOP_ADDRESS_LENGTH);
  const address = isAddressShort
    ? getShortStringWithEllipsis(wallet.baseAddress || '', MAX_SHORT_ADDRESS_LENGTH, VISIBLE_PART_LENGTH, VISIBLE_PART_LENGTH)
    : wallet.baseAddress;

  const handleOptionsClick = () => {
    onClick(wallet);
  };

  return (
    <div className={cn([
      classes.payoutWalletsTableRow,
      isActive && classes.payoutWalletsTableRowActive,
    ])}
    >
      <div className={classes.payoutWalletsTableRowContent}>
        {wallet.priority === 0 && (
          <div
            className={classes.payoutWalletsTableRowPriority}
            data-tooltip-id="main-wallet-tooltip"
          >
            Main wallet
          </div>
        )}
        <LogoTickerNetwork
          className={classes.payoutWalletsTableRowCoinInfo}
          currentTicker={wallet.currentTicker}
          ticker={wallet.ticker}
          logoUrl={wallet.logoUrl}
          network={wallet.network}
          logoSize={20}
        />
        <div className={classes.payoutWalletsTableRowPaymentInfo}>
          <CopyText
            className={classes.payoutWalletsTableRowCopy}
            text={address}
            copyIconPath="/images/copy-icon-small.svg"
            copyText={wallet.baseAddress}
          />
          {wallet.extraId && (
            <CopyText
              className={classes.payoutWalletsTableRowCopy}
              text={wallet.extraId}
              copyIconPath="/images/copy-icon-small.svg"
            />
          )}
        </div>
      </div>
      <button
        className={classes.payoutWalletsTableRowOptions}
        type="button"
        data-tooltip-id="payout-wallets-tooltip"
        onClick={handleOptionsClick}
      >
        <DotsIcon />
      </button>
    </div>
  );
};

export const PayoutWalletsTableRowMemo = React.memo(PayoutWalletsTableRow);
