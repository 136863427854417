import { rules } from "@coxy/react-validator";

const PASSWORD_MIN_LENGTH = 6;
const PASSWORD_MAX_LENGTH = 256;

export const emailRules = () => {
  const rule = [...rules.email];

  rule[0].message = "Please enter your e-mail";
  rule[1].message = "Invalid e-mail";

  return rule;
};

export const passwordRules = () => {
  const rules = [{
    rule: value => value.length > 0,
    message: `Please enter password`,
  },
  {
    rule: value => value.length >= PASSWORD_MIN_LENGTH,
    message: `Password can't be less than ${PASSWORD_MIN_LENGTH} characters`,
  },
  {
    rule: value => value.length <= PASSWORD_MAX_LENGTH,
    message: `Password can't be longer than ${PASSWORD_MAX_LENGTH} characters`,
  },
  {
    rule: value => /^(?=.*?[A-Z])(?=.*?[0-9]).+$/.test(value),
    message: `Password should have 1 uppercase, 1 number, and more than 6 characters in total`,
  }];

  return rules;
};

export const verificationCodeRules = () => {
  const rules = [{
    rule: value => value.length > 0,
    message: 'Please enter code',
  },
  {
    rule: value => value.length === PASSWORD_MIN_LENGTH,
    message: `Code should have 6 symbols`,
  }];

  return rules;
};

export const captchaRules = () => {
  const rules = [
    {
      rule: (value) => value.length > 0,
      message: `Please solve reCAPTCHA correctly`,
    },
  ];

  return rules;
};

export const passwordSignInRules = () => [
  {
    rule: (value) => value.length > 0,
    message: "Please enter password",
  },
];

export const walletRules = (regex) => {
  const rules = [
    {
      message: "Please check your address",
      rule: (value) => Boolean(value.match(regex)),
    },
  ];

  return rules;
};

export const extraIdRules = (regex) => {
  const rules = [
    {
      message: "Please enter a valid destination tag",
      rule: (value) => Boolean(value.match(regex)),
    },
  ];

  return rules;
};

export const shopifyExampleUrlRules = (regex) => [
  {
    rule: (value) => value.length > 0 && regex.test(value),
    message: "Incorrect example URL",
  },
];
