import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button } from '../../../ui/button';
import classes from './styles.module.scss';

export const MassPayoutInfoButtons = (props) => {
  const {
    className,
    onVerify,
    verified,
    onClose,
  } = props;

  const isButtonVerifyShow = !verified && onVerify;

  return (
    <div className={cn(classes.buttons, className)}>
      {isButtonVerifyShow && (
        <Button
          onClick={onVerify}
          className={classes.button}
        >
          Verify
        </Button>
      )}
      <Button
        onClick={onClose}
        className={classes.button}
        displayBorder
      >
        OK
      </Button>
    </div>
  );
};

MassPayoutInfoButtons.defaultProps = {
  className: null,
  onVerify: null,
  verified: false,
  onClose: null,
};

MassPayoutInfoButtons.propTypes = {
  className: PropTypes.string,
  onVerify: PropTypes.func,
  verified: PropTypes.bool,
  onClose: PropTypes.func,
};
