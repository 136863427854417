import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { InfoAboutUse } from '../../../shared/info-about-use';

export const CustodyInfoAboutUse = (props) => {
  const {
    className,
    name,
    onClose,
  } = props;

  return (
    <InfoAboutUse
      className={className}
      name={name}
      onClose={onClose}
    >
      Custody allows you to securely store, exchange, and withdraw your cryptocurrency whenever
      needed.
      <div>
        <strong>How to use:</strong>
        <ol>
          <li>Accept the Custody Solution user agreement and proceed.</li>
          <li>
            Whitelist your IP addresses:
            <p>
              &nbsp;&nbsp;a. Automatically include your current IP address or add other IP addresses.
            </p>
            <p>
              &nbsp;&nbsp;b. Note that future changes or additions should be made through the
              {' '}
              <Link to='/store-settings#addresses'>
                &quot;Payments Settings&quot;
              </Link>
              {' '}
              page
            </p>
          </li>
          <li>Confirm your intention to include the IP address(es) and accept associated risks.</li>
          <li>
            Send a request to
            {' '}
            <a href="mailto:whitelist@nowpayments.io">whitelist@nowpayments.io</a>
            {' '}
            in order to increase withdrawal limit and whitelist your wallets for safety reasons.
          </li>
          <li>Your Custody is now enabled and ready to use.</li>
        </ol>
      </div>
    </InfoAboutUse>
  );
};

CustodyInfoAboutUse.defaultProps = {
  className: null,
  name: null,
  onClose: null,
};

CustodyInfoAboutUse.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onClose: PropTypes.func,
};
