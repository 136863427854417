import React from 'react';
import { Portal } from '../../../portal';
import { Popup } from '../../../popup';
import { POPUP_OBJECT } from '../../helpers';
import { EditApiKey } from '../edit-api-key';
import { DeleteApiKey } from '../delete-api-key';
import { UnableDeleteLastApiKey } from '../unable-delete-last-api-key';
import { AddWalletCurrencies } from '../../../add-wallet-currencies';
import { ApiKey, Currency } from '../../../../default-types';
import { PopupData } from '../../types';
import classes from './styles.module.scss';

type ApiKeysPopupProps = {
  type: PopupData | null,
  currencies: Array<Currency>;
  onClose: () => void,
  selectedApiKey: ApiKey | null,
  isLastApiKey: boolean,
  newApiKey: ApiKey | null,
  onAddNewKey: () => Promise<void>,
  onAddNewWallet: (generatedApiKey: string) => void,
  isCreatingApiKey: boolean,
  isNeedGenerateNewKey: boolean,
};

export const ApiKeysPopup: React.FC<ApiKeysPopupProps> = (props) => {
  const {
    type,
    currencies,
    onClose,
    selectedApiKey,
    isLastApiKey,
    newApiKey,
    onAddNewKey,
    onAddNewWallet,
    isCreatingApiKey,
    isNeedGenerateNewKey,
  } = props;

  const isEditApiKey = type?.type === POPUP_OBJECT.edit.type && selectedApiKey;
  const isDelete = type?.type === POPUP_OBJECT.delete.type && selectedApiKey && !isLastApiKey;
  const isDeleteLastKey = type?.type === POPUP_OBJECT.delete.type && selectedApiKey && isLastApiKey;
  const isAddKey = type?.type === POPUP_OBJECT.addKey.type;
  const isAddWallet = type?.type === POPUP_OBJECT.addWallet.type;
  const title = isDeleteLastKey ? 'Unable to delete the only API key' : type?.title;

  return (
    <Portal>
      <Popup
        classNameContent={classes.apiKeysPopup}
        title={title}
        open={Boolean(type)}
        onClose={onClose}
        withCloseButton
        mobileFullView
        withoutPadding
        line
      >
        {isAddWallet && (
          <AddWalletCurrencies
            className={classes.apiKeysAddWalletPopup}
            currencies={currencies}
            onGenerateApiKey={onAddNewWallet}
            showWarning
            formAttributes={{
              currencyTitle: 'Choose currency',
              currencyPlaceholder: 'Currency',
              isSmallTheme: true,
              walletTitle: 'Enter wallet address',
              walletPlaceholder: 'Address',
              memoTitle: 'Enter Memo',
              memoPlaceholder: 'Memo',
              submitButtonLabel: 'Add wallet',
            }}
          />
        )}
        {(isEditApiKey || isAddKey) && (
          <EditApiKey
            apiKey={isAddKey ? newApiKey : selectedApiKey}
            onClose={onClose}
            isNeedGenerateNewKey={isNeedGenerateNewKey}
            withNewKey={isAddKey}
            onAddNewKey={onAddNewKey}
            isCreatingApiKey={isCreatingApiKey}
          />
        )}
        {isDelete && (
          <DeleteApiKey
            apiKey={selectedApiKey}
            onClose={onClose}
          />
        )}
        {isDeleteLastKey && (
          <UnableDeleteLastApiKey onClose={onClose} />
        )}
      </Popup>
    </Portal>
  );
};
