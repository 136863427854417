import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { isFunction } from '../../../helpers/is-function';
import classes from './styles.module.scss';

export const Radio = (props) => {
  const {
    className,
    id,
    name,
    value,
    checked,
    children,
    onChange,
  } = props;

  const handleChange = () => {
    if (isFunction(onChange)) {
      onChange(value);
    }
  };

  return (
    <label className={cn(classes.radio, className)}>
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={handleChange}
      />
      <div className={classes.radioBox} />
      {children}
    </label>
  );
};

Radio.defaultProps = {
  className: null,
  id: null,
  name: null,
  value: null,
  checked: false,
  children: null,
  onChange: null,
};

Radio.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  children: PropTypes.node,
  onChange: PropTypes.func,
};
