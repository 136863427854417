import React from "react";
import { Helmet } from "react-helmet";

const Metatags = ({ title, description, url }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    <link href={url} rel="canonical" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={url} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:site_name" content="NOWPayments" />
    <meta
      property="og:image"
      content="https://nowpayments.io/images/logo/sharing_image.png"
    />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@NOWPayments_io" />
    <meta name="twitter:creator" content="@NOWPayments_io" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta
      name="twitter:image"
      content="https://nowpayments.io/images/logo/sharing_image.png"
    />
    <script type="application/ld+json">
      {`{
          "@context": "http://schema.org",
          "@type": "WebPage",
          "headline": "${title}",
          "name": "${title}",
          "mainEntity": "${url}",
          "url": "${url}",
          "description": "${description}",
          "about": "${description}",
          "image": "https://nowpayments.io/images/logo/sharing_image.png",
          "publisher": {
            "@type": "Organization",
            "name": "NOWPayments",
            "logo": {
              "@type": "ImageObject",
              "url": "https://nowpayments.io/images/logo/scheme_logo.png",
              "height": "60",
              "width": "400"
            }
          },
          "sameAs": [
            "https://twitter.com/NOWPayments_io",
            "https://www.facebook.com/NOWPayments.io",
            "https://medium.com/@NOWPayments",
            "https://www.reddit.com/user/NOWPayments",
            "https://github.com/nowpaymentsIO%22",
            "https://www.youtube.com/channel/UC3jYxRnDdSKr65sdVA09Q5Q",
            "https://www.producthunt.com/posts/nowpayments",
            "https://www.linkedin.com/company/nowpayments"
          ]
        }`}
    </script>
  </Helmet>
);

export default Metatags;