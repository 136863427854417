import axios from 'axios';

const FORM_URL = 'https://docs.google.com/forms/u/0/d/e/1FAIpQLScyDWmeS5qOyUlNnosXrzQ23aCPEjtnxFAdW5hZZxD94Hz7Sw/formResponse';
const CORS_PROXY = 'https://api.allorigins.win/get?url=';
const EMAIL_ID = 'entry.1330885341';
const UTMS_ID = 'entry.1569425536';
/**
 * @param {String} email
 * @param {String} metrics
 */
export const sendUtmMetrics = async (email, metrics) => {
  const isEmail = typeof email === 'string' && email;
  const isMetrics = typeof metrics === 'string' && metrics;
  const currentEmail = isEmail ? email : '';

  if (!isMetrics) {
    return;
  }

  const emailData = `${EMAIL_ID}=${currentEmail}`;
  const metricsData = `${UTMS_ID}=${metrics}`;
  const actionUrlWithParams = `${FORM_URL}?${emailData}&${metricsData}&submit=Submit`;

  try {
    await axios({
      method: 'get',
      url: CORS_PROXY + encodeURIComponent(actionUrlWithParams),
    });
  } catch (error) {
    console.log(error);
  }
};
