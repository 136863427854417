import { formatISO } from 'date-fns';

/**
 * @param {Object|String} date
 * @param {String} representation
 * @returns {String}
 */
export const getDateIsoString = (date = null, representation = null) => {
  const newDate = new Date(date);

  return formatISO(newDate, { representation });
};
