import React from 'react';
import config from '../../config';
import classes from './styles.module.scss';

const YEAR = new Date().getFullYear();
const DOMAIN = config.front_domain;

export const Footer = () => (
  <div className={classes.footer}>
    <div className={classes.copyright}>
      &copy;
      {' '}
      <span>NOW</span>Payments
      {' '}
      -
      {' '}
      {YEAR}
    </div>
    <div className={classes.links}>
      <a
        href={`${DOMAIN}/doc/user_agreement-v1_2_3.pdf`}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
      >
        User Agreement
      </a>
      <a
        href={`${DOMAIN}/doc/privacy_policy-v1_2_2.pdf`}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
      >
        Privacy Policy
      </a>
    </div>
  </div>
);
