import React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';
import { walletMapFn } from '../../helpers/account/map-fns';

const mapFn = (data) => ({
  covering: data?.covering ?? null,
  markup: data?.markup ?? null,
  feeOptimization: data?.feeOptimization ?? false,
  wallets: Array.isArray(data?.wallets) ? data.wallets.map(walletMapFn) : [],
  withdrawalFeePaidBy: data?.withdrawalFeePaidBy ?? null,
});

export const useStoreOutcomeWallets = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async () => {
    const [cancelToken] = getRequestSource();
    const requestPromise = client.get(
      '/store-outcome-wallets',
      null,
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
