import React from "react";
import PropTypes from "prop-types";
import IconPrimaryLoader from "./loader.svg";

const Loader = ({ className, width }) => (
  <img
    src={IconPrimaryLoader}
    alt=""
    className={className}
    {...(width ? { style: { width } } : {})}
    {...(width ? { style: { height: width } } : {})}
  />
);

Loader.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
};

export default Loader;
