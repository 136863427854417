import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Popup } from '../../../popup';
import { isFunction } from '../../../../helpers/is-function';
import { Button } from '../../../ui/button';
import { LoaderIcon } from '../../../icons/loader-icon';
import classes from './styles.module.scss';

export const PopupUnsubscribe = (props) => {
  const {
    className,
    open,
    onClose,
    name,
    onUnsubscribe,
    apiError,
    pending,
  } = props;

  const handleUnsubscribe = () => {
    if (isFunction(onUnsubscribe)) {
      onUnsubscribe();
    }
  };

  return (
    <Popup
      classNameContent={cn(classes.popupUnsubscribe, className)}
      open={open}
      onClose={onClose}
      withCloseButton
      mobileFullView
      line
      title="Deactivate user"
    >
      Are you sure you want to deactivate billing plan for
      {' '}
      <strong>{name}</strong>? If you remove this user from the subscription plan, he will
      stop receiving payment invoices. No further payments will be accepted.
      <div className={classes.error}>
        {apiError}
      </div>
      <Button
        className={classes.buttonUnsubscribe}
        onClick={handleUnsubscribe}
        displayBorder
      >
        {pending
          ? <LoaderIcon path="/images/loader-white-on-red-icon.gif" />
          : 'Deactivate'}
      </Button>
      <Button
        className={classes.buttonCancel}
        onClick={onClose}
        displayBorder
      >
        Cancel
      </Button>
    </Popup>
  );
};

PopupUnsubscribe.defaultProps = {
  className: null,
  open: null,
  onClose: null,
  name: null,
  onUnsubscribe: null,
  pending: false,
};

PopupUnsubscribe.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  name: PropTypes.string,
  onUnsubscribe: PropTypes.func,
  pending: PropTypes.bool,
};
