import React from 'react';
import {
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
  getRedirectLink,
  setRedirectLink,
} from '../../../libs/local-storage';

export const useNotifications = () => {
  const [isNotificationPopupShow, setIsNotificationPopupShow] = React.useState<boolean>(false);

  const checkRedirect = () => {
    const redirectLink = getRedirectLink();

    if (redirectLink) {
      setRedirectLink('');
      window.location.href = redirectLink;
    }
  };
  const checkDestroyTfa = () => {
    const isNeeded = getItemFromLocalStorage('destroyTfa');

    if (isNeeded) {
      setIsNotificationPopupShow(true);
    }
  };
  const handleNotificationPopupClose = () => {
    setIsNotificationPopupShow(false);
    removeItemFromLocalStorage('destroyTfa');
  };

  React.useEffect(() => {
    checkRedirect();
    checkDestroyTfa();
  }, []);

  return {
    isNotificationPopupShow,
    handleNotificationPopupClose,
  };
};
