import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { AddWalletCurrencies } from '../add-wallet-currencies';
import { Popup } from '../popup';
import classes from './styles.module.scss';

export const AddWalletCurrenciesPopup = (props) => {
  const {
    className,
    open,
    onClose,
    currencies,
    onCallback,
    updateFetching,
  } = props;

  return (
    <Popup
      classNameContent={cn(classes.popup, className)}
      open={open}
      onClose={onClose}
      withCloseButton
    >
      <AddWalletCurrencies
        currencies={currencies}
        onCallback={onCallback}
        callbackFetching={updateFetching}
      />
    </Popup>
  );
};

AddWalletCurrenciesPopup.defaultProps = {
  className: null,
  open: null,
  onClose: null,
  currencies: null,
  onCallback: null,
  updateFetching: false,
};

AddWalletCurrenciesPopup.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  currencies: PropTypes.arrayOf(PropTypes.shape({})),
  onCallback: PropTypes.func,
  updateFetching: PropTypes.bool,
};
