import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Link, useHistory } from 'react-router-dom';
import qs from 'query-string';
import ValidatorWrapper, { ValidatorField } from '@coxy/react-validator';
import ReCAPTCHA from 'react-google-recaptcha';
import cn from 'classnames';
import apiClient from '../../api/api-client';
import { emailRules, passwordSignInRules, captchaRules } from '../../libs/validators';
import WithdrawalInput from '../../components/withdrawal-input';
import Button from '../../components/button';
import Metatags from '../../components/metatags';
import config from '../../config';
import passwordShowIcon from '../../images/svg/password-show.svg';
import {
  removeItemFromLocalStorage,
  setAuthToken,
  setDestroyTfa,
  setMasterId,
} from '../../libs/local-storage';
import { setAuthCookieToken } from '../../libs/cookies';
import { getServiceById } from '../../api/oauth-api-client';
import { useLogin } from '../../api/modules/account/use-login';
import { ENABLE_CUSTODY_LOCAL_STORAGE_NAME } from '../../constants/app-constants';
import { GoogleAuthButton } from '../../components/google-auth-button';
import { useLoginWithGoogle } from '../../api/modules/account/use-login-with-google';
import { getSafeErrorMessageText } from '../../helpers/get-safe-error-message-text';
import {
  LOGIN_VERIFICATION_PATH,
  useLoginVerification,
} from '../../api/modules/account/use-login-verification';
import { consoleErrorMessage } from '../../helpers/console-error-message';
import { LoaderIcon } from '../../components/icons/loader-icon';
import { ROUTES } from '../../constants/routes';
import { useTimeout } from '../../hooks/use-timeout';
import { ERROR_TEXTS, ERRORS } from '../../constants/errors-constants';
import { useSendOuterClientError } from '../../api/modules/account/use-send-outer-client-error';
import SelectTeam from './components/select-team';
import TwoFactor from './components/two-factor';
import styles from './styles.module.scss';

const SigninPage = (props) => {
  const isDevelopment = process.env.NODE_ENV === 'development';
  const fetchLogin = useLogin(isDevelopment);
  const fetchLoginWithGoogle = useLoginWithGoogle();
  const sendOuterClientError = useSendOuterClientError();
  const fetchLoginVerification = useLoginVerification();
  const history = useHistory();

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [passwordType, setPasswordType] = React.useState('password');
  const [isOauth, setIsOauth] = React.useState(false);
  const [oauthService, setOauthService] = React.useState({});
  const [partnerId, setPartnerId] = React.useState('');
  const [captcha, setCaptcha] = React.useState('');
  const [verificationCode, setVerificationCode] = React.useState('');
  const [backupKey, setBackupKey] = React.useState('');
  const [tokenKey, setTokenKey] = React.useState('');
  const [tfaButtonErrorMessage, setTfaButtonErrorMessage] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [isActivated, setIsActivated] = React.useState(false);
  const [needTfaToken, setNeedTfaToken] = React.useState(false);
  const [hasTeam, setHasTeam] = React.useState(false);
  const [isSelectTeamShow, setIsSelectTeamShow] = React.useState(false);
  const [isErrorShown, setIsErrorShown] = React.useState(false);
  const [submitButtonPressed, setSubmitButtonPressed] = React.useState(false);
  const [temporaryToken, setTemporaryToken] = React.useState(null);
  const [tfaFetching, setTfaFetching] = React.useState(false);
  const [isPending, setIsPending] = React.useState(false);
  const [registerGoogleApiError, setRegisterGoogleApiError] = React.useState(null);
  const [type2fa, setType2fa] = React.useState(null);
  const [isNeedUserAuthentication, setIsNeedUserAuthentication] = React.useState(false);
  const [isUserAuthenticationPending, setIsUserAuthenticationPending] = React.useState(false);
  const [apiErrorMessage, setApiErrorMessage] = React.useState(null);

  const validator = React.useRef();
  const captchaRef = React.useRef();

  const resetApiError = () => {
    setApiErrorMessage(null);
  };
  const resetGoogleApiError = () => {
    setRegisterGoogleApiError(null);
  };
  const _handleKeyUpEvent = async (e) => {
    if (e.key === 'Enter') {
      await handleSignInClick();
    }
  };

  const handleTeamBackClick = () => {
    setHasTeam(false);
    setIsSelectTeamShow(false);
  };
  const handleSingInSuccess = (data) => {
    const token = data?.token ?? null;
    const need2faToken = data?.need2faToken ?? false;
    const hasTeam = data?.hasTeam ?? false;
    const nextPartnerId = data?.partnerId ?? null;
    const nextType2fa = data?.type2fa ?? null;

    setTokenKey(token);
    setPartnerId(nextPartnerId);
    removeItemFromLocalStorage(ENABLE_CUSTODY_LOCAL_STORAGE_NAME);
    setMasterId(nextPartnerId);

    if (need2faToken) {
      setTemporaryToken(token);
      setIsLoading(false);
      setType2fa(nextType2fa);
      setNeedTfaToken(true);
      setHasTeam(hasTeam);

      return;
    }

    if (hasTeam) {
      setIsLoading(false);
      setIsUserAuthenticationPending(false);
      setHasTeam(hasTeam);
      setIsSelectTeamShow(true);

      return;
    }

    setAuthToken(token);
    setAuthCookieToken(token);
    window.location.reload();
  };
  const handleGoogleAuthSubmit = async ({ accessToken, email: nextEmail }) => {
    if (nextEmail) {
      setEmail(nextEmail);
    }
    setIsPending(true);
    const { data, status } = await fetchLoginWithGoogle({ accessToken });

    if (status === 200) {
      const isUserConfirmationRequired = data?.isUserConfirmationRequired ?? false;
      if (!isUserConfirmationRequired) {
        handleSingInSuccess(data);
      } else {
        setIsNeedUserAuthentication(true);
      }
    } else {
      const errorMessage = getSafeErrorMessageText(data?.errorData?.message);
      setRegisterGoogleApiError(errorMessage);
      void sendOuterClientError({
        message: `Sign in Google - ${email}: ${errorMessage}`,
        href: window.location.href,
      });
    }
    setIsPending(false);
  };
  const handleSignInClick = async () => {
    const { isValid } = validator.current.validate();

    setSubmitButtonPressed(true);

    if (!isValid) {
      return;
    }

    setIsLoading(true);
    const { data, status } = await fetchLogin({
      email,
      password,
      captchaToken: captcha,
    });

    if (status === 200) {
      const isUserConfirmationRequired = data?.isUserConfirmationRequired ?? false;
      if (!isUserConfirmationRequired) {
        handleSingInSuccess(data);
      } else {
        setIsNeedUserAuthentication(true);
      }
    } else {
      setIsLoading(false);
      setIsErrorShown(true);
      if (!isDevelopment) {
        captchaRef.current.reset();
      }
      if (data?.errorData?.error !== (ERRORS.INVALID_RECARTCHA || ERRORS.SOLVE_RECARTCHA)) {
        void sendOuterClientError({
          message: `Sign in - ${email}: ${getSafeErrorMessageText(data?.errorData?.message)}`,
          href: window.location.href,
        });
      }
    }
  };

  const tfaSignIn = async (captchaToken) => {
    if (!captchaToken) {
      setTfaButtonErrorMessage('Please solve reCAPTCHA correctly');

      setTimeout(() => {
        setTfaButtonErrorMessage('');
      }, 2000);

      return;
    }

    try {
      setTfaFetching(true);

      const { data } = await apiClient.post('login/two-factor', {
        verification_code: verificationCode,
        captchaToken: captcha,
        token: temporaryToken,
      });
      const nextToken = data?.token ?? null;

      setTokenKey(nextToken);
      setTfaFetching(false);
      setAuthToken(nextToken);
      setAuthCookieToken(nextToken);

      if (!hasTeam) {
        window.location.reload();
      } else {
        setNeedTfaToken(false);
        setIsSelectTeamShow(true);
      }
    } catch (err) {
      let tfaError = 'Error';

      if (err.response && err.response.data && err.response.data.error) {
        tfaError = err.response.data.error;
      }

      setTfaFetching(false);
      setTfaButtonErrorMessage(tfaError);

      if (err?.response?.data?.error !== (ERRORS.INVALID_RECARTCHA || ERRORS.SOLVE_RECARTCHA)) {
        void sendOuterClientError({
          message: `Sign in 2FA - ${email}: ${tfaError}`,
          href: window.location.href,
        });
      }

      setTimeout(() => {
        setTfaButtonErrorMessage('');
      }, 2000);
    }
  };

  const backupKeySignIn = async (captchaToken) => {
    if (!captchaToken.length) {
      setTfaButtonErrorMessage('Please solve reCAPTCHA correctly');

      setTimeout(() => {
        setTfaButtonErrorMessage('');
      }, 2000);

      return;
    }

    try {
      setTfaFetching(true);

      const { data } = await apiClient.post('login/backup', {
        backup_key: backupKey,
        captchaToken: captcha,
        token: temporaryToken,
      });

      setAuthToken(data.token);
      setAuthCookieToken(data.token);
      setDestroyTfa(true);
      window.location.reload();
    } catch (err) {
      let tfaError = 'Error';

      if (err.response && err.response.data && err.response.data.error) {
        tfaError = err.response.data.error;
      }

      setTfaFetching(false);
      setTfaButtonErrorMessage(tfaError);

      setTimeout(() => {
        setTfaButtonErrorMessage('');
      }, 2000);
    }
  };

  const handleCaptchaChange = async (token) => {
    setCaptcha(token);
    setSubmitButtonPressed(false);
    setIsErrorShown(false);
  };

  const clearErrorState = () => {
    setSubmitButtonPressed(false);
    setIsErrorShown(false);
  };

  const changePasswordType = () => {
    if (passwordType === 'password') setPasswordType('text');
    else setPasswordType('password');
  };

  const handleLoginVerification = async (uuid) => {
    setIsUserAuthenticationPending(true);
    const { data, status, errorMessage } = await fetchLoginVerification({
      uuid: uuid,
    });

    history.replace(ROUTES.SIGN_IN);

    if (status === 200) {
      handleSingInSuccess(data);
    } else {
      setIsUserAuthenticationPending(false);
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      const frontendErrorText = ERROR_TEXTS[errorDataMessage];
      const currentErrorMessage = frontendErrorText || errorDataMessage;
      consoleErrorMessage(errorMessage, currentErrorMessage, LOGIN_VERIFICATION_PATH);
      setApiErrorMessage(currentErrorMessage);
    }
  };

  React.useEffect(() => {
    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });

    if (params.confirmationHash) {
      void handleLoginVerification(params.confirmationHash);

      return () => {};
    }

    if (params.activated) {
      setIsActivated(true);
    }

    if (params.oauth) {
      setIsOauth(true);
    }

    window.addEventListener('keypress', _handleKeyUpEvent);

    return () => {
      window.removeEventListener('keypress', _handleKeyUpEvent);
    };
  }, []);

  const fetch = async () => {
    const params = qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
    });

    const { data } = await getServiceById(params.service_id);

    setOauthService(data);
  };

  React.useEffect(() => {
    if (isOauth) {
      fetch();
    }
  }, [isOauth]);

  const changeRecapcha = async (token) => {
    setCaptcha(token);
    clearErrorState();
  };

  const onExpired = async () => {
    setCaptcha('');
    clearErrorState();
  };

  useTimeout(resetGoogleApiError, 4000, registerGoogleApiError);
  useTimeout(resetApiError, 4000, apiErrorMessage);

  const isTwoFactorShow = !isNeedUserAuthentication && needTfaToken && !isSelectTeamShow;
  const isSignInFormShow = !isSelectTeamShow && !needTfaToken;
  const isShowRecaptcha = !isDevelopment;

  return (
    <div className={styles.formContainer}>
      <Metatags
        title="Sign In or Create Your Account Today | NOWPayments"
        description="Access an opportunity to accept payments in crypto with NOWPayments. Just enter your email, password, click «Sign In» and go."
        url="https://account.nowpayments.io/sign-in"
      />
      <div className={styles.form}>
        {isSelectTeamShow && (
          <SelectTeam
            email={email}
            token={tokenKey}
            partnerId={partnerId}
            onBack={handleTeamBackClick}
          />
        )}
        {isTwoFactorShow && (
          <TwoFactor
            verificationCode={verificationCode}
            backupKey={backupKey}
            tfaErrorMessage={tfaButtonErrorMessage}
            onTfaChange={(value) => setVerificationCode(value.trim())}
            onBackupChange={(value) => setBackupKey(value.trim())}
            tfaSignIn={tfaSignIn}
            backupKeySignIn={backupKeySignIn}
            onCaptchaChange={handleCaptchaChange}
            fetching={tfaFetching}
            type2fa={type2fa}
          />
        )}
        {isSignInFormShow && (
          <>
            {isActivated && (
              <div className={styles.notification}>
                Your email was successfully verified. Now sign in with your credentials
              </div>
            )}
            <div>
              <p className={styles.logo}>
                <span className={cn(styles.logo, styles.blueText)}>NOW</span>
                Payments
              </p>
              {isOauth && (
                <p>Sign in your account to authorize <b>{oauthService.name}</b></p>
              )}
            </div>
            <div className={styles.divider} />
            <div className={styles.title}>
              <p className={styles.titleBig}>Sign in</p>
              {isUserAuthenticationPending && (
                <LoaderIcon size={25} />
              )}
            </div>
            {!isUserAuthenticationPending && isNeedUserAuthentication && (
              <div className={styles.notification}>
                We&apos;ve sent a message to your email for login attempt confirmation. Please follow
                the link in the message to proceed with authorization.
              </div>
            )}
            {!isUserAuthenticationPending && !isNeedUserAuthentication && (
              <>
                <ValidatorWrapper ref={validator}>
                  <ValidatorField value={email} rules={emailRules()}>
                    {({ isValid, message }) => (
                      <div className={styles.inputContainer}>
                        <WithdrawalInput
                          type="email"
                          placeholder={'john.doe@gmail.com'}
                          label="E-mail"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            clearErrorState();
                          }}
                          error={((!isValid && submitButtonPressed) || isErrorShown)}
                          errorMessage={((!isValid && submitButtonPressed) && message) || (isErrorShown && submitButtonPressed && 'Incorrect email or password.')}
                          autoComplete="on"
                        />
                      </div>
                    )}
                  </ValidatorField>
                  <ValidatorField value={password} rules={passwordSignInRules()}>
                    {({ isValid, message }) => (
                      <div className={styles.inputContainer}>
                        <WithdrawalInput
                          type={passwordType}
                          label="Password"
                          value={password}
                          placeholder={'1 uppercase, 1 number, >6 characters'}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            clearErrorState();
                          }}
                          error={((!password && submitButtonPressed) || isErrorShown)}
                          errorMessage={!isValid && submitButtonPressed && message}
                        />
                        <div className={styles.passwordEye} onClick={changePasswordType}>
                          <img src={passwordShowIcon} alt="password eye" width={14} height={11} />
                        </div>
                      </div>
                    )}
                  </ValidatorField>
                  {isShowRecaptcha && <ValidatorField value={captcha} rules={captchaRules()}>
                    {({ isValid, message }) => (
                      <div className={styles.reCaptchaContainer}>
                        {!isValid && submitButtonPressed && <div className={styles.error}>{message}</div>}
                        <ReCAPTCHA
                          sitekey={config.captcha_key}
                          ref={captchaRef}
                          hl="en"
                          onChange={(token) => changeRecapcha(token)}
                          onExpired={onExpired}
                        />
                      </div>
                    )}
                  </ValidatorField>}
                </ValidatorWrapper>
                <div className={styles.apiErrorMessage}>
                  {apiErrorMessage}
                </div>
                <Button
                  onClick={handleSignInClick}
                  loading={isLoading}
                >
                  Sign in
                </Button>
                {config.GOOGLE_CLIENT_ID && (
                  <GoogleOAuthProvider clientId={config.GOOGLE_CLIENT_ID}>
                    <GoogleAuthButton
                      className={styles.googleButton}
                      onCallBack={handleGoogleAuthSubmit}
                      pending={isPending}
                      errorMessage={registerGoogleApiError}
                    >
                      Sign in with Google
                    </GoogleAuthButton>
                  </GoogleOAuthProvider>
                )}
                <div className={styles.divider} />
                <div className={styles.createContainer}>
                  <Link
                    to="/create-account"
                    className={styles.link}
                  >
                    Create an account
                  </Link>
                  <Link
                    to="/request-reset"
                    className={styles.link}
                  >
                    Reset password
                  </Link>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SigninPage;
