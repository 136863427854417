import * as React from 'react';
import PropTypes from 'prop-types';
import { MassPayoutsFilter } from '../mass-payouts-filter';
import { isFunction } from '../../../../helpers/is-function';
import { CustomSearchFilter } from '../../../shared/custom-search-filter';
import { Button } from '../../../ui/button';
import classes from './styles.module.scss';

export const MassPayoutsFullFilter = (props) => {
  const {
    onChangeSearch,
    activeFilterItemsNumber,
    onSubmit,
    onFilterReset,
    defaultData,
    onExportModal,
    exportDisabled,
  } = props;

  const handleFilterClose = (func) => {
    func();
  };
  const handleFilterSubmit = (data) => {
    if (isFunction(onSubmit)) {
      onSubmit(data, handleFilterClose);
    }
  };

  return (
    <CustomSearchFilter
      className={classes.massPayoutsFullFilter}
      onChangeSearch={onChangeSearch}
      activeFilterItemsNumber={activeFilterItemsNumber}
      placeholder="ID, Batch ID"
      onFilterClose={handleFilterClose}
      AdditionElements={(
        <Button
          className={classes.exportButton}
          onClick={onExportModal}
          disabled={exportDisabled}
        >
          Export
        </Button>
      )}
    >
      <MassPayoutsFilter
        className={classes.massPayoutsFilter}
        onSubmit={handleFilterSubmit}
        onResetFormData={onFilterReset}
        defaultData={defaultData}
      />
    </CustomSearchFilter>
  );
};

MassPayoutsFullFilter.defaultProps = {
  onChangeSearch: null,
  activeFilterItemsNumber: null,
  onSubmit: null,
  onFilterReset: null,
  defaultData: null,
  onExportModal: null,
  exportDisabled: false,
};

MassPayoutsFullFilter.propTypes = {
  onChangeSearch: PropTypes.func,
  activeFilterItemsNumber: PropTypes.number,
  onSubmit: PropTypes.func,
  onFilterReset: PropTypes.func,
  defaultData: PropTypes.object,
  onExportModal: PropTypes.func,
  exportDisabled: PropTypes.bool,
};
