import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isFunction } from '../../helpers/is-function';
import {
  BALANCE_TOP_UP_SCHEMA,
  BALANCE_TOP_UP_SCHEMA_WITH_AMOUNT,
} from '../custody-page/custody-page-helpers';
import { CurrenciesSelectField } from '../currencies-select-field';
import { Field } from '../ui/field';
import { safeToUpperCase } from '../../helpers/safe-to-upper-case';
import { Button } from '../ui/button';
import { LoaderIcon } from '../icons/loader-icon';
import { DEPOSIT_ADDRESSES_NETWORKS } from '../../constants/app-constants';
import { safeToLowerCase } from '../../helpers/safe-to-lower-case';
import { trackEvent } from '../../helpers/utils/track-event';
import { TRACK_EVENT_NAMES } from '../../constants/track-event-names';
import classes from './styles.module.scss';

export const BalanceTopUpForm = (props) => {
  const {
    className,
    currencies,
    isLoading,
    estimateFetching,
    onSubmit,
    baseCurrencyTicker,
    errorApiMessage,
    minAmount,
    errorAmountMessage,
    estimated,
    onAmountChange,
    onCurrencyFrom,
    onCurrencyTo,
    payCurrencyErrorMessage,
    defaultTicker,
    onDepositActivate,
    depositError,
    resetAddressError,
  } = props;

  const [fromCurrencySelected, setFromCurrencySelected] = React.useState(null);
  const [toCurrencySelected, setToCurrencySelected] = React.useState(null);
  const [isFromCurrencyPDA, setIsFromCurrencyPDA] = React.useState(false);
  const [isToCurrencyPDA, setIsToCurrencyPDA] = React.useState(false);
  const [payAmount, setPayAmount] = React.useState(null);
  const [isAmountVisible, setIsAmountVisible] = React.useState(true);

  const {
    control,
    handleSubmit,
    formState,
    setError,
  } = useForm({
    resolver: yupResolver(isAmountVisible ? BALANCE_TOP_UP_SCHEMA_WITH_AMOUNT : BALANCE_TOP_UP_SCHEMA),
  });
  const {
    fromCurrency,
    toCurrency,
    amount,
  } = formState.errors;
  const fromCurrencyErrorMessage = fromCurrency?.message ?? null;
  const toCurrencyErrorMessage = toCurrency?.message ?? null;
  const amountErrorMessage = amount?.message ?? null;
  const isOneNetwork = fromCurrencySelected?.network === toCurrencySelected?.network;
  const isPDA = isFromCurrencyPDA && isToCurrencyPDA && isOneNetwork && !depositError;

  const checkPDANetwork = (network) => {
    return DEPOSIT_ADDRESSES_NETWORKS.includes(safeToLowerCase(network));
  };
  const handleCurrencyFromSelect = (func) => (currency) => {
    const ticker = currency?.code ?? '';
    const prevNetwork = fromCurrencySelected?.network;
    const network = currency?.network ?? '';

    func(ticker);
    setFromCurrencySelected(currency);
    setIsFromCurrencyPDA(checkPDANetwork(network));
    setError('fromCurrency', { type: 'error', message: null });
    if (prevNetwork !== network) {
      resetAddressError();
    }

    if (isFunction(onCurrencyFrom)) {
      onCurrencyFrom(currency?.code, toCurrencySelected?.code, payAmount);
    }
  };
  const handleCurrencyToSelect = (func) => (currency) => {
    const ticker = currency?.code ?? '';
    const prevNetwork = toCurrencySelected?.network;
    const network = currency?.network ?? '';

    func(ticker);
    setToCurrencySelected(currency);
    setIsToCurrencyPDA(checkPDANetwork(network));
    setError('toCurrency', { type: 'error', message: null });
    if (prevNetwork !== network) {
      resetAddressError();
    }

    if (isFunction(onCurrencyTo)) {
      onCurrencyTo(fromCurrencySelected?.code, currency?.code, payAmount);
    }
  };
  const handleAmountChange = (func) => (value) => {
    if (!fromCurrencySelected) {
      setError('fromCurrency', { type: 'error', message: 'Balance currency is required field' });
    }

    func(value);
    setPayAmount(value);

    if (isFunction(onAmountChange)) {
      onAmountChange(value, fromCurrencySelected?.code, toCurrencySelected?.code);
    }
  };
  const handleFormSubmit = (data) => {
    if (isFunction(onSubmit) && !isPDA) {
      onSubmit(data);
    }
    if (isFunction(onDepositActivate) && isPDA) {
      onDepositActivate(toCurrencySelected);
      trackEvent(TRACK_EVENT_NAMES.CUSTODY_PDA_TOP_UP_SUCCESS, {
        action: 'submit',
      });
    }
  };

  React.useEffect(() => {
    if (isPDA) {
      setIsAmountVisible(false);
    } else {
      setIsAmountVisible(true);
    }
  }, [isPDA]);

  const baseCurrencyTickerUpperCased = safeToUpperCase(baseCurrencyTicker);
  const fromCurrencyTicker = fromCurrencySelected?.code ?? null;
  const fromCurrencyTickerUpperCased = safeToUpperCase(fromCurrencyTicker);
  const toCurrencyTicker = toCurrencySelected?.code ?? null;
  const toCurrencyTickerUpperCased = safeToUpperCase(toCurrencyTicker);
  const minAmountTextShow = minAmount && !estimated && !estimateFetching && !isPDA;
  const estimatedTextShow = estimated && !estimateFetching && !isPDA;

  return (
    <form
      className={cn(classes.balanceTopUpForm, className)}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <Controller
        name="fromCurrency"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <CurrenciesSelectField
            className={cn(classes.balanceTopUpFormSelect, classes.balanceTopUpFormSelectFrom)}
            label="Select balance to debit"
            placeholder="All currencies"
            placeholderText="Select currency to debit your balance"
            currencies={currencies}
            onSelect={handleCurrencyFromSelect(field.onChange)}
            error={fromCurrencyErrorMessage}
            defaultTicker={defaultTicker}
          />
        )}
      />
      <Controller
        name="toCurrency"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <CurrenciesSelectField
            className={cn(classes.balanceTopUpFormSelect, classes.balanceTopUpFormSelectTo)}
            label="Pay currency"
            placeholder="All currencies"
            placeholderText="Client can choose any supported crypto for payment"
            currencies={currencies}
            onSelect={handleCurrencyToSelect(field.onChange)}
            error={payCurrencyErrorMessage || toCurrencyErrorMessage}
          />
        )}
      />
      {isAmountVisible && (
        <Controller
          name="amount"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Field
              className={classes.balanceTopUpFormInput}
              title="Amount to debit"
              data={{
                id: field.name,
                value: field.value,
                onChange: handleAmountChange(field.onChange),
                name: field.name,
                ticker: baseCurrencyTickerUpperCased,
                placeholder: '0.00',
                error: amountErrorMessage || errorAmountMessage,
                pattern: /^\d*[.]?\d{0,8}$/,
                autoComplete: 'off',
              }}
            />
          )}
        />
      )}
      <div className={classes.balanceTopUpFormDescription}>
        {estimateFetching && !isPDA && (
          <LoaderIcon size={20} />
        )}
        {minAmountTextShow && (
          <div className={classes.balanceTopUpFormDescriptionItem}>
            Current
            {' '}
            <span className={classes.balanceTopUpFormBlueColor}>
              minimum payment amount
            </span>
            {' '}
            for
            {' '}
            {toCurrencyTickerUpperCased}
            {' '}
            is
            {' '}
            {minAmount}
            {' '}
            {baseCurrencyTickerUpperCased}
          </div>
        )}
        {estimatedTextShow && (
          <div className={classes.balanceTopUpFormDescriptionItem}>
            You will receive a transfer of
            {' '}
            <span className={classes.balanceTopUpFormBlueColor}>
              {estimated}
              {' '}
              {fromCurrencyTickerUpperCased}
            </span>
            {' '}
            to your balance
          </div>
        )}
      </div>
      <div className={classes.balanceTopUpFormError}>
        {errorApiMessage}
      </div>
      <Button
        type="submit"
        className={classes.balanceTopUpFormButton}
        disabled={isLoading}
      >
        {isLoading
          ? <LoaderIcon path="/images/loader-white-on-blue-icon.gif" />
          : 'Continue'}
      </Button>
    </form>
  );
};

BalanceTopUpForm.defaultProps = {
  className: null,
  currencies: null,
  isLoading: false,
  estimateFetching: false,
  onSubmit: null,
  baseCurrencyTicker: null,
  errorApiMessage: null,
  minAmount: null,
  errorAmountMessage: null,
  estimated: null,
  onAmountChange: null,
  onCurrencyFrom: null,
  onCurrencyTo: null,
  payCurrencyErrorMessage: null,
  defaultTicker: null,
  onDepositActivate: null,
  depositError: null,
  resetAddressError: null,
};

BalanceTopUpForm.propTypes = {
  className: PropTypes.string,
  currencies: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  estimateFetching: PropTypes.bool,
  onSubmit: PropTypes.func,
  baseCurrencyTicker: PropTypes.string,
  errorApiMessage: PropTypes.string,
  minAmount: PropTypes.number,
  errorAmountMessage: PropTypes.string,
  estimated: PropTypes.number,
  onAmountChange: PropTypes.func,
  onCurrencyFrom: PropTypes.func,
  onCurrencyTo: PropTypes.func,
  payCurrencyErrorMessage: PropTypes.string,
  defaultTicker: PropTypes.string,
  onDepositActivate: PropTypes.func,
  depositError: PropTypes.bool,
  resetAddressError: PropTypes.func,
};
