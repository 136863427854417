import React from 'react';
import { Section } from '../section';
import { useApiKeys } from '../../api/modules/account/use-api-keys';
import { useUpdatePosTerminalLink } from '../../api/modules/account/use-update-pos-terminal-link';
import { usePosTerminalData } from '../../api/modules/account/use-pos-terminal-data';
import { hasElementsInArray } from '../../helpers/has-elements-in-array';
import { SimpleSelectField } from '../ui/simple-select-field';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import config from '../../config';
import { CopyButton } from '../ui/copy-button';
import copyToClipboard from '../../helpers/copy-to-clipboard';
import WarningBlock from '../warning-block';
import classes from './styles.module.scss';

const POS_TERMINAL_THEME = [{
  key: 'light',
  value: 'light',
}, {
  key: 'dark',
  value: 'dark',
}];
let timerIdCopy = null;
const TIMEOUT = 3000;
const POS_TERMINAL_PATH = `${config.front_domain}/pos-terminal/`;

export const PosTerminalPage = () => {
  const fetchApiKeys = useApiKeys();
  const fetchPosTerminalData = usePosTerminalData();
  const fetchUpdatePosTerminalLink = useUpdatePosTerminalLink();

  const [isFetching, setIsFetching] = React.useState(false);
  const [isFetched, setIsFetched] = React.useState(false);
  const [apiKeys, setApiKeys] = React.useState([]);
  const [name, setName] = React.useState('');
  const [theme, setTheme] = React.useState(null);
  const [apiKey, setApiKey] = React.useState(null);
  const [errorText, setErrorText] = React.useState(null);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isCopied, setIsCopied] = React.useState(false);

  const handleCopyButtonClick = () => {
    const text = `${POS_TERMINAL_PATH}${name}`;
    const isCopyToClipboard = copyToClipboard(text);

    if (!isCopyToClipboard) {
      return;
    }

    setIsCopied(true);
    clearTimeout(timerIdCopy);
    timerIdCopy = setTimeout(() => {
      setIsCopied(false);
    }, TIMEOUT);
  };
  const updatePosTerminalData = async (nextApiKey = null, nextTheme = null) => {
    if (!name) {
      setErrorText('Pos terminal link must not be empty');

      return;
    }

    const currentApiKey = nextApiKey || apiKey;
    const currentTheme = nextTheme || theme;

    const { data, status } = await fetchUpdatePosTerminalLink({
      apiKey: currentApiKey,
      link: name,
      theme: currentTheme,
    });

    if (status === 200 && data?.status === 'ok') {
      setIsSaving(true);
    } else {
      setErrorText(data?.errorData?.message || data?.errorData?.error);
    }
  };
  const handleInputChange = (value) => {
    setName(value);
  };
  const handleInputBlur = () => {
    updatePosTerminalData();
  };
  const handleApiKeySelectChange = (value) => {
    setApiKey(value);
    updatePosTerminalData(value);
  };
  const handleThemeSelectChange = (value) => {
    setTheme(value);
    updatePosTerminalData(null, value);
  };
  const getApiKeys = async () => {
    let keys = [];
    const { data, status } = await fetchApiKeys();

    if (status === 200) {
      keys = data?.keys ?? [];
    }

    return keys;
  };
  const getPosTerminalData = async () => {
    let posTerminalData = null;
    const { data, status } = await fetchPosTerminalData();

    if (status === 200) {
      posTerminalData = data?.link ?? null;
    }

    return posTerminalData;
  };
  const fetchData = async () => {
    setIsFetching(true);
    const keys = await getApiKeys();
    const posTerminalData = await getPosTerminalData();
    const posTerminalName = posTerminalData?.name ?? '';
    const posTerminalTheme = posTerminalData?.theme ?? null;
    const posTerminalApiKey = posTerminalData?.apiKey ?? null;

    setApiKeys(keys);
    setName(posTerminalName);
    setTheme(posTerminalTheme);
    setApiKey(posTerminalApiKey);
    setIsFetching(false);
    setIsFetched(true);
  };

  const apiKeysForSelectNormalized = React.useMemo(() => {
    const hasApiKeys = hasElementsInArray(apiKeys);
    let result = [];

    if (hasApiKeys) {
      result = apiKeys.map((item) => ({ key: item?.apiKey, value: item?.apiKey }));
    }

    return result;
  }, [apiKeys]);

  React.useEffect(() => {
    const hasApiKeys = hasElementsInArray(apiKeys);

    if (!apiKey && hasApiKeys) {
      const key = apiKeys[0]?.apiKey ?? null;

      setApiKey(key);
    }
  }, [apiKey, apiKeys]);

  React.useEffect(() => {
    let timerIdError = null;
    let timerIdSaved = null;

    if (isSaving) {
      timerIdSaved = setTimeout(() => {
        setIsSaving(false);
      }, TIMEOUT);
    }

    if (errorText) {
      timerIdError = setTimeout(() => {
        setErrorText(null);
      }, TIMEOUT);
    }

    return () => {
      if (timerIdSaved) {
        clearTimeout(timerIdSaved);
      }
      if (timerIdError) {
        clearTimeout(timerIdError);
      }
    };
  }, [
    errorText,
    isSaving,
  ]);

  React.useEffect(() => {
    fetchData();
  }, []);

  const hasApiKeys = hasElementsInArray(apiKeys);

  return (
    <div className={classes.posTerminalPage}>
      <h1>POS Terminal link</h1>
      <Section title="How to accept crypto payments offline">
        <ol className={classes.posTerminalList}>
          <li className={classes.posTerminalListItem}>
            Open the link on your phone
          </li>
          <li className={classes.posTerminalListItem}>
            Enter payment amount
          </li>
          <li className={classes.posTerminalListItem}>
            Customer scans QR with crypto wallet
          </li>
          <li className={classes.posTerminalListItem}>
            Customer sends funds
          </li>
          <li className={classes.posTerminalListItem}>
            When received - see success sign &#x1F389;
          </li>
        </ol>
        <div className={classes.posTerminalCreateBox}>
          <h2 className={classes.posTerminalTitleH2}>
            Create your Point Of Sale terminal
          </h2>
          {!hasApiKeys && isFetched && (
            <WarningBlock apiKeys={apiKeys} />
          )}
          <div className={classes.posTerminalSelects}>
            <SimpleSelectField
              className={classes.posTerminalSelectApiKeys}
              title="Api Key"
              fetching={isFetching}
              data={{
                id: "api-key-select",
                items: apiKeysForSelectNormalized,
                onSelect: handleApiKeySelectChange,
                defaultValue: apiKey,
              }}
            />
            <SimpleSelectField
              className={classes.posTerminalSelectThemes}
              title="Theme"
              fetching={isFetching}
              data={{
                id: "theme-select",
                items: POS_TERMINAL_THEME,
                onSelect: handleThemeSelectChange,
                defaultValue: theme,
              }}
            />
          </div>
          <div className={classes.posTerminalLink}>
            <div className={classes.posTerminalLinkText}>
              nowpayments.io/pos-terminal/
            </div>
            <div className={classes.posTerminalInputBox}>
              <Input
                className={classes.posTerminalInput}
                type="text"
                value={name}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                pattern={/^[a-z0-9]{0,18}$/}
                error={Boolean(errorText)}
              />
              <CopyButton
                className={classes.posTerminalButtonCopy}
                onClick={handleCopyButtonClick}
                isCopied={isCopied}
                copyIconPath="/images/copy-blue-icon.svg"
              />
            </div>
            {isSaving && (
              <div className={classes.posTerminalSuccess}>
                <img
                  src="/images/arrow-blue-icon.svg"
                  alt="Icon"
                  decoding="async"
                />
                Changes saved!
              </div>
            )}
            {errorText && (
              <div className={classes.posTerminalError}>
                {errorText}
              </div>
            )}
          </div>
          <Button
            className={classes.posTerminalButton}
            onClick={handleCopyButtonClick}
            disabled={isCopied}
          >
            Copy link POS terminal
          </Button>
        </div>
      </Section>
    </div>
  );
};
