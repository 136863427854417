import React from "react";
import { LoaderIcon } from '../../components/icons/loader-icon';
import styles from "./styles.module.scss";

export default function LoadingPage() {
  return (
    <div className={styles.loading}>
      <LoaderIcon size={50} />
    </div>
  );
}
