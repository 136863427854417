import axios from 'axios';

const FORM_URL = 'https://docs.google.com/forms/u/0/d/e/1FAIpQLScMbiGVHREaL88G3RoZf7iTFEClPGFsq7l4PMBummjm1O44Ug/formResponse';
const CORS_PROXY = 'https://api.allorigins.win/get?url=';
const EMAIL_ID = 'entry.537636904';
const UTMS_ID = 'entry.169527614';
/**
 * @param {String} userEmail
 * @param {String} utmMetrics
 */
export const sendGoogleAuthWithUtmMetrics = async (userEmail, utmMetrics) => {
  const isUserEmail = typeof userEmail === 'string' && userEmail;
  const currentEmail = isUserEmail ? userEmail : '';
  const isUtmMetrics = typeof utmMetrics === 'string' && utmMetrics;
  const currentUtmMetrics = isUtmMetrics ? utmMetrics : '';

  const emailData = `${EMAIL_ID}=${currentEmail}`;
  const metricsData = `${UTMS_ID}=${currentUtmMetrics}`;
  const actionUrlWithParams = `${FORM_URL}?${emailData}&${metricsData}&submit=Submit`;

  try {
    await axios({
      method: 'get',
      url: CORS_PROXY + encodeURIComponent(actionUrlWithParams),
    });
  } catch (error) {
    console.log(error);
  }
};
