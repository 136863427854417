import React from 'react';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';

const mapFn = (data: { ipnTimeout: string, ipnNumRecurrent: string }) => ({
  ipnTimeout: data?.ipnTimeout ?? null,
  ipnNumRecurrent: data?.ipnNumRecurrent ?? null,
});

export const useUpdateIpnRecurrentNotification = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (ipnNumRecurrent: string, ipnTimeout: string) => {
    const [cancelToken] = getRequestSource();
    const requestPromise = client.put(
      '/instant-payment-notifications/recurrent-notifications',
      { ipnNumRecurrent, ipnTimeout },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
