import React from 'react';
import cn from 'classnames';
import { isFunction } from '../../../../../helpers/is-function';
import { ListItemProps } from '../../types';
import classes from './style.module.scss';

export const ListItem: React.FC<ListItemProps> = (props) => {
  const {
    className,
    name,
    logoUrl,
    onClick,
    isSmallTheme,
  } = props;

  const handleClick = () => {
    if (isFunction(onClick)) {
      onClick();
    }
  };
  const handleKeyPress = () => {
    if (isFunction(onClick)) {
      onClick();
    }
  };

  return (
    <li
      className={cn([
        classes.listItem,
        className,
        isSmallTheme && classes.listItemSmall,
      ])}
      onClick={handleClick}
      onKeyDown={handleKeyPress}
      tabIndex={0}
      role="button" // eslint-disable-line jsx-a11y/no-noninteractive-element-to-interactive-role
    >
      {logoUrl && (
        <img
          className={classes.listItemImg}
          src={logoUrl}
          alt={name}
          width={20}
          height={20}
          decoding="async"
          loading="lazy"
        />
      )}
      {name && (
        <div className={classes.listItemName}>
          {name}
        </div>
      )}
    </li>
  );
};
