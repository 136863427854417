import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useFetchPartnerProfile } from '../../api/modules/account/use-fetch-partner-profile';
import { LoaderIcon } from '../icons/loader-icon';
import { useAllCurrencies } from '../../hooks/use-all-currencies';
import { ButtonBack } from '../shared/button-back';
import { ROUTES } from '../../constants/routes';
import { useMediaQuery } from '../../hooks/use-media-query';
import {
  partnerFetchedSelector,
  partnerFetchingSelector,
} from '../../store/partner/selectors';
import { usePartnerCustodyCheck } from '../../hooks/use-partner-custody-check';
import { useHistoryReplace } from '../../hooks/use-history-replace';
import ToolTip from '../tool-tip';
import { useBalancesData } from '../../hooks/use-balances-data';
import { isNeedStatusUpdate } from '../../helpers/is-need-status-update';
import { currencyListSelector } from '../../store/currencies/selectors';
import { baseCurrencySelector } from '../../store/payment-settings/selectors';
import { useCreateSubscriptionPlan } from '../../hooks/use-create-subscription-plan';
import { setAllPlansFetched } from '../../store/customer-management/reducer';
import { useAddSubPartnerToSubscriptionPlans } from '../../hooks/use-add-sub-partner-to-subscription-plans';
import { useCustomerSubscriptions } from '../../hooks/use-customer-subscriptions';
import { useUnsubscribeUser } from '../../hooks/use-unsubscribe-user';
import { useCurrenciesToObject } from '../../hooks/use-currencies-to-object';
import { safeToUpperCase } from '../../helpers/safe-to-upper-case';
import {
  useCustomerPageInitState,
  useCustomerPageBalancesState,
  useCustomerPageActionsState,
  useTransactionOrPaymentInfo,
  useTransactionsAndPaymentsHistoryState,
} from './hooks';
import { CustomerInfo } from './components/customer-info';
import { PopupCustomerBalanceInfo } from './components/popup-customer-balance-info';
import { CustomerHistoryTable } from './components/customer-history-table';
import { CustomerFullFilter } from './components/customer-full-filter';
import { CustomerButtons } from './components/customer-buttons';
import { PopupCustomerForms } from './components/popup-customer-forms';
import { PopupInfo } from './components/popup-info';
import { PopupObservablePaymentResult } from './components/popup-observable-payment-result';
import { useObservablePaymentState } from './hooks/use-observable-payment-state';
import { PopupUnsubscribe } from './components/popup-unsubscribe';
import classes from './customer-page-styles.module.scss';

export const CustomerPageContext = React.createContext(null);

export const CustomerPage = () => {
  const isTablet = useMediaQuery('(min-width: 768px)');
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const { customerId } = params;
  const partnerFetching = useSelector(partnerFetchingSelector());
  const partnerFetched = useSelector(partnerFetchedSelector());
  const currencyList = useSelector(currencyListSelector());
  const baseCurrency = useSelector(baseCurrencySelector());
  const { currenciesObject } = useCurrenciesToObject(currencyList);
  const fullBaseCurrency = currenciesObject[safeToUpperCase(baseCurrency)];
  const { reFetchCustomerSubscriptions } = useCustomerSubscriptions(customerId);

  const {
    isLoading,
    isLoaded,
    isCustomerNotFound,
    subPartner,
    partnerBalances,
  } = useCustomerPageInitState(customerId, reFetchCustomerSubscriptions);

  const {
    isBalancesFetching,
    reFetchBalances,
    subPartnerFullData,
    balancesInfoItems,
    subPartnerBalances,
    isBalancesPopupShow,
    handleBalancesPopupToggle,
    hasBalances,
  } = useCustomerPageBalancesState(customerId, isLoaded, subPartner, currenciesObject);

  const {
    isHistoryPending,
    historyItems,
    historyItemsCount,
    historyApiErrorMessage,
    reFetchTransactions,
    reFetchPayments,
    activeHistoryKey,
    setActiveHistoryKey,
    isTransactionsHistoryActive,
    isPaymentsHistoryActive,
    searchText,
    handleSearchChange,
    filterData,
    page,
    setPage,
    numberOfActiveFilters,
    handleFilterReset,
    handleFilterSubmit,
  } = useTransactionsAndPaymentsHistoryState(customerId, isLoaded);

  const {
    isInfoPopupShow,
    infoItems,
    infoId,
    setDataForInfo,
    handleInfoPopupClose,
    infoTitle,
  } = useTransactionOrPaymentInfo(isTransactionsHistoryActive, currenciesObject);

  const {
    isFetching,
    isPopupActionShow,
    activeFormName,
    handlePopupActionOpen,
    handlePopupActionClose,
    handleActionSubmit,
    actionsApiError,
    handleActiveFormNameChange,
  } = useCustomerPageActionsState({
    customerId,
    reFetchBalances,
    reFetchTransactions,
    reFetchPayments,
  });

  const {
    observablePaymentShow,
    observablePaymentData,
    handlePaymentInfoOpen,
    handlePaymentInfoClose,
  } = useObservablePaymentState();

  const handleCreateSubscriptionPlanCallback = () => {
    dispatch(setAllPlansFetched(false));
    handlePopupActionClose();
  };

  const {
    isFetching: isBillingPlanCreating,
    apiError: createBillingPlanApiError,
    handleSubmit: createBillingPlanSubmit,
  } = useCreateSubscriptionPlan({ callback: handleCreateSubscriptionPlanCallback });

  const {
    isSent: addSubPartnerToSubscriptionPlanSaved,
    isFetching: addSubPartnerToSubscriptionPlanFetching,
    apiError: addSubPartnerToSubscriptionPlanError,
    handleSubmit: addSubPartnerToSubscriptionPlan,
  } = useAddSubPartnerToSubscriptionPlans({ subPartnerId: customerId, callback: reFetchCustomerSubscriptions });

  const {
    hasSubscribeId,
    pending: unsubscribeCustomerPending,
    apiError: unsubscribeCustomerApiError,
    handleUnsubscribe,
    handleSubscribeIdAdd,
    handleSubscribeIdRemove,
  } = useUnsubscribeUser({ callback: reFetchCustomerSubscriptions });

  const handleBackClick = () => {
    history.push(ROUTES.CUSTOMERS);
  };
  const handleFormSubmit = (data) => {
    void handleActionSubmit(data, handlePaymentInfoOpen);
  };

  const handleHistoryRowClick = React.useCallback((payment) => {
    if (isPaymentsHistoryActive && isNeedStatusUpdate(payment?.status)) {
      handlePaymentInfoOpen(payment);
    } else {
      setDataForInfo(payment);
    }
  }, [isPaymentsHistoryActive]);

  useFetchPartnerProfile();
  useAllCurrencies();
  useHistoryReplace(ROUTES.CUSTOMERS, isCustomerNotFound);
  usePartnerCustodyCheck(ROUTES.CUSTOMERS, true);
  useBalancesData();

  const isLoaderShow = partnerFetching || !partnerFetched || isLoading || !isLoaded;
  const hasSearchData = Boolean(searchText) || Boolean(numberOfActiveFilters);
  const customerFormOptions = {
    open: isPopupActionShow,
    onClose: handlePopupActionClose,
    partnerBalances: partnerBalances,
    subPartnerBalances: subPartnerBalances,
    fullBaseCurrency: fullBaseCurrency,
    activeFormName: activeFormName,
    onDepositOrWithdraw: handleFormSubmit,
    depositOrWithdrawFetching: isFetching,
    depositOrWithdrawApiError: actionsApiError,
    onActiveFormNameChange: handleActiveFormNameChange,
    isBillingPlanCreating: isBillingPlanCreating,
    createBillingPlanApiError: createBillingPlanApiError,
    onBillingPlanSubmit: createBillingPlanSubmit,
    onSaveFormSubmit: addSubPartnerToSubscriptionPlan,
    saveFormFetching: addSubPartnerToSubscriptionPlanFetching,
    saveFormApiError: addSubPartnerToSubscriptionPlanError,
    saveFormSaved: addSubPartnerToSubscriptionPlanSaved,
    onUnsubscribe: handleSubscribeIdAdd,
    currenciesObject,
  };

  if (isLoaderShow) {
    return (
      <div className={classes.customerPageLoader}>
        <LoaderIcon size={40} />
      </div>
    );
  }

  return (
    <div className={classes.customerPage}>
      <div className={classes.customerPageHeader}>
        <ButtonBack
          className={classes.customerPageHeaderBack}
          onClick={handleBackClick}
        />
        {subPartner?.name}
      </div>
      <CustomerButtons
        className={classes.customerPageButtons}
        onClick={handlePopupActionOpen}
        hasBalances={hasBalances}
      />
      <CustomerInfo
        className={classes.customerPageInfo}
        data={subPartnerFullData}
        landscape={isTablet}
        balanceInfoElement={(
          <>
            <img
              className={classes.customerPageInfoIcon}
              onClick={handleBalancesPopupToggle}
              width={14}
              height={14}
              src="/images/info-icon.svg"
              alt="Info Balance"
              decoding="async"
              loading="lazy"
              data-tooltip-id="customer-tooltip"
              data-tooltip-html="This is an estimated balance of your customer in USD. <br /> Click on the info button to see full balances"
            />
            <ToolTip id="customer-tooltip" />
          </>
        )}
        loaderElement={isBalancesFetching && (
          <LoaderIcon size={15} className={classes.customerPageInfoLoader} />
        )}
      />
      <CustomerFullFilter
        searchValue={searchText}
        onChangeSearch={handleSearchChange}
        activeFilterItemsNumber={numberOfActiveFilters}
        onSubmit={handleFilterSubmit}
        onFilterReset={handleFilterReset}
        transactionHistoryActive={isTransactionsHistoryActive}
        defaultData={filterData}
      />
      <CustomerHistoryTable
        items={historyItems}
        hasSearchData={hasSearchData}
        fetching={isHistoryPending}
        onClick={handleHistoryRowClick}
        count={historyItemsCount}
        onPaginatorClick={setPage}
        page={page}
        apiError={historyApiErrorMessage}
        activeHistoryKey={activeHistoryKey}
        onTabCLick={setActiveHistoryKey}
        currenciesObject={currenciesObject}
      />
      <PopupCustomerBalanceInfo
        open={isBalancesPopupShow}
        onClose={handleBalancesPopupToggle}
        items={balancesInfoItems}
        onUpdate={reFetchBalances}
        fetching={isBalancesFetching}
      />
      <PopupInfo
        open={isInfoPopupShow}
        onClose={handleInfoPopupClose}
        items={infoItems}
        id={infoId}
        title={infoTitle}
      />
      <CustomerPageContext.Provider value={customerFormOptions}>
        <PopupCustomerForms />
      </CustomerPageContext.Provider>
      <PopupObservablePaymentResult
        open={observablePaymentShow}
        onClose={handlePaymentInfoClose}
        data={observablePaymentData}
        currenciesObject={currenciesObject}
      />
      <PopupUnsubscribe
        open={hasSubscribeId}
        onClose={handleSubscribeIdRemove}
        name={subPartner?.name}
        onUnsubscribe={handleUnsubscribe}
        pending={unsubscribeCustomerPending}
        apiError={unsubscribeCustomerApiError}
      />
    </div>
  );
};
