import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  isPartnerCustodySelector,
  partnerFetchedSelector,
  partnerFetchingSelector,
} from '../../store/partner/selectors';
import { ROUTES } from '../../constants/routes';
import { usePartnerCustodyCheck } from '../../hooks/use-partner-custody-check';
import { PopupCreateSubscriptionPlan } from '../popup-create-subscription-plan';
import { baseCurrencySelector } from '../../store/payment-settings/selectors';
import { currencyListSelector } from '../../store/currencies/selectors';
import { useBaseCurrencyData } from '../../hooks/use-base-currency-data';
import { useCreateSubscriptionPlan } from '../../hooks/use-create-subscription-plan';
import { setAllPlansFetched } from '../../store/customer-management/reducer';
import { useCurrenciesToObject } from '../../hooks/use-currencies-to-object';
import { safeToUpperCase } from '../../helpers/safe-to-upper-case';
import { BillingPlansTable } from './components/billing-plans-table';
import { useBillingPlansPageInitState } from './hooks';
import { PagesWrapper } from './components/pages-wrapper';
import { BillingPlansFilter } from './components/billing-plans-filter';

export const BillingPlansPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const partnerFetching = useSelector(partnerFetchingSelector());
  const partnerFetched = useSelector(partnerFetchedSelector());
  const isPartnerCustody = useSelector(isPartnerCustodySelector());
  const currencyList = useSelector(currencyListSelector());
  const baseCurrency = useSelector(baseCurrencySelector());
  const { currenciesObject } = useCurrenciesToObject(currencyList);
  const fullBaseCurrency = currenciesObject[safeToUpperCase(baseCurrency)];

  const {
    isFetching,
    plansData,
    plansDataCount,
    apiErrorMessage,
    reFetchPlans,
    searchText,
    handleSearchChange,
    page,
    setPage,
  } = useBillingPlansPageInitState();

  const handleCreateSubscriptionPlanCallback = () => {
    dispatch(setAllPlansFetched(false));
  };

  const {
    isPopupShow: isPopupPlanShow,
    isFetching: isPlanCreating,
    apiError: createPlanApiError,
    handlePopupOpen: handlePopupPlanOpen,
    handlePopupClose: handlePopupPlanClose,
    handleSubmit: createPlanSubmit,
  } = useCreateSubscriptionPlan({
    reFetch: reFetchPlans,
    callback: handleCreateSubscriptionPlanCallback,
  });

  const handlePlansRowClick = React.useCallback((id) => {
    history.push(`${ROUTES.CUSTOMERS_BILLING_PLANS}/${id}`);
  }, []);

  usePartnerCustodyCheck(ROUTES.CUSTOMERS, true);
  useBaseCurrencyData();

  const isContentShow = !partnerFetching && partnerFetched && isPartnerCustody;
  const isDataLoading = partnerFetching || isFetching;
  const isFilterAllowed = Boolean(plansDataCount) || (Boolean(searchText) && Boolean(!plansDataCount));

  return (
    <PagesWrapper contentShow={isContentShow}>
      <BillingPlansFilter
        onClick={handlePopupPlanOpen}
        onChangeSearch={handleSearchChange}
        isAllowed={isFilterAllowed}
      />
      <BillingPlansTable
        items={plansData}
        hasSearchData={Boolean(searchText)}
        loading={isDataLoading}
        onClick={handlePlansRowClick}
        count={plansDataCount}
        onPaginatorClick={setPage}
        onCreatePlan={handlePopupPlanOpen}
        page={page}
        apiError={apiErrorMessage}
        currenciesObject={currenciesObject}
      />
      <PopupCreateSubscriptionPlan
        title="Create billing plan"
        open={isPopupPlanShow}
        onClose={handlePopupPlanClose}
        fullBaseCurrency={fullBaseCurrency}
        onSubmit={createPlanSubmit}
        errorApiMessage={createPlanApiError}
        pending={isPlanCreating}
      />
    </PagesWrapper>
  );
};
