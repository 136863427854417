import { configureStore } from '@reduxjs/toolkit';
import { reducers } from './reducers';

const IS_CLIENT = typeof window === 'object';
const isDevTools = IS_CLIENT && window.location.host !== 'account.nowpayments.io';

export const store = configureStore({
  devTools: isDevTools,
  reducer: reducers,
});
