import axios from "axios";
import config from "../config";
import { getAuthToken } from "../libs/local-storage";

const token = getAuthToken();

const apiClient = axios.create({
  baseURL: config.oauth_api_domain,
  headers: {
    Authorization: "bearer " + token,
    'X-Temp-Token': config.X_TEMP_TOKEN,
  },
});

export async function getServiceById(id) {
  const { data } = await apiClient.get(`/services/${id}`);

  return data;
}

export async function getOAuthCode({
  client_id,
  client_secret,
  state,
  response_type,
  redirect_uri,
}) {
  const { data } = await apiClient.post("/authorize", {
    client_id,
    client_secret,
    state,
    response_type,
    redirect_uri,
  });

  return data;
}
