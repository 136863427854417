import React from 'react';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';

export const useUpdateProfile = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (companyName: string, storeDetails: string | null) => {
    const [cancelToken] = getRequestSource();
    const requestPromise = client.post(
      '/profile',
      {
        company_name: companyName,
        store_details: storeDetails,
      },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      (data) => data,
    );
  }, [getRequestSource]);
};
