import React from 'react';
import PropTypes from 'prop-types';
import { safeToUpperCase } from '../../../../helpers/safe-to-upper-case';
import { formatDate } from '../../../../helpers/format-date';
import { isFunction } from '../../../../helpers/is-function';
import { DATE_PATTERN } from '../../../../constants/app-constants';
import { getColorStatus } from '../../../../helpers/get-color-status';
import { toFixed } from '../../../../helpers/to-fixed';
import { getAmountWithTicker } from '../../../../helpers/get-amount-with-ticker';
import { safeToLowerCase } from '../../../../helpers/safe-to-lower-case';
import {
  getTransactionType,
  getTransactionTypeText,
} from '../../../../helpers/get-transaction-type';

export const CustomerHistoryTableRow = (props) => {
  const {
    className,
    data,
    onClick,
    isDesktop,
    currenciesObject = {},
  } = props;

  const id = data?.id || data?.paymentId || '-';
  const status = data?.status ?? null;
  const statusColor = status && getColorStatus(status);
  const statusNormalized = safeToLowerCase(status) || '-';

  const amount = data?.amount ?? null;
  const amountFixed = toFixed(amount, 8);
  const ticker = data?.currency ?? null;
  const tickerUpperCased = safeToUpperCase(ticker);
  const currentTicker = currenciesObject[safeToUpperCase(ticker)]?.ticker ?? tickerUpperCased;
  const amountWithTicker = getAmountWithTicker(amountFixed, currentTicker);

  const payAmount = data?.payAmount ?? null;
  const payAmountFixed = toFixed(payAmount, 8);
  const payTicker = data?.payCurrency ?? null;
  const payTickerUpperCased = safeToUpperCase(payTicker);
  const payCurrentTicker = currenciesObject[payTickerUpperCased]?.ticker ?? payTickerUpperCased;
  const payAmountWithTicker = payAmount ? `${payAmountFixed} ${payCurrentTicker}` : null;
  const currentAmountText = payAmountWithTicker || amountWithTicker;

  const type = data?.type ?? null;
  const typeUpperCased = safeToUpperCase(type);
  const transactionType = getTransactionType(typeUpperCased);
  const transactionTypeText = getTransactionTypeText(typeUpperCased);

  const updatedDateFormatted = formatDate(data?.updatedAt ?? '', DATE_PATTERN);
  const updatedDateNormalized = updatedDateFormatted || '-';

  const handleRowClick = () => {
    if (isFunction(onClick)) {
      onClick(data);
    }
  };

  return (
    <tr
      className={className}
      onClick={handleRowClick}
    >
      <td>
        <div>
          {id}
        </div>
        {!isDesktop && (
          <div
            data-margin="top"
            data-text="capitalize"
            style={{ color: statusColor }}
          >
            {statusNormalized}
          </div>
        )}
      </td>
      {isDesktop && (
        <td style={{ color: statusColor }} data-text="capitalize">
          {statusNormalized}
        </td>
      )}
      <td>
        <div data-color="black">
          {currentAmountText}
        </div>
      </td>
      <td>
        <div>
          <span data-color="black">
            {transactionType}
          </span>
          {' '}
          {transactionTypeText}
        </div>
        {!isDesktop && (
          <div data-margin="top">
            {updatedDateNormalized}
          </div>
        )}
      </td>
      {isDesktop && (
        <td>
          {updatedDateNormalized}
        </td>
      )}
    </tr>
  );
};

CustomerHistoryTableRow.defaultProps = {
  className: null,
  data: null,
  currenciesObject: {},
  onClick: null,
  isDesktop: false,
};

CustomerHistoryTableRow.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  currenciesObject: PropTypes.object,
  onClick: PropTypes.func,
  isDesktop: PropTypes.bool,
};
