import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { CurrenciesDropdownInnerMenu } from '../currencies-dropdown-inner-menu';
import classes from './styles.module.scss';

export const CurrenciesDropdown = (props) => {
  const {
    className,
    Search,
    children,
    hasInnerMenu,
    activeTab,
    onClick,
    isOnRampEnabled,
  } = props;

  return (
    <div className={cn(classes.currenciesDropdown, className)}>
      {Search}
      {hasInnerMenu && (
        <CurrenciesDropdownInnerMenu
          onClick={onClick}
          activeTab={activeTab}
          isOnRampEnabled={isOnRampEnabled}
        />
      )}
      {children && (
        <div className={classes.currenciesDropdownContent}>
          {children}
        </div>
      )}
    </div>
  );
};

CurrenciesDropdown.defaultProps = {
  className: null,
  Search: null,
  children: null,
  hasInnerMenu: false,
  onClick: null,
  activeTab: null,
  isOnRampEnabled: false,
};

CurrenciesDropdown.propTypes = {
  className: PropTypes.string,
  Search: PropTypes.element,
  children: PropTypes.node,
  hasInnerMenu: PropTypes.bool,
  onClick: PropTypes.func,
  activeTab: PropTypes.string,
  isOnRampEnabled: PropTypes.bool,
};
