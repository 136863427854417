import React from 'react';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import apiClient from '../../../api/api-client';
import commonStyles from '../../../css/common.module.scss';
import NewUserModal from './components/new-user-modal';
import EditUserModal from './components/edit-user-modal';
import styles from './styles.module.scss';

const accessData = [
  {
    title: 'Invoice',
    code: 'invoice',
  },
  {
    title: 'Payments',
    code: 'payments',
  },
  {
    title: 'Purchases',
    code: 'purchases',
  },
  {
    title: 'API Keys',
    code: 'api_keys',
  },
  {
    title: 'Balances',
    code: 'balances',
  },
  {
    title: 'Profile',
    code: 'profile',
  },
  {
    title: 'Payout Wallets',
    code: 'outcome_wallets',
  },
  {
    title: 'Donation Link',
    code: 'donation_link',
  },
  {
    title: 'Fiat Withdrawals',
    code: 'crypto_to_fiat',
  },
  {
    title: 'Coins Settings',
    code: 'coins',
  },
  {
    title: 'Referral Program',
    code: 'referral_program',
  },
  {
    title: 'Team Roles',
    code: 'team',
  },
  {
    title: 'Conversions',
    code: 'conversions',
  },
];

const MyTeam = () => {
  const [team, setTeam] = React.useState([]);
  const [isOpenNewUserModal, setIsOpenNewUserModal] = React.useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = React.useState(false);
  const [activeTeamData, setActiveTeamData] = React.useState();

  const handleAddTeam = () => {
    setIsOpenNewUserModal(true);
  };

  const handleEditClick = (data) => {
    setActiveTeamData(data);
    setIsOpenEditModal(true);
  };

  const handleChangeTeamAccessById = (access, id) => {
    setTeam((prevState) =>
      prevState.map((item) => (item.id === id ? access : item)),
    );
  };

  React.useEffect(() => {
    async function fetchTeam() {
      const team = await apiClient.getTeam();

      setTeam(team);
    }
    fetchTeam();
  }, []);

  return (
    <>
      <h2>My Team</h2>
      <div className={`${styles.wrapper} ${commonStyles.panel}`}>
        <table className={styles.table}>
          <thead className={styles.thead}>
            <tr className={styles.tr}>
              <td>Email</td>
              <td>Role</td>
              <td>Is Active</td>
              <td>Access</td>
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            {team.map((item) => (
              <tr key={item.id}>
                <td>{item['Slave.email']}</td>
                <td>{item.role}</td>
                <td>
                  {item.is_active ? (
                    <FontAwesomeIcon icon={faCheck} size="1x" color="#1BDC66" />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} size="1x" color="#FF7DA0" />
                  )}
                </td>
                <td>
                  <button onClick={() => handleEditClick(item)}>Edit</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button className={styles.addBtn} onClick={handleAddTeam}>
          Add new user
        </button>
        <NewUserModal
          isOpen={isOpenNewUserModal}
          onClose={() => setIsOpenNewUserModal(false)}
          accessData={accessData}
          setTeam={setTeam}
        />
        <EditUserModal
          isOpen={isOpenEditModal}
          onClose={() => setIsOpenEditModal(false)}
          accessData={accessData}
          activeTeamData={activeTeamData}
          setTeam={setTeam}
          onChangeTeamAccessById={handleChangeTeamAccessById}
        />
      </div>
    </>
  );
};

export default MyTeam;
