import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import {
  isPartnerCustodySelector,
  partnerFetchedSelector,
} from '../../store/partner/selectors';
import { SettingTableWrapper } from '../setting-table-wrapper';
import { ForActivateCustody } from './components/for-activate-custody';
import { WhitelistActivation } from './components/whitelist-activation';
import { WhitelistTable } from './components/whitelist-table';
import { useInitState } from './hooks';
import { WhitelistPopup } from './components/whitelist-popup';
import { New2faPopup } from '../new-2fa-popup';
import { useNormalizedDataWithSearch } from './hooks/use-normalized-data-with-search';
import { useActions } from './hooks/use-actions';
import classes from './styles.module.scss';

export const WhitelistingSettings: React.FC<{ className: string | null }> = (props) => {
  const { className = null } = props;

  const isPartnerCustody = useSelector(isPartnerCustodySelector());
  const partnerFetched = useSelector(partnerFetchedSelector());

  const {
    ips,
    isWhitelistActive,
    isWhitelistFetching,
    isWhitelistFetched,
    whitelistError,
    reFetchWhitelistIps,
  } = useInitState();

  const {
    currentIps,
    ipsFilteredLength,
    handleSearchChange,
    handlePageChange,
    page,
    searchValue,
  } = useNormalizedDataWithSearch(ips);

  const {
    popupType,
    fetching,
    apiError,
    is2faShow,
    ipAddressForDelete,
    handlePopupOpen,
    handlePopupClose,
    handle2faClose,
    handleToAddIpAddress,
    handleIpAddressDelete,
    handleButtonConfirmClick,
    handleTooltipDeleteClick,
    handleAddAddressPopupOpen,
  } = useActions(ips, reFetchWhitelistIps);

  return (
    <div className={cn(classes.whitelistingSettings, className)}>
      <h2 className={classes.titleH2}>
        IP addresses Whitelisting
        {partnerFetched && !isPartnerCustody && ' for Custody'}
      </h2>
      {partnerFetched && !isPartnerCustody && (
        <ForActivateCustody />
      )}
      {partnerFetched && isPartnerCustody && (
        <>
          <WhitelistActivation
            isActive={isWhitelistActive}
            fetching={isWhitelistFetching}
            onPopupOpen={handlePopupOpen}
          />
          <SettingTableWrapper
            onChange={handleSearchChange}
            onClick={handleAddAddressPopupOpen}
            placeholder="Search IP address"
            buttonLabel="Add an IP address to Whitelist"
            isNotActivated={false}
          >
            <WhitelistTable
              ips={currentIps}
              fetching={isWhitelistFetching}
              fetched={isWhitelistFetched}
              error={whitelistError}
              onClick={handleTooltipDeleteClick}
              page={page}
              onPage={handlePageChange}
              ipsFilteredLength={ipsFilteredLength}
              hasSearchValue={Boolean(searchValue)}
            />
          </SettingTableWrapper>
          <WhitelistPopup
            open={Boolean(popupType)}
            onClose={handlePopupClose}
            type={popupType}
            onButtonConfirm={handleButtonConfirmClick}
            fetching={fetching}
            apiError={apiError}
            onDelete={handleIpAddressDelete}
            ipAddressForDelete={ipAddressForDelete}
          />
          <New2faPopup
            open={is2faShow}
            onClose={handle2faClose}
            onUpdate={handleToAddIpAddress}
            is2faCode
            pending={fetching}
            apiErrorMessage={apiError}
          />
        </>
      )}
    </div>
  );
};
