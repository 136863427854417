import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from '../../../popup';
import { BalanceTopUpForm } from '../../../balance-top-up-form';
import { DepositAddressTopUp } from '../../../deposit-address-top-up';

export const TopUpBalancePopup = (props) => {
  const {
    className,
    open,
    onClose,
    formData,
    depositAddressData,
  } = props;

  const {
    currencies,
    onSubmit,
    baseCurrency,
    defaultTicker,
    payCurrencyErrorMessage,
    minAmount,
    errorAmountMessage,
    estimate,
    onAmountChange,
    onCurrencyFrom,
    onCurrencyTo,
    estimateFetching,
    fetching,
    errorApiMessage,
  } = formData;

  const {
    isDeposit,
    address,
    depositCurrency,
    setDepositCurrency,
    depositAddressesFetching,
    isAddressFetching,
    depositError,
    resetAddressError,
  } = depositAddressData;

  return (
    <Popup
      classNameContent={className}
      title="Top-up your balance"
      open={open}
      onClose={onClose}
      mobileFullView
      withCloseButton
      line
    >
      {isDeposit ? (
        <DepositAddressTopUp
          address={address}
          baseCurrency={baseCurrency}
          currency={depositCurrency}
          minAmount={minAmount}
          isFetching={isAddressFetching}
          error={depositError}
        />
      ) : (
        <BalanceTopUpForm
          currencies={currencies}
          onSubmit={onSubmit}
          baseCurrencyTicker={baseCurrency}
          errorApiMessage={errorApiMessage}
          payCurrencyErrorMessage={payCurrencyErrorMessage}
          minAmount={minAmount}
          errorAmountMessage={errorAmountMessage}
          estimated={estimate}
          onAmountChange={onAmountChange}
          onCurrencyFrom={onCurrencyFrom}
          onCurrencyTo={onCurrencyTo}
          estimateFetching={estimateFetching}
          isLoading={fetching || isAddressFetching || depositAddressesFetching}
          defaultTicker={defaultTicker}
          onDepositActivate={setDepositCurrency}
          depositError={depositError}
          resetAddressError={resetAddressError}
        />
      )}
    </Popup>
  );
};

TopUpBalancePopup.defaultProps = {
  className: null,
  open: false,
  onClose: null,
  formData: {},
  depositAddressData: {},
};

TopUpBalancePopup.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  formData: PropTypes.shape({}),
  depositAddressData: PropTypes.shape({}),
};
