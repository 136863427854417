import React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';
import { hasElementsInArray } from '../../../helpers/has-elements-in-array';

export const LAST_NEWS_API_PATH = '/last-news';

const itemMapFn = (data) => ({
  id: data?.id ?? null,
  title: data?.title ?? null,
  link: data?.link ?? null,
  createdAt: data?.created_at ?? null,
  updatedAt: data?.updated_at ?? null,
});
const mapFn = (data) => hasElementsInArray(data) ? data.map(itemMapFn) : [];

export const useLastNews = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async () => {
    const [cancelToken] = getRequestSource();
    const requestPromise = client.get(
      LAST_NEWS_API_PATH,
      null,
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
