import React from 'react';
import { useDispatch } from 'react-redux';
import { POPUP_OBJECT } from '../helpers';
import { setApiKeysFetched } from '../../../store/payment-settings/reducer';
import { getSafeErrorMessageText } from '../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../helpers/console-error-message';
import { useCreateApiKey } from '../../../api/modules/account/use-create-api-key';
import { ApiKey, ResponseType } from '../../../default-types';
import { PopupData } from '../types';

export const useActions = (apiKeys: Array<ApiKey>) => {
  const fetchCreateApiKey = useCreateApiKey();
  const dispatch = useDispatch();

  const ref = React.useRef<HTMLDivElement>(null);

  const [isCreatingApiKey, setIsCreatingApiKey] = React.useState(false);
  const [newApiKey, setNewApiKey] = React.useState<null | string>(null);
  const [newApiKeyData, setNewApiKeyData] = React.useState<null | ApiKey>(null);
  const [popupType, setPopupType] = React.useState<PopupData | null>(null);
  const [selectedApiKey, setSelectedApiKey] = React.useState<ApiKey | null>(null);
  const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout | null>(null);
  const [isNeedGenerateNewKey, setIsNeedGenerateNewKey] = React.useState(false);

  const handlePopupOpen = (type: PopupData) => {
    setPopupType(type);
  };
  const handleAddApiKeyPopupOpen = () => {
    setIsNeedGenerateNewKey(true);
    setPopupType(POPUP_OBJECT.addKey);
  };
  const handleAddWalletPopupOpen = () => {
    setPopupType(POPUP_OBJECT.addWallet);
  };
  const handlePopupClose = () => {
    setPopupType(null);
    setNewApiKeyData(null);
    setNewApiKey(null);
    setIsNeedGenerateNewKey(false);
    ref.current?.click();
    const currentTimeoutId = setTimeout(() => setSelectedApiKey(null), 300);
    setTimeoutId(currentTimeoutId);
  };
  const handleOptionsClick = React.useCallback((apiKey: ApiKey) => {
    setSelectedApiKey(apiKey);
  }, []);
  const resetSelectedApiKey = () => {
    if (!popupType) {
      setSelectedApiKey(null);
    }
  };
  const saveNewApiKey = (generatedApiKey: string) => {
    setNewApiKey(generatedApiKey);
    dispatch(setApiKeysFetched(false));
    setPopupType(POPUP_OBJECT.addKey);
  };
  const getApiKey = React.useCallback(async () => {
    setIsCreatingApiKey(true);
    const {
      data,
      status,
      errorMessage,
    }: ResponseType = await fetchCreateApiKey();

    if (status === 200) {
      setNewApiKey(data?.apiKey);
      dispatch(setApiKeysFetched(false));
    } else {
      const currentErrorMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, currentErrorMessage, '/api-keys/create');
    }
    setIsCreatingApiKey(false);
  }, [fetchCreateApiKey]);

  React.useEffect(() => () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  }, []);

  React.useEffect(() => {
    if (!newApiKey) return;

    const generatedKey = apiKeys.find((data) => data.apiKey === newApiKey);
    if (generatedKey) setNewApiKeyData(generatedKey);
  }, [apiKeys, newApiKey]);

  return {
    handlePopupOpen,
    handleAddApiKeyPopupOpen,
    handleAddWalletPopupOpen,
    handlePopupClose,
    handleOptionsClick,
    resetSelectedApiKey,
    ref,
    popupType,
    selectedApiKey,
    newApiKeyData,
    getApiKey,
    saveNewApiKey,
    isCreatingApiKey,
    isNeedGenerateNewKey,
  };
};
