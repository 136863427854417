export const TABS_OBJECT = {
  currencies: {
    title: 'Currencies',
    iconPath: '/images/payment-details-currencies.svg',
  },
  fees: {
    title: 'Fees',
    iconPath: '/images/payment-details-fees.svg',
  },
  deposits: {
    title: 'Deposits',
    iconPath: '/images/payment-details-deposits.svg',
  },
};

export const TABS = Object.values(TABS_OBJECT);
