import React, { Fragment } from "react";
import SubmitButton from "../../../components/submit-button";
import commonStyles from "../../../css/common.module.scss";
import copyToClipboard from '../../../helpers/copy-to-clipboard';
import styles from "./styles.module.scss";

function ReferralProgram(props) {
  const {
    referralLink,
    copyButtonState,
    onCopyButtonClick,
  } = props;

  const handleCopyButtonClick = () => {
    copyToClipboard(`${referralLink}&utm_source=affiliate_lk&utm_medium=referral`);
    onCopyButtonClick();
  };

  // TODO: use HOC or a separate component instead of commonStyles.panel
  return (
    <Fragment>
      <h2>Referral Program</h2>

      <div className={`${styles.referralProgram} ${commonStyles.panel}`}>
        <div className={styles.fieldContainer}>
          <p>
            Pass this link to users so that we can determine that they were
            invited by you
          </p>

          <input
            className={commonStyles.textField}
            value={referralLink}
            type="text"
            readOnly
          />
        </div>

        <SubmitButton
          successMessage="Referral link copied to clipboard"
          buttonState={copyButtonState}
          onSubmit={handleCopyButtonClick}
        >
          Copy
        </SubmitButton>
      </div>
    </Fragment>
  );
}

export default ReferralProgram;
