import * as React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isFunction } from '../../../../helpers/is-function';
import { SimpleSelectField } from '../../../ui/simple-select-field';
import { DateField } from '../../../ui/date-field';
import { CustomFilter } from '../../../shared/custom-filter';
import { useTimeout } from '../../../../hooks/use-timeout';
import { MASS_PAYOUTS_FILTER_SCHEMA, MASS_PAYOUTS_STATUS_ITEMS } from '../../helpers';
import { CurrenciesSelectField } from '../../../currencies-select-field';
import { currencyListSelector } from '../../../../store/currencies/selectors';
import { useCryptoCurrencies } from '../../../../hooks/use-crypto-currencies';
import { Field } from '../../../ui/field';
import classes from './styles.module.scss';

export const MassPayoutsFilter = (props) => {
  const {
    className,
    onSubmit,
    onResetFormData,
    defaultData,
  } = props;

  const currencyList = useSelector(currencyListSelector());
  const { cryptoCurrencies } = useCryptoCurrencies(currencyList);
  const defaultDateFrom = defaultData?.dateFrom ?? null;
  const defaultDateTo = defaultData?.dateTo ?? null;
  const defaultStatus = defaultData?.status ?? '';
  const defaultCurrency = defaultData?.currency ?? '';
  const defaultPayoutDescription = defaultData?.payoutDescription ?? '';

  const {
    control,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(MASS_PAYOUTS_FILTER_SCHEMA),
  });

  const [createdFrom, setCreatedFrom] = React.useState(defaultDateFrom);
  const [createdTo, setCreatedTo] = React.useState(defaultDateTo);
  const [isReset, setIsReset] = React.useState(false);

  const defaultStatusText = React.useMemo(() => (
    MASS_PAYOUTS_STATUS_ITEMS
      .find((item) => item.key === defaultStatus)?.value ?? ''
  ), [defaultStatus]);

  const handleDateChange = React.useCallback(({ from, to }) => {
    setCreatedFrom(from);
    setCreatedTo(to);
  }, []);
  const handleCurrencySelect = (func) => (currency) => {
    func(currency?.code ?? '');
  };
  const handleFormSubmit = (data) => {
    setIsReset(false);

    if (isFunction(onSubmit)) {
      onSubmit({
        dateFrom: createdFrom,
        dateTo: createdTo,
        ...data,
      });
    }
  };
  const handleResetForm = () => {
    if (!isReset) {
      setIsReset(true);
    }
    setCreatedFrom(null);
    setCreatedTo(null);
    reset();
    if (isFunction(onResetFormData)) {
      onResetFormData();
    }
  };

  useTimeout(() => { setIsReset(false); }, 1000, isReset);

  return (
    <CustomFilter
      className={className}
      onSubmit={handleSubmit(handleFormSubmit)}
      onReset={handleResetForm}
    >
      <DateField
        className={classes.fieldDate}
        title="Date"
        createdFrom={createdFrom}
        createdTo={createdTo}
        onChange={handleDateChange}
      />
      <Controller
        name="status"
        control={control}
        defaultValue={defaultStatus}
        render={({ field }) => (
          <SimpleSelectField
            className={classes.filterInput}
            title="Status"
            data={{
              id: field.name,
              items: MASS_PAYOUTS_STATUS_ITEMS,
              onSelect: field.onChange,
              placeholder: 'Choose',
              reset: isReset,
              defaultValue: defaultStatusText,
            }}
          />
        )}
      />
      <Controller
        name="currency"
        control={control}
        defaultValue={defaultCurrency}
        render={({ field }) => (
          <CurrenciesSelectField
            className={classes.filterInput}
            label="Currency"
            placeholder="Choose"
            currencies={cryptoCurrencies}
            onSelect={handleCurrencySelect(field.onChange)}
            reset={isReset}
            defaultTicker={defaultCurrency}
            smallTheme
          />
        )}
      />
      <Controller
        name="payoutDescription"
        control={control}
        defaultValue={defaultPayoutDescription}
        render={({ field }) => (
          <Field
            className={classes.filterInput}
            title="Payout description"
            data={{
              id: field.name,
              value: field.value,
              onChange: field.onChange,
              name: field.name,
            }}
          />
        )}
      />
    </CustomFilter>
  );
};

MassPayoutsFilter.defaultProps = {
  className: null,
  onSubmit: null,
  onResetFormData: null,
  defaultData: null,
};

MassPayoutsFilter.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  onResetFormData: PropTypes.func,
  defaultData: PropTypes.object,
};
