import { formatDate } from '../../../helpers/format-date';
import { DATE_PATTERN } from '../../../constants/app-constants';
import { toFixed } from '../../../helpers/to-fixed';
import { getAmountWithTicker } from '../../../helpers/get-amount-with-ticker';
import currencyFormat from '../../../libs/currency-format';
import { safeToUpperCase } from '../../../helpers/safe-to-upper-case';
import { hasElementsInArray } from '../../../helpers/has-elements-in-array';
import { getArrayWithIndexId } from '../../../helpers/get-array-with-index-id';
import { getCurrentNetworkForShow } from '../../../helpers/get-current-network-for-show';
import { CUSTOMER_FORM_NAMES } from './constants';

/**
 * @param {Object} data
 * @param {React.element|null} element
 * @returns {Array<object>}
 */
export const getCustomerInfoData = (data, element = null) => {
  const id = data?.id ?? '-';
  const ticker = data?.ticker ?? null;
  const tickerUpperCased = safeToUpperCase(ticker);
  const tickerFormatted = currencyFormat(tickerUpperCased);
  const amount = data?.amount ?? null;
  const amountFixed = toFixed(amount, 2);
  const amountWithTicker = getAmountWithTicker(amountFixed, tickerFormatted);
  const amountWithTickerNormalized = amount ? `~ ${amountWithTicker}` : amountWithTicker;
  const name = data?.name ?? '-';
  const updatedAt = data?.updatedAt ?? null;
  const updatedDateFormatted = formatDate(updatedAt, DATE_PATTERN);
  const updatedDateNormalized = updatedDateFormatted || '-';

  const defaultParams = {
    isTextBlack: false,
    textWithTitle: false,
    element: null,
  };

  return [{
    id: '1',
    text: id,
    ...defaultParams,
  }, {
    id: '2',
    text: amountWithTickerNormalized,
    ...defaultParams,
    element: element,
    isTextBlack: true,
  }, {
    id: '3',
    text: name,
    ...defaultParams,
    isTextBlack: true,
  }, {
    id: '4',
    text: updatedDateNormalized,
    ...defaultParams,
  }];
};

/**
 * @param {Array<object>} balances
 * @param {Object} currenciesObject
 * @returns {[]}
 */
export const getBalancesInfoItems = (balances, currenciesObject = {}) => {
  if (!hasElementsInArray(balances)) {
    return [];
  }

  return balances.map((item, index) => {
    const ticker = item?.ticker ?? null;
    const tickerUpperCased = safeToUpperCase(ticker);
    const currencyFound = currenciesObject[tickerUpperCased] || {};
    const currentTicker = currencyFound?.ticker ?? tickerUpperCased;
    const amount = String(item?.amount ?? 0);
    const network = getCurrentNetworkForShow(currencyFound?.network, ticker);

    return {
      id: String(index),
      key: currentTicker,
      keyNetwork: network,
      value: amount,
      link: null,
      isCopied: false,
    };
  });
};

/**
 * @param {Array<object>} balances
 * @param {object} currenciesObject
 * @returns {Array}
 */
export const getBalancesCurrencies = (balances, currenciesObject = {}) => {
  const hasBalances = hasElementsInArray(balances);

  if (!hasBalances || Object.values(currenciesObject).length <= 0) {
    return [];
  }

  return balances.reduce((acc, item) => {
    const ticker = item?.ticker || null;
    const tickerUpperCased = safeToUpperCase(ticker);
    const foundCurrency = currenciesObject[tickerUpperCased] || null;

    if (foundCurrency) {
      acc.push({
        ...foundCurrency,
        amount: item?.amount ?? null,
      });
    }

    return acc;
  }, []);
};

/**
 * @param {String} activeFormName
 * @param {Array<object>} partnerBalances
 * @param {Array<object>} subPartnerBalances
 * @returns {Object}
 */
export const getCurrentDataForForm = (activeFormName, partnerBalances, subPartnerBalances) => {
  const result = {
    title: '',
    currencies: null,
    defaultTicker: '',
    buttonText: '',
    tabsShow: false,
  };

  switch (activeFormName) {
    case CUSTOMER_FORM_NAMES.DEPOSIT: {
      result.title = 'Deposit to customer account';
      result.currencies = partnerBalances || null;
      result.defaultTicker = partnerBalances?.[0]?.code ?? null;
      result.buttonText = 'Deposit';
      result.tabsShow = true;
      break;
    }
    case CUSTOMER_FORM_NAMES.WITHDRAW: {
      result.title = 'Withdraw from customer account';
      result.currencies = subPartnerBalances || null;
      result.defaultTicker = subPartnerBalances?.[0]?.code ?? null;
      result.buttonText = 'Withdraw';
      break;
    }
    case CUSTOMER_FORM_NAMES.CREATE_BILLING_PLAN:
    case CUSTOMER_FORM_NAMES.SAVE_BILLING_PLAN: {
      result.title = 'Set up a recurring payment';
      break;
    }
    case CUSTOMER_FORM_NAMES.ACTIVE_BILLING_PLAN: {
      result.title = 'Active billing plans';
      break;
    }
    default: {
      console.log(`Form ${activeFormName} not found`);
    }
  }

  return result;
};

/**
 * @param {Boolean} isTransactions
 * @returns {{ desktopThs: (*|Array<Object>), mobileThs: (*|Array<Object>) }}
 */
export const getCustomerHistoryThs = (isTransactions) => {
  const currentIdText = isTransactions ? 'Transaction ID' : 'Payment ID';
  const currentTypeText = isTransactions ? 'Transaction type' : 'Payment type';

  const desktopThs = getArrayWithIndexId([{
    name: currentIdText,
  }, {
    name: 'Status',
  }, {
    name: 'Amount',
  }, {
    name: currentTypeText,
  }, {
    name: 'Last update',
  }]);
  const mobileThs = getArrayWithIndexId([{
    name: `${currentIdText} / Status`,
  }, {
    name: 'Amount',
  }, {
    name: `${currentTypeText} / Last&nbsp;update`,
  }]);

  return { desktopThs, mobileThs };
};

/**
 * @param {Array<object>} plans
 * @param {Array<object>} customerSubscriptionsItems
 * @returns {Array<object>}
 */
export const getBillingPlansWithoutCustomerActivePlans = (plans, customerSubscriptionsItems) => {
  const currentPlans = !hasElementsInArray(plans) ? [] : plans;

  if (!hasElementsInArray(customerSubscriptionsItems)) {
    return currentPlans;
  }

  const subscriptionPlanIds = customerSubscriptionsItems.map(({ subscriptionPlanId }) => subscriptionPlanId);

  return currentPlans.filter(({ id }) => !subscriptionPlanIds.includes(id));
};

/**
 * @param {String} text
 * @param {Array<object>} items
 * @returns {Array<object>}
 */
export const getBillingPlansFiltered = (text, items) => {
  if (!hasElementsInArray(items)) {
    return [];
  }

  const regExp = new RegExp(text, 'i');

  return items.filter((item) => regExp.test(item?.id) || regExp.test(item?.title));
};
