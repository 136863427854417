import * as React from 'react';
import PropTypes from 'prop-types';
import { getCustomerInfoData } from '../../helpers';
import { UserDataInfoDesktop } from '../../../shared/user-data-info-desktop';

export const CustomerInfo = React.memo((props) => {
  const {
    className,
    data,
    landscape,
    balanceInfoElement,
    loaderElement,
  } = props;

  const headerData = React.useMemo(() => ([{
    id: 1,
    name: 'Customer ID',
    element: null,
  }, {
    id: 2,
    name: 'Balance',
    element: balanceInfoElement,
  }, {
    id: 3,
    name: 'Customer name',
    element: null,
  }, {
    id: 4,
    name: 'Last transaction',
    element: null,
  }]), [balanceInfoElement]);
  const bodyData = React.useMemo(() => (
    getCustomerInfoData(data, loaderElement)
  ), [data, loaderElement]);

  return (
    <UserDataInfoDesktop
      className={className}
      headerItems={headerData}
      bodyItems={bodyData}
      landscape={landscape}
    />
  );
});

CustomerInfo.defaultProps = {
  className: null,
  data: {},
  landscape: false,
  balanceInfoElement: null,
  loaderElement: null,
};

CustomerInfo.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({}),
  landscape: PropTypes.bool,
  balanceInfoElement: PropTypes.node,
  loaderElement: PropTypes.node,
};

CustomerInfo.displayName = 'CustomerInfo';
