import React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';

const mapFn = (data) => ({
  status: data?.status ?? null,
});

export const useUpdatePosTerminalLink = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (params) => {
    const apiKey = params?.apiKey ?? null;
    const link = params?.link ?? null;
    const theme = params?.theme ?? null;
    const [cancelToken] = getRequestSource();
    const requestPromise = client.post(
      '/pos-terminal-link',
      {
        apiKey,
        link,
        theme,
      },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
