import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Input } from '../input';
import { CurrenciesSelect } from '../../currencies-select';
import styles from './styles.module.scss';

export const Field = (props) => {
  const {
    className,
    title,
    data,
    errorTopRight,
  } = props;

  const {
    id,
    name,
    type,
    value,
    placeholder,
    onChange,
    onFocus,
    disabled,
    readOnly,
    autoComplete,
    error,
    success,
    ticker,
    pattern,
    Icon,
    ref,
    currencies,
    onSelect,
    defaultTicker,
  } = data;

  return (
    <div className={cn([
      styles.field,
      currencies && styles.fieldWithCurrencies,
      className,
    ])}>
      {title && (
        <div className={styles.fieldTitle}>
          {title}
        </div>
      )}
      <div className={styles.fieldBox}>
        <Input
          className={cn([
            styles.fieldInput,
            Icon && styles.fieldInputWithIcon,
          ])}
          id={id}
          name={name}
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onFocus={onFocus}
          disabled={disabled}
          readOnly={readOnly}
          autoComplete={autoComplete}
          error={Boolean(error)}
          success={success}
          pattern={pattern}
          ref={ref}
        />
        {ticker && !currencies && (
          <span className={styles.fieldTicker}>
            {ticker}
          </span>
        )}
        {Icon && !currencies && (
          <span className={styles.fieldIcon}>
            {Icon}
          </span>
        )}
        {currencies && (
          <CurrenciesSelect
            className={styles.fieldCurrenciesSelect}
            currencies={currencies}
            themeWithAmount
            error={Boolean(error)}
            onSelect={onSelect}
            defaultSelectTicker={defaultTicker}
          />
        )}
      </div>
      {error && (
        <div className={cn([
          styles.error,
          error && styles.errorShow,
          errorTopRight && styles.errorTopRight,
        ])}>
          <div className={styles.errorText}>
            {error}
          </div>
        </div>
      )}
    </div>
  );
};

Field.defaultProps = {
  className: null,
  title: null,
  data: {
    id: null,
    name: null,
    type: 'text',
    value: null,
    placeholder: null,
    onChange: null,
    onFocus: null,
    disabled: false,
    readOnly: false,
    autoComplete: null,
    error: null,
    success: false,
    pattern: null,
    Icon: null,
    ref: null,
    currencies: null,
    onSelect: null,
    defaultTicker: null,
  },
  errorTopRight: false,
};

Field.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    autoComplete: PropTypes.string,
    error: PropTypes.string,
    success: PropTypes.bool,
    pattern: PropTypes.instanceOf(RegExp),
    Icon: PropTypes.node,
    ref: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
    currencies: PropTypes.arrayOf(PropTypes.object),
    onSelect: PropTypes.func,
    defaultTicker: PropTypes.string,
  }),
  errorTopRight: PropTypes.bool,
};
