import React from 'react';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';

export const useAddPayoutAccount = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (options) => {
    const currency = options?.currency ?? null;
    const paymentCode = options?.paymentCode ?? null;
    const accountNumber = options?.accountNumber ?? null;
    const provider = options?.provider ?? null;
    const iban = options?.iban ?? null;
    const country = options?.countryCode ?? null;
    const swiftbic = options?.swiftBic ?? null;
    const countryCode = options?.countryCode ?? null;
    const fields = {
      iban,
      accountNumber,
      country,
      swiftbic,
    };

    const [cancelToken] = getRequestSource();
    const requestPromise = client.post(
      '/fiat-payouts/account',
      {
        currency,
        paymentCode,
        fields,
        provider,
        countryCode,
      },
      { cancelToken },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      (data) => data,
    );
  }, [getRequestSource]);
};
