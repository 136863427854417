import React from 'react';
import { Button } from '../../../ui/button';
import classes from './styles.module.scss';

export const UnableDeleteLastApiKey: React.FC<{ onClose: () => void }> = (props) => {
  const { onClose } = props;

  return (
    <div className={classes.unableDeleteLastApiKey}>
      <p className={classes.unableDeleteText}>
        You cannot delete this API key because it is the only one in your account. If you wish to delete this key,
        first, generate a new one.
      </p>
      <br />
      <p className={classes.unableDeleteText}>
        Please note: Once deleted, you will lose access to data on all transactions (payments, payouts,
        conversions and transfers) that were created with this key.
      </p>
      <Button
        className={classes.closeButton}
        onClick={onClose}
      >
        OK, close
      </Button>
    </div>
  );
};
