import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from '../../../popup';
import { InvoiceResultWithCurrency } from '../../../invoice-result-with-currency';
import { safeToUpperCase } from '../../../../helpers/safe-to-upper-case';
import { getCurrentNetworkForShow } from '../../../../helpers/get-current-network-for-show';
import { InvoiceResultWithoutCurrency } from "../../../payment-link-page/components/invoice-result-without-currency";

export const InvoiceResultPopup = (props) => {
  const {
    className,
    open,
    onClose,
    invoice,
    payment,
    currenciesObject,
  } = props;

  const orderId = invoice?.order_id ?? null;
  const invoicePayCurrency = invoice?.pay_currency ?? null;

  const priceAmount = invoice?.price_amount ?? null;
  const priceCurrency = invoice?.price_currency ?? null;
  const priceCurrencyUpperCased = safeToUpperCase(priceCurrency);
  const priceCurrencyFound = currenciesObject[priceCurrencyUpperCased] || {};
  const priceCurrentTicker = priceCurrencyFound?.ticker ?? priceCurrencyUpperCased;
  const priceCurrentNetwork = getCurrentNetworkForShow(priceCurrencyFound?.network, priceCurrency);

  const payAmount = payment?.pay_amount ?? null;
  const payCurrency = payment?.pay_currency ?? null;
  const payCurrencyUpperCased = safeToUpperCase(payCurrency);
  const payCurrencyFound = currenciesObject[payCurrencyUpperCased] || {};
  const payCurrentTicker = payCurrencyFound?.ticker ?? payCurrencyUpperCased;
  const payCurrentNetwork = getCurrentNetworkForShow(payCurrencyFound?.network, payCurrency);

  const paymentPayinAddress = payment?.exchange_data?.payinAddress ?? null;
  const paymentPayinExtraId = payment?.payin_extra_id ?? null;
  const paymentStatus = payment?.status ?? null;
  const paymentId = payment?.id ?? null;
  const invoiceUrl = invoice?.invoice_url ?? null;
  const paymentUrl = invoiceUrl && paymentId ? `${invoiceUrl}&paymentId=${paymentId}` : null;

  return (
    <Popup
      classNameContent={className}
      open={open}
      onClose={onClose}
      withCloseButton
    >
      {invoicePayCurrency && (
        <InvoiceResultWithCurrency
          orderId={orderId}
          priceAmount={priceAmount}
          priceTicker={priceCurrentTicker}
          priceNetwork={priceCurrentNetwork}
          payAmount={payAmount}
          payTicker={payCurrentTicker}
          payNetwork={payCurrentNetwork}
          payinAddress={paymentPayinAddress}
          payinExtraId={paymentPayinExtraId}
          status={paymentStatus}
          url={paymentUrl}
        />
      )}
      {!invoicePayCurrency && (
        <InvoiceResultWithoutCurrency
          orderId={orderId}
          priceAmount={priceAmount}
          priceTicker={priceCurrentTicker}
          priceNetwork={priceCurrentNetwork}
          url={invoiceUrl}
        />
      )}
    </Popup>
  );
};

InvoiceResultPopup.defaultProps = {
  className: null,
  open: false,
  onClose: null,
  invoice: {},
  payment: {},
  currenciesObject: {},
};

InvoiceResultPopup.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  invoice: PropTypes.shape({}),
  payment: PropTypes.shape({}),
  currenciesObject: PropTypes.object,
};
