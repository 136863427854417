/* eslint-disable no-undef */
import * as React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { InputSearch } from '../../../ui/input-search';
import { LoaderIcon } from '../../../icons/loader-icon';
import { CheckboxWithText } from '../../../ui/checkbox-with-text';
import { Button } from '../../../ui/button';
import { debounce } from '../../../../helpers/utils';
import { isFunction } from '../../../../helpers/is-function';
import { useAllSubscriptionPlans } from '../../../../hooks/use-all-subscription-plans';
import {
  allPlansErrorSelector,
  allPlansFetchedSelector,
  allPlansFetchingSelector,
  allPlansSelector,
  customerSubscriptionsSelector,
} from '../../../../store/customer-management/selectors';
import {
  getBillingPlansFiltered,
  getBillingPlansWithoutCustomerActivePlans,
} from '../../helpers';
import { hasElementsInArray } from '../../../../helpers/has-elements-in-array';
import { CustomerPageContext } from '../../customer-page';
import classes from './styles.module.scss';

export const CustomerSaveBillingPlanForm = (props) => {
  const {
    className,
    onButtonClick,
  } = props;

  const customerPageContext = React.useContext(CustomerPageContext);
  const {
    onSaveFormSubmit: onSubmit,
    saveFormApiError: apiErrorMessage,
    saveFormFetching: fetching,
    saveFormSaved: saved,
  } = customerPageContext;
  const params = useParams();
  const { customerId } = params;
  const allPlans = useSelector(allPlansSelector());
  const allPlansFetching = useSelector(allPlansFetchingSelector());
  const allPlansFetched = useSelector(allPlansFetchedSelector());
  const allPlansError = useSelector(allPlansErrorSelector());
  const customerSubscriptions = useSelector(customerSubscriptionsSelector());
  const customerSubscriptionsItems = customerSubscriptions[customerId] || null;
  const { reFetchAllSubscriptionPlans } = useAllSubscriptionPlans();

  const [searchText, setSearchText] = React.useState('');
  const [collectionIds, setCollectionIds] = React.useState(new Set());
  const [errorMessage, setErrorMessage] = React.useState(null);

  const billingPlansWithoutCustomerActivePlans = React.useMemo(() => (
    getBillingPlansWithoutCustomerActivePlans(allPlans, customerSubscriptionsItems)
  ), [allPlans, customerSubscriptionsItems]);
  const billingPlansItems = React.useMemo(() => (
    billingPlansWithoutCustomerActivePlans.map((item) => ({
      id: item?.id ?? null,
      title: item?.title ?? null,
      checked: collectionIds.has(item?.id),
    }))
  ), [billingPlansWithoutCustomerActivePlans, collectionIds]);
  const billingPlansItemsFiltered = React.useMemo(() => (
    getBillingPlansFiltered(searchText, billingPlansItems)
  ), [billingPlansItems, searchText]);

  const handleSearchText = React.useCallback(debounce((value) => {
    setSearchText(value);
  }, 400), []);
  const handleBillingPlanChecked = (id) => () => {
    setCollectionIds((prevState) => {
      if (!prevState.has(id)) {
        prevState.add(id);
      } else {
        prevState.delete(id);
      }

      return new Set(prevState);
    });
    setErrorMessage(null);
  };
  const handleSubmit = async () => {
    const ids = Array.from(collectionIds);
    if (!hasElementsInArray(ids)) {
      setErrorMessage('No subscription plans selected');
    }
    if (isFunction(onSubmit)) {
      await onSubmit(ids);
    }
    setCollectionIds(() => new Set());
  };

  React.useEffect(() => {
    if (!allPlansFetched) {
      void reFetchAllSubscriptionPlans();
    }
  },[allPlansFetched]);

  const currentButtonText = !saved ? 'Save' : 'Saved';
  const isButtonDisabled = fetching || allPlansFetching || saved;

  return (
    <div className={className}>
      <InputSearch
        className={classes.searchInput}
        placeholder="Search Plan"
        onChange={handleSearchText}
      />
      <div className={classes.billingPlans}>
        {allPlansFetching && (
          <div className={classes.billingPlansLoader}>
            <LoaderIcon />
          </div>
        )}
        {!allPlansFetching && billingPlansItemsFiltered.map((item) => (
          <CheckboxWithText
            className={classes.billingPlansItem}
            key={item.id}
            id={item.id}
            onChange={handleBillingPlanChecked(item.id)}
            checked={item.checked}
            disabled={fetching || saved}
          >
            <label
              htmlFor={item.id}
              className={classes.billingPlansItemLabel}
            >
              <span className={classes.billingPlansItemId}>
                {item.id}
              </span>
              {' '}
              <span className={classes.billingPlansItemName}>
                {item.title}
              </span>
            </label>
          </CheckboxWithText>
        ))}
      </div>
      <div className={classes.error}>
        {apiErrorMessage || allPlansError || errorMessage}
      </div>
      <Button
        className={classes.button}
        type="button"
        onClick={handleSubmit}
        disabled={isButtonDisabled}
      >
        {fetching && (
          <LoaderIcon path="/images/loader-white-on-blue-icon.gif" />
        )}
        {!fetching && currentButtonText}
      </Button>
      <Button
        className={classes.buttonSecond}
        onClick={onButtonClick}
        displayBorder
      >
        Create a new billing plan
      </Button>
    </div>
  );
};

CustomerSaveBillingPlanForm.defaultProps = {
  className: null,
  onButtonClick: null,
};

CustomerSaveBillingPlanForm.propTypes = {
  className: PropTypes.string,
  onButtonClick: PropTypes.func,
};

/* eslint-enable no-undef */
