import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { PaymentLinksTable } from '../payment-links-table';
import { MenuFilterIcon } from '../../../icons/menu-filter-icon';
import { InputSearch } from '../../../ui/input-search';
import { hasElementsInArray } from '../../../../helpers/has-elements-in-array';
import { PaymentLinkFiltersFormMemo } from '../payment-link-filters';
import {
  getFilteredPaymentsWithSearch,
  getFilteredPaymentsWithSearchData,
} from '../../payment-link-page-helpers';
import { usePaymentsState, useProfileInfoState } from '../../hooks';
import styles from './styles.module.scss';

export const InvoiceDetails = React.memo((props) => {
  const { invoice, onPaymentRowClick } = props;

  const {
    payments,
    arePaymentsLoading,
  } = usePaymentsState();

  const { profileInfo: { currenciesObject } } = useProfileInfoState();

  const invoiceId = invoice?.id ?? null;
  const hasPayments = !arePaymentsLoading && hasElementsInArray(payments);
  const [searchValue, setSearchValue] = React.useState('');
  const [isFiltersOpen, setIsFiltersOpen] = React.useState(false);
  const [searchFormData, setSearchFormData] = React.useState(null);

  const handleSearchChange = (value) => {
    setSearchValue(value);
    setSearchFormData(null);
  };
  const handleFiltersToggle = React.useCallback(() => {
    setIsFiltersOpen((prev) => !prev);
  }, [setIsFiltersOpen]);
  const handleSubmit = React.useCallback((data) => {
    setSearchFormData((prevState) => ({
      ...prevState,
      ...data,
    }));
    setSearchValue('');
    const createdAt = data?.createdAt ?? null;
    const orderId = data?.orderId ?? null;
    const payinAddress = data?.payinAddress ?? null;
    const payinHash = data?.payinHash ?? null;
    const paymentId = data?.paymentId ?? null;
    const status = data?.status ?? null;
    const isDataEmpty = (
      !createdAt && !orderId && !payinAddress && !payinHash && !paymentId && !status
    );

    if (isDataEmpty) {
      setSearchFormData(null);
    }
  }, [setSearchFormData, setSearchValue]);
  const paymentsFiltered = React.useMemo(() => {
    return searchFormData
      ? getFilteredPaymentsWithSearchData(payments, searchFormData)
      : getFilteredPaymentsWithSearch(payments, searchValue);
  }, [payments, searchValue, searchFormData]);

  const isFilterMenuIconActive = isFiltersOpen || Boolean(searchFormData);

  return (
    <div className={styles.paymentDetails}>
      <div className={styles.paymentDetailsHeader}>
        <div className={styles.paymentDetailsTitle}>
          Payment link ID
          {' '}
          {invoiceId}
        </div>
        {hasPayments && (
          <InputSearch
            id="payment-details-search"
            className={styles.searchInput}
            placeholder="Payment ID, order ID, hash"
            value={searchValue}
            onChange={handleSearchChange}
            ImageAdditional={(
              <span
                onClick={handleFiltersToggle}
                className={cn([
                  styles.searchFilterMenuIcon,
                  isFilterMenuIconActive && styles.searchFilterMenuIconActive,
                ])}
              >
                <MenuFilterIcon />
              </span>
            )}
          />
        )}
      </div>
      <div className={cn([
        styles.paymentDetailsFilters,
        isFiltersOpen && styles.paymentDetailsFiltersActive,
      ])}>
        <PaymentLinkFiltersFormMemo
          className={styles.paymentDetailsFiltersPanel}
          onClose={handleFiltersToggle}
          onSubmit={handleSubmit}
          onSearchFormData={setSearchFormData}
        />
      </div>
      <PaymentLinksTable
        className={styles.paymentDetailsTable}
        data={paymentsFiltered}
        onClick={onPaymentRowClick}
        hasPayments={hasPayments}
        loading={arePaymentsLoading}
        currenciesObject={currenciesObject}
      />
    </div>
  );
});

InvoiceDetails.defaultProps = {
  invoice: null,
  onPaymentRowClick: null,
};

InvoiceDetails.propTypes = {
  invoice: PropTypes.object,
  onPaymentRowClick: PropTypes.func,
};

InvoiceDetails.displayName = 'InvoiceDetails';
