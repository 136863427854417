import React from 'react';
import { nanoid } from 'nanoid';
import useAxiosSource from '../../../hooks/use-axios-source';
import restRequestWrapper from '../../helpers/rest-request-wrapper';
import nowPaymentsAccountApiRestTransport from '../../transports/nowpayments-account-api-rest-transport';

const offRampFiatCurrencyMapFn = (data) => ({
  id: nanoid(),
  provider: data?.provider ?? null,
  currencyCode: data?.currencyCode ?? null,
  enabled: data?.enabled ?? null,
});

const mapFn = (data) => ({
  result: (data?.result ?? []).map(offRampFiatCurrencyMapFn),
});

export const useOffRampFiatCurrencies = () => {
  const client = nowPaymentsAccountApiRestTransport();
  const getRequestSource = useAxiosSource();

  return React.useCallback(async (params) => {
    const [cancelToken] = getRequestSource();
    const requestPromise = client.get(
      '/fiat-payouts/fiat-currencies',
      { cancelToken, params },
    );

    return restRequestWrapper(
      requestPromise,
      null,
      mapFn,
    );
  }, [getRequestSource]);
};
