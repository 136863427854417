export const SUBSCRIPTION_PLAN_PAGE_PER_PAGE = 20;

export const SUBSCRIPTION_PLAN_PAGE_INFO_THS_DESKTOP = [{
  id: 1,
  name: 'Subscription ID',
}, {
  id: 2,
  name: 'Name',
}, {
  id: 3,
  name: 'Cost',
}, {
  id: 4,
  name: 'Period',
}, {
  id: 5,
  name: 'Created at',
}];

export const SUBSCRIPTION_PLAN_PAGE_INFO_THS_MOBILE = [{
  id: 1,
  name: 'Subscription ID /<br /> Name',
}, {
  id: 2,
  name: 'Cost /<br /> Period',
}, {
  id: 3,
  name: 'Created at',
}];

export const SUBSCRIPTION_PLAN_PAGE_THS_DESKTOP = [{
  id: 1,
  name: 'User',
}, {
  id: 2,
  name: 'Status',
}, {
  id: 3,
  name: 'Subscribed',
}, {
  id: 4,
  name: 'Updated',
}];

export const SUBSCRIPTION_PLAN_PAGE_THS_MOBILE = [{
  id: 1,
  name: 'User',
}, {
  id: 2,
  name: 'Status',
}, {
  id: 3,
  name: 'Subscribed /<br /> Updated',
}];
