import React from 'react';
import { Helmet } from 'react-helmet';

const META_TITLE = 'NOWPayments';
const META_DESCRIPTION = 'Payment gateway to accept Ethereum, Bitcoin, Litecoin and other cryptocurrencies on your store.';
const URL = 'https://account.nowpayments.io';

export const MainHead = () => (
  <Helmet>
    <title>{META_TITLE}</title>
    <meta name="description" content={META_DESCRIPTION} />
    <link href={URL} rel="canonical" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={URL} />
    <meta property="og:title" content={META_TITLE} />
    <meta property="og:description" content={META_DESCRIPTION} />
    <meta property="og:site_name" content="NOWPayments" />
    <meta property="og:image" content="https://nowpayments.io/images/logo/sharing_image.png" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@NOWPayments_io" />
    <meta name="twitter:creator" content="@NOWPayments_io" />
    <meta name="twitter:title" content={META_TITLE} />
    <meta name="twitter:description" content={META_DESCRIPTION} />
    <meta name="twitter:image" content="https://nowpayments.io/images/logo/sharing_image.png" />
    <script type="application/ld+json">
      {`{
        "@context": "http://schema.org",
        "@type": "WebPage",
        "headline": "${META_TITLE}",
        "name": "${META_TITLE}",
        "mainEntity": "${URL}",
        "url": "${URL}",
        "description": "${META_DESCRIPTION}",
        "about": "${META_DESCRIPTION}",
        "image": "https://nowpayments.io/images/logo/sharing_image.png",
        "publisher": {
          "@type": "Organization",
          "name": "NOWPayments",
          "logo": {
            "@type": "ImageObject",
            "url": "https://nowpayments.io/images/logo/scheme_logo.png",
            "height": "60",
            "width": "400"
          }
        },
        "sameAs": [
          "https://twitter.com/NOWPayments_io",
          "https://www.facebook.com/NOWPayments.io",
          "https://medium.com/@NOWPayments",
          "https://www.reddit.com/user/NOWPayments",
          "https://github.com/nowpaymentsIO%22",
          "https://www.youtube.com/channel/UC3jYxRnDdSKr65sdVA09Q5Q",
          "https://www.producthunt.com/posts/nowpayments",
          "https://www.linkedin.com/company/nowpayments"
        ]
      }`}
    </script>
  </Helmet>
);
