import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAllCurrencies } from '../../../hooks/use-all-currencies';
import { partnerSelector } from '../../../store/partner/selectors';
import { isObject } from '../../../helpers/is-object';
import { balancesSelector, baseCurrencySelector } from '../../../store/payment-settings/selectors';
import { checkErrorCode } from '../../../helpers/check-error-code';
import { getSafeErrorMessageText } from '../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../helpers/console-error-message';
import { ResponseType } from '../../../default-types';
import {
  setBalance,
  setCountOfPayouts,
} from '../../../store/statistics/reducer';
import { useBalancesData } from '../../../hooks/use-balances-data';
import { useMarketInfoRates } from '../../../hooks/use-market-info-rates';
import { hasElementsInArray } from '../../../helpers/has-elements-in-array';
import { getUniqueTickersForMarketInfo } from '../../../helpers/get-unique-tickers-for-market-info';
import { currencyListSelector } from '../../../store/currencies/selectors';
import { useCurrenciesToObject } from '../../../hooks/use-currencies-to-object';
import {
  marketInfoFromCurrencySelector,
  marketInfoRatesSelector,
} from '../../../store/market-info/selectors';
import { getFiatAmountOfBalances } from '../../../helpers/get-fiat-amount-of-balances';
import { toFixed } from '../../../helpers/to-fixed';
import { safeToUpperCase } from '../../../helpers/safe-to-upper-case';
import { useWithdrawalPayments } from '../../../api/modules/account/use-withdrawal-payments';
import { STATUSES } from '../../../constants/app-constants';
import { useSystemStatus } from '../../../hooks/use-system-status';

export const useDashboardInitState = () => {
  const dispatch = useDispatch();
  const fetchWithdrawalPayments = useWithdrawalPayments();
  const partner = useSelector(partnerSelector());
  const partnerBalances = useSelector(balancesSelector());
  const baseCurrency = useSelector(baseCurrencySelector());
  const currencyList = useSelector(currencyListSelector());
  const { currenciesObject } = useCurrenciesToObject(currencyList);
  const marketInfoRates = useSelector(marketInfoRatesSelector());
  const marketInfoFromCurrency = useSelector(marketInfoFromCurrencySelector());

  const tickersForMarketInfoRates = React.useMemo(() => (
    getUniqueTickersForMarketInfo(partnerBalances, currenciesObject, true)
  ), [partnerBalances, currenciesObject]);

  const initPayouts = React.useCallback(async (): Promise<void> => {
    const { data, status, errorMessage }: ResponseType = await fetchWithdrawalPayments({
      params: {
        type: 'mass_payouts',
        status: STATUSES.FINISHED,
      },
    });
    if (status === 200 && isObject(data)) {
      const count = hasElementsInArray(data?.withdrawals) ? data.withdrawals.length - 1 : 0;
      dispatch(setCountOfPayouts(String(count)));
    } else {
      checkErrorCode(data?.errorData?.code);
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/withdrawal-payments');
    }
  }, [
    dispatch,
    fetchWithdrawalPayments,
  ]);

  useAllCurrencies();
  const { reFetchBalances } = useBalancesData();
  useMarketInfoRates({
    isNeedRates: hasElementsInArray(partnerBalances),
    // @ts-ignore
    tickers: tickersForMarketInfoRates,
  });
  useSystemStatus();

  React.useEffect(() => {
    const fiatAmount = getFiatAmountOfBalances(partnerBalances, currenciesObject, marketInfoRates, true);
    const fiatAmountToFixed = toFixed(fiatAmount, 2);
    const currencyUpperCased = safeToUpperCase(marketInfoFromCurrency) || '';
    dispatch(setBalance({
      amount: fiatAmountToFixed,
      currency: currencyUpperCased,
    }));
  }, [
    partnerBalances,
    currenciesObject,
    marketInfoRates,
    marketInfoFromCurrency,
  ]);

  React.useEffect(() => {
    if (isObject(partner) && baseCurrency) {
      if (partner.isCustody) {
        initPayouts();
      }
    }
  }, [partner, baseCurrency]);

  React.useEffect(() => {
    if (partnerBalances) {
      reFetchBalances();
    }
  }, []);

  return { currenciesObject };
};
