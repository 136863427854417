import React from 'react';
import cn from 'classnames';
import ValidatorWrapper, { ValidatorField } from '@coxy/react-validator';
import { useHistory, useLocation } from 'react-router-dom';
import root from '../styles.module.scss';
import apiClient from '../../../api/api-client';
import { walletRules, extraIdRules } from '../../../libs/validators';
import Button from '../../../components/button';
import WithdrawalInput from '../../../components/withdrawal-input';
import Metatags from '../../../components/metatags';
import { CurrenciesSelect } from '../../../components/currencies-select';
import { useAvailableCurrencies } from '../../../api/modules/account/use-available-currencies';
import { hasElementsInArray } from '../../../helpers/has-elements-in-array';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { sendGoogleAuthWithUtmMetrics } from '../../../helpers/send-google-auth-with-utm-metrics';
import styles from './styles.module.scss';

const DEFAULT_TICKER = 'BTC';
const SUCCESS_PATH = '/sign-up-success';
const REGISTER_API_KEY_NAME = 'register-api-key';
const LINK_SEARCH = '?download&utm_source=NOWPayments&utm_medium=Referral&utm_campaign=NP+reg';
const WALLET_DESKTOP_LINK = `https://walletnow.app/${LINK_SEARCH}`;
const WALLET_MOBILE_LINK =  `https://walletnow.app.link/${LINK_SEARCH}`;

const WalletAddition = () => {
  const history = useHistory();
  const location = useLocation();
  const fetchAvailableCurrencies = useAvailableCurrencies();
  const isDesktop = useMediaQuery('(min-width: 992px)');
  const walletLink = isDesktop ? WALLET_DESKTOP_LINK : WALLET_MOBILE_LINK;

  const [currencies, setCurrencies] = React.useState([]);
  const [selectedCurrency, setSelectedCurrency] = React.useState(location.state?.lastCreatedWalletCurrency || {});
  const [walletAddress, setWalletAddress] = React.useState(location.state?.lastCreatedWalletAddress || '');
  const [valuExtraId, setValuExtraId] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [serverError, setServerError] = React.useState('');
  const [isErrorShown, setIsErrorShow] = React.useState(false);
  const [serverErrorOnTopInput, setServerErrorOnTopInput] = React.useState('');
  const [submitButtonPressed, setSubmitButtonPressed] = React.useState(false);
  const [searchParamsString, setSearchParamsString] = React.useState(null);

  const validator = React.useRef();

  const currentSuccessUrl = React.useMemo(() => {
    const searchParams = new URLSearchParams(searchParamsString);
    const successUrl = searchParams.get('utm_button');

    return successUrl ? `${SUCCESS_PATH}/${successUrl}` : SUCCESS_PATH;
  }, [searchParamsString]);

  const handleSkipClick = () => {
    history.push({
      pathname: currentSuccessUrl,
      search: 'utm_skip=true',
      state: {
        lastCreatedWalletCurrency: null,
        lastCreatedWalletAddress: null,
      },
    });
  };
  const onSubmit = async() => {
    setSubmitButtonPressed(true);

    const { isValid } = validator.current.validate();

    if (!isValid) {
      return;
    }

    setIsLoading(true);

    const wallets = [{
      c: selectedCurrency?.code,
      addr: walletAddress,
      eid: selectedCurrency?.extra_id_exists ? valuExtraId : null,
    }];

    try {
      await apiClient.saveWallets({ wallets });

      if (!window.sessionStorage.getItem(REGISTER_API_KEY_NAME)) {
        await apiClient.createApiKey();
        await apiClient.changeBaseCurrency('USD');
        window.sessionStorage.setItem(REGISTER_API_KEY_NAME, true);
      }

      history.push({
        pathname: currentSuccessUrl,
        state: {
          lastCreatedWalletCurrency: selectedCurrency,
          lastCreatedWalletAddress: walletAddress,
        },
      });
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        setServerErrorOnTopInput (e.response.data.message);
      } else {
        setServerError('Error');
        setIsErrorShow(true);
      }
    }

    setIsLoading(false);
  };
  const clearErrorState = () => {
    setSubmitButtonPressed(false);
    setIsErrorShow(false);
    setServerError('');
    setServerErrorOnTopInput('');
  };
  const handleCurrenciesSelect = (currency) => {
    setSelectedCurrency(currency);
  };
  const setFetchedCurrencies = async () => {
    const { data, status } = await fetchAvailableCurrencies();

    if (status === 200 && hasElementsInArray(data)) {
      setCurrencies(data);
    }
  };

  React.useEffect(() => {
    setFetchedCurrencies();
    const searchParamsFromSessionStorage = sessionStorage.getItem('queryParams');
    const googleAuthEmailFromSessionStorage = sessionStorage.getItem('GOOGLE_AUTH_EMAIL');

    if (searchParamsFromSessionStorage) {
      setSearchParamsString(searchParamsFromSessionStorage);
    }

    if (googleAuthEmailFromSessionStorage) {
      const searchParams = window.location.search;
      const searchParamsString = searchParams.split('?').join('');
      const searchParamsForMetrics = searchParamsString.split('&').join('  ');
      void sendGoogleAuthWithUtmMetrics(googleAuthEmailFromSessionStorage, searchParamsForMetrics);
    }

    return () => {
      sessionStorage.removeItem('GOOGLE_AUTH_EMAIL');
    };
  }, []);

  return (
    <div className={root.formContainer}>
      <Metatags
        title="Wallet addition | NOWPayments"
        description="Please provide the email address that you used when you signed up for your NOWPayments account. We will send you an email that will allow you to reset your password."
        url="https://account.nowpayments.io/wallet-addition"
      />
      <div className={root.form}>
        <div className={root.formHeader}>
          <p className={root.logo}>
            <span className={cn(root.logo, root.blueText)}>NOW</span>
            Payments
          </p>
          <p className={root.stepsCount}>Step 3 of 3</p>
        </div>
        <div className={root.divider} />
        <div className={styles.dividerContent}>
          <p className={cn(root.titleBig, styles.title)}>Add your crypto address to receive payouts</p>
        </div>
        <p className={cn(root.titleSmall, styles.description)}>
          Funds sent by your customers are converted automatically and sent to this payout wallet
        </p>
        <div style={{ marginTop: 30 }}>
          <ValidatorWrapper ref={validator}>
            {(isErrorShown && submitButtonPressed) && (
              <div className={root.serverError}>
                <span>{serverError}</span>
              </div>
            )}
            <CurrenciesSelect
              currencies={currencies}
              defaultSelectTicker={DEFAULT_TICKER}
              onSelect={handleCurrenciesSelect}
              className={styles.containerInput}
            />
            <a
              href={walletLink}
              target="_blank"
              rel="nofollow noopener noreferrer"
              className={styles.link}
            >
              Don&apos;t have a wallet yet?
            </a>
            <ValidatorField value={walletAddress} rules={walletRules(selectedCurrency.wallet_regex)}>
              {({ isValid, message }) => (
                <div className={`${styles.inputContainer} ${styles.inputContainerPadding}`}>
                  <WithdrawalInput
                    placeholder="e.g.: 1HP2esjU9H914ML7NHg7rX4w4wPGpZkLez"
                    value={walletAddress}
                    onChange={(e) => {
                      setWalletAddress(e.target.value);
                      clearErrorState();
                    }}
                    disabled={!selectedCurrency.code}
                    errorMessage={(!isValid && submitButtonPressed && message)
                      || (serverErrorOnTopInput.length > 1 && serverErrorOnTopInput)}
                  />
                </div>
              )}
            </ValidatorField>

            {selectedCurrency?.extra_id_exists && (
              <ValidatorField value={valuExtraId} rules={extraIdRules(selectedCurrency.extra_id_regex)}>
                {({ isValid, message }) => (
                  <div className={styles.contentTag}>
                    <WithdrawalInput
                      label={'Destination tag (memo)'}
                      placeholder="e.g.: 123456789"
                      value={valuExtraId}
                      onChange={(e) => {
                        setValuExtraId(e.target.value);
                      }}
                      disabled={!selectedCurrency.code}
                      errorMessage={(!isValid && submitButtonPressed) && message}
                    />
                  </div>
                )}
              </ValidatorField>
            )}
          </ValidatorWrapper>
        </div>
        <div className={styles.footer}>
          <Button onClick={onSubmit} loading={isLoading}>
            Add wallet
          </Button>
          <div className={styles.footerLine} />
          <div className={styles.skip}>
            <span className={styles.skipText}>
              Want to add a wallet later?
              {' '}
              <span
                className={styles.skipLink}
                onClick={handleSkipClick}
                onKeyPress={handleSkipClick}
                tabIndex={0}
                role="button"
              >
                Skip
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default  WalletAddition;
