import React from 'react';
import { getSafeErrorMessageText } from '../../../helpers/get-safe-error-message-text';
import { useCreateSubPartners } from '../../../api/modules/account/use-create-sub-partners';
import { checkNames } from '../helpers';
import { useTimeout } from '../../../hooks/use-timeout';
import { trackEvent } from '../../../helpers/utils/track-event';
import { TRACK_EVENT_NAMES } from '../../../constants/track-event-names';

const MAX_NAMES = 10;
const MAX_NAME_LENGTH = 46;
const TIMER_TIMEOUT = 4000;
const TIMER_TIMEOUT_SUCCESS = 1000;

export const useCreateCustomersData = () => {
  const fetchCreateSubPartners = useCreateSubPartners();

  const [createCustomersPending, setCreateCustomersPending] = React.useState(false);
  const [createCustomersSuccess, setCreateCustomersSuccess] = React.useState(false);
  const [createCustomersError, setCreateCustomersError] = React.useState(null);
  const [createCustomersApiError, setCreateCustomersApiError] = React.useState(null);
  const [isPopupCreateCustomersShown, setIsPopupCreateCustomersShown] = React.useState(false);

  const resetCreateCustomersSuccess = () => {
    setCreateCustomersSuccess(false);
  };
  const resetCreateCustomersApiError = () => {
    setCreateCustomersApiError(null);
  };
  const resetCreateCustomersError = () => {
    setCreateCustomersError(null);
  };
  const handlePopupCreateCustomersOpen = React.useCallback(() => {
    setIsPopupCreateCustomersShown(true);
    trackEvent(TRACK_EVENT_NAMES.CUSTOMERS_ADD, {
      action: 'click',
    });
  }, []);
  const handlePopupCreateCustomersClose = () => {
    setIsPopupCreateCustomersShown(false);
  };
  const handleCreateUsersSubmit = async (names) => {
    setCreateCustomersError(null);

    if (!names) {
      setCreateCustomersError('Customers is required field');

      return false;
    }

    const namesArray = names.split(',').map((item) => item.trim());
    const oneEmail = namesArray.length === 1 ? namesArray.join('') : null;
    const { isValid, error } = checkNames(namesArray, MAX_NAMES, MAX_NAME_LENGTH);

    if (!isValid) {
      setCreateCustomersError(error);

      return;
    }

    setCreateCustomersPending(true);
    const { data, status } = await fetchCreateSubPartners({
      name: oneEmail || namesArray,
      isOneName: Boolean(oneEmail),
    });

    if (status === 201) {
      setCreateCustomersPending(false);
      setIsPopupCreateCustomersShown(false);
      setCreateCustomersSuccess(true);
      trackEvent(TRACK_EVENT_NAMES.CUSTOMERS_ADDED, {
        action: 'submit',
      });
    } else {
      const errorMessage = getSafeErrorMessageText(data?.errorData?.message);
      setCreateCustomersApiError(errorMessage);
      setCreateCustomersPending(false);
    }
  };

  useTimeout(resetCreateCustomersApiError, TIMER_TIMEOUT, createCustomersApiError);
  useTimeout(resetCreateCustomersError, TIMER_TIMEOUT, createCustomersError);
  useTimeout(resetCreateCustomersSuccess, TIMER_TIMEOUT_SUCCESS, createCustomersSuccess);

  return {
    isPopupCreateCustomersShown,
    createCustomersPending,
    createCustomersApiError,
    createCustomersError,
    createCustomersSuccess,
    handleCreateUsersSubmit,
    handlePopupCreateCustomersOpen,
    handlePopupCreateCustomersClose,
  };
};
