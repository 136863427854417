import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ReCAPTCHA from 'react-google-recaptcha';
import { useSelector } from 'react-redux';
import { isFunction } from '../../helpers/is-function';
import { Field } from '../ui/field';
import { Button } from '../ui/button';
import { LoaderIcon } from '../icons/loader-icon';
import { useSend2faCode } from '../../api/modules/account/use-send-2fa-code';
import { partnerType2faSelector } from '../../store/partner/selectors';
import { TFA } from '../../constants/app-constants';
import { getSafeErrorMessageText } from '../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../helpers/console-error-message';
import { use2faVerify } from '../../api/modules/account/use-2fa-verify';
import config from '../../config';
import { useTimeout } from '../../hooks/use-timeout';
import { useSendClientError } from '../../api/modules/account/use-send-client-error';
import classes from './styles.module.scss';

export const New2fa = (props) => {
  const {
    onUpdate,
    onClose,
    pending,
    apiErrorMessage,
  } = props;

  const partnerType2fa = useSelector(partnerType2faSelector());
  const sendClientError = useSendClientError();
  const fetchSend2faCode = useSend2faCode();
  const fetch2faVerify = use2faVerify();

  const captchaRef = React.useRef(null);
  const [captcha, setCaptcha] = React.useState('');
  const [code, setCode] = React.useState('');
  const [error, setError] = React.useState('');
  const [isInvalidCode, setIsInvalidCode] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);

  const handleChange = (value) => {
    setCode(value);
    setIsInvalidCode(false);
  };
  const handleCaptchaChange = (token) => {
    setCaptcha(token);
  };
  const onCaptchaExpired = async () => {
    setCaptcha('');
  };
  const handleTfaSubmit = async () => {
    if (!code) {
      setIsInvalidCode(true);

      return;
    }

    if (!captcha) {
      setError('Please solve reCAPTCHA correctly');

      return;
    }

    setIsFetching(true);
    const { data, status } = await fetch2faVerify({
      verificationCode: code,
      captchaToken: captcha,
    });
    setIsFetching(false);

    if (status === 200 && data) {
      if (isFunction(onUpdate)) {
        onUpdate(true);
      }
    } else if (data?.errorData?.message === 'Invalid verification code') {
      setIsInvalidCode(true);
    } else {
      const message = data?.errorData?.error ?? null;
      setError(message);
      void sendClientError({
        message: `2FA (type: ${partnerType2fa}): ${message}`,
        href: window.location.href,
      });
    }
  };
  const send2faCode = async () => {
    const { data, status, errorMessage } = await fetchSend2faCode();

    if (status !== 200) {
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/two-factor/send-code');

      setError(errorDataMessage);
    }
  };

  React.useEffect(() => {
    if (partnerType2fa !== TFA.TYPES.APP) {
      send2faCode();
    }
  }, [partnerType2fa]);

  useTimeout(() => { setError(''); }, 5000, error);

  const currentDescriptionText = partnerType2fa === TFA.TYPES.APP
    ? TFA.TEXTS.APP_SECOND
    : TFA.TEXTS.EMAIL_SECOND;
  const invalidCodeErrorText = isInvalidCode ? 'Wrong authentication code' : null;

  return (
    <div className={classes.new2fa}>
      <div className={classes.new2faTitle}>
        Two step authentication
      </div>
      <div className={classes.new2faHr} />
      <div className={classes.new2faDescription}>
        {currentDescriptionText}
      </div>
      <Field
        className={classes.new2faField}
        data={{
          value: code,
          onChange: handleChange,
          error: invalidCodeErrorText,
          placeholder: 'Enter your authentication code',
        }}
      />
      <div className={classes.captchaBlock}>
        <ReCAPTCHA
          sitekey={config.captcha_key}
          onChange={handleCaptchaChange}
          onExpired={onCaptchaExpired}
          ref={captchaRef}
          hl="en"
        />
      </div>
      <div className={classes.new2faError}>
        {apiErrorMessage || error}
      </div>
      <div className={classes.new2faButtons}>
        <Button
          onClick={handleTfaSubmit}
          className={classes.new2faButton}
          disabled={pending || isFetching}
        >
          {(pending || isFetching) ? (
            <LoaderIcon
              size={25}
              path="/images/loader-white-on-blue-icon.gif"
            />
          ) : 'Send'}
        </Button>
        {onClose && (
          <Button
            onClick={onClose}
            className={cn(classes.new2faButton, classes.new2faButtonCancel)}
          >
            Cancel
          </Button>
        )}
      </div>
    </div>
  );
};

New2fa.defaultProps = {
  onUpdate: null,
  onClose: null,
  pending: false,
  apiErrorMessage: null,
};

New2fa.propTypes = {
  onUpdate: PropTypes.func,
  onClose: PropTypes.func,
  pending: PropTypes.bool,
  apiErrorMessage: PropTypes.string,
};
