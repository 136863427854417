import * as React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import { HistoryTableMemo } from '../../../history-table';
import {
  CUSTOMERS_PAGE_PER_PAGE,
  CUSTOMERS_PAGE_THS_DESKTOP,
  CUSTOMERS_PAGE_THS_MOBILE,
} from '../../helpers';
import { NewPagination } from '../../../shared/new-pagination';
import { Button } from '../../../ui/button';
import { CustomersTableRow } from '../customers-table-row';
import classes from './styles.module.scss';

export const CustomersTable = React.memo((props) => {
  const {
    items,
    hasItems,
    hasSearchData,
    loading,
    onClick,
    count,
    onPaginatorClick,
    page,
    apiError,
    onButtonClick,
  } = props;

  const isDesktop = useMediaQuery('(min-width: 1200px)');
  const thItems = isDesktop ? CUSTOMERS_PAGE_THS_DESKTOP : CUSTOMERS_PAGE_THS_MOBILE;
  const isPaginationShow = count > CUSTOMERS_PAGE_PER_PAGE;

  return (
    <>
      <div className={classes.customersTableBox}>
        <HistoryTableMemo
          className={classes.customersTable}
          thItems={thItems}
          colSpan={6}
          loading={loading}
          historyData={items}
          hasData={hasItems || hasSearchData}
          emptyText="Create your first customer!"
          emptyElement={(
            <div className={classes.customersTableButton}>
              <Button onClick={onButtonClick}>
                Add customers
              </Button>
            </div>
          )}
          notFoundText="No customers matching this criteria."
          apiError={apiError}
        >
          {hasItems && items.map((item) => (
            <CustomersTableRow
              key={item?.id}
              id={item?.id}
              name={item?.name}
              amount={item?.amount}
              ticker={item?.ticker}
              updatedAt={item?.updatedAt}
              isDesktop={isDesktop}
              onClick={onClick}
            />
          ))}
        </HistoryTableMemo>
      </div>
      {isPaginationShow && (
        <NewPagination
          className={classes.paginator}
          currentPage={page}
          countItems={count}
          itemsPerPage={CUSTOMERS_PAGE_PER_PAGE}
          onChangePage={onPaginatorClick}
        />
      )}
    </>
  );
});

CustomersTable.defaultProps = {
  items: null,
  hasItems: false,
  hasSearchData: false,
  loading: false,
  onClick: null,
  count: 0,
  onPaginatorClick: null,
  page: 1,
  apiError: null,
};

CustomersTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  hasItems: PropTypes.bool,
  hasSearchData: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  count: PropTypes.number,
  onPaginatorClick: PropTypes.func,
  page: PropTypes.number,
  apiError: PropTypes.string,
};

CustomersTable.displayName = 'CustomersTable';
