import React from 'react';
import { useSelector } from 'react-redux';
import { useSubscriptionPlan } from '../../../api/modules/account/use-subscription-plan';
import { getSafeErrorMessageText } from '../../../helpers/get-safe-error-message-text';
import { consoleErrorMessage } from '../../../helpers/console-error-message';
import { isObject } from '../../../helpers/is-object';
import { isPartnerCustodySelector, partnerFetchedSelector } from '../../../store/partner/selectors';
import { checkErrorCode } from '../../../helpers/check-error-code';

export const useBillingPlanPageInitState = (planId) => {
  const fetchSubscriptionPlan = useSubscriptionPlan();
  const isPartnerCustody = useSelector(isPartnerCustodySelector());
  const partnerFetched = useSelector(partnerFetchedSelector());

  const [isPlanFetching, setIsPlanFetching] = React.useState(false);
  const [planData, setPlanData] = React.useState({});
  const [isPlanNotFound, setIsPlanNotFound] = React.useState(false);

  const init = async () => {
    setIsPlanFetching(true);

    const { data, status, errorMessage } = await fetchSubscriptionPlan(planId);

    if (status === 200 && isObject(data?.result)) {
      setPlanData(data.result);
      setIsPlanFetching(false);
    } else {
      checkErrorCode(data?.errorData?.code);
      const errorDataMessage = getSafeErrorMessageText(data?.errorData?.message);
      consoleErrorMessage(errorMessage, errorDataMessage, '/subscriptions/plans/:id');
      setIsPlanNotFound(true);
      setIsPlanFetching(false);
    }
  };

  React.useEffect(() => {
    if (partnerFetched && isPartnerCustody) {
      void init();
    }
  }, [partnerFetched, isPartnerCustody]);

  return {
    isPlanFetching,
    planData,
    isPlanNotFound,
  };
};
