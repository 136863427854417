import React from 'react';
import cn from 'classnames';
import { TYPES } from '../../helpers';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import classes from './styles.module.scss';

type WhitelistTabsTypes = {
  activeType: string,
  onActiveType: React.Dispatch<React.SetStateAction<string>>,
};

export const WhitelistTabs: React.FC<WhitelistTabsTypes> = (props) => {
  const {
    activeType,
    onActiveType,
  } = props;
  const isTablet = useMediaQuery('(min-width: 768px)');

  const handleTabClick = (type: string) => () => {
    onActiveType(type);
  };

  return (
    <div className={classes.whitelistTabs}>
      <button
        type="button"
        className={cn([
          classes.whitelistTab,
          activeType === TYPES.MANUALLY && classes.whitelistTabActive,
        ])}
        onClick={handleTabClick(TYPES.MANUALLY)}
      >
        {isTablet ? 'Fill in the data manually' : 'Fill manually'}
      </button>
      <button
        type="button"
        className={cn([
          classes.whitelistTab,
          activeType === TYPES.UPLOAD && classes.whitelistTabActive,
        ])}
        onClick={handleTabClick(TYPES.UPLOAD)}
      >
        Upload CSV file
      </button>
    </div>
  );
};
