import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { useWithdrawalPaymentsVerify } from '../../api/modules/account/use-withdrawal-payments-verify';
import { isFunction } from '../../helpers/is-function';
import { Field } from '../ui/field';
import { Button } from '../ui/button';
import { LoaderIcon } from '../icons/loader-icon';
import { useSend2faCode } from '../../api/modules/account/use-send-2fa-code';
import { partnerType2faSelector } from '../../store/partner/selectors';
import { TFA } from '../../constants/app-constants';
import { useSendClientError } from '../../api/modules/account/use-send-client-error';
import classes from './styles.module.scss';

export const New2faWithdrawal = (props) => {
  const {
    onUpdate,
    onClose,
    withdrawalId,
  } = props;

  const partnerType2fa = useSelector(partnerType2faSelector());
  const sendClientError = useSendClientError();
  const fetchSend2faCode = useSend2faCode();
  const fetchWithdrawalPaymentsVerify = useWithdrawalPaymentsVerify();

  const [code, setCode] = React.useState('');
  const [error, setError] = React.useState('');
  const [isInvalidCode, setIsInvalidCode] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);

  const handleChange = (value) => {
    setCode(value);
    setIsInvalidCode(false);
  };
  const handleTfaSubmit = async () => {
    if (!code) {
      setIsInvalidCode(true);

      return;
    }

    setIsFetching(true);
    const { data, status, errorMessage } = await fetchWithdrawalPaymentsVerify({
      verificationCode: code,
      batchWithdrawalId: withdrawalId,
    });

    setIsFetching(false);

    if (status === 200 && data) {
      if (isFunction(onUpdate)) {
        onUpdate(true);
      }
    } else if (errorMessage === 'Invalid verification code') {
      setIsInvalidCode(true);
    } else {
      const message = data?.errorData?.message ?? null;
      setError(message);
      void sendClientError({
        message: `2FA Withdrawal (type: ${partnerType2fa}): ${message}`,
        href: window.location.href,
      });
    }
  };
  const send2faCode = async () => {
    const { data, status } = await fetchSend2faCode();

    if (status !== 200) {
      const errorMessage = data?.errorData?.message ?? null;
      const currentMessage = typeof errorMessage === 'string' ? errorMessage : 'Something went wrong';

      setError(currentMessage);
    }
  };

  React.useEffect(() => {
    if (partnerType2fa === TFA.TYPES.EMAIL) {
      send2faCode();
    }
  }, [partnerType2fa]);

  const currentDescriptionText = partnerType2fa === TFA.TYPES.APP
    ? TFA.TEXTS.APP
    : TFA.TEXTS.EMAIL;
  const invalidCodeErrorText = isInvalidCode ? 'Enter valid verification code' : null;

  return (
    <div className={classes.new2faWithdrawal}>
      <div className={classes.new2faWithdrawalTitle}>
        Two step authentication
      </div>
      <div className={classes.new2faWithdrawalHr} />
      <div className={classes.new2faWithdrawalDescription}>
        {currentDescriptionText}
      </div>
      <Field
        className={classes.new2faWithdrawalField}
        data={{
          value: code,
          onChange: handleChange,
          error: invalidCodeErrorText,
          placeholder: 'Enter your authentication code',
        }}
      />
      <div className={classes.new2faWithdrawalError}>
        {error}
      </div>
      <div className={classes.new2faWithdrawalButtons}>
        <Button
          onClick={handleTfaSubmit}
          className={classes.new2faWithdrawalButton}
        >
          {isFetching && (
            <LoaderIcon
              size={25}
              path="/images/loader-white-on-blue-icon.gif"
            />
          )}
          {!isFetching && 'OK'}
        </Button>
        {onClose && (
          <Button
            onClick={onClose}
            className={cn(classes.new2faWithdrawalButton, classes.new2faWithdrawalButtonCancel)}
          >
            Cancel
          </Button>
        )}
      </div>
    </div>
  );
};

New2faWithdrawal.defaultProps = {
  onUpdate: null,
  onClose: null,
  withdrawalId: null,
};

New2faWithdrawal.propTypes = {
  onUpdate: PropTypes.func,
  onClose: PropTypes.func,
  withdrawalId: PropTypes.string,
};
