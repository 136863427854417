import React from 'react';
import cn from 'classnames';
import dateFormat from '../../../../libs/date-format';
import { CopyText } from '../../../ui/copy-text';
import { DotsIcon } from '../../../icons/dots-icon';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import { getShortStringWithEllipsis } from '../../../../helpers/get-short-string-with-ellipsis';
import { ApiKey } from '../../../../default-types';
import classes from './styles.module.scss';

type ApiKeysTableRowProps = {
  apiKeyData: ApiKey,
  tdClassname: string,
  selectedApiKey: ApiKey | null,
  onClick: (apiKey: ApiKey) => void,
};

const VISIBLE_KEY_LENGTH_SMALL = 3;
const VISIBLE_KEY_LENGTH = 4;
const MAX_FULL_VISIBLE_LENGTH = 9;

const ApiKeysTableRow: React.FC<ApiKeysTableRowProps> = (props) => {
  const {
    apiKeyData,
    tdClassname,
    selectedApiKey,
    onClick,
  } = props;

  const isDesktop = useMediaQuery('(min-width: 992px)');
  const isSmallMobile = useMediaQuery('(max-width: 374px)');

  const ref = React.useRef<HTMLDivElement>(null);

  const [isTitleTooltip, setIsTitleTooltip] = React.useState(false);

  const handleClick = () => {
    onClick(apiKeyData);
  };

  React.useEffect(() => {
    const element = ref.current;
    if (!apiKeyData.description || !element || !isDesktop) {
      setIsTitleTooltip(false);
      return;
    }

    const isOverflowing = element.scrollWidth > element.clientWidth;
    setIsTitleTooltip(isOverflowing);
  }, [isDesktop, apiKeyData, ref]);

  const apiKeyShortPart = isSmallMobile ? VISIBLE_KEY_LENGTH_SMALL : VISIBLE_KEY_LENGTH;
  const apiKeyShort = getShortStringWithEllipsis(apiKeyData.apiKey || '', MAX_FULL_VISIBLE_LENGTH, apiKeyShortPart, apiKeyShortPart);
  const tidShort = getShortStringWithEllipsis(apiKeyData.tid || '', MAX_FULL_VISIBLE_LENGTH, apiKeyShortPart, apiKeyShortPart);
  const createdAtFormatted = apiKeyData.createdAt ? dateFormat(apiKeyData.createdAt) : '';

  return (
    <div
      key={apiKeyData.id}
      className={cn([
        classes.apiKeysTableRow,
        selectedApiKey?.id === apiKeyData.id && classes.apiKeysTableRowActive,
      ])}
    >
      <div className={classes.apiKeysTableRowContent}>
        <div className={cn(tdClassname, classes.apiKeysTableRowTitle)}>
          {apiKeyData.description && (
            <div
              ref={ref}
              className={cn(classes.secondaryText, classes.keyTitle)}
              data-tooltip-id={isTitleTooltip ? 'api-key-title-tooltip' : ''}
              data-tooltip-content={apiKeyData.description}
            >
              {apiKeyData.description}
            </div>
          )}
          <CopyText
            className={classes.copy}
            copyText={apiKeyData.apiKey}
            text={apiKeyShort}
            copyIconPath="/images/copy-icon-settings.svg"
            copySuccessIconPath="/images/done-icon.svg"
          />
        </div>
        <div className={cn(tdClassname, classes.tdBottom)}>
          <CopyText
            className={classes.copy}
            text={tidShort}
            copyText={apiKeyData.tid}
            copyIconPath="/images/copy-icon-settings.svg"
            copySuccessIconPath="/images/done-icon.svg"
          />
        </div>
        {isDesktop && (
          <div className={cn(tdClassname, classes.tdBottom, classes.secondaryText)}>
            {createdAtFormatted}
          </div>
        )}
        <div className={cn(tdClassname, classes.apiKeysTableRowOptions)}>
          <button
            type="button"
            className={classes.apiKeysTableRowOptionsButton}
            data-tooltip-id="api-key-options-tooltip"
            onClick={handleClick}
          >
            <DotsIcon />
          </button>
        </div>
      </div>
      {!isDesktop && (
        <div className={cn(classes.secondaryText, classes.createdAtMobile)}>
          {`Created: ${createdAtFormatted}`}
        </div>
      )}
    </div>
  );
};

export const ApiKeysTableRowMemo = React.memo(ApiKeysTableRow);
