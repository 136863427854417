import XLSX from 'xlsx';

/**
 * @param options {Object}
 */
export const saveToDifferentFileFormat = (options) => {
  const name = options?.name ?? 'document';
  const format = options?.format ?? 'csv';
  const headerData = options?.headerData ?? [];
  const bodyData = options?.bodyData ?? [];
  const data = [...headerData, ...bodyData];
  const worksheet = XLSX.utils.aoa_to_sheet(data);
  const new_workbook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
  XLSX.writeFile(new_workbook, `${name}${format}`);
};
