import { STATUSES } from '../constants/app-constants';
import { safeToLowerCase } from './safe-to-lower-case';

export const getColorStatus = (status = '') => {
  const statusLowerCased = safeToLowerCase(status);
  let color = null;

  switch (statusLowerCased) {
    case STATUSES.FINISHED:
      color = '#61BC6A';
      break;
    case STATUSES.PARTIALLY_PAID:
      color = '#8247E5';
      break;
    case STATUSES.FAILED:
      color = '#FF7DA0';
      break;
    case STATUSES.REJECTED:
      color = '#FF7DA0';
      break;
    case STATUSES.REJECTING:
      color = '#FF7DA0';
      break;
    case STATUSES.EXPIRED:
      color = '#FF7DA0';
      break;
    case STATUSES.WAITING:
      color = '#FFBE40';
      break;
    case STATUSES.SENDING:
      color = '#FFBE40';
      break;
    case STATUSES.PROCESSING:
      color = '#FFBE40';
      break;
    default:
      return null;
  }

  return color;
};
