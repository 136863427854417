import classNames from 'classnames';
import info from '../../images/svg/info.svg';
import styles from './styles.module.scss';
export default function Info({
  children,
  text,
  width = 10,
  height = 10,
  containerWidth,
  variant,
}) {
  return (
    <div className={classNames(styles.content, styles[variant])}>
      {children}
      {!text || (
        <div className={styles.info}>
          <img src={info} alt="Info" width={width} height={height} />
          <div className={styles.container} style={{ width: containerWidth }}>
            {text}
          </div>
        </div>
      )}
    </div>
  );
}
