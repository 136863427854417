import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { EnableCustodySteps } from '../enable-custody-steps';
import { setItemToLocalStorage } from '../../libs/local-storage';
import { ENABLE_CUSTODY_LOCAL_STORAGE_NAME } from '../../constants/app-constants';
import { getSafeErrorMessageText } from '../../helpers/get-safe-error-message-text';
import { useOpenCustody } from '../../api/modules/account/use-open-custody';
import { hasElementsInArray } from '../../helpers/has-elements-in-array';
import { useAvailableCurrencies } from '../../api/modules/account/use-available-currencies';
import { useApiKeys } from '../../api/modules/account/use-api-keys';
import { partnerSelector } from '../../store/partner/selectors';
import { setPartner } from '../../store/partner/reducer';
import { walletsSelector } from '../../store/payment-settings/selectors';
import { useStoreOutcomeData } from '../../hooks/use-store-outcome-data';

export const EnableCustody = (props) => {
  const {
    className,
    title,
  } = props;

  const dispatch = useDispatch();
  const partner = useSelector(partnerSelector());
  const fetchOpenCustody = useOpenCustody();
  const fetchAvailableCurrencies = useAvailableCurrencies();
  const fetchApiKeys = useApiKeys();
  const wallets = useSelector(walletsSelector());
  const hasWallets = hasElementsInArray(wallets);

  const [isEnableCustodyFetching, setIsEnableCustodyFetching] = React.useState(false);
  const [apiErrorMessage, setApiErrorMessage] = React.useState(null);

  const handleEnableCustodySubmit = async (ips) => {
    setApiErrorMessage(null);
    setIsEnableCustodyFetching(true);
    const { data, status } = await fetchOpenCustody({ ips });
    setIsEnableCustodyFetching(false);

    if (status === 200 && data?.result) {
      setItemToLocalStorage(ENABLE_CUSTODY_LOCAL_STORAGE_NAME, true);
      dispatch(setPartner({ ...partner, isCustody: true }));
    } else {
      const errorMessage = getSafeErrorMessageText(data?.errorData?.message);
      setApiErrorMessage(errorMessage);
    }
  };
  const getAvailableCurrencies = async () => {
    const { data, status } = await fetchAvailableCurrencies();

    return status === 200 && hasElementsInArray(data) ? data : [];
  };
  const getApiKeys = async () => {
    const { data, status } = await fetchApiKeys();

    if (status === 200) {
      return data?.keys ?? [];
    }

    return [];
  };

  useStoreOutcomeData();

  return (
    <EnableCustodySteps
      className={className}
      title={title}
      onSubmit={handleEnableCustodySubmit}
      apiErrorMessage={apiErrorMessage}
      fetching={isEnableCustodyFetching}
      onAvailableCurrencies={getAvailableCurrencies}
      onApiKeys={getApiKeys}
      hasWallets={hasWallets}
    />
  );
};

EnableCustody.defaultProps = {
  className: null,
  title: null,
};

EnableCustody.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
