import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Field } from '../ui/field';
import { SimpleSelectField } from '../ui/simple-select-field';
import { Button } from '../ui/button';
import { LoaderIcon } from '../icons/loader-icon';
import { isFunction } from '../../helpers/is-function';
import { getUrlWithDomain } from '../../helpers/get-url-with-domain';
import { isCurrencyNetworkShow } from '../../helpers/is-currency-network-show';
import { CurrencyNetworkSup } from '../shared/currency-network-sup';
import {
  SUBSCRIPTION_PLAN_PERIODS,
  CREATE_SUBSCRIPTION_PLAN_SCHEMA,
  MAX_NUMBER_OF_TITLE_CHARACTERS,
  MAX_NUMBER_OF_TITLE_ERROR_MESSAGE,
} from './helpers';
import classes from './styles.module.scss';

export const CreateSubscriptionPlanForm = (props) => {
  const {
    className,
    fullBaseCurrency,
    onSubmit,
    errorApiMessage,
    pending,
    advanceSettingsShow,
  } = props;

  const {
    control,
    handleSubmit,
    formState,
    setError,
  } = useForm({
    resolver: yupResolver(CREATE_SUBSCRIPTION_PLAN_SCHEMA),
  });
  const {
    title,
    period,
    amount,
  } = formState.errors;
  const titleError = title?.message ?? null;
  const periodError = period?.message ?? null;
  const amountError = amount?.message ?? null;

  const [areAdditionFieldsShown, setAreAdditionFieldsShown] = React.useState(false);

  const handleTitleChange = (func) => (value) => {
    let message;
    if (value.length > MAX_NUMBER_OF_TITLE_CHARACTERS) {
      message = MAX_NUMBER_OF_TITLE_ERROR_MESSAGE;
    } else {
      message = null;
    }
    setError('title', { type: 'error', message });
    func(value);
  };
  const handleSettingsToggle = () => {
    setAreAdditionFieldsShown((prevState) => !prevState);
  };
  const handleFormSubmit = (formData) => {
    if (isFunction(onSubmit)) {
      onSubmit(formData);
    }
  };

  const baseCurrencyTicker = fullBaseCurrency?.code || '';
  const baseCurrencyCurrentTicker = fullBaseCurrency?.ticker ?? baseCurrencyTicker;
  const baseCurrencyLogoUrl = getUrlWithDomain(fullBaseCurrency?.logo_url || fullBaseCurrency?.logoUrl);
  const baseCurrencyNetwork = fullBaseCurrency?.network ?? null;
  const isNetworkShow = isCurrencyNetworkShow(baseCurrencyNetwork, baseCurrencyTicker);

  return (
    <form
      className={className}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <div>
        <Controller
          name="title"
          control={control}
          defaultValue=""
          maxLength={12}
          render={({ field }) => (
            <Field
              className={classes.formField}
              title="Title"
              data={{
                id: field.name,
                value: field.value,
                onChange: handleTitleChange(field.onChange),
                name: field.name,
                placeholder: 'Plan name',
                error: titleError,
                ref: field.ref,
              }}
            />
          )}
        />
        <div className={cn(classes.formField, classes.formCustomGroup)}>
          <Controller
            name="period"
            control={control}
            defaultValue="1"
            render={({ field }) => (
              <Field
                className={classes.formFieldPeriod}
                title="Period duration"
                data={{
                  id: field.name,
                  value: field.value,
                  onChange: field.onChange,
                  name: field.name,
                  placeholder: '0',
                  error: periodError,
                  pattern: /^\d{0,3}$/,
                  ref: field.ref,
                }}
              />
            )}
          />
          <Controller
            name="periodSelect"
            control={control}
            defaultValue={SUBSCRIPTION_PLAN_PERIODS[1].key}
            render={({ field }) => (
              <SimpleSelectField
                className={classes.formFieldPeriodSelect}
                data={{
                  id: field.name,
                  items: SUBSCRIPTION_PLAN_PERIODS,
                  onSelect: field.onChange,
                  defaultValue: SUBSCRIPTION_PLAN_PERIODS[1].value,
                }}
              />
            )}
          />
        </div>
        <div className={cn(classes.formField, classes.formCustomGroup)}>
          <Controller
            name="amount"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Field
                className={classes.formFieldCost}
                title="Cost per period"
                data={{
                  id: field.name,
                  value: field.value,
                  onChange: field.onChange,
                  name: field.name,
                  placeholder: '0',
                  error: amountError,
                  pattern: /^\d*[.]?\d{0,2}$/,
                  ref: field.ref,
                }}
              />
            )}
          />
          <Controller
            name="currency"
            control={control}
            defaultValue={baseCurrencyCurrentTicker}
            render={() => (
              <div className={classes.formFieldCostCurrency}>
                <img
                  src={baseCurrencyLogoUrl}
                  alt={baseCurrencyCurrentTicker}
                  decoding="async"
                  loading="lazy"
                  width={24}
                  height={24}
                />
                {baseCurrencyCurrentTicker}
                {isNetworkShow && (
                  <CurrencyNetworkSup network={baseCurrencyNetwork} />
                )}
              </div>
            )}
          />
        </div>
        {advanceSettingsShow && (
          <>
            <div
              className={cn([
                classes.formSettingsButton,
                areAdditionFieldsShown && classes.formSettingsButtonActive,
              ])}
              onClick={handleSettingsToggle}
            >
              <div className={classes.formSettingsButtonImg}>
                <img
                  src="/images/settings-plus.svg"
                  alt="Icon settings"
                  decoding="async"
                  loading="lazy"
                  width={12}
                  height={12}
                />
              </div>
              Advanced settings
            </div>
            <div
              className={cn([
                classes.formSettingsBox,
                areAdditionFieldsShown && classes.formSettingsBoxActive,
              ])}
            >
              <Controller
                name="ipnCallbackUrl"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Field
                    className={classes.formField}
                    title="Payment notifications link"
                    data={{
                      id: field.name,
                      value: field.value,
                      onChange: field.onChange,
                      name: field.name,
                      placeholder: 'Where you will receive notifs',
                    }}
                  />
                )}
              />
              <Controller
                name="successUrl"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Field
                    className={classes.formField}
                    title="Successful payment page"
                    data={{
                      id: field.name,
                      value: field.value,
                      onChange: field.onChange,
                      name: field.name,
                      placeholder: 'Where users will be redirected',
                    }}
                  />
                )}
              />
              <Controller
                name="cancelUrl"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Field
                    className={classes.formField}
                    title="Payment failed page"
                    data={{
                      id: field.name,
                      value: field.value,
                      onChange: field.onChange,
                      name: field.name,
                      placeholder: 'Where users will be redirected',
                    }}
                  />
                )}
              />
              <Controller
                name="partiallyPaidUrl"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Field
                    className={classes.formField}
                    title="Partial payment page"
                    data={{
                      id: field.name,
                      value: field.value,
                      onChange: field.onChange,
                      name: field.name,
                      placeholder: 'Where users will be redirected',
                    }}
                  />
                )}
              />
            </div>
          </>
        )}
      </div>
      <div
        className={cn([
          classes.formApiError,
          !advanceSettingsShow && classes.formApiErrorHeight,
        ])}
      >
        {errorApiMessage}
      </div>
      <Button
        type="submit"
        className={classes.formButton}
        disabled={pending}
      >
        {pending
          ? <LoaderIcon path="/images/loader-white-on-blue-icon.gif" />
          : 'Create'}
      </Button>
    </form>
  );
};

CreateSubscriptionPlanForm.defaultValue = {
  className: null,
  fullBaseCurrency: null,
  errorApiMessage: null,
  pending: false,
  onSubmit: null,
  advanceSettingsShow: false,
};

CreateSubscriptionPlanForm.propTypes = {
  className: PropTypes.string,
  fullBaseCurrency: PropTypes.object,
  errorApiMessage: PropTypes.string,
  pending: PropTypes.bool,
  onSubmit: PropTypes.func,
  advanceSettingsShow: PropTypes.bool,
};
