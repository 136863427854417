import * as yup from 'yup';
import { emptyToNull } from '../../../helpers/utils/schema';
import {
  ERROR_TEXTS,
  FIELD_NAMES,
  NAME_FOR_PAYOUTS_SCHEMA,
} from './constants';

export const WHITELIST_MANUAL_FORM_SCHEMA = yup.object({
  [NAME_FOR_PAYOUTS_SCHEMA]: yup.array(yup.object({
    [FIELD_NAMES.CURRENCY]: yup.string().trim()
      .required(ERROR_TEXTS.REQUIRED)
      .transform(emptyToNull)
      .nullable(),
    [FIELD_NAMES.ADDRESS]: yup.string().trim()
      .required(ERROR_TEXTS.REQUIRED)
      .transform(emptyToNull)
      .nullable(),
    [FIELD_NAMES.EXTRA_ID]: yup.string().trim()
      .transform(emptyToNull)
      .nullable()
      .optional(),
  })),
});
