import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  partnerFetchedSelector,
  partnerFetchingSelector,
} from '../../store/partner/selectors';
import { hasElementsInArray } from '../../helpers/has-elements-in-array';
import { ROUTES } from '../../constants/routes';
import { usePartnerCustodyCheck } from '../../hooks/use-partner-custody-check';
import { LoaderIcon } from '../icons/loader-icon';
import { ButtonBack } from '../shared/button-back';
import { useMediaQuery } from '../../hooks/use-media-query';
import { useHistoryReplace } from '../../hooks/use-history-replace';
import { useFetchPartnerProfile } from '../../api/modules/account/use-fetch-partner-profile';
import { currencyListSelector } from '../../store/currencies/selectors';
import { useCurrenciesToObject } from '../../hooks/use-currencies-to-object';
import {
  useBillingPlanPageInitState,
  useBillingPlanPageUsersState,
  useBillingPlanPageManageCustomersState,
} from './hooks';
import { BillingPlanInfo } from './components/billing-plan-info';
import { BillingPlanFilter } from './components/billing-plan-filter';
import { BillingPlanUsersTable } from './components/billing-plan-users-table';
import { PopupManageCustomers } from './components/popup-manage-customers';
import classes from './billing-plan-page-styles.module.scss';

export const BillingPlanPage = () => {
  const isTablet = useMediaQuery('(min-width: 768px)');
  const history = useHistory();
  const params = useParams();
  const planId = params?.planId ?? null;
  const partnerFetching = useSelector(partnerFetchingSelector());
  const partnerFetched = useSelector(partnerFetchedSelector());
  const currencyList = useSelector(currencyListSelector());
  const { currenciesObject } = useCurrenciesToObject(currencyList);

  const {
    isPlanFetching,
    planData,
    isPlanNotFound,
  } = useBillingPlanPageInitState(planId);

  const {
    isUsersFetching,
    users,
    usersCount,
    usersApiErrorMessage,
    searchText,
    page,
    handleSearchChange,
    setPage,
    reFetchUsers,
  } = useBillingPlanPageUsersState(planId);

  const {
    isPopupManageCustomersShow,
    handlePopupManageCustomersOpen,
    handlePopupManageCustomersClose,
    isSubPartnersFetching,
    isSubPartnersFetched,
    subPartners,
    subPartnersApiErrorMessage,
    reFetchSubPartners,
    handleManageCustomersSubmit,
    isAddSubPartnersFetching,
  } = useBillingPlanPageManageCustomersState(users, planId, reFetchUsers);

  const handleBackClick = () => {
    history.push(ROUTES.CUSTOMERS_BILLING_PLANS);
  };
  const handleUserRowClick = React.useCallback((id) => {
    history.push(`${ROUTES.CUSTOMERS}/${id}`);
  }, []);

  useFetchPartnerProfile();
  usePartnerCustodyCheck(ROUTES.CUSTOMERS, true);
  useHistoryReplace(ROUTES.CUSTOMERS_BILLING_PLANS, isPlanNotFound);

  const isLoaderShow = partnerFetching || !partnerFetched || isPlanFetching;
  const isFilterAllowed = hasElementsInArray(users) || (Boolean(searchText) && !hasElementsInArray(users));

  if (isLoaderShow) {
    return (
      <div className={classes.billingPlanPageLoader}>
        <LoaderIcon size={40} />
      </div>
    );
  }

  return (
    <div>
      <div className={classes.billingPlanPageHeader}>
        <ButtonBack
          className={classes.billingPlanPageBack}
          onClick={handleBackClick}
        />
        {planData?.title}
      </div>
      <BillingPlanInfo
        className={classes.billingPlanPageInfo}
        data={planData}
        landscape={isTablet}
        currenciesObject={currenciesObject}
      />
      <BillingPlanFilter
        onClick={handlePopupManageCustomersOpen}
        onChangeSearch={handleSearchChange}
        isAllowed={isFilterAllowed}
      />
      <BillingPlanUsersTable
        items={users}
        hasSearchData={Boolean(searchText)}
        onClick={handleUserRowClick}
        count={usersCount}
        onPaginatorClick={setPage}
        page={page}
        fetching={isUsersFetching}
        apiError={usersApiErrorMessage}
      />
      <PopupManageCustomers
        open={isPopupManageCustomersShow}
        onClose={handlePopupManageCustomersClose}
        onSubmit={handleManageCustomersSubmit}
        subPartners={subPartners}
        subPartnersFetching={isSubPartnersFetching}
        subPartnersFetched={isSubPartnersFetched}
        onSubPartners={reFetchSubPartners}
        apiErrorMessage={subPartnersApiErrorMessage}
        fetching={isAddSubPartnersFetching}
      />
    </div>
  );
};
